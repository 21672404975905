import { useEffect, useState } from 'react';
import { Jurisdiction } from '../../types/Jurisdiction.interface';
import { User } from '../../types/User';
import { useAuth } from '../../utils/auth/AuthService';

const { VITE_API_URL } = import.meta.env;

export function useDashboardDataGridAPI<T>(jurisdictions: Jurisdiction[], endpoint: string) {
  const auth = useAuth();
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  const loadData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('JDXLIST', JSON.stringify(jurisdictions));
    formData.append('USERSEQ', auth.user?.userSeq!);

    try {
      const response = await fetch(`${VITE_API_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data: T[] = await response.json();
        setData(data);
      } else {
        setData([]);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [jurisdictions]);

  return {
    data,
    loading,
    error,
  };
}

export async function loadDashboardDataGridAPI<T>(
  endpoint: string,
  user: User,
  jurisdictions: Jurisdiction[]
) {
  const formData = new FormData();
  formData.append('JDXLIST', JSON.stringify(jurisdictions));
  formData.append('USERSEQ', user?.userSeq!);
  let data: T[] = [];
  let error: any = null;

  try {
    const response = await fetch(`${VITE_API_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
      body: formData,
    });

    if (response.ok) {
      data = await response.json();
    }
  } catch (e) {
    error = e;
  } finally {
    return {
      data,
      error,
    };
  }
}

export interface DashboardDataGridProps {
  isLoading: boolean;
  data: any[];
  height?: number;
}
