import {
  Box,
  Button,
  Chip,
  createSvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';

const { VITE_API_URL } = import.meta.env;

const ExportIcon = createSvgIcon(
  <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z' />,
  'SaveAlt'
);

export function HL7_FHIR_Export_Button() {
  const formik = useFormikContext<any>();
  const caseid = formik.values?.caseSummary?.caseInformation?.caseID || '';

  const [open, setOpen] = useState(false);
  const [fhirData, setFhirData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // GET {VITE_API_URL}getcmscase/fhir?CASEID={caseid}
  const FHIR_ENDPOINT = `${VITE_API_URL}getcmscase/fhir?CASEID=${caseid}`;

  const handleOpen = async () => {
    setOpen(true);
    setLoading(true);
    setError(null);
    setFhirData(null);

    try {
      const response = await fetch(FHIR_ENDPOINT);
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      const data = await response.json();
      setFhirData(data);
    } catch (err: any) {
      setError(err.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  // Trigger file download of the JSON data
  const handleDownload = () => {
    if (!fhirData) return;

    const jsonBlob = new Blob([JSON.stringify(fhirData, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(jsonBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'fhir-export.json';
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Box component='div'>
      <Button variant='text' onClick={handleOpen} startIcon={<ExportIcon />}>
        HL7® FHIR Export
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
        <DialogTitle>
          <Box component='div' display='flex' alignItems='center' gap={1}>
            HL7® FHIR Document Bundle Export (JSON)
            <Chip label='Experimental' color='warning' variant='outlined' size='small' />
          </Box>
          {/* Disclaimer under the title (no sticky) */}
          <Box component='div' mt={0.5}>
            <Typography variant='body2' color='warning.main'>
              <strong>WARNING:</strong> This feature is a work in progress. Please report any
              inconsistencies or issues by submitting a new feedback ticket.
            </Typography>
          </Box>
        </DialogTitle>
        {loading && <LinearProgress sx={{ mb: 2 }} />}
        {!loading && (
          <DialogContent dividers sx={{ maxHeight: '70vh', overflow: 'auto' }}>
            {!loading && error && (
              <Typography variant='body2' color='error'>
                {error}
              </Typography>
            )}

            {!loading && !error && fhirData && (
              <pre
                style={{
                  marginTop: 16,
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {JSON.stringify(fhirData, null, 2)}
              </pre>
            )}

            {!loading && !error && !fhirData && (
              <Typography variant='body2'>No data found.</Typography>
            )}
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleDownload} disabled={!fhirData}>
            Download
          </Button>
          <Button onClick={handleClose} color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
