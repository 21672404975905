import React, { useEffect } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import { Tab, Typography, Box } from '@mui/material';
import { Container } from '@mui/system';
import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Contact from './Contact';
// import { axiosPublic, checkAdd } from 'utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

const id = '00000000-0000-0000-0000-000000000000';

const ContactTab = ({
  contactItem = [],
  handleChange,
  setContactDetailsError = () => {},
  isErrormsg = true,
  editing = true,
  isOpenEmptyTab = false,
}) => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));

  const removeContactTab = index => {
    const updatedContacts = contactItem.filter((_, i) => i !== index);
    handleChange(updatedContacts);
  };

  const handleContactSelectionChange = (e, index) => {
    const tempPanels = JSON.parse(JSON.stringify(contactItem));
    tempPanels[index][e.target.name] = e.target.value;
    handleChange(tempPanels);
  };

  const createNewTab = () => {
    let makeTab = true;
    contactItem?.forEach(panel => {
      if (!panel.contactItemTypeSeq || !panel.contactItemDetails) {
        makeTab = false;
      }
    });
    if (!makeTab) {
      // show dialog box that first fill mandatory fields of all tabs to add new contact
      return;
    }
    handleChange([
      ...contactItem,
      {
        contactItemSeq: id,
        contactItemTypeSeq: '',
        contactItemDetails: '',
        contactOrder: '1',
        isActive: true,
        contactItemIsPrimary: false,
        status: 'Active',
      },
    ]);
  };

  const [contactTypeNames, setContactTypeNames] = useState({});

  const getContactTypes = async () => {
    const tempContactTypeNames = {};
    axios.get(`${import.meta.env.VITE_API_URL}GetContactItemType`).then(response => {
      response.data.data.forEach(contactType => {
        tempContactTypeNames[contactType.contactItemTypeSeq] = contactType;
      });
      setContactTypeNames(tempContactTypeNames);
    });
  };

  useEffect(() => {
    getContactTypes().then(() => {
      if (contactItem?.length === 0) {
        createNewTab();
      }
    });
  }, []);

  return (
    <Box component={'div'}>
      <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
        <strong>Contact Method</strong>
      </Typography>
      {Object.keys(contactTypeNames).length
        ? contactItem?.map((panel, index) => (
            <Contact
              removeContactTab={removeContactTab}
              handleAddContact={createNewTab}
              handleContactSelectionChange={handleContactSelectionChange}
              panel={panel}
              index={index}
              panels={contactItem}
              contactTypeNames={contactTypeNames}
              setContactDetailsError={setContactDetailsError}
              isErrormsg={isErrormsg}
              editing={editing}
            />
          ))
        : null}
    </Box>
  );
};

export default ContactTab;
