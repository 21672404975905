import { useNotifications } from '../components/AppBar/Notifications/NotificationsContext';

export function useUserNotificationPreferences(userSeq: string) {
  const { preferences, preferencesLoading, preferencesError } = useNotifications();

  return {
    notificationPreferences: preferences,
    loading: preferencesLoading,
    error: preferencesError,
  };
}
