
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ReferenceData from './ReferenceDataModule/ReferenceDataView';
import AccountRequestView from './AccountRequestView';
import MDXUserAccounts from './MDXUserAccounts';

export const SUPPORT_MENU_ICON = { 
  'user-accounts': {
    icon: null,
    component: <MDXUserAccounts />,
  },

  'account-requests': {
    icon: null,
    component: <AccountRequestView />,
  },

  'Reference Data': {
    icon: <MenuBookIcon />,
    component: <ReferenceData />,
  }, 
};

export const CASE_ADMIN = {
  'Reference Data': true,
  'Lab List': true,
  POC: true,
  'Location List': true,
  'Case Visit': true,
  'Report Header Details': true,
  'Generate Blank RIME': true,
  'Livery Services': true,
};

export const SYSTEMS_ADMIN = {
  'Workstation Setup': true,
};


