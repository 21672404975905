// @ts-ignore
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Jurisdiction } from '../../types/Jurisdiction.interface';
import { useAuth } from '../../utils/auth/AuthService';
import { useDashboardDataGridAPIs } from './DashboardView';
import { constructGridRows } from './Grid/constructGridRows.function';
import { convertGridRowsToDashboardLayouts } from './Grid/convertGridRowsToDashboardLayouts';
import { GridRow } from './Grid/grid.constants';
import {
  DASHBOARD_SILO_SEQS,
  DashboardSilo,
  DashboardSiloLayout,
  defaultSiloMap,
} from './dashboard.constants';

const { VITE_API_URL } = import.meta.env;

export interface DashboardContextProps {
  addSiloDrawerOpen: boolean;
  toggleAddSiloDrawer: (addSiloDrawerOpen: boolean) => void;
  swapSiloDrawerOpen: boolean;
  toggleSwapSiloDrawer: ({
    open,
    fromSilo,
  }: {
    open: boolean;
    fromSilo?: DashboardSilo | undefined;
  }) => void;
  silos: DashboardSilo[];
  siloResponses: {
    dashboardSiloSeq: string;
    data: any[];
    loading: boolean;
    error: unknown;
  }[];
  selectedSilo: DashboardSilo | null;
  selectSilo: (silo: DashboardSilo | null) => void;
  handleSwapSilo: ({ from, to }: { from: DashboardSilo; to: DashboardSilo }) => void;
  handleAddSilo: (silo: DashboardSilo) => void;
  handleRemoveSilo: (silo: DashboardSilo) => void;
  isEditing: boolean;
  toggleEditing: (isEditing: boolean) => void;
  saveDashboard: () => Promise<void>;
  setSilos: React.Dispatch<React.SetStateAction<DashboardSilo[]>>;
  selectedJurisdictions: Jurisdiction[];
  setSelectedJurisdictions: React.Dispatch<React.SetStateAction<Jurisdiction[]>>;
  selectedJurisdictionsLoading: boolean;
  silosLoading: boolean;
  preferencesLoading: boolean;
  preferencesSaving: boolean;
  preferencesError: null | string;
  preferences: DashboardPrefences | null;
  saveDashboardJurisdictions: (jurisdictions: Jurisdiction[]) => Promise<void>;
  gridRows: GridRow[];
  setGridRows: React.Dispatch<React.SetStateAction<GridRow[]>>;
  setPreferences: React.Dispatch<React.SetStateAction<DashboardPrefences | null>>;
  reloadSiloData: (dashboardSiloSeq: keyof typeof DASHBOARD_SILO_SEQS) => Promise<void>;
}

export interface DashboardPrefences {
  version: number;
  userSeq: string;
  dashboardJurisdictions: Jurisdiction[];
  dashboardLayout: DashboardSiloLayout[];
  refreshSeconds: number;
}

export const DashboardContext = React.createContext<DashboardContextProps | undefined>(undefined);

export const DashboardProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const auth = useAuth();
  const [silosLoading, setSilosLoading] = useState(true);
  const [silos, setSilos] = useState<DashboardSilo[]>(defaultSiloMap);
  const [gridRows, setGridRows] = useState<GridRow[]>([]);

  const [selectedSilo, setSelectedSilo] = useState<DashboardSilo | null>(null);
  const [isEditing, setEditing] = useState(false);
  const [addSiloDrawerOpen, setAddSiloDrawerOpen] = useState(false);
  const [swapSiloDrawerOpen, setSwapSiloDrawerOpen] = useState(false);

  const [preferencesLoading, setPreferencesLoading] = useState(true);
  const [preferences, setPreferences] = useState<DashboardPrefences | null>(null);
  const [preferencesSaving, setPreferencesSaving] = useState(false);
  const [preferencesError, setPreferencesError] = useState<null | string>(null);

  const [selectedJurisdictions, setSelectedJurisdictions] = useState<Jurisdiction[]>([]);
  const [selectedJurisdictionsLoading, setSelectedJurisdictionsLoading] = useState(true);

  const { responses: siloResponses, reloadSiloData } = useDashboardDataGridAPIs({
    selectedJurisdictions,
    preferencesLoading,
  });

  const toggleEditing = (isEditing: boolean) => setEditing(isEditing);
  const toggleAddSiloDrawer = (addSiloDrawerOpen: boolean) => {
    setAddSiloDrawerOpen(addSiloDrawerOpen);
  };

  const handleAddSilo = (silo: DashboardSilo) => {
    if (!preferences || !preferences?.dashboardLayout) return;

    const cloned = silo ? _.cloneDeep(silo) : null;
    if (!cloned) return;
    setSilos(silos => [...silos, cloned]);

    const layoutClone = _.cloneDeep(preferences.dashboardLayout);
    const newLayout: DashboardSiloLayout[] = [
      ...layoutClone,
      {
        dashboardSiloName: cloned.dashboardSiloName,
        dashboardSiloSeq: cloned.dashboardSiloSeq,
        layoutOrder: layoutClone.length,
      },
    ];

    const gridRows = constructGridRows(newLayout, [...silos, cloned]);
    setGridRows(gridRows);
    setPreferences({ ...preferences, dashboardLayout: newLayout });
  };
  const handleRemoveSilo = (siloToRemove: DashboardSilo) => {
    // Remove the silo from the silos array
    setSilos(currentSilos =>
      currentSilos.filter(s => s.dashboardSiloSeq !== siloToRemove.dashboardSiloSeq)
    );

    // Update the gridRows to reflect the removal of the silo
    setGridRows(currentGridRows => {
      return (
        currentGridRows
          .map(row => {
            // Filter out the silo to remove from each row's items
            const filteredItems = row.items.filter(
              item => item.silo.dashboardSiloSeq !== siloToRemove.dashboardSiloSeq
            );

            // Return the updated row with the silo removed from its items
            return { ...row, items: filteredItems };
          })
          // Optionally: Only remove rows that are completely empty after the item removal
          .filter(row => row.items.length > 0)
      );
    });

    if (preferences && preferences.dashboardLayout) {
      // Update preferences to remove the silo from dashboardLayout
      const updatedDashboardLayout = preferences.dashboardLayout.filter(
        layoutItem => layoutItem.dashboardSiloSeq !== siloToRemove.dashboardSiloSeq
      );

      // Optionally, adjust the layoutOrder for remaining items if necessary
      // This step assumes you want to maintain a continuous sequence for layoutOrder
      const reorderedDashboardLayout = updatedDashboardLayout.map((item, index) => ({
        ...item,
        layoutOrder: index,
      }));

      // Set the updated preferences
      setPreferences({
        ...preferences,
        dashboardLayout: reorderedDashboardLayout,
      });
    }
  };

  const toggleSwapSiloDrawer = ({
    open,
    fromSilo,
  }: {
    open: boolean;
    fromSilo?: DashboardSilo;
  }) => {
    setSwapSiloDrawerOpen(open);

    if (fromSilo !== undefined) {
      setSelectedSilo(fromSilo);
    }
  };

  const selectSilo = (silo: DashboardSilo | null) => {
    setSelectedSilo(silo);
  };

  const handleSwapSilo = ({ from, to }: { from: DashboardSilo; to: DashboardSilo }) => {
    if (!preferences || !preferences.dashboardLayout) return;

    const toClone = _.cloneDeep(to);
    toClone.layoutOrder = from.layoutOrder;

    // Update the silos array
    setSilos(currentSilos =>
      currentSilos.map(silo =>
        silo.dashboardSiloSeq === from.dashboardSiloSeq ? { ...toClone } : silo
      )
    );

    // Find the layoutOrder of the from silo in preferences.dashboardLayout
    const fromSiloLayout =
      preferences.dashboardLayout.find(
        layoutItem => layoutItem.dashboardSiloSeq === from.dashboardSiloSeq
      ) ?? null;

    if (!fromSiloLayout) return;

    const updatedLayout = preferences.dashboardLayout.map(layoutItem =>
      layoutItem.dashboardSiloSeq === from.dashboardSiloSeq
        ? {
            ...layoutItem,
            dashboardSiloSeq: to.dashboardSiloSeq,
            dashboardSiloName: to.dashboardSiloName,
          }
        : layoutItem
    );

    setPreferences({
      ...preferences,
      dashboardLayout: updatedLayout,
    });

    // Update gridRows
    setGridRows(currentGridRows =>
      currentGridRows.map(row => ({
        ...row,
        items: row.items.map(item =>
          item.silo.dashboardSiloSeq === from.dashboardSiloSeq ? { ...item, silo: toClone } : item
        ),
      }))
    );
  };

  const fetchDashboardPreferences = async () => {
    try {
      setPreferencesLoading(true);
      const url = `${VITE_API_URL}user/preferences/getDashboardPreferences`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.user?.accessToken}`,
          Accept: 'application/json',
        },
      });
      const preferences: DashboardPrefences = await response.json();
      const sortedLayout = defaultSiloMap
        .map(defaultSilo => {
          const foundSilo = preferences.dashboardLayout.find(
            s => s.dashboardSiloSeq === defaultSilo.dashboardSiloSeq
          );
          if (foundSilo) {
            return { ...defaultSilo, layoutOrder: foundSilo.layoutOrder };
          }
          return null;
        })
        .filter((silo): silo is NonNullable<typeof silo> => silo !== null) // This type guard filters out null values and refines the type
        .sort((a, b) => {
          // Assuming layoutOrder can be nullable, we provide a fallback value (e.g., 0) to ensure we always have a number to compare.
          const orderA = a.layoutOrder ?? 0;
          const orderB = b.layoutOrder ?? 0;
          return orderA - orderB;
        });

      setSilos(sortedLayout);
      setPreferences(preferences);
      setSelectedJurisdictions(preferences.dashboardJurisdictions);
    } catch (error) {
      console.log(error);
    } finally {
      setPreferencesLoading(false);
      setSelectedJurisdictionsLoading(false);
      setSilosLoading(false);
    }
  };

  const saveDashboard = async () => {
    if (!preferences) return;
    const layout = convertGridRowsToDashboardLayouts(gridRows);

    const newPreferences: DashboardPrefences = {
      version: preferences.version,
      dashboardLayout: layout,
      dashboardJurisdictions: selectedJurisdictions,
      userSeq: auth.user?.userSeq!,
      refreshSeconds: preferences.refreshSeconds,
    };

    try {
      setPreferencesError(null);
      setPreferencesSaving(true);
      const url = `${VITE_API_URL}user/preferences/dashboard/save`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user?.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPreferences),
      });
    } catch (err) {
      console.log(err);
      setPreferencesError(`${err}`);
    } finally {
      setPreferencesSaving(false);
    }
  };

  const saveDashboardJurisdictions = async (jurisdictions: Jurisdiction[]) => {
    if (!preferences) return;

    try {
      const url = `${VITE_API_URL}user/preferences/dashboard/savejurisdictions`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user?.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jurisdictions),
      });
    } catch (err) {
      console.log(err);
    } finally {
      setPreferencesSaving(false);
    }
  };

  useEffect(() => {
    fetchDashboardPreferences();
  }, []);

  useEffect(() => {
    if (preferences && preferences?.dashboardLayout.length > 0 && !preferencesLoading) {
      const gridRows = constructGridRows(preferences.dashboardLayout, silos);
      setGridRows(gridRows);
    }
  }, [preferences, silos, preferencesLoading]);

  return (
    <DashboardContext.Provider
      value={{
        setPreferences,
        setSilos,
        setGridRows,
        toggleAddSiloDrawer,
        toggleSwapSiloDrawer,
        selectSilo,
        handleAddSilo,
        handleSwapSilo,
        handleRemoveSilo,
        toggleEditing,
        saveDashboard,
        setSelectedJurisdictions,
        saveDashboardJurisdictions,
        reloadSiloData,
        addSiloDrawerOpen,
        swapSiloDrawerOpen,
        isEditing,
        selectedSilo,
        siloResponses,
        silos,
        silosLoading,
        selectedJurisdictions,
        selectedJurisdictionsLoading,
        preferences,
        preferencesLoading,
        preferencesSaving,
        preferencesError,
        gridRows,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = React.useContext(DashboardContext);
  // If the hook is used outside of the Provider's scope, throw an error.
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardContext');
  }
  return context;
};
