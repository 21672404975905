import React from 'react';
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from 'mui-tiptap';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { StarterKit } from '@tiptap/starter-kit';
import { useCallback, useEffect, useRef, useState } from 'react';
import EditorMenuControls from '../../views/Home/EditorMenuControls';
import useExtensions from '../../views/Home/useExtensions';
import { Close, Send, TextFields } from '@mui/icons-material';

interface RichTextEditorProps {
  value?: string;
  onChange?: (content: string) => void;
  placeholder?: string;
  isEditing?: boolean;
  sx?: any;
}

function NotesRichTextEditor({
  value = '',
  onChange,
  placeholder = 'Type comments...',
  isEditing = true,
  sx,
}: RichTextEditorProps) {
  const extensions = useExtensions({ placeholder });
  const rteRef = useRef<RichTextEditorRef>(null);
  const [showMenuBar, setShowMenuBar] = useState(true);

  const handleChange = useCallback(() => {
    const content = rteRef.current?.editor?.getHTML() || '';
    onChange?.(content);
  }, [onChange]);

  return (
    <Box
      component='div'
      sx={{
        '& .ProseMirror': {
          minHeight: '150px',
          '& h1, & h2, & h3, & h4, & h5, & h6': {
            scrollMarginTop: showMenuBar ? 50 : 0,
          },
        },
        ...sx,
      }}
    >
      <RichTextEditor
        ref={rteRef}
        extensions={extensions}
        editable={isEditing}
        content={value}
        onUpdate={handleChange}
        renderControls={() => <EditorMenuControls />}
        RichTextFieldProps={{
          variant: 'outlined',
          MenuBarProps: { hide: !isEditing },
          //   footer: (
          //     <Stack
          //       direction='row'
          //       spacing={2}
          //       sx={{
          //         borderTopStyle: 'solid',
          //         borderTopWidth: 1,
          //         borderTopColor: theme => theme.palette.divider,
          //         py: 1,
          //         px: 1.5,
          //       }}
          //     >
          //       <MenuButton
          //         value='formatting'
          //         tooltipLabel={showMenuBar ? 'Hide formatting' : 'Show formatting'}
          //         size='small'
          //         onClick={() => setShowMenuBar(prev => !prev)}
          //         selected={showMenuBar}
          //         IconComponent={TextFields}
          //       />
          //     </Stack>
          //   ),
        }}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </RichTextEditor>
    </Box>
  );
}

export default NotesRichTextEditor;
