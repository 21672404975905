import React, { useEffect, useRef, useState } from 'react';
import { Alert, AlertProps, Box, Typography } from '@mui/material';

interface ScrollingAlertProps extends AlertProps {
  message: string;
  speed?: number;
}

const ScrollingAlert: React.FC<ScrollingAlertProps> = ({ message, speed = 10, ...props }) => {
  const alertRef = useRef<HTMLDivElement | null>(null);
  const messageRef = useRef<HTMLDivElement | null>(null);
  const [messageWidth, setMessageWidth] = useState<number>(0);
  const [alertWidth, setAlertWidth] = useState<number>(0);

  useEffect(() => {
    if (alertRef.current) setAlertWidth(alertRef.current.offsetWidth);
  }, [alertRef.current]);

  useEffect(() => {
    if (messageRef.current) setMessageWidth(messageRef.current.offsetWidth);
  }, [messageRef.current]);

  // return (
  //   <Alert
  //     ref={alertRef}
  //     {...props}
  //     sx={{
  //       overflow: 'hidden',
  //       position: 'relative',
  //       display: 'flex',
  //       whiteSpace: 'nowrap',
  //       '& .MuiAlert-message': { width: '100%', overflow: 'hidden' },
  //     }}
  //   >
  //     <div
  //       style={{
  //         display: 'flex',
  //         whiteSpace: 'nowrap',
  //         paddingLeft: '100%',
  //         animation: `scroll-text ${50 / speed}s linear infinite`,
  //       }}
  //     >
  //       {/* <span
  //         style={{
  //           paddingRight: `calc(${alertWidth}px - ${messageWidth}px - 36px - 1.9rem)`,
  //         }}
  //       >
  //         {message}
  //       </span> */}
  //       {/* <div ref={messageRef}>{message}</div> */}
  //       <span>{message}</span>
  //     </div>

  //     <style>
  //       {`
  //         @keyframes scroll-text {
  //           from { transform: translateX(0); }
  //           to { transform: translateX(calc(-100%); }
  //         }
  //       `}
  //     </style>
  //   </Alert>
  // );

  return (
    <Alert
      {...props}
      sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
        display: 'flex',
        '& .MuiAlert-message': { width: '100%', overflow: 'hidden' },
      }}
    >
      <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
        <div
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            animation: `scroll-text ${50 / speed}s linear infinite`,
            paddingLeft: '100%', // Start off-screen
          }}
        >
          {message}
          {/* Duplicated text for seamless looping */}
        </div>
      </div>

      {/* Keyframes in global style */}
      <style>
        {`
          @keyframes scroll-text {
            from { transform: translateX(0%); }
            to { transform: translateX(-100%); }
          }
        `}
      </style>
    </Alert>
  );
};

export default ScrollingAlert;
