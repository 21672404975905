import { Info } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { Option } from '../Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { CallCenterUserOption } from './CallCenterUsersDataGrid';

interface UserAddress {
  addressSeq: string;
  contactInfoSeq: string | null;
  addressTypeSeq: string;
  otherAddressType: string;
  placeSeq: string | null;
  addressLine1: string;
  addressLine2: string;
  apartmentNo: string;
  crossStreet: string;
  city: string;
  stateSeq: string;
  otherState: string;
  zip: string;
  countrySeq: string;
  isPrimary: boolean;
  creationDateTime: string;
  isActive: boolean | null;
  floor: string;
  isCopyAddress: boolean;
  geoX: number | null;
  geoY: number | null;
  isWithinServiceArea: boolean;
  countySeq: string | null;
  nursingHomeSeq: string | null;
  funeralHomeSeq: string | null;
  lawEnforcementSeq: string | null;
  status: string;
}

interface UserContact {
  contactItemSeq: string;
  contactItemDetails: string;
  contactItemTypeSeq: string;
  contactOrder: string;
  isActive: boolean;
  contactItemIsPrimary: boolean;
}

export interface UserProfile {
  userSeq: string;
  roleseq: string[];
  countySeqandDashboard: string[];
  userTitle: string;
  userName: string;
  primaryEmail: string;
  userFirstName: string;
  userMiddleName: string;
  userLastName: string;
  userSuffix: string;
  primaryMobileNo: string;
  initials: string;
  barcode: string;
  departmentSeq: string;
  organizationseq: string;
  operationMode: string;
  srtCaseEditable: boolean;
  xRayEmailEnabled: boolean;
  holdable: boolean;
  isHighProfile: boolean;
  isExamCompleteDateEditable: boolean;
  isUnconfirmCaseRelease: boolean;
  isVoidTissueNumber: boolean;
  isDeleteTissueNumber: boolean;
  isHideDropdownPopUp: boolean;
  isModifyIdUser: boolean;
  isAutopsyReportFinal: boolean;
  enable2FA: boolean;
  eSignature: string | null;
  isAnatomicPathologyCert: boolean;
  isClinicalPathologyCert: boolean;
  isForensicPathologyCert: boolean;
  mdLicenseNo: string;
  mdLicenseExpiryDate: string;
  isDeathInvestigationCert: boolean;
  isAutopsyCertCert: boolean;
  isExternalExamCert: boolean;
  ocsmeCertificateExpiryDate: string;
  abmdiCertificateNO: string;
  abmdiCertificateExpiryDate: string;
  boardcertificatefellowNo: string;
  userAddress: UserAddress[];
  userContacts: UserContact[];
  securityQuestion: string | null;
}

interface CallCenterUserDialogProps {
  mode: 'add' | 'edit';
  initialData?: UserProfile;
  selectedUser: CallCenterUserOption | null;
  open?: boolean;
  onClose?: () => void;
  stateOptions?: Option[];
  countryOptions?: Option[];
  addressTypeOptions?: Option[];
}

const { VITE_API_URL } = import.meta.env;

export function CallCenterUserDialog({
  mode,
  selectedUser = null,
  initialData,
  open = false,
  onClose = () => {},
  stateOptions = [],
  countryOptions = [],
  addressTypeOptions = [],
}: CallCenterUserDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <UserForm
        mode={mode}
        initialData={initialData}
        onClose={onClose}
        addressTypeOptions={addressTypeOptions}
        countryOptions={countryOptions}
        stateOptions={stateOptions}
        selectedUser={selectedUser}
      />
    </Dialog>
  );
}

interface UserFormProps {
  mode: 'add' | 'edit';
  initialData?: UserProfile;
  selectedUser: CallCenterUserOption | null;
  onClose: (status?: { success: boolean; message: string }) => void;
  stateOptions?: Option[];
  countryOptions?: Option[];
  addressTypeOptions: Option[];
}

const UserForm: React.FC<UserFormProps> = ({
  mode,
  selectedUser = null,
  initialData,
  onClose,
  addressTypeOptions = [],
  stateOptions = [],
  countryOptions = [],
}) => {
  const { user } = useAuth();
  const defaultUserProfile: UserProfile = {
    userSeq: '',
    roleseq: [],
    countySeqandDashboard: [],
    userTitle: '',
    userName: '',
    primaryEmail: '',
    userFirstName: '',
    userMiddleName: '',
    userLastName: '',
    userSuffix: '',
    primaryMobileNo: '',
    initials: '',
    barcode: '',
    departmentSeq: '',
    organizationseq: '',
    operationMode: '',
    srtCaseEditable: false,
    xRayEmailEnabled: false,
    holdable: false,
    isHighProfile: false,
    isExamCompleteDateEditable: false,
    isUnconfirmCaseRelease: false,
    isVoidTissueNumber: false,
    isDeleteTissueNumber: false,
    isHideDropdownPopUp: false,
    isModifyIdUser: false,
    isAutopsyReportFinal: false,
    enable2FA: false,
    eSignature: null,
    isAnatomicPathologyCert: false,
    isClinicalPathologyCert: false,
    isForensicPathologyCert: false,
    mdLicenseNo: '',
    mdLicenseExpiryDate: '',
    isDeathInvestigationCert: false,
    isAutopsyCertCert: false,
    isExternalExamCert: false,
    ocsmeCertificateExpiryDate: '',
    abmdiCertificateNO: '',
    abmdiCertificateExpiryDate: '',
    boardcertificatefellowNo: '',
    userAddress: [],
    userContacts: [],
    securityQuestion: null,
  };

  const defaultNewUserProfile: UserProfile = {
    // @ts-ignore
    abmdiCertificateExpiryDate: null,
    abmdiCertificateNO: '',
    barcode: '',
    boardcertificatefellowNo: '',
    // countySeqandDashboard: ['a4f61e5a-30ec-433f-ad5c-892fab64957f || True || False'],
    countySeqandDashboard: [],
    departmentSeq: '4f66a4b5-858e-459a-8f37-33e224a45c99',
    eSignature: null,
    enable2FA: false,
    initials: '',
    // @ts-ignore
    mdLicenseExpiryDate: null,
    // @ts-ignore
    mdLicenseNo: null,
    // @ts-ignore
    ocsmeCertificateExpiryDate: null,
    // @ts-ignore
    operationMode: null,
    organizationseq: 'a6dbc293-7045-4cc1-bc79-36d772a57743',
    primaryEmail: '',
    primaryMobileNo: '',
    roleseq: [
      '9bb433a7-1cf5-48e1-969e-b41bcdfd3b4b || True', // DVCS,
      'A62EB010-88D5-440A-BE2C-E94BCEC73502 || True', // MEDEXLAB-ACCESS
    ],
    securityQuestion: null,
    userAddress: [
      {
        addressSeq: '00000000-0000-0000-0000-000000000000',
        addressTypeSeq: '',
        addressLine1: '',
        city: '',
        placeSeq: null,
        stateSeq: '',
        // @ts-ignore
        countrySeq: null,
        countySeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
        isWithinServiceArea: true,
        // @ts-ignore
        creationDateTime: '2025-02-10T18:08:50.749Z',

        status: 'Active',
        geoX: null,
        geoY: null,
        isCopyAddress: false,
        floor: '',
        isPrimary: false,
        otherState: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
        contactInfoSeq: '00000000-0000-0000-0000-000000000000',
        isActive: true,
      },
    ],
    userContacts: [
      {
        contactItemSeq: '00000000-0000-0000-0000-000000000000',
        contactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
        contactItemDetails: '',
        contactOrder: '1',
        isActive: true,
        contactItemIsPrimary: false,
        // @ts-ignore
        status: 'Active',
      },
    ],
    userFirstName: '',
    userLastName: '',
    userMiddleName: '',
    userName: '',
    userSeq: '00000000-0000-0000-0000-000000000000',
    userSuffix: '',
    userTitle: '',
    IsWithinServiceArea: true,
    isAnatomicPathologyCert: false,
    isAutopsyCertCert: false,
    isAutopsyReportFinal: false,
    isClinicalPathologyCert: false,
    isDeathInvestigationCert: false,
    isDeleteTissueNumber: false,
    isExamCompleteDateEditable: false,
    isExternalExamCert: false,
    isForensicPathologyCert: false,
    isHideDropdownPopUp: false,
    isHighProfile: false,
    isModifyIdUser: false,
    isUnconfirmCaseRelease: false,
    isVoidTissueNumber: false,
  };

  const [formData, setFormData] = useState<UserProfile>(
    initialData || (mode === 'edit' ? defaultUserProfile : defaultNewUserProfile)
  );

  const primaryAddress = formData.userAddress[0] || {
    addressTypeSeq: '',
    stateSeq: '',
    countrySeq: '',
  };

  const selectedAddressType = useMemo(
    () =>
      primaryAddress.addressTypeSeq
        ? addressTypeOptions.find(
            opt => opt.optionSeq.toLowerCase() === primaryAddress.addressTypeSeq?.toLowerCase()
          ) || null
        : null,
    [addressTypeOptions, primaryAddress]
  );

  const selectedAddressState = useMemo(
    () =>
      primaryAddress.stateSeq
        ? stateOptions.find(
            opt => opt.optionSeq.toLowerCase() === primaryAddress.stateSeq?.toLowerCase()
          ) || null
        : null,
    [stateOptions, primaryAddress]
  );

  const selectedAddressCountry = useMemo(
    () =>
      primaryAddress.countrySeq
        ? countryOptions.find(
            opt => opt.optionSeq.toLowerCase() === primaryAddress.countrySeq?.toLowerCase()
          ) || null
        : null,
    [countryOptions, primaryAddress]
  );

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const field = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        userAddress: [
          {
            ...prev.userAddress[0],
            creationDateTime: new Date().toISOString(),
            [field]: value,
          },
        ],
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDeactivateUser = async () => {
    const response = await fetch(`${VITE_API_URL}InactivateUser?userSeq=${initialData?.userSeq}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    const { status } = await response.json();

    onClose({
      success: Boolean(!status),
      message: Boolean(!status) ? 'User deactivated successfully.' : 'Failed to deactivate user',
    });
  };

  const handleReactivateUser = async () => {
    const response = await fetch(`${VITE_API_URL}ActivateUser?userSeq=${initialData?.userSeq}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    const { status } = await response.json();

    onClose({
      success: Boolean(!status),
      message: Boolean(!status) ? 'User reactivated successfully.' : 'Failed to reactivate user',
    });
  };

  const handleResetPassword = async () => {
    const response = await fetch(`${VITE_API_URL}user/generatetemporarypassword`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        securityAnswer: '',
        securityQuestionSeq: '00000000-0000-0000-0000-000000000000',
        userSeq: initialData?.userSeq,
      }),
    });

    const { status } = await response.json();

    onClose({
      success: Boolean(!status),
      message: Boolean(!status)
        ? 'User password reset successfully.'
        : 'Failed to reset user password',
    });
  };

  const handleSave = async () => {
    try {
      const body =
        mode === 'edit'
          ? formData
          : {
              ...formData,
              userContacts: [
                {
                  contactItemSeq: '00000000-0000-0000-0000-000000000000',
                  contactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
                  contactItemDetails: formData.primaryMobileNo,
                  contactOrder: '1',
                  isActive: true,
                  contactItemIsPrimary: false,
                  // @ts-ignore
                  status: 'Active',
                },
              ],
            };

      const response = await fetch(
        `${VITE_API_URL}SaveUpdateUser?LogInuserSeq=${user?.userSeq}&isIncludeSecurityQuestion=false`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      );

      if (mode === 'add') {
        const { data: created_user, status } = await response.json();
        const temp_password_response = await fetch(
          `${VITE_API_URL}user/generatetemporarypassword`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              securityAnswer: '',
              securityQuestionSeq: '00000000-0000-0000-0000-000000000000',
              userSeq: created_user.userSeq,
            }),
          }
        );

        onClose({
          success: Boolean(!status),
          message: Boolean(!status) ? 'User created successfully' : 'Failed to create user',
        });
      } else {
        const { data: updated_user, status } = await response.json();

        console.log(status);
        onClose({
          success: Boolean(!status),
          message: Boolean(!status) ? 'User updated successfully' : 'Failed to update user',
        });
      }
    } catch (error) {
      onClose({
        success: false,
        message: 'Error saving user',
      });
    }
  };

  return (
    <Box component='div' sx={{ p: 4, width: '600px' }}>
      <Typography variant='h5' sx={{ mb: 4 }}>
        {mode === 'add' ? 'Add New User to Call Center' : 'Edit DVCS User Details'}
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label='First Name'
            name='userFirstName'
            value={formData.userFirstName}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label='Middle Name'
            name='userMiddleName'
            value={formData.userMiddleName}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label='Last Name'
            name='userLastName'
            value={formData.userLastName}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={mode === 'edit' ? true : false}
            label='UserID'
            name='userName'
            value={formData.userName}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Initials'
            name='initials'
            value={formData.initials}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Email'
            name='primaryEmail'
            type='email'
            value={formData.primaryEmail}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput
            fullWidth
            label='Phone'
            name='primaryMobileNo'
            value={formData.primaryMobileNo}
            onChange={e => {
              if (mode === 'add') {
                setFormData(prev => ({
                  ...prev,
                  primaryMobileNo: e.target.value,
                  userContacts: [
                    {
                      contactItemSeq: '00000000-0000-0000-0000-000000000000',
                      contactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
                      contactItemDetails: e.target.value,
                      contactOrder: '1',
                      isActive: true,
                      contactItemIsPrimary: false,
                      status: 'Active',
                    },
                  ],
                }));
              } else {
                setFormData(prev => ({
                  ...prev,
                  primaryMobileNo: e.target.value,
                }));
              }
            }}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='overline' sx={{ mb: 2 }}>
            Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={addressTypeOptions}
            renderInput={params => (
              <TextField {...params} label='Address Type' variant='outlined' />
            )}
            value={selectedAddressType}
            onChange={(_, newValue) => {
              setFormData(prev => ({
                ...prev,
                userAddress: [
                  {
                    ...prev.userAddress[0],
                    addressTypeSeq: newValue?.optionSeq || '',
                  },
                ],
              }));
            }}
            getOptionLabel={(option: Option) => option.optionName}
            isOptionEqualToValue={(option: Option, value: Option) =>
              option.optionSeq === value.optionSeq
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Address Line 1'
            name='address.addressLine1'
            value={primaryAddress.addressLine1}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Address Line 2'
            name='address.addressLine2'
            value={primaryAddress.addressLine2}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Apt/Suite'
            name='address.apartmentNo'
            value={primaryAddress.apartmentNo}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Floor'
            name='address.floor'
            value={primaryAddress.floor}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='City'
            name='address.city'
            value={primaryAddress.city}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={stateOptions}
            renderInput={params => <TextField {...params} label='State' variant='outlined' />}
            value={selectedAddressState}
            onChange={(_, newValue) => {
              setFormData(prev => ({
                ...prev,
                userAddress: [
                  {
                    ...prev.userAddress[0],
                    stateSeq: newValue?.optionSeq || '',
                  },
                ],
              }));
            }}
            getOptionLabel={(option: Option) => option.optionName}
            isOptionEqualToValue={(option: Option, value: Option) =>
              option.optionSeq === value.optionSeq
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='ZIP Code'
            name='address.zip'
            value={primaryAddress.zip}
            onChange={handleChange}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={6}>
          <Autocomplete
            options={countryOptions}
            renderInput={params => <TextField {...params} label='Country' variant='outlined' />}
            value={selectedAddressCountry}
            onChange={(_, newValue) => {
              setFormData(prev => ({
                ...prev,
                userAddress: [
                  {
                    ...prev.userAddress[0],
                    countrySeq: newValue?.optionSeq || '',
                  },
                ],
              }));
            }}
            getOptionLabel={(option: Option) => option.optionName}
            isOptionEqualToValue={(option: Option, value: Option) =>
              option.optionSeq === value.optionSeq
            }
          />
        </Grid>

        {mode === 'add' && (
          <Grid item xs={12}>
            <Alert icon={<Info />} severity='info' variant='filled'>
              After saving, the user will receive an email containing login instructions.
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          {mode === 'edit' && (
            <Button
              variant='outlined'
              color='warning'
              onClick={selectedUser?.isActive ? handleDeactivateUser : handleReactivateUser}
            >
              {selectedUser?.isActive ? 'Deactivate' : 'Reactivate'}
            </Button>
          )}
          {mode === 'edit' && selectedUser?.isActive && (
            <Button variant='outlined' color='warning' onClick={handleResetPassword}>
              Reset Password
            </Button>
          )}
          <Button variant='outlined' color='error' onClick={() => onClose()}>
            Cancel
          </Button>

          {mode === 'add' && (
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save User
            </Button>
          )}

          {mode === 'edit' && selectedUser?.isActive && (
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save User
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface PhoneInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (e: { target: { name: string; value: string } }) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value, onChange, ...props }) => {
  const formatPhoneNumber = (input: string): string => {
    const cleaned = input.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (!match) return '';
    const parts = [match[1], match[2], match[3]].filter(Boolean);
    return parts.join('-');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    onChange({
      target: {
        name: props.name || '',
        value: formatted,
      },
    });
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      placeholder='123-456-7890'
      inputProps={{
        maxLength: 12,
        ...props.inputProps,
      }}
    />
  );
};
