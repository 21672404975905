import React, { useState, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import {
  Box,
  Button,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Typography,
  Input,
  TextField,
} from '@mui/material';

import { Formik, Form, ErrorMessage } from 'formik';
import FileUpload from '../../../../components/AdministrationModules/FileUpload';
import axios from 'axios';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
// import Loader from 'ui-component/Loader';
import authHeader from '../../../../services/auth-header';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);
  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          sx={{ fontFamily: 'DataGrid, sans-serif' }}
          disableRipple
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const UploadRes = ({ switchon, readonly }) => {
  const permissions =
    JSON.parse(localStorage.getItem('accessPermissions')) ?? {};
    const { user } = useAuth();
  const [livecheck, setLivecheck] = useState(readonly);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [isEditOn, setIsEditOn] = useState();
  const [files, setFiles] = useState([]);
  const [filesToShow, setFilesToShow] = useState([]);
  const [editObj, setEditObj] = useState({});
  const [openDeleteDialogBox, setOpenDeleteDialogBox] = useState(false);
  const [openExportDialogBox, setOpenExportDialogBox] = useState(false);
  const [deletePhotoSeq, setDeletePhotoSeq] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const initialValues = { type: 'all' };
  const [resourceFileName, setResourceFileName] = useState('');

  const columns = [
    {
      field: 'photoLabel',
      headerName: 'File Name',
      minWidth: 100,
      align: 'left',
      flex: 5,
      headerClassName: 'super-app-theme--header light-blue-bg',

      renderCell: (params) => {
        const { row } = params;

        const openDoc = async (cellValues) => {
          setIsLoading(true);
          try {
            const response = await axios.get(
              `${import.meta.env.VITE_API_URL}OpenResourceDoc?PhotoSeq=${cellValues}`,
              { headers: authHeader() }
            );
            const fileData = response?.data?.data?.[0];
            const base64String = fileData?.photo;
            const filename = fileData?.fileName;
            const contentType = fileData?.photoMimeType;

            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: contentType });
            if ('download' in document.createElement('a')) {
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = filename;
              link.click();
              URL.revokeObjectURL(url);
            } else {
              const fileReader = new FileReader();
              fileReader.onload = function (event) {
                const fileURL = event.target.result;
                const link = document.createElement('a');
                link.href = fileURL;
                link.target = '_blank';
                link.download = filename;
                link.click();
              };
              fileReader.readAsDataURL(blob);
            }
          } catch (error) {
            console.log('error', error);
          }
          setIsLoading(false);
        };

        return (
          <Typography
            onClick={() => openDoc(row.photoSeq)}
            style={{ color: 'blue' }}
          >
            {row.photoLabel}
          </Typography>
        );
      },
    },
    {
      field: 'userCertificateTypeName',
      headerName: 'Certificate Type',
      headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
      renderCell: (cellValues) => {
        const photoTypeName = certificateTypes?.find(
          (row) => row.photoTypeSeq === cellValues.row.assignmentTypeSeq
        )?.photoTypeName;

        return <Typography>{photoTypeName}</Typography>;
      },
    },

    {
      field: 'photoMimeType',
      headerName: 'Extension Type',
      headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'void',
      headerName: 'Void',
      headerClassName: 'super-app-theme--header',
      align: 'center',
      renderCell: (cellValues) => {
        const handleRemove = () => {
          setOpenDeleteDialogBox(true);
          setDeletePhotoSeq(cellValues.row.photoSeq);
        };
        return (
          <Button
            onClick={handleRemove}
            disabled={!permissions?.['Upload Resources']?.edit}
            aria-label='delete'
            id={`row_id_${cellValues.row.id}`}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  const userSeq = user?.userSeq; //useSelector((state) => state?.userData?.user_edit?.data?.userSeq);
  const [certificateTypes, setCertificateTypes] = useState([]);

  const [
    userCertificateByCertificateType,
    setUserCertificateByCertificateType,
  ] = useState([]);
  const getCertificateTypes = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}GetAllDepartment?LogedInUserSeq=${userSeq}&type=resource`,
      { headers: authHeader() }
    );
    const data = response.data.data;

    setCertificateTypes(data || []);
    setIsLoading(false);
  };

  const openDoc = async (row) => {
    setIsLoading(true);
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}OpenResourceDoc?PhotoSeq=${row.photoSeq}`,
      { headers: authHeader() }
    );
    const fileData = response.data.data[0];

    const base64String = fileData?.photo;
    const filename = 'User Certificate';
    const contentType = fileData?.photoMimeType;

    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: contentType });
    const url = URL.createObjectURL(blob);

    window.open(url, '_blank');
    setIsLoading(false);
  };

  const getUserCertificateByCertificateTypes = async (photoTypeSeq) => {
    setIsLoading(true);
    const files = await getAllUserCertificates();
    if (photoTypeSeq == 'all' || !photoTypeSeq) {
      setFilesToShow(files);
      return;
    }
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}GetUploadResourceByAssignmentType?assignmentTypeSeq=${photoTypeSeq}&isPhotoTab=true`,
      { headers: authHeader() }
    );
    setEditObj({});
    setIsEditOn(false);
    setFilesToShow(response.data.data);
    setIsLoading(false);
  };

  const getAllUserCertificates = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}GetUploadResourceByAssignmentType?isPhotoTab=true`,
      { headers: authHeader() }
    );

    setEditObj({});
    setIsEditOn({});
    setFiles(response.data.data);
    setIsLoading(false);
    return response.data.data;
  };

  useEffect(() => {
    getCertificateTypes();
    getUserCertificateByCertificateTypes();
  }, []);

  const onSubmit = (values) => {};
  useEffect(() => {
    if (switchon == false) {
      setLivecheck(true);
      setIsEditOn(false);
      setEditObj({});
    } else if (switchon == true) {
      setLivecheck(false);
    }
  }, [switchon]);

  const editRow = async (params) => {
    setIsLoading(true);
    setIsEditOn(false);
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}GetEditCertificate?photoSeq=${params.row.photoSeq}`,
      { headers: authHeader() }
    );
    const editObj = response.data.data;
    setEditObj(editObj);
    setTimeout(() => {
      setIsEditOn(true);
    }, 200);
    setIsLoading(false);
  };

  const deleteFile = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${import.meta.env.VITE_API_URL}DeleteResource?photoSeq=${deletePhotoSeq}`,
        '',
        { headers: authHeader() }
      );
      setOpenDeleteDialogBox(false);
      setFiles(files?.filter((row) => row.photoSeq != deletePhotoSeq));
      getUserCertificateByCertificateTypes();
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  const downloadSectionsZip = async () => {
    setIsLoading(true);
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}ExportFile?isAll=${
        Object.values(selectedCheckboxes).every((value) => value === true)
          ? true
          : false
      }&userSeq=${userSeq}`,
      [...Object.keys(selectedCheckboxes).map((checkbox) => checkbox)],
      { headers: authHeader() }
    );
    exportToZip(response.data.data);
    setIsLoading(false);
  };

  const handleResourceFileName = (event) => {
    setResourceFileName(event.target.value);
  };

  // if (isLoading) {
  //     return <Loader />;
  // } else {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange
          enableReinitialize
        >
          {({
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit,
          }) => (
            <Form>
              <>
                <Grid container spacing={2}>
                  <Grid item lg={5} xs={12} md={4} sm={12}>
                    <FormControl fullWidth>
                      <InputLabel id={``}>Department</InputLabel>
                      <Select
                        id={`type`}
                        value={values.type}
                        disabled={livecheck}
                        label='Department'
                        name='type'
                        onChange={(e) => {
                          handleChange({
                            target: { value: e.target.value, name: 'type' },
                          });
                          getUserCertificateByCertificateTypes(e.target.value);
                        }}
                      >
                        <MenuItem value='all'>All</MenuItem>

                        {certificateTypes?.map((certificate) => {
                          const count = Array.isArray(files)
                            ? files?.filter(
                                (row) =>
                                  row.assignmentTypeSeq ==
                                  certificate.photoTypeSeq
                              ).length
                            : 0;
                          return (
                            <MenuItem
                              value={certificate.photoTypeSeq}
                              key={certificate.photoTypeSeq}
                              style={{
                                background: count ? '#1e88e5' : '',
                                color: count ? '#fff' : '',
                              }}
                            >
                              {certificate.photoTypeName}{' '}
                              {count ? `( ${count} )` : ''}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        name={`type`}
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={5} xs={12} md={12} sm={12}>
                    <FormControl
                      error
                      component='fieldset'
                      variant='standard'
                      sx={{ minWidth: '100%' }}
                    >
                      <TextField
                        id='outlined-basic'
                        label='Resource File Name'
                        variant='outlined'
                        required
                        value={resourceFileName}
                        onChange={handleResourceFileName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} xs={12} md={6} sm={12}>
                    <FormControl
                      error
                      required
                      component='fieldset'
                      variant='standard'
                    >
                      <Stack spacing={2} direction='row'>
                        <FileUpload
                          photoTypeSeq={values?.type}
                          setFilesToShow={setFilesToShow}
                          setFiles={setFiles}
                          documentType={values?.type}
                          module={resourceFileName}
                          permission={permissions?.['Upload Resources']}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            </Form>
          )}
        </Formik>
        <Grid container>
          <Grid item xs={12}>
            {files?.length && !livecheck ? (
              <Box
                sx={{
                  width: '100%',
                  '& .super-app-theme--header': {
                    backgroundColor: '#2196f3',
                  },
                }}
                style={{ tr: 'hover', cursor: 'pointer' }}
              >
                <DataGridPremium
                  getRowId={(row) => row?.photoSeq}
                  rows={filesToShow}
                  columns={columns}
                  columnHeaderHeight={50}
                  rowHeight={30}
                  pageSize={50}
                  scrollbarSize={5}
                  pagination
                  slots={{
                    pagination: CustomPagination,
                  }}
                  sx={{
                    width: '100%',
                    height: 450,
                    border: 'none',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 10px #ddd',
                    backgroundColor: 'white',
                    mt: 2,
                  }}
                />
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            {Object.keys(editObj).length ? (
              <Edit
                editObj={editObj}
                getAllData={getUserCertificateByCertificateTypes}
                certificateTypes={certificateTypes}
              />
            ) : null}
          </Grid>
        </Grid>
        <div className='modal'>
          <Dialog
            open={openDeleteDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenDeleteDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                Are you sure you want to delete this file?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteDialogBox(false)}>No</Button>
              <Button onClick={deleteFile}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </>
  );
  // }
};

export default UploadRes;
