export const referenceTypeFieldLengthChecks: Record<string, number> = { 
    bodyPartName: 30,
    caseEventName: 200,
    colorName: 50,
    countryName: 30,
    colorCodeName: 50,
    denominationValue: 5000,
    assignmentTypeName: 100,
    employmentStatusName: 50,
    licenseNo: 65, // handle with care
    funeralHomeName: 100,
    itemName: 100,
    jewelryTypeName: 30,
    lawEnforcementName: 50,
    hcfName: 500,
    nursingHomeName: 50,
    occupationName: 30,
    organDonorOrganizationName: 50,
    originName: 30,
    photoTypeName: 50,
    priorityName: 30,
    pronouncerLocationName: 50,
    raceName: 30,
    reasonDetailName: 50,
    reasonDetailOrder: 99999,
    reasonForNoPronouncementName: 50,
    reasonTypeName: 50,
    relshipName: 30,
    relshipInverseMale: 30,
    relshipInverseFemale: 30,
    requestedDocumentName: 100,
    requestedProcedureListName: 30,
    sceneConditionName: 100,
    securityQuestionDetails: 500,
    stateName: 30,
    supplementalCaseNoteTypeName: 100,
    pronouncerAgencyName: 500,
    voidCaseReasonName: 100,
    cosmeticSurgeryLocationName: 50,
    countyBurialItemName: 50,
    pointName: 30,
    sampleTypeName: 30,
    eyeColorName: 30,
    eyeStatusName: 50,
    facialHairColorName: 50,
    facialHairTypeName: 50,
    governmentalOrganizationName: 50,
    hairAccessoryName: 50,
    hairStyleName: 50,
    healthStatusName: 30,
    authorityTypeName: 50,
    medicalConditionTypeName: 50,
    medicationTypeName: 50,
    mentalConditionName: 30,
    nailLengthName: 50,
    nailTypeName: 50,
    notificationModeName: 30,
    notificationStatusName: 30,
    objectInBodyTypeName: 50,
    piercingLocationName: 50,
    placeOfIdentificationName: 50,
    prostheticTypeName: 50,
    requestStatusName: 30,
    responseStatusName: 30,
    skinTypeName: 30,
    surgeryTypeName: 50,
    publicAdministratorOfficeName: 100,
    usArmyAgencyName: 30,
    vehicleMakeName: 500,
    caseLocationName: 50,
    caseClassificationName: 500,
    chargeableRequestAgencyName: 50,
    paymentModeName: 50,
    // requestedProcedureName: 0, // make dropdown
    anthropologyAgeRangeSourceName: 300,
    drugName: 30,
    autopsyTableName: 50,
    clothingTypeName: 30,
    currencyTypeName: 30,
    documentTypeName: 30,
    evidenceTypeName: 30,
    outOfStateFuneralHomeName: 50,
    liveryService: 0, // check this if dropdown in cms,
    personalTypeName: 30,
    xRayDentalTypeName: 30,
    authorityRecordTypeName: 50,
    ageEstimateName:50,
    labAnalyteName:50,
};
