import { useEffect, useState } from 'react';

import {
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  useTheme,
  Autocomplete,
  TextField,
  Box,
  Stack,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';

import React from 'react';
import { Container } from '@mui/system';

// Add other imports as necessary

const { VITE_API_URL } = import.meta.env;

import { useNavigate } from 'react-router';

import { type1, type4, nameMappingReferenceMaterTable } from './referenceTypes';
import axios from 'axios';
import authHeader from '../../../../services/auth-header';

const ReferenceData = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [referenceData, setReferenceData] = useState({});
  const [selectedGroupName, setSelectedGroupName] = useState('');
console.log('referenceData', referenceData)
  const getReferenceTableNames = async () => {
    const ReferenceMasterTableUrl = VITE_API_URL + 'GetMasterData?tableName=referencemaster';
    const response = await axios.get(ReferenceMasterTableUrl, {
      headers: authHeader(),
    });
    const data = response.data;
    const referenceDataTempState = {};
    data.data.map(table =>
      table.tableGroup in referenceDataTempState
        ? referenceDataTempState[table.tableGroup].push(table)
        : (referenceDataTempState[table.tableGroup] = [table])
    );
    console.info(referenceDataTempState);
    setReferenceData(referenceDataTempState);
  };

  useEffect(() => {
    getReferenceTableNames();
  }, []);

  const navigateToTablePage = table => {
    if (type1.includes(table.referenceMasterTable))
      navigate(`reference-data/type1/${table.referenceMasterTable}`);
    else if (type4.includes(table.referenceMasterTable))
      navigate(`reference-data/type2/${table.referenceMasterTable}`);
    else navigate(`reference-data/unique/${table.referenceMasterTable}`);
  };

  return (
    <>
      <Stack>
        <Typography variant='h5' component='h1'>
          <b>Reference Data Tables</b>
        </Typography>
        <Typography variant='subtitle1' color='gray'>
          View reference data tables
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />

      <Box
        sx={{
          width: '100%',
          mt: '24px',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={10}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              spacing: 2,
            }}
          >
            <Typography
              variant='h6'
              component='label'
              xs={6}
              sx={{ display: 'flex', alignItems: 'center', mr: '10px' }}
            >
              Select Module:
            </Typography>
            <Autocomplete
              xs={6}
              disablePortal
              id='combo-box-module'
              options={Object.keys(referenceData)}
              sx={{ width: 300 }}
              onChange={(e, newValue) => {
                setSelectedGroupName(newValue);
              }}
              renderInput={params => <TextField {...params} label='Module' />}
            />
          </Grid>
        </Grid>

        <Box sx={{ minHeight: 100, mt: '1.5rem' }}>
          {referenceData && selectedGroupName !== undefined && (
            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
              {Object.keys(referenceData).map(tableGroupName => {
                const tableNames = Object.keys(nameMappingReferenceMaterTable);
                return (
                  <Paper
                    key={tableGroupName}
                    sx={{
                      display:
                        selectedGroupName && selectedGroupName !== tableGroupName ? 'none' : '',
                    }}
                  >
                    <Card raised xs={12} sm={6} md={3}>
                      <CardHeader
                        variant='h5'
                        title={tableGroupName
                          .split('')
                          .map(alpha => {
                            if (alpha <= 'Z') return ' ' + alpha;
                            return alpha;
                          })
                          .join('')
                          .trim()}
                        sx={{
                          bgcolor: '#000333',
                          p: '6px',
                          textAlign: 'center',
                          '.MuiTypography-h5': { color: '#FFF' },
                        }}
                      />
                      <CardContent
                        sx={{
                          padding: '0px',
                        }}
                      >
                        <nav>
                          <List disablePadding>
                            {referenceData[tableGroupName]
                              .sort((item1, item2) => {
                                const table1 = tableNames.includes(item1.referenceMasterTable)
                                  ? nameMappingReferenceMaterTable[item1.referenceMasterTable]
                                  : item1?.referenceMasterTable;
                                const table2 = tableNames.includes(item2.referenceMasterTable)
                                  ? nameMappingReferenceMaterTable[item2.referenceMasterTable]
                                  : item2?.referenceMasterTable;
                                let fa = table1.toLowerCase(),
                                  fb = table2.toLowerCase();
                                if (fa < fb) {
                                  return -1;
                                }
                                if (fa > fb) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map(table => {
                                const masterTableName = tableNames.includes(
                                  table.referenceMasterTable
                                )
                                  ? nameMappingReferenceMaterTable[table.referenceMasterTable]
                                  : table?.referenceMasterTable;
                                  const masterTableListName = masterTableName?.toLowerCase()?.includes('county') ? masterTableName?.replace(/County/g, 'Jurisdiction') : masterTableName;
                                return (
                                  <ListItem
                                    disablePadding
                                    key={table.referenceMasterSeq}
                                    onClick={() => navigateToTablePage(table)}
                                  >
                                    <ListItemButton>
                                      <ListItemText
                                        primary={
                                          masterTableListName === 'DNASampleType'
                                            ? 'DNA Sample Type'
                                            : masterTableListName
                                                ?.split('')
                                                .map(alpha => {
                                                  if (alpha <= 'Z') return ' ' + alpha;
                                                  return alpha;
                                                })
                                                .join('')
                                                .trim()
                                        }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </nav>
                      </CardContent>
                      <Divider light />
                    </Card>
                  </Paper>
                );
              })}
            </Masonry>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ReferenceData;
