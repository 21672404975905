import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Collapse,
  Alert,
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useAuth } from '../utils/auth/AuthService';
import CondensedDataGrid from './CondensedDataGrid';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ActionList } from './CaseView/CaseViewDateTimeField.jsx';
import DecedentNameHUD from '../utils/components/DecedentNameHUD';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

interface GroupCasesDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseSeq?: string | null;
  caseID?: string;
  isEditing?: boolean;
  onGroupActionComplete?: (status: 'success' | 'failure', groupId?: string) => void;
}

interface GroupCaseOption {
  caseSeq: string;
  caseID: string;
  firstName: string;
  lastName: string;
}

interface IncidentGroupOption {
  incidentGroupSeq: string | null;
  incidentGroupId: string;
  incidentDescription: string;
  incidentDateTime: string | null;
  isCaseGrouped: boolean;
}

interface CaseList {
  casenumber: string;
  decedentname: string;
}

const matchCount = 20;

export default function GroupCasesDialog({
  open,
  setOpen,
  caseSeq = null,
  caseID = '',
  isEditing = false,
  onGroupActionComplete,
}: GroupCasesDialogProps) {
  const [caseOptions, setCaseOptions] = useState<GroupCaseOption[]>([]);
  const [selectedCases, setSelectedCases] = useState<GroupCaseOption[]>([]);
  const [loadingCases, setLoadingCases] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [existingGroupOptions, setExistingGroupOptions] = useState<IncidentGroupOption[]>([]);
  const [existingGroupsLoading, setExistingGroupsLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<CaseList | null>(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;

  const [selectedIncidentGroup, setSelectedIncidentGroup] = useState<IncidentGroupOption>({
    incidentGroupSeq: null,
    incidentGroupId: '',
    incidentDescription: '',
    incidentDateTime: null,
    isCaseGrouped: false,
  });

  const [casesOnIncidentGroup, setCasesOnIncidentGroup] = useState<any[]>([]);
  const [isGenerateIdChecked, setIsGenerateIdChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [casesOnIncidentGroupLoading, setCasesOnIncidentGroupLoading] = useState(false);

  const handleConfirmDelete = (row: any) => {
    setCasesOnIncidentGroup((prev: any) =>
      prev.map((item: any) =>
        item.caseSeq === row?.caseSeq ? { ...item, isActive: false, isChanged: true } : item
      )
    );
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleAutocompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: IncidentGroupOption | null
  ): void => {
    if (value) {
      setSelectedIncidentGroup({
        incidentGroupSeq: value.incidentGroupSeq,
        incidentGroupId: value.incidentGroupId,
        incidentDescription: value.incidentDescription || '',
        incidentDateTime: value.incidentDateTime,
        isCaseGrouped: false,
      });
      fetchCasesOnIncidentGroup(value.incidentGroupSeq);
    } else {
      setSelectedIncidentGroup({
        incidentGroupSeq: null,
        incidentGroupId: '',
        incidentDescription: '',
        incidentDateTime: null,
        isCaseGrouped: false,
      });
      setCasesOnIncidentGroup([]);
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedIncidentGroup(prevState => ({
      ...prevState,
      incidentDescription: event.target.value, // Update only the description
    }));
  };

  const fetchCaseOptions = debounce(async (caseIdOrLastName: string) => {
    if (caseIdOrLastName.includes('--') || caseIdOrLastName.trim() === '') {
      setCaseOptions([]);
      return;
    }

    const caseIdRegex =
      /^(\d{1,2})(?:(?<=\d{2})(-\d{0,2}){0,1})?(?:(?<=\d{2}-\d{2})(-\d{0,4}){0,1})?$/;

    let caseID: string | null = null;
    let lastName: string | null = null;

    if (caseIdRegex.test(caseIdOrLastName)) {
      caseID = caseIdOrLastName;
    } else {
      lastName = caseIdOrLastName;
    }

    setLoadingCases(true);

    try {
      const response = await fetch(
        `${VITE_API_URL}GetCaseMatchesFromCaseIdOrLastName?count=${matchCount}&caseID=${
          caseID ?? ''
        }&lastName=${lastName ?? ''}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + user?.token,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch case options.');
      }

      const data: GroupCaseOption[] = await response.json();
      // Every option except ourselves
      const filteredData = data.filter(
        option => option.caseSeq?.toUpperCase() !== caseSeq?.toUpperCase()
      );
      setCaseOptions(filteredData);
    } catch (error) {
      console.error(error);
      setCaseOptions([]);
    } finally {
      setLoadingCases(false);
    }
  }, 300); // 300ms debounce time

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    try {
      let groupCases = [
        ...selectedCases.map(caseOpt => ({
          caseSeq: caseOpt.caseSeq,
          isActive: true,
          isChanged: true,
        })),
        ...casesOnIncidentGroup.map(item => ({
          caseSeq: item.caseSeq,
          isActive: item.isActive,
          isChanged: item?.isChanged || false,
        })),
      ];

      groupCases.push({
        caseSeq,
        isActive: true,
        isChanged: true,
      });

      groupCases = Array.from(
        new Map(groupCases.map(item => [item.caseSeq, item])).values()
      ).filter(item => item.isChanged);

      const payload = {
        incidentGroupDetails: {
          IncidentGroupSeq: selectedIncidentGroup?.incidentGroupSeq,
          IncidentGroupId: selectedIncidentGroup?.incidentGroupId,
          IncidentDescription: selectedIncidentGroup?.incidentDescription,
          IncidentDateTime: selectedIncidentGroup?.incidentDateTime || new Date().toISOString(),
        },
        userSeq: user?.userSeq,
        groupCases: groupCases,
      };

      const response = await fetch(VITE_API_URL + 'createIncidentGroup', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user?.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create incident group.');
      }

      const result = await response.json();
      console.log(result); // Log the server response for debugging
      onGroupActionComplete?.('success', result.incidentGroupId);
      setOpen(false);
    } catch (error) {
      console.error(error);
      onGroupActionComplete?.('failure');
      // alert(error.message || 'An error occurred.');
    } finally {
      setLoadingSubmit(false);
      setShowAlert(false);
    }
  };

  const fetchExistingGroupOptions = async () => {
    setExistingGroupsLoading(true);
    fetch(VITE_API_URL + 'getexistingincidentgroupoptions', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setExistingGroupOptions(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setExistingGroupsLoading(false);
      });
  };

  const fetchIncidentGroupOnCase = async () => {
    const caseSeqList = [caseSeq, ...selectedCases.map(caseOpt => caseOpt.caseSeq)].filter(
      (seq): seq is string => Boolean(seq)
    );

    if (caseSeqList.length === 0) return;
    const queryParams = new URLSearchParams();
    caseSeqList?.forEach(seq => queryParams.append('CaseSeqs', seq));

    fetch(VITE_API_URL + `getincidentgrouponcase?${queryParams}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (!data || data.length === 0) {
          setSelectedIncidentGroup({
            incidentGroupSeq: null,
            incidentGroupId: '',
            incidentDescription: 'Grouped - ' + dayjs().format('MM/DD/YYYY'),
            incidentDateTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
            isCaseGrouped: false,
          });
          setIsGenerateIdChecked(true);
          return;
        }
        
        setShowAlert(data.length > 1);

        if (!selectedIncidentGroup.incidentGroupId) {
          setSelectedIncidentGroup(data[0]);
          setIsGenerateIdChecked(false);
          if (data[0]?.incidentGroupSeq) {
            fetchCasesOnIncidentGroup(data[0].incidentGroupSeq);
          }
        }
      })
      .catch(e => {
        alert(e);
      });
  };

  const fetchCasesOnIncidentGroup = async (incidentGroupSeq: string | null) => {
    if (!incidentGroupSeq) return;

    setCasesOnIncidentGroupLoading(true);
    fetch(VITE_API_URL + 'getcasesonincidentgroup?IncidentGroupSeq=' + incidentGroupSeq, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setCasesOnIncidentGroup(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setCasesOnIncidentGroupLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      fetchExistingGroupOptions();
      fetchIncidentGroupOnCase();
    }
  }, []);

  useEffect(() => {
    const isGroupIdValid = Boolean(selectedIncidentGroup?.incidentGroupId || isGenerateIdChecked);
    const isDescriptionValid = Boolean(selectedIncidentGroup?.incidentDescription);

    setIsFormValid(isGroupIdValid);
  }, [
    selectedIncidentGroup?.incidentGroupId,
    selectedIncidentGroup?.incidentDescription,
    isGenerateIdChecked,
  ]);

  useEffect(() => {
    if (selectedCases.length > 0) {
      fetchIncidentGroupOnCase();
    } else if (showAlert) {
      setShowAlert(false);
    }
  }, [selectedCases]);

  const columns = [
    {
      field: 'casenumber',
      headerName: 'CaseID',
      width: 150,
      flex: 1,
    },
    {
      field: 'menotified',
      headerName: 'Reported',
      width: 150,
      flex: 1,
      valueFormatter: (value: any) => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : '';
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      width: 80,
      renderCell: (params: any) => {
        const onDeleteClick = (e: React.MouseEvent, row: any) => {
          e.stopPropagation();
          // setShowConfirmation(true);
          // setItemToDelete(row);
          handleConfirmDelete(row);
        };
        const jdxCode = params.row.casenumber.split('-')[0];
        const hasJdxAccess = user?.jdxAccessList.some(jdx => jdx.jdxCode === jdxCode);

        return (
          <div>
            {/* System-Admin and jurisdiction access user can remove the case from the group. (need to check on systems admin how this should work)*/}
            {/* (hasJdxAccess || user?.roleCheck(['451'])) */}
            {hasJdxAccess && (
              <IconButton
                onClick={e => onDeleteClick(e, params.row)}
                style={{
                  justifyContent: 'left',
                }}
                size='small'
                color='error'
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this case from group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='md'>
        <DialogTitle>
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Group Case</strong>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {showAlert && (
              <Grid item xs={12}>
                <Alert
                  severity='warning'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => setShowAlert(false)}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  The selected cases are tagged to another incident. Please verify the group details
                  and select the correct one.
                </Alert>
              </Grid>
            )}

            {!isEditing ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!isEditing}
                    id='incidentDescription'
                    name='incidentDescription'
                    label='Group Description'
                    variant='outlined'
                    fullWidth
                    type='text'
                    value={selectedIncidentGroup.incidentDescription || ''} 
                    onChange={handleDescriptionChange}
                    required
                    multiline
                    inputProps={{
                      maxLength: 1000,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      name='incidentDateTime'
                      label='Date'
                      disabled={!isEditing}
                      value={
                        selectedIncidentGroup.incidentDateTime
                          ? dayjs(selectedIncidentGroup.incidentDateTime)
                          : null
                      }
                      onChange={date => {
                        const localDate = date?.isValid()
                          ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
                          : null;
                        setSelectedIncidentGroup(prevState => ({
                          ...prevState,
                          incidentDateTime: localDate,
                        }));
                      }}
                      // disableFuture={true}
                      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                      ampm={false}
                      slots={{
                        actionBar: ActionList,
                      }}
                      slotProps={{
                        field: { clearable: true },
                        textField: { id: 'incidentDateTime' },
                      }}
                      sx={{
                        minWidth: '100%',
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                    <strong>Cases in this group:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <CondensedDataGrid
                    loading={casesOnIncidentGroupLoading}
                    listresults={casesOnIncidentGroup.filter(item => item.isActive) || []}
                    columnsInput={columns}
                    idcolumn='casenumber'
                    gridcolor='#0288d1'
                    gridheight={casesOnIncidentGroup?.length > 0 ? 'auto' : '100'}
                    rowsperpage={10}
                    tooltip='List of Grouped cases'
                    columnsVisibility={{
                      actions: isEditing,
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <DecedentNameHUD />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                    <strong>Group with these Cases:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disableCloseOnSelect
                    multiple
                    // options={caseOptions}
                    options={caseOptions.filter(option => {
                      const jdxCode = option.caseID.split('-')[0];
                      return user?.jdxAccessList.some(jdx => jdx.jdxCode === jdxCode);
                    })}
                    value={selectedCases}
                    onChange={(event, newValue) => setSelectedCases(newValue)}
                    isOptionEqualToValue={(option, value) => option.caseSeq === value.caseSeq}
                    getOptionLabel={option =>
                      `${option.caseID} -- ${option.lastName}, ${option.firstName}`
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<MdCheckBoxOutlineBlank />}
                          checkedIcon={<MdOutlineCheckBox />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.caseID} -- {option.lastName}, {option.firstName}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={`${option.caseID} -- ${option.lastName}, ${option.firstName}`}
                          key={option.caseSeq}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Case ID or Last Name'
                        variant='outlined'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingCases && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        onChange={event => fetchCaseOptions(event.target.value)}
                      />
                    )}
                    loading={loadingCases}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
                      </Grid>
                      <Button
                        startIcon={openDetails ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                        variant='text'
                        onClick={() => setOpenDetails(!openDetails)}
                      >
                        {openDetails ? 'Hide' : 'More'} details{' '}
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Collapse in={openDetails}>
                        <Grid container spacing={2}>
                          {isGenerateIdChecked ? (
                            <Grid item xs={12} md={6}>
                              <TextField
                                disabled={!isGenerateIdChecked}
                                id='incidentDescription'
                                name='incidentDescription'
                                label='Group Description'
                                variant='outlined'
                                fullWidth
                                type='text'
                                value={selectedIncidentGroup.incidentDescription || ''} // Display group description
                                onChange={handleDescriptionChange}
                                required
                                multiline
                                inputProps={{
                                  maxLength: 1000,
                                }}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12} md={6}>
                              <Autocomplete
                                loading={existingGroupsLoading}
                                options={existingGroupOptions}
                                value={
                                  isGenerateIdChecked
                                    ? null
                                    : existingGroupOptions.find(
                                        option =>
                                          option.incidentGroupId ===
                                          selectedIncidentGroup.incidentGroupId
                                      ) || null
                                }
                                getOptionLabel={option => option.incidentDescription}
                                onChange={handleAutocompleteChange}
                                disabled={!isEditing || isGenerateIdChecked}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Group'
                                    placeholder='Select a Group'
                                    variant='outlined'
                                    fullWidth
                                    required
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <Stack>
                                      <Typography>{option.incidentDescription}</Typography>
                                      <Typography sx={{ opacity: 0.6 }}>
                                        {option.incidentGroupId}
                                      </Typography>
                                    </Stack>
                                  </li>
                                )}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} md={isGenerateIdChecked ? 2 : 6}>
                            <FormControlLabel
                              label='New'
                              control={
                                <Checkbox
                                  checked={isGenerateIdChecked}
                                  onChange={event => {
                                    const isChecked = event.target.checked;
                                    setIsGenerateIdChecked(isChecked);
                                    if (isChecked) {
                                      setSelectedIncidentGroup({
                                        incidentGroupSeq: null,
                                        incidentGroupId: '',
                                        incidentDescription:
                                          'Grouped - ' + dayjs().format('MM/DD/YYYY'),
                                        incidentDateTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
                                        isCaseGrouped: false,
                                      });
                                      setCasesOnIncidentGroup([]);
                                    }
                                  }}
                                />
                              }
                            />
                          </Grid>

                          {!isGenerateIdChecked && (
                            <Grid item xs={12} md={6}>
                              <TextField
                                id='incidentDescription'
                                name='incidentDescription'
                                label='Group Description'
                                variant='outlined'
                                fullWidth
                                type='text'
                                value={selectedIncidentGroup.incidentDescription || ''} // Display group description
                                onChange={handleDescriptionChange}
                                required
                                multiline
                                inputProps={{
                                  maxLength: 1000,
                                }}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} md={isGenerateIdChecked ? 4 : 6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                name='incidentDateTime'
                                label='Date'
                                value={
                                  selectedIncidentGroup.incidentDateTime
                                    ? dayjs(selectedIncidentGroup.incidentDateTime)
                                    : null
                                }
                                onChange={date => {
                                  const localDate = date?.isValid()
                                    ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
                                    : null;
                                  setSelectedIncidentGroup(prevState => ({
                                    ...prevState,
                                    incidentDateTime: localDate,
                                  }));
                                }}
                                // disableFuture={true}
                                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                ampm={false}
                                slots={{
                                  actionBar: ActionList,
                                }}
                                slotProps={{
                                  field: { clearable: true },
                                  textField: { id: 'incidentDateTime' },
                                }}
                                sx={{
                                  minWidth: '100%',
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                              <strong>Cases in this group:</strong>
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <CondensedDataGrid
                              loading={casesOnIncidentGroupLoading}
                              listresults={casesOnIncidentGroup.filter(item => item.isActive) || []}
                              columnsInput={columns}
                              idcolumn='casenumber'
                              gridcolor='#0288d1'
                              gridheight={casesOnIncidentGroup?.length > 0 ? 'auto' : '100'}
                              rowsperpage={10}
                              tooltip='List of Grouped cases'
                              columnsVisibility={{
                                actions: isEditing,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        {/* // end collapse called "More Details" */}

        <DialogActions
          style={{ justifyContent: 'right', marginRight: '1rem', marginBottom: '1rem' }}
        >
          {isEditing && (
            <LoadingButton
              onClick={handleSubmit}
              loading={loadingSubmit}
              variant='contained'
              color='primary'
              disabled={!isFormValid}
            >
              {/* {isGroupEditing
                ? 'Save Group'
                : isGenerateIdChecked
                ? `Create and add this case (${caseID}) to the Group`
                : selectedIncidentGroup.isCaseGrouped
                ? `Update this case Group`
                : `Add this case (${caseID}) to the Group`} */}
              Save Group
            </LoadingButton>
          )}

          <Button
            onClick={() => {
              setOpen(false);
              setShowAlert(false);
              setCasesOnIncidentGroup([]);
            }}
            color='secondary'
          >
            {isEditing ? 'Cancel' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationModal />
    </>
  );
}
