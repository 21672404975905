import { Autocomplete, Divider, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ActionList } from '../../CaseView/CaseViewDateTimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import AddressFieldWithoutSearch from '../../../utils/components/AddressFieldWIthoutSearch';
import { renderAutocompleteEditCell } from '../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import CrudDataGrid from '../../../utils/components/CrudDataGrid/CrudDataGridReqDoc';
import { dateTimeColumnType } from '../../../utils/components/CrudDataGrid/DateTimeColumn';
import AddressData from '../../../components/AdministrationModules/AddressComponents/AddressData';
import { AddressTypeOptions, CaseReporterTypeOptions } from '../../../utils/constants/constants';
import InformantDialog from '../../../components/InformantDialog.jsx';
import ContactsView from '../../../utils/components/ContactsView';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReqDocNotesDialog from '../../../components/ReqDocNotesDialog.jsx';
import { CASE_REPORTER_TYPES } from '../../../utils/api/useOptions.hook';
import { ConvertHtmlToPlainText } from '../../../utils/functions/ConvertHtmlToPlainText';
import { useUnsavedChanges } from '../../../Context/UnsavedChangesContext';

const { VITE_API_URL } = import.meta.env;

const getLocalTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

function findPropertyName(obj, value) {
  console.log(obj);
  console.log(value);

  for (let prop in obj) {
    console.log(obj[prop]);
    console.log(value);
    console.log(obj[prop] === value);
    if (obj[prop] === value) {
      return prop;
    }
  }
  return null; // If value is not found in any property
}

// Gets the correct values for the caseReporter chosen given the jurisdiction (some agencies are
// only available in certain jurisdictions, others are available in every jurisdiction)
const getCaseReporterOptions = async (caseReporterType, jdxSeq) => {
  if (caseReporterType == null) {
    return [];
  }

  switch (caseReporterType.optionSeq?.toUpperCase()) {
    case CaseReporterTypeOptions.MEDICALFACILITY.toUpperCase():
      var hcfoptions = [];
      // GET HCF OPTIONS
      await fetch(VITE_API_URL + 'gethcfoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          hcfoptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return hcfoptions;

    case CaseReporterTypeOptions.LAWENFORCEMENT.toUpperCase():
      var leoptions = [];
      // GET LAW ENFORCEMENT OPTIONS
      await fetch(VITE_API_URL + 'getlawenforcementoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          leoptions = data;
        })
        .catch(e => {
          console.error(e);
          //alert(e);
        });
      return leoptions;

    case CaseReporterTypeOptions.NURSINGHOME.toUpperCase():
      var nhoptions = [];
      //GET NURSING HOME OPTIONS
      await fetch(VITE_API_URL + 'getnursinghomeoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          nhoptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return nhoptions;

    case CaseReporterTypeOptions.FUNERALHOME.toUpperCase():
      var fhoptions = [];
      // GET FUNERAL HOME OPTIONS
      await fetch(VITE_API_URL + 'getfuneralhomeoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          fhoptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return fhoptions;

    case CaseReporterTypeOptions.CHARGEABLEREQUEST.toUpperCase():
      var croptions = [];
      // GET CHARGEABLE REQUEST AGENCY OPTIONS
      await fetch(VITE_API_URL + 'getchargeablerequestagencyoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          croptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return croptions;

    default:
      return [];
  }
};

// Returns the proper name of the caseReporter type given its sequence (for formik id purposes)
function getCaseReporterId(caseReporterType, currentChargeableRequestIndex) {
  switch (caseReporterType?.optionSeq?.toUpperCase()) {
    case CaseReporterTypeOptions.MEDICALFACILITY:
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].hcf`;
    case CaseReporterTypeOptions.LAWENFORCEMENT:
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].lawEnforcement`;
    case CaseReporterTypeOptions.NURSINGHOME:
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].nursingHome`;
    case CaseReporterTypeOptions.FUNERALHOME:
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].funeralHome`;
    case CaseReporterTypeOptions.CHARGEABLEREQUEST:
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].chargeableRequestAgency`;
    default:
      return null;
  }
}

const getAgencySeqField = addressTypeSeq => {
  switch (addressTypeSeq?.toLowerCase()) {
    case AddressTypeOptions.MEDICALFACILITY:
      return 'placeSeq';
    case AddressTypeOptions.NURSINGHOME:
      return 'nursingHomeSeq';
    case AddressTypeOptions.LAWENFORCEMENT:
      return 'lawEnforcementSeq';
    case AddressTypeOptions.FUNERALHOME:
      return 'funeralHomeSeq';
    default:
      return null;
  }
};

// Returns the proper values of the caseReporter type given its sequence (for formik value purposes)
function getCaseReporterValue(caseReporterType, values) {
  switch (caseReporterType?.optionSeq?.toUpperCase()) {
    case CaseReporterTypeOptions.MEDICALFACILITY:
      return values?.hcf;
    case CaseReporterTypeOptions.LAWENFORCEMENT:
      return values?.lawEnforcement;
    case CaseReporterTypeOptions.NURSINGHOME:
      return values?.nursingHome;
    case CaseReporterTypeOptions.FUNERALHOME:
      return values?.funeralHome;
    case CaseReporterTypeOptions.CHARGEABLEREQUEST:
      return values?.chargeableRequestAgency;
    default:
      return null;
  }
}

export default function ChargeableRequestsForm({ currentChargeableRequestIndex }) {
  const [caseReporterTypeOptions, setCaseReporterTypeOptions] = useState([]);
  const [caseReporterOptions, setCaseReporterOptions] = useState([]);
  const [contactTypeOptions, setContactTypeOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [requestMethodOptions, setRequestMethodOptions] = useState([]);
  const { user } = useAuth();
  const formik = useFormikContext();
  const [informantOptions, setInformantOptions] = useState([]);
  const [pendingChangeEvent, setPendingChangeEvent] = useState(null);
  const [isInformantDialogOpen, setIsInformantDialogOpen] = useState(false);
  const [selectedInformant, setSelectedInformant] = useState([]);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateKey, setUpdateKey] = useState(0);

  const { setUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    // INITIALIZE CASE REPORTER TYPE OPTIONS
    fetch(VITE_API_URL + 'getcasereportertypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCaseReporterTypeOptions(
          data.filter(
            cr =>
              cr.optionSeq.toUpperCase() !== '2006ADAE-1F69-4FD6-9CF0-05C8E83B6685' &&
              cr.optionSeq.toUpperCase() !== '5B11112C-42EA-46D3-8A99-02F4BFCCF148' &&
              cr.optionSeq.toUpperCase() !== '9ADE1C87-424B-464F-8843-2E175139E592' &&
              cr.optionSeq.toUpperCase() !== 'A44FBBA9-C726-4ED8-A325-1CCD17B9578B'
          )
        );
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE CONTACT TYPE OPTIONS
    fetch(VITE_API_URL + 'getcontactitemtypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setContactTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE DOCUMENT OPTIONS
    fetch(VITE_API_URL + 'getrequesteddocumentoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setDocumentOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE PAYMENT OPTIONS
    fetch(VITE_API_URL + 'getpaymentmodeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPaymentOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE REQUEST METHOD OPTIONS
    fetch(VITE_API_URL + 'getrequestmethodoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setRequestMethodOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  }, []);

  const updateClearingHoldStatus = async currentIndex => {
    const updatedStatus = {
      ...formik.status,
      clearingHoldDateTimeUpdated: formik.status?.clearingHoldDateTimeUpdated?.map((value, index) =>
        index === currentIndex ? true : value
      ),
    };

    formik.setStatus(await updatedStatus);
  };

  useEffect(() => {
    if (
      currentChargeableRequestIndex !== -1 &&
      !formik.status?.clearingHoldDateTimeUpdated?.[currentChargeableRequestIndex] &&
      formik.values?.caseRecords?.chargeableRequests?.length > 0
    ) {
      const localTimezone = getLocalTimezone();
      const parsedDateTime = dayjs(
        formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.clearingHoldDateTime
      ).tz(localTimezone);
      const timezoneOffset = parsedDateTime.utcOffset() / 60;
      const updatedDateTime = parsedDateTime.add(timezoneOffset, 'hour');

      formik.setFieldValue(
        `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`,
        updatedDateTime
      );
      updateClearingHoldStatus(currentChargeableRequestIndex);
    }
  }, [currentChargeableRequestIndex]);

  const updateCaseReporterOptions = async () => {
    setCaseReporterOptions(
      await getCaseReporterOptions(
        formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.caseReporterType,
        formik.values?.caseSummary?.jdxSeq
      )
    );
  };

  useEffect(() => {
    if (caseReporterOptions.length === 0) {
      updateCaseReporterOptions();
    }
  }, [
    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
      ?.caseReporterType,
  ]);

  const getAgencySeq = caseReporterType => {
    switch (caseReporterType?.toUpperCase()) {
      case CaseReporterTypeOptions.MEDICALFACILITY.toUpperCase():
        return AddressTypeOptions.MEDICALFACILITY;
      case CaseReporterTypeOptions.NURSINGHOME.toUpperCase():
        return AddressTypeOptions.NURSINGHOME;
      case CaseReporterTypeOptions.LAWENFORCEMENT.toUpperCase():
        return AddressTypeOptions.LAWENFORCEMENT;
      case CaseReporterTypeOptions.FUNERALHOME.toUpperCase():
        return AddressTypeOptions.FUNERALHOME;
      case CaseReporterTypeOptions.CHARGEABLEREQUEST.toUpperCase(): //Chargeable request agency, return Office as default
        return '119B123C-41E6-42EC-BA5C-32C91731EFDD';
      case CaseReporterTypeOptions.OTHER.toUpperCase():
        return AddressTypeOptions.OTHER;
      default:
        return null;
    }
  };

  const handleCaseReporterTypeChange = async (event, data) => {
    // Reset all the caseReporter values since the type is changing
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].caseReporterType`,
      data
    );

    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress[0].addressTypeSeq`,
      getAgencySeq(data?.optionSeq)
    );

    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].hcf`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].lawEnforcement`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].nursingHome`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].funeralHome`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].otherCaseReporter`,
      null
    );

    // Get the correct caseReporter options for the type chosen in this jurisdiction
    setCaseReporterOptions(await getCaseReporterOptions(data, formik.values?.caseSummary?.jdxSeq));

    setUnsavedChanges();
  };

  const getTableName = caseReporterType => {
    switch (caseReporterType?.toUpperCase()) {
      case CaseReporterTypeOptions.MEDICALFACILITY.toUpperCase():
        return 'Hcf';
      case CaseReporterTypeOptions.NURSINGHOME.toUpperCase():
        return 'NursingHome';
      case CaseReporterTypeOptions.LAWENFORCEMENT.toUpperCase():
        return 'LawEnforcement';
      case CaseReporterTypeOptions.FUNERALHOME.toUpperCase():
        return 'FuneralHome';
      case CaseReporterTypeOptions.CHARGEABLEREQUEST.toUpperCase():
        return 'ChargeableRequestAgency';
      default:
        return null;
    }
  };

  const fetchAgencyAddress = async (agency, caseReporterType) => {
    const tableName = getTableName(caseReporterType);

    if (!tableName) {
      console.error('Invalid address type');
      return null;
    }

    try {
      const response = await fetch(
        `${VITE_API_URL}getagencyaddress?agencySeq=${agency.optionSeq}&tableName=${tableName}`
      );
      const addressData = await response.json();
      return addressData;
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };

  const fetchAgencyContactItems = async (agency, caseReporterType) => {
    const tableName = getTableName(caseReporterType);

    if (!tableName) {
      console.error('Invalid address type');
      return null;
    }

    try {
      const response = await fetch(
        `${VITE_API_URL}getagencycontacts?agencySeq=${agency.optionSeq}&tableName=${tableName}`
      );
      const contactsData = await response.json();
      return contactsData;
    } catch (error) {
      console.error('Error fetching contacts:', error);
      return null;
    }
  };

  const handleCaseReporterAgencyChange = async (event, value) => {
    formik.setFieldValue(
      getCaseReporterId(
        formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.caseReporterType,
        currentChargeableRequestIndex
      ),
      value
    );

    setUnsavedChanges();

    const agencySeqField = getAgencySeqField(
      formik.values?.caseRecords.chargeableRequests[currentChargeableRequestIndex]
        .requesterAddress[0].addressTypeSeq
    );

    if (value) {
      const addressData = await fetchAgencyAddress(
        value,
        formik.values?.caseRecords?.chargeableRequests[currentChargeableRequestIndex]
          ?.caseReporterType?.optionSeq
      );

      const contactsData = await fetchAgencyContactItems(
        value,
        formik.values?.caseRecords?.chargeableRequests[currentChargeableRequestIndex]
          ?.caseReporterType?.optionSeq
      );

      if (addressData) {
        const updatedAddress = {
          ...formik.values.caseRecords.chargeableRequests[currentChargeableRequestIndex]
            .requesterAddress[0],
          ...addressData,
          [agencySeqField]: value?.optionSeq,
        };
        formik.setFieldValue(
          `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress[0]`,
          updatedAddress
        );
      } else {
        const updatedAddress = {
          ...formik.values.caseRecords.chargeableRequests[currentChargeableRequestIndex]
            .requesterAddress[0],
          addressLine1: '',
          addressLine2: '',
          apartmentNo: '',
          floor: '',
          zip: '',
          city: '',
          otherAddressType: '',
          countySeq: null,
          [agencySeqField]: value?.optionSeq,
        };

        formik.setFieldValue(
          `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress[0]`,
          updatedAddress
        );
      }

      formik.setFieldValue(
        `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo`,
        contactsData || []
      );

      formik.setFieldValue(
        `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo`,
        contactsData?.length ? contactsData : [{ contactItemType: null, contactItemDetails: '' }]
      );
    }
  };

  const handleNewContactClick = event => {
    setPendingChangeEvent({ event });
    setSelectedInformant([]);
    setIsInformantDialogOpen(true);
  };

  const handleRequesterChange = value => {
    const { personSeq, personLastName, personFirstName } = value || {};
    setUnsavedChanges();

    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester[0]`,
      {
        personSeq,
        personLastName,
        personFirstName,
      }
    );

    if (value?.address) {
      const updatedAddress = {
        ...formik.values.caseRecords.chargeableRequests[currentChargeableRequestIndex]
          .requesterAddress[0],
        ...value.address,
      };

      formik.setFieldValue(
        `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress[0]`,
        updatedAddress
      );
    } else {
      const updatedAddress = {
        ...formik.values.caseRecords.chargeableRequests[currentChargeableRequestIndex]
          .requesterAddress[0],
        addressTypeSeq: null,
        addressLine1: '',
        addressLine2: '',
        apartmentNo: '',
        floor: '',
        zip: '',
        city: '',
        otherAddressType: '',
        countySeq: null,
      };

      formik.setFieldValue(
        `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress[0]`,
        updatedAddress
      );
    }

    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo`,
      value?.contactItem?.length
        ? value.contactItem.map(({ contactItemSeq, ...rest }) => rest)
        : [{ contactItemType: null, contactItemDetails: '' }]
    );
  };

  const handleNewContactSubmit = newContact => {
    const newContactOption = {
      informantSeq: newContact?.informantSeq,
      personSeq: newContact?.personSeq,
      personLastName: newContact?.personLastName,
      personFirstName: newContact?.personFirstName,
      relship: newContact?.relship?.optionName,
      PersonContactInfoSeq: newContact?.PersonContactInfoSeq,
      address: getFirstActiveAddress(newContact?.informantAddress?.address || []),
      contactItem: newContact?.informantAddress?.contactItem || [],
    };
    setInformantOptions(prevOptions => [...prevOptions, newContactOption]);
    setIsInformantDialogOpen(false);
    if (pendingChangeEvent) {
      handleRequesterChange(newContactOption);
      setPendingChangeEvent(null);
    }
  };

  const getFirstActiveAddress = addressList => {
    const activeAddress = addressList?.find(info => info.isActive);
    return activeAddress || null;
  };

  const handleContactChange = data => {
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo`,
      data
    );
  };

  const handleRequestMethodChange = event => {
    const selectedOption = requestMethodOptions.find(
      option => option.optionSeq.toUpperCase() === event.target.value
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestMethod`,
      selectedOption
    );
    setUnsavedChanges();
  };

  const handleIsFamilyRequestChange = event => {
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isFamilyRequest`,
      event.target.value === 'true' ? true : false
    );
    setUnsavedChanges();
  };

  const handleIsLegalReviewRequiredChange = event => {
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isLegalReviewRequired`,
      event.target.value === 'true' ? true : false
    );
    setUnsavedChanges();
  };

  const createRequestedDocument = newRow => {
    const currentNotes = formik.values.caseRecords?.chargeableRequests[
      currentChargeableRequestIndex
    ]?.requestedDocuments.find(
      doc => doc.requestedDocumentListSeq === newRow.requestedDocumentListSeq
    )?.notes;

    const updatedNewRow = {
      ...newRow,
      notes: currentNotes, // render edit cell not updating the row so alternate getting from formik will look into it.
    };

    let formData = new FormData();
    formData.append('newRow', JSON.stringify(updatedNewRow));
    formData.append(
      'chargeableRequestSeq',
      formik.values?.caseRecords.chargeableRequests?.[currentChargeableRequestIndex]
        ?.chargeableRequestSeq
    );
    formData.append(
      'contactInfoSeq',
      formik.values?.caseRecords.chargeableRequests?.[currentChargeableRequestIndex]?.contactInfoSeq
    );
    formData.append(
      'personSeq',
      formik.values?.caseRecords.chargeableRequests?.[currentChargeableRequestIndex]?.requester[0]
        .personSeq
    );
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    fetch(VITE_API_URL + 'createrequesteddocuments', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('successfully created entry');
      })
      .catch(e => {
        console.log(e);
        throw new Error('An error occured');
      });
  };

  const updateRequestedDocument = updatedRow => {
    const currentNotes = formik.values.caseRecords?.chargeableRequests[
      currentChargeableRequestIndex
    ]?.requestedDocuments.find(
      doc => doc.requestedDocumentListSeq === updatedRow.requestedDocumentListSeq
    )?.notes;

    const updatedUpdatedRow = {
      ...updatedRow,
      notes: currentNotes, // render edit cell not updating the row so alternate getting from formik will look into it.
    };

    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedUpdatedRow));
    formData.append('userSeq', user.userSeq);

    fetch(VITE_API_URL + 'updaterequesteddocuments', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('successfully updated entry');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteRequestedDocument = id => {
    fetch(VITE_API_URL + `deleterequesteddocuments/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('successfully deleted entry');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleNotesEditClick = (row, isEditing = true) => {
    let currentNotes = formik.values.caseRecords?.chargeableRequests[
      currentChargeableRequestIndex
    ]?.requestedDocuments.find(
      doc => doc.requestedDocumentListSeq === row.requestedDocumentListSeq
    )?.notes;

    currentNotes = ConvertHtmlToPlainText(currentNotes);
    setSelectedRow({
      ...row,
      notes: currentNotes, // render edit cell not updating the row so alternate getting from formik will look into it.
      isEditing: isEditing,
    });
    setOpenNotesDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenNotesDialog(false);
  };

  const handleSaveNotes = newNotes => {
    if (selectedRow) {
      const updatedRows = formik.values.caseRecords?.chargeableRequests[
        currentChargeableRequestIndex
      ]?.requestedDocuments?.map(row =>
        row.requestedDocumentListSeq === selectedRow.requestedDocumentListSeq
          ? { ...row, notes: newNotes }
          : row
      );
      formik.setFieldValue(
        `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestedDocuments`,
        updatedRows
      );

      setSelectedRow(prev => ({
        ...prev,
        notes: newNotes,
      }));

      handleCloseDialog();
    }
  };

  const requestedDocumentsColumns = [
    {
      flex: 1,
      field: 'dateRequested',
      headerName: 'Date Requested',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      valueSetter: (value, row) => {
        if (value == null || value == 'Invalid Date') {
          return { ...row, dateRequested: null };
        } else {
          const localDate = value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : null;

          return { ...row, dateRequested: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'itemRequested',
      headerName: 'Item Requested',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'singleSelect',
      editable: true,
      valueOptions: documentOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'numberOfItemsRequested',
      headerName: '# of Items',
      type: 'number',
      editable: true,
      headerAlign: 'left',
      align: 'right',
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'costPerItem',
      headerName: 'Cost Per Item',
      type: 'number',
      editable: true,
      headerAlign: 'left',
      align: 'right',
    },
    {
      flex: 1,
      field: 'dateInvoiced',
      headerName: 'Date Invoiced',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      valueSetter: (value, row) => {
        if (value == null || value == 'Invalid Date') {
          return { ...row, dateInvoiced: null };
        } else {
          const localDate = value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : null;

          return { ...row, dateInvoiced: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'dateReceived',
      headerName: 'Date Received',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      valueSetter: (value, row) => {
        if (value == null || value == 'Invalid Date') {
          return { ...row, dateReceived: null };
        } else {
          const localDate = value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : null;

          return { ...row, dateReceived: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'paymentMethod',
      headerName: 'Payment Method',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'singleSelect',
      editable: true,
      valueOptions: paymentOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      field: 'dateSent',
      headerName: 'Date Sent',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      valueSetter: (value, row) => {
        if (value == null || value == 'Invalid Date') {
          return { ...row, dateSent: null };
        } else {
          const localDate = value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : null;

          return { ...row, dateSent: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 0.4,
      minWidth: 80,
      field: 'notes',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
      renderCell: params => (
        <div style={{ marginLeft: '1rem' }}>
          <ArrowForwardIcon
            style={{ cursor: 'pointer', color: '#5C72FF' }}
            onClick={() => handleNotesEditClick(params.row, false)}
          />
        </div>
      ),
      renderEditCell: params => (
        <div style={{ marginLeft: '1rem' }}>
          <ArrowForwardIcon
            style={{ cursor: 'pointer', color: '#5C72FF' }}
            onClick={() => handleNotesEditClick(params.row, true)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    //Identified By options
    const filteredInformantList =
      formik.values?.caseDemographics?.informantList?.filter(
        informant => informant.isActive === 1
      ) ?? [];

    const informantOptions = [
      { personSeq: 'new', personLastName: 'Add New Contact', personFirstName: '' },
      ...filteredInformantList?.map(option => ({
        informantSeq: option?.informantSeq,
        personSeq: option?.personSeq,
        personLastName: option?.personLastName,
        personFirstName: option?.personFirstName,
        relship: option?.relship?.optionName,
        PersonContactInfoSeq: option?.personContactInfoSeq,
        address: getFirstActiveAddress(option?.informantAddress?.address || []),
        contactItem: option?.informantAddress?.contactItem || [],
      })),
    ];
    setInformantOptions(informantOptions);
  }, [formik.values?.caseDemographics?.informantList]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Request Made By:</FormLabel>
            <RadioGroup
              row
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isFamilyRequest`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isFamilyRequest`}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.isFamilyRequest ?? false
              }
              onChange={handleIsFamilyRequestChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label='Individual'
                disabled={!formik.status.editing}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label='Facility/Agency/Institution'
                disabled={!formik.status.editing}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {!formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.isFamilyRequest ? (
          <>
            <Grid item xs={12} md={6}>
              <Autocomplete
                {...formik.getFieldProps('caseReporterType')}
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].caseReporterType`}
                name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].caseReporterType`}
                options={caseReporterTypeOptions}
                value={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.caseReporterType ?? null
                }
                isOptionEqualToValue={(option, value) => {
                  return option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase();
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.caseReporterType && Boolean(formik.errors.caseReporterType)}
                helperText={formik.touched.caseReporterType && formik.errors.caseReporterType}
                size='small'
                required
                disabled={!formik.status.editing}
                getOptionLabel={option => option.optionName}
                onChange={handleCaseReporterTypeChange}
                fullWidth
                renderInput={params => (
                  <TextField {...params} label='Agency Type' placeholder='Agency Type' required />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.caseReporterType != null &&
              formik.values?.caseRecords?.chargeableRequests?.[
                currentChargeableRequestIndex
              ]?.caseReporterType.optionSeq?.toUpperCase() ==
                '25439ADE-F997-451A-9122-2C904DAD52D9' ? (
                <TextField
                  id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].otherCaseReporter`}
                  name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].otherCaseReporter`}
                  value={
                    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                      ?.otherCaseReporter ?? ''
                  }
                  onChange={formik.handleChange}
                  onBlur={e => {
                    formik.handleBlur(e);
                    const currentValue = formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]?.otherCaseReporter ?? ''
                    if (e.target.value !== currentValue) {
                      setUnsavedChanges();
                    }
                  }}
                  error={
                    formik.touched.otherCaseReporter && Boolean(formik.errors.otherCaseReporter)
                  }
                  helperText={formik.touched.otherCaseReporter && formik.errors.otherCaseReporter}
                  size='small'
                  variant='outlined'
                  label='Other CaseReporter'
                  disabled={!formik.status.editing}
                  fullWidth
                  required
                />
              ) : (
                <Autocomplete
                  id={() =>
                    getCaseReporterId(
                      formik.values?.caseRecords?.chargeableRequests?.[
                        currentChargeableRequestIndex
                      ]?.caseReporterType,
                      currentChargeableRequestIndex
                    )
                  }
                  name={() =>
                    getCaseReporterId(
                      formik.values?.caseRecords?.chargeableRequests?.[
                        currentChargeableRequestIndex
                      ]?.caseReporterType,
                      currentChargeableRequestIndex
                    )
                  }
                  options={caseReporterOptions}
                  value={getCaseReporterValue(
                    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                      ?.caseReporterType,
                    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                  }
                  size='small'
                  getOptionLabel={option => option.optionName}
                  onChange={handleCaseReporterAgencyChange}
                  fullWidth
                  disabled={
                    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                      ?.caseReporterType === null || !formik.status.editing
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Agency'
                      placeholder={
                        formik.values?.caseRecords?.chargeableRequests?.[
                          currentChargeableRequestIndex
                        ]?.caseReporterType?.optionName
                      }
                      onBlur={formik.handleBlur}
                      error={formik.touched.caseReporter && Boolean(formik.errors.caseReporter)}
                      helperText={formik.touched.caseReporter && formik.errors.caseReporter}
                      required
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personLastName`}
                name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personLastName`}
                value={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.requester?.[0]?.personLastName ?? ''
                }
                onChange={formik.handleChange}
                onBlur={e => {
                  formik.handleBlur(e);
                  const currentValue = formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.requester?.[0]?.personLastName ?? '';
                  if (e.target.value !== currentValue) {
                      setUnsavedChanges();
                  }
                }}
                error={
                  formik.touched.requester?.[0]?.personLastName &&
                  Boolean(formik.errors.requester?.[0]?.personLastName)
                }
                helperText={
                  formik.touched.requester?.[0]?.personLastName &&
                  formik.errors.requester?.[0]?.personLastName
                }
                size='small'
                variant='outlined'
                disabled={!formik.status.editing}
                label='Requester Last Name'
                fullWidth
                // required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personFirstName`}
                name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personFirstName`}
                value={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.requester?.[0]?.personFirstName ?? ''
                }
                onChange={formik.handleChange}
                onBlur={e => {
                  formik.handleBlur(e);
                  const currentValue = formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.requester?.[0]?.personFirstName ?? '';
                  if (e.target.value !== currentValue) {
                      setUnsavedChanges();
                  }
                }}
                error={
                  formik.touched.requester?.[0]?.personFirstName &&
                  Boolean(formik.errors.requester?.[0]?.personFirstName)
                }
                helperText={
                  formik.touched.requester?.[0]?.personFirstName &&
                  formik.errors.requester?.[0]?.personFirstName
                }
                size='small'
                disabled={!formik.status.editing}
                variant='outlined'
                label='Requester First Name'
                fullWidth
                // required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personMiddleName`}
                name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personMiddleName`}
                value={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.requester?.[0]?.personMiddleName ?? ''
                }
                onChange={formik.handleChange}
                onBlur={e => {
                  formik.handleBlur(e);
                  const currentValue = formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.requester?.[0]?.personMiddleName ?? '';
                  if (e.target.value !== currentValue) {
                      setUnsavedChanges();
                  }
                }}
                error={
                  formik.touched.requester?.[0]?.personMiddleName &&
                  Boolean(formik.errors.requester?.[0]?.personMiddleName)
                }
                helperText={
                  formik.touched.requester?.[0]?.personMiddleName &&
                  formik.errors.requester?.[0]?.personMiddleName
                }
                size='small'
                variant='outlined'
                disabled={!formik.status.editing}
                label='Requester Middle Name'
                fullWidth
                // required
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={4}>
            <Autocomplete
              id='individualPerson'
              name='individualPerson'
              options={informantOptions}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.requester != null
                  ? informantOptions?.find(
                      option =>
                        option.personSeq?.toLowerCase() ===
                        formik.values?.caseRecords?.chargeableRequests?.[
                          currentChargeableRequestIndex
                        ]?.requester?.[0]?.personSeq?.toLowerCase()
                    ) || null
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue?.personSeq === 'new') {
                  handleNewContactClick(event);
                } else {
                  handleRequesterChange(newValue);
                }
              }}
              getOptionLabel={option =>
                `${option.personLastName || ''}${
                  option.personLastName && option.personFirstName ? ', ' : ''
                }${option.personFirstName || ''}`
              }
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={
                    option.personSeq === 'new'
                      ? {
                          fontStyle: 'italic',
                          color: '#5C72FF',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }
                      : {}
                  }
                >
                  {option.personSeq === 'new'
                    ? 'Add New Contact'
                    : `${option.personLastName || ''}${
                        option.personLastName && option.personFirstName ? ', ' : ''
                      }${option.personFirstName || ''}${
                        option.relship ? ` (${option.relship})` : ''
                      }`}
                </li>
              )}
              renderInput={params => <TextField {...params} label='Requested By' />}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <AddressData
            handleChange={data =>
              formik?.handleChange({
                target: {
                  value: data,
                  name: `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress`,
                },
              })
            }
            addresses={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.requesterAddress || []
            }
            title='Address'
            showAllTypes={true}
            // disabled={
            //   formik.values.caseRecords?.chargeableRequests[
            //     currentChargeableRequestIndex
            //   ]?.requesterAddress?.[0]?.addressTypeSeq?.toLowerCase() !== AddressTypeOptions.OTHER
            // }
          />
        </Grid>

        <Grid item xs={12}>
          <ContactsView
            contacts={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.requesterContactInfo || []
            }
            handleChange={data => handleContactChange(data)}
          />
        </Grid>

        <Grid item xs={12}>
          <br />
          <CrudDataGrid
            rows={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.requestedDocuments ?? []
            }
            setRows={data => {
              formik.setFieldValue(
                `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestedDocuments`,
                data
              );
            }}
            initialColumns={requestedDocumentsColumns}
            createEntry={createRequestedDocument}
            updateEntry={updateRequestedDocument}
            deleteEntry={deleteRequestedDocument}
            title='Requested Document'
            idcolumn='requestedDocumentListSeq'
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel>How Request Was Submitted:</FormLabel>
            <RadioGroup
              row
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestMethod`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestMethod`}
              // Using string interpolation here because of odd timing error.  I'm assuming that the
              // code maps the Radio button values before formik.values is updated (so it's still
              // just an empty array) and it won't rerender when formik.values updates like other
              // components will.  Using string interpolation appears to force a rerender, and then
              // the correct value is displayed.  There might be a more elegant solution for this,
              // but I wasn't able to figure it out
              value={`${formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]?.requestMethod?.optionSeq}`}
              onChange={handleRequestMethodChange}
            >
              {requestMethodOptions.map(method => (
                <FormControlLabel
                  key={method.optionSeq.toUpperCase()}
                  value={method.optionSeq.toUpperCase()}
                  control={<Radio />}
                  disabled={!formik.status.editing}
                  label={method.optionName}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Is Legal Review Required?:</FormLabel>
            <RadioGroup
              row
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isLegalReviewRequired`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isLegalReviewRequired`}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.isLegalReviewRequired ?? false
              }
              onChange={handleIsLegalReviewRequiredChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label='Yes'
                disabled={!formik.status.editing}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label='No'
                disabled={!formik.status.editing}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.isLegalReviewRequired && (
          <Grid item xs='auto'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`}
                name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`}
                value={dayjs(
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.clearingHoldDateTime
                )}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                slots={{
                  actionBar: ActionList,
                }}
                onChange={updatedValue => {
                  formik.setFieldValue(
                    `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`,
                    updatedValue
                  );
                  // setClearingHoldDateTime(updatedValue);
                  // formik.setStatus(previousStatus => ({
                  //   ...previousStatus,
                  //   clearingHoldDateTimeUpdated: previousStatus.clearingHoldDateTimeUpdated.map(
                  //     (value, index) =>
                  //       index ===
                  //       previousStatus.clearingHoldDateTimeUpdated.indexOf(
                  //         currentChargeableRequestIndex
                  //       )
                  //         ? true
                  //         : value
                  //   ),
                  // }));
                  updateClearingHoldStatus(currentChargeableRequestIndex);
                  setUnsavedChanges();
                }}
                timezone='UTC'
                // disableFuture
                label='Hold Cleared On'
                disabled={!formik.status.editing}
                size='small'
                format='L HH:mm'
              />
            </LocalizationProvider>
          </Grid>
        )}

        {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.isLegalReviewRequired && (
          <Grid item xs={12} sm>
            <TextField
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].holdClearedBy`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].holdClearedBy`}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.holdClearedBy ?? ''
              }
              onChange={formik.handleChange}
              onBlur={e => {
                formik.handleBlur(e);
                const currentValue = formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.holdClearedBy ?? '';
                if (e.target.value !== currentValue) {
                    setUnsavedChanges();
                }
              }}
              error={formik.touched.holdClearedBy && Boolean(formik.errors.holdClearedBy)}
              helperText={formik.touched.holdClearedBy && formik.errors.holdClearedBy}
              variant='outlined'
              label='Hold Cleared By'
              disabled={!formik.status.editing}
              size='small'
              // required
            />
          </Grid>
        )}
      </Grid>

      {isInformantDialogOpen && (
        <InformantDialog
          isInformantDialogOpen={isInformantDialogOpen}
          setIsInformantDialogOpen={setIsInformantDialogOpen}
          selectedInformant={selectedInformant}
          setSelectedInformant={setSelectedInformant}
          handleNewContactCallback={handleNewContactSubmit}
        />
      )}

      {openNotesDialog && (
        <ReqDocNotesDialog
          open={openNotesDialog}
          onClose={handleCloseDialog}
          onSave={handleSaveNotes}
          selectedRow={selectedRow}
        />
      )}
    </form>
  );
}
