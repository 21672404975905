import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Stack,
  styled,
  Tab,
  Tabs,
} from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useOptionsAPI } from '../../utils/api/useOptions.hook';
import { useAuth } from '../../utils/auth/AuthService';
import { Option } from '../Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { YourLiveryDataGrid } from './LiveryModules/YourLiveryDataGrid';
import { LegacyLiveryStatus, LiveryCase } from './LiveryModules/livery.api';

export interface LiveryCaseWithStatusOptions extends LiveryCase {
  liveryStatusOptions: Option[];
}

const MapWrapper = styled(Box)({
  height: 'calc(15vh + 48px)',
  width: '100%',
  position: 'relative',
  zIndex: 0,
});

const TabsWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: '-48px',
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.85)' : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(5px)',
  zIndex: 1,
}));

export function LiveryCheckinView() {
  const [cases, setCases] = useState<LiveryCaseWithStatusOptions[]>([]);
  const [casesLoading, setCasesLoading] = useState(true);
  const [showLocationAlert, setShowLocationAlert] = useState(true);
  const [locationStatus, setLocationStatus] = useState<'prompt' | 'granted' | 'denied'>('prompt');
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  const { options, loading, error } = useOptionsAPI('getliverystatusoptions');

  const optionsWithCompletedOption: Option[] = [
    ...options,
    {
      optionName: 'Completed',
      optionSeq: LegacyLiveryStatus.COMPLETED,
      isActive: true,
    },
  ];
  const auth = useAuth();

  async function fetchCases() {
    try {
      setCasesLoading(true);
      const liverycases = await getLiveryCasesForUser(auth.user?.userSeq!, auth.user?.accessToken!);

      setCases(
        liverycases.map(c => {
          const isAssigned = c.activityLog.find(
            a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED
          );
          const isAccepted = c.activityLog.find(
            a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ACCEPTED
          );
          const isDeferred = c.activityLog.find(
            a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.DEFERRED
          );
          const isClosed = c.activityLog.find(
            a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.FORCE_LIVERY_CLOSED
          );

          const isCancelled = c.activityLog.find(
            a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.CANCELLED
          );

          const shouldAppear = !isClosed && !isDeferred && !isCancelled;

          function determineStatus() {
            if (shouldAppear) {
              if (isAssigned && !isAccepted) {
                return 'assigned';
              }

              if (isAssigned && isAccepted) {
                return 'accepted';
              }
            }

            if (isDeferred) {
              return 'deferred';
            }

            if (isCancelled) {
              return 'cancelled';
            }

            return 'other';
          }

          return {
            ...c,
            decedentFullName: c.decedentFullName?.toUpperCase() ?? 'UNKNOWN',
            setAt: new Date(c.setAt),
            liveryStatusOptions: optionsWithCompletedOption,
            status: determineStatus(),
            refetchCases: fetchCases,
          };
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setCasesLoading(false);
    }
    return;
  }

  useEffect(() => {
    if (options.length > 0 && !loading) {
      fetchCases();
    }
  }, [options, loading]);

  const [value, setValue] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: 'status',
        operator: 'equals',
        value: 'assigned',
      },
    ],
  });

  useEffect(() => {
    if (value === 0) {
      setFilterModel({
        items: [
          {
            field: 'status',
            operator: 'equals',
            value: 'assigned',
          },
        ],
      });
    } else if (value === 1) {
      setFilterModel({
        items: [
          {
            field: 'status',
            operator: 'equals',
            value: 'accepted',
          },
        ],
      });
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        if (result.state === 'granted') {
          handleGrantLocation();
        } else if (result.state === 'prompt') {
          setLocationStatus('prompt');
        } else {
          setLocationStatus('denied');
        }
      });
    } else {
      setLocationStatus('denied');
    }
  }, []);

  const handleGrantLocation = () => {
    if (navigator.geolocation) {
      setIsLocationLoading(true);
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          console.log('Location granted:', latitude, longitude);
          setUserLocation([latitude, longitude]);
          setLocationStatus('granted');
          setIsLocationLoading(false);
        },
        error => {
          console.error('Error getting location:', error);
          setLocationStatus('denied');
          setIsLocationLoading(false);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      setLocationStatus('denied');
    }
  };

  return (
    <Stack height='100%' position='relative'>
      {locationStatus === 'prompt' && (
        <Alert elevation={2} severity='info'>
          <AlertTitle>Location required</AlertTitle>
          <Stack gap={2}>
            Please grant access to your current location
            <Button
              disabled={isLocationLoading}
              color='inherit'
              variant='contained'
              size='small'
              onClick={handleGrantLocation}
              startIcon={isLocationLoading && <CircularProgress color='info' />}
              fullWidth
            >
              {isLocationLoading
                ? 'Retrieving current location'
                : 'Grant Access to Current Location'}
            </Button>
          </Stack>
        </Alert>
      )}

      {userLocation && (
        <MapWrapper>
          <MapContainer center={userLocation} zoom={18} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {/* <Marker position={userLocation}>
              <Popup>Your current location</Popup>
            </Marker> */}
          </MapContainer>
        </MapWrapper>
      )}

      <Collapse in={Boolean(userLocation)}>
        <TabsWrapper>
          <Tabs value={value} onChange={handleChange} textColor='primary' indicatorColor='primary'>
            <Tab label='Assigned' {...a11yProps(0)} />
            <Tab label='In-Progress' {...a11yProps(1)} />
          </Tabs>
        </TabsWrapper>
      </Collapse>

      <Collapse in={!Boolean(userLocation)}>
        <Tabs value={value} onChange={handleChange} textColor='primary' indicatorColor='primary'>
          <Tab label='Assigned' {...a11yProps(0)} />
          <Tab label='In-Progress' {...a11yProps(1)} />
        </Tabs>
      </Collapse>

      <Box component='div' flexGrow={1} overflow='auto'>
        <CustomTabPanel value={value} index={0} />
        <CustomTabPanel value={value} index={1} />

        <YourLiveryDataGrid
          loading={casesLoading}
          liveryCases={cases}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          refetchCases={fetchCases}
        />
      </Box>
    </Stack>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`your-livery-${index}`}
      aria-labelledby={`your-livery-${index}`}
      {...other}
    >
      {value === index && (
        <Box component='div' sx={{ p: 0, m: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `your-livery-${index}`,
    'aria-controls': `your-livery-${index}`,
  };
}

const { VITE_API_URL } = import.meta.env;

const getLiveryCasesForUser = async (
  userSeq: string,
  accessToken: string
): Promise<LiveryCase[]> => {
  try {
    const response = await fetch(VITE_API_URL + `livery/getliverycasesforuser?userSeq=${userSeq}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  } finally {
  }
};
