import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Popper,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode == 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import AccessDenied from '../../../components/AdministrationModules/AccessDenied';
import MainCard from '../../../components/AdministrationModules/MainCard';
import authHeader from '../../../services/auth-header';
import { useAuth } from '../../../utils/auth/AuthService.tsx';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({});

const columns = [
  {
    field: 'fullName',
    headerName: 'POC',
    // headerClassName: 'super-app-theme--header',
    type: 'text',
    minWidth: 200,
    align: 'left',
    flex: 5,
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    // headerClassName: 'super-app-theme--header',
    type: 'text',
    minWidth: 200,
    align: 'left',
    flex: 5,
  },
  {
    field: 'startDateTime',
    headerName: 'Start Date/ Time',
    // headerClassName: 'super-app-theme--header',
    type: 'text',
    minWidth: 100,
    align: 'left',
    flex: 5,
  },
  {
    field: 'endDateTime',
    headerName: 'End Date/ Time',
    // headerClassName: 'super-app-theme--header',
    type: 'text',
    minWidth: 100,
    align: 'left',
    flex: 5,
  },
  {
    field: 'pocOnCounty',
    headerName: 'Jurisdiction',
    // headerClassName: 'super-app-theme--header',
    type: 'text',
    minWidth: 200,
    align: 'left',
    flex: 5,
  },
];

const POCView = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const pocPermissions = permissions?.['POC'];
  const today = dayjs();
  const nextMonthDate = today.add(1, 'month');
  const previousMonthDate = today.subtract(1, 'month');
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllcall, setCheckAllcall] = useState(false);
  const [dropdownopen, setDropdownopen] = useState(false);
  const [dropdownopencall, setDropdownopencall] = useState(false);
  const [countyDropUser, setCountyDropUser] = useState();
  const [countydata, setCountydata] = useState([]);

  const [physiciancall, setPhysiciancall] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagsAll, setSelectedTagsAll] = useState([]);
  const [savedatamsg, setSavedatamsg] = useState('');
  const [userdata, setUserdata] = useState();
  const [setTouchedDep, setSetTouchedDep] = useState([]);
  const [openapi, setOpenapi] = useState(false);
  const [open, setOpen] = useState(false);
  const Close = () => setOpen(false);
  const [opensaveapi, setOpensaveapi] = useState(false);
  const [showApi, setShowApi] = useState();
  const [countyDropDown, setCountyDropDown] = useState([]);
  const [dataGrids, setDataGrids] = useState([]);
  const [startDate, setStartDate] = useState(today);
  const [startDatecall, setStartDatecall] = useState(previousMonthDate);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedTagscall, setSelectedTagscall] = useState([]);
  const [endDate, setEndDate] = useState(nextMonthDate);
  const [endDatecall, setEndDatecall] = useState(today);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [datekey, setDatekey] = useState(false);
  const [countyNameSequence, setCountyNameSequence] = useState({});
  const [pocData, setPocData] = useState([]);
  console.log('setTouchedDep', setTouchedDep);
  var userSeqVal = setTouchedDep?.county
    ?.split(',')
    ?.filter(str => /^\d+$/.test(str))
    ?.map(str => parseInt(str));

  const [phyCounty, setPhyCounty] = useState();

  useEffect(() => {
    const state = [];
    const cname = [];

    for (let i = 0; i < userSeqVal?.length; i++) {
      const code = userSeqVal[i].toString().padStart(2, '0'); // pad single-digit codes with zero
      const match = countydata?.find(item => item.code.slice(-2) === code); // ignore first zero while matching

      if (match) {
        state.push({
          label: match.label,
          value: match.value,
          code: match.code,
        });
        cname.push(match.label);
      }
    }

    if (cname != '' && cname != null) {
      setSelectedTagsAll(cname.filter(val => val !== ''));
    }
    setPhyCounty(state);
  }, [setTouchedDep]);

  var guid = '00000000-0000-0000-0000-000000000000';
  const filteredData = countydata
    ?.filter(item => countyDropDown?.includes(item.label))
    ?.map(item => ({
      pocSeq: guid,
      countySeq: item.value,
      status: 'Active',
    }));

  const selPocCounty = selectedRows[0]?.pocOnCounty;

  var selpocSeq = selectedRows[0]?.pocSeq;
  var SelstartDateTime = selectedRows[0]?.startDateTime;

  const filteredData1 = countydata?.filter(d => countyDropDown?.includes(d.label));
  const result = filteredData1?.map(d => ({
    pocSeq: '5978c37a-5511-4de7-936b-4a996951ab37',
    countySeq: d.value,
    status: 'Active',
  }));

  const [Skills, setSkills] = useState([]);
  const [SkillsSave, setSkillsSave] = useState([]);
  const [isEditFlag, setIsEditFlag] = useState(false);

  var matchingObject1 = dataGrids ? dataGrids?.find(obj => obj?.pocSeq === selpocSeq) : [];

  useEffect(() => {
    if (selectedRows?.length > 0) {
      const getStartDate = matchingObject1?.startDateTime ?? '';
      const getEndDate = matchingObject1?.endDateTime ?? '';

      if (matchingObject1?.length > 0) {
        setSelectedTags([]);
        for (const county of countydata) {
          for (const clickedUser of matchingObject1?.pocOnCounty) {
            if (clickedUser?.countySeq == county?.value) {
              setSelectedTags(prev => [...prev, county?.label]);
            }
          }
        }
      }
      setStartDate(getStartDate);
      setEndDate(getEndDate);
    }
  }, [matchingObject1, selectedRows]);

  useEffect(() => {
    if (selectedRows?.length > 0) {
      const Skills = selectedRows?.map(item => ({
        label: `${item.fullName}`,

        userSeq: `${item.userSeq}`,
        pocOnCounty: `${item.pocOnCounty}`,
      }));
      setSetTouchedDep(matchingObject1 ? Skills : selectedRows[0]?.fullName);

      setSkillsSave(Skills);
      const matchingCounties = selPocCounty
        ?.map(county => {
          return countydata
            ?.filter(countyData => {
              return countyData.value === county.countySeq;
            })
            ?.map(match => match.label);
        })
        ?.flat();

      setSelectedTagsAll(matchingCounties);
    }
  }, [selectedRows]);

  useEffect(() => {
    const Skills = physiciancall?.map(item => ({
      label: `${item.firstname}, ${item.lastname}`,

      value: `${item.userseq}`,
      county: `${item.county}`,
    }));
    setSkills(Skills);
    setSkillsSave(Skills);
  }, [physiciancall]);

  var guid = '00000000-0000-0000-0000-000000000000';

  const FormikAutocompletecall = ({
    textFieldProps,
    selectedOption,
    setSelectedOption,
    ...props
  }) => {
    const handleSelect = (value, index) => {
      setSelectedOption(options[index]);
    };

    return (
      <Autocomplete
        {...props}
        onChange={(value, index) => {
          setIsEditFlag(false);
          setSetTouchedDep(index);
        }}
        getOptionSelected={(item, current) => item.value === current.value}
        value={matchingObject1 ? matchingObject1?.fullName : setTouchedDep?.label}
        renderInput={props => <TextField {...props} label='Physician On Call' required />}
      />
    );
  };

  const [setTouchedDepcall, setSetTouchedDepcall] = useState();

  const FormikAutocompletepoc = ({
    textFieldProps,
    selectedOption,
    setSelectedOption,
    ...props
  }) => {
    const handleSelect = (value, index) => {
      setSelectedOption(options[index]);
    };
    return (
      <Autocomplete
        {...props}
        onChange={(value, index) => setSetTouchedDepcall(index)}
        getOptionSelected={(item, current) => item.value === current.value}
        value={setTouchedDepcall}
        renderInput={props => <TextField {...props} label='Physician On Call' />}
      />
    );
  };

  const handleStartDateChange = date => {
    setStartDateError(false);
    // const newDate = new Date();
    setStartDate(date);
    // setEndDate(dayjs(date).add(30, 'day'));
    // setEndDate(null);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleEndDateChange2 = date => {
    setStartDatecall(date);
    setEndDateError(false);
  };
  const handleEndDateChange4 = date => {
    setEndDatecall(date);
  };

  const initialValues = {};

  useEffect(() => {
    getCounty();
    getuserdata();
  }, [selectedRows]);

  const getCounty = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_URL}GetAllCounty`);
      setCountyDropUser(response.data.data);
      let newData = [];
      let county = {};
      response.data.data?.map(data => {
        data.children?.forEach(children => {
          newData.push({
            label: children.countyName,
            value: children.countySeq,
            code: children.code,
          });
          county = { ...county, [children.countyName]: children.countySeq };
        });
      });
      setCountyNameSequence(county);
      setCountydata(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  const allCounty = countydata; //state?.userData?.county_name; //useSelector((state) => state?.userData?.county_name);
  var allCountyName = allCounty?.map(value => value.label);
  let countynew = allCounty?.filter(value =>
    selectedTagscall.includes(value.label) ? value.value : ''
  );

  var arr = [];
  for (var i = 0; i < countynew?.length; ++i) {
    for (var j = 0; j < selectedTagscall?.length; ++j) {
      if (countynew[i].label == selectedTagscall[j]) {
        arr.push(countynew[i].value);
      }
    }
  }

  const checkAllChange = event => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      let newdata = [...selectedTagsAll];

      setSelectedTags(newdata);
    } else {
      setSelectedTags([]);
    }
  };

  const handleClickAway = e => {
    setDropdownopen(false);
  };

  const checkAllChangecall = event => {
    setCheckAllcall(event.target.checked);

    if (event.target.checked) {
      let newdata = [...allCountyName];
      delete newdata[0];
      setSelectedTagscall(newdata);
    } else {
      setSelectedTagscall([]);
    }
  };

  const handleClickAwaycall = e => {
    setDropdownopencall(false);
  };

  const getuserdata = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_URL}GetUserCountyDetails`, {
        headers: authHeader(),
      });
      let filtercounty = response.data.data?.map(item => item.county.filter(value => value !== ''));
      let filteruserdata = response.data.data?.filter(
        value =>
          value.isHideDropdownPopUp === false &&
          value.status !== 'Inactive' &&
          value.roleseq.length !== 0 &&
          filtercounty.length !== 0 &&
          value.isMedicalExaminer === true
      );

      setUserdata(response.data.data);

      setPhysiciancall(filteruserdata);
    } catch (error) {
      console.log('error', error);
    }
  };

  const rows = dataGrids
    ? dataGrids?.map(row => {
        const getTimeSearch = new Date(row?.startDateTime)?.getTime();
        const getUpdateDateSearch = getTimeSearch + 55 * 60 * 60 * 100;
        const getStartDateSearch = new Date(getUpdateDateSearch);
        const getStartios = getStartDateSearch
          .toISOString()
          .slice(0, -8)
          .replaceAll('-', '/')
          .replace('T', ' ');
        const dateTimeParts = getStartios.split(' ');
        const dateComponents = dateTimeParts[0].split('/');
        var newDate = dateComponents[1] + '/' + dateComponents[2] + '/' + dateComponents[0];
        var newDateTime = newDate + ' ' + dateTimeParts[1];

        const getEndTimeSearch = new Date(row?.endDateTime)?.getTime();
        const updateEndTimeSerach = getEndTimeSearch + 55 * 60 * 60 * 100;
        const getEndDateSearch = new Date(updateEndTimeSerach);
        const getEndios = getEndDateSearch
          .toISOString()
          .slice(0, -8)
          .replaceAll('-', '/')
          .replace('T', ' ');
        var dateTimeend = getEndios.split(' ');
        var enddateComponents = dateTimeend[0].split('/');
        var newendDate =
          enddateComponents[1] + '/' + enddateComponents[2] + '/' + enddateComponents[0];
        var newrndDateTime = newendDate + ' ' + dateTimeend[1];

        const getCreatedDate = row?.createdOn?.toLocaleString()?.split(',');

        var newUpdatedCreatedTime = getCreatedDate?.[0]?.substr(0, 10)?.split('-');
        const addForwardSlashToDate =
          newUpdatedCreatedTime?.[1] +
          '/' +
          newUpdatedCreatedTime?.[2] +
          '/' +
          newUpdatedCreatedTime?.[0];
        const formatTime = getCreatedDate?.[0]?.split('T');
        const furtherSplit = formatTime?.[1]?.split(':');
        const finalFormatTime = furtherSplit?.[0] + ':' + furtherSplit?.[1];

        const createdDateTimeSplit = row?.createdOn?.toLocaleString()?.split(', ');
        const timeSplit = createdDateTimeSplit?.[1]?.split(':');
        const formattedCreateOnTime = addForwardSlashToDate + ' ' + finalFormatTime;

        return {
          id: row?.pocSeq,
          fullName: row?.fullName,
          createdOn: formattedCreateOnTime,
          startDateTime: getTimeSearch ? newDateTime : null,
          endDateTime: getEndTimeSearch ? newrndDateTime : null,

          pocOnCounty: countydata
            ?.map(v => {
              return row?.pocOnCounty
                ?.map(p => {
                  if (v.value === p.countySeq && v.label !== '') {
                    return ' ' + v.label;
                  }
                })

                .filter(name => name !== undefined);
            })

            .filter(arr => arr?.length > 0),
        };
      })
    : [];

  useEffect(() => {
    handleSearchdata();
  }, []);

  const handleSearchdata = () => {
    const searchData = async () => {
      const getTime = startDatecall?.$d?.getTime();
      const getUpdateDate = getTime + 55 * 60 * 60 * 100;
      const getStartDate = new Date(getUpdateDate);
      const getEndTime = endDatecall?.$d?.getTime();
      const updateEndTime = getEndTime + 55 * 60 * 60 * 100;
      const getEndDate = new Date(updateEndTime);
      let Buttonsearch = {
        fullName: setTouchedDepcall?.label,
        userSeq: setTouchedDepcall?.value,
        countySeqs: arr,
        startDate: getTime ? getStartDate?.toISOString() : null,
        endDate: getEndTime ? getEndDate?.toISOString() : null,
      };

      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}SearchPOCData`,
          Buttonsearch,
          { headers: authHeader() }
        );
        setDataGrids(response.data.data);
      } catch (error) {
        console.log('error', error);
      }
    };
    searchData();
  };

  function clearDate() {
    setSelectedTagscall([]);
    setSetTouchedDepcall('');
    setStartDatecall('');
    setEndDatecall('');
    setDatekey(k => !k);
  }

  function clearSaveDate() {
    matchingObject1 = [];
    setSelectedRows([]);
    setSetTouchedDep([]);
    setCountyDropDown([]);
    setStartDate('');
    setEndDate('');
    setDatekey(k => !k);
    setSkills(Skills);
    setSkillsSave(SkillsSave);
  }

  const { user } = useAuth();
  const userSeq = user.userSeq;

  const SaveDataButton = async (e, values) => {
    const getTimesave = startDate?.$d?.getTime();
    const getUpdateDatesave = getTimesave + 55 * 60 * 60 * 100;
    const getStartDatesave = new Date(getUpdateDatesave);
    const getEndTimesave = endDate?.$d?.getTime();
    const updateEndTimesave = getEndTimesave + 55 * 60 * 60 * 100;
    const getEndDatesave = new Date(updateEndTimesave);

    const getTimeUpdate = dayjs(startDate)?.$d?.getTime();
    const getUpdateDateUpdate = getTimeUpdate + 55 * 60 * 60 * 100;
    const getStartDateUpdate = new Date(getUpdateDateUpdate);
    const getEndTimeUpdate = dayjs(endDate)?.$d?.getTime();
    const updateEndTimeUpdate = getEndTimeUpdate + 55 * 60 * 60 * 100;
    const getEndDateUpdate = new Date(updateEndTimeUpdate);
    if (!startDate) {
      setStartDateError(true);
    }
    if (!endDate) {
      setEndDateError(true);
    }

    if (
      (!setTouchedDep || setTouchedDep.length === 0) &&
      (!countyDropDown || selectedTags.length === 0) &&
      (!startDate || startDate === 0) &&
      (!endDate || endDate.length === 0)
    ) {
      setShowApi(' POC, Jurisdiction, StartDate, EndDate fields are required');
      setOpensaveapi(true);
    } else if (setTouchedDep?.length === 0) {
      setShowApi('POC is required');

      setOpensaveapi(true);
    } else if (countyDropDown?.length === 0) {
      setShowApi('Jurisdiction is required');

      setOpensaveapi(true);
    } else if (!startDate || startDate?.length === 0) {
      setShowApi('Start Date is required');

      setOpensaveapi(true);
    } else if (!endDate || endDate?.length === 0) {
      setShowApi('End Date is required');

      setOpensaveapi(true);
    } else {
      if (e.target.value == 'Save') {
        const getCreatedOnTime = new Date()?.getTime();
        const getUpdatedCreateOntime = getCreatedOnTime + 55 * 60 * 60 * 100;
        const updatedCreatedOn = new Date(getUpdatedCreateOntime);
        var data = [
          {
            pocSeq: selpocSeq ? selpocSeq : '00000000-0000-0000-0000-000000000000',
            fullName: setTouchedDep?.label,
            userSeq: setTouchedDep?.value,
            pocOnCounty: mapCounty(),
            startDateTime: getTimesave ? getStartDatesave?.toISOString() : null,
            endDateTime: getEndTimesave ? getEndDatesave?.toISOString() : null,
            createdOn: updatedCreatedOn,
            createdByUserSeq: userSeq,
            lastUpdatedOn: new Date(),
            lastUpdatedByUserSeq: '00000000-0000-0000-0000-000000000000',
            status: 'Active',
          },
        ];

        try {
          const response = await axios.post(
            `${import.meta.env.VITE_API_URL}SaveMasterData?UserSeq=${userSeq}&tableName=${'poc'}`,
            data,
            { headers: authHeader() }
          );
          setOpenapi(true);
          setSavedatamsg(response.data.message);
          setDataGrids([...dataGrids, response.data.data[0]]);
        } catch (error) {
          console.log('error', error);
        }
      } else {
        let Buttonsave = {
          fullName: setTouchedDep.fullName,
          userSeq: setTouchedDep.value,
          county: countyDropDown,
        };

        if (Buttonsave) {
          let dates = new Date();
          const getCreatedOnTime = new Date()?.getTime();
          const getUpdatedCreateOntime = getCreatedOnTime + 55 * 60 * 60 * 100;
          const updatedCreatedOn = new Date(getUpdatedCreateOntime);
          const id = '00000000-0000-0000-0000-000000000000';

          var data = [
            {
              pocSeq: selpocSeq ? selpocSeq : '00000000-0000-0000-0000-000000000000',
              fullName: matchingObject1?.fullName,
              userSeq: matchingObject1?.userSeq ?? id,
              pocOnCounty: mapCounty(),
              // startDateTime: matchingObject1?.startDateTime,
              // endDateTime: matchingObject1?.endDateTime,
              startDateTime: getTimeUpdate ? getStartDateUpdate?.toISOString() : null,
              endDateTime: getEndTimeUpdate ? getEndDateUpdate?.toISOString() : null,
              createdOn: updatedCreatedOn,
              createdByUserSeq: userSeq,
              lastUpdatedOn: new Date(),
              lastUpdatedByUserSeq: '00000000-0000-0000-0000-000000000000',
              status: 'Active',
            },
          ];

          const saveRoles = async () => {
            try {
              const response = await axios.post(
                `${
                  import.meta.env.VITE_API_URL
                }SaveMasterData?UserSeq=${userSeq}&tableName=${'poc'}`,
                data,
                { headers: authHeader() }
              );
              setOpenapi(true);

              setSavedatamsg(response.data.message);

              let Buttonsearch = {
                countySeqs: [],
                startDate: null,
                endDate: null,
              };
              const getAllPOC = await axios.post(
                `${import.meta.env.VITE_API_URL}SearchPOCData`,
                Buttonsearch,
                { headers: authHeader() }
              );
              setDataGrids(getAllPOC.data.data);
            } catch (error) {
              console.log('error', error);
            }
          };

          await saveRoles();
        }
      }
    }
  };

  useEffect(() => {
    if (!isEditFlag) {
      setCountyDropDown(selectedTagsAll);
      setIsEditFlag(false);
    }
  }, [selectedTagsAll]);

  const mapCounty = () => {
    const pocCounty = [];
    const guid = '00000000-0000-0000-0000-000000000000';

    for (const overAllCounty of selectedTagsAll) {
      if (countyDropDown?.includes(overAllCounty)) {
        pocCounty.push({
          countySeq: countyNameSequence?.[overAllCounty] ?? guid,
          pocSeq: selectedRows?.[0]?.pocSeq ?? guid,
          status: 'Active',
        });
      } else {
        pocCounty.push({
          countySeq: countyNameSequence?.[overAllCounty] ?? guid,
          pocSeq: selectedRows?.[0]?.pocSeq ?? guid,
          status: 'InActive',
        });
      }
    }
    setPocData(pocCounty);
    return pocCounty;
  };

  if (!pocPermissions?.view) {
    return <AccessDenied />;
  } else {
    return (
      <>
        <MainCard sx={{ border: 'none', boxShadow: 'none', bgcolor: 'transparent' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange
            onSubmit={values => {}}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, errors, touched, handleSubmit }) => (
              <Form>
                <Box
                // sx={{
                //   background: '#f4f4f4',
                //   padding: '16px',
                //   borderRadius: '12px',
                // }}
                >
                  <Box
                  // sx={{
                  //   flexGrow: 1,
                  //   fontSize: '12px',
                  //   fontFamily:
                  //     'Roboto , Helvetica , Arial , sans-serif !important',
                  // }}
                  >
                    <Typography
                      variant='h5'
                      component='h1'
                      // color='#349cec'
                      sx={{ fontWeight: 'bold' }}
                    >
                      Physician On Call
                    </Typography>

                    <br />

                    <Grid container spacing={2}>
                      <Grid item lg={3} xs={12} md={12} sm={12}>
                        <FormControl
                          error
                          required
                          component='fieldset'
                          variant='standard'
                          sx={{
                            minWidth: '100%',
                          }}
                        >
                          {/* {userdata ? ( */}
                          <Field
                            name='skills'
                            component={FormikAutocompletecall}
                            label='Skills'
                            options={SkillsSave || []}
                            textFieldProps={{
                              fullWidth: true,
                              margin: 'normal',
                              variant: 'outlined',
                            }}
                          />
                          {/* ) : (
                            ''
                          )} */}
                        </FormControl>
                      </Grid>

                      <Grid item lg={3} xs={12} md={12} sm={12}>
                        <FormControl
                          error
                          component='fieldset'
                          variant='standard'
                          sx={{ minWidth: '100%' }}
                        >
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <Box>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                limitTags={9}
                                id='checkboxes-tags-demo'
                                options={selectedTagsAll || []}
                                value={countyDropDown ?? []}
                                open={dropdownopen}
                                onChange={(event, newValue, reason, v) => {
                                  if (reason === 'selectOption') {
                                    setCountyDropDown(newValue);
                                  } else if (reason === 'removeOption') {
                                    setCheckAll(false);

                                    setCountyDropDown(newValue);
                                  } else if (reason === 'clear') {
                                    setCountyDropDown([]);

                                    setCheckAll(false);
                                  }
                                }}
                                onOpen={() => {
                                  setDropdownopen(true);
                                }}
                                PopperComponent={param => (
                                  <PopperStyledComponent {...param}>
                                    <Box {...param} />
                                    <Divider />{' '}
                                  </PopperStyledComponent>
                                )}
                                getOptionLabel={option => option}
                                renderOption={(props, option, { selected }) => (
                                  <>
                                    <li {...props}>
                                      {option === 'Select All' ? (
                                        <Checkbox
                                          checked={checkAll}
                                          onChange={checkAllChange}
                                          id='check-all'
                                          sx={{ marginRight: '8px' }}
                                          onMouseDown={e => e.preventDefault()}
                                        />
                                      ) : (
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected || checkAll}
                                        />
                                      )}
                                      {option}
                                    </li>
                                  </>
                                )}
                                renderInput={params => (
                                  <TextField {...params} label='Jurisdiction' />
                                )}
                              />
                            </Box>
                          </ClickAwayListener>
                        </FormControl>
                      </Grid>

                      <Grid item lg={6} xs={12} md={12} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid xs={12} container spacing={2}>
                            <Grid item lg={6} xs={12} md={12} sm={12}>
                              <DateTimePicker
                                sx={{
                                  minWidth: '100%',
                                }}
                                label='Start Date'
                                value={dayjs(startDate)}
                                onChange={handleStartDateChange}
                                inputVariant='outlined'
                                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                slots={{
                                  actionBar: ActionList,
                                }}
                                dateFormat='yyyy/MM/dd'
                                clearable
                                // disablePast
                                ampm={false}
                                // defaultValue={today}
                              />
                            </Grid>

                            <Grid item lg={6} xs={12} md={12} sm={12}>
                              <DateTimePicker
                                sx={{
                                  minWidth: '100%',
                                }}
                                label='End Date'
                                value={dayjs(endDate)}
                                onChange={handleEndDateChange}
                                inputVariant='outlined'
                                dateFormat='yyyy/MM/dd'
                                clearable
                                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                slots={{
                                  actionBar: ActionList,
                                }}
                                ampm={false}
                                // disablePast
                                minDate={startDate}
                                maxDate={
                                  //startDate &&
                                  dayjs(startDate).add(30, 'day')
                                }
                              />
                            </Grid>
                          </Grid>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <br />
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={1} xs={12} md={12} sm={12}>
                        <Button
                          fullWidth
                          type='submit'
                          variant='contained'
                          color='primary'
                          value={matchingObject1 ? 'Update' : 'Save'}
                          onClick={e => SaveDataButton(e)}
                          disabled={!pocPermissions?.edit && !pocPermissions?.add}
                          // sx={{ marginRight: '0px' }}
                          // sx={{ padding: '7px' }}
                        >
                          {matchingObject1 ? 'Update' : 'Save'}
                        </Button>
                      </Grid>
                      <Grid item lg={1} xs={12} md={12} sm={12}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          onClick={clearSaveDate}
                          fullWidth
                        >
                          Clear
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange
            onSubmit={values => {}}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, errors, touched, handleSubmit }) => (
              <Form>
                <Box
                  sx={{
                    // background: '#f4f4f4',
                    // padding: '16px',
                    // borderRadius: '12px',
                    marginTop: '16px',
                  }}
                >
                  <Box
                  // sx={{
                  //   flexGrow: 1,
                  //   fontSize: '12px',
                  //   fontFamily:
                  //     'Roboto , Helvetica , Arial , sans-serif !important',
                  // }}
                  >
                    <Typography
                      variant='h5'
                      component='h1'
                      // color='#349cec'
                      sx={{ fontWeight: 'bold' }}
                    >
                      History
                    </Typography>

                    <br />

                    <Grid container spacing={2}>
                      <Grid item lg={3} xs={12} md={12} sm={12}>
                        <FormControl
                          error
                          required
                          component='fieldset'
                          variant='standard'
                          sx={{
                            minWidth: '100%',
                          }}
                        >
                          {/* {userdata ? ( */}
                          <Field
                            name='skills'
                            component={FormikAutocompletepoc}
                            label='Skills'
                            options={Skills || []}
                            textFieldProps={{
                              fullWidth: true,

                              margin: 'normal',

                              variant: 'outlined',
                            }}
                          />
                          {/* ) : (
                            ''
                          )} */}
                        </FormControl>
                      </Grid>

                      <Grid item lg={3} xs={12} md={12} sm={12}>
                        <FormControl
                          error
                          required
                          component='fieldset'
                          variant='standard'
                          sx={{ minWidth: '100%' }}
                        >
                          <ClickAwayListener onClickAway={handleClickAwaycall}>
                            <Box>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                limitTags={9}
                                id='checkboxes-tags-demo'
                                options={allCountyName || []}
                                value={selectedTagscall}
                                open={dropdownopencall}
                                onChange={(event, newValue, reason) => {
                                  if (reason === 'selectOption') {
                                    setSelectedTagscall(newValue);
                                  } else if (reason === 'removeOption') {
                                    setCheckAllcall(false);

                                    setSelectedTagscall(newValue);
                                  } else if (reason === 'clear') {
                                    setSelectedTagscall([]);

                                    setCheckAllcall(false);
                                  }
                                }}
                                onOpen={() => {
                                  setDropdownopencall(true);
                                }}
                                PopperComponent={param => (
                                  <PopperStyledComponent {...param}>
                                    <Box {...param} />
                                    <Divider />{' '}
                                  </PopperStyledComponent>
                                )}
                                getOptionLabel={option => option}
                                renderOption={(props, option, { selected }) => (
                                  <>
                                    <li
                                      {...props}
                                      style={{
                                        marginLeft:
                                          option == 'New Jersey' ||
                                          option == 'NJ State Case' ||
                                          option == 'MAiD Case'
                                            ? ''
                                            : '15px',
                                      }}
                                    >
                                      {option === 'Select All' ? (
                                        <Checkbox
                                          checked={checkAllcall}
                                          onChange={checkAllChangecall}
                                          id='check-all'
                                          sx={{ marginRight: '8px' }}
                                          onMouseDown={e => e.preventDefault()}
                                        />
                                      ) : (
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected || checkAllcall}
                                        />
                                      )}
                                      {option}
                                    </li>
                                  </>
                                )}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Jurisdiction'
                                    //   placeholder='Select Jurisdiction'
                                  />
                                )}
                              />
                            </Box>
                          </ClickAwayListener>
                        </FormControl>
                      </Grid>

                      <Grid item lg={6} xs={12} md={12} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid xs={12} container spacing={2}>
                            <Grid item lg={6} xs={12} md={12} sm={12}>
                              <DateTimePicker
                                sx={{
                                  minWidth: '100%',
                                }}
                                key={datekey}
                                label='Start Date'
                                value={dayjs(startDatecall)}
                                onChange={handleEndDateChange2}
                                inputVariant='outlined'
                                dateFormat='MM/dd/yyyy'
                                clearable
                                maxDate={endDatecall}
                                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                slots={{
                                  actionBar: ActionList,
                                }}
                                ampm={false}
                              />
                            </Grid>

                            <Grid item lg={6} xs={12} md={12} sm={12}>
                              <DateTimePicker
                                sx={{
                                  minWidth: '100%',
                                }}
                                key={datekey}
                                label='End Date'
                                value={dayjs(endDatecall)}
                                onChange={handleEndDateChange4}
                                inputVariant='outlined'
                                dateFormat='MM/dd/yyyy'
                                clearable
                                minDate={startDatecall}
                                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                slots={{
                                  actionBar: ActionList,
                                }}
                                ampm={false}
                              />
                            </Grid>
                          </Grid>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <br />
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={1} xs={12} md={12} sm={12}>
                        <Button
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            handleSearchdata();
                          }}
                          sx={{ padding: '7px' }}
                        >
                          <SearchIcon />
                        </Button>
                      </Grid>
                      <Grid item lg={1} xs={12} md={12} sm={12}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          onClick={clearDate}
                          fullWidth
                        >
                          Clear
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Divider sx={{ borderColor: '#555', m: 5, mt: 3, pb: 0, mx: 0 }} />
          <Box
          // sx={{
          //   width: '100%',
          //   '& .super-app-theme--header': {
          //     backgroundColor: '#2196f3',
          //   },
          // }}
          >
            <DataGridPremium
              // className='ViewTable'
              getRowId={row => row.id}
              rows={rows}
              columns={columns}
              columnHeaderHeight={50}
              rowHeight={30}
              pageSize={50}
              scrollbarSize={5}
              pagination
              slots={{
                pagination: CustomPagination,
              }}
              onRowSelectionModelChange={pocRow => {
                let pocUser = rows?.filter(row => row.id === pocRow[0]);
                selpocSeq = pocUser[0]?.id;
                matchingObject1 = Skills.find(
                  a =>
                    a.label ===
                    pocUser[0].fullName.split(',')[1].trim() +
                      ', ' +
                      pocUser[0].fullName.split(',')[0].trim()
                );
                setIsEditFlag(true);
                setSetTouchedDep(matchingObject1);
                setCountyDropDown(pocUser[0]?.pocOnCounty.flat());
                setStartDate(dayjs(pocUser[0]?.startDateTime));
                setEndDate(dayjs(pocUser[0]?.endDateTime));
                return;
              }}
              // sx={{
              //   width: '100%',
              //   height: 450,
              //   borderRadius: '6px',
              //   backgroundColor: 'white',
              //   boxShadow: '0px 0px 10px #ddd',
              //   mt: 2,
              // }}
            />
          </Box>
          <div className='modal'>
            <Dialog
              open={openapi}
              TransitionComponent={Transition}
              keepMounted
              onClose={Close}
              aria-describedby='alert-dialog-slide-description'
            >
              <DialogTitle></DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                  {savedatamsg}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <IconButton onClick={() => setOpenapi(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </Dialog>
          </div>
          <div className='modal'>
            <Dialog
              open={opensaveapi}
              TransitionComponent={Transition}
              keepMounted
              onClose={Close}
              aria-describedby='alert-dialog-slide-description'
            >
              <DialogTitle></DialogTitle>
              <DialogContent>
                <DialogContentText
                  id='alert-dialog-slide-description'
                  // style={{ color: 'red' }}
                >
                  {showApi}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <IconButton onClick={() => setOpensaveapi(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </Dialog>
          </div>
        </MainCard>
      </>
    );
  }
};

export default POCView;
