import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
/** Weird bug relating to yup types */
// @ts-ignore
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grow,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { useAuth } from '../../utils/auth/AuthService';

const validationSchema = yup.object({
  userid: yup.string().required('UserID is required'),
  password: yup.string().required('Password is required'),
});

export function LoginForm() {
  const auth = useAuth();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      userid: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      await auth.login({ username: values.userid, password: values.password });
    },
  });

  const inputsFilled = formik.values.userid.length > 0 && formik.values.password.length > 0;
  const isLoggingIn = auth.login_state.state === 'awaiting response';

  function onForgotPassword() {
    auth.authDispatch({ type: 'SET_LOGIN_ERROR_MESSAGE', payload: null });
    navigate('/login/forgot-password');
  }

  return (
    <>
      <Snackbar
        open={auth.authState?.logout_state?.displayAlert}
        onClose={() =>
          auth.authDispatch({
            type: 'SET_LOGOUT_MESSAGE_STATE',
            payload: {
              displayAlert: false,
              shouldDismiss: true,
              dismissAfterSeconds: 5,
              message: '',
            },
          })
        }
        TransitionComponent={Grow}
        autoHideDuration={
          auth?.authState?.logout_state?.shouldDismiss
            ? 1000 * Number(auth.authState?.logout_state?.dismissAfterSeconds)
            : null
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity='info'
          onClose={() =>
            auth.authDispatch({
              type: 'SET_LOGOUT_MESSAGE_STATE',
              payload: {
                displayAlert: false,
                shouldDismiss: true,
                dismissAfterSeconds: 5,
                message: '',
              },
            })
          }
        >
          {auth.authState?.logout_state?.message}
        </Alert>
      </Snackbar>
      <Stack display='flex' flexDirection='column' alignItems='center' py={2}>
        <Typography variant='h5' fontWeight='bold'>
          Login
        </Typography>
        <Typography variant='subtitle2' sx={{ opacity: '0.6' }}>
          Enter your details below to login
        </Typography>

        <Grow in={Boolean(auth.authState.error_message)} unmountOnExit>
          <Typography variant='body2' color='error' my={1} textAlign='center'>
            <b>Error logging in:</b>
            <br /> {auth.authState.error_message ?? 'Unknown error'}
          </Typography>
        </Grow>
      </Stack>
      <br />
      <Divider variant='fullWidth' component='hr' />
      <form onSubmit={formik.handleSubmit}>
        <div className='form-group login-input'>
          <TextField
            size='small'
            fullWidth
            id='userid'
            name='userid'
            label='UserID'
            value={formik.values.userid}
            onChange={formik.handleChange}
            error={formik.touched.userid && Boolean(formik.errors.userid)}
            helperText={formik.touched.userid && formik.errors.userid}
            disabled={isLoggingIn}
          />
        </div>
        <div className='form-group login-input'>
          <TextField
            size='small'
            fullWidth
            id='password'
            name='password'
            label='Password'
            type='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            disabled={isLoggingIn}
          />
        </div>
        <div className='form-group login-input'>
          <Button
            color='primary'
            disabled={!inputsFilled || isLoggingIn}
            sx={{
              fontWeight: 'bold',
            }}
            variant='contained'
            fullWidth
            type='submit'
            endIcon={
              isLoggingIn ? (
                <CircularProgress
                  size={16}
                  sx={{
                    color: 'inherit',
                  }}
                />
              ) : null
            }
          >
            Login
          </Button>
          <Button
            variant='text'
            size='small'
            sx={{ mt: 2 }}
            onClick={() => onForgotPassword()}
            disabled={isLoggingIn}
          >
            Reset Password
          </Button>
        </div>
      </form>
      <Stack alignItems='center' mt={3}>
        {/* <Typography variant="caption" >
          <Link
            href="https://ocsme.nj.gov/cmsaccountrequest"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            REQUEST A NEW ACCOUNT
          </Link>
        </Typography> */}
      </Stack>
    </>
  );
}
