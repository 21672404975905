import axios from 'axios';
import { useEffect } from 'react';
import { User } from '../../../types/User';

export function useSysadminPermissionCheck_TEMP(user: User | null) {
  const APP_ACTION_SEQUENCE = {
    'Dashboard Configuration': 'B2A9CD89-227F-41AF-A985-0B338B1194B8',
    'Workstation Setup': 'D81D1D1B-0BA1-4966-A496-49DE7344BEAA',
    'Case Visit': 'ED3B9EDE-5DEA-41E6-9288-55CD3669B115',
    'Generate Blank RIME': 'BB09B608-698E-4EBA-A88C-E0DD78759076',
    Users: '6455911B-D30D-40FA-9C42-B83455AFE0B1',
    Roles: '24A189DC-5FCF-4567-894E-30582E4F7357',
    'Import External RM Data': 'D0DA9694-6A85-4265-8AB9-127F911A6DD7',
    'Reference Data': '15499A92-055E-4E4B-A257-248E8CA66080',
    'Temporary Password': '995E4CE0-F23A-4B93-A7FD-31055332AC1D',
    'Lab List': '3D34479F-68CF-42F0-B3DD-3A4F99BB088E',
    Lab: '53A76D7D-6D45-4B08-9F72-F6E45EBA5474',
    POC: '3870BC7E-9881-49E0-9C26-49BE2042E1B8',
    'Worstation Setup': 'D81D1D1B-0BA1-4966-A496-49DE7344BEAA',
    'Location List': '9AECC044-B76A-451E-B58E-4D60AC0FC0F3',
    Location: 'C8E126C6-EEF4-42E3-A5F7-A16E6245BFFE',
    'Change Password': 'BEAF88FD-5991-444A-8C8B-64B434C3AB3C',
    'Business Logic': 'EBE453D9-BA0D-477D-8397-89507386FBD1',
    'User Profile': '8DDBA3E9-4DCE-4328-A55B-B6A76712D679',
    'Report Header Details': '8385BCE0-373A-4431-AA8A-C0E21DDFD5A1',
    'Import Passenders': '5BE23D9D-23AB-47DF-96F8-E0006C638114',
    'Generate Blank Rime': 'BB09B608-698E-4EBA-A88C-E0DD78759076',
    'Upload Resources': '24CBF53C-5225-4802-BE15-E5908E2FB6BE',
    'Livery Services': '46A75A4D-BC97-4020-B28D-EE81DE58A731',
    'Livery Service': 'C24EFE9F-7AE5-4BBA-9D35-C935A673C1A0',
    'Two Factor Authentication': 'CB77E7F1-4DA7-4900-BCE6-EEB19FF0292E',
    'Notification Template': 'C31B7696-63A6-42A6-89D7-488BF2041279',
    Notification: 'C1F2867F-89F7-4DED-8A66-BA08C9A8DE91',
    Activity: 'A1C8FBEC-92F8-440F-97A3-CB3E17645B03',
  };

  const PERMISSIONS = {
    View: 'View',
    Edit: 'Edit',
    Creator: 'Creator',
  };

  let permission = {};

  const checkAccess = (appActionSequence: string, appActionName: string) => {
    const loggedInUserSeq = user?.userSeq;
    // const appactionseq = APP_ACTION_SEQUENCE?.[appActionSequence];
    try {
      axios
        .get(
          `${import.meta.env.VITE_API_URL}user/getpagegrantaccesstype?appactionseq=${appActionSequence}&userSeq=${loggedInUserSeq}`,
          {
            headers: {
              //Authorization: 'Bearer ' + user?.token,
              'Content-Type': 'application/json',
            },
          }
        )
        .then(access => {
          const accessProvided = access?.data?.data?.pageGrantAccess ?? '';
          const edit = checkEdit(accessProvided);
          const view = checkView(accessProvided);
          const add = checkAdd(accessProvided);
          permission = {
            ...permission,
            [appActionName]: { add, edit, view, accessProvided },
          };
          localStorage.setItem('accessPermissions', JSON.stringify(permission));
          return permission;
        });
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const checkView = (access: string) => {
    if (access === PERMISSIONS?.['Creator']) {
      return true;
    } else if (access === PERMISSIONS?.['Edit']) {
      return true;
    } else if (access === PERMISSIONS?.['View']) {
      return true;
    }
  };

  const checkEdit = (access: any) => {
    return access === PERMISSIONS?.['Edit'] || access === PERMISSIONS?.['Creator'];
  };

  const checkAdd = (access: any) => {
    return access === PERMISSIONS?.['Creator'];
  };

  useEffect(() => {
    if (!user) return;
    Object.entries(APP_ACTION_SEQUENCE).map(([appActionName, appActionSequence], index) =>
      checkAccess(appActionSequence, appActionName)
    );
  }, [user]);
}
