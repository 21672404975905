import { Autocomplete, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import { dateTimeColumnType } from '../../../../utils/components/CrudDataGrid/DateTimeColumn';
import DialogCrudDataGrid, {
  handleAutocompleteChange,
} from '../../../../utils/components/DialogCrudDataGrid';

export default function CaseActivityLogDataGrid() {
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext();
  const [activityLog, setActivityLog] = useState<any[]>(
    // @ts-ignore
    formik.values?.caseInvestigations?.ActivityLog ?? []
  );
  const [liveryOptions, setLiveryOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchLiveryOptions();
    fetchActivityLog();
  }, []);

  const fetchLiveryOptions = async () => {
    try {
      const response = await fetch(`${VITE_API_URL}getliverystatusoptions`, {
        method: 'GET',
      });
      const data = await response.json();
      setLiveryOptions(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchActivityLog = async () => {
    setLoading(true);

    try {
      // @ts-ignore
      const caseSeq = formik.values?.caseSummary?.caseSeq;
      const response = await fetch(`${VITE_API_URL}gettransportationlogoncase/${caseSeq}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setActivityLog(data);
    } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
    } finally {
      setLoading(false);
    }
  };

  const createActivityLog = async newRow => {
    const sendActivityRequest = async (latitude = null, longitude = null) => {
      const requestBody = {
        // @ts-ignore
        caseSeq: formik.values?.caseSummary?.caseSeq,
        statusSeq: newRow?.description?.optionSeq,
        userSeq: user.userSeq,
        ...(latitude !== null && longitude !== null && { geocode: { latitude, longitude } }), // only if both are defined
      };

      try {
        const res = await fetch(VITE_API_URL + 'liverystatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
          },
          body: JSON.stringify(requestBody),
        });

        if (res.status === 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occurred');
        }

        await res.text();
        fetchActivityLog(); // Refresh log
      } catch (e) {
        console.error('Error:', e);
      }
    };

    const getLocation = async () => {
      setLoading(true);

      if (!navigator.geolocation) {
        console.log('Geolocation is not supported, proceeding without location data');
        await sendActivityRequest(); // Proceed without geolocation
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude, longitude } = position.coords;
          await sendActivityRequest(latitude, longitude);
        },
        async () => {
          // Silently proceed without location data if access is denied or an error occurs
          await sendActivityRequest();
        }
      );
    };

    await getLocation();
  };

  const activityLogColumns: GridColDef[] = [
    {
      flex: 1,
      minWidth: 150,
      field: 'description',
      headerName: 'Description',
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (value: Option) => value?.optionName || '',
      valueOptions: liveryOptions,
      // @ts-ignore
      renderEditCell: params => renderAutocompleteEditCell(params, 'Description', liveryOptions),
    },
    // @ts-ignore
    {
      flex: 1,
      field: 'timeStamp',
      headerName: 'Time Stamp',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      editable: false,
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'loggedByUser',
      headerName: 'Logged by User',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => value || user.userName,
      editable: false,
    },
  ];

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState<any>(data);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <>
        <Autocomplete
          options={liveryOptions || []}
          getOptionLabel={(option: any) => option.optionName || ''}
          value={localData?.description || null}
          onChange={(event, value) =>
            handleAutocompleteChange(event, value, 'description', localData, setLocalData, onChange)
          }
          renderInput={params => (
            <TextField {...params} label='Description' margin='dense' fullWidth required />
          )}
        />
        <TextField
          label='Time Stamp'
          value={
            localData?.timeStamp
              ? format(new Date(localData.timeStamp), 'MM/dd/yyyy, HH:mm')
              : format(new Date(), 'MM/dd/yyyy, HH:mm')
          }
          variant='outlined'
          fullWidth
          margin='normal'
          disabled
        />
        <TextField
          label='Logged by User'
          value={localData?.loggedByUser?.optionName || user.userName}
          variant='outlined'
          fullWidth
          margin='normal'
          disabled
        />
      </>
    );
  };

  return (
    <DialogCrudDataGrid
      title='Activity Log'
      columns={activityLogColumns}
      rows={activityLog}
      createFunction={createActivityLog}
      dialogContent={dialogContent}
      idColumn='caseActivityLogSeq'
      loading={loading}
    />
  );
}
