import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ScrollingAlert from '../../components/ScrollingAlert';
import { useAuth } from '../../utils/auth/AuthService';
import CustomHeader from '../../utils/components/CustomHeader';
import { DVCSAlert } from './DVCSAdminView';
import HomeCard from '../Home/HomeCard';

interface HomeCardProps {
  homeCardSeq: string;
  mobileSize: number;
  desktopSize: number;
  cardContent: string;
}

const DVCSHome = () => {
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const [alert, setAlert] = useState<DVCSAlert>({ alertText: '', alertSeverity: 'info' });
  const [loading, setLoading] = useState<boolean>(false);
  const [cardContent, setCardContent] = useState<HomeCardProps[]>([]);

  const getHomeCardContent = async () => {
    await fetch(VITE_API_URL + 'dvcs/getcardcontent', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCardContent(data);
      })
      .catch(e => {
        //alert(e);
      });

    setLoading(false);
  };

  useEffect(() => {
    getHomeCardContent();
  }, []);

  const fetchAlert = async () => {
    await fetch(VITE_API_URL + `dvcs/getalert`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAlert(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchAlert();
  }, []);

  return (
    <Box component='div' sx={{ p: 3, height: '100%', position: 'relative' }}>
      {/* Real-time Updates */}

      {/* Header */}
      <CustomHeader
        title='Disaster Victim Coordination System'
        description='Use this module to view and manage all disaster cases.'
        inDevelopment
        alert={
          alert.alertText !== '' && (
            <ScrollingAlert
              severity={alert.alertSeverity}
              message={alert.alertText ?? ''}
              speed={4}
            />
          )
        }
      />

      <Box component='div' sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid
          container
          spacing={5}
          sx={{ mt: 5, height: '500px' }}
          justifyContent='center'
          alignItems='center'
        >
          {loading ? (
            <Skeleton height={500} sx={{ width: '35%', borderRadius: 4 }} />
          ) : (
            cardContent.map(card => (
              <Grid item xs={card.desktopSize}>
                <HomeCard cardContent={card.cardContent} />
              </Grid>
            ))
          )}
        </Grid>
      </Box>

      <TextField
        sx={{ position: 'absolute', display: 'flex', bottom: '2rem', width: 'calc(100% - 3rem)' }}
        placeholder='Incident Search'
        fullWidth
      />
    </Box>
  );
};

export default DVCSHome;
