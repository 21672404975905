import { DownloadRounded } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../../../Context/GlobalContext';
import ExportDocuments from '../../../../components/AdministrationModules/ExportDocuments';
import FileUpload from '../../../../components/AdministrationModules/FileUpload';
import authHeader from '../../../../services/auth-header.jsx';
import { fetchUserCertificateType } from '../../../../services/utility-api';
import ControlComponents from './ControlComponents';
import DocumentEditView from './DocumentEditView';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Documents = ({ userSeq }) => {
  // const { user } = useAuth();
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const userPermissions = permissions?.['Users'];
  const navigate = useNavigate();
  const [documentsDropDown, setDocumentsDropDown] = useState([]);
  const [openExportDialogBox, setOpenExportDialogBox] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesToShow, setFilesToShow] = useState([]);
  const [editDocument, setEditDocument] = useState({});
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  const { profileInformation } = useContext(GlobalContext);
  const [deletePhotoSeq, setDeletePhotoSeq] = useState('');
  const initialValues = {
    type: 'all',
    date: null,
  };

  const columns = [
    {
      field: 'photoLabel',
      headerName: 'File name',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
      renderCell: cellValues => {
        // if (permissions?.[nodePermission]?.view) {
        return (
          <Typography onClick={() => openDoc(cellValues.row)} style={{ color: 'blue' }}>
            {cellValues.row.photoLabel}
          </Typography>
        );
        // }
      },
    },
    {
      field: 'userCertificateTypeName',
      headerName: 'Certificate Type',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'photoMimeType',
      headerName: 'Extension type',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      // headerClassName: 'super-app-theme--header',
      align: 'center',
      renderCell: cellValues => {
        return (
          <Button
            onClick={() => {
              editRow(cellValues);
            }}
            aria-label='edit'
            id={`row_id_${cellValues.row.id}`}
            disabled={!userPermissions?.edit}
          >
            <EditIcon />
          </Button>
        );
      },
    },
    {
      field: 'void',
      headerName: ' Delete',
      // headerClassName: 'super-app-theme--header',
      align: 'center',
      renderCell: cellValues => {
        const handleRemove = () => {
          setDeleteDialogBox(true);
          setDeletePhotoSeq(cellValues.row.photoSeq);
        };
        return (
          <Button
            onClick={handleRemove}
            // disabled={!permissions?.[nodePermission]?.edit}
            aria-label='delete'
            id={`row_id_${cellValues.row.id}`}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  const [certificateTypes, setCertificateTypes] = useState([]);

  const getCertificateTypes = async () => {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}GetCertificateType?userSeq=${userSeq}`,
      { headers: authHeader() }
    );
    setCertificateTypes(response?.data?.data ?? []);
  };

  const editRow = async params => {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}GetEditCertificate?photoSeq=${params.row.photoSeq}`,
      { headers: authHeader() }
    );

    setEditDocument(response.data.data);
    getAllUserCertificates();
  };

  const openDoc = async row => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}OpenDoc?photoSeq=${row.photoSeq}`,
        {
          headers: authHeader(),
        }
      );
      console.log('response?.data?.data[0]', response?.data?.data[0]);
      const fileData = response?.data?.data[0];

      const base64String = fileData?.photo;
      const filename = response?.data?.data[0]?.fileName;
      const contentType = fileData?.photoMimeType;

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: contentType });

      if ('download' in document.createElement('a')) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
      } else {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          const fileURL = event.target.result;
          const link = document.createElement('a');
          link.href = fileURL;
          link.target = '_blank';
          link.download = filename;
          link.click();
        };
        fileReader.readAsDataURL(blob);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDisabled = () => {
    if (files.length < 1) {
      return true;
    }
  };

  const deleteFile = async () => {
    try {
      await axios.post(
        `${import.meta.env.VITE_API_URL}VoidCertificate?photoSeq=${deletePhotoSeq}`,
        null,
        { headers: authHeader() }
      );
      setDeleteDialogBox(false);
      getAllUserCertificates();
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUserCertificateByCertificateTypes = async photoTypeSeq => {
    const files = await getAllUserCertificates();
    await axios.get(
      `${import.meta.env.VITE_API_URL}GetUserCertificateByCertificateType?userSeq=${userSeq}&UserCertificateTypeSeq=${photoTypeSeq}`,
      { headers: authHeader() }
    );
  };

  const getAllUserCertificates = async () => {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}GetAllUserCertificate?userSeq=${userSeq}`,
      { headers: authHeader() }
    );
    setFiles(response.data.data);
    setFilesToShow(response.data.data);
  };

  useEffect(() => {
    if (profileInformation.length === 0) {
      navigate('/sysadmin');
    }
    fetchUserCertificateType(userSeq).then(documents => setDocumentsDropDown(documents));
    getAllUserCertificates();
    getCertificateTypes();
    getUserCertificateByCertificateTypes();
  }, []);

  const [selectedType, setSelectedType] = useState('all');
  useEffect(() => {
    if (selectedType === 'all' || selectedType === 'Select One') {
      getAllUserCertificates();
      return;
    }
    setFilesToShow(() => files?.filter(item => item.userCertificateTypeSeq === selectedType));
  }, [selectedType]);

  return (
    <div className='container-fluid my-2 py-2'>
      <Formik initialValues={initialValues}>
        {({ form, values }) => {
          setSelectedType(values?.type);
          return (
            <>
              {/* {!window.location.search.includes('your-profile') ? <UserHeaderContainer /> : null}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: 'black',
                  padding: '16px',
                  borderRadius: '32px',
                  margin: '20px 0px',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={3} xs={6} md={6} sm={6}>
                    <Chips
                      title='User Profile'
                      location={
                        window.location.search.includes('your-profile')
                          ? '/yourprofile'
                          : '/edit-user'
                      }
                    />
                  </Grid>
                  <>
                    <Grid item lg={3} xs={6} md={6} sm={6}>
                      <Chips
                        title='Login History'
                        location={
                          window.location.search.includes('your-profile')
                            ? '/login-history?page=your-profile'
                            : '/login-history'
                        }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} md={6} sm={6}>
                      <Chips
                        title='Documents'
                        location={
                          window.location.search.includes('your-profile')
                            ? '/documents?page=your-profile'
                            : '/documents'
                        }
                      />
                    </Grid>
                    {window.location.search.includes('your-profile') ? (
                      '/yourprofile'
                    ) : '/edit-user' ? (
                      <Grid item lg={3} xs={6} md={6} sm={6}>
                        <Chips title='Notes' location='/notes' />
                      </Grid>
                    ) : null}
                  </>
                </Grid>
              </Box> */}
              <Form>
                <Box>
                  <Grid container spacing={0}>
                    <Grid item lg={9} xs={12} md={12} sm={12}>
                      <ControlComponents
                        fullWidth
                        size='small'
                        control='select'
                        name='type'
                        options={documentsDropDown}
                        label='Type'
                      />
                      {/* <FileUpload
                          fullWidth
                          photoTypeSeq={values.type}
                          setFilesToShow={setFilesToShow}
                          setFiles={setFiles}
                          documentType={values?.type}
                          permission={userPermissions}
                        />
                        <IconButton
                          size='large'
                          color='primary'
                          disabled={!userPermissions?.edit}
                          onClick={() => setOpenExportDialogBox(true)}
                        >
                          <DownloadRounded />
                        </IconButton> */}
                    </Grid>
                    <Grid item lg={3} xs={12} md={12} sm={12}>
                      <Stack direction='row'>
                        <FileUpload
                          fullWidth
                          color='primary'
                          photoTypeSeq={values.type}
                          setFilesToShow={setFilesToShow}
                          setFiles={setFiles}
                          documentType={values?.type}
                          permission={userPermissions}
                        />
                        <Button
                          fullWidth
                          color='primary'
                          variant='text'
                          disabled={!userPermissions?.edit}
                          onClick={() => setOpenExportDialogBox(true)}
                          startIcon={<DownloadRounded />}
                        >
                          Download
                        </Button>
                        {/* <IconButton
                          size='small'
                          color='primary'
                          disabled={!userPermissions?.edit}
                          onClick={() => setOpenExportDialogBox(true)}
                        >
                          <DownloadRounded />
                        </IconButton> */}
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Form>

              <Box sx={{ paddingTop: '20px' }}>
                <DataGridPremium
                  getRowId={row => row.photoSeq}
                  rows={filesToShow}
                  columns={columns}
                  columnHeaderHeight={50}
                  rowHeight={30}
                  pageSize={50}
                  scrollbarSize={5}
                  pagination
                  slots={{
                    pagination: CustomPagination,
                  }}
                  sx={{
                    width: '100%',
                    height: 450,
                    // border: 'none',
                    borderRadius: '6px',
                    // backgroundColor: 'white',
                    // boxShadow: '0px 0px 10px #ddd',
                  }}
                />
              </Box>
              <ExportDocuments
                openExportDialogBox={openExportDialogBox}
                setOpenExportDialogBox={setOpenExportDialogBox}
                files={files}
              />

              <Box>
                {Object.keys(editDocument).length > 0 ? (
                  <DocumentEditView
                    editDocument={editDocument}
                    getAllData={getAllUserCertificates}
                    certificateTypes={certificateTypes}
                    setEditDocument={setEditDocument}
                  />
                ) : null}
              </Box>
              <div className='modal'>
                <Dialog
                  open={deleteDialogBox}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setDeleteDialogBox(false)}
                  aria-describedby='alert-dialog-slide-description'
                >
                  <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                      Are you sure you want to delete this file?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setDeleteDialogBox(false)}>No</Button>
                    <Button onClick={deleteFile}>Yes</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Documents;
