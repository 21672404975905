import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ElabInventoryManagement() {
  const inventoryColumns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 125,
      maxWidth: 125,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'itemName',
      headerName: 'Item Name',
      minWidth: 175,
      maxWidth: 200,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleELabCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'traceName',
      headerName: 'Trace Name',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'inventoryType',
      headerName: 'Inventory Type',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'storageLocation',
      headerName: 'Storage Location',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'active',
      headerName: 'Active',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'published',
      headerName: 'Published',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'recievedDate',
      headerName: 'Recieved Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'recievedBy',
      headerName: 'Recieved By',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'openedDate',
      headerName: 'Opened Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  return (
    <ReportDataGrid
      idcolumn='id'
      //   loading={loading}
      listresults={[]}
      columnsInput={inventoryColumns}
      title=''
      customButtons={[<GridToolbarQuickFilter key='filter' />]}
    />
  );
}
