import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { GuidEmptySeq } from '../../../utils/constants/GuidEmptySeq';
import { Option } from '../../Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { CaseActivityLogTimeline } from './CaseActivityLogTimeline';
import { LiveryCase } from './livery.api';

export function YourLiveryDataGridDetailPane(params: GridRowParams<LiveryCase>) {
  const address = params.row.address?.toUpperCase();
  const otherItemsRequested =
    (typeof params.row.otherRequestedItems === 'string' &&
      params.row.otherRequestedItems.length > 0) ||
    false;
  let requestedItems: Option[] =
    params.row.requestedItems.filter(item => item.optionName !== 'Others') || [];
  if (otherItemsRequested) {
    requestedItems.push({
      optionSeq: GuidEmptySeq,
      isActive: true,
      // @ts-ignore
      optionName: params.row.otherRequestedItems,
    });
  }

  return (
    <Stack
      direction='row'
      alignItems='start'
      sx={{ mb: 1 }}
      useFlexGap
      flexWrap='wrap'
      justifyContent='start'
    >
      <Stack p={2} alignItems='start'>
        <Typography variant='subtitle2' textTransform='uppercase' fontWeight='bold' gutterBottom>
          {params.row.decedentFullName}
        </Typography>
        <TableContainer>
          <Table sx={{ maxWidth: 650, color: 'text.secondary', mt: 0, pt: 0 }} size='small'>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='left'></TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ color: 'text.secondary' }}>
              <TableRow>
                <TableCell component='th' scope='row'>
                  CaseID
                </TableCell>
                <TableCell align='left'>{params.row.caseID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row'>
                  Pickup address
                </TableCell>
                <TableCell align='left'>
                  {address === null || address?.length === 0 ? 'Unknown' : address}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component='th' scope='row'>
                  Assigned MLDI
                </TableCell>
                <TableCell align='left'>{params.row.assignedMLDI}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component='th' scope='row'>
                  Notes from MLDI
                </TableCell>
                <TableCell align='left'>
                  {params.row?.liveryNotes!?.trim().length > 0
                    ? params.row.liveryNotes?.trim()
                    : ' None'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component='th' scope='row'>
                  Items requested
                </TableCell>
                <TableCell align='left'>
                  {requestedItems.length === 0 && <p>None</p>}
                  <Stack direction='row' spacing={1}>
                    {requestedItems.map((item, index) => (
                      <>
                        {item.optionName?.toUpperCase()}
                        {index === requestedItems.length - 1 ? '' : ', '}
                      </>
                    ))}
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <CaseActivityLogTimeline
        sx={{ mt: 1, pt: 1 }}
        caseActivityLogRecords={params.row.activityLog}
      />
    </Stack>
  );
}
