import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import TreeDrawer from '../../utils/components/TreeDrawer';
import TreeTabPanel from '../../utils/components/TreeTabPanel';
import { SUPPORT_MENU_ICON } from './constants';

export default function SupportAgentView() {
  const [currentTab, setCurrentTab] = useState('0');
  const [menuOpen, setMenuOpen] = useState(false);
  const [tabContent, setTabContent] = useState(0);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  const drawerContents = [
    {
      id: 'user-accounts',
      text: 'User Accounts',
      label: 'User Accounts',
    },
    {
      id: 'account-requests',
      text: 'Account Requests',
      label: 'Account Requests',
    },
    {
      id: 'Reference Data',
      text: 'Reference Data',
      label: 'Reference Data',
      // icon: SUPPORT_MENU_ICON?.['Reference Data']?.icon,
    },
  ];

  const fetchComponent = () => {
    //@ts-ignore
    return SUPPORT_MENU_ICON?.[tabContent]?.component;
  };

  return (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <TreeDrawer
        drawerContents={drawerContents}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        setTabContent={setTabContent}
      />
      <Box component='section' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <TreeTabPanel value={currentTab} index={currentTab}>
          <Box component={'div'}>
            <Box component={'div'} sx={{ display: 'flex' }}>
              <Box component={'div'} sx={{ ml: 'auto', mr: 2, mt: 1 }}>
                <Hidden mdUp>
                  <Tooltip title='Show Case Menu'>
                    <IconButton
                      size={isMediumScreen ? 'medium' : 'large'}
                      sx={{ width: 'max-content', height: 'max-content' }}
                      onClick={handleMenuClick}
                    >
                      <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                    </IconButton>
                  </Tooltip>
                </Hidden>
              </Box>
            </Box>
          </Box>
          {fetchComponent()}
        </TreeTabPanel>
      </Box>
    </Box>
  );
}
