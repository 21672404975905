import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
// import { breadcrumbReportNav } from "../../../common/helper-functions";
import { useAuth } from '../../../utils/auth/AuthService';
import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function SceneExamineByView() {
  // Field Values (Investigators & Scene Examiners):
  const [allInvestigatorsSelected, setAllInvestigatorsSelected] = useState(false);
  const [allExaminersSelected, setAllExaminersSelected] = useState(false);

  const { user } = useAuth();
  const [redirect, setRedirect] = useState(null);
  const [userReady, setUserReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [currentUser, setCurrentUser] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [dateRangeAlertVisible, setDateRangeAlertVisible] = useState(false);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [allInvestigators, setAllInvestigators] = useState([]);
  const [allExaminers, setAllExaminers] = useState([]);
  const [investigators, setInvestigators] = useState([]);
  const [examiners, setExaminers] = useState([]);
  const [jdxs, setJdxs] = useState([]);
  const [MEAction, setMEAction] = useState([]);
  const [MEActionOptions, setMEActionOptions] = useState([]);
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const theme = useTheme();
  const { VITE_API_URL } = import.meta.env;

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      flex: 1,
      editable: false,
      headerAlign: 'left',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'menotified',
      headerName: 'ME Notified Date / Time',
      flex: 2,
      editable: false,
      headerAlign: 'left',
      valueFormatter: value => dayjs(value).format('MMM DD, YYYY - HH:mm'),
    },
    {
      field: 'investigator',
      headerName: 'Assigned Investigator',
      flex: 2,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'sceneexaminedby',
      headerName: 'Scene Examined By',
      flex: 2,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'sceneexamineddatetime',
      headerName: 'Scene Examined Date',
      flex: 2,
      editable: false,
      headerAlign: 'left',
      // valueFormatter: (params) => dayjs(params.value).format('MMM DD, YYYY - HH:mm'),
    },
    {
      field: 'facilitytype',
      headerName: 'Facility Type',
      flex: 2,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'transportbody',
      headerName: 'Body Transport',
      flex: 2,
      editable: false,
      headerAlign: 'left',
    },
  ];

  // const handleCaseClick = (event, data) => {
  //   if (import.meta.env.NODE_ENV === 'production') {
  //    // window.location.href = '/medexlab/caseview?id=' + data.formattedValue;
  //    navigate('/medexlab/caseview', { state: { cmscaseid: data.formattedValue } });
  //   } else {
  //    // window.location.href = '/caseview?id=' + data.formattedValue;
  //    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  //   }
  // };

  const handleInvestigatorChange = (event, data) => {
    setInvestigators(data);
  };

  const handleExaminerChange = (event, data) => {
    setExaminers(data);
  };

  const handleJdxChange = (event, data) => {
    setJdxs(data);
  };

  const handleMEActionChange = (event, data) => {
    setMEAction(data);
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const handleStartDateChange = event => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = event => {
    setEndDate(event.target.value);
  };

  const dismissDateRangeAlert = () => {
    setDateRangeAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  // Function to handle toggling all investigators selection
  const toggleAllInvestigatorsSelection = () => {
    const isChecked = event.target.checked;
    setInvestigators(isChecked ? allInvestigators : []);
    setAllInvestigatorsSelected(isChecked);
  };

  // Function to handle toggling all examiners selection
  const toggleAllExaminersSelection = () => {
    const isChecked = event.target.checked;
    setExaminers(isChecked ? allExaminers : []);
    setAllExaminersSelected(isChecked);
  };

  const clearAll = () => {
    setInvestigators([]);
    setExaminers([]);
    setJdxs([]);
    setYear(null);
    setStartDate('');
    setEndDate('');
    setDateRangeAlertVisible(false);
    setJdxAlertVisible(false);
    setLoading(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();
    if (user === null) return;

    if (
      (year == null || year == '') &&
      (startDate == null || startDate == '') &&
      (endDate == null || endDate == '')
    ) {
      setDateRangeAlertVisible(true);
      return;
    } else if ((startDate == null || startDate == '') && (endDate == null || endDate == '')) {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        setDateRangeAlertVisible(true);
        return;
      }
    }

    if (jdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setDateRangeAlertVisible(false);
    setLoading(true);
    setResultsVisible(true);

    formData.append('INVESTIGATORS', JSON.stringify(investigators));
    formData.append('EXAMINERS', JSON.stringify(examiners));
    formData.append('JDXLIST', JSON.stringify(jdxs));
    formData.append('MEACTION', JSON.stringify(MEAction));
    formData.append('YEAR', year);
    formData.append('STARTDATE', startDate);
    formData.append('ENDDATE', endDate);

    if (user && user.token) {
      await fetch(VITE_API_URL + 'sceneexaminedby', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setResults(data);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setLoading(false);
  };

  useEffect(() => {
    if (user === null) return;

    // INITIALIZE ME ACTION OPTIONS
    fetch(VITE_API_URL + 'getmeactionoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setMEActionOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setJdxs(user.jdxAccessList);

    setUserReady(true);
  }, [user]);

  useEffect(() => {
    if (jdxs && jdxs.length > 0) {
      const queryParams = new URLSearchParams(jdxs.map(jdx => ['jdxSeq', jdx.jdxSeq])).toString();

      fetch(`${VITE_API_URL}user/getinvestigators?${queryParams}`, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setAllInvestigators(data);
          setInvestigators(allInvestigatorsSelected ? data : []);
        })
        .catch(e => {
          //alert(e);
        });

      fetch(`${VITE_API_URL}user/getAllUsersInJdx?${queryParams}`, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setAllExaminers(data);
          setExaminers(allExaminersSelected ? data : []);
        })
        .catch(e => {
          //alert(e);
        });
    }
  }, [jdxs]);

  return (
    //     <div>
    //     <Card
    //         raised
    //         sx={{
    //           my: 3,
    //           mx: 'auto',
    //           width: { xs: '95%', lg: '75%' },
    //         }}
    //       >
    //         <CardHeader
    //         title='Scene Examined By'
    //         sx={{
    //           mx: 'auto',
    //           textAlign: 'center',
    //           color: theme.palette.primary.main,
    //         }}
    //         action={
    //           <Tooltip
    //             sx={{ color: theme.palette.text.primary }}
    //             title={(
    //               <div>
    //                 <div>This Report Tracks the number of open cases that the pathologists of the chosen jurisdictions have, grouped by the exam type.</div>
    //                 <div>&nbsp;</div>
    //                 <div>Here are the requirements for a case to be brought back:</div>
    //                 <ol>
    //                   <li>Case Year (or date range) matches selection</li>
    //                   <li>Jurisdiction is in the user’s available list</li>
    //                   <li>MEAction is either ‘Accepted’ or ‘Facilitated Release’</li>
    //                   <li>CaseStatus is ‘Open’</li>
    //                   <li>Case’s [Status] is ‘Active’</li>
    //                 </ol>
    //               </div>
    //             )}
    //             placement='right-end'
    //           >
    //             <InfoIcon />
    //           </Tooltip>
    //         }
    //       />

    // <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

    //       </Card>

    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Investigator/Scene Examiner Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
      >
        <Tooltip style={{ float: 'right' }} title='TBD' placement='right-end'>
          <InfoIcon />
        </Tooltip>
      </CardHeader>

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert
            color='info'
            isOpen={dateRangeAlertVisible}
            toggle={dismissDateRangeAlert}
            style={{ marginTop: 20, marginBottom: 0 }}
          >
            Date range must be within 1 year or year dropdown must be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert
            color='info'
            isOpen={jdxAlertVisible}
            toggle={dismissJdxAlert}
            style={{ marginTop: 20, marginBottom: 0 }}
          >
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Autocomplete
              multiple
              id='selectedjdxs'
              size='small'
              options={authorizedJdxs}
              value={jdxs}
              disableCloseOnSelect
              limitTags={4}
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
            />
          </Grid>

          {/* MEAction dropdown */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id='meaction'
              size='small'
              options={MEActionOptions.filter(o => o.isActive !== false)}
              value={MEAction}
              disableCloseOnSelect
              getOptionLabel={option => option.optionName}
              onChange={(event, data) => handleMEActionChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName}
                </li>
              )}
              style={{ width: '100%', marginTop: 15 }}
              renderInput={params => (
                <TextField {...params} label='ME Action' placeholder='ME Action' />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              id='year'
              size='small'
              options={yearOptions}
              value={year}
              getOptionLabel={option => option}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: '100%', marginTop: 15 }}
              renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id='startdate'
              name='startdate'
              label='Start Date'
              size='small'
              type='date'
              onChange={handleStartDateChange}
              disabled={year}
              style={{ width: '100%', marginTop: 15 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='enddate'
              name='enddate'
              label='End Date'
              size='small'
              type='date'
              onChange={handleEndDateChange}
              disabled={year}
              style={{ width: '100%', marginTop: 15 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
            />
          </Grid>

          {/* Third Row: Checkboxes for select all functionality & their dropdowns: */}
          <Grid item xs={12} md={12}>
            {/* Dropdown for all investigators */}
            <Autocomplete
              multiple
              id='investigators'
              size='small'
              options={allInvestigators}
              value={investigators}
              disableCloseOnSelect
              limitTags={4}
              getOptionLabel={option => option.personLastName + ', ' + option.personFirstName}
              onChange={(event, data) => handleInvestigatorChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.personLastName + ', ' + option.personFirstName}
                </li>
              )}
              style={{ width: '100%', marginTop: 15 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Assigned Investigator'
                  placeholder='Assigned Investigator'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {/* Checkbox to select all investigators */}
            <Checkbox
              checked={allInvestigatorsSelected}
              onChange={toggleAllInvestigatorsSelection}
            />
            <span>Select All Investigators</span>
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Dropdown for all 'Examiners' */}
            <Autocomplete
              multiple
              id='examiners'
              size='small'
              options={allExaminers}
              value={examiners}
              disableCloseOnSelect
              limitTags={4}
              getOptionLabel={option => option.personLastName + ', ' + option.personFirstName}
              onChange={(event, data) => handleExaminerChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.personLastName + ', ' + option.personFirstName}
                </li>
              )}
              style={{ width: '100%', marginTop: 15 }}
              renderInput={params => (
                <TextField {...params} label='Scene Examined By' placeholder='Scene Examined By' />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {/* Checkbox to select all examiners */}
            <Checkbox checked={allExaminersSelected} onChange={toggleAllExaminersSelection} />
            <span>Select All Scene Examiners</span>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Search
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={results}
            idcolumn='caseid'
            columnsInput={columns}
            title='Investigators By Scene'
            casecount
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've listed the detailed case records below, you can export the list to csv
                  format.
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
