import React, { useEffect, useState } from 'react';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CustomHeader from '../../utils/components/CustomHeader';
import axios from 'axios';

const { VITE_API_URL } = import.meta.env;

interface CMSUser {
  personLastName: string | null;
  personFirstName: string | null;
  userName: string | null;
  primaryEmail: string | null;
  primaryMobileNo: string | null;
  userSeq: string | null;
  personSeq: string | null;
  jurisdictions: string | null;
  roles: string | null;
  status: boolean; // Representing the IsActive field as a boolean
}

export default function MDXUserAccounts() {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<CMSUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<CMSUser[]>([]);
  const [showActive, setShowActive] = useState<boolean>(true); // For active/inactive toggle
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CMSUser | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  // Fetch all users from the API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get<CMSUser[]>(`${VITE_API_URL}user/getallusers`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Filter users based on active/inactive status and search filter
  useEffect(() => {
    const filtered = users.filter((user) => {
      const isActiveMatch = showActive ? user.status === true : user.status === false;
      const searchTerm = searchFilter.toLowerCase();
      const matchesSearch =
        searchFilter.trim() === '' ||
        (user.personLastName?.toLowerCase().includes(searchTerm) ||
          user.personFirstName?.toLowerCase().includes(searchTerm) ||
          user.roles?.toLowerCase().includes(searchTerm) ||
          user.jurisdictions?.toLowerCase().includes(searchTerm)); // Add jurisdictions here
      return isActiveMatch && matchesSearch;
    });
  
    setFilteredUsers(filtered);
  }, [users, showActive, searchFilter]);
  

  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Username',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          variant="text"
          color="primary"
          onClick={() => handleOpenModal(params.row)}
        >
          {params.value}
        </Button>
      ),
    },
    { field: 'personLastName', headerName: 'Last Name', flex: 1 },
    { field: 'personFirstName', headerName: 'First Name', flex: 1 },
    { field: 'primaryEmail', headerName: 'Email', flex: 1 },
    { field: 'primaryMobileNo', headerName: 'Mobile No', flex: 1 },
    { field: 'jurisdictions', headerName: 'Jurisdictions', flex: 2 },
    { field: 'roles', headerName: 'Roles', flex: 2 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueFormatter: (params: { value: boolean | null }) =>
        params.value === true ? 'Active' : params.value === false ? 'Inactive' : 'Unknown',
    },
  ];

  const handleOpenModal = (user: CMSUser) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setOpenModal(false);
  };

  return (
    <>
      <CustomHeader
        title="User Accounts"
        description="View all Current User accounts and roles"
      />

      {/* Radio button for Active/Inactive */}
      <FormControl>
        <FormLabel id="active-status">Users:</FormLabel>
        <RadioGroup
          row
          aria-labelledby="active-status"
          name="active-status-group"
          value={showActive ? 'active' : 'inactive'}
          onChange={(event) => setShowActive(event.target.value === 'active')}
        >
          <FormControlLabel value="active" control={<Radio />} label="Active" />
          <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
        </RadioGroup>
      </FormControl>

      {/* Search Filter */}
      <TextField
        label="Filter by Last Name, First Name, Jurisdiction or Role"
        variant="outlined"
        fullWidth
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      />

      {/* Conditional rendering with loading state */}
      {loading ? (
        <div>Loading users...</div>
      ) : filteredUsers.length === 0 ? (
        <div>No users found matching the current filters.</div>
      ) : (
        <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            rows={filteredUsers.map((user) => ({
              id: user.userSeq,
              ...user,
            }))}
            columns={columns}
            loading={loading}
            getRowHeight={() => 'auto'} // Dynamically adjust row height
            sx={{
              '& .MuiDataGrid-row': {
                paddingTop: '8px',
                paddingBottom: '8px',
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              },
            }}
          />
        </div>
      )}

      {/* Modal Dialog */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedUser?.personFirstName} {selectedUser?.personLastName}
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            centered
          >
            <Tab label="Main" />
            <Tab label="Jurisdictions" />
            <Tab label="Roles" />
          </Tabs>
          {activeTab === 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Main Information</Typography>
              <Button variant="contained" color="primary" fullWidth>
                (Under construction)
              </Button>
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedUser?.status === true}
                      onChange={() => {
                        // Handle active/inactive toggle here
                      }}
                    />
                  }
                  label={selectedUser?.status ? 'Active' : 'Inactive'}
                />
              </Box>
            </Box>
          )}
          {activeTab === 1 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Jurisdictions</Typography>
              {/* Add MultiSelect for Jurisdictions */}
            </Box>
          )}
          {activeTab === 2 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Roles</Typography>
              {/* Add MultiSelect for Roles */}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
