import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Fab, Tooltip, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import React from 'react';
import '../../../App.css';

export default function ExpandedReportDataGrid({
  loading,
  rows,
  columns,
  idcolumn,
  expanded,
  setExpanded,
  ...props
}) {
  const apiRef = useGridApiRef();
  const theme = useTheme();

  const toggleExpandDataGrid = event => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleExportToExcel = () => apiRef.current.exportDataAsExcel();

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: { xs: 'calc(100% - 5rem)', lg: 'calc(100% - 59px)' },
        top: 59,
        left: 0,
        zIndex: theme => theme.zIndex.drawer + 1,
        '& .MuiDataGrid-columnHeaders': {
          borderRadius: '0px', // Fixed duplicate borderRadius
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          border: 'none',
        },
      }}
    >
      <Tooltip title='tooltip' placement='top'>
        <Fab
          size='small'
          color='primary'
          sx={{ position: 'fixed', top: 105, right: 25 }}
          onClick={toggleExpandDataGrid}
        >
          <OpenInFullIcon fontSize='small' />
        </Fab>
      </Tooltip>
      <Fab
        size='small'
        color='primary'
        sx={{
          position: 'fixed',
          bottom: { xs: 105, lg: 25 },
          right: 25,
          width: 100,
          borderRadius: 15,
        }}
        onClick={handleExportToExcel}
      >
        Export
      </Fab>
      <DataGridPremium
        {...props}
        loading={loading}
        getRowId={row => row[idcolumn]}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        hideFooter
        sx={{
          border: 'none',
          borderRadius: '0 0 40px 40px',
          backgroundColor: theme.palette.background.paper,
          '& .MuiDataGrid-cell': {
            borderRight: '1px solid #ddd',
            fontFamily: 'DataGrid, sans-serif',
            fontWeight: 500,
          },
          '& .MuiDataGrid-cell:last-child': {
            borderRight: 'none',
          },
          '& .header-theme': {
            borderRight: '1px solid #ddd',
          },
          '& .header-theme:last-child': {
            borderRight: 'none',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
        }}
        rowHeight={28}
        columnHeaderHeight={28}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              ID: false,
            },
          },
        }}
      />
    </Box>
  );
}
