import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  IconButton,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../services/utility-api';
import InputLabel from '@mui/material/InputLabel';
// import { checkAccess, checkView, checkEdit } from 'utils/index';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { referenceTypeFieldLengthChecks } from '../referenceTypeFieldLengthChecks';
import { SlideProps } from '@mui/material/Slide';

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface AddEditProps {
  rows: any[];
  editObj: any;
  isCode: boolean;
  isCounty: boolean;
  referenceMasterTableName: string;
  maxOrder: number;
  countyNames: any;
  setIsAddEditOn: React.Dispatch<React.SetStateAction<boolean>>;
  getAllData: () => void;
  masterTableName: string;
}

const AddEdit: React.FC<AddEditProps> = ({
  rows,
  editObj,
  isCode,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  masterTableName,
}) => {
  const id = '00000000-0000-0000-0000-000000000000';
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const formattedReferenceMasterTableName = referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1);

  const handleClose = () => {
    setIsAddEditOn(false);
  };

  const fetchField = () => {
    const referenceTable = ['medicationtype', 'ageestimate'];
    if (referenceTable.includes(referenceMasterTableName.toLowerCase())) {
      return 'order';
    } else {
      return (formattedReferenceMasterTableName + 'Order');
    }
  };
  const fetchNameField = () => {
    const referenceTable = ['securityquestion'];
    if (referenceTable.includes(referenceMasterTableName.toLowerCase())) {
      return (formattedReferenceMasterTableName + 'Details');
    } else {
      return formattedReferenceMasterTableName + 'Name';
    }
  };

  const fetchHeaderName = () => {
    const referenceTable = ['usarmyagency'];
    if (referenceTable.includes(referenceMasterTableName.toLowerCase())) {
      let name = masterTableName
        .split('')
        .map(alpha => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim();
      name = name.split(' ')[0].toUpperCase() + ' ' + name.split(' ')[1] + ' ' + name.split(' ')[2];
      return name;
    } else {
      const masterTableListName = masterTableName
        .split('')
        .map(alpha => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim();
      return masterTableListName.toLowerCase().includes('county')
        ? masterTableListName.replace(/County/g, 'Jurisdiction')
        : masterTableListName;
    }
  };

  const onSave = async (values: any) => {
    const isNameAlreadyExist = rows.filter(
      row => row[fetchNameField()] == values[fetchNameField()]
    ).length;
    if (isNameAlreadyExist && !Object.keys(editObj).length) {
      setDialogContents(
        `Invalid data: there is another record with the same name in ${fetchHeaderName()} table`
      );
      setOpenApiDialogBox(true);
      return;
    }

    try {
      const response = await saveAdminData({
        values: {
          ...values,
          [formattedReferenceMasterTableName + 'Seq']:
            values[
              formattedReferenceMasterTableName + 'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.message.includes('UNIQUE KEY')) {
        setDialogContents('Duplicate values are not allowed!');
      } else {
        setDialogContents(response.message);
      }
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };
  {
    console.log('firstcode', [
      formattedReferenceMasterTableName + 'Code',
    ]);
  }
  return (
    <>   
      <Formik
        initialValues={{
          ...editObj,
          [fetchNameField()]: editObj[fetchNameField()],
          [formattedReferenceMasterTableName + 'Code']:
            editObj[
              formattedReferenceMasterTableName + 'Code'
            ],
          [fetchField()]: editObj[fetchField()] ? Number(editObj[fetchField()]) : maxOrder + 10,
          countySeq: editObj?.countySeq || id,
        }}
        validationSchema={Yup.object().shape({
          [fetchNameField()]: Yup.string()
            .required(`${fetchHeaderName()} is required`)
            .max(
              referenceTypeFieldLengthChecks[fetchNameField() ?? ''],
              `${fetchHeaderName()} can not be more than ${
                referenceTypeFieldLengthChecks[fetchNameField() ?? '']
              }`
            )
            .nullable(),
          [fetchField()]: Yup.number()
            .required('List Order is Required')
            .max(99999, 'List Order can not be more than 99999')
            .nullable(),
          countySeq: isCounty
            ? Yup.string()
                .test('countySeq', 'Jurisdiction is Required', item => {
                  return item !== id ? item : editObj?.countySeq;
                })
                .required('Jurisdiction is Required')
                .nullable()
            : Yup.string().nullable(),
          [formattedReferenceMasterTableName + 'Code']:
          formattedReferenceMasterTableName + 'Code' === 'countryCode'
              ? Yup.string()
                  .required(`Country code is Required`)
                  .max(3, 'Country Code length can not be more than 3.')
                  .nullable()
              : formattedReferenceMasterTableName + 'Code' === 'stateCode'
              ? Yup.string()
                  .required(`State code is Required`)
                  .max(2, 'State Code length can not be more than 2.')
                  .nullable()
              : isCode
              ? Yup.string()
                  .required(`${fetchHeaderName()} code is Required`)
                  .max(10, 'Code length can not be more than 3.')
                  .nullable()
              : Yup.string().nullable(),
        })}
        onSubmit={onSave}
      >
        {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>

              <Grid item xs={12}>
                <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                  <strong>
                    {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} {fetchHeaderName()}
                  </strong>
                </Typography>
              </Grid>

                {isCounty && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl required fullWidth focused sx={{ minWidth: '88%' }}>
                          <InputLabel htmlFor='countySeq' id='countySeq'>
                            Jurisdiction
                          </InputLabel>
                          <Select
                            onChange={handleChange}
                            variant='outlined'
                            label='Jurisdiction'
                            displayEmpty
                            size='small'
                            name={`countySeq`}
                            value={values?.countySeq || ''}
                          >
                            {Object.keys(countyNames).map((item, z) => {
                              return (
                                <MenuItem
                                  key={countyNames[item].countySeq}
                                  value={countyNames[item].countySeq}
                                >
                                  {countyNames[item].countyName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <ErrorMessage
                            name={'countySeq'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {isCode && (
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error required component='fieldset' variant='standard'>
                      <TextField
                        required
                        focused
                        id='outlined-basic'
                        defaultValue={''}
                        label={fetchHeaderName() + ' Code'}
                        onInput={e => {
                          const target = e.target as HTMLInputElement;
                          if (
                            formattedReferenceMasterTableName +
                              'Code' ==
                            'countryCode'
                          )
                            target.value = target.value.slice(0, 3);
                        }}
                        variant='outlined'
                        name={
                          formattedReferenceMasterTableName +
                          'Code'
                        }
                        value={
                          values[
                            formattedReferenceMasterTableName +
                              'Code'
                          ]
                        }
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name={
                          formattedReferenceMasterTableName +
                          'Code'
                        }
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      id='outlined-basic'
                      defaultValue={''}
                      onInput={e => {
                        const target = e.target as HTMLInputElement;
                        target.value =
                          referenceMasterTableName.toLowerCase() === 'country'
                            ? target.value
                                .slice(0, referenceTypeFieldLengthChecks[fetchNameField() ?? ''])
                                .replace(/[^a-z]/gi, '')
                            : target.value.slice(
                                0,
                                referenceTypeFieldLengthChecks[fetchNameField() ?? '']
                              );
                      }}
                      disabled={
                        values[fetchNameField()] == 'Other' || values[fetchNameField()] == 'Unknown'
                      }
                      label={fetchHeaderName()}
                      variant='outlined'
                      name={fetchNameField()}
                      value={values[fetchNameField()]}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name={fetchNameField()}
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      id='outlined-basic'
                      defaultValue={''}
                      label='List Order'
                      variant='outlined'
                      name={fetchField()}
                      type='number'
                      value={values[fetchField()]}
                      disabled={
                        values[fetchNameField()] == 'Other' || values[fetchNameField()] == 'Unknown'
                      }
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name={fetchField()}
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid container item xs={12} justifyContent='flex-start'>
                  <Box component={'div'}>
                    <Button
                      variant='contained'
                      sx={{ minWidth: '100px', mt: '16px' }}
                      onClick={e => handleSubmit()}
                      disabled={
                        values[fetchNameField()] == 'Other' || values[fetchNameField()] == 'Unknown'
                      }
                    >
                      {Object.keys(editObj).length ? 'Update' : 'Save'}
                    </Button>

                    <Button
                      color='error'
                      variant='outlined'
                      onClick={handleClose}
                      sx={{ minWidth: '100px', marginTop: '16px', marginLeft: '1rem' }}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <div className='modal'>
        <Dialog
          open={openApiDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenApiDialogBox(false)}
          // aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogContents ==
              `Invalid data: there is another record with the same name in ${fetchHeaderName()} table` ? (
                <FormHelperText error>{dialogContents}</FormHelperText>
              ) : (
                dialogContents
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenApiDialogBox(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddEdit;
