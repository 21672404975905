import { Add } from '@mui/icons-material';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import HomeCard from '../SysAdmin/SysAdminModules/HomeCardsAdmin/HomeCard';
import { useAuth } from '../../utils/auth/AuthService';
import DVCSHomeCard_AdminView from './DVCSHomeCard-AdminView';

export class DVCSCardProps {
  homeCardSeq: string;
  mobileSize: number;
  desktopSize: number;
  cardContent: string;

  constructor(
    homeCardSeq: string = uuidv4(),
    mobileSize: number = 12,
    desktopSize: number = 6,
    cardContent: string = ''
  ) {
    this.homeCardSeq = homeCardSeq;
    this.mobileSize = mobileSize;
    this.desktopSize = desktopSize;
    this.cardContent = cardContent;
  }
}

export default function DVCSHomeCardsAdmin() {
  const { user } = useAuth();
  const [cardContent, setCardContent] = useState<DVCSCardProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const { VITE_API_URL } = import.meta.env;

  const fetchCardData = async () => {
    try {
      const response = await fetch(`${VITE_API_URL}dvcs/getcardcontent`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Assuming data is an array of objects that match Card properties
        const cardData = data.map(
          (card: any) =>
            new DVCSCardProps(card.homeCardSeq, card.mobileSize, card.desktopSize, card.cardContent)
        );
        setCardContent(cardData);
      } else {
        console.error('Failed to fetch card data');
      }
    } catch (error) {
      console.error('Error fetching card data:', error);
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  };

  const isCardEmpty = (card: DVCSCardProps) => {
    return card.cardContent === '';
  };

  useEffect(() => {
    if (refresh) fetchCardData();
  }, [VITE_API_URL, user, refresh]);

  const addNewCard = () => {
    const newCard = new DVCSCardProps();
    setCardContent(prevCardContent => [...prevCardContent, newCard]);
  };

  return (
    <Grid container spacing={3}>
      {loading ? (
        <Grid xs={6}>
          <Skeleton variant='rounded' height={200} sx={{ borderRadius: 4 }} />
        </Grid>
      ) : (
        cardContent.map(card => (
          // SETTING TO 6 TEMPORARILY FOR MVP
          <Grid key={card.homeCardSeq} xs={6}>
            <DVCSHomeCard_AdminView
              cardSeq={card.homeCardSeq}
              allCards={cardContent}
              setAllCards={setCardContent}
              initEditing={isCardEmpty(card)}
              setRefresh={setRefresh}
            />
          </Grid>
        ))
      )}
      <Grid xs={6}>
        <Box
          component='div'
          sx={{
            width: '100%',
            height: '100%',
            minHeight: 200,
            maxHeight: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#335',
            borderRadius: 5,
            transition: 'background-color 0.2s ease',
            '&:hover': {
              backgroundColor: '#447',
            },
          }}
          onClick={addNewCard}
        >
          <Stack direction='row' spacing={1} sx={{ color: 'white' }}>
            <Add />
            <Typography variant='button'>New Card</Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
