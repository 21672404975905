import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ExamReportUploadedByPathologistReportView() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [MEOptions, setMEOptions] = useState([]);
  const [listresults, setlistresults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [numCases, setNumCases] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [selectedPathologists, setPathologist] = useState([]);
  const { VITE_API_URL } = import.meta.env;
  const theme = useTheme();

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 100,
      editable: false,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'jdxname',
      headerName: 'Jurisdiction',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'menotified',
      headerName: 'Case Reported On',
      width: 140,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'reporttype',
      headerName: 'Report Type',
      width: 140,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'uploadedon',
      headerName: 'Report Uploaded On',
      width: 140,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examtype',
      headerName: 'Exam Type',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examenddatetime',
      headerName: 'Exam Completed Date',
      width: 140,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'mannerofdeath',
      headerName: 'Manner Of Death',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'meaction',
      headerName: 'MEAction',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'administrativeassistantname',
      headerName: 'Admin Assistant',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
  ];

  const setResultsOverview = data => {
    var numCases = 0;

    for (const x of data) {
      numCases++;
    }

    setNumCases(numCases);
  };

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handlePathologistChange = (event, data) => {
    setPathologist(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear(null);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();

    // if (year == null) {
      if (startDate == '' || endDate == '') {
        setRequiredAlertVisible(true);
        return;
      }

      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 63072000000) {
        setRequiredAlertVisible(true);
        return;
      }
    // }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    formData.append('SELECTEDPATHOLOGISTS', JSON.stringify(selectedPathologists));
    // formData.append('TAG', JSON.stringify(tag));

    if (user && user.token) {
      await fetch(VITE_API_URL + 'GetExamReportUploadedByPathologist', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setResultsOverview(data);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchPathologists = async () => {
      await fetch(import.meta.env.VITE_API_URL + 'getpathologistoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setMEOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    fetchPathologists();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '85%' },
      }}
    >
      <CardHeader
        title='Exam Report Uploaded (By Pathologist)'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This Report tracks cases (for each pathologist selected in the date range) which have had an Autopsy or External Examination Report uploaded.'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Call taken range must be set within 2 years.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 Jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              id='pathologists'
              size='small'
              options={MEOptions}
              value={selectedPathologists}
              disableCloseOnSelect
              getOptionLabel={option => option.optionName}
              onChange={(event, data) => handlePathologistChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='Pathologist' placeholder='Pathologist' />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Report Uploaded On (From)'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              //disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Report Uploaded On (To)'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              //disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listresults}
            idcolumn='caseid'
            columnsInput={columns}
            title=' Tag Results By Jurisdiction'
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've listed all <b>{numCases} Cases</b> using your Tag selection in the date-range selected. These
                  cases are reported within the date-range and have been signed and filed, ordered by the Jurisdictions.
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
