import { Node, mergeAttributes } from '@tiptap/core';

export const YoutubeVideo = Node.create({
  name: 'video',

  group: 'block',

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: 560,
      },
      height: {
        default: 315,
      },
      frameborder: {
        default: 0,
      },
      allow: {
        default:
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
      },
      allowfullscreen: {
        default: true,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'iframe' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { class: 'iframe-container' }, ['iframe', mergeAttributes(HTMLAttributes)]];
  },
});
