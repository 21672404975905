import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { RichTextReadOnly } from 'mui-tiptap';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { useNotifications } from '../../../../../utils/components/AppBar/Notifications/NotificationsContext';
import useExtensions from '../../../../Home/useExtensions';
import { useConversation } from './ConversationsContext';
import {
  ChatNotificationItem,
  ConversationTypes,
  shouldMaskNameAsSupportAgent,
} from './conversation.types';

const CountdownBorder = ({
  onComplete,
  isPaused,
}: {
  onComplete: () => void;
  isPaused: boolean;
}) => {
  const theme = useTheme();
  const { messageNotificationDismissSeconds } = useConversation();

  return (
    <Box
      component='div'
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '2px',
        overflow: 'hidden',
      }}
    >
      <motion.div
        style={{
          width: '100%',
          height: '100%',
          background: theme.palette.primary.main,
          transformOrigin: 'left',
          opacity: 0.5,
        }}
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        transition={{
          duration: messageNotificationDismissSeconds,
          ease: 'linear',
          pause: isPaused,
        }}
        onAnimationComplete={onComplete}
      />
    </Box>
  );
};

export const ChatNotifications = () => {
  const theme = useTheme();
  const {
    notifications,
    supportAgentName,
    chats,
    handleCloseNotification,
    handleNotificationClick,
    toggleNotificationPause,
    dialogOpen,
  } = useConversation();
  const extensions = useExtensions({});
  const { user } = useAuth();
  const {
    preferences: notificationPreferences,
    preferencesLoading: notificationPreferencesLoading,
    preferencesError: notificationPreferencesError,
  } = useNotifications();

  const newChatNotificationPreference = notificationPreferences.find(
    p => p.notificationTypeSeq.toLowerCase() === '3688702a-659f-476a-bb0f-f6b299e4cae2' // New Chat
  );

  const shouldDisplay = newChatNotificationPreference?.inApp || false;

  const shouldMask = (n: ChatNotificationItem) => {
    const chat =
      chats.find(c => c.conversationSeq.toLowerCase() === n.conversationSeq.toLowerCase()) || null;
    const type = chat?.type.conversationTypeSeq || ConversationTypes.Feedback;

    const senderName = shouldMaskNameAsSupportAgent(
      n.messageRecord!,
      type,
      // @ts-ignore
      user
    )
      ? supportAgentName
      : `${n.sender.personFirstName} ${n.sender.personLastName}`;

    return senderName;
  };

  return shouldDisplay ? (
    <Box
      component='div'
      sx={{ position: 'fixed', top: 84, right: 24, zIndex: 2000, pointerEvents: 'none' }}
    >
      <AnimatePresence mode='sync'>
        {!dialogOpen &&
          notifications.map(notification => (
            <motion.div
              key={notification.id}
              layout
              style={{
                position: 'relative',
                pointerEvents: 'auto',
                marginBottom: '8px',
                transformOrigin: 'top right',
                width: '400px',
              }}
              initial={{ opacity: 0, scale: 0.85, x: 100 }}
              animate={{
                opacity: 1,
                scale: 1,
                x: 0,
                transition: {
                  type: 'spring',
                  damping: 20,
                  stiffness: 300,
                  mass: 0.8,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.9,
                x: 100,
                transition: {
                  duration: 0.2,
                  ease: 'easeOut',
                },
              }}
            >
              <Box
                component='div'
                onClick={() => {
                  // console.log(notification);
                  if (notification.id) {
                    handleNotificationClick(notification.conversationSeq, notification.id);
                  }
                }}
                onMouseEnter={() => toggleNotificationPause(notification.id)}
                onMouseLeave={() => toggleNotificationPause(notification.id)}
                onTouchStart={() => toggleNotificationPause(notification.id)}
                onTouchEnd={() => toggleNotificationPause(notification.id)}
                sx={{
                  position: 'relative',
                  bgcolor: theme.palette.primary.contrastText,
                  backdropFilter: 'blur(2px)',
                  borderRadius: 1,
                  boxShadow: theme.shadows[1],
                  overflow: 'hidden',
                  cursor: 'pointer',
                  transition: theme.transitions.create('box-shadow'),
                  '&:hover': {
                    boxShadow: theme.shadows[2],
                  },
                }}
              >
                <Box component='div' sx={{ p: 2, pr: 6 }}>
                  <Typography
                    variant='body1'
                    sx={{
                      fontWeight: 500,
                      color: theme.palette.text.primary,
                      mb: 0.5,
                    }}
                  >
                    {shouldMask(notification)}
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <RichTextReadOnly content={notification.message} extensions={extensions} />
                  </Typography>
                </Box>

                <IconButton
                  size='small'
                  onClick={e => {
                    e.stopPropagation();
                    handleCloseNotification(notification.id);
                  }}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: theme.palette.action.active,
                    padding: '4px',
                    '&:hover': {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>

                <CountdownBorder
                  onComplete={() => handleCloseNotification(notification.id)}
                  isPaused={notification.isPaused}
                />
              </Box>
            </motion.div>
          ))}
      </AnimatePresence>
    </Box>
  ) : null;
};
