import {
  Autocomplete,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import CaseNotes from '../../../components/CaseNotes';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import { useAuth } from '../../../utils/auth/AuthService';
import { AssignmentTypes } from '../../../utils/constants/constants';
import CertificationView from './CertificationView';
import ChargeableRequestsView from './RecordsModules/ChargeableRequestsView';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useUnsavedChanges } from '../../../Context/UnsavedChangesContext';
import useStickyState from '../../../utils/functions/useStickyState';
import { CourtDocumentsView } from './RecordsModules/CourtDocumentsView';

export default function RecordsView({ showNotes = true }) {
  const location = useLocation();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useStickyState(
    0,
    'case.records.tab',
    location.state?.cmscaseid
  );
  const { user } = useAuth();
  const formik = useFormikContext();
  const { setUnsavedChanges } = useUnsavedChanges();
  const [voidCaseReasonOptions, setVoidCaseReasonOptions] = useState([]);
  const { VITE_API_URL } = import.meta.env;

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);

  const voidCaseAccess = user.roleCheck(['451', 'f45']); //Sys admin, case super admin

  const fetchVoidCaseReasons = async () => {
    await fetch(VITE_API_URL + 'getvoidcasereason', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setVoidCaseReasonOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    if (formik.values?.caseCertification?.caseStatus === 'V') {
      fetchVoidCaseReasons();
    }
  }, [formik.values?.caseCertification?.caseStatus]);

  return (
    <form>
      <TableContainer component={Paper} variant='outlined'>
        <Table sx={{ minWidth: 650 }}>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Typography variant='subtitle1' color='gray' sx={{ fontSize: '.675rem' }}>
                  <strong>CASE STATUS</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <FormControl>
                  <RadioGroup
                    id='caseCertification.caseStatus'
                    name='caseCertification.caseStatus'
                    value={
                      formik.values != null ? formik.values?.caseCertification?.caseStatus : ''
                    }
                    onChange={e => {
                      formik.handleChange(e);
                      setUnsavedChanges();
                    }}
                    row
                  >
                    <FormControlLabel
                      disabled={!formik.status.editing || !authorizedToEdit}
                      value='O'
                      control={<Radio />}
                      label={<Typography variant='body2'>Open</Typography>}
                    />
                    <FormControlLabel
                      disabled={!formik.status.editing || !authorizedToEdit}
                      value='C'
                      control={<Radio />}
                      label={<Typography variant='body2'>Closed</Typography>}
                    />
                    {voidCaseAccess && (
                      <FormControlLabel
                        disabled={!formik.status.editing}
                        value='V'
                        control={<Radio />}
                        label={<Typography variant='body2'>Void</Typography>}
                      />
                    )}
                  </RadioGroup>
                </FormControl>

                {formik.values?.caseCertification?.caseStatus &&
                  formik.values?.caseCertification?.caseStatus === 'V' && (
                    <Grid container spacing={2} sx={{ marginTop: 1 }} direction='row'>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          disabled={!formik.status.editing || !authorizedToEdit}
                          id='caseCertification.voidCaseReasonSeq'
                          name='caseCertification.voidCaseReasonSeq'
                          options={voidCaseReasonOptions}
                          value={
                            formik.values?.caseCertification != null
                              ? voidCaseReasonOptions?.find(
                                  option =>
                                    option.optionSeq ===
                                    formik.values?.caseCertification?.voidCaseReasonSeq
                                ) || null
                              : null
                          }
                          onChange={(e, value) => {
                            formik.setFieldValue(
                              'caseCertification.voidCaseReasonSeq',
                              value?.optionSeq
                            );
                            setUnsavedChanges();
                          }}
                          error={
                            formik.touched.voidCaseReasonSeq &&
                            Boolean(formik.errors.voidCaseReasonSeq)
                          }
                          helperText={
                            formik.touched.voidCaseReasonSeq && formik.errors.voidCaseReasonSeq
                          }
                          getOptionLabel={option => option?.optionName}
                          renderInput={params => <TextField {...params} label='Void Case Reason' />}
                        />
                      </Grid>

                      <Grid item xs={12} md={9}>
                        <TextField
                          disabled={!formik.status.editing || !authorizedToEdit}
                          label='Void Case Note'
                          name='caseCertification.voidCaseNote'
                          type='text'
                          multiline
                          value={
                            formik.values?.caseCertification != null
                              ? formik.values?.caseCertification?.voidCaseNote
                              : null
                          }
                          onChange={e => {
                            formik.handleChange(e);
                            setUnsavedChanges();
                          }}
                          fullWidth
                          InputProps={{
                            inputProps: {
                              minRows: 1,
                              maxRows: 5,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 0, mb: 0, pb: 0 }} />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label='case records tabs'
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1001,
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Tab label='Documents' />
            <Tab label='File Requests' />
            <Tab label='Court Records' />
            <Tab label='Case Notes' />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {selectedTab === 0 && (
            <>
              <CaseDocuments caseinfo={formik.values?.caseSummary} />

              <br />
              <TableContainer component={Paper} variant='outlined'>
                <Table sx={{ minWidth: 650 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle1' color='gray' sx={{ fontSize: '.675rem' }}>
                          <strong>CERTIFICATION</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <CertificationView
                          caseinfo={formik.values}
                          editing={formik.status.editing}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {selectedTab === 1 && (
            <ChargeableRequestsView caseinfo={formik.values} editing={formik.status.editing} />
          )}

          {selectedTab === 2 && <CourtDocumentsView />}

          {selectedTab === 3 && <CaseNotes department={AssignmentTypes.Records} />}
        </Grid>
      </Grid>

      {/* {showNotes && (
        <>
          <br />
          <br />
          <Grid item xs={12}>
            <CaseNotes department={AssignmentTypes.Records} />
          </Grid>
        </>
      )} */}
    </form>
  );
}
