import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function CompletedPathologistExamsView() {
  const { user } = useAuth();

  const [redirect, setredirect] = useState(null);
  const [userReady, setuserReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedPathologists, setPathologist] = useState([]);
  const [MEOptions, setMEOptions] = useState([]);
  const [currentUser, setcurrentUser] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const { VITE_API_URL } = import.meta.env;

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 105,
      editable: false,
      headerAlign: 'center',

      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'jdxname',
      headerName: 'Jurisdiction',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      width: 140,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'meaction',
      headerName: 'MEACTION',
      width: 80,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examtype',
      headerName: 'Exam Type',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'menotified',
      headerName: 'Reported On',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent Name',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examstartdatetime',
      headerName: 'Exam Start Datetime',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examenddatetime',
      headerName: 'Exam Completed Datetime',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'casesignedandfileddate',
      headerName: 'Case Completed On',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'mannerofdeath',
      headerName: 'Manner Of Death',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'administrativeassistantname',
      headerName: 'Administrative Assistant',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
  ];

  //console.log(columns);

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const handlePathologistChange = (event, data) => {
    setPathologist(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear(null);
    setPathologist([]);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();

    let startDateArr = startDate.split('-');
    let endDateArr = endDate.split('-');

    let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
    let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

    if (endDateValue - startDateValue > 31536000000) {
      setRequiredAlertVisible(true);
      return;
    }

    if (selectedJdxs.length == 0 && selectedPathologists.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    formData.append('SELECTEDPATHOLOGISTS', JSON.stringify(selectedPathologists));
    if (user && user.token) {
      await fetch(VITE_API_URL + 'CompletedPathologistExams', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(null);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    const fetchPathologists = async () => {
      await fetch(import.meta.env.VITE_API_URL + 'getpathologistoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setMEOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    fetchYear();
    fetchPathologists();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Completed Exams By Pathologist'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This Report Tracks the number of exams performed by the Pathologist(s) within the selected date range'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Please select a date range with a maximum of 1 year.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select a Pathologist, or at least 1 Jusrisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Autocomplete
              multiple
              id='selectedjdxs'
              size='small'
              options={authorizedJdxs}
              value={selectedJdxs}
              limitTags={4}
              disableCloseOnSelect
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              id='pathologists'
              size='small'
              options={MEOptions}
              value={selectedPathologists}
              disableCloseOnSelect
              getOptionLabel={option => option.optionName}
              onChange={(event, data) => handlePathologistChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='Pathologist(s)' placeholder='Pathologist(s)' />
              )}
            />
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Autocomplete
              id='year'
              options={yearOptions}
              value={year}
              size='small'
              getOptionLabel={(option) => option.toString()}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label='Year' placeholder='Year' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid> */}

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Exam Completed From: '
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Exam Completed To: ' 
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listresults}
            idcolumn='caseid'
            columnsInput={columns}
            title='Completed Pathologist Exams'
            casecount
            // chatcontent={averageTATInfo(
            //   acceptedAverage,
            //   facilitatedAverage,
            //   releaseAverage,
            //   storageAverage,
            //   transferAverage
            // )}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}

// Original for reference (delete when not needed)
// return (
//   <div className='me-response-time' style={{ padding: 20 }}>

//     <BreadcrumbReportNav currentPage='Closed Pathologist Cases' />
//     <Card style={{ marginBottom: '1rem' }} className='card-main'>
//       <CardHeader className='header-txt'>
//         Closed Cases By Pathologist
//         <Tooltip
//           style={{ float: 'right' }}
//           title='This Report Tracks the number of cases closed by the Pathologist within the selected date range'
//         >
//           <InfoIcon />
//         </Tooltip>
//       </CardHeader>

//       <Alert
//         color='info'
//         isOpen={requiredAlertVisible}
//         toggle={dismissDateAlert}
//         style={{ margin: 10 }}
//       >
//         Please either select a Pathologist or enter a date range for when the Case was closed
//       </Alert>

//       <Alert
//         color='info'
//         isOpen={jdxAlertVisible}
//         toggle={dismissJdxAlert}
//         style={{ margin: 10 }}
//       >
//         Please select a Pathologist, or at least 1 Jusrisdiction.
//       </Alert>

//       <div>
//         <CardBody>
//           <Row>
//             <Col className='md=12'>
//               <Autocomplete
//                 multiple
//                 id='selectedJdxs'
//                 options={authorizedJdxs}
//                 value={selectedJdxs}
//                 disableCloseOnSelect
//                 limitTags={4}
//                 size='small'
//                 getOptionLabel={(option) => option.jdxName}
//                 onChange={(event, data) => handleJdxChange(event, data)}
//                 renderOption={(props, option, { selected }) => (
//                   <li {...props}>
//                     <Checkbox
//                       icon={<MdCheckBoxOutlineBlank />}
//                       checkedIcon={<MdOutlineCheckBox />}
//                       style={{ marginRight: 8 }}
//                       checked={selected}
//                     />
//                     {option.jdxName}
//                   </li>
//                 )}
//                 style={{ width: '100%', marginTop: 15 }}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label='Jurisdiction'
//                     placeholder='Jurisdiction'
//                   />
//                 )}
//                 ListboxComponent={'ul'}
//               />
//             </Col>
//           </Row>

//           <Row>
//             {/* <Col className='me-col' md='4'>
//               <Autocomplete
//                 id='year'
//                 options={yearOptions}
//                 value={year}
//                 size='small'
//                 getOptionLabel={(option) => option.toString()}
//                 onChange={(event, data) => handleYearChange(event, data)}
//                 renderOption={(props, option, { selected }) => (
//                   <li {...props}>
//                     <Checkbox
//                       icon={<MdCheckBoxOutlineBlank />}
//                       checkedIcon={<MdOutlineCheckBox />}
//                       style={{ marginRight: 8 }}
//                       checked={selected}
//                     />
//                     {option.toString()}
//                   </li>
//                 )}
//                 style={{ width: '100%' }}
//                 renderInput={(params) => (
//                   <TextField {...params} label='Year' placeholder='Year' />
//                 )}
//                 ListboxComponent={'ul'}
//               />
//             </Col> */}

//             <Col className='md=4'>
//               <Autocomplete
//                 multiple
//                 id='pathologists'
//                 options={MEOptions}
//                 value={selectedPathologists}
//                 disableCloseOnSelect
//                 limitTags={4}
//                 size='small'
//                 getOptionLabel={(option) => option.optionName}
//                 onChange={(event, data) =>
//                   handlePathologistChange(event, data)
//                 }
//                 renderOption={(props, option, { selected }) => (
//                   <li {...props}>
//                     <Checkbox
//                       icon={<MdCheckBoxOutlineBlank />}
//                       checkedIcon={<MdOutlineCheckBox />}
//                       style={{ marginRight: 8 }}
//                       checked={selected}
//                     />
//                     {option.optionName}
//                   </li>
//                 )}
//                 style={{ width: '100%', marginTop: 10 }}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label='Pathologist'
//                     placeholder='Pathologist'
//                   />
//                 )}
//                 ListboxComponent={'ul'}
//               />
//             </Col>

//             <Col className='me-col' md='4'>
//               <TextField
//                 id='searchfromdate'
//                 name='searchfromdate'
//                 label='Case Closed From'
//                 type='date'
//                 size='small'
//                 value={startDate}
//                 onChange={handleStartDateChange}
//                 //disabled={year}
//                 style={{ width: '100%' }}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//               />
//             </Col>

//             <Col className='me-col' md='4'>
//               <TextField
//                 id='searchtodate'
//                 name='searchtodate'
//                 label='Case Closed To'
//                 type='date'
//                 size='small'
//                 value={endDate}
//                 onChange={handleEndDateChange}
//                 //disabled={year}
//                 style={{ width: '100%' }}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//               />
//             </Col>
//           </Row>
//         </CardBody>
//         <br />

//         <Button
//           type='submit'
//           color='primary'
//           style={{ width: 120, marginLeft: 18, float: 'right' }}
//           onClick={handleSubmit}
//         >
//           Search
//         </Button>

//         <Button
//           color='secondary'
//           style={{ width: 120, marginLeft: 18, float: 'right' }}
//           onClick={clearAll}
//         >
//           Clear
//         </Button>
//         <hr />
//         <br />

//         <Collapse isOpen={resultsVisible}>
//           <ReportDataGrid
//             loading={loading}
//             listresults={listresults}
//             idcolumn='caseid'
//             columnsInput={columns}
//             title='Closed Pathologist Cases'
//             casecount
//             chatcontent={
//               <Paper
//                 elevation={3}
//                 sx={{
//                   p: '10px',
//                   px: '20px',
//                   borderRadius: '20px',
//                   width: 'max-content',
//                 }}
//               >
//                 <div style={{ textAlign: 'center' }}>
//                   I've listed the Cases that were Closed by the Pathologist in the
//                   selected date range below.  You can also export the list to csv
//                   format.
//                 </div>
//               </Paper>
//             }
//           />
//         </Collapse>
//       </div>
//     </Card>
//   </div>
// );
