import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';
import { DataGridPremium } from '@mui/x-data-grid-premium';

export default function UnclaimedCasesView() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [countResults, setCountResults] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [jdxs, setJdxs] = useState([]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [daysDelayedValue, setDaysDelayedValue] = useState('30');
  const [nok, setNok] = useState(false);
  const navigate = useNavigate();
  const { VITE_API_URL } = import.meta.env;
  const theme = useTheme();

  const handleJdxChange = (event, data) => {
    setJdxs(data);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const handleDaysDelayedChange = event => {
    const newValue = event.target.value;
    if (newValue != '' && /^[0-9]*$/.test(newValue)) {
      setDaysDelayedValue(newValue);
    }
  };

  const listColumns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      width: 125,
      editable: false,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'decedentName',
      headerName: 'Decedent Name',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examEndDate',
      headerName: 'Exam EndDate',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'causeOfDeath',
      headerName: 'Cause of Death',
      width: 210,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'caseStatus',
      headerName: 'Status',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
  ];

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();

    if (jdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setLoading(true);
    setResultsVisible(true);

    formData.append('JdxList', JSON.stringify(jdxs));
    formData.append('Days', daysDelayedValue);
    formData.append('NOK', nok);

    if (user && user.token) {
      await fetch(VITE_API_URL + 'GetUnclaimedCasesCount', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          } else if (res.status == 204) {
            // No data returned
          } else {
            return res.json();
          }
        })
        .then(data => {
          console.log('GOT HERE: ', data ?? []);
          setCountResults(data ?? []);
        })
        .catch(e => {
          alert(e);
        });

      await fetch(VITE_API_URL + 'GetUnclaimedCasesList', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          } else if (res.status == 204) {
            // No data returned
          } else {
            return res.json();
          }
        })
        .then(data => {
          setListResults(data ?? []);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setLoading(false);
  };

  const clearAll = () => {
    setJdxs([]);
    setResultsVisible(false);
  };

  const CountContent = () => {
    var jdxs = [];
    var counts = [{ id: 0 }];

    for (var i of countResults) {
      jdxs.push({
        field: i.jurisdictionName,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'count-header-theme',
        minWidth: 120,
      });
      counts[0][i.jurisdictionName] = i.count + ' Cases';
    }

    return countResults.length > 0 ? (
      <Paper
        elevation={3}
        sx={{
          p: '10px',
          px: '20px',
          borderRadius: '20px',
          width: '100%',
        }}
      >
        <p style={{ textAlign: 'center', fontFamily: 'DataGrid, sans-serif' }}>
          Based on your I have generated the following table. <br /> The results reflect cases that
          are positively identified, <br /> where the Exam has been completed for more than{' '}
          {daysDelayedValue} days, <br /> and the case is yet to be checked out.
        </p>

        <div style={{ textAlign: 'center' }}>
          <DataGridPremium
            columns={jdxs}
            rows={counts}
            columnHeaderHeight={35}
            rowHeight={35}
            sx={{
              width: '100%',
              '& .MuiDataGrid-cell': {
                borderRight: '1px solid #ddd',
              },
              '& .MuiDataGrid-cell:last-child': {
                borderRight: 'none',
              },
              '& .count-header-theme': {
                borderRight: '1px solid #ddd',
              },
              '& .count-header-theme:last-child': {
                borderRight: 'none',
              },
              '& .MuiDataGrid-iconSeparator': {
                display: 'none',
              },
              fontFamily: 'DataGrid, sans-serif',
            }}
            autoHeight
            hideFooter
            disableSelectionOnClick
          />
        </div>
      </Paper>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (user === null) return;
    setAuthorizedJdxs(user.jdxAccessList);
    setJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Unclaimed Cases Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid
          container
          spacing={2}
          alignItems='center'
          justifyContent='center'
          alignContent='center'
        >
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='jdxs'
              options={authorizedJdxs}
              value={jdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs>
            <TextField
              id='daysDelayed'
              name='daysDelayed'
              label='Exam Completed Greater than (days)'
              size='small'
              type='text'
              onChange={handleDaysDelayedChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '^[0-9]*$',
              }}
              value={daysDelayedValue}
            />
          </Grid>

          <Grid item xs='auto'>
            <Tooltip title='If checked, only cases with an NOK will be returned, if unchecked, only cases without an NOK will be returned'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => setNok(event.target.checked)}
                    checked={nok}
                    name='NOK?'
                  />
                }
                label='NOK?'
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listResults}
            idcolumn='caseID'
            columnsInput={listColumns}
            title='Records Found'
            casecount
            chatcontent={<CountContent />}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
