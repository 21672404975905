import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DashboardChiclets } from '../../components/DashboardChiclets/DashboardChiclets';
import { loadDashboardDataGridAPI } from '../../components/DashboardDataGrids/useDashboardDataGridAPI.hook';
import { Jurisdiction } from '../../types/Jurisdiction.interface';
import { useAuth } from '../../utils/auth/AuthService';
import { AddSiloDrawer } from './AddSiloDrawer';
import { DashboardProvider } from './DashboardContext';
import { DashboardDataGrids } from './DashboardDataGrids';
import { SwapSiloDrawer } from './SwapSiloDrawer';
import {
  DASHBOARD_SILO_SEQS,
  DashboardSilo,
  dashboardSiloSeqsMap,
  defaultSiloMap,
} from './dashboard.constants';

export function DashboardView() {
  return (
    <DashboardProvider>
      <DashboardChiclets />
      <Box component='div' sx={{ overflow: 'hidden' }}>
        <DashboardDataGrids />
        <AddSiloDrawer />
        <SwapSiloDrawer />
        {/* <DashboardContext.Consumer>
          {ctx =>
            auth.user &&
            auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) &&
            !ctx?.isEditing && (
              <Fab
                color='primary'
                aria-label='New Case'
                sx={{
                  position: 'fixed',
                  bottom: { xs: '85px', lg: '45px' },
                  right: '45px',
                }}
                onClick={() => navigate('/caseintake')}
              >
                <Add />
              </Fab>
            )
          }
        </DashboardContext.Consumer> */}
      </Box>
    </DashboardProvider>
  );
}

export interface DashboardDataGridAPIResponse {
  dashboardSiloSeq: string;
  data: any[];
  loading: boolean;
  error: any;
}

export function useDashboardDataGridAPIs({
  selectedJurisdictions,
  preferencesLoading,
}: {
  selectedJurisdictions: Jurisdiction[];
  preferencesLoading: boolean;
}) {
  const auth = useAuth();

  const [responses, setResponses] = useState<
    {
      dashboardSiloSeq: string;
      data: any[];
      loading: boolean;
      error: unknown;
    }[]
  >(
    defaultSiloMap.map(silo => ({
      dashboardSiloSeq: silo.dashboardSiloSeq,
      data: [],
      loading: true,
      error: null,
    }))
  );

  // Update the state with new data for a specific silo
  const updateSiloData = (siloSeq: string, update: Partial<DashboardDataGridAPIResponse>) => {
    setResponses(currentResponses =>
      currentResponses.map(response => {
        if (response.dashboardSiloSeq === siloSeq) {
          return { ...response, ...update };
        }
        return response;
      })
    );
  };

  const fetchDataForSilo = async (silo: DashboardSilo) => {
    try {
      const { data, error } = await loadDashboardDataGridAPI(
        silo.endpoint,
        auth.user!,
        selectedJurisdictions
      );
      return { data, error, loading: false };
    } catch (error) {
      return { data: [], error, loading: false };
    }
  };

  const reloadSiloData = async (dashboardSiloName: keyof typeof DASHBOARD_SILO_SEQS) => {
    const seq = dashboardSiloSeqsMap.get(dashboardSiloName) ?? null;
    if (!seq) return;

    const silo = defaultSiloMap.find(s => s.dashboardSiloSeq === seq);
    if (!silo) return;

    const { data, error } = await fetchDataForSilo(silo);
    updateSiloData(seq, { data, error, loading: false });
  };

  useEffect(() => {
    if (preferencesLoading) return;

    defaultSiloMap.forEach(silo => {
      updateSiloData(silo.dashboardSiloSeq, { loading: true });
    });
    // Function to fetch data for a single silo
    const fetchDataForSilo = async (silo: DashboardSilo) => {
      try {
        const { data, error } = await loadDashboardDataGridAPI(
          silo.endpoint,
          auth.user!,
          selectedJurisdictions
        );
        return { data, error, loading: false };
      } catch (error) {
        return { data: [], error, loading: false };
      }
    };

    defaultSiloMap.forEach(silo => {
      fetchDataForSilo(silo).then(({ data, error, loading }) => {
        updateSiloData(silo.dashboardSiloSeq, { data, error, loading });
      });
    });
  }, [preferencesLoading, selectedJurisdictions]); // Dependency array includes `user` to refetch when the user changes

  return { responses, reloadSiloData };
}
