// import { Box } from '@mui/material';
// import { DataGridPremium, GridColDef, GridToolbar } from '@mui/x-data-grid-premium';
// import { format } from 'date-fns';
// import React, { useEffect, useState } from 'react';
// import { IncidentGroupsDataGrid } from '../../components/DashboardDataGrids/IncidentGroupsDataGrid';
// import { useAuth } from '../../utils/auth/AuthService';
// import CustomPagination from '../../utils/components/CustomPagination';
// import { DASHBOARD_SILO_SEQS } from '../Dashboard/dashboard.constants';
// import { useIncident } from './IncidentContext';
// import NumericTextField from '../../utils/components/NumericTextField';

// const silo = {
//   dashboardSiloName: 'Mass Casualty Incidents',
//   endpoint: 'GetIncidentGroups',
//   Component: React.memo(IncidentGroupsDataGrid),
//   defaultSize: 6,
//   dashboardSiloSeq: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
//   layoutOrder: -1,
//   id: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
// };

// const columns: GridColDef[] = [
//   {
//     field: 'groupID',
//     headerName: 'Group ID',
//     width: 150,
//     editable: false,
//     align: 'left',
//     headerAlign: 'left',
//     headerClassName: 'header-theme',
//   },
//   {
//     field: 'incidentDescription',
//     headerName: 'Description',
//     width: 300,
//     editable: false,
//     align: 'left',
//     headerAlign: 'left',
//     headerClassName: 'header-theme',
//   },
//   {
//     field: 'incidentDateTime',
//     headerName: 'Date/Time',
//     width: 220,
//     editable: false,
//     align: 'left',
//     headerAlign: 'left',
//     headerClassName: 'header-theme',
//     type: 'dateTime',
//     valueGetter: value => {
//       return value != null ? new Date(value) : null;
//     },
//     valueFormatter: value => {
//       return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
//     },
//   },
//   {
//     field: 'caseCount',
//     headerName: 'Case Count',
//     width: 100,
//     editable: false,
//     align: 'left',
//     headerAlign: 'left',
//     headerClassName: 'header-theme',
//   },
//   {
//     field: 'isActive',
//     headerName: 'Active',
//     width: 75,
//     editable: false,
//     align: 'left',
//     headerAlign: 'left',
//     headerClassName: 'header-theme',
//   },
//   {
//     field: 'createdBy',
//     headerName: 'Created By',
//     width: 150,
//     editable: false,
//     align: 'left',
//     headerAlign: 'left',
//     headerClassName: 'header-theme',
//   },
//   {
//     field: 'createdAt',
//     headerName: 'Created At:',
//     width: 150,
//     editable: false,
//     align: 'left',
//     headerAlign: 'left',
//     headerClassName: 'header-theme',
//     valueGetter: (value: string) => {
//       return value != null ? new Date(value) : null;
//     },
//     valueFormatter: (value: string) => {
//       return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
//     },
//   },
// ];

// const DVCSDashboard = () => {
//   const { incident } = useIncident();
//   const [loading, setLoading] = useState(true);
//   const auth = useAuth();
//   const [rows, setRows] = useState<any[]>([]);
//   const { VITE_API_URL } = import.meta.env;
//   const [paginationModel, setPaginationModel] = useState<any>({
//     pageSize: 15,
//     page: 0,
//   });
//   const [caseCount, setCaseCount] = useState<number>(0);

//   const fetchData = async () => {
//     setLoading(true);

//     const formData = new FormData();
//     formData.append('JDXLIST', JSON.stringify(auth.user?.jdxAccessList));
//     formData.append('USERSEQ', auth.user?.userSeq!);

//     try {
//       const response = await fetch(`${VITE_API_URL}getIncidentGroups`, {
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${auth.user?.accessToken}`,
//         },
//         body: formData,
//       });

//       if (response.ok) {
//         const responseData = await response.json();

//         console.log(responseData);

//         setRows(responseData);
//       }
//     } catch (e) {
//       console.error(e);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   return (
//     <Box component='div' sx={{ p: 2 }}>
//       <NumericTextField
//         label='Case Count'
//         value={caseCount}
//         onChange={value => setCaseCount(Number(value))}
//       />
//       <DataGridPremium
//         rows={rows}
//         columns={columns}
//         getRowId={row => row.incidentGroupSeq}
//         disableRowSelectionOnClick
//         pagination
//         paginationModel={paginationModel}
//         onPaginationModelChange={newModel => setPaginationModel(newModel)}
//         autoHeight
//         loading={loading}
//         // onRowClick={handleRowClick}
//         slots={{ toolbar: GridToolbar, pagination: CustomPagination }}
//         slotProps={{
//           toolbar: {
//             showQuickFilter: true,
//             quickFilterProps: { debounceMs: 1 },
//             // @ts-ignore
//             showDensitySelector: true,
//           },
//           loadingOverlay: {
//             variant: 'skeleton',
//             noRowsVariant: 'skeleton',
//           },
//         }}
//         initialState={{
//           sorting: {
//             sortModel: [{ field: 'incident', sort: 'asc' }],
//           },
//           density: 'compact',
//         }}
//         sx={{
//           '& .MuiDataGrid-root': {
//             border: 'none',
//           },
//         }}
//       />
//     </Box>
//   );
// };

// export default DVCSDashboard;

import { Box, Stack } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowParams, GridToolbar } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CustomHeader from '../../utils/components/CustomHeader';
import CustomPagination from '../../utils/components/CustomPagination';
import NumericTextField from '../../utils/components/NumericTextField';
import { useIncident } from './IncidentContext';

const columns: GridColDef[] = [
  {
    field: 'groupID',
    headerName: 'Group ID',
    width: 150,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header-theme',
  },
  {
    field: 'incidentDescription',
    headerName: 'Description',
    width: 300,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header-theme',
  },
  {
    field: 'incidentDateTime',
    headerName: 'Date/Time',
    width: 220,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header-theme',
    type: 'dateTime',
    valueGetter: value => (value != null ? new Date(value) : null),
    valueFormatter: value => (value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null),
  },
  {
    field: 'caseCount',
    headerName: 'Case Count',
    width: 100,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header-theme',
  },
  {
    field: 'isActive',
    headerName: 'Active',
    width: 75,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header-theme',
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header-theme',
  },
  {
    field: 'createdAt',
    headerName: 'Created At:',
    width: 150,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header-theme',
    valueGetter: (value: string) => (value != null ? new Date(value) : null),
    valueFormatter: (value: string) =>
      value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null,
  },
];

const DVCSDashboard = () => {
  const { setIncident, dashboardRows, dashboardRowsLoading, defaultIncidentThreshold } =
    useIncident();
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [paginationModel, setPaginationModel] = useState<any>({
    pageSize: 10,
    page: 0,
  });
  const [caseCount, setCaseCount] = useState<number>(defaultIncidentThreshold);
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredRows(dashboardRows.filter(row => row.caseCount >= caseCount));
  }, [caseCount, dashboardRows]);

  const handleRowClick = (params: GridRowParams) => {
    setIncident(params.row);
    navigate('/dvcs/cases');
  };

  return (
    <Box component='div' sx={{ p: 2 }}>
      <CustomHeader
        title='DVCS Dashboard'
        description='View all disaster cases, and use the Case Count field to filter the incidents'
      />

      <Stack spacing={2}>
        <NumericTextField
          label='Case Count'
          value={caseCount}
          onChange={value => setCaseCount(Number(value))}
        />
        <DataGridPremium
          rows={filteredRows}
          columns={columns}
          getRowId={row => row.incidentGroupSeq}
          disableRowSelectionOnClick
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={newModel => setPaginationModel(newModel)}
          onRowClick={params => handleRowClick(params)}
          loading={dashboardRowsLoading}
          slots={{ toolbar: GridToolbar, pagination: CustomPagination }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 1 },
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'incident', sort: 'asc' }],
            },
            density: 'compact',
          }}
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default DVCSDashboard;
