import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Slide,
  Box,
} from '@mui/material';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { saveAdminData } from '../../../../../services/utility-api';
import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  reasonTypes,
  referencePermissions,
}) => {
  const navigate = useNavigate();
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

  useEffect(() => {}, [editObj]);

  const onSave = async (values) => {
    const id = '00000000-0000-0000-0000-000000000000';
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          [
            referenceMasterTableName[0].toLowerCase() +
              referenceMasterTableName.slice(1) +
              'Seq'
          ]: values[
            referenceMasterTableName[0].toLowerCase() +
              referenceMasterTableName.slice(1) +
              'Seq'
          ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status === 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} mb={2} mt={2}>
          <Typography variant="h5">
            <strong>
              {Object.keys(editObj).length ? 'Modify ' : 'Enter New '}{' '}
              {referenceMasterTableName.split('').map((alpha) => {
                if (alpha <= 'Z') return ' ' + alpha;
                return alpha;
              }).join('').trim()}
            </strong>
          </Typography>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          ...editObj,
          reasonDetailName: editObj['reasonDetailName'],
          reasonTypeSeq: editObj?.reasonTypeSeq,
          reasonDetailOrder: editObj?.reasonDetailOrder
            ? Math.floor(Number(editObj.reasonDetailOrder) / 10) * 10
            : maxOrder + 10,
        }}
        validationSchema={Yup.object().shape({
          reasonDetailName: Yup.string()
            .required(`${referenceMasterTableName} is required`)
            .max(
              referenceTypeFieldLengthChecks[`reasonDetailName`],
              `Reason Detail Name cannot exceed ${referenceTypeFieldLengthChecks['reasonDetailName']} characters.`
            ),
          reasonDetailOrder: Yup.number()
            .required('Reason Detail Order is required')
            .max(99999, 'Reason Detail Order cannot exceed 99999'),
          reasonTypeSeq: Yup.string().required('Reason Type is required'),
          countySeq: isCounty
            ? Yup.string().required('County is required')
            : Yup.string().nullable(),
        })}
        onSubmit={onSave}
      >
        {({ errors, handleBlur, handleSubmit, touched, values, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {isCounty && (
                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth focused>
                    <InputLabel id="countySeq">County</InputLabel>
                    <Select
                      onChange={handleChange}
                      label="County"
                      name="countySeq"
                      value={values?.countySeq || ''}
                    >
                      {Object.keys(countyNames).map((item) => (
                        <MenuItem
                          key={countyNames[item].countySeq}
                          value={countyNames[item].countySeq}
                        >
                          {countyNames[item].countyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item sm={6} xs={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <TextField
                    id="reasonDetailName"
                    label="Reason Detail Name"
                    variant="outlined"
                    name="reasonDetailName"
                    value={values.reasonDetailName}
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(
                        0,
                        referenceTypeFieldLengthChecks['reasonDetailName']
                      );
                    }}
                  />
                  <ErrorMessage
                    name="reasonDetailName"
                    component={FormHelperText}
                  />
                </FormControl>
              </Grid>

              <Grid item sm={6} xs={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="reasonTypeSeq">Reason Type</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="Reason Type"
                    name="reasonTypeSeq"
                    value={values.reasonTypeSeq || ''}
                  >
                    {reasonTypes.map((item) => (
                      <MenuItem key={item.reasonTypeSeq} value={item.reasonTypeSeq}>
                        {item.reasonTypeName}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="reasonTypeSeq"
                    component={FormHelperText}
                  />
                </FormControl>
              </Grid>

              <Grid item sm={6} xs={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <TextField
                    id="reasonDetailOrder"
                    label="List Order"
                    variant="outlined"
                    name="reasonDetailOrder"
                    type="number"
                    value={values.reasonDetailOrder}
                    onChange={handleChange}
                    onInput={(e) => {
                      const maxValue = 99999;
                      const input = e.target.value;
                      if (parseInt(input, 10) > maxValue) {
                        e.target.value = input.slice(0, input.length - 1);
                      }
                    }}
                  />
                  <ErrorMessage
                    name="reasonDetailOrder"
                    component={FormHelperText}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!referencePermissions?.add}
                  >
                    {Object.keys(editObj).length ? 'Update' : 'Save'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

      <Dialog
        open={openApiDialogBox}
        TransitionComponent={Transition}
        onClose={() => setOpenApiDialogBox(false)}
      >
        <DialogTitle>
          <IconButton onClick={() => setOpenApiDialogBox(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContents}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenApiDialogBox(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEdit;
