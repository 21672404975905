// import { Box, Button, IconButton, Tooltip } from '@mui/material';
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../utils/auth/AuthService';
// import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
// import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';

// // Define the props type
// interface NavButtonProps {
//   navigationPageTitle?: string;
//   startIcon?: React.ReactNode;
//   authorizedRoles?: string[];
//   title?: string;
//   tooltip?: string;
//   mono?: boolean;
// }

// const NavButton: React.FC<NavButtonProps> = ({
//   navigationPageTitle,
//   startIcon,
//   authorizedRoles,
//   title = '',
//   tooltip = '',
//   mono = false,
// }) => {
//   const location = window.location;
//   const navigate = useNavigate();
//   const { user } = useAuth();
//   const { VITE_URL } = import.meta.env;
//   const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
//   const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);

//   const handleNavigation = () => {
//     if (hasUnsavedChanges) {
//       setUnsavedDialogOpen(true);
//     } else {
//       navigate(`/${navigationPageTitle}`);
//     }
//   };

//   const handleUnsavedDialogLeave = () => {
//     resetUnsavedChanges();
//     setUnsavedDialogOpen(false);
//     navigate(`/${navigationPageTitle}`);
//   };

//   const handleUnsavedDialogCancel = () => {
//     setUnsavedDialogOpen(false);
//   };

//   const isActive =
//     location.pathname?.toLowerCase() === `${VITE_URL}${navigationPageTitle?.toLowerCase()}`;
//   const isAuthorized = authorizedRoles == null || user?.roleCheck(authorizedRoles);

//   return (
//     <Box component='div' sx={sx}>
//       {isAuthorized &&
//         (title !== '' ? (
//           <Button
//             variant='text'
//             color={isActive ? 'primary' : 'inherit'}
//             sx={{
//               whiteSpace: 'nowrap',
//             }}
//             startIcon={startIcon}
//             onClick={handleNavigation}
//           >
//             {title}
//           </Button>
//         ) : (
//           <Tooltip title={tooltip}>
//             <IconButton color={isActive ? 'primary' : 'inherit'} onClick={handleNavigation}>
//               {startIcon}
//             </IconButton>
//           </Tooltip>
//         ))}
//       <UnsavedChangesDialog
//         open={unsavedDialogOpen}
//         onLeave={handleUnsavedDialogLeave}
//         onCancel={handleUnsavedDialogCancel}
//       />
//     </Box>
//   );
// };

// export default NavButton;

import { Box, Button, IconButton, Tooltip, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth/AuthService';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';

// Define the props type
interface NavButtonProps {
  navigationPageTitle?: string;
  startIcon?: React.ReactNode;
  authorizedRoles?: string[];
  title?: string;
  navButtonActiveColor?: string | null;
  tooltip?: string;
  mono?: boolean;
  sx?: object;
}

const NavButton: React.FC<NavButtonProps> = ({
  navigationPageTitle,
  startIcon,
  authorizedRoles,
  title = '',
  navButtonActiveColor = null,
  tooltip = '',
  mono = false,
  sx = {},
}) => {
  const location = window.location;
  const navigate = useNavigate();
  const { user } = useAuth();
  const { VITE_URL } = import.meta.env;
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);
  const theme = useTheme();

  const handleNavigation = () => {
    if (hasUnsavedChanges) {
      setUnsavedDialogOpen(true);
    } else {
      navigate(`/${navigationPageTitle}`);
    }
  };

  const handleUnsavedDialogLeave = () => {
    resetUnsavedChanges();
    setUnsavedDialogOpen(false);
    navigate(`/${navigationPageTitle}`);
  };

  const handleUnsavedDialogCancel = () => {
    setUnsavedDialogOpen(false);
  };

  const isActive =
    location.pathname?.toLowerCase() === `${VITE_URL}${navigationPageTitle?.toLowerCase()}`;
  const isAuthorized = authorizedRoles == null || user?.roleCheck(authorizedRoles);

  return (
    <Box component='div' sx={sx}>
      {isAuthorized &&
        (title !== '' ? (
          <Button
            variant='text'
            sx={{
              whiteSpace: 'nowrap',
              color: isActive
                ? navButtonActiveColor !== null
                  ? navButtonActiveColor
                  : theme.palette.primary.main
                : 'inherit',
            }}
            startIcon={startIcon}
            onClick={handleNavigation}
          >
            {title}
          </Button>
        ) : (
          <Tooltip title={tooltip}>
            <IconButton
              onClick={handleNavigation}
              sx={{
                color: isActive
                  ? navButtonActiveColor !== null
                    ? navButtonActiveColor
                    : theme.palette.primary.main
                  : 'inherit',
              }}
            >
              {startIcon}
            </IconButton>
          </Tooltip>
        ))}
      <UnsavedChangesDialog
        open={unsavedDialogOpen}
        onLeave={handleUnsavedDialogLeave}
        onCancel={handleUnsavedDialogCancel}
      />
    </Box>
  );
};

export default NavButton;
