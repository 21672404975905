import DeleteIcon from '@mui/icons-material/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { AttachFile, Description, PictureAsPdf } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { TailSpin } from 'react-loader-spinner';
import { Alert } from 'reactstrap';
import { useAuth } from '../auth/AuthService';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import PDFThumbnailPreview from './PDFThumbnailPreview';
import { useTheme } from '@mui/material/styles';

const baseStyle = {
  minWidth: 550,
  minHeight: 400,
  border: '3px dashed #ccc',
  borderColor: '#ccc',
  borderRadius: 1,
  padding: 30,
  backgroundColor: '#eee',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function MLResourceDropzone({ isOpen, setIsOpen, onSuccessUpload }) {
  const { user } = useAuth();
  const [userSeq, setuserSeq] = useState(user?.userSeq);
  const { VITE_API_URL } = import.meta.env;
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [allDocumentType, setAllDocumentType] = useState([]);
  const [documentTypeOption, setDocumentTypeOption] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [isThumbnailDialogOpen, setIsThumbnailDialogOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [documentTitle, setDocumentTitle] = useState('');
  
  const appTheme = useTheme();
  const isLaptop = useMediaQuery(appTheme.breakpoints.up('md'));

  const handleClose = () => {
    setIsOpen(false);
    setRejectedFiles([]);
    setAcceptedFiles([]);
    setDocumentTypeOption(null);
    setSelectedFileIndex(null);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const dialogTitle = () => (
    <>
      <div style={{ paddingTop: '1rem' }}>
        {rejectedFiles.length > 0 && (
          <Alert color='danger' isOpen={showErrorAlert} toggle={dismissAlert}>
            {rejectedFiles.map((file, index) => (
              <div style={{ fontSize: '14px' }} key={index}>
                {`${index + 1}. ${file.name.split('.')[0]} : ${file.rejectReason}`}
              </div>
            ))}
          </Alert>
        )}
      </div>
    </>
  );

  const dismissAlert = () => {
    setShowErrorAlert(false);
    setRejectedFiles([]);
  };

  const handleDeleteClick = index => {
    const updatedAcceptedFiles = acceptedFiles.filter((file, i) => i !== index);
    setAcceptedFiles(updatedAcceptedFiles);
  };

  const handlePreviewIcon = (fileObject, index) => {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '150px',
          borderBottom: '1px solid #ccc',
          overflow: 'hidden',
        }}
      >
        {fileObject?.thumbnail ? (
          // <Document file={fileObject}>
          //   <Page
          //     pageNumber={fileObject?.selectedPage}
          //     width={100}
          //     height={100}
          //     scale={1}
          //     style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto' }}
          //   />
          // </Document>
          <img
            src={`data:image/jpeg;base64,${fileObject.thumbnail}`}
            style={{ position: 'relative', width: '100%', height: '150px', objectFit: 'cover' }}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <PictureAsPdfIcon style={{ width: '100px', height: '100px' }} />
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: '4px',
            left: '4px',
            backgroundColor: '#333',
            borderRadius: '50%',
            padding: '4px',
            zIndex: 1,
            cursor: 'pointer',
          }}
        >
          <EditIcon color='secondary' onClick={() => handleEditClick(index, fileObject)} />
        </div>
      </div>
    );
  };

  const handleEditClick = (fileIndex, fileObject) => {
    setSelectedPage(fileObject.selectedPage);
    setSelectedFileIndex(fileIndex);
    setDocumentTitle(fileObject.title);
    setIsThumbnailDialogOpen(true);
  };

  const handleThumbnailSelect = async () => {
    const selectedFile = acceptedFiles[selectedFileIndex];
    const thumbnailData = await generatePageContent(selectedFile, selectedPage);
    selectedFile.thumbnail = thumbnailData;
    selectedFile.selectedPage = selectedPage;
    selectedFile.title = documentTitle;
    handleThumbnailDialogClose();
  };

  const handleTitleInputChange = async value => {
    setDocumentTitle(value);
  };

  const handleThumbnailDialogClose = () => {
    setIsThumbnailDialogOpen(false);
  };

  const generatePageContent = async (file, pageNumber) => {
    try {
      const pdfURL = URL.createObjectURL(file);

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const pdf = await pdfjs.getDocument(pdfURL).promise;
      const page = await pdf.getPage(pageNumber);

      const scale = 0.1;
      const viewport = page.getViewport({ scale });
      const canvasHeight = viewport.height;
      const canvasWidth = viewport.width;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      const dataURL = canvas.toDataURL('image/png');
      const base64ImageData = dataURL.split(',')[1];

      return base64ImageData;
    } catch (error) {
      console.error('Error generating thumbnail:', error);
      return null;
    }
  };

  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    const newRejectedFiles = [];

    // Check each file to see if it has a prohibited extension
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const extension = file.name.split('.').pop();

      if (!['pdf'].includes(extension.toLowerCase())) {
        file.rejectReason = 'This file has a filetype that is not allowed';
        file.accepted = false;
        newRejectedFiles.push(file);
        acceptedFiles.splice(i, 1);
        i--;
      } else {
        file.accepted = true;
        file.thumbnail = '';
        file.selectedPage = null;
        file.title = '';
      }
    }

    setAcceptedFiles(acceptedFiles);
    setRejectedFiles(newRejectedFiles);

    if (newRejectedFiles.length > 0) {
      setShowErrorAlert(true);
    }
  };

  const handleFilesSave = files => {
    if (uploadInProgress) {
      return;
    }
    setUploadInProgress(true);

    const acceptedFiles = files.filter(file => file.accepted);
    if (acceptedFiles.length === 0) {
      setUploadInProgress(false);
      return false;
    }

    const formData = new FormData();
    acceptedFiles.forEach((file, index) => {
      formData.append(`qryParams[${index}].FILE`, file);
      formData.append(`qryParams[${index}].THUMBNAIL`, file?.thumbnail || '');
      formData.append(`qryParams[${index}].FILERELATEDTO`, documentTypeOption?.photoTypeSeq || '');
      formData.append(`qryParams[${index}].DOCUMENTLABEL`, file?.title || '');
      formData.append(`qryParams[${index}].USERSEQ`, userSeq);
    });

    if (user && user.token) {
      fetch(VITE_API_URL + 'resourcefileuploads', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          if (data.status === 0) {
            setShowErrorAlert(false);
            setIsOpen(false);
            setDocumentTypeOption(null);
            setAcceptedFiles([]);
            setRejectedFiles([]);
          } else {
            // Handle failed files
            const failedFiles = data.data || [];
            acceptedFiles.map(file => {
              const isFailed = failedFiles.some(failedFile => failedFile === file.name);
              if (isFailed) {
                file.accepted = false;
                file.rejectReason = 'File was not processed';
              }
              return file;
            });

            const updatedRejectedFiles = acceptedFiles.filter(file =>
              failedFiles.includes(file.name)
            );
            setRejectedFiles(updatedRejectedFiles);
            setShowErrorAlert(true);
            setIsOpen(true);
          }
        })
        .catch(e => {
          alert(e);
        })
        .finally(() => {
          setUploadingFiles([]);
          setUploadInProgress(false);
          const successFiles = acceptedFiles.filter(file => file.accepted);
          if (successFiles.length > 0) {
            onSuccessUpload();
          }
        });
    } else {
      console.log('user or token is null');
      setUploadingFiles([]);
      setUploadInProgress(false);
    }
    return false;
  };

  const handleDocumentTypeChange = (event, data) => {
    setDocumentTypeOption(data);
  };

  const getDocumenttypes = async () => {
    await fetch(VITE_API_URL + 'getassignmenttypes', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAllDocumentType(data);
      })
      .catch(e => {});
  };

  useEffect(() => {
    getDocumenttypes();
    setRejectedFiles([]);
    setAcceptedFiles([]);
  }, []);

  var { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'application/pdf', // Accept specific MIME type for pdf file
    maxFiles: 50,
    maxSize: 2000000000,
    onDrop: (acceptedFiles, event) => {
      handleDrop(acceptedFiles);
    },
  });

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '60%',
            maxWidth: '60%',
            margin: '0 auto',
          },
        }}
      >
        <DialogTitle>{dialogTitle()}</DialogTitle>
        <DialogContent>
          <Grid style={{ margin: '1rem 0 1rem 0' }}>
            <Autocomplete
              id='documentType'
              size='small'
              options={allDocumentType}
              value={documentTypeOption}
              getOptionLabel={option => option.photoTypeName}
              onChange={(event, data) => handleDocumentTypeChange(event, data)}
              fullWidth
              sx={{ maxWidth: '25rem' }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>{option.photoTypeName}</li>
              )}
              renderInput={params => (
                <TextField {...params} label='Department' placeholder='Department' />
              )}
            />
          </Grid>
          <Box {...getRootProps({ style: { ...style, minHeight: '80px' } })}>
            <Box sx={{ display: 'block', textAlign: 'center' }}>
              <DriveFolderUploadIcon
                fontSize='large'
                sx={{ color: '#aaa', height: 50, width: 50, marginBottom: '1rem' }}
              />
              <input {...getInputProps()} />
              <Typography variant='h6' sx={{ color: '#aaa' }}>
                Drag & drop files here, or click to select files
              </Typography>
              <Typography variant='subtitle' color='gray'>
                (Only *.pdf files will be accepted)
              </Typography>
            </Box>
          </Box>
          {acceptedFiles.length > 0 || rejectedFiles.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                padding: '16px 16px 16px 0',
              }}
            >
              {acceptedFiles.map((fileObject, index) => (
                <Card key={fileObject.path} style={{ width: '250px', position: 'relative' }}>
                  <CardContent style={{ padding: 0 }}>
                    {handlePreviewIcon(fileObject, index)}
                    <Typography
                      variant='body2'
                      component='p'
                      style={{ textAlign: 'center', margin: '4px' }}
                    >
                      <b>{fileObject.title}</b>
                    </Typography>

                    <Typography
                      variant='body2'
                      component='p'
                      style={{ textAlign: 'center', margin: '4px' }}
                    >
                      {fileObject.path}
                    </Typography>

                    <div
                      style={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        cursor: 'pointer',
                        backgroundColor: '#333',
                        borderRadius: '50%',
                        padding: '4px',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CloseIcon
                        style={{ color: 'white' }}
                        onClick={() => handleDeleteClick(index)}
                      />
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleFilesSave(acceptedFiles)}
            autoFocus
            disabled={acceptedFiles && acceptedFiles?.length === 0}
          >
            Upload
          </Button>
          <Button onClick={handleClose} color='error'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            minWidth: '50%',
            maxWidth: '50%',
            margin: '0 auto',
          },
        }}
        open={isThumbnailDialogOpen}
        onClose={handleThumbnailDialogClose}
      >
        <DialogTitle>
          <Grid item xs={12}>
            <TextField
              label='Title'
              name='Title'
              type='text'
              size='small'
              value={documentTitle}
              onChange={e => handleTitleInputChange(e.target.value)}
              fullWidth
            />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {selectedFileIndex !== null && acceptedFiles[selectedFileIndex] && (
                <PDFThumbnailPreview
                  fileObject={acceptedFiles[selectedFileIndex]}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThumbnailDialogClose}>Cancel</Button>
          <Button onClick={() => handleThumbnailSelect()}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
