import React, { useState, useEffect, useCallback } from 'react';
import { pdfjs } from 'react-pdf';
import pdfWorker from 'pdfjs';
import ImageGallery from 'react-image-gallery';
import { CircularProgress, Typography } from '@mui/material';

export default function PDFThumbnailPreview({ fileObject, selectedPage, setSelectedPage }) {
  const [thumbnails, setThumbnails] = useState([]);
  const [originals, setOriginals] = useState([]);

  pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

  const handleThumbnailClick = useCallback(
    (event, index) => {
      const pageNumber = index + 1;
      setSelectedPage(pageNumber);
    },
    [selectedPage, setSelectedPage]
  );

  const generateImages = async type => {
    const pdfURL = URL.createObjectURL(fileObject);
    const pdf = await pdfjs.getDocument(pdfURL).promise;
    const pages = [];

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: type === 'Thumbnails' ? 3 : 2 });
      const canvas = document.createElement('canvas');
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const context = canvas.getContext('2d');
      await page.render({ canvasContext: context, viewport }).promise;

      const imageDataUrl = canvas.toDataURL('image/png');
      if (type === 'Thumbnails') {
        setThumbnails(prevThumbnails => [...prevThumbnails, imageDataUrl]);
      } else {
        setOriginals(prevOriginals => [...prevOriginals, imageDataUrl]);
      }
    }

    // pages.push(canvas.toDataURL('image/png'));
    // if (type === "Thumbnails") {
    //   setThumbnails(pages);
    // } else {
    //   setOriginals(pages);
    // }
  };

  useEffect(() => {
    const generateImagesAsync = async () => {
      if (fileObject) {
        setThumbnails([]);
        generateImages('Thumbnails');
        //await generateImages("Thumbnails");
        //generateImages("Originals");
      }
    };

    generateImagesAsync();
  }, [fileObject]);

  const images = thumbnails?.map((thumbnail, index) => ({
    thumbnail: thumbnail,
    original: thumbnail, //originals[index],
    originalAlt: `Page ${index + 1}`,
    thumbnailAlt: `Page ${index + 1}`,
  }));

  if (thumbnails?.length === 0) {
    return (
      <div
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress size='2rem' />
        <div style={{ marginTop: '1rem' }}>
          <Typography>Loading Thumbnails!</Typography>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          width: '70%',
          maxWidth: '70%',
          height: '100%',
          maxHeight: '100%',
          margin: selectedPage ? '10px auto' : '50px auto',
        }}
      >
        <ImageGallery
          className='image-gallery-thumbnail'
          items={images}
          showPlayButton={false}
          showBullets={false}
          showFullscreenButton={false}
          showNav={false}
          startIndex={selectedPage - 1}
          onThumbnailClick={handleThumbnailClick}
          showIndex={selectedPage ? true : false}
          showThumbnails={true}
          lazyLoad={true}
          renderItem={item => (
            <div
              style={{
                paddingTop: '80%',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <img
                key={item.originalAlt}
                src={item.original}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
                loading='lazy'
              />
            </div>
          )}
        />
      </div>
      <style>{`
   .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 8em !important; 
  }
`}</style>
    </>
  );
}
