import {
  Box,
  Button,
  Pagination,
  PaginationItem,
  Paper,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
//@ts-ignore
import ExpandedDataGrid from '../ExpandedDataGrid';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      renderItem={(props2: any) => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

interface DataGridProps {
  loading?: boolean;
  listresults?: any[];
  columnsInput?: any[];
  gridheight?: string | number;
  idcolumn?: string;
  rowsperpage?: number;
  columnsVisibility?: Record<string, boolean>;
  gridcolor?: string;
  tooltip?: string;
  title?: string;
  checkboxSelection?: boolean;
  toolbarComponent?: any;
  onRowClick?: (params: any) => void;
  sortModel?: Array<{ field: string; sort: 'asc' | 'desc' }>;
  disableRowSelectionOnClick?: boolean;
}

const DataGridComponent: React.FC<DataGridProps> = ({
  loading = false,
  listresults = [],
  columnsInput = [],
  gridheight = '400',
  idcolumn = 'id',
  rowsperpage = 5,
  columnsVisibility = {},
  gridcolor = '#0288d1',
  tooltip = '',
  title = '',
  checkboxSelection = false,
  toolbarComponent = null,
  onRowClick = () => {},
  sortModel = [],
  disableRowSelectionOnClick = true,
}) => {
  const [updatedColumns, setUpdatedColumns] = useState<any[]>([]);
  const [updatedExpandedColumns, setUpdatedExpandedColumns] = useState<any[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: rowsperpage,
    page: 0,
  });

  const theme = useTheme();

  /**
   * MUI DataGrid bug: Using getRowHeight={() => "auto"} causes infinite row resize jittering.
   * Fix: Set resizeThrottleMs to 1000000ms (16.6min) by default, but allow 500ms of
   * unrestricted resizing when page dimensions actually change.
   * This preserves auto-height functionality while preventing unwanted resize loops.
   */
  const [resizeThrottle, setResizeThrottle] = useState(1000000);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setResizeThrottle(-1);
      setTimeout(() => setResizeThrottle(1000000), 500);
    });

    resizeObserver.observe(document.documentElement);
    return () => resizeObserver.disconnect();
  }, []);

  const preprocessColumns = () => {
    if (listresults.length > 0) {
      var count = 1;
      for (var row of listresults) {
        row.rownumber = count;
        count += 1;
      }
    }

    for (var column of columnsInput) {
      column.align = 'left';
      column.headerAlign = 'left';
      column.flex = column?.flex || 1;

      if (column.width != null) {
        column.minWidth = column.width;
      }

      if (column.field == 'id' || column.field == 'ID') {
        column.hideable = false;
      }
    }

    var exColumnsTemp = columnsInput.slice();
    exColumnsTemp.unshift({
      field: 'rownumber',
      headerName: '',
      width: 65,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      disableColumnMenu: true,
      disableExport: true,
    });

    setUpdatedColumns(columnsInput);
    setUpdatedExpandedColumns(exColumnsTemp);
  };

  useEffect(() => {
    preprocessColumns();
  }, [listresults]);

  return loading ? (
    <Stack spacing={0}>
      <Skeleton
        variant='rounded'
        animation='wave'
        height={30}
        sx={{ borderRadius: '10px 10px 3px 3px' }}
      />
      <Skeleton variant='text' animation='wave' sx={{ fontSize: '2rem' }} />
      <Skeleton
        variant='rounded'
        animation='wave'
        height={+gridheight - 40}
        sx={{ borderRadius: '3px 3px 10px 10px' }}
      />
    </Stack>
  ) : expanded ? (
    <ExpandedDataGrid
      listresults={listresults}
      columns={updatedExpandedColumns}
      setExpanded={setExpanded}
      gridcolor={gridcolor}
      idcolumn={idcolumn}
      columnsVisibility={columnsVisibility}
    />
  ) : (
    <Paper sx={{ backgroundColor: gridcolor, width: '100%' }} elevation={3}>
      <DataGridPremium
        // resizeThrottleMs={resizeThrottle}
        getRowId={row => row[idcolumn]}
        columns={updatedColumns}
        rows={listresults}
        pagination
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        checkboxSelection={checkboxSelection}
        onRowClick={onRowClick}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: '0px 0px 4px 4px',
          height: gridheight,
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        getRowHeight={() => 'auto'}
        columnHeaderHeight={32}
        initialState={{
          columns: {
            columnVisibilityModel: columnsVisibility,
          },
        }}
        slots={{
          toolbar: toolbarComponent,
          pagination: CustomPagination,
          noRowsOverlay: () => <></>,
        }}
        hideFooter={listresults.length <= rowsperpage}
        sortModel={sortModel}
      />
    </Paper>
  );
};

export default DataGridComponent;
