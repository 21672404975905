import { Paper } from '@mui/material';
import { RichTextReadOnly } from 'mui-tiptap';
import useExtensions from './useExtensions';

export default function HomeCard({ cardContent }) {
  const extensions = useExtensions({
    placeholder: '',
  });

  return (
    <Paper sx={{ p: 3, borderRadius: 4 }}>
      <RichTextReadOnly content={cardContent} extensions={extensions} />
    </Paper>
  );
}
