import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSettings } from '../../Context/AppSettingsContext';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import { Settings } from '../../views/Login/LoginView';

export default function AppTitle() {
  const navigate = useNavigate();
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
  const appName = useAppSettings().getAppSettingByKey('AppName');

  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { VITE_API_URL } = import.meta.env;

  const fetchLoginViewSettings = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${VITE_API_URL}GlobalAppSettings/getloginglobalappsettings`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch settings');
      }

      const fetchedSettings: Settings = await response.json();

      setSettings(fetchedSettings);
    } catch (err) {
      console.error('Error fetching settings:', err);
      setError('Failed to fetch settings');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoginViewSettings();
  }, []);

  const handleNavigation = () => {
    if (hasUnsavedChanges) {
      setUnsavedDialogOpen(true);
    } else {
      navigate('/home');
    }
  };

  const handleUnsavedDialogLeave = () => {
    resetUnsavedChanges();
    setUnsavedDialogOpen(false);
    navigate('/home');
  };

  const handleUnsavedDialogCancel = () => {
    setUnsavedDialogOpen(false);
  };

  return (
    <div>
      {/* REMOVE APP NAME IN FAVOR OF LOGO */}
      {/* <Typography
        variant='h5'
        noWrap
        onClick={handleNavigation}
        sx={{
          minWidth: 'max-content',
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        {appName}
      </Typography> */}

      <Box component='div'>
        <div
          className='app-bar-logo'
          dangerouslySetInnerHTML={{ __html: settings?.AppLogo ?? '' }}
          style={{ backgroundColor: 'none' }}
        />
      </Box>

      <UnsavedChangesDialog
        open={unsavedDialogOpen}
        onLeave={handleUnsavedDialogLeave}
        onCancel={handleUnsavedDialogCancel}
      />
    </div>
  );
}
