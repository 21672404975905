import { Check, Edit, Save } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
// @ts-ignore
import _ from 'lodash';
import React, { useState } from 'react';
import { User } from '../../../../../types/User';
import { useAuth } from '../../../../../utils/auth/AuthService';
import {
  BodyCategoryDto,
  DecedentType,
  ExtremityRequestDto,
  ExtremityTypeDto,
  SubExtremityTypeDto,
  TypeOfCaseDto,
  UpdateXrayRequestDto,
  UpdateXrayRequest_UpdateBodyCategory,
  UpdateXrayRequest_UpdateCaseType,
  UpdateXrayRequest_UpdateExtremity,
  XrayRequestDto,
  updateXRayRequest,
} from './xrayrequestform.constants';

import * as Yup from 'yup';
import { ActionList } from '../../../../../components/CaseView/CaseViewDateTimeField';

const validationSchema = Yup.object().shape({
  requestedBy: Yup.object().required('Requested by is required'),
  requestedOn: Yup.date().required('Requested on is required'),
  // assignedMedicalExaminer: Yup.object().required('Assigned Medical Examiner is required'),
});

export function EditXrayRequestFormModal({
  open = false,
  handleClose,
  xrayRequest,
  extremities = [],
  caseTypes = [],
  bodyCategories = [],
  users = [],
  medicalExaminerOptions = [],
  decedentTypes = [],
}: {
  open: boolean;
  handleClose: any;
  xrayRequest: XrayRequestDto;
  extremities: ExtremityTypeDto[];
  bodyCategories: BodyCategoryDto[];
  caseTypes: TypeOfCaseDto[];
  users: Partial<User>[];
  medicalExaminerOptions: Partial<Option>[];
  decedentTypes: DecedentType[];
}) {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    isInitialValid: true,
    initialValues: {
      requestedBy: users.find(
        u => u?.userSeq?.toUpperCase() === xrayRequest?.requedstedByUser?.userSeq?.toUpperCase()
      ),
      requestedOn: xrayRequest.requestedOn ? dayjs(xrayRequest.requestedOn).local() : null,
      assignedMedicalExaminer: medicalExaminerOptions.find(
        u =>
          u?.optionSeq?.toLowerCase() ===
          xrayRequest?.assignedMedicalExaminerUser?.userSeq?.toLowerCase()
      ),
      caseTypes: xrayRequest.caseTypes.filter(t => t.isChecked),
      extremitiesRequested: xrayRequest.extremitiesRequested,
      bodyCategories: xrayRequest.bodyCategories,
      specialProcedures: xrayRequest.specialProcedures,
      decedentType: xrayRequest.decedentType,
      completedBy:
        users.find(
          u => u?.userSeq?.toUpperCase() === xrayRequest?.completedByUser?.userSeq?.toUpperCase()
        ) ?? null,
      completedOn: xrayRequest.completedOn ? dayjs(xrayRequest.completedOn).local() : null,
    },

    onSubmit: async values => {
      setSaving(true);
      try {
        const isValid = await formik.validateForm();
        if (!isValid) {
          return;
        }

        const changedValues: Partial<XrayRequestDto> = Object.keys(values).reduce((acc, key) => {
          // @ts-ignore
          if (!_.isEqual(values[key], formik.initialValues[key])) {
            // @ts-ignore
            acc[key] = values[key];
          }
          return acc;
        }, {});

        const updateXRayRequestDto: Partial<UpdateXrayRequestDto> = {};
        Object.keys(changedValues).forEach(key => {
          console.log(key);
          if (key in updateXRayRequestDto) {
            // Ensure the key exists in `UpdateXrayRequestDto`
            // @ts-ignore - Ignoring TypeScript error for dynamic key assignment
            updateXRayRequestDto[key] = changedValues[key];
          }
        });

        const mapExtremityRequestDtoToUpdateXrayExtremityRequested = (
          extremity: ExtremityRequestDto
        ): UpdateXrayRequest_UpdateExtremity => {
          return {
            extremitySeq: extremity.extremitySeq,
            isChecked: extremity.isChecked,
            subExtremitySeqs: extremity.subExtremities.map(sub => sub.subExtremitySeq),
          };
        };

        if (changedValues.extremitiesRequested) {
          updateXRayRequestDto.extremitiesRequested = changedValues.extremitiesRequested.map(
            mapExtremityRequestDtoToUpdateXrayExtremityRequested
          );
        }

        const mapBodyCategoriesDtoToUpdateXrayBodyCategoriesRequested = (
          bodyCategory: BodyCategoryDto
        ): UpdateXrayRequest_UpdateBodyCategory => {
          return {
            bodyCategorySeq: bodyCategory.bodyCategorySeq!,
            isChecked: bodyCategory.isChecked ?? false,
            subAreaSeqs:
              bodyCategory?.subCategories
                ?.map(sub => sub.bodySubCategorySeq)
                .filter((seq): seq is string => seq !== undefined) ?? [],
          };
        };

        if (changedValues.bodyCategories) {
          updateXRayRequestDto.bodyCategories = changedValues.bodyCategories.map(
            mapBodyCategoriesDtoToUpdateXrayBodyCategoriesRequested
          );
        }

        const mapCaseTypesDto = (caseType: TypeOfCaseDto): UpdateXrayRequest_UpdateCaseType => {
          return {
            caseTypeSeq: caseType.typeOfCaseSeq,
            isChecked: caseType.isChecked ?? false,
          };
        };

        if (changedValues.caseTypes) {
          const updatedCaseTypes = xrayRequest.caseTypes.map(caseType => {
            const changedCaseType = changedValues.caseTypes?.find(
              changedType => changedType.typeOfCaseSeq === caseType.typeOfCaseSeq
            );

            if (changedCaseType) {
              return {
                ...changedCaseType,
                isChecked: true,
              };
            } else {
              return {
                ...caseType,
                isChecked: false,
              };
            }
          });

          const mappedCaseTypes = updatedCaseTypes.map(mapCaseTypesDto);
          updateXRayRequestDto.caseTypes = mappedCaseTypes;
        }

        const response = await updateXRayRequest(auth.user?.accessToken!, {
          requestSeq: xrayRequest.xRayRequestSeq,
          assignedMedicalExaminerUserSeq: formik.values.assignedMedicalExaminer?.optionSeq ?? null,
          bodyCategories: updateXRayRequestDto.bodyCategories ?? null,
          caseTypes: updateXRayRequestDto.caseTypes ?? null,
          extremitiesRequested: updateXRayRequestDto.extremitiesRequested ?? null,
          specialProcedures: values.specialProcedures ?? null,
          decedentTypeSeq: formik.values.decedentType
            ? formik.values.decedentType.decedentTypeSeq
            : null,
          completedBy: formik.values.completedBy,
          completedOn: values.completedOn
            ? dayjs(values.completedOn).format('YYYY-MM-DDTHH:mm:ss')
            : null,
          requestedByUserSeq: formik.values.requestedBy?.userSeq ?? null,
          requestedOn: values.requestedOn
            ? dayjs(values.requestedOn).format('YYYY-MM-DDTHH:mm:ss')
            : null, // Stripped timezone
        });

        if (response.ok) {
          setSaveAletOpen(true);
        } else {
          console.log(response.statusText);
        }
      } catch (err) {
      } finally {
        setSaving(false);
      }
    },
  });

  const auth = useAuth();

  const handleBodyCategoryChange = (categorySeq: string, checked: boolean) => {
    if (checked) {
      const categoryExists = formik.values.bodyCategories.some(
        c => c.bodyCategorySeq === categorySeq
      );
      if (!categoryExists) {
        const baseCategory = bodyCategories.find(c => c.bodyCategorySeq === categorySeq);
        if (baseCategory) {
          const newCategory: BodyCategoryDto = {
            bodyCategoryName: baseCategory.bodyCategoryName,
            bodyCategoryOnRequestSeq: null,
            bodyCategorySeq: baseCategory.bodyCategorySeq,
            isChecked: true,
            subCategories: [],
          };
          formik.setFieldValue('bodyCategories', [...formik.values.bodyCategories, newCategory]);
        }
      }
    } else {
      const updatedCategories = formik.values.bodyCategories.filter(
        c => c.bodyCategorySeq !== categorySeq
      );
      formik.setFieldValue('bodyCategories', updatedCategories);
    }
  };

  const handleSubCategoryChange = (
    parentCategory: BodyCategoryDto,
    subCategorySeq: string,
    checked: boolean
  ) => {
    const newCategories = formik.values.bodyCategories.map(category => {
      if (category.bodyCategorySeq === parentCategory.bodyCategorySeq) {
        const newSubCategories = checked
          ? [
              ...(category.subCategories || []),
              parentCategory.subCategories?.find(sc => sc.bodySubCategorySeq === subCategorySeq)!,
            ]
          : category.subCategories?.filter(sc => sc.bodySubCategorySeq !== subCategorySeq) || [];

        return {
          ...category,
          subCategories: newSubCategories,
        };
      }
      return category;
    });

    formik.setFieldValue('bodyCategories', newCategories);
  };

  const [saveAlertOpen, setSaveAletOpen] = useState(false);

  const handleCloseSaveAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      setSaveAletOpen(false);
      setEditing(false);
      return;
    }
    setEditing(false);
    setSaveAletOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setEditing(false);
        handleClose(formik.dirty);
      }}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack>
            <Typography variant='h5' fontWeight='bold'>
              {editing ? 'Editing X-Ray Request' : 'Viewing X-Ray Request'}
            </Typography>
            <Typography variant='subtitle1' color='text.secondary'>
              {`Requested by ${xrayRequest.createdByUser.userName} (${formatDistanceToNow(
                new Date(xrayRequest.createdOn),
                {
                  addSuffix: true,
                  includeSeconds: true,
                }
              )})`}
            </Typography>
            {xrayRequest.lastUpdatedByUser && xrayRequest.lastUpdatedOn && (
              <Typography variant='subtitle1' color='text.secondary'>
                {`Last updated by ${xrayRequest.lastUpdatedByUser?.userName} (${formatDistanceToNow(
                  new Date(xrayRequest.lastUpdatedOn),
                  {
                    addSuffix: true,
                    includeSeconds: true,
                  }
                )})`}
              </Typography>
            )}
          </Stack>

          <Button
            variant='outlined'
            startIcon={editing ? saving ? <CircularProgress /> : <Save /> : <Edit />}
            onClick={async e => {
              e.preventDefault();
              if (editing) {
                formik.handleSubmit();
              } else {
                setEditing(true);
              }
            }}
          >
            {editing ? 'Save' : 'Edit'}
          </Button>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ paddingTop: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={saveAlertOpen}
            autoHideDuration={6000}
            onClose={handleCloseSaveAlert}
          >
            <Alert icon={<Check fontSize='inherit' />} severity='success'>
              X-Ray request successfully updated
            </Alert>
          </Snackbar>
          <form onSubmit={formik.handleSubmit}>
            <Stack mt={1}>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                }}
                spacing={1}
              >
                <Autocomplete
                  disabled={true}
                  disablePortal
                  autoHighlight
                  value={formik.values.requestedBy}
                  isOptionEqualToValue={(o, v) =>
                    o.userSeq?.toLowerCase() === v.userSeq?.toLowerCase()
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('requestedBy', newValue);
                  }}
                  inputMode='text'
                  getOptionLabel={u => `${u.userLastName}, ${u.userFirstName}`}
                  options={users}
                  renderOption={(props, option) => (
                    <li {...props} key={option?.userSeq}>
                      {`${option.userLastName}, ${option.userFirstName}`}
                    </li>
                  )}
                  sx={{ flex: 1 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Requested by'
                      error={Boolean(formik.touched.requestedBy && formik.errors.requestedBy)}
                      // @ts-ignore
                      helperText={formik.touched.requestedBy && formik.errors.requestedBy}
                    />
                  )}
                />
                <FormControl
                  sx={{ flex: 1 }}
                  error={Boolean(formik.touched.requestedOn && formik.errors.requestedOn)}
                >
                  <DateTimePicker
                    disabled={!editing || saving}
                    label='Requested on'
                    value={
                      formik.values.requestedOn !== null ? dayjs(formik.values.requestedOn) : null
                    }
                    onChange={(date: dayjs.Dayjs | null): void => {
                      formik.setFieldValue('requestedOn', date ? dayjs(date).local() : null);
                    }}
                    format='MM/DD/YYYY, HH:mm' // Updated format to exclude timezone
                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                    slots={{
                      actionBar: ActionList,
                    }}
                    ampm={false}
                    sx={{ width: '100%' }}
                  />

                  {/* {formik.touched.requestedOn && formik.errors.requestedOn && (
                    <FormHelperText>{formik.errors.requestedOn}</FormHelperText>
                  )} */}
                </FormControl>
                <Autocomplete
                  disabled={!editing || saving}
                  disablePortal
                  autoHighlight
                  inputMode='text'
                  value={formik.values.assignedMedicalExaminer}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('assignedMedicalExaminer', newValue);
                  }}
                  options={medicalExaminerOptions}
                  getOptionLabel={o => String(o?.optionName)}
                  renderOption={(props, option) => (
                    <li {...props} key={option?.optionSeq}>
                      {`${option.optionName}`}
                    </li>
                  )}
                  sx={{ flex: 1 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Assigned Medical Examiner'
                      error={Boolean(
                        formik.touched.assignedMedicalExaminer &&
                          formik.errors.assignedMedicalExaminer
                      )}
                    />
                  )}
                />
                <Autocomplete
                  disabled={!editing || saving}
                  autoHighlight
                  multiple={true}
                  limitTags={1}
                  inputMode='text'
                  getOptionLabel={o => o.typeOfCaseName}
                  value={formik.values.caseTypes}
                  isOptionEqualToValue={(o, v) =>
                    o.typeOfCaseSeq.toLowerCase() === v.typeOfCaseSeq.toLowerCase()
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('caseTypes', newValue);
                  }}
                  options={caseTypes}
                  sx={{ flex: 2 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Case Type'
                      helperText={editing ? 'Select all that apply' : null}
                      required={editing}
                    />
                  )}
                />
                <Autocomplete
                  disabled={!editing || saving}
                  autoHighlight
                  disablePortal
                  multiple={false}
                  inputMode='text'
                  value={formik.values.decedentType}
                  isOptionEqualToValue={(o, v) =>
                    o.decedentTypeSeq.toLowerCase() === v.decedentTypeSeq.toLowerCase()
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('decedentType', newValue);
                  }}
                  getOptionLabel={option => option.decedentTypeName}
                  options={decedentTypes}
                  renderInput={params => <TextField {...params} label='Decedent Type' />}
                  sx={{ flex: 1 }}
                />
              </Stack>

              <Box component='div' mt={2}>
                <Typography variant='h6' component='h6'>
                  Areas
                </Typography>

                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                  spacing={1}
                >
                  {bodyCategories.map(category => {
                    const parentCategory =
                      formik.values.bodyCategories.find(
                        c => c.bodyCategorySeq === category.bodyCategorySeq
                      ) ?? null;
                    const isChecked = (parentCategory && parentCategory.isChecked) ?? false;

                    const subCategories = category.subCategories!;

                    return (
                      <Stack>
                        <FormControlLabel
                          label={category.bodyCategoryName}
                          control={
                            <Checkbox
                              disabled={!editing || saving}
                              size='small'
                              checked={isChecked}
                              onChange={(event, checked) =>
                                handleBodyCategoryChange(category.bodyCategorySeq!, checked)
                              }
                            />
                          }
                        />
                        <Collapse in={isChecked}>
                          {subCategories.map(subCategory => {
                            const isSubCategoryChecked =
                              parentCategory?.subCategories?.some(
                                sc => sc.bodySubCategorySeq === subCategory.bodySubCategorySeq
                              ) ?? false;
                            return (
                              <FormControlLabel
                                key={subCategory.bodySubCategorySeq}
                                label={subCategory.bodySubCategoryName}
                                control={
                                  <Checkbox
                                    disabled={!editing || saving}
                                    sx={{ ml: 3 }}
                                    size='small'
                                    checked={isSubCategoryChecked}
                                    onChange={(event, checked) =>
                                      handleSubCategoryChange(
                                        category,
                                        subCategory.bodySubCategorySeq!,
                                        checked
                                      )
                                    }
                                  />
                                }
                              />
                            );
                          })}
                        </Collapse>
                      </Stack>
                    );
                  })}
                </Stack>
              </Box>

              <ExtremityCheckboxes
                extremities={formik.values.extremitiesRequested}
                extremityTypes={extremities}
                setFieldValue={formik.setFieldValue}
                editing={editing}
                saving={saving}
              />

              <TextField
                sx={{ mt: 2 }}
                disabled={!editing || saving}
                fullWidth
                name='specialProcedures'
                placeholder='Special procedure notes'
                value={formik.values.specialProcedures}
                onChange={formik.handleChange}
              />
            </Stack>

            <Stack direction='row' spacing={1} mt={2}>
              <Autocomplete
                disabled={!editing || saving}
                value={formik.values.completedBy}
                onChange={(event, newValue) => {
                  formik.setFieldValue('completedBy', newValue);
                }}
                inputMode='text'
                getOptionLabel={u => `${u.userLastName}, ${u.userFirstName}`}
                options={users}
                isOptionEqualToValue={(o, v) =>
                  o.userSeq?.toLowerCase() === v.userSeq?.toLowerCase()
                }
                renderOption={(props, option) => (
                  <li {...props} key={option?.userSeq}>
                    {`${option.userLastName}, ${option.userFirstName}`}
                  </li>
                )}
                sx={{ width: 300 }}
                renderInput={params => (
                  <TextField {...params} label='Completed by' required={editing} />
                )}
              />
              <FormControl sx={{ flex: 1 }}>
                <DateTimePicker
                  disabled={!editing || saving}
                  label='Completed on'
                  value={
                    formik.values.completedOn !== null ? dayjs(formik.values.completedOn) : null
                  }
                  onChange={(date: dayjs.Dayjs | null): void => {
                    formik.setFieldValue('completedOn', date ? dayjs(date).local() : null);
                  }}
                  format='MM/DD/YYYY, HH:mm'
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  ampm={false}
                  sx={{ width: '100%' }}
                />
              </FormControl>
            </Stack>
          </form>
        </LocalizationProvider>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={saving}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ExtremityCheckboxes({
  extremities,
  setFieldValue,
  extremityTypes,
  editing,
  saving,
}: {
  extremities: XrayRequestDto['extremitiesRequested'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  extremityTypes: ExtremityTypeDto[];
  editing: boolean;
  saving: boolean;
}) {
  const handleExtremityChange = (
    extremitySeq: string,
    extremityName: string,
    isChecked: boolean
  ) => {
    const existingExtremity = extremities.find(e => e.extremitySeq === extremitySeq);

    if (existingExtremity) {
      // If the extremity already exists, update its isChecked property
      const updatedExtremities = extremities.map(e => {
        if (e.extremitySeq === extremitySeq) {
          return {
            ...e,
            isChecked,
          };
        }
        return e;
      });
      setFieldValue('extremitiesRequested', updatedExtremities);
    } else {
      // If the extremity doesn't exist, add a new one to the array
      const newExtremity: ExtremityRequestDto = {
        extremityOnRequestSeq: '',
        extremitySeq,
        extremityName,
        isChecked,
        isUpper: false,
        isLower: false,
        subExtremities: [],
      };
      setFieldValue('extremitiesRequested', [...extremities, newExtremity]);
    }
  };

  const handleSubExtremityChange = (
    extremitySeq: string,
    subExtremitySeq: string,
    isChecked: boolean
  ) => {
    const updatedExtremities = extremities.map(e => {
      if (e.extremitySeq === extremitySeq) {
        const updatedSubExtremities = isChecked
          ? [...e.subExtremities, { subExtremitySeq, subExtremityName: '' }]
          : e.subExtremities.filter(se => se.subExtremitySeq !== subExtremitySeq);
        return { ...e, subExtremities: updatedSubExtremities };
      }
      return e;
    });
    setFieldValue('extremitiesRequested', updatedExtremities);
  };

  const renderSubExtremities = (extremitySeq: string, subExtremities: SubExtremityTypeDto[]) =>
    subExtremities.map(se => {
      const extremity = extremities.find(e => e.extremitySeq === extremitySeq);
      const isChecked =
        extremity?.subExtremities.some(sub => sub.subExtremitySeq === se.subExtremitySeq) ?? false;
      return (
        <FormControlLabel
          sx={{ ml: 2 }}
          label={se.subExtremityName}
          control={
            <Checkbox
              disabled={!editing || saving}
              size='small'
              checked={isChecked}
              onChange={(event, checked) =>
                handleSubExtremityChange(extremitySeq, se.subExtremitySeq, checked)
              }
            />
          }
        />
      );
    });

  return (
    <Stack direction='row' justifyContent='flex-start' spacing={2} mt={2}>
      <Stack>
        <Typography variant='h6' component='h6'>
          Upper Extremities
        </Typography>

        {extremityTypes
          .filter(e => e.isUpper)
          .map(e => {
            const extremity = extremities.find(er => er.extremitySeq === e.extremitySeq);
            const subExtremitiesLength = extremity?.subExtremities?.length ?? 0;
            const isChecked = (extremity?.isChecked ?? false) || subExtremitiesLength > 0;
            return (
              <React.Fragment>
                <FormControlLabel
                  label={e.extremityName}
                  control={
                    <Checkbox
                      disabled={!editing || saving}
                      size='small'
                      checked={isChecked}
                      onChange={(event, checked) =>
                        handleExtremityChange(e.extremitySeq, e.extremityName, checked)
                      }
                    />
                  }
                />
                <Collapse in={isChecked}>
                  {renderSubExtremities(e.extremitySeq, e.subExtremityTypes)}
                </Collapse>
              </React.Fragment>
            );
          })}
      </Stack>
      <Stack>
        <Typography variant='h6' component='h6'>
          Lower Extremities
        </Typography>
        {extremityTypes
          .filter(e => e.isLower)
          .map(e => {
            const extremity = extremities.find(er => er.extremitySeq === e.extremitySeq);
            const subExtremitiesLength = extremity?.subExtremities?.length ?? 0;
            const isChecked = (extremity?.isChecked ?? false) || subExtremitiesLength > 0;
            return (
              <React.Fragment>
                <FormControlLabel
                  label={e.extremityName}
                  control={
                    <Checkbox
                      disabled={!editing || saving}
                      size='small'
                      checked={isChecked}
                      onChange={(event, checked) =>
                        handleExtremityChange(e.extremitySeq, e.extremityName, checked)
                      }
                    />
                  }
                />

                <Collapse in={isChecked}>
                  {renderSubExtremities(e.extremitySeq, e.subExtremityTypes)}
                </Collapse>
              </React.Fragment>
            );
          })}
      </Stack>
    </Stack>
  );
}
