import React from 'react';
import HL7Uploader from './ToolsModules/hl7uploaderView';
import BulkFileUpload from '../../../../components/CaseRecords/BulkFileUploadModule';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';
import { Box, Grid } from '@mui/material';

export default function ToolsView() {
  const { user } = useAuth();
  const hasEFARole = user?.roleCheck(['EXPERIMENTAL-FEATURES']);
  
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <BulkFileUpload />
        </Grid>
        {hasEFARole && (
        <Grid item xs={12} md={6}>
          <HL7Uploader />
        </Grid>
        )}
      </Grid>
    </Box>
  );
}
