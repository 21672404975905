import React, { useState, useEffect } from 'react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';

import { Box, Button, Grid, TextField, FormHelperText } from '@mui/material';
// import { gridSpacing } from 'redux/store/constant';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';

import axios from 'axios';
import { MMDDYYYY } from '../../../../services/utility-api';
import authHeader from '../../../../services/auth-header';
import { logDOM } from '@testing-library/react';
import ApiNotification from '../../../../components/DialogBox';
import { ActionList } from '../../../../components/CaseView/CaseViewDateTimeField';
// import AccessDenied from 'ui-component/AccessDenied';

const CaseVisitSearchFilter = ({ setRows, setShowTable }) => {
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [startDate, setStartDate] = useState(dayjs(new Date().setDate(new Date().getDate() - 30)));
  // const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const onSearch = async values => {
    if (MMDDYYYY(startDate).includes('01/01/1970')) {
      setDialogContents('Start Date Required!');
      setOpenApiDialogBox(true);
    } else if (MMDDYYYY(endDate).includes('01/01/1970')) {
      setDialogContents('End Date Required!');
      setOpenApiDialogBox(true);
    } else {
      let formattedCaseId = values.caseId.split('-').join('');
      formattedCaseId =
        formattedCaseId.slice(0, 2) +
        '-' +
        formattedCaseId.slice(2, 4) +
        '-' +
        formattedCaseId.slice(4);
      const response = await axios.get(
        `${
          import.meta.env.VITE_API_URL
        }admin/GetCaseVisitHistory?caseId=${formattedCaseId}&stDate=${MMDDYYYY(
          startDate
        )}&enDate=${MMDDYYYY(endDate)}`,
        {
          headers: authHeader(),
        }
      );
      setRows(
        response.data.data.map(row => {
          return {
            id: row.caseVisitHistorySeq,
            fullName: row.fullName,
            caseID: formattedCaseId,
            mac: row.mac,
            ip: row.ip,
            visitTime: row.visitTime.split('T').join(' '),
            status: row.status,
          };
        })
      );
      setShowTable(true);
    }
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleClear = () => {
    setEndDate(null);
    setStartDate(null);
  };

  // if (!permissions?.['Case Visit']?.view) {
  //     return <AccessDenied />;
  // } else {
  return (
    <>
      <Formik
        initialValues={{
          caseId: '',
          startDate: null,
          endDate: null,
        }}
        validationSchema={Yup.object().shape({
          caseId: Yup.string('').required('Case ID is required!').nullable(),
        })}
        onSubmit={onSearch}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          handleChange,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <FormControl error required component='fieldset' variant='standard' fullWidth>
                        <TextField
                          id='outlined-basic'
                          label='Case ID'
                          name='caseId'
                          required
                          value={values.caseId}
                          onChange={handleChange}
                          variant='outlined'
                          inputProps={{ maxLength: 15 }}
                          // error={errors.caseId}
                        />
                        <ErrorMessage
                          name='caseId'
                          render={msg => <FormHelperText error>{msg}</FormHelperText>}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <FormControl error required component='fieldset' variant='standard' fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='Start Date'
                            value={startDate}
                            name='startDate'
                            onChange={handleStartDateChange}
                            inputVariant='outlined'
                            dateFormat='MM/dd/yyyy'
                            clearable
                            // disableFuture
                            maxDate={endDate}
                            slotProps={{
                              textField: {
                                required: true,
                              },
                            }}
                            slots={{
                              actionBar: ActionList,
                            }}
                          />
                          <ErrorMessage
                            name='startDate'
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <FormControl error required component='fieldset' variant='standard' fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='End Date'
                            value={endDate}
                            name='endDate'
                            onChange={handleEndDateChange}
                            inputVariant='outlined'
                            dateFormat='MM/dd/yyyy'
                            clearable
                            // disableFuture
                            minDate={startDate}
                            slotProps={{
                              textField: {
                                required: true,
                              },
                            }}
                            slots={{
                              actionBar: ActionList,
                            }}
                          />
                          <ErrorMessage
                            name='endDate'
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={6} md={6} lg={6}>
                          <Box sx={{ mt: 1 }}>
                            <FormControl fullWidth>
                              <Button
                                size='large'
                                type='submit'
                                variant='contained'
                                color='primary'
                                onClick={handleSubmit}
                                sx={{ padding: '6px' }}
                              >
                                <SearchIcon />
                              </Button>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item sm={6} xs={6} md={6} lg={6}>
                          <Box
                            sx={{ mt: 1 }}
                            onClick={() => {
                              resetForm();
                              setShowTable(false);
                            }}
                          >
                            <Button variant='contained' onClick={handleClear} fullWidth>
                              Clear
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Formik>
      <ApiNotification
        openApiDialogBox={openApiDialogBox}
        setOpenApiDialogBox={setOpenApiDialogBox}
        dialogContents={dialogContents}
        closeDialogBox={() => {
          setOpenApiDialogBox(false);
        }}
      />
    </>
  );
  // }
};

export default CaseVisitSearchFilter;
