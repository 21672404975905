import { OrbitControls, useTexture } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { XR, createXRStore } from '@react-three/xr';

const store = createXRStore({
  emulate: true,
});

const SphereWithTexture = ({ image }: { image: string }) => {
  const texture = useTexture(image);

  return (
    <mesh>
      <sphereGeometry args={[5, 64, 64]} />
      <meshBasicMaterial map={texture} side={2} />
    </mesh>
  );
};

const Viewer360 = ({ image }: { image: string }) => {
  return (
    <>
      {import.meta.env.DEV && <button onClick={() => store.enterAR()}>Enter VR</button>}
      <Canvas camera={{ position: [0, 0, 0.1] }} style={{ height: 650 }}>
        <XR store={store}>
          <OrbitControls enableZoom={false} />
          <SphereWithTexture image={image} />
        </XR>
      </Canvas>
    </>
  );
};

export default Viewer360;
