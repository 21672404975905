import React, { useRef, useState, useEffect } from 'react';
import './GenerateBlankRIME.css';
import RimeReportFirst from './assets/rime_report/RimeReportFirstNew.png';
import RimeReportSecond from './assets/rime_report/RimeReportSecond.png';
// import { PDFExport } from '@progress/kendo-react-pdf';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import { RIME_REPORT_LOGOS } from './constants';
import { TextField } from '@mui/material';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
// import AccessDenied from 'ui-component/AccessDenied';
import { useReactToPrint } from 'react-to-print';
import authHeader from '../../../../services/auth-header';
// import Loader from 'ui-component/Loader';

function GenerateBlankRIMEView() {
    const ref = useRef(null);
    // const dispatch = useDispatch();
    const [checked, setChecked] = useState('all');

    const [skillsnew, setSkillsnew] = useState([]);
    const [countyDrop, setCountyDrop] = useState([]);
    const [countyvalueget, setCountyvalueget] = useState('');
    const [roles, setRoles] = useState([]);

    const [firstlast, setFirstlast] = useState('');
    const [roledropFilter, setRoledropFilter] = useState('');
    const [countydropFilter, setCountydropFilter] = useState('');
    // const user_details = useSelector((state) => state?.userData?.user_details);
    const [dataGrids, setDataGrids] = useState(roles);
    const [fax, setFax] = useState('');
    // const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
    const [isLoading, setIsLoading] = useState(true);
    const [rolesDrop, setRolesDrop] = useState([]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const allFieldFilter = (userName, roledrop, Countydrop) => {
        setCountydropFilter(Countydrop);
        setRoledropFilter(roledrop);
        setFirstlast(userName.toLowerCase());
    };

    useEffect(() => {
        allFieldFilter(firstlast, roledropFilter, countydropFilter);
    }, [checked]);

    const FormikAutocomplete = ({ textFieldProps, ...props }) => {
        return (
            <Autocomplete
                {...props}
                onChange={(e, v) => {
                    allFieldFilter(firstlast, roledropFilter, v?.code ? v.code : '');
                    setCountyvalueget(v?.label ? v.label : '');
                }}
                value={countyvalueget}
                getOptionSelected={(item, current) => item.value === current.value}
                renderInput={(props) => <TextField {...props} label="Jurisdiction" />}
            />
        );
    };

    const getRoles = async (para) => {
        setIsLoading(true);
        const response = await axios.get(`${import.meta.env.VITE_API_URL}GetUserCountyDetails`);
        setRoles(response.data.data);
        setIsLoading(false);
        return;
    };

    const getCounty = async (para) => {
        setIsLoading(true);
        const response = await axios.get(`${import.meta.env.VITE_API_URL}GetAllCounty`);
        setCountyDrop(response.data.data);

        let newData = [];

        response.data.data.map((data) => {
            newData.push({ label: data.countyName, value: data.countySeq, code: data.code });
            data.children.map((children) => newData.push({ label: children.countyName, value: children.countySeq, code: children.code }));
        });
        setSkillsnew(newData.filter((val) => val.value !== '17501e53-d1cd-4828-abe6-8e124b0ad35d').sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        }));
        setIsLoading(false);
    };

    const [toggle, setToggle] = useState(false);
    const [showReport, setShowReport] = useState(false);

    const [pdfData, setPdfData] = useState({});

    useEffect(() => {
        if (!pdfData || Object.keys(pdfData ?? {}).length !== 0) {
            const renderPdf = () => {
                setToggle(true);
                setShowReport(true);
            };
            renderPdf();
        }
    }, [pdfData]);

    useEffect(() => {
        if (toggle) {
            handlePrint();
            setShowReport(false);
        }
    }, [showReport]);

    const [errorMessage, setErrorMessage] = useState(null);

    const fetchPdf = async () => {
        setIsLoading(true);
        try {
            axios
                .get(`${import.meta.env.VITE_API_URL}GetGenerateBlankRimeData?CountyName=${countyvalueget}`, {
                    headers: authHeader(),
                })
                .then((response) => {
                    setPdfData(response.data.data[0]);
                    if (response?.data?.data?.[1]) {
                        setFax(response?.data?.data?.[1]?.contact);
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log('error', error);
            return;
        }
        setIsLoading(false);
    };

    const getRolesData = async (para) => {
        setIsLoading(true);
        const response = await axios.get(`${import.meta.env.VITE_API_URL}GetAllRole`);
        setRolesDrop(response.data.data);
        setIsLoading(false);
        return;
    };

    useEffect(() => {
        getCounty();
        getRoles();
        getRolesData();
    }, []);

    const fetchLogo = () => {
        const splitUrl = pdfData?.imageUrl?.split('/');
    
        const fetchTheLogo =
            splitUrl
                ?.filter((_, i) => i === 2)?.[0]
                ?.split('.')
                ?.filter((_, i) => i === 0)?.[0] ?? '';
                
        return RIME_REPORT_LOGOS?.[fetchTheLogo] ?? '';
    };
    

    // if (isLoading) {
    //     return <Loader />;
    // } else if (!permissions?.['Generate Blank RIME']?.view) {
    //     return <AccessDenied />;
    // } else {
    return (
        <Formik
            initialValues={{
                role: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({})}
        >
            <FormControl error required component="fieldset" variant="standard" style={{ width: '100%' }}>
                <Wrapper>
                    <Field
                        name="skillsnew"
                        style={{ width: '100%', paddingRight: '20px' }}
                        component={FormikAutocomplete}
                        label="Skillsnew"
                        size="small"
                        options={skillsnew}
                        textFieldProps={{
                            variant: 'outlined'
                        }}
                    />

                    <StyledButton
                        onClick={() => {
                            if (!countyvalueget) {
                                setErrorMessage('Please Select Jurisdiction');
                            } else {
                                fetchPdf();
                            }
                        }}
                    >
                        Generate RIME
                    </StyledButton>
                </Wrapper>
                {errorMessage && !countyvalueget && <StyledSelectCounty>{errorMessage}</StyledSelectCounty>}

                {pdfData ? (
                    <div ref={componentRef} className={toggle ? 'showReport' : 'hideReport'}>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="headerContainer">
                                    <div className="logo">
                                        <img src={fetchLogo()} alt={pdfData?.logo} style={{ transform: 'rotate(270deg)' }} />
                                    </div>
                                    <div className="addressContainer">
                                        <div className="style-address-border">
                                            <p>{pdfData?.reportHeaderName}</p>
                                            <p>{pdfData?.addressLine}</p>
                                            <p>{`${pdfData?.city}, ${pdfData?.stateName}`}</p>
                                            <p>Zip Code: {pdfData?.zip}</p>
                                            <p>{pdfData?.contact + ' ' + fax}</p>
                                        </div>

                                        <div className="style-county">
                                            <p>{pdfData?.countyName}</p>
                                        </div>
                                    </div>
                                    <div className="caseIdentification">
                                        <p>Case Identification Number</p>
                                    </div>
                                </div>
                                <img className="first-form-style" src={RimeReportFirst} alt="first report" />

                                <div className="align-form">
                                    <img className="second-form-style" src={RimeReportSecond} alt="second report" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <StyledSelectCounty>
                        <p>No Data.</p>
                    </StyledSelectCounty>
                )}
            </FormControl>
        </Formik>
    );
    // }
}

export default GenerateBlankRIMEView;

export const StyledButton = styled.button`
    border-radius: 6px;
    background-color: #2196f3;
    color: #ffffff;
    width: 200px;
    border: none;
    float: right;
    text-align: center;
    cursor: pointer;
    @media (max-width: 768px) {
        margin-top: 15px;
        font-size: 12px;
        width: 140px;
        padding: 10px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 40%;
    @media (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const StyledSelectCounty = styled.div`
    height: 60vh;
    padding: 0px 30px;
    color: red;
`;

