import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../../App.css';
import CondensedReportDataGrid from './CondensedReportDataGrid';
import ExpandedReportDataGrid from './ExpandedReportDataGrid';

export default function ReportDataGrid({
  loading = false,
  listresults,
  columnsInput,
  title = null,
  headerAlignment = 'left',
  columnAlignment = 'left',
  chatcontent = null,
  idcolumn,
  casecount = null,
  rowsperpage = 5,
  customButtons = [],
  ...props
}) {
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  useEffect(() => {
    var count = 1;
    for (var row of listresults) {
      row.rownumber = count;
      count += 1;
    }

    for (var column of columnsInput) {
      column.align = columnAlignment;
      column.headerAlign = headerAlignment;
      column.headerClassName = 'header-theme';
      column.flex = 1;

      if (column.width != null) {
        column.minWidth = column.width;
      }

      if (column.field == 'caseid' || column.field == 'caseID') {
        column.renderCell = params => {
          return (
            <Button
              variant='text'
              color='primary'
              sx={{ fontFamily: 'DataGrid, sans-serif', fontWeight: 700 }}
              onClick={event => {
                handleCaseClick(event, params);
              }}
            >
              {params.formattedValue}
            </Button>
          );
        };
      }

      if (column.field == 'id' || column.field == 'ID') {
        column.hideable = false;
      }
    }

    setUpdatedColumns(columnsInput);
  }, [listresults]);

  return expanded ? (
    <ExpandedReportDataGrid
      {...props}
      loading={loading}
      rows={listresults ?? []}
      columns={updatedColumns}
      idcolumn={idcolumn}
      expanded={expanded}
      setExpanded={setExpanded}
      customButtons={customButtons}
    />
  ) : (
    <CondensedReportDataGrid
      {...props}
      loading={loading}
      columns={updatedColumns}
      rows={listresults ?? []}
      idcolumn={idcolumn}
      title={title}
      chatcontent={chatcontent}
      casecount={casecount}
      rowsperpage={rowsperpage}
      expanded={expanded}
      setExpanded={setExpanded}
      customButtons={customButtons}
    />
  );
}
