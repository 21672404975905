import { Close, Send, TextFields } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import type { EditorOptions } from '@tiptap/core';
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from 'mui-tiptap';
import { useCallback, useEffect, useRef, useState } from 'react';
import EditorMenuControls from '../../views/Home/EditorMenuControls';
import useExtensions from '../../views/Home/useExtensions';
import { useConversation } from '../../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';

// Function to convert File to base64
async function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

// Function to resize image
async function resizeImage(file: File, maxWidth = 600, maxHeight = 600): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Could not create blob'));
          }
        },
        file.type,
        0.85 // compression quality
      );
    };
    img.onerror = () => reject(new Error('Could not load image'));
  });
}

interface RichTextEditorProps {
  initialContent?: string;
  onSend?: (content: string, attachments: File[]) => Promise<void>;
  placeholder?: string;
  sx?: any;
  showPreview?: boolean;
  preventEscapeKeyClosing?: boolean;
  isConversationClosed?: boolean;
  requireConfirmation?: boolean;
}

export default function EnhancedRichTextEditor({
  initialContent = '',
  onSend,
  placeholder = 'Type a message...',
  sx,
  showPreview = false,
  preventEscapeKeyClosing = false,
  isConversationClosed = false,
  requireConfirmation = false,
}: RichTextEditorProps) {
  const { selectedChat, emitTypingIndicator } = useConversation();
  const extensions = useExtensions({ placeholder });
  const rteRef = useRef<RichTextEditorRef>(null);
  const [content, setContent] = useState(initialContent);
  const [isEditable, setIsEditable] = useState(true);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [previews, setPreviews] = useState<{ file: File; url: string }[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isReopenDialogOpen, setIsReopenDialogOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const processFiles = useCallback(
    async (files: File[]) => {
      const newFiles = files.filter(file => !attachments.some(a => a.name === file.name));

      for (const file of newFiles) {
        try {
          let processedFile = file;
          if (file.type.startsWith('image/')) {
            const resizedBlob = await resizeImage(file);
            processedFile = new File([resizedBlob], file.name, { type: file.type });
          }
          const previewUrl = URL.createObjectURL(processedFile);
          setPreviews(prev => [...prev, { file: processedFile, url: previewUrl }]);
          setAttachments(prev => [...prev, processedFile]);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      }
    },
    [attachments]
  );

  const removeAttachment = (fileToRemove: File) => {
    setAttachments(prev => prev.filter(file => file !== fileToRemove));
    setPreviews(prev => {
      const filteredPreviews = prev.filter(preview => preview.file !== fileToRemove);
      const removedPreview = prev.find(preview => preview.file === fileToRemove);
      if (removedPreview) {
        URL.revokeObjectURL(removedPreview.url);
      }
      return filteredPreviews;
    });
  };

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      processFiles(selectedFiles);
    }
    e.target.value = '';
  };

  const handleSendMessage = async () => {
    if (!onSend) return;

    try {
      setIsSending(true);
      await onSend(content, attachments);
      setContent('');
      previews.forEach(preview => URL.revokeObjectURL(preview.url));
      setPreviews([]);
      setAttachments([]);
      setIsConfirmDialogOpen(false);
      setIsReopenDialogOpen(false);

      if (rteRef.current?.editor) {
        rteRef.current.editor.commands.setContent('');
      }
    } finally {
      setIsSending(false);
    }
  };

  const handleSendClick = () => {
    if (isConversationClosed) {
      setIsConfirmDialogOpen(false);
      setIsReopenDialogOpen(true);
      return;
    }

    if (requireConfirmation) {
      setIsConfirmDialogOpen(true);
    } else {
      handleSendMessage();
    }
  };

  const handleNewImageFiles = useCallback(async (files: File[], insertPosition?: number) => {
    if (!rteRef.current?.editor) {
      return;
    }

    try {
      const processedImages = await Promise.all(
        files.map(async file => {
          const resizedBlob = await resizeImage(file);
          const resizedFile = new File([resizedBlob], file.name, { type: file.type });
          const base64 = await convertToBase64(resizedFile);
          return { src: base64, alt: file.name };
        })
      );

      insertImages({
        images: processedImages,
        editor: rteRef.current.editor,
        position: insertPosition,
      });
    } catch (error) {
      console.error('Image processing error:', error);
    }
  }, []);

  const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = Array.from(event.dataTransfer.files).filter(file =>
        file.type.startsWith('image/')
      );
      if (imageFiles.length > 0) {
        const position = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, position);
        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = Array.from(event.clipboardData.files).filter(file =>
        file.type.startsWith('image/')
      );
      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const renderAttachmentPreviews = (previewsToRender: typeof previews) => (
    <Box component='div' sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 2 }}>
      {previewsToRender.map(({ file, url }) => (
        <Box
          component='div'
          key={file.name}
          sx={{
            position: 'relative',
            width: 100,
            height: 100,
            borderRadius: 1,
            overflow: 'hidden',
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          {file.type.startsWith('image/') ? (
            <img
              src={url}
              alt={file.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Box
              component='div'
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'action.hover',
              }}
            >
              <Typography variant='caption' sx={{ p: 1, textAlign: 'center' }}>
                {file.name}
              </Typography>
            </Box>
          )}
          <IconButton
            size='small'
            onClick={() => removeAttachment(file)}
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <Close fontSize='small' />
          </IconButton>
        </Box>
      ))}
    </Box>
  );

  useEffect(() => {
    const editor = rteRef.current?.editor;
    if (!editor) return;

    const updateHandler = () => {
      if (selectedChat) {
        emitTypingIndicator(selectedChat?.conversationSeq);
      }
      setContent(editor.getHTML());
    };

    editor.on('update', updateHandler);
    return () => {
      editor.off('update', updateHandler);
    };
  }, [rteRef.current, selectedChat]);

  return (
    <Box component='div' sx={sx}>
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInput}
        multiple
      />

      {previews.length > 0 && renderAttachmentPreviews(previews)}

      <Box
        component='div'
        sx={{
          '& .ProseMirror': {
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={content}
          editable={isEditable && !isSending}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          renderControls={() => <EditorMenuControls />}
          RichTextFieldProps={{
            variant: 'outlined',
            MenuBarProps: { hide: !showMenuBar },
            footer: (
              <Stack
                direction='row'
                spacing={2}
                sx={{
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  borderTopColor: theme => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
                <Box component='div' sx={{ flexGrow: 1 }}>
                  <MenuButton
                    value='formatting'
                    tooltipLabel={showMenuBar ? 'Hide formatting' : 'Show formatting'}
                    size='small'
                    onClick={() => setShowMenuBar(prev => !prev)}
                    selected={showMenuBar}
                    IconComponent={TextFields}
                  />
                  {/* <MenuButton
                    value='formatting'
                    tooltipLabel={isEditable ? 'Prevent edits (use read-only mode)' : 'Allow edits'}
                    size='small'
                    onClick={() => setIsEditable(prev => !prev)}
                    selected={!isEditable}
                    IconComponent={isEditable ? Lock : LockOpen}
                  /> */}
                </Box>
                {onSend && (
                  <IconButton
                    onClick={handleSendClick}
                    disabled={(!content.trim() && attachments.length === 0) || isSending}
                    sx={{
                      bgcolor: theme => theme.palette.primary.main,
                      color: 'white',
                      '&:hover': {
                        bgcolor: theme => theme.palette.primary.dark,
                      },
                      '&.Mui-disabled': {
                        bgcolor: 'action.disabledBackground',
                        color: 'action.disabled',
                      },
                    }}
                  >
                    <Send />
                  </IconButton>
                )}
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>

      {showPreview && (
        <>
          <Typography variant='h5' sx={{ my: 5 }}>
            Preview:
          </Typography>
          <RichTextReadOnly content={content} extensions={extensions} />
        </>
      )}

      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        disableEscapeKeyDown={preventEscapeKeyClosing}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Send message?</DialogTitle>
        <DialogContent>
          <Box component='div' sx={{ mb: 2 }}>
            <Typography variant='subtitle2' color='text.secondary' gutterBottom>
              Message:
            </Typography>
            <Box
              component='div'
              sx={{
                p: 2,
                bgcolor: 'action.hover',
                borderRadius: 1,
                '& > div': {
                  overflow: 'auto',
                  maxHeight: '200px',
                },
              }}
            >
              <RichTextReadOnly content={content} extensions={extensions} />
            </Box>
          </Box>

          {attachments.length > 0 && (
            <Box component='div'>
              <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                Attachments ({attachments.length}):
              </Typography>
              {renderAttachmentPreviews(previews)}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)} color='inherit'>
            Cancel
          </Button>
          <Button onClick={handleSendMessage} variant='contained' color='primary'>
            Send Message
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isReopenDialogOpen}
        onClose={() => setIsReopenDialogOpen(false)}
        disableEscapeKeyDown={preventEscapeKeyClosing}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Reopen Conversation?</DialogTitle>
        <DialogContent>
          <Typography>
            This conversation is currently closed. Sending a message will reopen the conversation.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsReopenDialogOpen(false)} color='inherit'>
            Cancel
          </Button>
          <Button onClick={handleSendMessage} variant='contained' color='primary'>
            Send and Reopen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
