import { ContactSupport } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ChatIcon from '@mui/icons-material/Chat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { useNotifications } from '../../utils/components/AppBar/Notifications/NotificationsContext';
import { NotificationsMenuDrawer } from '../../utils/components/AppBar/Notifications/NotificationsMenuDrawer';
import { useConversation } from '../../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';
import { NewFeedbackDialog } from '../../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/Dialogs/NewFeedbackDialog';
import { FeedbackDialog } from '../FeedbackDialog';
import NavButton from './NavButton';

const { VITE_API_URL } = import.meta.env;

export const CommunicationsButtons = ({
  notificationMenuOpen = false,
  userMenuOpen = false,
  handleOpenNotificationMenu,
  handleCloseNotificationMenu,
  color = null,
}: {
  handleOpenNotificationMenu: any;
  handleCloseNotificationMenu: any;
  notificationMenuOpen: boolean;
  userMenuOpen: boolean;
  color?: string | null;
}) => {
  const { user } = useAuth();
  const {
    setSelectedChat,
    unreadMessagesCount,
    fetchUnreadConversatonsCount,
    setDialogOpen,
    dialogOpen,
  } = useConversation();
  const [messagesOpen, setMessagesOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { notifications } = useNotifications();
  const theme = useTheme();

  const fetchUnreadCount = async () => {
    fetchUnreadConversatonsCount({ userSeq: user?.userSeq });
  };

  useEffect(() => {
    fetchUnreadCount();
    const interval = setInterval(
      () => fetchUnreadConversatonsCount({ userSeq: user?.userSeq }),
      300000 // 5 mins
    );
    return () => clearInterval(interval);
  }, []);

  const closeMessages = () => {
    setSelectedChat(null);
    setMessagesOpen(false);
    setDialogOpen(false);
    fetchUnreadConversatonsCount({ userSeq: user?.userSeq });
  };

  const unreadNotifications = notifications.filter(n => !n.notificationRead && n.isActive);

  return (
    <Stack direction='row' spacing={1} sx={{ p: 0.5, display: 'flex' }}>
      <NavButton
        startIcon={<AddIcon />}
        tooltip='New Case'
        navigationPageTitle='caseintake'
        authorizedRoles={['CREATE-CASE']}
      />

      <IconButton
        title='Feedback'
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        onClick={() => setFeedbackOpen(true)}
      >
        <Tooltip title='Feedback'>
          <ContactSupport sx={{ color: color !== null ? color : theme.palette.text.primary }} />
        </Tooltip>
      </IconButton>

      <IconButton
        title='Messages'
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        onClick={() => setDialogOpen(true)}
      >
        <Tooltip title='Messages'>
          <Badge badgeContent={unreadMessagesCount} color='error'>
            <ChatIcon sx={{ color: color !== null ? color : theme.palette.text.primary }} />
          </Badge>
        </Tooltip>
      </IconButton>

      <IconButton title='Notifications' onClick={handleOpenNotificationMenu}>
        <Tooltip title='Notifications'>
          <Badge badgeContent={unreadNotifications.length} color='error'>
            <NotificationsIcon
              sx={{ color: color !== null ? color : theme.palette.text.primary }}
            />
          </Badge>
        </Tooltip>
      </IconButton>

      <NotificationsMenuDrawer
        handleCloseNotificationMenu={handleCloseNotificationMenu}
        notificationMenuOpen={notificationMenuOpen}
      />

      <NewFeedbackDialog
        open={dialogOpen}
        onClose={closeMessages}
        onConversationCreated={() => {
          fetchUnreadCount();
        }}
      />

      <FeedbackDialog
        onClose={() => setFeedbackOpen(false)}
        open={feedbackOpen}
        onMessagesClick={() => setDialogOpen(true)}
      />
    </Stack>
  );
};
