// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { Alert } from '@mui/material';
// import { AlertProps } from '@mui/material/Alert';
// import IconButton from '@mui/material/IconButton';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import React, { FormEventHandler, useState } from 'react';

// const SuccessIcon = () => {
//   return (
//     <svg
//       focusable='false'
//       aria-hidden='true'
//       viewBox='0 0 24 24'
//       data-testid='SuccessOutlinedIcon'
//       fill='#66bb6a'
//     >
//       <path d='M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z'></path>
//     </svg>
//   );
// };

// const InfoIcon = () => {
//   return (
//     <svg
//       focusable='false'
//       aria-hidden='true'
//       viewBox='0 0 24 24'
//       data-testid='InfoOutlinedIcon'
//       fill='#29b6f6'
//     >
//       <path d='M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z'></path>
//     </svg>
//   );
// };

// const WarningIcon = () => {
//   return (
//     <svg
//       focusable='false'
//       aria-hidden='true'
//       viewBox='0 0 24 24'
//       data-testid='ReportProblemOutlinedIcon'
//       fill='#ffa726'
//     >
//       <path d='M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z'></path>
//     </svg>
//   );
// };

// const ErrorIcon = () => {
//   return (
//     <svg
//       focusable='false'
//       aria-hidden='true'
//       viewBox='0 0 24 24'
//       data-testid='ErrorOutlineIcon'
//       fill='#f44336'
//     >
//       <path d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'></path>
//     </svg>
//   );
// };

// const severityOptions = {
//   success: <SuccessIcon />,
//   info: <InfoIcon />,
//   warning: <WarningIcon />,
//   error: <ErrorIcon />,
// } as const;

// export type Severity = keyof typeof severityOptions;

// interface SeveritySelectableAlertProps extends Omit<AlertProps, 'severity' | 'onChange'> {
//   value: Severity;
//   onChange: (newSeverity: Severity) => 'warning' | 'success' | 'info' | 'error';
// }

// const SeveritySelectableAlert: React.FC<SeveritySelectableAlertProps> = ({
//   children,
//   value,
//   onChange,
//   ...alertProps
// }) => {
//   const [severity, setSeverity] = useState<Severity>(value);
//   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

//   const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = (event: React.MouseEvent<HTMLLIElement>, newSeverity: Severity) => {
//     setSeverity(newSeverity);
//     setAnchorEl(null);
//   };

//   interface HandleChangeEvent extends React.ChangeEvent<HTMLInputElement> {
//     target: HTMLInputElement & { value: (typeof severityOptions)[keyof typeof severityOptions] };
//   }

//   const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event: HandleChangeEvent) => {
//     if (event.target.value === severityOptions.success) {
//       onChange('success');
//     } else if (event.target.value === severityOptions.info) {
//       onChange('info');
//     } else if (event.target.value === severityOptions.warning) {
//       onChange('warning');
//     } else if (event.target.value === severityOptions.error) {
//       onChange('error');
//     } else {
//       return;
//     }
//   };

//   return (
//     <Alert
//       severity={severity}
//       icon={
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <IconButton size='small' onClick={handleMenuOpen}>
//             <div style={{ width: 23, height: 23 }}>{severityOptions[severity]}</div>
//             <ArrowDropDownIcon />
//           </IconButton>
//           <Menu
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={() => setAnchorEl(null)}
//             onChange={handleChange}
//           >
//             {Object.entries(severityOptions).map(([key, icon]) => (
//               <MenuItem
//                 key={key}
//                 onClick={event => handleMenuClose(event, key as Severity)}
//                 sx={{ p: 0.5 }}
//               >
//                 <div style={{ width: 23, height: 23 }}>{icon}</div>
//               </MenuItem>
//             ))}
//           </Menu>
//         </div>
//       }
//       {...alertProps}
//     >
//       {children}
//     </Alert>
//   );
// };

// export default SeveritySelectableAlert;

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Alert } from '@mui/material';
import { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

const severityOptions = {
  success: {
    icon: (
      <svg focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='#66bb6a'>
        <path d='M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z' />
      </svg>
    ),
  },
  info: {
    icon: (
      <svg focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='#29b6f6'>
        <path d='M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z' />
      </svg>
    ),
  },
  warning: {
    icon: (
      <svg focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='#ffa726'>
        <path d='M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z' />
      </svg>
    ),
  },
  error: {
    icon: (
      <svg focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='#f44336'>
        <path d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' />
      </svg>
    ),
  },
} as const;

export type Severity = keyof typeof severityOptions;

interface SeveritySelectableAlertProps extends Omit<AlertProps, 'severity' | 'onChange'> {
  value: Severity;
  onChange: (newSeverity: Severity) => void;
}

const SeveritySelectableAlert: React.FC<SeveritySelectableAlertProps> = ({
  children,
  value,
  onChange,
  ...alertProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (newSeverity: Severity) => {
    onChange(newSeverity);
    handleMenuClose();
  };

  return (
    <Alert
      severity={value}
      icon={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton size='small' onClick={handleMenuOpen}>
            <div style={{ width: 23, height: 23 }}>{severityOptions[value].icon}</div>
            <ArrowDropDownIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {Object.entries(severityOptions).map(([key, { icon }]) => (
              <MenuItem key={key} onClick={() => handleSelect(key as Severity)} sx={{ p: 0.5 }}>
                <div style={{ width: 23, height: 23 }}>{icon}</div>
              </MenuItem>
            ))}
          </Menu>
        </div>
      }
      {...alertProps}
    >
      {children}
    </Alert>
  );
};

export default SeveritySelectableAlert;
