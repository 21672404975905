import React from 'react';
import { useEffect, useState } from 'react';
// project imports
// import MainCard from '../../../../../../components/AdministrationModules/MainCard';
import {
  activeData,
  deleteData,
  getAdminData,
} from '../../../../../services/utility-api';

// material-ui
import { Grid, Box, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { SlideProps } from '@mui/material/Slide';
import ReferenceMasterTableFilter from '../ReferenceMasterTableFilter';
import AddEdit from './AddEdit';
import AccessDenied from '../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../components/AdministrationModules/Breadcrumb';
import DataGridComponent from '../../../../../components/AdministrationModules/DataGridComponent';

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Jurisdiction = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions') || '{}');
  const referencePermissions = permissions?.['Reference Data'];
  const [isAddEditOn, setIsAddEditOn] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);

  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] = useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState<{ icon?: string; seq?: string; status?: string }>({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

  const [columns, setColumns] = useState([
    {
      field: 'jdxName',
      headerName: 'Jurisdiction',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 2,
    },
    {
      field: 'jdxCode',
      headerName: 'Code',
      // headerClassName: 'super-app-theme--header',
      minWidth: 100,
      align: 'left',
      flex: 1,
    },
    {
      field: 'jdxOrder',
      headerName: 'List Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 100,
      align: 'left',
      flex: 1,
    },
    {
      field: 'colorCode',
      headerName: 'Color Code',
      // headerClassName: 'super-app-theme--header',
      minWidth: 100,
      align: 'left',
      flex: 1,
    },
    {
      field: 'maxAvailableTissueNumberCount',
      headerName: 'Maximum Available TIssue Number to Display',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 2,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const [rowsToShow, setRowsToShow] = useState<any[]>([]);
  const MENU_OBJECT = {
    Home: '/support',
    'Reference Data': '/support',
    'Jurisdiction': '',
  };
  let isStatusColumnAdded = false;
  columns.forEach((column) => {
    if (column.field == 'status') {
      isStatusColumnAdded = true;
    }
  });
  if (!isStatusColumnAdded) {
    setColumns((prev) => [
      ...prev,
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        align: 'left',
        flex: 1,
        // headerClassName: 'super-app-theme--header',
        renderCell: (cellValues: any) => {
          const handleClick = async (row: any) => {
            // if (!permissions?.['Reference Data']?.edit) return;
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row?.jdxSeq,
              status: row.status,
            });
          };

          return Number(cellValues.row.order) > 90000 ? (
            <></>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClick(cellValues.row);
              }}
            //   disabled={!permissions?.['Reference Data']?.edit}
            >
              {cellValues.row.status === 'Active' ? (
                <Tooltip title='Active'>
                  <LockIcon />
                </Tooltip>
              ) : (
                <Tooltip title='InActive'>
                  <DoneIcon />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ]);
  }

  const getAllData = async () => {
    const response = await getAdminData('jurisdiction');
    const data = response.data.data.map((row: Record<string, any>) => {
      const newObj: Record<string, any> = {};
      Object.keys(row).forEach((key) => {
        newObj[key] = row[key];
      });
      newObj.id = newObj['jdxSeq'];
      if (newObj['jdxOrder'])
        newObj['jdxOrder'] = Number(newObj['jdxOrder']);
      return newObj;
    });
    let maxOrder = 0;
    for (let i = 0; i < data.length; i++) {
      if (
        Number(data[i].jdxOrder) > maxOrder &&
        Number(data[i].jdxOrder) < 90000
      )
        maxOrder = Number(data[i].jdxOrder);
    }
    console.log('data', data, maxOrder);
    setMaxOrder(maxOrder);

    setRows(data);
    setRowsToShow(
      data.filter((row : any) => row['status'].toLowerCase() == 'active')
    );
  };

  const editRow = (params: any) => {
    if (referencePermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };
  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: 'jurisdiction',
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: 'jurisdiction',
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  if (!referencePermissions?.view) {
    return (<>
      <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
      <AccessDenied />
    </>)
  }
  else {
    return (
      // <div className='container my-5 py-5'>
      <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {/* <MainCard> */}
              <Box component={'div'}
                sx={{ tr: 'hover', cursor: 'pointer' }}
              >
                <ReferenceMasterTableFilter
                  rows={rows}
                  isJdx={false}
                  jurisdictionOptions={[]}
                  setRowsToShow={setRowsToShow}
                />
                <DataGridComponent
                  sortModel={[
                    {
                      field: 'jdxName',
                      sort: 'asc',
                    },
                  ]}
                  listresults={rowsToShow}
                  columnsInput={columns}
                  rowsperpage={10}
                  onRowClick={editRow}
                />
              </Box>
              <Box component={'div'}>
                <Button
                  variant='contained'
                  sx={{ marginTop: '7px' }}
                  onClick={() => {
                    setIsAddEditOn(false);
                    setTimeout(() => {
                      setEditObj({});
                      setIsAddEditOn(true);
                    }, 200);
                  }}
                  disabled={!referencePermissions?.add}
                >
                  Add Jurisdiction
                </Button>
              </Box>
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {isAddEditOn && editObj && (
                <AddEdit
                  maxOrder={maxOrder}
                  editObj={editObj}
                  referenceMasterTableName='jurisdiction'
                  setIsAddEditOn={setIsAddEditOn}
                  getAllData={getAllData}
                  referencePermissions={referencePermissions}
                />
              )}
              <div className='modal'>
                <Dialog
                  open={openActiveInActiveDialogBox}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setOpenActiveInActiveDialogBox(false)}
                  aria-describedby='alert-dialog-slide-description'
                >
                  <DialogTitle></DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                      Are you sure you want to{' '}
                      {activeInactiveClickData?.status === 'Active' ? 'InActive ' : 'Active'} this
                      jurisdiction?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>No</Button>
                    <Button onClick={changeActiveInActiveClick}>Yes</Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div className='modal'>
                <Dialog
                  open={openApiDialogBox}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setOpenApiDialogBox(false)}
                  aria-describedby='alert-dialog-slide-description'
                >
                  <DialogTitle></DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                      {dialogContents}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <IconButton onClick={() => setOpenApiDialogBox(false)}>
                      <CloseIcon />
                    </IconButton>
                  </DialogActions>
                </Dialog>
              </div>
              {/* </MainCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // </div>
    );
  }
};
export default Jurisdiction;
