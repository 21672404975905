import { Box, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';

function getValueByNestedKey(object, key) {
  const keys = key.split('.');
  return keys.reduce((obj, k) => obj?.[k], object);
}

function getValueFromFieldValue(type, fieldValue) {
  if (type == 'date') {
    if (fieldValue == null || fieldValue === '') {
      return '';
    } else {
      return new Date(fieldValue).toISOString().split('T')[0];
    }
  } else if (type == 'datetime-local') {
    return fieldValue ?? '';
  } else {
    return fieldValue ?? '';
  }
}

export default function CaseViewTextField({
  xs = null,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  authorizedToEdit = true,
  fieldName,
  multiline = false,
  rows = undefined,
  type = 'string',
  label,
  sx = {},
}) {
  const formik = useFormikContext();
  const { setUnsavedChanges } = useUnsavedChanges();

  const { value: fieldValue } = formik.getFieldProps(fieldName);
  const { touched: fieldTouched, error: fieldErrors } = formik.getFieldMeta(fieldName);
  const [state, setState] = useState(fieldValue);

  const handleChange = useCallback(event => {
    setState(event.target.value);   
  }, []);

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(fieldName, true);
    formik.setFieldValue(fieldName, state);
    if (fieldValue !== state) {
      setUnsavedChanges();
    }
  }, [formik, fieldName, state]);

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      sx={sx}
      /* sx={{ display: displayStyle }} */
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
        }}
      >
        <TextField
          disabled={
            !formik?.status?.editing || !authorizedToEdit //||
            // !fieldVisibilityStatus?.enabled ||
            // !moduleVisibilityStatus?.enabled
          }
          fullWidth
          multiline={multiline}
          rows={rows}
          id={fieldName}
          name={fieldName}
          label={label}
          type={type}
          variant='outlined'
          value={state ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={fieldTouched && Boolean(fieldErrors)}
          helperText={fieldTouched ? fieldErrors?.toString() : ''}
          InputLabelProps={{
            shrink: type === 'date' || type === 'datetime-local' || state !== null,
          }}
        />
        {(!formik?.status?.editing || !authorizedToEdit) && (
          <Box
            onClick={() => {
              if (formik.status?.disabledClickCount === 0) {
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                });
              }
            }}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
    </Grid>
  );
}
