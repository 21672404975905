import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../services/utility-api.jsx';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  FormControl,
  IconButton,
  FormHelperText,
  Card,
} from '@mui/material';
import { TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { SlideProps } from '@mui/material/Slide';
import { HexColorPicker } from 'react-colorful';
import MLSVGDropzone from '../../../../../utils/components/MLSVGDropzone.js';

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface AddEditProps {
  editObj: any;
  referenceMasterTableName: string;
  maxOrder: number;
  setIsAddEditOn: React.Dispatch<React.SetStateAction<boolean>>;
  getAllData: () => void;
  referencePermissions: any;
}

const AddEdit: React.FC<AddEditProps> = ({
  editObj,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  referencePermissions,
}) => {
  const id = '00000000-0000-0000-0000-000000000000';
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const initialValues = {
    ...editObj,
    // parentJdxSeq: editObj?.parentJdxSeq || id,
    jdxName: editObj?.jdxName || '',
    jdxCode: editObj?.jdxCode || '',
    jdxOrder: editObj?.jdxOrder
      ? Math.floor(Number(editObj.jdxOrder) / 10) * 10
      : maxOrder + 10, // Pls explain this.
  };

  const validationSchema = Yup.object({
    jdxName: Yup.string().required('Jurisdiction Name is required').nullable(),
    jdxCode: Yup.string()
      .required('Jurisdiction code order is required')
      .max(2, 'Jurisdiction code should be 2 numbers or fewer')
      .nullable(),
      jdxOrder: Yup.string().required('Jurisdiction order is required').nullable(),
    // colorCode: Yup.string().required(`Color Code is required`).nullable(),
  });
  const onSave = async (values: any) => {
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          ['jdxSeq']: values['jdxSeq'] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleClose = () => {
    setIsAddEditOn(false);
  }

  return (
    <>
      <Grid spacing={2} sx={{ padding: '16px', marginLeft: '-16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>
                {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Jurisdiction{' '}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
          {({
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} spacing={{ xs: 0, sm: 2 }}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormControl fullWidth variant='standard'>
                        <TextField
                          id='jdx'
                          required
                          label='Jurisdiction'
                          variant='outlined'
                          name='jdxName'
                          value={values?.['jdxName'] ?? ''}
                          onChange={handleChange}
                          margin='normal'
                          size='small'
                        />
                        <ErrorMessage
                          name='jdxName'
                          render={msg => <FormHelperText error>{msg}</FormHelperText>}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormControl fullWidth variant='standard'>
                        <TextField
                          id='code'
                          label='Code'
                          required
                          variant='outlined'
                          name='jdxCode'
                          value={values?.jdxCode}
                          onChange={handleChange}
                          margin='normal'
                          size='small'
                        />
                        <ErrorMessage
                          name='jdxCode'
                          render={msg => <FormHelperText error>{msg}</FormHelperText>}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormControl fullWidth variant='standard'>
                        <TextField
                          id='denominationOrder'
                          required
                          label='List Order'
                          variant='outlined'
                          name='denominationOrder'
                          value={values?.jdxOrder}
                          onChange={handleChange}
                          margin='normal'
                          size='small'
                          disabled
                        />
                        <ErrorMessage
                          name='jdxOrder'
                          render={msg => <FormHelperText error>{msg}</FormHelperText>}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12} md={6} lg={3}>
                      <FormControl fullWidth variant='standard'>
                        <TextField
                          id='TissueNumberDisplay'
                          label='Maximum Available Tissue Number to Display	'
                          variant='outlined'
                          name={'maxAvailableTissueNumberCount'}
                          value={values?.maxAvailableTissueNumberCount}
                          onChange={handleChange}
                          margin='normal'
                          size='small'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    spacing={2}
                    sx={{ display: 'flex', alignItems: 'stretch' }}
                  >
                    <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <MLSVGDropzone initialValue={values?.jdxLogo} setFieldValue={setFieldValue} />
                    </Grid>

                    <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Card variant='outlined' sx={{ padding: '16px', flex: 1 }}>
                        <FormControl
                          fullWidth
                          sx={{ m: 0, minWidth: 120 }}
                          error
                          // required
                          component='fieldset'
                          variant='standard'
                        >
                          <TextField
                            // required
                            focused
                            id='outlined-basic'
                            label='Color Code'
                            variant='outlined'
                            name={'colorCode'}
                            //   defaultValue={values['colorCode']}
                            value={values['colorCode']}
                            // onChange={handleChange}
                            margin='normal'
                            size='small'
                          />
                        </FormControl>
                        <HexColorPicker
                          style={{ width: '100%', height: '150px', marginTop: '8px' }}
                          color={values['colorCode']}
                          onChange={e =>
                            setFieldValue('colorCode', e.split('#')[1].toUpperCase().toString())
                          }
                        />
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Card
                        variant='outlined'
                        sx={{
                          padding: '16px',
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Grid container>
                          {[
                            {
                              name: 'isCaseIDUsedAsTissueNumber',
                              label: 'Case ID Used As Tissue Number',
                            },
                            {
                              name: 'isOptimalTissueNumberPreview',
                              label: 'Optional Tissue Number Preview',
                            },
                            { name: 'isMorgueChecked', label: 'Support Multiple Morgues' },
                            { name: 'isDecisionMakingNOK', label: 'Decision Making NOK' },
                            { name: 'trackLab', label: 'Track Lab' },
                            { name: 'isRegularMorgue', label: 'Regular Morgue' },
                            { name: 'isHospitalMorgue', label: 'Hospital Morgue' },
                            {
                              name: 'isRecoverySealNoRequired',
                              label: 'Duplicate Recovery Seal No.',
                            },
                            {
                              name: 'isDocumentTrackerAutoPopulated',
                              label: 'Document Tracker Auto Populated',
                            },
                          ].map((checkbox, index) => (
                            <Grid item xs={12} lg={6} key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values[checkbox.name]}
                                    onChange={() =>
                                      handleChange({
                                        target: {
                                          name: checkbox.name,
                                          value: !values[checkbox.name],
                                        },
                                      })
                                    }
                                  />
                                }
                                label={checkbox.label}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} justifyContent='flex-start'>
                    <Button 
                      variant='contained'
                      onClick={(e) => handleSubmit()}
                      sx={{ minWidth: '100px', marginTop: '16px' }}
                      disabled={!referencePermissions?.add}
                    >
                      {Object.keys(editObj).length ? 'Update' : 'Save'}
                    </Button>

                    <Button
                      color='error'
                      variant='outlined'
                      onClick={handleClose}
                      sx={{ minWidth: '100px', marginTop: '16px', marginLeft: '1rem' }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};
export default AddEdit;
