import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { AddressTypeOptions } from '../../../utils/constants/constants';

const Address = ({
  handleChange,
  handleCreateNewAddress,
  panel,
  panels,
  index,
  countyNames,
  countryNames,
  stateNames,
  addressTypeNames,
  isMultipleOn,
  title,
  disabled,
  showCopyAddressCheck,
}) => {
  const [agencyOptions, setAgencyOptions] = useState([]);
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext();

  const id = '00000000-0000-0000-0000-000000000000';

  const getAgencyOptions = async (agencyType, jdxSeq) => {
    if (agencyType == null) {
      return [];
    }

    switch (agencyType) {
      case AddressTypeOptions.MEDICALFACILITY:
        var hcfoptions = [];
        // GET HCF OPTIONS
        await fetch(VITE_API_URL + 'gethcfoptions', {
          method: 'GET',
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            hcfoptions = data;
          });
        return hcfoptions;

      case AddressTypeOptions.LAWENFORCEMENT:
        var leoptions = [];
        // GET LAW ENFORCEMENT OPTIONS
        await fetch(VITE_API_URL + 'getlawenforcementoptions', {
          method: 'GET',
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            leoptions = data;
          });
        return leoptions;

      case AddressTypeOptions.NURSINGHOME:
        var nhoptions = [];
        //GET NURSING HOME OPTIONS
        await fetch(VITE_API_URL + 'getnursinghomeoptions', {
          method: 'GET',
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            nhoptions = data;
          });
        return nhoptions;

      case AddressTypeOptions.FUNERALHOME:
        var fhoptions = [];
        // GET FUNERAL HOME OPTIONS
        await fetch(VITE_API_URL + 'getfuneralhomeoptions', {
          method: 'GET',
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            fhoptions = data;
          });
        return fhoptions;

      default:
        return [];
    }
  };

  const getAgencySeqField = addressTypeSeq => {
    switch (addressTypeSeq?.toLowerCase()) {
      case AddressTypeOptions.MEDICALFACILITY:
        return 'placeSeq';
      case AddressTypeOptions.NURSINGHOME:
        return 'nursingHomeSeq';
      case AddressTypeOptions.LAWENFORCEMENT:
        return 'lawEnforcementSeq';
      case AddressTypeOptions.FUNERALHOME:
        return 'funeralHomeSeq';
      default:
        return null;
    }
  };

  const getTableName = addressTypeSeq => {
    switch (addressTypeSeq?.toLowerCase()) {
      case AddressTypeOptions.MEDICALFACILITY:
        return 'Hcf';
      case AddressTypeOptions.NURSINGHOME:
        return 'NursingHome';
      case AddressTypeOptions.LAWENFORCEMENT:
        return 'LawEnforcement';
      case AddressTypeOptions.FUNERALHOME:
        return 'FuneralHome';
      default:
        return null;
    }
  };

  const getLabel = addressTypeSeq => {
    switch (addressTypeSeq?.toLowerCase()) {
      case AddressTypeOptions.MEDICALFACILITY:
        return 'Medical Facility';
      case AddressTypeOptions.NURSINGHOME:
        return 'Nursing Home';
      case AddressTypeOptions.LAWENFORCEMENT:
        return 'Law Enforcement';
      case AddressTypeOptions.FUNERALHOME:
        return 'Funeral Home';
      default:
        return 'Agency';
    }
  };

  const getAgencyValue = () => {
    const agencySeqField = getAgencySeqField(panel?.addressTypeSeq);
    return panel?.[agencySeqField] || null;
  };

  const isAgencyType = addressTypeSeq => {
    const types = [
      AddressTypeOptions.MEDICALFACILITY,
      AddressTypeOptions.NURSINGHOME,
      AddressTypeOptions.LAWENFORCEMENT,
      AddressTypeOptions.FUNERALHOME,
      AddressTypeOptions.OTHER,
    ];
    return types.includes(addressTypeSeq?.toLowerCase());
  };

  const isAgencyVisible = () => {
    return isAgencyType(panel?.addressTypeSeq);
  };

  const handleDeleteAddress = addressIndex => {
    const tempPanels = panels.filter((_, i) => i !== addressIndex);
    handleChange(tempPanels);
  };

  const fetchAgencyAddress = async (agency, addressTypeSeq) => {
    const tableName = getTableName(addressTypeSeq);

    if (!tableName) {
      console.error('Invalid address type');
      return null;
    }

    try {
      const response = await fetch(
        `${VITE_API_URL}getagencyaddress?agencySeq=${agency.optionSeq}&tableName=${tableName}`
      );
      const addressData = await response.json();
      return addressData;
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };

  function onCopyAddressCheck(event, index) {
    const isChecked = event.target.checked;

    const tempPanels = JSON.parse(JSON.stringify(panels));
    // tempPanels[index]["isCopyAddress"] = isChecked;

    if (isChecked) {
      const decedentAddress = formik.values.caseDemographics?.decedentAddress?.[0] || {};
      tempPanels[index] = {
        ...decedentAddress,
        addressSeq: panels?.[index]?.addressSeq,
        isCopyAddress: isChecked,
      };
    } else {
      tempPanels[index] = {
        ...tempPanels[index],
        addressTypeSeq: null,
        addressLine1: '',
        city: '',
        placeSeq: null,
        countySeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
        floor: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
        isCopyAddress: isChecked,
      };
    }

    handleChange(tempPanels);
  }

  const handleAgencySelectionChange = async (value, index) => {
    const tempPanels = JSON.parse(JSON.stringify(panels));
    const agencySeqField = getAgencySeqField(tempPanels[index].addressTypeSeq);

    if (agencySeqField) {
      tempPanels[index][agencySeqField] = value?.optionSeq || null;
    }

    if (value) {
      const addressData = await fetchAgencyAddress(value, tempPanels[index].addressTypeSeq);
      if (addressData) {
        tempPanels[index] = {
          ...tempPanels[index],
          ...addressData,
        };
      } else {
        tempPanels[index] = {
          ...tempPanels[index],
          addressLine1: '',
          addressLine2: '',
          apartmentNo: '',
          floor: '',
          zip: '',
          city: '',
          otherAddressType: '',
          countySeq: null,
        };
      }
    }

    handleChange(tempPanels);
  };

  const handleAddressSelectionChange = async (e, index) => {
    const tempPanels = JSON.parse(JSON.stringify(panels));
    tempPanels[index][e.target.name] = e.target.value;

    if (e.target.name === 'addressTypeSeq') {
      tempPanels[index] = {
        ...tempPanels[index],
        placeSeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
      };

      if (
        e.target.value == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
        e.target.value == 'eea37e74-0a50-419e-9de9-372d21d1f890'
      ) {
        tempPanels[index] = {
          ...tempPanels[index],
          addressTypeSeq: e.target.value || id,
          addressLine1: '',
          city: '',
          contactInfoSeq: id,
          placeSeq: null,
          stateSeq: null,
          countrySeq: null,
          countySeq: null,
          floor: '',
          otherState: '',
          zip: '',
          addressLine2: '',
          apartmentNo: '',
          crossStreet: '',
          otherAddressType: '',
        };
      }
    }

    handleChange(tempPanels);
  };

  const addInputField = () => {
    if (
      addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() == 'homeless' ||
      addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() == 'unknown'
    ) {
      return addressTypeNames[panel?.addressTypeSeq]?.addressTypeName;
    } else {
      return panel?.addressLine1;
    }
  };

  useEffect(() => {
    async function fetchAgencyOptions() {
      try {
        if (isAgencyType(panel?.addressTypeSeq)) {
          const options = await getAgencyOptions(
            panel?.addressTypeSeq?.toLowerCase(),
            formik?.values?.caseSummary?.jdxSeq
          );
          setAgencyOptions(options);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchAgencyOptions();
  }, [panel?.addressTypeSeq]);

  return (
    <Box>
      {title && (
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mb: '8px' }}>
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <b>{isMultipleOn ? `${title} ${index + 1}` : title}</b>
          </Typography>

          {showCopyAddressCheck && (
            <FormControlLabel
              checked={panel?.isCopyAddress ?? false}
              control={<Checkbox sx={{ transform: 'scale(0.8)' }} />} // Optional: Scale the checkbox size
              onChange={e => onCopyAddressCheck(e, index)}
              label='Copy address from decedent residence'
              sx={{
                flexShrink: 0,
                '& .MuiFormControlLabel-label': { fontSize: '0.7rem' }, // Adjust label font size
                ml: '1rem',
              }}
            />
          )}
        </Grid>
      )}
      <Box sx={{ mb: '8px', pointerEvents: disabled ? 'none' : 'auto' }}>
        <Grid container spacing={2}>
          {isAgencyVisible() && (
            <>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormGroup>
                  <FormControl variant='outlined'>
                    <InputLabel htmlFor='addressTypeSeq' id='addressTypeSeq'>
                      Address Type
                    </InputLabel>
                    <Select
                      onChange={e => handleAddressSelectionChange(e, index)}
                      variant='outlined'
                      // focused
                      size='small'
                      label='Address Type'
                      displayEmpty
                      name={`addressTypeSeq`}
                      value={panel?.addressTypeSeq?.toLowerCase() ?? id}
                    >
                      <MenuItem key={id} value={id}>
                        --Select One--
                      </MenuItem>
                      {Object.keys(addressTypeNames).map((item, z) => {
                        return (
                          <MenuItem
                            key={addressTypeNames[item].addressTypeSeq?.toLowerCase()}
                            value={addressTypeNames[item].addressTypeSeq?.toLowerCase()}
                          >
                            {addressTypeNames[item].addressTypeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name={`address.${index}.addressTypeSeq`}
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>

              {panel?.addressTypeSeq?.toLowerCase() === AddressTypeOptions.OTHER?.toLowerCase() ? (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormGroup>
                    <FormControl error component='fieldset' variant='standard'>
                      <TextField
                        type='text'
                        label='Other AddressType'
                        name={`otherAddressType`}
                        onChange={e => handleAddressSelectionChange(e, index)}
                        value={panel?.otherAddressType || ''}
                        variant='outlined'
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                  <Autocomplete
                    id='agency'
                    name='agency'
                    options={agencyOptions || []}
                    value={
                      agencyOptions?.find(
                        option =>
                          option.optionSeq?.toLowerCase() === getAgencyValue()?.toLowerCase()
                      ) || null
                    }
                    // isOptionEqualToValue={(option, value) =>
                    //   option?.optionSeq?.toUpperCase() === value?.toUpperCase()
                    // }
                    size='small'
                    getOptionLabel={option => option?.optionName}
                    onChange={(e, value) => handleAgencySelectionChange(value, index)}
                    fullWidth
                    disabled={disabled}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={getLabel(panel?.addressTypeSeq)}
                        placeholder={getLabel(panel?.addressTypeSeq)}
                        required
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {!isAgencyVisible() && (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormGroup>
                    <FormControl variant='outlined'>
                      <InputLabel htmlFor='addressTypeSeq' id='addressTypeSeq'>
                        Address Type
                      </InputLabel>
                      <Select
                        onChange={e => handleAddressSelectionChange(e, index)}
                        variant='outlined'
                        // focused
                        size='small'
                        label='Address Type'
                        displayEmpty
                        name={`addressTypeSeq`}
                        value={panel?.addressTypeSeq?.toLowerCase() ?? id}
                      >
                        <MenuItem key={id} value={id}>
                          --Select One--
                        </MenuItem>
                        {Object.keys(addressTypeNames).map((item, z) => {
                          return (
                            <MenuItem
                              key={addressTypeNames[item].addressTypeSeq?.toLowerCase()}
                              value={addressTypeNames[item].addressTypeSeq?.toLowerCase()}
                            >
                              {addressTypeNames[item].addressTypeName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        name={`address.${index}.addressTypeSeq`}
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormGroup>
                  <FormControl focused error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      // focused
                      type='text'
                      name={`addressLine1`}
                      label='Address Line 1'
                      onChange={e => handleAddressSelectionChange(e, index)}
                      value={addInputField()}
                      disabled={
                        panel?.addressTypeSeq &&
                        (addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                          'homeless' ||
                          addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                            'unknown')
                      }
                      variant='outlined'
                      inputProps={{ maxLength: 50 }}
                    />
                    <ErrorMessage
                      name={`address.${index}.addressLine1`}
                      render={msg => {
                        console.log('message', msg);
                        return <FormHelperText error>{msg}</FormHelperText>;
                      }}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormGroup>
                  <FormControl error component='fieldset' variant='standard'>
                    <TextField
                      type='text'
                      label='Address Line 2'
                      name={`addressLine2`}
                      onChange={e => handleAddressSelectionChange(e, index)}
                      value={panel?.addressLine2 || ''}
                      variant='outlined'
                      disabled={
                        panel?.addressTypeSeq &&
                        (addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                          'homeless' ||
                          addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                            'unknown')
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={3} md={1.5} lg={1.5}>
                <FormControl error component='fieldset' variant='standard' fullWidth>
                  <TextField
                    type='text'
                    id='outlined-basic_tab_${activeStep + 1}'
                    label='Apt/Suite'
                    variant='outlined'
                    name={`apartmentNo`}
                    onChange={e => handleAddressSelectionChange(e, index)}
                    value={panel?.apartmentNo || ''}
                    disabled={
                      panel?.addressTypeSeq &&
                      (addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                        'homeless' ||
                        addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                          'unknown')
                    }
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={1.5} lg={1.5}>
                <FormGroup>
                  <FormControl error component='fieldset' variant='standard'>
                    <TextField
                      type='text'
                      id={`outlined-basic_tab_${panel.value}`}
                      label='Floor No'
                      name={`floor`}
                      onChange={e => handleAddressSelectionChange(e, index)}
                      value={panel?.floor || ''}
                      variant='outlined'
                      disabled={
                        panel?.addressTypeSeq &&
                        (addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                          'homeless' ||
                          addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                            'unknown')
                      }
                      inputProps={{
                        maxLength: 10, // Limits input to 10 characters
                      }}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormGroup>
                  <FormControl focused required component='fieldset' variant='standard'>
                    <TextField
                      required
                      // focused
                      type='text'
                      name={`city`}
                      label='City'
                      onChange={e => handleAddressSelectionChange(e, index)}
                      value={panel?.city || ''}
                      disabled={
                        panel?.addressTypeSeq &&
                        (addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                          'homeless' ||
                          addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                            'unknown')
                      }
                      variant='outlined'
                      inputProps={{ maxLength: 30 }}
                    />
                    <ErrorMessage
                      name={`address.${index}.city`}
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormGroup>
                  <FormControl>
                    <InputLabel htmlFor='stateSeq' id='stateSeq' variant='outlined'>
                      State
                    </InputLabel>
                    <Select
                      onChange={e => handleAddressSelectionChange(e, index)}
                      variant='outlined'
                      size='small'
                      label='State'
                      name={`stateSeq`}
                      displayEmpty
                      value={panel?.stateSeq ?? id}
                      disabled={
                        panel?.addressTypeSeq &&
                        (addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                          'homeless' ||
                          addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                            'unknown')
                      }
                    >
                      <MenuItem key='select-option' value={id}>
                        --Select One--
                      </MenuItem>
                      {stateNames.map((item, z) => {
                        return (
                          <MenuItem key={item.stateSeq} value={item.stateSeq}>
                            {item.stateName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <FormGroup>
                  <FormControl error component='fieldset' variant='standard'>
                    <TextField
                      type='text'
                      label='Zip Code'
                      name={`zip`}
                      onChange={e => handleAddressSelectionChange(e, index)}
                      value={panel?.zip || ''}
                      id={`outlined-basic_${panel.value}`}
                      // value={`${panel[0]?.zip}`}
                      variant='outlined'
                      size='small'
                      disabled={
                        panel?.addressTypeSeq &&
                        (addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                          'homeless' ||
                          addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                            'unknown')
                      }
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <FormControl fullWidth>
                  <InputLabel htmlFor='countySeq' id='countySeq'>
                    Jurisdiction
                  </InputLabel>
                  <Select
                    onChange={e => handleAddressSelectionChange(e, index)}
                    variant='outlined'
                    label='Jurisdiction'
                    name={`countySeq`}
                    size='small'
                    value={panel?.countySeq || ''}
                  >
                    <MenuItem key='select-option' value=''>
                      --Select One--
                    </MenuItem>
                    {countyNames
                      ? Object.keys(countyNames).map((item, z) => {
                          return (
                            <MenuItem
                              key={countyNames[item].countySeq}
                              value={countyNames[item].countySeq}
                              sx={{
                                marginLeft:
                                  countyNames[item].countyName == 'New Jersey' ||
                                  countyNames[item].countyName == 'NJ State Case' ||
                                  countyNames[item].countyName == 'MAiD Case'
                                    ? ''
                                    : '15px',
                              }}
                            >
                              {countyNames[item].countyName}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='countrySeq' id='countrySeq'>
                    Country
                  </InputLabel>
                  <Select
                    onChange={e => handleAddressSelectionChange(e, index)}
                    variant='outlined'
                    label='Country'
                    name={`countrySeq`}
                    size='small'
                    value={panel?.countrySeq || ''}
                  >
                    <MenuItem key='select-option' value=''>
                      --Select One--
                    </MenuItem>
                    {countryNames.map((item, z) => {
                      return (
                        <MenuItem key={item.countrySeq} value={item.countrySeq}>
                          {item.countryName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {isMultipleOn && (
                <Grid item xs={12}>
                  <Button
                    disabled={true}
                    variant='contained'
                    endIcon={<PublishedWithChangesIcon />}
                    style={{ marginRight: '8px' }}
                  >
                    Verify Address
                  </Button>
                  {panels?.length > 1 && (
                    <Tooltip title='Delete Address'>
                      <IconButton onClick={() => handleDeleteAddress(index)}>
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  )}
                  {index === panels?.length - 1 && (
                    <Tooltip title='Add New Address'>
                      <IconButton onClick={handleCreateNewAddress}>
                        <AddCircleOutline />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Address;
