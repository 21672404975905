import React, { useEffect, useState } from 'react';
import { IconButton, CircularProgress, IconButtonProps, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface LoadingIconButtonWithResultProps extends IconButtonProps {
  loading?: boolean;
  result?: 'success' | 'failure';
}

const LoadingIconButtonWithResult: React.FC<LoadingIconButtonWithResultProps> = ({
  loading = false,
  result,
  children,
  ...props
}) => {
  const [displayedResult, setDisplayedResult] = useState<'success' | 'failure' | null>(null);

  useEffect(() => {
    if (result) {
      setDisplayedResult(result);
      const timer = setTimeout(() => setDisplayedResult(null), 2000); // Reset after 2s
      return () => clearTimeout(timer);
    }
  }, [result]);

  const getResultIcon = () => {
    if (displayedResult === 'success') {
      return (
        <CheckCircleIcon
          color='success'
          sx={{ fontSize: 24, opacity: 1, transition: 'opacity 0.3s' }}
        />
      );
    }
    if (displayedResult === 'failure') {
      return (
        <CancelIcon color='error' sx={{ fontSize: 24, opacity: 1, transition: 'opacity 0.3s' }} />
      );
    }
    return null;
  };

  return (
    <Box component='div' position='relative' display='inline-flex'>
      <IconButton
        {...props}
        disabled={loading || props.disabled}
        sx={{
          ...props.sx,
          position: 'relative',
        }}
      >
        {displayedResult ? getResultIcon() : children}
      </IconButton>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};

export default LoadingIconButtonWithResult;
