import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import {
  handleAutocompleteChange,
  handleCheckboxChange,
  handleNumericTextFieldChange,
  handleTextFieldChange,
} from '../../../../../utils/components/DialogCrudDataGrid';
import NumericTextField from '../../../../../utils/components/NumericTextField';
import { PropertyInventoryDataGrid } from './PropertyInventoryDataGrid';

export function CurrencyDataGrid() {
  const { user } = useAuth();
  const [countryOptions, setcountryOptions] = useState([]);
  const [denominationOptions, setdenominationOptions] = useState([]);
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext<CMSCase>();

  useEffect(() => {
    fetch(VITE_API_URL + 'getcountryoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setcountryOptions(data);
      })
      .catch(e => {
        alert('Failed to get from country Options\nError: ');
      });

    fetch(VITE_API_URL + 'getcurrencydenominations', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setdenominationOptions(data);
      })
      .catch(e => {
        alert('Failed to get from denominations Options\nError: ');
      });
  }, []);

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={countryOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.countryOfOrigin || null}
            onChange={(event, value) =>
              handleAutocompleteChange(
                event,
                value,
                'countryOfOrigin',
                localData,
                setLocalData,
                onChange
              )
            }
            renderInput={params => (
              <TextField {...params} label='Country' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={denominationOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.denomination || null}
            onChange={(event, value) =>
              handleAutocompleteChange(
                event,
                value,
                'denomination',
                localData,
                setLocalData,
                onChange
              )
            }
            renderInput={params => (
              <TextField {...params} label='Denomination' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <NumericTextField
            value={localData?.number ?? 0}
            onChange={value =>
              handleNumericTextFieldChange(value, 'number', localData, setLocalData, onChange)
            }
            margin='dense'
            fullWidth
            label='Number'
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Funeral Home'
            control={
              <Checkbox
                checked={localData?.funeralHome ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'funeralHome', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Law Enforcement'
            control={
              <Checkbox
                checked={localData?.releasedtoLE ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'releasedtoLE', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Retained'
            control={
              <Checkbox
                checked={localData?.retained ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'retained', localData, setLocalData, onChange)
                }
              />
            }
            sx={{ pr: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={localData?.note ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'note', localData, setLocalData, onChange)
            }
            label='Notes'
            fullWidth
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  const propertyCurrencyColumns = [
    {
      flex: 1,
      minWidth: 120,
      field: 'countryOfOrigin',
      headerName: 'Country',

      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: countryOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 0.5,
      minWidth: 100,
      field: 'denomination',
      headerName: 'Den.',

      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: denominationOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 0.5,
      minWidth: 100,
      field: 'number',
      headerName: 'Number',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
  ];

  return (
    <PropertyInventoryDataGrid
      rows={formik.values?.caseMortuary?.propertyInventory?.propertyCurrency ?? []}
      columns={propertyCurrencyColumns}
      idColumn='currencySeq'
      getApi='getpropertycurrency'
      createApi='createpropertycurrency'
      updateApi='updatepropertycurrency'
      deleteApi='deletepropertycurrency'
      title='Currency'
      fieldPath='propertyCurrency'
      dialogContent={dialogContent}
    />
  );
}
