import {
  ChevronLeft, // Re-added ChevronRight
  CloudUploadRounded,
  CreateNewFolderRounded,
  FileOpenRounded,
  Folder,
  ImageRounded,
  MoreHoriz,
  PictureAsPdfRounded,
  VideoFileRounded,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../../../../utils/auth/AuthService';

const { VITE_API_URL } = import.meta.env;

const getFileIcon = fileType => {
  switch (fileType) {
    case 'pdf':
      return <PictureAsPdfRounded />;
    case 'image':
      return <ImageRounded />;
    case 'video':
      return <VideoFileRounded />;
    case 'folder':
      return <Folder />;
    default:
      return <FileOpenRounded />;
  }
};

export const FilesAdminView = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Delete Confirmation Dialog State
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  // Download Confirmation Dialog State
  const [openDownloadConfirm, setOpenDownloadConfirm] = useState(false);
  const [downloadItem, setDownloadItem] = useState(null);

  // State for Drag & Drop Uploads over the List
  const [isListDragActive, setIsListDragActive] = useState(false);
  const [dropFiles, setDropFiles] = useState([]);

  // Menu and Rename Dialog States
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [newName, setNewName] = useState('');

  useEffect(() => {
    fetchFileStructure();
  }, []);

  const fetchFileStructure = async (folderId = undefined) => {
    setIsLoading(true);
    try {
      let url = `${VITE_API_URL}systems-admin/support-files`;
      if (folderId) {
        url += `?path=${encodeURIComponent(folderId)}`;
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch file structure.');
      }
      const data = await response.json();
      setCurrentFolder(data);
    } catch (error) {
      console.error('Error fetching file structure:', error);
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFolderClick = async folder => {
    setCurrentPath([...currentPath, currentFolder]);
    setCurrentFolder(folder);
    fetchFileStructure(folder.id);
  };

  const handleBackClick = () => {
    if (currentPath.length > 0) {
      const newPath = [...currentPath];
      const previousFolder = newPath.pop();
      setCurrentPath(newPath);
      setCurrentFolder(previousFolder);
      fetchFileStructure(previousFolder.id);
    }
  };

  const handleUpload = useCallback(files => {
    setAcceptedFiles(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUpload,
    multiple: true,
  });

  const handleListDrop = useCallback(
    files => {
      if (files && files.length > 0) {
        setDropFiles(files);
        setIsUploading(true);
        const uploadFiles = async () => {
          try {
            const formData = new FormData();
            files.forEach(file => {
              formData.append('files', file);
            });
            formData.append(
              'path',
              currentFolder && currentFolder.id !== 'root' ? currentFolder.id : ''
            );

            const response = await fetch(`${VITE_API_URL}systems-admin/upload-file`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${auth.user?.accessToken}`,
              },
              body: formData,
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Failed to upload files.');
            }

            fetchFileStructure(currentFolder?.id);
            setDropFiles([]);
          } catch (error) {
            console.error('Error uploading files:', error);
            setError(error.message);
            setSnackbarOpen(true);
          } finally {
            setIsUploading(false);
          }
        };
        uploadFiles();
      }
    },
    [auth.user, currentFolder]
  );

  const {
    getRootProps: getListRootProps,
    getInputProps: getListInputProps,
    isDragActive: isListDragActiveState,
  } = useDropzone({
    onDrop: handleListDrop,
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDragEnter: () => setIsListDragActive(true),
    onDragLeave: () => setIsListDragActive(false),
    onDropAccepted: () => setIsListDragActive(false),
    onDropRejected: () => setIsListDragActive(false),
  });

  const handleCreateFolder = async () => {
    setOpenNewFolderDialog(false);
    setIsUploading(true);
    try {
      const response = await fetch(`${VITE_API_URL}systems-admin/create-folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        body: JSON.stringify({
          name: newFolderName,
          path: currentFolder && currentFolder.id !== 'root' ? currentFolder.id : '',
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create folder.');
      }

      setNewFolderName('');
      fetchFileStructure(currentFolder?.id);
    } catch (error) {
      console.error('Error creating folder:', error);
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsUploading(false);
    }
  };

  const confirmDelete = item => {
    setDeleteItem(item);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    if (!deleteItem) return;
    setOpenDeleteConfirm(false);
    setIsUploading(true);
    try {
      const response = await fetch(`${VITE_API_URL}systems-admin/delete-item`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        body: JSON.stringify({
          id: deleteItem.id,
          path: currentFolder && currentFolder.id !== 'root' ? currentFolder.id : '',
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete the item.');
      }

      fetchFileStructure(currentFolder?.id);
    } catch (error) {
      console.error('Error deleting item:', error);
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsUploading(false);
    }
  };

  const confirmDownload = item => {
    setDownloadItem(item);
    setOpenDownloadConfirm(true);
  };

  const handleDownloadConfirm = async () => {
    if (!downloadItem) return;
    setOpenDownloadConfirm(false);
    setIsDownloading(true);
    try {
      const response = await fetch(
        `${VITE_API_URL}systems-admin/download-file?id=${encodeURIComponent(downloadItem.id)}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to download the file.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadItem.label);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsDownloading(false);
    }
  };

  const renderBreadcrumbs = () => (
    <Breadcrumbs>
      <Link
        underline='hover'
        color='inherit'
        href='/'
        onClick={e => {
          e.preventDefault();
          setCurrentPath([]);
          setCurrentFolder(null);
          fetchFileStructure();
        }}
        sx={{ cursor: 'pointer' }}
      >
        Admin Resources
      </Link>
      {currentPath.map((folder, index) => (
        <Link
          key={index}
          underline='hover'
          color='inherit'
          onClick={() => {
            const newPath = currentPath.slice(0, index);
            setCurrentPath(newPath);
            setCurrentFolder(folder);
            fetchFileStructure(folder.id);
          }}
          sx={{ cursor: 'pointer' }}
        >
          {folder.label}
        </Link>
      ))}
      {currentFolder && currentFolder.label !== 'SupportFiles' && (
        <Typography color='text.primary'>{currentFolder.label}</Typography>
      )}
    </Breadcrumbs>
  );

  const handleSnackbarClose = event => {
    setSnackbarOpen(false);
  };

  const handleRename = async () => {
    if (!selectedItem) return;

    setOpenRenameDialog(false);
    setIsUploading(true);

    try {
      const response = await fetch(`${VITE_API_URL}systems-admin/rename-item`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        body: JSON.stringify({
          id: selectedItem.id,
          newName: newName.trim(),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to rename the item.');
      }

      // Optionally, display a success message
      fetchFileStructure(currentFolder?.id);
    } catch (error) {
      console.error('Error renaming item:', error);
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsUploading(false);
      setSelectedItem(null);
      setNewName('');
    }
  };

  return (
    <Grid container spacing={1} direction='row' style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }}>
        {renderBreadcrumbs()}
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      </Grid>

      <Grid item xs={12} style={{ height: 'calc(100% - 100px)' }}>
        {/* Adjust height as needed */}
        <Box sx={{ p: 2, position: 'relative', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Button
              variant='text'
              startIcon={<ChevronLeft />}
              onClick={handleBackClick}
              disabled={currentPath.length === 0}
            >
              {currentPath.length > 0 ? currentPath[currentPath.length - 1].label : 'Root'}
            </Button>
            <Typography variant='h6' sx={{ textAlign: 'center' }}>
              {currentFolder ? currentFolder.label : 'SupportFiles'}
            </Typography>
            <IconButton color='primary' onClick={handleMenuClick}>
              <MoreHoriz />
            </IconButton>
          </Box>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                setOpenUploadDialog(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <CloudUploadRounded fontSize='small' />
              </ListItemIcon>
              <ListItemText>Upload Files</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenNewFolderDialog(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <CreateNewFolderRounded fontSize='small' />
              </ListItemIcon>
              <ListItemText>New Folder</ListItemText>
            </MenuItem>
          </Menu>
          <Divider sx={{ borderColor: '#555', my: 2 }} />

          {/* Dropzone for the List Area */}
          <Box
            {...getListRootProps()}
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              border: isListDragActiveState ? '2px dashed #1976d2' : '2px dashed transparent', // Transparent when not active
              borderRadius: 1,
              backgroundColor: isListDragActiveState ? 'rgba(25, 118, 210, 0.1)' : 'transparent',
              transition: 'background-color 0.3s, border-color 0.3s',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input {...getListInputProps()} />
            {isListDragActiveState && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#fff',
                  borderRadius: 1,
                  flexDirection: 'column',
                }}
              >
                <Typography variant='h6' mb={1}>
                  Drop {dropFiles.length > 1 ? `${dropFiles.length} files` : 'a file'} to upload
                </Typography>
                <Typography variant='body2'>
                  Target Folder: {currentFolder ? currentFolder.label : 'Root'}
                </Typography>
              </Box>
            )}
            {isUploading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  bgcolor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 1,
                  flexDirection: 'column',
                }}
              >
                <CircularProgress />
                <Typography variant='body1' mt={2}>
                  Uploading {dropFiles.length > 1 ? `${dropFiles.length} files` : 'a file'} to{' '}
                  {currentFolder ? currentFolder.label : 'Root'}
                </Typography>
              </Box>
            )}
            {!isLoading && (
              <List style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                {currentFolder &&
                  currentFolder.children &&
                  currentFolder.children.map(item => (
                    <ListItemButton
                      key={item.id}
                      onClick={() => {
                        if (item.fileType === 'folder') {
                          handleFolderClick(item);
                        } else {
                          confirmDownload(item);
                        }
                      }}
                    >
                      <ListItemIcon>{getFileIcon(item.fileType)}</ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        secondary={`${new Date(item.lastModified).toLocaleDateString()} — ${
                          item.fileType === 'folder'
                            ? `${item.children ? item.children.length : 0} items`
                            : `${(item.size / 1024 / 1024).toFixed(2)} MB`
                        }`}
                      />
                      {/* {item.fileType === 'folder' && (
                        <ChevronRight sx={{ marginRight: 1 }} /> // Added ChevronRight for folders
                      )} */}
                      <IconButton
                        edge='end'
                        onClick={e => {
                          e.stopPropagation(); // Prevent triggering the ListItemButton onClick
                          setSelectedItem(item);
                          setMenuAnchorEl(e.currentTarget);
                        }}
                      >
                        <MoreHoriz />
                      </IconButton>
                    </ListItemButton>
                  ))}
              </List>
            )}
            {isLoading && (
              <List sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                {Array.from(new Array(5)).map((_, index) => (
                  <ListItem key={index} sx={{ width: '100%' }}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <Skeleton variant='circular' width={40} height={40} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Skeleton variant='text' width='80%' />}
                      secondary={<Skeleton variant='text' width='60%' />}
                      sx={{ width: '100%', ml: 2 }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
      </Grid>

      {/* Upload Dialog */}
      <Dialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>Upload Files</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Drag and drop files here or click to select files to upload.
          </DialogContentText>
          <Box
            {...getRootProps()}
            sx={{
              border: isDragActive ? '2px dashed #1976d2' : '2px dashed #ccc',
              p: 2,
              mt: 2,
              textAlign: 'center',
              cursor: 'pointer',
              borderRadius: 1,
              backgroundColor: isDragActive ? 'rgba(25, 118, 210, 0.1)' : 'transparent',
              transition: 'background-color 0.3s, border-color 0.3s',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop some files here, or click to select files</Typography>
            )}
          </Box>
          {acceptedFiles.length > 0 && (
            <Box mt={2}>
              <Typography variant='subtitle1'>
                {acceptedFiles.length} {acceptedFiles.length > 1 ? 'files' : 'file'} selected
              </Typography>
              <List>
                {acceptedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={file.name}
                      secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpenUploadDialog(false);
              setIsUploading(true);
              const uploadFiles = async () => {
                try {
                  const formData = new FormData();
                  acceptedFiles.forEach(file => {
                    formData.append('files', file);
                  });
                  formData.append(
                    'path',
                    currentFolder && currentFolder.id !== 'root' ? currentFolder.id : ''
                  );

                  const response = await fetch(`${VITE_API_URL}systems-admin/upload-file`, {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${auth.user?.accessToken}`,
                    },
                    body: formData,
                  });

                  if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to upload files.');
                  }

                  fetchFileStructure(currentFolder?.id);
                  setAcceptedFiles([]);
                } catch (error) {
                  console.error('Error uploading files:', error);
                  setError(error.message);
                  setSnackbarOpen(true);
                } finally {
                  setIsUploading(false);
                }
              };
              uploadFiles();
            }}
            color='primary'
            variant='contained'
            disabled={acceptedFiles.length === 0 || isUploading}
            startIcon={isUploading && <CircularProgress size={20} />}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Folder Dialog */}
      <Dialog
        open={openNewFolderDialog}
        onClose={() => setOpenNewFolderDialog(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Folder Name'
            type='text'
            fullWidth
            value={newFolderName}
            onChange={e => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewFolderDialog(false)}>Cancel</Button>
          <Button
            onClick={handleCreateFolder}
            disabled={!newFolderName.trim() || isUploading}
            startIcon={isUploading && <CircularProgress size={20} />}
          >
            {isUploading ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rename Dialog */}
      <Dialog
        open={openRenameDialog}
        onClose={() => setOpenRenameDialog(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>Rename {selectedItem?.fileType === 'folder' ? 'Folder' : 'File'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='New Name'
            type='text'
            fullWidth
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRenameDialog(false)}>Cancel</Button>
          <Button
            onClick={handleRename}
            color='primary'
            variant='contained'
            disabled={!newName.trim()}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>{deleteItem ? `Delete "${deleteItem.label}"?` : 'Delete Item'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'bold' }}>This cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)} fullWidth variant='outlined'>
            No, Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color='error' variant='contained'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Download Confirmation Dialog */}
      <Dialog
        open={openDownloadConfirm}
        onClose={() => setOpenDownloadConfirm(false)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>
          {downloadItem ? `Download "${downloadItem.label}"?` : 'Download File'}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setOpenDownloadConfirm(false)}>No, Cancel</Button>
          <Button onClick={handleDownloadConfirm} color='success' variant='contained'>
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Menu for Rename and Delete */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);
            setOpenRenameDialog(true);
            setNewName(selectedItem.label); // Pre-fill with current name
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);
            confirmDelete(selectedItem);
          }}
          disabled={
            selectedItem?.fileType === 'folder' &&
            selectedItem.children &&
            selectedItem.children.length > 0
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {/* Rename Dialog */}
      <Dialog
        open={openRenameDialog}
        onClose={() => setOpenRenameDialog(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>Rename {selectedItem?.fileType === 'folder' ? 'Folder' : 'File'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='New Name'
            type='text'
            fullWidth
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRenameDialog(false)}>Cancel</Button>
          <Button
            onClick={handleRename}
            color='primary'
            variant='contained'
            disabled={!newName.trim()}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Error Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='error'
          sx={{ width: '100%' }}
          action={
            <Button color='inherit' size='small' onClick={() => setSnackbarOpen(false)}>
              Close
            </Button>
          }
        >
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
