import { Box, Container, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { AppLogoImg } from '../../assets/index';
import { useAuth } from '../../utils/auth/AuthService';

export interface Settings {
  AppName: string | '';
  AppDisclaimer: string | '';
  AppLogo: string | '';
}

export interface Props {
  settings: Settings;
}

export function LoginView() {
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { VITE_API_URL } = import.meta.env;

  const fetchLoginViewSettings = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${VITE_API_URL}GlobalAppSettings/getloginglobalappsettings`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch settings');
      }

      const fetchedSettings: Settings = await response.json();

      setSettings(fetchedSettings);
    } catch (err) {
      console.error('Error fetching settings:', err);
      setError('Failed to fetch settings');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoginViewSettings();
  }, []);

  useEffect(() => {
    if (auth.ready && auth.user && auth.two_factor.passed) {
      navigate('/home');
    }
  }, [auth, auth.ready, auth.user]);

  return (
    <Box
      bgcolor={theme.palette.background.default}
      color={theme.palette.text.primary}
      height='100%'
      component='div'
      display='flex'
      alignItems='center'
      justifyContent='center'
      py={5}
    >
      <Container
        sx={{
          display: { md: 'grid', xs: 'flex' },
          flexDirection: { xs: 'column' },
          gridTemplateColumns: { lg: '1fr 1fr' },
          maxHeight: '100%',
          overflowY: 'auto',
        }}
      >
        <WarningBox settings={settings} />
        <LoginSection settings={settings} />
      </Container>
    </Box>
  );
}

const LoginSection: React.FC<Props> = ({ settings }) => {
  return (
    <Box component='div' p={6}>
      <Box component='div'>
        <div
          className='profile-img-card'
          dangerouslySetInnerHTML={{ __html: settings?.AppLogo }}
          style={{ backgroundColor: 'none' }}
        />
      </Box>

      <Outlet />
    </Box>
  );
};

const WarningBox: React.FC<Props> = ({ settings }) => {
  return (
    <Box
      component='div'
      display={{ lg: 'flex' }}
      flexDirection='column'
      bgcolor='#282828'
      p={2}
      color='grey.500'
      // sx={{ opacity: '0.6' }}
      justifyContent='space-between'
    >
      <Box component='div' position='relative' display='flex' alignItems='center'>
        <Typography variant='h6' fontWeight='bold'>
          {settings?.AppName}
        </Typography>
      </Box>
      <Box component='div' position='relative' mt='auto'>
        <Typography variant='body2'>{settings?.AppDisclaimer}</Typography>
      </Box>
    </Box>
  );
};
