import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Divider, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import LabTestTrackingDataGrid from '../../../components/CaseView/LabTestingView/LabTestTrackingDataGrid';
import ToxResultsDataGrid from '../../../components/CaseView/LabTestingView/ToxResultsDataGrid';
import { useUnsavedChanges } from '../../../Context/UnsavedChangesContext';
import { useAuth } from '../../../utils/auth/AuthService';
import { FormikTextField } from '../../../utils/components/FormikTextField';

export default function LabTestingView() {
  const [selectedTab, setSelectedTab] = useState('POS');
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext();
  const { setUnsavedChanges } = useUnsavedChanges();

  const informantcntinfocolumns = [
    {
      field: 'test',
      headerName: 'Test Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: value => {
        return value ? value.optionName : '';
      },
    },
    {
      field: 'details',
      headerName: 'Value',
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      renderCell: params => {
        return (
          <div>
            <Button
              onClick={e => onContactInfoEditClick(e, params.row)}
              size='small'
              color='primary'
              style={{ justifyContent: 'left' }}
              startIcon={<EditIcon />}
            ></Button>
            <Button
              onClick={e => onContactInfoDeleteClick(e, params.row)}
              size='small'
              color='error'
              style={{ justifyContent: 'left' }}
              startIcon={<DeleteIcon />}
            ></Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log(formik.values?.caseLabTesting.toxLabResults);
  }, [formik.values?.caseLabTesting.toxLabResults]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const authorizedToEdit =
    user?.roleCheck([
      '2D5', // Investigator
      '451', // System Admin
      'MES', // Pathologist
      'c97', // Administrative Assistant,
      '08b', // HISTORIC-DATAENTRYONLY
      '0C4', // Forensic Tech
    ]) || false;

  return (
    <form>
      <Stack spacing={2}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>TEST REQUEST LOG</strong>
        </Typography>

        <LabTestTrackingDataGrid />

        <Grid container sx={{ mt: 1 }}>
          <FormikTextField
            gridProps={{ xs: 12, md: 3 }}
            authorizedToEdit={authorizedToEdit}
            formikField='caseCertification.labNumber'
            label='Lab Number'
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>

        <Typography variant='subtitle1' color='gray'>
          <strong>TEST DOCUMENTS </strong>
        </Typography>

        <CaseDocuments caseinfo={formik.values?.caseSummary} isLab={true} />

        <br />

        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>LAB TEST TOX NOTES </strong>
        </Typography>

        <TextField
          disabled={!formik.status.editing}
          fullWidth
          id='caseLabTesting.finalToxicologyResult'
          name='caseLabTesting.finalToxicologyResult'
          label='Tox Notes'
          variant='outlined'
          multiline
          rows={4}
          value={formik.values?.caseLabTesting.finalToxicologyResult}
          onChange={e => {
            formik.handleChange(e);
            setUnsavedChanges();
          }}
          error={
            (formik.touched?.caseLabTesting?.finalToxicologyResult ?? false) &&
            Boolean(formik.errors?.caseLabTesting?.finalToxicologyResult ?? false)
          }
          helperText={
            (formik.touched?.caseLabTesting?.finalToxicologyResult ?? false) &&
            (formik.errors?.caseLabTesting?.finalToxicologyResult ?? false)
          }
        />
        <br />

        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>TOXICOLOGY RESULTS </strong>
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label='Filter Lab Tests'
          sx={{ mt: 2 }}
          textColor='primary'
          indicatorColor='primary'
          variant='fullWidth'
        >
          <Tab label='Pos' value='POS' />
          <Tab label='Neg' value='NEG' />
          <Tab label='All' value='All' />
        </Tabs>

        <ToxResultsDataGrid filterBy={selectedTab} />
      </Stack>
    </form>
  );
}
