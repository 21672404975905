export const convertSvgToPng = (svgText: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!svgText) {
        resolve('');
        return;
      }
      const img = new Image();
      const svgDataUrl = 'data:image/svg+xml;base64,' + btoa(svgText);
      img.src = svgDataUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (ctx) {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const pngDataUrl = canvas.toDataURL('image/png');
          resolve(pngDataUrl); 
        } else {
          reject('Failed to get canvas context');
        }
      };
  
      img.onerror = (error) => {
        reject('Error loading SVG as image: ' + error); 
      };
    });
  };