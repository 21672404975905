import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DoneIcon from '@mui/icons-material/Done';
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../../../Context/GlobalContext';
import AccessDenied from '../../../../components/AdministrationModules/AccessDenied';
import ApiNotification from '../../../../components/DialogBox';
import authHeader from '../../../../services/auth-header';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const RolesAdminView = () => {
  const [role, setRole] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');
  const [open, setOpen] = useState(false);
  const [iconData, setIcondata] = useState({});
  const [dialogMessageModal, setDialogMessageModal] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [modal, setModal] = useState(false);
  const [dialogContents, setDialogContents] = useState('');
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const rolePermissions = permissions?.['Roles'];
  const navigate = useNavigate();
  const { roleDetails, sendRoleDetails, sendCopyRoleObject } = useContext(GlobalContext);
  const columns = [
    {
      field: 'roleName',
      headerName: 'Roles',
      renderCell: ({ row }) => row.name,
      minWidth: 300,
      align: 'left',
      // flex: 5,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      // headerClassName: 'super-app-theme--header',
      renderCell: cellValues => {
        const handleClick = event => {
          event.stopPropagation();
          setOpen(true);
          const newIconData = cellValues.row.status.toLowerCase() === 'active' ? 'locked' : 'done';
          setIcondata({
            icon: newIconData,
            userseq: cellValues?.id,
            status: cellValues.row.status,
          });
        };

        return (
          <Button onClick={handleClick} disabled={!rolePermissions?.edit}>
            {cellValues.row.status.toLowerCase() === 'active' ? <LockIcon /> : <DoneIcon />}
          </Button>
        );
      },
    },
  ];

  const UpdateStatus = async () => {
    const callStatus = iconData.status === 'Active' ? 'DeleteRoleDetails' : 'ActiveRoleDetails';
    await axios
      .post(`${import.meta.env.VITE_API_URL}${callStatus}?RoleSeq=${iconData.userseq}`, null, {
        headers: authHeader(),
      })
      .then(response => {
        getAllRoles();
        setModal(true);
        setOpen(false);
        setDialogContents(response.data.message);
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  const searchInTable = () => {
    role
      ? activeInActiveFilter.toLowerCase() === 'all'
        ? setFilteredRows(rows.filter(a => a.roleSeq === role))
        : setFilteredRows(
            rows.filter(
              a =>
                a.status.toLowerCase() === activeInActiveFilter.toLowerCase() && a.roleSeq === role
            )
          )
      : activeInActiveFilter.toLowerCase() === 'all'
      ? setFilteredRows(rows)
      : setFilteredRows(
          rows.filter(a => a.status.toLowerCase() === activeInActiveFilter.toLowerCase())
        );
  };

  const getAllRoles = async () => {
    axios
      .get(`${import.meta.env.VITE_API_URL}GetAllRole`, {
        headers: authHeader(),
      })
      .then(res => {
        let data = res?.data?.data?.map(a => ({
          ...a,
          value: a.roleSeq,
          name: a.roleName,
        }));
        setRoleOptions(data);
        setRows(data);
        setFilteredRows(
          data.filter(a => a.status.toLowerCase() === activeInActiveFilter.toLowerCase())
        );
      });
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const checkStatus = name => {
    const selectedUser = rows?.filter(row => row?.roleSeq === name);
    return selectedUser?.[0]?.status.toLowerCase() === 'inactive';
  };

  useEffect(() => {
    searchInTable();
  }, [role, activeInActiveFilter]);

  if (rolePermissions?.view) {
    return (
      <div>
        <Stack>
          <Typography variant='h5' component='h1'>
            <b>Roles Admin</b>
          </Typography>
          <Typography variant='subtitle1' color='gray'>
            Create, Edit and Modify Roles
          </Typography>
        </Stack>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <br />

        <Box style={{ marginTop: '0px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={4}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='role' id='role'>
                  Role Filter
                </InputLabel>
                <Select
                  label='Role'
                  id='role'
                  name='role'
                  size='large'
                  value={role}
                  onChange={e => setRole(e.target.value)}
                  style={{ width: '100%', height: '100%' }}
                >
                  {roleOptions?.map(roleOption => {
                    if (roleOption) {
                      return <MenuItem value={roleOption?.value}>{roleOption?.name}</MenuItem>;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='isActive'
                onChange={e => setActiveInActiveFilter(e.target.value)}
                value={activeInActiveFilter}
                sx={{ marginLeft: 1 }}
              >
                {' '}
                <FormControlLabel value='Active' control={<Radio />} label='Active Only' />{' '}
                <FormControlLabel value='InActive' control={<Radio />} label='Inactive Only' />{' '}
                <FormControlLabel value='all' control={<Radio />} label='All' />{' '}
              </RadioGroup>
            </Grid>
          </Grid>

          <Box
            mt={2}
            // sx={{
            //   '& .super-app-theme--header': {
            //     backgroundColor: 'rgb(2, 136, 209)',
            //   },
            // }}
          >
            <DataGridPremium
              // className='ViewTable'
              getRowId={row => row.value}
              rows={filteredRows}
              columns={columns}
              columnHeaderHeight={50}
              rowHeight={30}
              pageSize={50}
              scrollbarSize={5}
              pagination
              slots={{
                pagination: CustomPagination,
              }}
              onRowSelectionModelChange={row => {
                if (rolePermissions?.edit && !checkStatus(row?.[0]) && row?.length !== 0) {
                  let role = rows.find(a => a.roleSeq === row[0]);
                  sendRoleDetails(role);
                  navigate('rolesedit');
                }
              }}
              // sx={{
              //   width: '100%',
              //   height: 450,
              //   // border: 'none',
              //   borderRadius: '6px',
              //   backgroundColor: 'white',
              //   // boxShadow: '0px 0px 10px #ddd',
              // }}
            />

            <Button
              variant='contained'
              color='info'
              sx={{ mt: 2 }}
              disabled={!rolePermissions?.add}
              onClick={() => {
                sendRoleDetails('00000000-0000-0000-0000-000000000000');
                sendCopyRoleObject('00000000-0000-0000-0000-000000000000');
                navigate('rolesedit');
              }}
              startIcon={<ControlPointIcon />}
            >
              Add Roles
            </Button>

            {/* Update status modal */}
            <div className='modal'>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby='alert-dialog-slide-description'
              >
                <DialogContent>
                  <DialogContentText id='alert-dialog-slide-description'>
                    Are you sure you want to{' '}
                    {activeInActiveFilter.toLowerCase() === 'active' ? 'Inactive' : 'Active'} this
                    role?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>No</Button>
                  <Button onClick={UpdateStatus}>Yes</Button>
                </DialogActions>
              </Dialog>
            </div>
            {modal ? (
              <ApiNotification
                openApiDialogBox={modal}
                closeDialogBox={() => setModal(false)}
                dialogContents={dialogContents}
              />
            ) : null}
          </Box>
        </Box>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AccessDenied />;
      </div>
    );
  }
};

export default RolesAdminView;
