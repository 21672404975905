// ConversationContext.tsx

import { HubConnectionState } from '@microsoft/signalr';
import { useMediaQuery, useTheme } from '@mui/material';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { useNotifications } from '../../../../../utils/components/AppBar/Notifications/NotificationsContext';
import { useGlobalAppSetting } from '../../../../../utils/functions/fetchGlobalAppSetting';
import { useUserNotificationPreferences } from '../../../../../utils/hooks/useUserNotificationPreferences';
import { ChatNotifications } from './ChatNotification';
import {
  ChatMessage,
  ChatNotificationItem,
  ChatParticipantHistory,
  ChatRecord,
  Conversation,
  ConversationMessage,
  ConversationParticipant,
  ConversationType,
  ConversationTypes,
  CreateChatRequestMetadata,
  OnOtherUserAddedToChatEvent,
  OnSelfAddedToChatEvent,
  SendChatMessageRequest,
} from './conversation.types';
import { ConversationUser } from './Dialogs/NewConversationDialog';

const { VITE_API_URL } = import.meta.env;

interface ConversationContextType {
  conversations: Conversation[];
  chats: ChatRecord[];
  setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>;
  loading: boolean;
  refreshing: boolean;
  error: Error | null;

  onlineParticipants: string[];
  fetchUserChats: (options?: { refresh?: boolean }) => Promise<void>;
  fetchChatsAccesibleBySupport: (options?: { refresh?: boolean }) => Promise<void>;
  createChat: (
    chatRecord: Partial<ChatRecord>,
    metadata?: Partial<CreateChatRequestMetadata>,
    attachments?: File[]
  ) => Promise<ChatRecord>;
  sendMessage: (request: SendChatMessageRequest) => Promise<void>;
  addParticipantsToChat: (params: {
    conversationSeq: string;
    participants: {
      userSeq: string;
      canViewMessageHistoryUpToTimestamp?: string;
      silent?: boolean;
    }[];
  }) => Promise<void>;

  removeParticipantFromChat: (params: {
    conversationSeq: string;
    participantUserSeq: string;
    silent?: boolean;
  }) => Promise<void>;

  notifications: ChatNotificationItem[];
  handleCloseNotification: (notificationId: string) => void;
  handleNotificationClick: (conversationSeq: string, notificationId: string) => void;
  toggleNotificationPause: (notificationId: string) => void;
  messageNotificationDismissSeconds: number;
  isUserOnline: (userSeq: string) => boolean;
  handleMessageRead: (messageSeq: string, userSeq?: string) => Promise<void>;
  handleSendMessage: (sendMessageRequest: SendMessageRequest) => Promise<void>;
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  availableUsers: ConversationUser[];
  updateConversationOpenStatus: (params: {
    conversationSeq: string;
    isOpen: boolean;
  }) => Promise<void>;
  unreadConversationCount: number;
  unreadMessagesCount: number;
  fetchUnreadConversatonsCount: (params: { userSeq?: string }) => Promise<number>;
  supportAgentName: string | null;
  selectedChat: ChatRecord | null;
  setSelectedChat: React.Dispatch<React.SetStateAction<ChatRecord | null>>;
  handleSelectChat: (chat: ChatRecord) => void;
  showConversationList: boolean;
  setShowConversationList: React.Dispatch<React.SetStateAction<boolean>>;
  createConversation: (params: {
    topic?: string;
    message?: string;
    conversationType?: string;
    participantUserSeqs?: string[];
    attachments?: any[];
  }) => Promise<Conversation>;
  creatingConversation: boolean;
  emitTypingIndicator: (conversationSeq: string) => void;
  typingParticipants: Record<string, TypingParticipant[]>;
  conversationTypes: ConversationType[];
  clearNotifications: () => void;
  unsubscribeFromAllChats: () => void;
}

interface TypingParticipant extends ConversationParticipant {
  isTyping: boolean;
}

interface SendMessageRequest {
  conversationSeq: string;
  message: string;
  attachments: File[];
  shouldNotifySupport?: boolean;
}

const ConversationContext = createContext<ConversationContextType | undefined>(undefined);

export const useConversation = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error('useConversation must be used within a ConversationProvider');
  }
  return context;
};

export const ConversationProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const {
    notificationPreferences,
    loading: notificationPreferencesLoading,
    error: notificationPreferencesError,
  } = useUserNotificationPreferences(user?.userSeq || '');

  const { notificationsConnection: connection } = useNotifications();
  const { setting: messageNotificationDismissSeconds } = useGlobalAppSetting(
    'MessageNotificationDismissSeconds'
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [unreadConversationCount, setUnreadConversationCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [supportAgentName, setSupportAgentName] = useState<null | string>(null);
  const [availableUsers, setAvailableUsers] = useState<ConversationUser[]>([]);
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [showConversationList, setShowConversationList] = useState(false);
  const [conversationTypes, setTypes] = useState<ConversationType[]>([]);
  const [typingParticipants, setTypingParticipants] = useState<Record<string, TypingParticipant[]>>(
    {}
  );
  const typingTimeouts = useRef<Record<string, NodeJS.Timeout>>({});
  const [notifications, setNotifications] = useState<ChatNotificationItem[]>([]);
  const [chats, setChats] = useState<ChatRecord[]>([]);
  const chatsRef = useRef<ChatRecord[]>(chats);
  const [selectedChat, setSelectedChat] = useState<ChatRecord | null>(null);
  const selectedChatRef = useRef<ChatRecord | null>(selectedChat);

  const [onlineParticipants, setOnlineParticipants] = useState<string[]>([]);

  const unreadMessagesCount = useMemo(() => {
    let count = 0;
    chats.forEach(chat => {
      chat.messages.forEach(message => {
        if (
          message?.sender?.userSeq?.toLowerCase() !== user?.userSeq.toLowerCase() &&
          !message.readBy?.some(
            reader => reader?.userSeq?.toLowerCase() === user?.userSeq.toLowerCase()
          )
        ) {
          count++;
        }
      });
    });
    return count;
  }, [chats, user?.userSeq]);

  useEffect(() => {
    selectedChatRef.current = selectedChat;
  }, [selectedChat]);

  useEffect(() => {
    if (notificationPreferences) {
      setNotifications([]);
    }
  }, [notificationPreferences]);

  const unsubscribeFromAllChats = () => {
    if (chatsRef.current) {
      connection
        ?.invoke(
          'LeaveChats',
          chats.map(c => c.conversationSeq)
        )
        .then(() => {
          console.log(`[Chat] Unsubscribed from ${chats.length} chats `);
          connectedChatsRef.current.clear();
        });
    }
  };

  // ========== Notification handlers ==========

  const handleCloseNotification = (notificationId: string) => {
    setNotifications(prev => prev.filter(n => n.id !== notificationId));
  };

  const handleNotificationClick = useCallback(
    (conversationSeq: string, notificationId: string) => {
      // Use chatsRef.current to avoid stale array
      // console.log(conversationSeq);
      const foundChat =
        chatsRef.current.find(
          c => c.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()
        ) || null;

      // console.log('Found chat:', foundChat, chats.length, chatsRef.current.length);
      // chatsRef.current.forEach(c => console.log(c.conversationSeq));
      if (foundChat) {
        handleSelectChat(foundChat);
      }

      handleCloseNotification(notificationId);
      setDialogOpen(true);
    },
    [handleCloseNotification, setDialogOpen]
  );

  const toggleNotificationPause = (notificationId: string) => {
    setNotifications(prev =>
      prev.map(notification =>
        notification.id === notificationId
          ? { ...notification, isPaused: !notification.isPaused }
          : notification
      )
    );
  };

  // ========== Connection + chat joining effect ==========
  const connectedChatsRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    if (connection?.state === HubConnectionState.Connected) {
      try {
        // console.log('[Chat] Attempting join', chats, connection);
        connection
          ?.invoke(
            'JoinChats',
            chats.map(c => c.conversationSeq)
          )
          .then(() => {
            console.log(`[Chat] Subscribed to ${chats.length} chats `);
            chats.forEach(c => connectedChatsRef.current.add(c.conversationSeq));
          });
      } catch (error) {
        console.error(`[Chat] Failed to join chats:`, error);
      }

      // Cleanup
      return () => {
        try {
          if (chats) {
            connection
              ?.invoke(
                'LeaveChats',
                chats.map(c => c.conversationSeq)
              )
              .then(() => {
                // console.log(`[Chat] Left ${chats.length} chats `);
                connectedChatsRef.current.clear();
              });
          }
        } catch (error) {
          console.error(`[Chat] Failed to leave chats:`, error);
        }
      };
    }
  }, [chats.map(c => c.conversationSeq).join(','), connection?.state]);

  // ========== SignalR event listeners ==========

  useEffect(() => {
    if (connection?.state === HubConnectionState.Connected) {
      // "user typing in conversation"
      connection.on('user typing in conversation', ({ conversationSeq, participant, isTyping }) => {
        if (participant.userSeq.toLowerCase() === user?.userSeq.toLowerCase()) return;

        setTypingParticipants(prev => {
          const conversationTypers = prev[conversationSeq] || [];
          if (isTyping) {
            const existingIndex = conversationTypers.findIndex(
              p => p.userSeq === participant.userSeq
            );
            if (existingIndex === -1) {
              return {
                ...prev,
                [conversationSeq]: [...conversationTypers, { ...participant, isTyping }],
              };
            }
          } else {
            return {
              ...prev,
              [conversationSeq]: conversationTypers.filter(p => p.userSeq !== participant.userSeq),
            };
          }
          return prev;
        });

        if (isTyping) {
          const timeoutKey = `${conversationSeq}-${participant.userSeq}`;
          if (typingTimeouts.current[timeoutKey]) {
            clearTimeout(typingTimeouts.current[timeoutKey]);
          }
          typingTimeouts.current[timeoutKey] = setTimeout(() => {
            setTypingParticipants(prev => ({
              ...prev,
              [conversationSeq]: (prev[conversationSeq] || []).filter(
                p => p.userSeq !== participant.userSeq
              ),
            }));
          }, 3000);
        }
      });

      // "new chat event"
      connection.on('new chat event', (chatRecord: ChatRecord) => {
        // If we already have this chat, skip
        const alreadyHasThisChatRecord =
          chatsRef.current.findIndex(
            c => c.conversationSeq.toLowerCase() === chatRecord.conversationSeq.toLowerCase()
          ) > -1;
        if (alreadyHasThisChatRecord) return;

        // Insert into state + ref
        setChats(prev => {
          const newChats = [chatRecord, ...prev];
          chatsRef.current = newChats;
          return newChats;
        });

        // If dialog is open, skip notification
        if (dialogOpen) return;

        // Show notification
        setNotifications(prev => [
          ...prev,
          {
            id: `new-chat-${chatRecord.conversationSeq}`,
            message: `Added to ${chatRecord.topic}`,
            messageRecord: null,
            conversationSeq: chatRecord.conversationSeq,
            createdAt: Date.now(),
            sender: chatRecord.participants[0],
            isPaused: false,
          },
        ]);
      });

      // "new message"
      connection.on(
        'new message',
        ({ conversationSeq, message }: { conversationSeq: string; message: ChatMessage }) => {
          if (message?.sender?.userSeq?.toLowerCase() === user?.userSeq.toLowerCase()) {
            return;
          }

          // 2) Insert/update the chat in the main chats array
          let updatedChatRef: ChatRecord | null = null;

          setChats(prevChats => {
            const newChats = prevChats.map(chat => {
              if (chat.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) {
                // Avoid duplication
                const messageExists = chat.messages.some(
                  m => m.messageSeq?.toLowerCase() === message.messageSeq.toLowerCase()
                );
                if (messageExists) return chat;

                // Add message to this chat
                const updatedChat = {
                  ...chat,
                  messages: [...chat.messages, message],
                  messageCount: chat.messageCount + 1,
                  lastMessageSeq: message.messageSeq,
                };
                updatedChatRef = updatedChat;

                if (
                  updatedChatRef &&
                  selectedChatRef.current?.conversationSeq?.toLowerCase() ===
                    conversationSeq.toLowerCase()
                ) {
                  selectedChatRef.current = updatedChat;
                }

                return updatedChat;
              }
              return chat;
            });
            chatsRef.current = newChats; // Keep your ref in sync
            return newChats;
          });

          // 3) If the user is currently viewing this chat, update `selectedChat` as well
          if (
            updatedChatRef &&
            selectedChatRef.current?.conversationSeq?.toLowerCase() ===
              conversationSeq.toLowerCase()
          ) {
            handleSelectChat(selectedChatRef.current);
          }

          // 4) If you have an open dialog (e.g., a custom Chat dialog) and prefer
          //    to show an in-app notification only when the dialog is *not* open,
          //    do that check here:
          if (!dialogOpen) {
            setNotifications(prev => [
              ...prev,
              {
                id: message.messageSeq,
                message: message?.messageContent || '',
                messageRecord: message,
                conversationSeq,
                createdAt: Date.now(),
                sender: message.sender,
                isPaused: false,
              },
            ]);
          }
        }
      );

      // "new message"
      connection.on(
        'new chat message event',
        ({ conversationSeq, message }: { conversationSeq: string; message: ChatMessage }) => {
          if (message?.sender?.userSeq?.toLowerCase() === user?.userSeq.toLowerCase()) {
            return;
          }

          // 2) Insert/update the chat in the main chats array
          let updatedChatRef: ChatRecord | null = null;
          setChats(prevChats => {
            const newChats = prevChats.map(chat => {
              if (chat.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) {
                const messageExists = chat.messages.some(
                  m => m.messageSeq?.toLowerCase() === message.messageSeq.toLowerCase()
                );

                if (messageExists) return chat;

                const updatedChat = {
                  ...chat,
                  messages: [...chat.messages, message],
                  messageCount: chat.messageCount + 1,
                  lastMessageSeq: message.messageSeq,
                };
                updatedChatRef = updatedChat;
                if (
                  selectedChatRef.current?.conversationSeq?.toLowerCase() ===
                  conversationSeq.toLowerCase()
                ) {
                  selectedChatRef.current = updatedChat;
                  setSelectedChat(updatedChat);
                }

                return updatedChat;
              }
              return chat;
            });
            chatsRef.current = newChats; // Keep your ref in sync
            return newChats;
          });

          // 3) If the user is currently viewing this chat, update `selectedChat` as well
          if (
            updatedChatRef &&
            selectedChatRef.current?.conversationSeq?.toLowerCase() ===
              conversationSeq.toLowerCase()
          ) {
            // console.log('ig we can try and reselect');

            handleSelectChat(selectedChatRef.current);
          }

          if (!dialogOpen) {
            setNotifications(prev => [
              ...prev,
              {
                id: message.messageSeq,
                message: message?.messageContent || '',
                messageRecord: message,
                conversationSeq,
                createdAt: Date.now(),
                sender: message.sender,
                isPaused: false,
              },
            ]);
          }
        }
      );

      // "participant removed event"
      connection.on(
        'participant removed event',
        ({
          conversationSeq = '',
          participantUserSeq = '',
          participants = [],
          participantHistory = [],
        }) => {
          if (participantUserSeq.toLowerCase() === user?.userSeq.toLowerCase()) {
            // remove the entire chat from local state
            setChats(prevChats => {
              const filtered = prevChats.filter(
                chat => chat.conversationSeq.toLowerCase() !== conversationSeq.toLowerCase()
              );
              chatsRef.current = filtered;
              return filtered;
            });

            // if it was selected, unselect
            if (
              selectedChatRef.current?.conversationSeq.toLowerCase() ===
              conversationSeq.toLowerCase()
            ) {
              setSelectedChat(null);
              if (isMobile) {
                setShowConversationList(true);
              }
            }
          } else {
            // Just update participants
            setChats(prev => {
              const newChats = prev.map(chat => {
                if (chat.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) {
                  return {
                    ...chat,
                    participants,
                    participantHistory,
                  };
                }
                return chat;
              });
              chatsRef.current = newChats;
              return newChats;
            });

            // If currently selected, update participants
            if (
              selectedChatRef.current?.conversationSeq.toLowerCase() ===
              conversationSeq.toLowerCase()
            ) {
              setSelectedChat(prevChat => {
                if (!prevChat) return prevChat;
                return {
                  ...prevChat,
                  participants,
                  participantHistory,
                };
              });
            }
          }
        }
      );

      // "other user added to chat event"
      connection.on('other user added to chat event', onOtherUserAddedToChatEvent);
      // "self added to chat event"
      connection.on('self added to chat event', onSelfAddedToChatEvent);

      return () => {
        connection?.off('user typing in conversation');
        connection?.off('new chat event');
        connection?.off('new message');
        connection?.off('new chat message event');
        connection?.off('participant removed event');
        connection?.off('other user added to chat event');
        connection?.off('self added to chat event');

        // Clear all timeouts
        Object.values(typingTimeouts.current).forEach(timeout => clearTimeout(timeout));
        // console.log('Event listeners removed and timeouts cleared.');
      };
    }
  }, [connection?.state, user?.userSeq, dialogOpen, isMobile]);

  const onOtherUserAddedToChatEvent = (event: OnOtherUserAddedToChatEvent) => {
    // Check if we have it:
    const chatExists = chatsRef.current.some(
      c => c.conversationSeq.toLowerCase() === event.conversationSeq.toLowerCase()
    );
    if (!chatExists) {
      // console.log(`Chat not found for other user added: ${event.conversationSeq}`);
      return;
    }

    // Update the existing chat with new participants
    setChats(() => {
      const newChats = chatsRef.current.map(chat => {
        if (chat.conversationSeq.toLowerCase() === event.conversationSeq.toLowerCase()) {
          return {
            ...chat,
            participants: event.participants,
            participantHistory: event.participantHistory,
          };
        }
        return chat;
      });
      chatsRef.current = newChats;
      return newChats;
    });

    // If currently selected
    if (
      selectedChatRef.current?.conversationSeq.toLowerCase() === event.conversationSeq.toLowerCase()
    ) {
      setSelectedChat(prevChat => {
        if (!prevChat) return prevChat;
        return {
          ...prevChat,
          participants: event.participants,
          participantHistory: event.participantHistory,
        };
      });
    }
  };

  const onSelfAddedToChatEvent = (event: OnSelfAddedToChatEvent) => {
    const chatExists = chatsRef.current.some(
      c => c.conversationSeq.toLowerCase() === event.conversationSeq.toLowerCase()
    );
    if (chatExists) {
      // console.log(`Already have chat: ${event.conversationSeq}`);
      return;
    }

    // Insert new chat
    setChats(() => {
      const newChats = [event, ...chatsRef.current];
      chatsRef.current = newChats;
      return newChats;
    });
  };

  // ========== Typing Indicator ==========

  const emitTypingIndicator = async (conversationSeq: string, isTyping: boolean) => {
    if (connection?.state === HubConnectionState.Connected) {
      const participant: ConversationParticipant = {
        // @ts-ignore
        userSeq: user?.userSeq,
        // @ts-ignore
        userName: user?.userName,
        // @ts-ignore
        personFirstName: user?.userFirstName || '',
        // @ts-ignore
        personLastName: user?.userLastName || '',
        joinedAt: new Date().toISOString(),
      };

      try {
        await connection.invoke('UserTypingInConversation', {
          conversationSeq,
          participant,
          isTyping,
        });
        // console.log(`Typing indicator emitted: ${isTyping} for ${conversationSeq}`);
      } catch (error) {
        console.error('Error sending typing indicator:', error);
      }
    }
  };

  // We'll debounce the "isTyping" => false
  const debouncedEmitTyping = useRef<NodeJS.Timeout>();
  const emitTypingIndicatorDebounced = (conversationSeq: string) => {
    if (debouncedEmitTyping.current) {
      clearTimeout(debouncedEmitTyping.current);
    }
    // Immediately say "true"
    emitTypingIndicator(conversationSeq, true);
    debouncedEmitTyping.current = setTimeout(() => {
      emitTypingIndicator(conversationSeq, false);
    }, 1000);
  };

  // ========== Fetch users, chats, etc. ==========

  const fetchAvailableUsers = async () => {
    try {
      const response = await fetch(`${VITE_API_URL}users/available`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch users');
      const data: ConversationUser[] = await response.json();
      const supportAgent: ConversationUser = {
        userName: supportAgentName || 'Support',
        userSeq: 'Support',
        personFirstName: '',
        personLastName: '',
      };
      setAvailableUsers([supportAgent, ...data]);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUserChats = async (options?: { refresh?: boolean }) => {
    try {
      setError(null);
      if (options?.refresh) {
        setRefreshing(true);
      } else {
        setLoading(true);
      }

      const response = await fetch(`${VITE_API_URL}GetChats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch chats');
      const data: ChatRecord[] = await response.json();

      // Use functional set:
      setChats(() => {
        chatsRef.current = data;
        return data;
      });

      // console.log('User chats fetched successfully.');
    } catch (error) {
      setError(error as Error);
      console.error('Error fetching user chats:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchChatsAccesibleBySupport = async (options?: { refresh?: boolean }) => {
    try {
      setError(null);
      if (options?.refresh) {
        setRefreshing(true);
      } else {
        setLoading(true);
      }

      const response = await fetch(`${VITE_API_URL}GetChatsAccesibleBySupport`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch chats accessible by support');
      const data: ChatRecord[] = await response.json();

      setChats(() => {
        chatsRef.current = data;
        return data;
      });

      console.log('Chats accessible by support fetched successfully.');
    } catch (error) {
      setError(error as Error);
      console.error('Error fetching chats accessible by support:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const createChat = async (
    chatRecord: Partial<ChatRecord>,
    metadata?: Partial<CreateChatRequestMetadata>,
    attachments?: File[]
  ) => {
    try {
      const formData = new FormData();
      formData.append('ChatRecordJson', JSON.stringify(chatRecord));
      if (metadata) {
        formData.append('MetadataJson', JSON.stringify(metadata));
      }
      if (attachments && attachments.length > 0) {
        attachments.forEach(file => {
          formData.append('Attachments', file);
        });
      }

      const response = await fetch(`${VITE_API_URL}CreateChat`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: formData,
      });
      if (!response.ok) throw new Error('Failed to create chat');
      const newChat: ChatRecord = await response.json();

      setChats(prev => {
        const newVal = [newChat, ...prev];
        chatsRef.current = newVal;
        return newVal;
      });

      if (connection?.state === HubConnectionState.Connected) {
        try {
          connection?.invoke('JoinChats', [newChat.conversationSeq]).then(() => {
            console.log(`[Chat] Subscribed to 1 new chat `);
            chats.forEach(c => connectedChatsRef.current.add(c.conversationSeq));
          });
        } catch (error) {
          console.error(`[Chat] Failed to join chats:`, error);
        }
      }

      handleSelectChat(newChat);
      // console.log('Chat created successfully:', newChat);
      return newChat;
    } catch (error) {
      console.error('Error creating chat:', error);
      throw error;
    }
  };

  const subscribeToChats = async (chatSeqs: string[]) => {};

  const fetchConversationTypes = async () => {
    try {
      const response = await fetch(`${VITE_API_URL}conversation-types`, {
        headers: { Authorization: `Bearer ${user?.accessToken}` },
      });
      if (!response.ok) throw new Error('Failed to fetch conversation types');
      const data: ConversationType[] = await response.json();
      setTypes(data);
      console.log('Conversation types fetched successfully.');
    } catch (error) {
      console.error('Error fetching conversation types:', error);
    }
  };

  const fetchConversationsSupportAgentName = async () => {
    try {
      const response = await fetch(`${VITE_API_URL}conversation/support-agent-name`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch support agent name');
      const data: string = await response.text();
      setSupportAgentName(data);
      console.log('Support agent name fetched successfully:', data);
    } catch (error) {
      console.error('Error fetching support agent name:', error);
    }
  };

  const fetchUnreadConversatonsCount = async ({ userSeq }: { userSeq?: string }) => {
    try {
      const response = await fetch(`${VITE_API_URL}conversations/unread/count`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) return 0;
      const data = await response.json();
      setUnreadConversationCount(data.unreadConversationsCount);
      // console.log('Unread conversations count fetched:', data.unreadConversationsCount);
      return data.unreadConversationsCount;
    } catch (error) {
      console.error('Error fetching unread conversations count:', error);
      return 0;
    }
  };

  useEffect(() => {
    fetchConversationTypes();
    fetchConversationsSupportAgentName();
  }, []);

  useEffect(() => {
    if (!supportAgentName && availableUsers.length === 0) {
      fetchAvailableUsers();
    }
  }, [supportAgentName]);

  useEffect(() => {
    if (isMobile) setShowConversationList(true);
  }, [isMobile]);

  const createConversation = async ({
    topic = '',
    message = '',
    conversationType = ConversationTypes.Chat,
    participantUserSeqs = [],
    attachments = [],
  }: {
    topic?: string;
    message?: string;
    conversationType?: string;
    participantUserSeqs?: string[];
    attachments?: any[];
  }) => {
    if (!topic.trim()) return Promise.reject('Topic cannot be empty');
    try {
      setCreatingConversation(true);
      const formData = new FormData();
      formData.append('topic', topic);
      formData.append('initialMessage', message);
      formData.append('conversationTypeSeq', conversationType);
      participantUserSeqs.forEach(userseq => {
        formData.append('participantUserSeqs[]', userseq);
      });
      attachments.forEach(file => {
        formData.append('attachments', file);
      });

      const response = await fetch(`${VITE_API_URL}conversations`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: formData,
      });
      if (!response.ok) throw new Error('Failed to create conversation');

      const newConversation: Conversation = await response.json();
      setConversations(prev => [newConversation, ...prev]);

      console.log('Conversation created successfully:', newConversation);
      return newConversation;
    } catch (error) {
      console.error('Error creating conversation:', error);
      throw error;
    } finally {
      setCreatingConversation(false);
    }
  };

  // ========== Mark message read ==========

  const handleMessageRead = async (messageSeq: string, userSeq?: string) => {
    const chatContainingMessage = chatsRef.current.find(chat =>
      chat.messages.some(message => message.messageSeq === messageSeq)
    );
    if (!chatContainingMessage) return;

    const message = chatContainingMessage.messages.find(m => m.messageSeq === messageSeq);
    if (!message) return;

    const targetUserSeq = userSeq || user?.userSeq;
    if (message.readBy?.some(r => r.userSeq?.toLowerCase() === targetUserSeq?.toLowerCase())) {
      return; // already read
    }

    try {
      const response = await fetch(`${VITE_API_URL}Chat/Read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify({
          userSeq: targetUserSeq,
          conversationSeq: chatContainingMessage.conversationSeq,
          messageSeq,
        }),
      });
      if (!response.ok) throw new Error('Failed to mark message as read');
      const updatedReadBy = await response.json();

      const formattedReadBy = updatedReadBy.map((reader: any) => ({
        userSeq: reader.userSeq,
        userName: reader.userName,
        personFirstName: reader.personFirstName,
        personLastName: reader.personLastName,
        readAtTimestamp: reader.readTimestamp,
      }));

      // Update local state
      setChats(prev => {
        const newChats = prev.map(chat => {
          if (chat.conversationSeq !== chatContainingMessage.conversationSeq) {
            return chat;
          }
          const updatedMessages = chat.messages.map(m =>
            m.messageSeq === messageSeq
              ? { ...m, readBy: formattedReadBy, readByCount: formattedReadBy.length }
              : m
          );
          return { ...chat, messages: updatedMessages };
        });
        chatsRef.current = newChats;
        return newChats;
      });

      // If current chat is selected, update it too
      if (selectedChatRef.current?.conversationSeq === chatContainingMessage.conversationSeq) {
        const updatedMessages = selectedChatRef.current.messages.map(m =>
          m.messageSeq === messageSeq
            ? { ...m, readBy: formattedReadBy, readByCount: formattedReadBy.length }
            : m
        );
        setSelectedChat({
          ...selectedChatRef.current,
          messages: updatedMessages,
        });
      }

      // console.log('Message marked as read:', messageSeq);
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  // ========== Sending message (old Conversation-based) ==========

  const handleSendMessage = useCallback(
    async ({
      attachments,
      conversationSeq,
      message,
      shouldNotifySupport = false,
    }: SendMessageRequest) => {
      const optimisticMessage: ConversationMessage = {
        messageSeq: `temp-${Date.now()}`,
        content: message.trim(),
        senderUserSeq: user?.userSeq || '',
        senderName: user?.userName || '',
        sentAt: new Date().toISOString(),
        readBy: [
          {
            userSeq: user?.userSeq || '',
            userName: user?.userName || '',
            personFirstName: user?.userFirstName || '',
            personLastName: user?.userLastName || '',
            readTimestamp: new Date().toISOString(),
          },
        ],
        readByCount: 1,
        attachments: [],
      };

      // Optimistic UI update in your "conversations" state
      setConversations(prevConversations =>
        prevConversations.map(conv => {
          if (conv.conversationSeq === conversationSeq) {
            return {
              ...conv,
              messages: [...conv.messages, optimisticMessage],
            };
          }
          return conv;
        })
      );

      try {
        const formData = new FormData();
        formData.append('content', message.trim());
        attachments.forEach(file => {
          formData.append('attachments', file);
        });
        formData.append('shouldNotifySupport', String(shouldNotifySupport));

        const response = await fetch(`${VITE_API_URL}conversations/${conversationSeq}/messages`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
          body: formData,
        });
        if (!response.ok) throw new Error('Failed to send message');
        const actualMessage: ConversationMessage = await response.json();

        // Replace optimistic message with actual
        setConversations(prevConversations =>
          prevConversations.map(conv => {
            if (conv.conversationSeq === conversationSeq) {
              return {
                ...conv,
                messages: conv.messages.map(msg =>
                  msg.messageSeq === optimisticMessage.messageSeq ? actualMessage : msg
                ),
              };
            }
            return conv;
          })
        );

        if (connection?.state === HubConnectionState.Connected) {
          connection.invoke('BroadcastNewMessage', conversationSeq, actualMessage);
        }
      } catch (error) {
        console.error('Error sending message:', error);
        // Remove the optimistic message
        setConversations(prevConversations =>
          prevConversations.map(conv => {
            if (conv.conversationSeq === conversationSeq) {
              return {
                ...conv,
                messages: conv.messages.filter(
                  msg => msg.messageSeq !== optimisticMessage.messageSeq
                ),
              };
            }
            return conv;
          })
        );
        throw error;
      }
    },
    [connection, user]
  );

  // ========== Sending message (new Chat-based) ==========

  const sendMessage = async (request: SendChatMessageRequest) => {
    const optimisticMessage: ChatMessage = {
      messageSeq: `temp-${Date.now()}`,
      sentAtTimestamp: new Date().toISOString(),
      messageContent: request.chatMessage.messageContent,
      sender: {
        userSeq: user?.userSeq || '',
        userName: user?.userName || '',
        personFirstName: user?.userFirstName || '',
        personLastName: user?.userLastName || '',
      },
      readBy: [
        {
          userSeq: user?.userSeq || '',
          userName: user?.userName || '',
          personFirstName: user?.userFirstName || '',
          personLastName: user?.userLastName || '',
          readAtTimestamp: new Date().toISOString(),
        },
      ],
      readByCount: 1,
      attachments: [],
    };

    // ----- 1) OPTIMISTIC UPDATE: CHATS -----
    setChats(prev => {
      const newChats = prev.map(chat => {
        if (chat.conversationSeq.toLowerCase() === request.conversationSeq.toLowerCase()) {
          return {
            ...chat,
            messages: [...chat.messages, optimisticMessage],
            messageCount: chat.messageCount + 1,
            lastMessageSeq: optimisticMessage.messageSeq,
          };
        }
        return chat;
      });
      chatsRef.current = newChats;
      return newChats;
    });

    // ----- 2) OPTIMISTIC UPDATE: SELECTED CHAT (if selected) -----
    if (
      selectedChatRef.current?.conversationSeq.toLowerCase() ===
      request.conversationSeq.toLowerCase()
    ) {
      setSelectedChat(prev => {
        if (!prev) return null; // just a safety check
        return {
          ...prev,
          messages: [...prev.messages, optimisticMessage],
          messageCount: prev.messageCount + 1,
          lastMessageSeq: optimisticMessage.messageSeq,
        };
      });
    }

    // ----- 3) ATTEMPT SENDING TO SERVER -----
    try {
      const response = await fetch(`${VITE_API_URL}SendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify(request),
      });
      if (!response.ok) throw new Error('Failed to send message');
      const actualMessage: ChatMessage = await response.json();

      // ----- 4) SWAP OPTIMISTIC MESSAGE WITH ACTUAL (in chats) -----
      setChats(prev => {
        const newChats = prev.map(chat => {
          if (chat.conversationSeq.toLowerCase() === request.conversationSeq.toLowerCase()) {
            return {
              ...chat,
              messages: [
                // remove the optimistic "temp" message
                ...chat.messages.filter(m => m.messageSeq !== optimisticMessage.messageSeq),
                actualMessage,
              ],
              // the server might have updated the message count or last message sequence
              messageCount: chat.messageCount,
              lastMessageSeq: actualMessage.messageSeq,
            };
          }
          return chat;
        });
        chatsRef.current = newChats;
        return newChats;
      });

      // ----- 5) SWAP OPTIMISTIC MESSAGE WITH ACTUAL (in selectedChat) -----
      if (
        selectedChatRef.current?.conversationSeq.toLowerCase() ===
        request.conversationSeq.toLowerCase()
      ) {
        setSelectedChat(prev => {
          if (!prev) return null;
          return {
            ...prev,
            messages: [
              ...prev.messages.filter(m => m.messageSeq !== optimisticMessage.messageSeq),
              actualMessage,
            ],
            messageCount: prev.messageCount, // or from server if it changes
            lastMessageSeq: actualMessage.messageSeq,
          };
        });
      }

      // ----- 6) IF CONNECTED, BROADCAST -----
      if (connection?.state === HubConnectionState.Connected) {
        connection.invoke('BroadcastNewMessage', request.conversationSeq, actualMessage);
      }
    } catch (error) {
      // ----- 7) ROLL BACK CHATS ON FAILURE -----
      setChats(prev => {
        const newChats = prev.map(chat => {
          if (chat.conversationSeq.toLowerCase() === request.conversationSeq.toLowerCase()) {
            return {
              ...chat,
              messages: chat.messages.filter(
                msg => msg.messageSeq !== optimisticMessage.messageSeq
              ),
              messageCount: chat.messageCount - 1,
              // revert lastMessageSeq if needed (i.e., the second-last message)
              lastMessageSeq: chat.messages[chat.messages.length - 2]?.messageSeq || '',
            };
          }
          return chat;
        });
        chatsRef.current = newChats;
        return newChats;
      });

      // ----- 8) ROLL BACK SELECTED CHAT ON FAILURE -----
      if (
        selectedChatRef.current?.conversationSeq.toLowerCase() ===
        request.conversationSeq.toLowerCase()
      ) {
        setSelectedChat(prev => {
          if (!prev) return null;
          const filteredMessages = prev.messages.filter(
            msg => msg.messageSeq !== optimisticMessage.messageSeq
          );
          return {
            ...prev,
            messages: filteredMessages,
            messageCount: prev.messageCount - 1,
            lastMessageSeq: filteredMessages[filteredMessages.length - 1]?.messageSeq || '',
          };
        });
      }
      console.error('Error sending chat message:', error);
      throw error;
    }
  };

  // ========== Select a chat ==========

  const handleSelectChat = (chat: ChatRecord) => {
    setSelectedChat(chat);
    selectedChatRef.current = chat;
    if (isMobile) {
      setShowConversationList(false);
    }
    // console.log('Chat selected:', chat);
  };

  const updateConversationOpenStatus = async ({
    conversationSeq = '',
    isOpen = false,
  }: {
    conversationSeq: string;
    isOpen: boolean;
  }) => {
    try {
      const response = await fetch(`${VITE_API_URL}conversations/${conversationSeq}/status`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isOpen }),
      });
      if (!response.ok) throw new Error('Failed to update conversation status');
      const updatedConversation: Conversation = await response.json();
      setConversations(prev =>
        prev.map(conv => (conv.conversationSeq === conversationSeq ? updatedConversation : conv))
      );
      // console.log('Conversation status updated:', updatedConversation);
    } catch (error) {
      console.error('Error updating conversation status:', error);
    }
  };

  // ========== Add/remove participants ==========

  const addParticipantsToChat = async ({
    conversationSeq,
    participants,
  }: {
    conversationSeq: string;
    participants: {
      userSeq: string;
      canViewMessageHistoryUpToTimestamp?: string;
      silent?: boolean;
    }[];
  }): Promise<void> => {
    try {
      if (!conversationSeq) throw new Error('Invalid conversation');
      if (participants.length === 0) throw new Error('No participants selected');

      const participantAdditions = await Promise.all(
        participants.map(participant =>
          fetch(`${VITE_API_URL}conversations/participants/add`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
              conversationSeq,
              participantUserSeq: participant.userSeq,
              canViewMessageHistoryUpToTimestamp: participant.canViewMessageHistoryUpToTimestamp,
              silent: participant.silent ?? false,
            }),
          }).then(async response => {
            if (!response.ok) {
              throw new Error(`Failed to add participant: ${participant.userSeq}`);
            }
            return response.json();
          })
        )
      );

      // Deduplicate
      const uniqueHistoryMap = new Map();
      participantAdditions.flat().forEach(history => {
        const key = `${history.userSeq}-${history.timestamp}`;
        uniqueHistoryMap.set(key, history);
      });
      const allParticipantHistory = Array.from(uniqueHistoryMap.values());

      setChats(prev => {
        const newChats = prev.map(chat => {
          if (chat.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) {
            return {
              ...chat,
              participantHistory: allParticipantHistory,
            };
          }
          return chat;
        });
        chatsRef.current = newChats;
        return newChats;
      });

      if (
        selectedChatRef.current?.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()
      ) {
        setSelectedChat({
          ...selectedChatRef.current,
          participantHistory: allParticipantHistory,
        });
      }

      // console.log('All participants added successfully:', conversationSeq);
    } catch (error) {
      console.error('Error adding participants:', error);
      throw error;
    }
  };

  const removeParticipantFromChat = async ({
    conversationSeq,
    participantUserSeq,
    silent = false,
  }: {
    conversationSeq: string;
    participantUserSeq: string;
    silent?: boolean;
  }): Promise<void> => {
    try {
      if (!conversationSeq) throw new Error('Invalid conversation');
      if (!participantUserSeq) throw new Error('No participant specified');

      const response = await fetch(`${VITE_API_URL}conversations/participants/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify({
          conversationSeq,
          participantUserSeq,
          silent,
        }),
      });
      if (!response.ok) throw new Error('Failed to remove participant');
      const participantHistory: ChatParticipantHistory[] = await response.json();

      setChats(prev => {
        const newChats = prev.map(chat => {
          if (chat.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) {
            return {
              ...chat,
              participantHistory,
            };
          }
          return chat;
        });
        chatsRef.current = newChats;
        return newChats;
      });

      if (
        selectedChatRef.current?.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()
      ) {
        setSelectedChat(() => {
          if (!selectedChatRef.current) return null;
          return {
            ...selectedChatRef.current,
            participantHistory,
          };
        });
      }

      // console.log('Participant removed successfully:', conversationSeq);
    } catch (error) {
      console.error('Error removing participant:', error);
      throw error;
    }
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  // ========== Track online participants ==========

  useEffect(() => {
    if (connection?.state === HubConnectionState.Connected) {
      connection.invoke('RequestOnlineUsers');
      connection.on('online_users_list', (onlineUsers: string[]) => {
        setOnlineParticipants(onlineUsers);
      });
      connection.on(
        'user_connection_changed',
        ({ userSeq, isOnline }: { userSeq: string; isOnline: boolean }) => {
          setOnlineParticipants(prev => {
            if (isOnline) {
              return prev.includes(userSeq) ? prev : [...prev, userSeq];
            } else {
              return prev.filter(id => id !== userSeq);
            }
          });
        }
      );
      return () => {
        connection.off('online_users_list');
        connection.off('user_connection_changed');
      };
    }
  }, [connection?.state]);

  const isUserOnline = useCallback(
    (userSeq: string) => {
      return onlineParticipants.includes(userSeq);
    },
    [onlineParticipants]
  );

  // ========== Initial load ==========

  useEffect(() => {
    if (user?.roleCheck(['451', '8f7'])) {
      fetchChatsAccesibleBySupport({ refresh: false });
      setDialogOpen(false);
    } else {
      fetchUserChats({ refresh: false });
      setDialogOpen(false);
    }
  }, [user]);

  // ========== Final provider ==========

  return (
    <ConversationContext.Provider
      value={{
        // new methods
        chats,
        fetchUserChats,
        fetchChatsAccesibleBySupport,
        createChat,
        sendMessage,
        dialogOpen,
        setDialogOpen,
        handleCloseNotification,
        handleNotificationClick,
        notifications,
        toggleNotificationPause,
        unreadMessagesCount,
        messageNotificationDismissSeconds: Number(messageNotificationDismissSeconds),
        availableUsers,
        addParticipantsToChat,
        removeParticipantFromChat,
        onlineParticipants,
        isUserOnline,
        // end new methods
        conversations,
        setConversations,
        unreadConversationCount,
        fetchUnreadConversatonsCount,
        loading,
        refreshing,
        error,
        updateConversationOpenStatus,
        handleMessageRead,
        handleSendMessage,
        supportAgentName,
        selectedChat,
        setSelectedChat,
        showConversationList,
        setShowConversationList,
        handleSelectChat,
        createConversation,
        creatingConversation,
        emitTypingIndicator: emitTypingIndicatorDebounced,
        typingParticipants,
        conversationTypes,
        clearNotifications,
        unsubscribeFromAllChats,
      }}
    >
      <ChatNotifications />
      {children}
    </ConversationContext.Provider>
  );
};
