import { Box, Checkbox, Collapse, FormControlLabel, Paper, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import AddressData from '../../components/AdministrationModules/AddressComponents/AddressData';
import { useEffect, useState } from 'react';
import { DecedentAddressTypeOptions } from '../../utils/constants/constants';

export function CaseViewSameAsAddressField() {
  const formik = useFormikContext<any>();
  const [showCheckbox, setShowCheckbox] = useState(true);

  const [isChecked, setIsChecked] = useState(Boolean(formik.values?.caseDemographics?.useBodyLocationAddressAsDecedentAddress));


  function onCheck(event) {
    const isChecked = event.target.checked;
    
    setIsChecked(isChecked);
    formik.setFieldValue('caseDemographics.useBodyLocationAddressAsDecedentAddress', isChecked);

    if (!isChecked) {     
      const decedentAddress = formik.values.caseDemographics?.decedentAddress?.[0] || [];

      const updatedDecedentAddress = {
        ...decedentAddress,
        addressTypeSeq: 'F701E8A5-0BEB-44C5-8167-DB4C9185CFAC',
        addressLine1: '',
        city: '',
        placeSeq: null,
        countySeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
        floor: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
      };

      formik.setFieldValue('caseDemographics.decedentAddress', [updatedDecedentAddress]);
    }
  }



  useEffect(() => {
    const { caseInvestigations, caseDemographics } = formik.values;

    const bodyLocation = caseInvestigations?.bodyLocation?.[0] || [];
    const decedentAddress = caseDemographics?.decedentAddress?.[0] || [];  

    const bodyLocationAddressType = bodyLocation?.addressTypeSeq;
    const shouldShowCheckbox = Object.values(DecedentAddressTypeOptions).includes(bodyLocationAddressType?.toUpperCase());
    
    setShowCheckbox(shouldShowCheckbox);

    if (caseDemographics?.useBodyLocationAddressAsDecedentAddress && shouldShowCheckbox) {
      
      formik.setFieldValue('caseDemographics.decedentAddress', [
        {
          ...bodyLocation,
          addressSeq: decedentAddress?.addressSeq,
          contactInfoSeq: decedentAddress?.contactInfoSeq,
        }
      ]);
    }

    if (!shouldShowCheckbox && caseDemographics?.useBodyLocationAddressAsDecedentAddress) {
      formik.setFieldValue('caseDemographics.useBodyLocationAddressAsDecedentAddress', false);
      setIsChecked(false);

      //uncomment below commented code to reset the address, for now just maintain what it has.
      // const updatedDecedentAddress = {
      //   ...decedentAddress,
      //   addressTypeSeq: 'F701E8A5-0BEB-44C5-8167-DB4C9185CFAC',
      //   addressLine1: '',
      //   city: '',
      //   placeSeq: null,
      //   countySeq: null,
      //   nursingHomeSeq: null,
      //   funeralHomeSeq: null,
      //   lawEnforcementSeq: null,
      //   floor: '',
      //   zip: '',
      //   addressLine2: '',
      //   apartmentNo: '',
      //   crossStreet: '',
      //   otherAddressType: '',
      // };

      // formik.setFieldValue('caseDemographics.decedentAddress', [updatedDecedentAddress]);
    }
  }, [formik.values.caseInvestigations?.bodyLocation, formik.values.caseDemographics?.useBodyLocationAddressAsDecedentAddress]);

  return formik.status.editing ? (
    <Stack
      width='100'
      component={Paper}
      square={false}
      elevation={0}
      variant={isChecked ? 'outlined' : 'elevation'}
      gap={2}
      mb={showCheckbox ? 1 : 0}
    >
      {showCheckbox && (
      <Stack direction='row' alignItems='center' gap={1} width='100%' px={2} color='text.secondary'>
        <FormControlLabel
          checked={isChecked}
          control={<Checkbox />}
          onChange={onCheck}
          label='Use address from Location of Body'
          sx={{ flexShrink: 0 }}
        />
      </Stack>
      )}

      <Box component={Collapse} in={isChecked} unmountOnExit px={2} pb={2} width='100%'>
        <AddressData
          handleChange={data =>
            formik?.handleChange({
              target: {
                value: data,
                name: 'caseInvestigations.bodyLocation',
              },
            })
          }
          addresses={formik.values?.caseInvestigations?.bodyLocation || []}
          title=''
          showAllTypes={true}
          disabled={true}
        />
      </Box>
    </Stack>
  ) : null;
}
