import { User } from '../../../types/User';

export type UserAction =
  | { type: 'SET_USER'; payload: User | null }
  | { type: 'SET_AUTH_TOKEN'; payload: string }
  | { type: 'SET_ACCESS_TOKEN'; payload: string }
  | { type: 'SET_REFRESH_TOKEN'; payload: string }
  | { type: 'CLEAR_USER' }
  | { type: 'SET_PHONE_OVERRIDE'; payload: string }
  | { type: 'SET_DARK_MODE'; payload: boolean };

export interface UserState {
  user: User | null;
}

export const initialUserState: UserState = {
  user: null,
};

export function userReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case 'SET_USER':
      let init_user = new User(action.payload);
      return { ...state, user: init_user };
    case 'SET_AUTH_TOKEN':
      if (state.user) {
        state.user.authToken = action.payload;
      }
      return { user: state.user };
    case 'SET_ACCESS_TOKEN':
      if (state.user) {
        state.user.accessToken = action.payload;
      }
      return { user: state.user };
    case 'SET_REFRESH_TOKEN':
      if (state.user) {
        state.user.refreshToken = action.payload;
      }
      return { user: state.user };
    case 'CLEAR_USER':
      localStorage.removeItem('user');
      localStorage.removeItem('auth_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('accessPermissions');
      localStorage.removeItem('logoutAt');
      return { ...state, user: null }; // Clears the user data
    case 'SET_PHONE_OVERRIDE':
      let old_user = state.user as User;
      let user = new User(old_user, { primaryMobileNo: action.payload });
      localStorage.setItem('user', JSON.stringify(user));
      return { user };
    case 'SET_DARK_MODE':
      let old_theme_user = state.user as User;
      let new_theme_user = new User(old_theme_user, { isDarkMode: action.payload });
      localStorage.setItem('user', JSON.stringify(new_theme_user));
      return { user: new_theme_user };
    default:
      return state;
  }
}
