import { Grid, Stack, Typography } from '@mui/material';
// import 'country-flag-icons/3x2/flags.css';
// import * as CountryFlags from 'country-flag-icons/react/3x2';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useUnsavedChanges } from '../Context/UnsavedChangesContext';
import { useOptionsAPI } from '../utils/api/useOptions.hook';
import { useAuth } from '../utils/auth/AuthService';
import { CaseViewOptionsAutocomplete } from '../utils/components/CaseViewOptionsAutocomplete';
import { ContactsViewNew } from '../utils/components/ContactsViewNew';
import { FormikTextField } from '../utils/components/FormikTextField';

interface PersonCardProps {
  formikFields: {
    baseAddressField?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    suffix?: string;
    contactItem?: string;
    medicalLicenceNumber?: string;
    facilityName?: string;
    address?: FormikAddressFields;
  };
  label?: string | React.ReactNode;
  facilityLabel?: string | React.ReactNode;
}

interface FormikAddressFields {
  country: string;
  addressLine1: string;
  addressLine2: string;
  suite: string;
  city: string;
  stateSeq: string;
  zip: string;
  floor: string;
  jurisdiction: string;
}

export function PersonCard({
  label = '',
  facilityLabel = '',
  formikFields = {
    contactItem: '',
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '',
    medicalLicenceNumber: '',
    facilityName: '',
    baseAddressField: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      floor: '',
      jurisdiction: '',
      stateSeq: '',
      suite: '',
      zip: '',
    },
  },
}: PersonCardProps) {
  const formik = useFormikContext<any>();
  const { user } = useAuth();
  const authorizedToEdit =
    user?.roleCheck([
      '2D5', // Investigator
      '451', // System Admin
      'MES', // Pathologist
      'c97', // Administrative Assistant,
      '08b', // HISTORIC-DATAENTRYONLY
    ]) || false;
  const { setUnsavedChanges } = useUnsavedChanges();

  const displayConfig = {
    contactItem: Boolean(formikFields.contactItem),
    firstName: Boolean(formikFields.firstName),
    middleName: Boolean(formikFields.middleName),
    lastName: Boolean(formikFields.lastName),
    suffix: Boolean(formikFields.suffix),
    medicalLicenceNumber: Boolean(formikFields.medicalLicenceNumber),
    facilityName: Boolean(formikFields.facilityName),
    address: Boolean(formikFields.address),
  };

  const { name, value: addressValue = null } = formik.getFieldProps(formikFields.baseAddressField);

  useEffect(() => {
    if (!addressValue) {
      formik.setFieldValue(formikFields.baseAddressField, { ...BlankAddress });
    }
  }, [addressValue]);

  if (!addressValue) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ mb: 0.5 }}>
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>{label}</strong>
        </Typography>
        {/* <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} /> */}
      </Grid>

      {displayConfig.lastName && (
        <Grid item xs={12} md={4}>
          <Stack direction='row'>
            <FormikTextField
              label='Last name'
              formikField={formikFields.lastName}
              authorizedToEdit={authorizedToEdit}
              variant='standard'
              InputLabelProps={{
                sx: {
                  color: 'text.secondary',
                },
              }}
              sx={{
                '& .MuiInputLabel-shrink': {
                  pt: 0.8,
                  color: 'text.secondary',
                },
              }}
              fullWidth
            />
          </Stack>
        </Grid>
      )}
      {displayConfig.firstName && (
        <Grid item xs={12} md={4}>
          <FormikTextField
            label='First name'
            formikField={formikFields.firstName}
            authorizedToEdit={authorizedToEdit}
            variant='standard'
            InputLabelProps={{
              sx: {
                color: 'text.secondary',
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            }}
            fullWidth
          />
        </Grid>
      )}
      {displayConfig.suffix && (
        <Grid item xs={12} md={1}>
          <FormikTextField
            label='suff.'
            formikField={formikFields.suffix}
            authorizedToEdit={authorizedToEdit}
            fullWidth
            variant='standard'
            InputLabelProps={{
              sx: {
                color: 'text.secondary',
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            }}
          />
        </Grid>
      )}
      {displayConfig.middleName && (
        <Grid item xs={12} md={3}>
          <FormikTextField
            label='Middle name'
            formikField={formikFields.middleName}
            authorizedToEdit={authorizedToEdit}
            fullWidth
            variant='standard'
            InputLabelProps={{
              sx: {
                color: 'text.secondary',
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            }}
          />
        </Grid>
      )}

      {displayConfig.contactItem && (
        <Grid item xs={12}>
          <ContactsViewNew
            contacts={formik.values?.caseInvestigations?.physician?.contactItem || []}
            handleChange={(data: any) => {
              formik?.handleChange({
                target: {
                  value: data,
                  name: formikFields.contactItem,
                },
              });
            }}
            onAddNewDefaultContact={newContact => {
              formik.setFieldValue('caseInvestigations.physician.contactItem', [newContact]);
            }}
            disabled={!formik.status.editing || !authorizedToEdit}
          />
        </Grid>
      )}

      {displayConfig.facilityName && (
        <Grid item xs={12} md={displayConfig.medicalLicenceNumber ? 9 : 12}>
          <FormikTextField
            label={facilityLabel}
            formikField={formikFields.facilityName}
            authorizedToEdit={authorizedToEdit}
            variant='standard'
            InputLabelProps={{
              sx: {
                color: 'text.secondary',
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            }}
          />
        </Grid>
      )}

      {displayConfig.medicalLicenceNumber && (
        <Grid item xs={12} md={3}>
          <FormikTextField
            label='MD License No.'
            formikField={formikFields.medicalLicenceNumber}
            authorizedToEdit={authorizedToEdit}
            fullWidth
            variant='standard'
            InputLabelProps={{
              sx: {
                color: 'text.secondary',
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            }}
          />
        </Grid>
      )}

      {displayConfig.address && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Address {...formikFields.address} baseField={formikFields.baseAddressField} />
        </Grid>
      )}
    </Grid>
  );
}

function Address(fields: FormikAddressFields & { baseField: string }) {
  const formik = useFormikContext<any>();

  const { options: countryCodeOptions } = useOptionsAPI('getcountrycodeoptions');
  const { value: countrySeq } = formik.getFieldProps(fields.country);

  const { user } = useAuth();
  const authorizedToEdit =
    user?.roleCheck([
      '2D5', // Investigator
      '451', // System Admin
      'MES', // Pathologist
      'c97', // Administrative Assistant,
      '08b', // HISTORIC-DATAENTRYONLY
    ]) || false;

  // const currentCountyCodeOption = useMemo(
  //   () =>
  //     countryCodeOptions.find(c => c?.optionSeq?.toLowerCase() === countrySeq?.toLowerCase()) ??
  //     null,
  //   [countrySeq, countryCodeOptions]
  // );

  // const convertTo2LetterCode = code3 => {
  //   return countryCodeMapping[code3] || null;
  // };
  // const countryCode = convertTo2LetterCode(currentCountyCodeOption?.optionName);
  // const FlagComponent = countryCode ? CountryFlags[countryCode] : null;

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} sx={{ mb: -0.5 }}>
        <Typography variant='subtitle2' color='gray' textTransform='uppercase'>
          <strong>Address</strong>
        </Typography>
      </Grid>
      {/* 
      <Grid
        layout
        transition={{
          layout: springConfig,
          exit: exitConfig,
        }}
        item
        xs={0.5}
        style={{ display: 'flex', alignItems: 'flex-end' }}
        sx={{ mr: 1, pb: 0.7 }}
        spacing={3}
      >
        {FlagComponent && (
          <FlagComponent
            title={countryCode}
            style={{
              width: '24px',
              height: '18px',
              display: 'block',
            }}
          />
        )}
      </Grid> */}

      <Grid item xs={11} md={12}>
        <CaseViewOptionsAutocomplete
          label='Country'
          optionsEndpoint='getcountryoptions'
          formikField={fields.country}
          getOptionLabel={o => o?.optionName}
          authorizedToEdit={authorizedToEdit}
          textFieldProps={{
            variant: 'standard',
            InputLabelProps: {
              sx: {
                color: 'text.secondary',
              },
            },
            sx: {
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            },
          }}
          onlyUseOptionSeq
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormikTextField
          label='Address Line 1'
          required
          formikField={fields.addressLine1}
          authorizedToEdit={authorizedToEdit}
          variant='standard'
          InputLabelProps={{
            sx: {
              color: 'text.secondary',
            },
          }}
          sx={{
            '& .MuiInputLabel-shrink': {
              pt: 0.8,
              color: 'text.secondary',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          label='Address Line 2'
          formikField={fields.addressLine2}
          authorizedToEdit={authorizedToEdit}
          variant='standard'
          InputLabelProps={{
            sx: {
              color: 'text.secondary',
            },
          }}
          sx={{
            '& .MuiInputLabel-shrink': {
              pt: 0.8,
              color: 'text.secondary',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          label='Apt/Suite'
          formikField={fields.suite}
          authorizedToEdit={authorizedToEdit}
          variant='standard'
          InputLabelProps={{
            sx: {
              color: 'text.secondary',
            },
          }}
          sx={{
            '& .MuiInputLabel-shrink': {
              pt: 0.8,
              color: 'text.secondary',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          label='Floor No.'
          formikField={fields.floor}
          authorizedToEdit={authorizedToEdit}
          variant='standard'
          InputLabelProps={{
            sx: {
              color: 'text.secondary',
            },
          }}
          sx={{
            '& .MuiInputLabel-shrink': {
              pt: 0.8,
              color: 'text.secondary',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormikTextField
          label='City'
          formikField={fields.city}
          authorizedToEdit={authorizedToEdit}
          variant='standard'
          InputLabelProps={{
            sx: {
              color: 'text.secondary',
            },
          }}
          sx={{
            '& .MuiInputLabel-shrink': {
              pt: 0.8,
              color: 'text.secondary',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={1.5}>
        <CaseViewOptionsAutocomplete
          label='State'
          optionsEndpoint='getstatecodeoptions'
          formikField={fields.stateSeq}
          authorizedToEdit={authorizedToEdit}
          onlyUseOptionSeq={true}
          textFieldProps={{
            variant: 'standard',
            InputLabelProps: {
              sx: {
                color: 'text.secondary',
              },
            },
            sx: {
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormikTextField
          label='Zip code'
          formikField={fields.zip}
          authorizedToEdit={authorizedToEdit}
          variant='standard'
          InputLabelProps={{
            sx: {
              color: 'text.secondary',
            },
          }}
          sx={{
            '& .MuiInputLabel-shrink': {
              pt: 0.8,
              color: 'text.secondary',
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={4.5}>
        <CaseViewOptionsAutocomplete
          label='Jurisdiction'
          optionsEndpoint='getjurisdictionoptions?jdxSeq=undefined'
          formikField={fields.jurisdiction}
          authorizedToEdit={authorizedToEdit}
          onlyUseOptionSeq={true}
          textFieldProps={{
            variant: 'standard',
            InputLabelProps: {
              sx: {
                color: 'text.secondary',
              },
            },
            sx: {
              '& .MuiInputLabel-shrink': {
                pt: 0.8,
                color: 'text.secondary',
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export const PersonAddress = Address;

export interface AddressType {
  addressTypeSeq: string;
  addressTypeName: string;
  addressTypeOrder: string;
  status: string;
}

export interface County {
  countySeq: string;
  countyName: string;
  countyCode: string;
  order: string;
  children?: any;
}

export interface Country {
  countrySeq: string;
  countryName: string;
  countryCode: string;
  status: string;
  countryOrder: string;
}

export interface State {
  stateSeq: string;
  stateName: string;
  stateCode: string;
  status: string;
  stateOrder: string;
}

const BlankAddress = {
  addressSeq: '00000000-0000-0000-0000-000000000000',
  addressTypeSeq: '00000000-0000-0000-0000-000000000000',
  addressLine1: '',
  city: '',
  placeSeq: null,
  stateSeq: null,
  countrySeq: null,
  countySeq: null,
  nursingHomeSeq: null,
  funeralHomeSeq: null,
  lawEnforcementSeq: null,
  isWithinServiceArea: true,
  creationDateTime: '2024-08-04T17:24:53.814Z',
  status: 'Active',
  geoX: null,
  geoY: null,
  isCopyAddress: false,
  floor: '',
  isPrimary: false,
  otherState: '',
  zip: '',
  addressLine2: '',
  apartmentNo: '',
  crossStreet: '',
  otherAddressType: '',
  contactInfoSeq: '00000000-0000-0000-0000-000000000000',
  isActive: true,
};
const countryCodeMapping = {
  CHE: 'CH',
  TZA: 'TZ',
  ARM: 'AM',
  ISL: 'IS',
  ITA: 'IT',
  ERI: 'ER',
  LKA: 'LK',
  HND: 'HN',
  MCO: 'MC',
  BGR: 'BG',
  RWA: 'RW',
  TMP: 'TL',
  MNP: 'MP',
  PAK: 'PK',
  BDI: 'BI',
  PRY: 'PY',
  ANT: 'AN',
  GIB: 'GI',
  GUM: 'GU',
  COK: 'CK',
  AGO: 'AO',
  GRL: 'GL',
  DNK: 'DK',
  ESP: 'ES',
  CPV: 'CV',
  TWN: 'TW',
  ZMB: 'ZM',
  BWA: 'BW',
  MMR: 'MM',
  JOR: 'JO',
  AND: 'AD',
  MYT: 'YT',
  MNG: 'MN',
  SMR: 'SM',
  REU: 'RE',
  TKL: 'TK',
  HMD: 'HM',
  PCN: 'PN',
  VGB: 'VG',
  TCA: 'TC',
  SPM: 'PM',
  FJI: 'FJ',
  NCL: 'NC',
  TUR: 'TR',
  MOZ: 'MZ',
  VNM: 'VN',
  FAC: 'FI',
  NOR: 'NO',
  FRO: 'FO',
  AZE: 'AZ',
  MHL: 'MH',
  ABW: 'AW',
  KGZ: 'KG',
  AIA: 'AI',
  DOM: 'DO',
  CIV: 'CI',
  RUS: 'RU',
  GIN: 'GN',
  IND: 'IN',
  SYC: 'SC',
  EST: 'EE',
  SJM: 'SJ',
  UKR: 'UA',
  COG: 'CG',
  URY: 'UY',
  IRL: 'IE',
  KEN: 'KE',
  LBR: 'LR',
  GLP: 'GP',
  UZB: 'UZ',
  COL: 'CO',
  VUT: 'VU',
  AFG: 'AF',
  AUS: 'AU',
  IRQ: 'IQ',
  CAN: 'CA',
  EGY: 'EG',
  GMB: 'GM',
  CZE: 'CZ',
  GUF: 'GF',
  SWZ: 'SZ',
  ZAF: 'ZA',
  GRC: 'GR',
  SLE: 'SL',
  BVT: 'BV',
  MSR: 'MS',
  NIU: 'NU',
  ATF: 'TF',
  DMA: 'DM',
  BHS: 'BS',
  TKM: 'TM',
  KIR: 'KI',
  MEX: 'MX',
  BLR: 'BY',
  ATA: 'AQ',
  MDA: 'MD',
  MLI: 'ML',
  NAM: 'NA',
  SYR: 'SY',
  ETH: 'ET',
  GEO: 'GE',
  TUV: 'TV',
  THA: 'TH',
  MRT: 'MR',
  ECU: 'EC',
  MUS: 'MU',
  LBY: 'LY',
  KHM: 'KH',
  SUR: 'SR',
  CXR: 'CX',
  BTN: 'BT',
  VEN: 'VE',
  UNK: 'UN',
  ROM: 'RO',
  PNG: 'PG',
  DZA: 'DZ',
  SOM: 'SO',
  FLK: 'FK',
  SLV: 'SV',
  PRK: 'KP',
  USA: 'US',
  KNA: 'KN',
  GAB: 'GA',
  NGA: 'NG',
  WSM: 'WS',
  MDV: 'MV',
  MTQ: 'MQ',
  HUN: 'HU',
  VCT: 'VC',
  MAC: 'MO',
  NRU: 'NR',
  BOL: 'BO',
  HTI: 'HT',
  SEN: 'SN',
  COM: 'KM',
  GNB: 'GW',
  BEL: 'BE',
  NLD: 'NL',
  MYS: 'MY',
  SAU: 'SA',
  MAR: 'MA',
  UGA: 'UG',
  FSM: 'FM',
  BHR: 'BH',
  ATG: 'AG',
  KOR: 'KR',
  HKG: 'HK',
  JPN: 'JP',
  NPL: 'NP',
  LVA: 'LV',
  AUT: 'AT',
  LSO: 'LS',
  LUX: 'LU',
  CAF: 'CF',
  ISR: 'IL',
  QAT: 'QA',
  LCA: 'LC',
  CCK: 'CC',
  JAM: 'JM',
  LTU: 'LT',
  GBR: 'GB',
  LAO: 'LA',
  CRI: 'CR',
  STP: 'ST',
  PLW: 'PW',
  MKD: 'MK',
  NFK: 'NF',
  YEM: 'YE',
  VAT: 'VA',
  BFA: 'BF',
  PYF: 'PF',
  IOT: 'IO',
  IRN: 'IR',
  CYP: 'CY',
  BIH: 'BA',
  FXX: 'FX',
  NIC: 'NI',
  ALB: 'AL',
  TGO: 'TG',
  SVN: 'SI',
  NZL: 'NZ',
  SHN: 'SH',
  MWI: 'MW',
  LIE: 'LI',
  VIR: 'VI',
  GRD: 'GD',
  KAZ: 'KZ',
  UMI: 'UM',
  PRI: 'PR',
  SVK: 'SK',
  LBN: 'LB',
  HRV: 'HR',
  SLB: 'SB',
  ZWE: 'ZW',
  CMR: 'CM',
  FRA: 'FR',
  ARE: 'AE',
  BEN: 'BJ',
  GNQ: 'GQ',
  KWT: 'KW',
  WLF: 'WF',
  MDG: 'MG',
  TJK: 'TJ',
  GHA: 'GH',
  TUN: 'TN',
  MLT: 'MT',
  YUG: 'YU',
  GTM: 'GT',
  BGD: 'BD',
  TCD: 'TD',
  TON: 'TO',
  TTO: 'TT',
  SDN: 'SD',
  CUB: 'CU',
  SWE: 'SE',
  ASM: 'AS',
  BRN: 'BN',
  SGS: 'GS',
  BLZ: 'BZ',
  BRA: 'BR',
  PAN: 'PA',
  POL: 'PL',
  PER: 'PE',
  OMN: 'OM',
  BRB: 'BB',
  CHN: 'CN',
  GUY: 'GY',
  CYM: 'KY',
  PRT: 'PT',
  COD: 'CD',
  DJI: 'DJ',
  NER: 'NE',
  ARG: 'AR',
  SGP: 'SG',
  CHL: 'CL',
  IDN: 'ID',
  BMU: 'BM',
  CRO: 'HR',
  PHL: 'PH',
  ESH: 'EH',
  DEU: 'DE',
};
const springConfig = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
};

const exitConfig = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
  scale: 0.8, // This will make the item shrink to 80% of its size when exiting
  opacity: 0, // This will fade out the item
};
