import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, FormHelperText, TextField, Autocomplete } from '@mui/material';

import * as Yup from 'yup';
import { Formik, ErrorMessage, Form } from 'formik';
import FormControl from '@mui/material/FormControl';
import { saveAdminData } from '../../../../../services/utility-api';
import AddressData from '../../../../../components/AdministrationModules/AddressData/AddressView';
import ContactData from '../../../../../components/AdministrationModules/ContactData/ContactView';
import ApiNotification from '../../../../../components/DialogBox';

interface AddEditProps {
  editObj: any;
  referenceMasterTableName: string;
  maxOrder: number;
  jurisdictionOptions: any;
  setIsAddEditOn: React.Dispatch<React.SetStateAction<boolean>>;
  getAllData: () => void;
}

const AddEdit: React.FC<AddEditProps> = ({
  editObj,
  referenceMasterTableName,
  maxOrder,
  jurisdictionOptions,
  setIsAddEditOn,
  getAllData,
}) => {
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const id = '00000000-0000-0000-0000-000000000000';

  const initialValues = {
    ...editObj,
    reportHeaderMasterName: editObj['reportHeaderMasterName'],
    jurisdiction: editObj['jurisdiction'] || null,
    reportHeaderName: editObj['reportHeaderName'] || '',
    meOfficeName: editObj['meOfficeName'] || '',
    reportHeaderDetailsOrder: editObj?.reportHeaderDetailsOrder
      ? Number(editObj.reportHeaderDetailsOrder)
      : maxOrder + 10,
  };

  const validationSchema = Yup.object({
    jurisdiction: Yup.object()
      .shape({
        optionSeq: Yup.string().required('Jurisdiction is required'),
      })
      .nullable()
      .required('Jurisdiction is required'),
    reportHeaderName: Yup.string().required(`Report Header Name is required`).nullable(),
    meOfficeName: Yup.string().required(`ME Office Name is required`).nullable(),
    reportHeaderDetailsOrder: Yup.number().required(`List Order is Required`), // Keep only this definition
    moneyOrderPayableTo: Yup.string().nullable(),
    federalId: Yup.string().nullable(),
    address: Yup.array()
      .of(
        Yup.object().shape({
          addressLine1: Yup.string().required('Address line 1 is required'),
          city: Yup.string().required('City is required'),
        })
      )
      .min(1, 'Address is a required field'),
    contactItem: Yup.array()
      .of(
        Yup.object().shape({
          contactItemTypeSeq: Yup.string().required('Contact type is required'),
          contactItemDetails: Yup.string().required('Contact Details is required'),
        })
      )
      .min(1, 'Contact is a required field'),
  });

  const closeDialogBox = () => {
    setOpenApiDialogBox(false);
  };

  const handleClose = () => {
    setIsAddEditOn(false);
  };

  const onSave = async (values: any) => {
    let contacts: any[] = [];
    if (values?.contactItem)
      values?.contactItem.forEach((item: any) => {
        if (item.contactItemDetails) {
          contacts.push(item);
        }
      });
    if (editObj?.contactItem)
      editObj.contactItem.map((contact: any) => {
        if (
          contact.contactItemSeq != id &&
          contacts.filter(item => item.contactItemSeq == contact.contactItemSeq).length == 0
        ) {
          contacts.push({
            ...contact,
            isActive: false,
            status: 'InActive',
          });
        }
      });
    try {
      values.address = values?.address.map((data: any) => ({
        ...data,
        countySeq: data.countySeq !== '' ? data.countySeq : id,
      }));
      const response = await saveAdminData({
        values: {
          ...values,
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
            ] || id,
          contactItem: contacts,
        },
        tableName: referenceMasterTableName,
      });
      setDialogContents(response?.message);
      setOpenApiDialogBox(true);
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          handleChange,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                    <strong>
                      {Object.keys(editObj).length ? 'Modify ' : 'Enter New '}{' '}
                      {referenceMasterTableName
                        .split('')
                        .map((alpha: any) => {
                          if (alpha <= 'Z') return ' ' + alpha;
                          return alpha;
                        })
                        .join('')
                        .trim()}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset'>
                    <Autocomplete
                      id='jurisdiction'
                      options={jurisdictionOptions || []}
                      value={values?.jurisdiction || null}
                      isOptionEqualToValue={(option, value) =>
                        option?.optionSeq?.toLowerCase() === value?.optionSeq?.toLowerCase()
                      }
                      size='small'
                      getOptionLabel={option => option?.optionName || ''}
                      onChange={(e, newValue) => setFieldValue('jurisdiction', newValue)}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Jurisdiction'
                          id='jurisdiction'
                          name='jurisdiction'
                          placeholder='Jurisdiction'
                          required
                        />
                      )}
                    />
                    <ErrorMessage
                      name='jurisdiction'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      name='reportHeaderName'
                      id='reportHeaderName'
                      label='Report Header Name'
                      variant='outlined'
                      multiline
                      value={values['reportHeaderName'] || ''}
                      onChange={handleChange}
                      InputProps={{
                        inputProps: {
                          minRows: 1,
                        },
                      }}
                    />
                    <ErrorMessage
                      name='reportHeaderName'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      name={`meOfficeName`}
                      id='meOfficeName'
                      label='ME Office Name'
                      variant='outlined'
                      value={values['meOfficeName'] || ''}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name={`meOfficeName`}
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth component='fieldset' variant='standard'>
                    <TextField
                      name='moneyOrderPayableTo'
                      id='moneyOrderPayableTo'
                      label='Money Order Payable To'
                      variant='outlined'
                      value={values['moneyOrderPayableTo'] || ''}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth component='fieldset' variant='standard'>
                    <TextField
                      name='federalId'
                      id='federalId'
                      label='Federal ID#'
                      variant='outlined'
                      value={values['federalId'] || ''}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      name='reportHeaderDetailsOrder'
                      id='reportHeaderDetailsOrder'
                      label='List Order'
                      variant='outlined'
                      type='number'
                      value={values['reportHeaderDetailsOrder'] || ''}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='reportHeaderDetailsOrder'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                    sx={{ marginTop: '10px' }}
                  >
                    <AddressData
                      title='Address'
                      addresses={values.address}
                      contactInfoSeq={values.contactInfoSeq || id}
                      handleChange={(data: any) =>
                        handleChange({
                          target: {
                            value: data,
                            name: 'address',
                          },
                        })
                      }
                    />
                    {typeof errors.address == 'string' && (
                      <ErrorMessage
                        name='address'
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    )}
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                    sx={{ paddingTop: '0px' }}
                  >
                    <ContactData
                      contactItem={values.contactItem}
                      handleChange={(data: any) =>
                        handleChange({
                          target: {
                            value: data,
                            name: 'contactItem',
                          },
                        })
                      }
                    />
                    {typeof errors.contactItem == 'string' && (
                      <ErrorMessage
                        name='contactItem'
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid container item xs={12} justifyContent='flex-start'>
                  <Box component={'div'}>
                    <Button
                      variant='contained'
                      sx={{ minWidth: '100px', mt: '16px' }}
                      onClick={e => handleSubmit()}
                      // disabled = {!values?.jurisdiction}
                    >
                      {Object.keys(editObj).length ? 'Update' : 'Save'}
                    </Button>

                    <Button
                      color='error'
                      variant='outlined'
                      onClick={handleClose}
                      sx={{ minWidth: '100px', marginTop: '16px', marginLeft: '1rem' }}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <ApiNotification
        openApiDialogBox={openApiDialogBox}
        closeDialogBox={closeDialogBox}
        dialogContents={dialogContents}
      />
    </>
  );
};

export default AddEdit;
