import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import ScrollingAlert from '../../components/ScrollingAlert';
import { useAuth } from '../../utils/auth/AuthService';
import { DVCSAlert } from './DVCSAdminView';
import IncidentCard from './IncidentCard';

const MassFatalityCases = () => {
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const [alert, setAlert] = useState<DVCSAlert>({ alertText: '', alertSeverity: 'info' });

  const fetchAlert = async () => {
    await fetch(VITE_API_URL + `dvcs/getalert`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAlert(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchAlert();
  }, []);

  return (
    <Box component='div' sx={{ p: 2 }}>
      {alert.alertText !== '' && (
        <Box component='div' sx={{ mb: 2 }}>
          <ScrollingAlert
            severity={alert.alertSeverity}
            message={alert.alertText ?? ''}
            speed={4}
          />
        </Box>
      )}
      <IncidentCard />
    </Box>
  );
};

export default MassFatalityCases;
