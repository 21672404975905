import { ViewInArSharp } from '@mui/icons-material';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import '../../App.css';
import { useAuth } from '../../utils/auth/AuthService';
import TreeDrawer from '../../utils/components/TreeDrawer';
import TreeTabPanel from '../../utils/components/TreeTabPanel';
import AIPromptView from './ResourcesModules/AIPromptView';
import Glossary from './ResourcesModules/Glossary/GlossaryView';
import ResourcesModelViewer from './ResourcesModules/ModelViewer/ResourcesModelViewer';
import Publications from './ResourcesModules/Publications/PublicationsView';
import Tools from './ResourcesModules/Tools/ToolsView';
import { fetchVideoThumbnailURL } from './ResourcesModules/Videos/VideoPlayer';
import Videos from './ResourcesModules/Videos/Videos';
import CustomHeader from '../../utils/components/CustomHeader';
import { NewAccountRequestForm } from './ResourcesModules/AccountRequests/NewAccountRequestForm';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


export default function ResourcesView() {
  const auth = useAuth();
  const [currentTab, setCurrentTab] = useState('publications');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const drawerContents = [
    {
      id: 'resources',
      label: 'Resources',
      children: [
        { id: 'publications', label: 'Publications', icon: <LibraryBooksIcon /> },
        { id: 'glossary', label: 'Glossary', icon: <MenuBookIcon /> },
        { id: 'videos', label: 'Videos', icon: <VideoLibraryIcon /> },

      ],
    },
    {
      id: 'tools',
      label: 'Tools',
      children: [
        { id: 'bulkupload', label: 'Bulk Upload', icon: <HomeRepairServiceIcon /> },
        // { id: 'model_library', label: 'Model Library', icon: <ViewInArSharp /> },
        // { id: 'ai_prompt', label: 'AI Prompt', icon: <CenterFocusWeakIcon /> },
      ],
    },
    {
      id: 'accountrequests',
      label: 'Account Requests',
      children: [
        { id: 'new-account-request', label: 'New', icon: <PersonAddIcon /> },
        // { id: 'changeaccount', label: 'Change/Update', icon: <ManageAccountsIcon /> },
        // { id: 'deleteaccount', label: 'Delete', icon: <PersonAddDisabledIcon /> },
      ],
    },
  ];

  const [thumbnailURL, setThumbnailURL] = useState('');
  useEffect(() => {
    fetchVideoThumbnailURL('bec34fef-113b-492f-90d3-96f53c053bb7', auth.user.accessToken).then(
      url => setThumbnailURL(url)
    );
  }, [auth.user.accessToken]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <TreeDrawer
        drawerContents={drawerContents}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <TreeTabPanel value={currentTab} index='publications'>
          <Publications handleMenuClick={handleMenuClick} />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='glossary'>
          <Glossary handleMenuClick={handleMenuClick} />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='videos'>
          <Videos handleMenuClick={handleMenuClick} />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='new-account-request'>
          <NewAccountRequestForm handleMenuClick={handleMenuClick} />
        </TreeTabPanel>


      



        <TreeTabPanel value={currentTab} index='bulkupload'>
          <CustomHeader
            title='Bulk Uploads'
            description='Forensic and Analytical tools'
            handleMenuClick={handleMenuClick}
          />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Tools />
          </ErrorBoundary>
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='model_library'>
          <CustomHeader
            title='Models'
            description='View and manage 3D model resources'
            handleMenuClick={handleMenuClick}
          />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResourcesModelViewer />
          </ErrorBoundary>
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='ai_prompt'>
          <CustomHeader
            title='AI Prompt'
            description='Use AI to help with your analysis'
            handleMenuClick={handleMenuClick}
          />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AIPromptView />
          </ErrorBoundary>
        </TreeTabPanel>
      </Box>
    </Box>
  );
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <hr />
      <p>Please try again later</p>
    </div>
  );
}
