import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { fetchUserCertificateType } from '../../services/utility-api';
import { GlobalContext } from '../../Context/GlobalContext';
import ExportCheckboxGroup from './ExportCheckboxGroup';
import axios from 'axios';
import exportToZip from './ExportToZip';
import authHeader from '../../services/auth-header';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ExportDocuments = ({
  openExportDialogBox,
  setOpenExportDialogBox,
  files,
}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [certificateTypes, setCertificateTypes] = useState([]);
  const { profileInformation } = useContext(GlobalContext);

  useEffect(() => {
    fetchUserCertificateType(profileInformation?.userSeq, true).then(
      (response) => setCertificateTypes(response)
    );
  }, []);

  const downloadSectionsZip = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}ExportFile?isAll=${
          Object.values(selectedCheckboxes).every((value) => value === true)
            ? true
            : false
        }&userSeq=${profileInformation?.userSeq}`,
        [
          ...Object.keys(selectedCheckboxes).filter(
            (key) => selectedCheckboxes[key]
          ),
        ],
        { headers: authHeader() }
      );
      exportToZip(response.data.data, setOpenExportDialogBox);
    } catch (error) {
      setOpenExportDialogBox(false);
    }
  };

  console.log('files', files);
  return (
    <div className='modal'>
      <Dialog
        open={openExportDialogBox}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenExportDialogBox(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>Export</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Documents
          </DialogContentText>
          <ExportCheckboxGroup
            options={certificateTypes}
            selectedCheckboxes={selectedCheckboxes}
            setSelectedCheckboxes={setSelectedCheckboxes}
            files={files}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenExportDialogBox(false)}>Cancel</Button>
          <Button onClick={downloadSectionsZip}>Download</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportDocuments;
