import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardMedia,
  CardActions,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

interface UploadedFile {
  name: string;
  data: string;
}

interface MLSVGDropzoneProps {
  setFieldValue: (field: string, value: any) => void;
  initialValue: string | null;
}

const MLSVGDropzone: React.FC<MLSVGDropzoneProps> = ({ initialValue, setFieldValue }) => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileData = { name: file.name, data: reader.result as string };
        setUploadedFile(fileData);
        setFieldValue('JdxLogo', fileData.data);
      };
      reader.readAsText(file);
    }
    setAnchorEl(null);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: { 'image/svg+xml': [] }, // Correct MIME type format for SVG files
    multiple: false, 
    noClick: true,
  });

  const handleDelete = () => {
    setUploadedFile(null);
    setFieldValue('JdxLogo', '');
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (initialValue) {
      setUploadedFile({ name: 'Saved Logo', data: initialValue });
      // setFieldValue('JdxLogo', initialValue);
    }
  }, [initialValue]);

  return (
    <Card
      variant='outlined'
      sx={{
        padding: '16px',
        flex: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Typography variant='h6' color='gray'>
          {uploadedFile ? 'Uploaded Jurisdiction Logo:' : 'Upload Jurisdiction Logo:'}
      </Typography>

      {!uploadedFile ? (
        <Box
          component={'div'}
          {...getRootProps()}
          sx={{
            p: 3,
            border: '1px dashed',
            borderRadius: 2,
            borderColor: 'divider',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 180,
            backgroundColor: 'background.default',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
          onClick={open}
        >
          <input {...getInputProps()} />
          <Typography>
            Drag & drop a file here, or{' '}
            <Typography
              component='span'
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              browse
            </Typography>
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            (Only SVG files are supported)
          </Typography>
        </Box>
      ) : (
        <Card
          variant='outlined'
          sx={{
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'grey',
            display:'flex', justifyContent: 'center', alignItems:'center', width: '100%', height: '100%'
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: uploadedFile.data }} 
            style={{width: '100%', height: '100%', maxWidth: '250px', maxHeight: '150px', display:'flex', justifyContent: 'center', alignItems:'center'}}
          />

          <CardActions
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adds a dark overlay for better visibility
              p: 1,
              zIndex: 1,
              borderRadius: '0 0 0 8px',
            }}
          >
            <Tooltip title='Options'>
              <IconButton
                size='small'
                onClick={handleMenuOpen}
                sx={{
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  },
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem {...getRootProps()} onClick={open}>
                <input {...getInputProps()} />
                <EditIcon fontSize='small' sx={{ mr: 1 }} />
                Replace
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <DeleteIcon fontSize='small' sx={{ mr: 1 }} />
                Delete
              </MenuItem>
            </Menu>
          </CardActions>
        </Card>
      )}
    </Card>
  );
};

export default MLSVGDropzone;
