import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import AddressData from '../../../../components/AdministrationModules/AddressComponents/AddressData';
import { ActionList } from '../../../../components/CaseView/CaseViewDateTimeField.jsx';
import InformantDialog from '../../../../components/InformantDialog.jsx';
import { useAuth } from '../../../../utils/auth/AuthService';
import { FormikTextField } from '../../../../utils/components/FormikTextField';
import GenerateINVDataSheetButton from '../../../../utils/components/GenerateDatasheet';
import { DecedentAddressTypeOptions } from '../../../../utils/constants/constants';
import { AddressMap } from './AddressMap.tsx';

export default function DiscoveryView({ discovery, informantList, handleChange, editing }) {
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const formik = useFormikContext();
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);
  const [lastKnownToBeAliveRelship, setLastKnownToBeAliveRelship] = useState(null);
  const [policeNotifiedByRelship, setPoliceNotifiedByRelship] = useState(null);
  const [foundOnsetIllnessByRelship, setFoundOnsetIllnessByRelship] = useState(null);
  const [lastKnownToBeAliveContact, setLastKnownToBeAliveContact] = useState(null);
  const [policeNotifiedByContact, setPoliceNotifiedByContact] = useState(null);
  const [foundOnsetIllnessByContact, setFoundOnsetIllnessByContact] = useState(null);
  const [foundOnsetIllnessByType, setFoundOnsetIllnessByType] = useState(null);
  const [pendingChangeEvent, setPendingChangeEvent] = useState(null);
  const [isInformantDialogOpen, setIsInformantDialogOpen] = useState(false);
  const [selectedInformant, setSelectedInformant] = useState([]);
  const [informantOptions, setInformantOptions] = useState([]);
  const [showDiscoveryMap, setShowDiscoveryMap] = useState(() => {
    const storedValue = localStorage.getItem('showDiscoveryMap');
    return storedValue === 'true';
  });
  const [showCheckbox, setShowCheckbox] = useState(true);

  const handleShowMapCheckboxChange = event => {
    const { checked } = event.target;
    setShowDiscoveryMap(checked);
    localStorage.setItem('showDiscoveryMap', checked);
  };

  const getFirstActiveContactNumber = contactInfoList => {
    const activeContacts = contactInfoList?.find(info => info.isActive) || {};
    return activeContacts?.contactItemDetails || '';
  };

  const handleNewContactClick = (event, context) => {
    setPendingChangeEvent({ event, context });
    setSelectedInformant([]);
    setIsInformantDialogOpen(true);
  };

  const handleNewContactSubmit = newContact => {
    const newContactOption = {
      informantSeq: newContact?.informantSeq,
      personSeq: newContact?.personSeq,
      personLastName: newContact?.personLastName,
      personFirstName: newContact?.personFirstName,
      relship: newContact?.relship?.optionName,
      PersonContactInfoSeq: newContact?.personContactInfoSeq,
      contactNumber: getFirstActiveContactNumber(newContact?.informantAddress?.contactItem || []),
    };
    setInformantOptions(prevOptions => [...prevOptions, newContactOption]);
    setIsInformantDialogOpen(false);
    if (pendingChangeEvent) {
      const { event, context } = pendingChangeEvent;
      if (context === 'foundOnsetIllnessBy') {
        handleFoundOnsetIllnessByChange(event, newContactOption);
      } else if (context === 'lastKnownToBeAlive') {
        handleLastKnownToBeAliveChange(event, newContactOption);
      } else if (context === 'policeNotifiedBy') {
        handlePoliceNotifiedByChange(event, newContactOption);
      }
      setPendingChangeEvent(null);
    }
  };

  const handleLastKnownToBeAliveChange = (event, value) => {
    setLastKnownToBeAliveRelship(value ? value.relship : null);
    handleChange({
      lastKnownToBeAlive: {
        ...discovery['lastKnownToBeAlive'],
        personSeq: value ? value.personSeq : null,
      },
    });
  };

  const handlePoliceNotifiedByChange = (event, value) => {
    setPoliceNotifiedByRelship(value ? value.relship : null);
    handleChange({
      policeNotifiedBy: {
        ...discovery['policeNotifiedBy'],
        personSeq: value ? value.personSeq : null,
      },
    });
  };

  const handleAddressChange = data => {
    formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', data);
  };

  function onCheck(event) {
    const isChecked = event.target.checked;

    formik.setFieldValue(
      'caseInvestigations.discovery.isCopyDecedentAddrToFoundOnsetIllnessAddr',
      isChecked
    );

    const foundOnsetIllnessAddress = discovery?.foundOnsetIllnessAddress?.[0] || [];

    if (isChecked) {
      const decedentAddress = formik.values.caseDemographics?.decedentAddress?.[0] || [];

      formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', [
        {
          ...decedentAddress,
          addressSeq: foundOnsetIllnessAddress?.addressSeq,
          contactInfoSeq: foundOnsetIllnessAddress?.contactInfoSeq,
        },
      ]);
    } else {
      const updatedDecedentAddress = {
        ...foundOnsetIllnessAddress,
        addressTypeSeq: null,
        addressLine1: '',
        city: '',
        placeSeq: null,
        countySeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
        floor: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
      };

      formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', [
        updatedDecedentAddress,
      ]);
    }
  }

  const handlePoliceCaseNumberChange = event => {
    formik.setFieldValue('caseInvestigations.discovery.policeCaseNumber', event.target.value);
  };

  const handleFoundOnsetIllnessByChange = (event, value) => {
    setFoundOnsetIllnessByRelship(value ? value.relship : null);

    const updatedFields = {
      foundOnsetIllnessBy: {
        ...discovery['foundOnsetIllnessBy'],
        personSeq: value ? value.personSeq : null,
      },
    };

    // Check if foundOnsetIllnessByType is empty or undefined, and if so, set it default to 'F'
    if (!discovery['foundOnsetIllnessByType']) {
      updatedFields.foundOnsetIllnessByType = 'F';
    }

    handleChange(updatedFields);
  };

  const handleDateTimeChange = (fieldName, newValue) => {
    const [fieldKey, nestedKey] = fieldName.split('.');

    const updatedField = {
      [fieldKey]: {
        ...discovery[fieldKey],
        [nestedKey]: newValue,
      },
    };

    handleChange(updatedField);
  };

  const handleFoundOnsetIllnessByTypeChange = event => {
    handleChange({ foundOnsetIllnessByType: event.target.value });
  };

  const fetchContactItem = async (field, ContactInfoSeq) => {
    fetch(VITE_API_URL + 'getcontactitembycontactseq?ContactInfoSeq=' + ContactInfoSeq, {
      method: 'GET',
    })
      .then(res => {
        if (res.status === 204) {
          if (field === 'lastKnownToBeAlive') {
            setLastKnownToBeAliveContact(null);
          }
          if (field === 'policeNotifiedBy') {
            setPoliceNotifiedByContact(null);
          }
          if (field === 'foundOnsetIllnessBy') {
            setFoundOnsetIllnessByContact(null);
          }
        }
        return res.json();
      })
      .then(data => {
        if (field === 'lastKnownToBeAlive') {
          setLastKnownToBeAliveContact(data);
        }
        if (field === 'policeNotifiedBy') {
          setPoliceNotifiedByContact(data);
        }
        if (field === 'foundOnsetIllnessBy') {
          setFoundOnsetIllnessByContact(data);
        }
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    const { caseInvestigations, caseDemographics } = formik.values;

    const decedentAddress = caseDemographics?.decedentAddress?.[0] || [];
    const foundOnsetIllnessAddress =
      caseInvestigations?.discovery?.foundOnsetIllnessAddress?.[0] || [];

    const decedentResidenceAddressType = decedentAddress?.addressTypeSeq?.toUpperCase();
    const shouldShowCheckbox =
      decedentResidenceAddressType === DecedentAddressTypeOptions.Residence ||
      decedentResidenceAddressType === DecedentAddressTypeOptions.NursingHome;

    setShowCheckbox(shouldShowCheckbox);

    if (
      caseInvestigations?.discovery?.isCopyDecedentAddrToFoundOnsetIllnessAddr &&
      shouldShowCheckbox
    ) {
      formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', [
        {
          ...decedentAddress,
          addressSeq: foundOnsetIllnessAddress?.addressSeq,
          contactInfoSeq: foundOnsetIllnessAddress?.contactInfoSeq,
        },
      ]);
    }

    if (
      !shouldShowCheckbox &&
      caseInvestigations?.discovery?.isCopyDecedentAddrToFoundOnsetIllnessAddr
    ) {
      formik.setFieldValue(
        'caseInvestigations.discovery.isCopyDecedentAddrToFoundOnsetIllnessAddr',
        false
      );

      //uncomment below commented code to reset the address, for now just maintain what it has.
      // const updatedDecedentAddress = {
      //   ...foundOnsetIllnessAddress,
      //   addressTypeSeq: null,
      //   addressLine1: '',
      //   city: '',
      //   placeSeq: null,
      //   countySeq: null,
      //   nursingHomeSeq: null,
      //   funeralHomeSeq: null,
      //   lawEnforcementSeq: null,
      //   floor: '',
      //   zip: '',
      //   addressLine2: '',
      //   apartmentNo: '',
      //   crossStreet: '',
      //   otherAddressType: '',
      // };

      // formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', [updatedDecedentAddress]);
    }
  }, [formik.values.caseDemographics?.decedentAddress]);

  useEffect(() => {
    const informantOptions = [
      { personSeq: 'new', personLastName: 'Add New Contact', personFirstName: '' },
      ...informantList.map(option => ({
        informantSeq: option?.informantSeq,
        personSeq: option?.personSeq,
        personLastName: option?.personLastName,
        personFirstName: option?.personFirstName,
        relship: option?.relship?.optionName,
        PersonContactInfoSeq: option?.personContactInfoSeq,
        contactNumber: getFirstActiveContactNumber(option?.informantAddress?.contactItem || []),
      })),
    ];
    setInformantOptions(informantOptions);
  }, [informantList]);

  useEffect(() => {
    if (discovery != null && discovery?.lastKnownToBeAlive?.personSeq) {
      const lastKnownToBeAlive = informantOptions?.find(
        option =>
          option.personSeq?.toLowerCase() ===
          discovery?.lastKnownToBeAlive?.personSeq?.toLowerCase()
      );
      setLastKnownToBeAliveRelship(lastKnownToBeAlive?.relship || null);

      if (
        typeof lastKnownToBeAlive?.PersonContactInfoSeq !== 'undefined' &&
        lastKnownToBeAlive?.PersonContactInfoSeq !== null &&
        lastKnownToBeAlive?.contactNumber === ''
      ) {
        fetchContactItem('lastKnownToBeAlive', lastKnownToBeAlive?.PersonContactInfoSeq);
      } else {
        setLastKnownToBeAliveContact({
          contactItemDetails: lastKnownToBeAlive?.contactNumber || null,
        });
      }
    } else {
      setLastKnownToBeAliveContact(null);
    }
  }, [discovery?.lastKnownToBeAlive?.personSeq, informantOptions]);

  useEffect(() => {
    if (discovery != null && discovery?.policeNotifiedBy?.personSeq) {
      const policeNotifiedBy = informantOptions?.find(
        option =>
          option.personSeq?.toLowerCase() === discovery?.policeNotifiedBy?.personSeq?.toLowerCase()
      );
      setPoliceNotifiedByRelship(policeNotifiedBy?.relship || null);

      if (
        typeof policeNotifiedBy?.PersonContactInfoSeq !== 'undefined' &&
        policeNotifiedBy?.PersonContactInfoSeq !== null &&
        policeNotifiedBy?.contactNumber === ''
      ) {
        fetchContactItem('policeNotifiedBy', policeNotifiedBy?.PersonContactInfoSeq);
      } else {
        setPoliceNotifiedByContact({ contactItemDetails: policeNotifiedBy?.contactNumber || null });
      }
    } else {
      setPoliceNotifiedByContact(null);
    }
  }, [discovery?.policeNotifiedBy?.personSeq, informantOptions]);

  useEffect(() => {
    if (discovery != null && discovery?.foundOnsetIllnessBy?.personSeq) {
      const foundOnsetIllnessBy = informantOptions?.find(
        option =>
          option.personSeq?.toLowerCase() ===
          discovery?.foundOnsetIllnessBy?.personSeq?.toLowerCase()
      );
      setFoundOnsetIllnessByRelship(foundOnsetIllnessBy?.relship || null);

      if (
        typeof foundOnsetIllnessBy?.PersonContactInfoSeq !== 'undefined' &&
        foundOnsetIllnessBy?.PersonContactInfoSeq !== null &&
        foundOnsetIllnessBy?.contactNumber === ''
      ) {
        fetchContactItem('foundOnsetIllnessBy', foundOnsetIllnessBy?.PersonContactInfoSeq);
      } else {
        setFoundOnsetIllnessByContact({
          contactItemDetails: foundOnsetIllnessBy?.contactNumber || null,
        });
      }
    } else {
      setFoundOnsetIllnessByContact(null);
    }
  }, [discovery?.foundOnsetIllnessBy?.personSeq, informantOptions]);

  useEffect(() => {
    setFoundOnsetIllnessByType(discovery?.foundOnsetIllnessByType);
  }, [discovery?.foundOnsetIllnessByType]);

  const isInvalidDate = date => {
    return !date || !dayjs(date).isValid();
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2}>
          <Divider component='hr' variant='fullWidth' />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <GenerateINVDataSheetButton label='Generate INV Data Sheet' />
        </Grid>

        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disabled={!editing || !authorizedToEdit}
              name='lastKnownToBeAlive.dateTime'
              label='Last Known To Be Alive On'
              value={
                discovery?.lastKnownToBeAlive?.dateTime
                  ? dayjs(discovery?.lastKnownToBeAlive?.dateTime) || null
                  : null
              }
              onChange={date => {
                const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                handleDateTimeChange('lastKnownToBeAlive.dateTime', localDate);
              }}
              slotProps={{
                textField: {
                  id: 'lastKnownToBeAlive.dateTime',
                },
                field: { clearable: true },
              }}
              timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              slots={{
                actionBar: ActionList,
              }}
              // disableFuture={true}
              ampm={false}
              sx={{
                minWidth: '100%',
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            disabled={!editing || !authorizedToEdit}
            id='lastKnownToBeAlive.personSeq'
            name='lastKnownToBeAlive.personSeq'
            options={informantOptions}
            value={
              discovery?.lastKnownToBeAlive?.personSeq != null
                ? informantOptions?.find(
                    option =>
                      option.personSeq?.toLowerCase() ===
                      discovery?.lastKnownToBeAlive?.personSeq?.toLowerCase()
                  ) || null
                : null
            }
            onChange={(event, newValue) => {
              if (newValue?.personSeq === 'new') {
                handleNewContactClick(event, 'lastKnownToBeAlive');
              } else {
                handleLastKnownToBeAliveChange(event, newValue);
              }
            }}
            getOptionLabel={option =>
              `${option.personLastName || ''}${
                option.personLastName && option.personFirstName ? ', ' : ''
              }${option.personFirstName || ''}`
            }
            renderOption={(props, option) => (
              <li
                {...props}
                style={
                  option.personSeq === 'new'
                    ? {
                        fontStyle: 'italic',
                        color: '#5C72FF',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }
                    : {}
                }
              >
                {option.personSeq === 'new'
                  ? 'Add New Contact'
                  : `${option.personLastName || ''}${
                      option.personLastName && option.personFirstName ? ', ' : ''
                    }${option.personFirstName || ''}${
                      option.relship ? ` (${option.relship})` : ''
                    }`}
              </li>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label='Last Known To Be Alive By'
                helperText={
                  <Stack color='text.secondary'>
                    {/* <div>
                      {lastKnownToBeAliveRelship &&
                        `Relationship to Decedent: ${lastKnownToBeAliveRelship}`}
                    </div> */}
                    <div>
                      {lastKnownToBeAliveContact &&
                        `Contact: ${lastKnownToBeAliveContact.contactItemDetails}`}
                    </div>
                  </Stack>
                }
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <GenerateINVDataSheetButton label='Generate INV Data Sheet' />
        </Grid>

        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <Typography
              variant='caption'
              color='gray'
              textTransform='uppercase'
              sx={{ fontWeight: 'bold', fontSize: '0.5rem' }}
            >
              Discovery Category
            </Typography>
            <RadioGroup
              name='foundOnsetIllnessByType'
              value={foundOnsetIllnessByType}
              onChange={handleFoundOnsetIllnessByTypeChange}
              row
              sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.7rem' } }}
            >
              <FormControlLabel
                disabled={!editing || !authorizedToEdit}
                value={'F'}
                control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                label='Found'
              />
              <FormControlLabel
                disabled={!editing || !authorizedToEdit}
                value={'O'}
                control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                label='Injury Observed'
              />
              <FormControlLabel
                disabled={!editing || !authorizedToEdit}
                value={'I'}
                control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                label='Onset Of Illness'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disabled={!editing || !authorizedToEdit}
                  name='foundOnsetIllnessBy.dateTime'
                  label={
                    foundOnsetIllnessByType === 'I'
                      ? 'Onset Of Illness On'
                      : foundOnsetIllnessByType === 'O'
                      ? 'Injury Observed On'
                      : 'Found On'
                  }
                  value={
                    discovery?.foundOnsetIllnessBy?.dateTime
                      ? dayjs(discovery?.foundOnsetIllnessBy?.dateTime) || null
                      : null
                  }
                  onChange={date => {
                    const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                    handleDateTimeChange('foundOnsetIllnessBy.dateTime', localDate);
                  }}
                  slotProps={{
                    textField: {
                      id: 'foundOnsetIllnessBy.dateTime',
                    },
                    field: { clearable: true },
                  }}
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  // disableFuture={true}
                  ampm={false}
                  sx={{
                    minWidth: '100%',
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                disabled={!editing || !authorizedToEdit}
                id='foundOnsetIllnessBy'
                name='foundOnsetIllnessBy'
                options={informantOptions}
                value={
                  discovery?.foundOnsetIllnessBy?.personSeq != null
                    ? informantOptions?.find(
                        option =>
                          option.personSeq?.toLowerCase() ===
                          discovery?.foundOnsetIllnessBy?.personSeq?.toLowerCase()
                      ) || null
                    : null
                }
                onChange={(event, newValue) => {
                  if (newValue?.personSeq === 'new') {
                    handleNewContactClick(event, 'foundOnsetIllnessBy');
                  } else {
                    handleFoundOnsetIllnessByChange(event, newValue);
                  }
                }}
                getOptionLabel={option =>
                  `${option.personLastName || ''}${
                    option.personLastName && option.personFirstName ? ', ' : ''
                  }${option.personFirstName || ''}`
                }
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={
                      option.personSeq === 'new'
                        ? {
                            fontStyle: 'italic',
                            color: '#5C72FF',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        : {}
                    }
                  >
                    {option.personSeq === 'new'
                      ? 'Add New Contact'
                      : `${option.personLastName || ''}${
                          option.personLastName && option.personFirstName ? ', ' : ''
                        }${option.personFirstName || ''}${
                          option.relship ? ` (${option.relship})` : ''
                        }`}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      foundOnsetIllnessByType === 'I'
                        ? 'Onset Of Illness By'
                        : foundOnsetIllnessByType === 'O'
                        ? 'Injury Observed By'
                        : 'Found By'
                    }
                    helperText={
                      <Stack color='text.secondary'>
                        {/* <div>
                          {foundOnsetIllnessByRelship !== null
                            ? `Relationship to Decedent: ${foundOnsetIllnessByRelship}`
                            : ''}
                        </div> */}
                        <div>
                          {foundOnsetIllnessByContact !== null
                            ? `Contact: ${foundOnsetIllnessByContact.contactItemDetails}`
                            : ''}
                        </div>
                      </Stack>
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disabled={!editing || !authorizedToEdit}
                  name='policeNotifiedBy.dateTime'
                  label='Police Notified On'
                  value={
                    discovery?.policeNotifiedBy?.dateTime
                      ? dayjs(discovery?.policeNotifiedBy?.dateTime) || null
                      : null
                  }
                  onChange={date => {
                    const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                    handleDateTimeChange('policeNotifiedBy.dateTime', localDate);
                  }}
                  slotProps={{
                    textField: {
                      id: 'policeNotifiedBy.dateTime',
                    },
                    field: { clearable: true },
                  }}
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  // disableFuture={true}
                  ampm={false}
                  sx={{
                    minWidth: '100%',
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                disabled={!editing || !authorizedToEdit}
                id='policeNotifiedBy'
                name='policeNotifiedBy'
                options={informantOptions}
                value={
                  discovery?.policeNotifiedBy?.personSeq != null
                    ? informantOptions?.find(
                        option =>
                          option.personSeq?.toLowerCase() ===
                          discovery?.policeNotifiedBy?.personSeq?.toLowerCase()
                      ) || null
                    : null
                }
                onChange={(event, newValue) => {
                  if (newValue?.personSeq === 'new') {
                    handleNewContactClick(event, 'policeNotifiedBy');
                  } else {
                    handlePoliceNotifiedByChange(event, newValue);
                  }
                }}
                getOptionLabel={option =>
                  `${option.personLastName || ''}${
                    option.personLastName && option.personFirstName ? ', ' : ''
                  }${option.personFirstName || ''}`
                }
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={
                      option.personSeq === 'new'
                        ? {
                            fontStyle: 'italic',
                            color: '#5C72FF',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        : {}
                    }
                  >
                    {option.personSeq === 'new'
                      ? 'Add New Contact'
                      : `${option.personLastName || ''}${
                          option.personLastName && option.personFirstName ? ', ' : ''
                        }${option.personFirstName || ''}${
                          option.relship ? ` (${option.relship})` : ''
                        }`}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Police Notified By'
                    helperText={
                      <Stack color='text.secondary'>
                        {/* <div>
                          {policeNotifiedByRelship !== null
                            ? `Relationship to Decedent: ${policeNotifiedByRelship}`
                            : ''}
                        </div> */}
                        <div>
                          {policeNotifiedByContact !== null
                            ? `Contact: ${policeNotifiedByContact.contactItemDetails}`
                            : ''}
                        </div>
                      </Stack>
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                disabled={!editing || !authorizedToEdit}
                fullWidth
                id='policeCaseNumber'
                name='policeCaseNumber'
                label='Police Case Number'
                value={discovery?.policeCaseNumber || ''}
                onChange={handlePoliceCaseNumberChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box display={{ xs: 'block', md: 'flex' }} alignItems='center' gap={{ md: 3 }}>
            <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
              <b>{`Location Where ${
                foundOnsetIllnessByType === 'I'
                  ? 'Illness Observed'
                  : foundOnsetIllnessByType === 'O'
                  ? 'Injury Observed'
                  : 'Found'
              }`}</b>
            </Typography>

            {showCheckbox && (
              <FormControlLabel
                disabled={!editing || !authorizedToEdit}
                checked={discovery?.isCopyDecedentAddrToFoundOnsetIllnessAddr ?? false}
                control={<Checkbox sx={{ transform: 'scale(0.8)' }} />} // Optional: Scale the checkbox size
                onChange={onCheck}
                label='Copy address from decedent residence'
                sx={{
                  flexShrink: 0,
                  '& .MuiFormControlLabel-label': { fontSize: '0.7rem' }, // Adjust label font size
                }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <AddressData
            handleChange={data => handleAddressChange(data)}
            addresses={discovery?.foundOnsetIllnessAddress || []}
            title=''
            showAllTypes={true}
            disabled={
              !editing || !authorizedToEdit || discovery?.isCopyDecedentAddrToFoundOnsetIllnessAddr
            }
          />
        </Grid>

        <FormikTextField
          authorizedToEdit={authorizedToEdit}
          label='Description of Location'
          formikField='caseInvestigations.discoveryLocationDescription'
          fullWidth
          multiline
          minRows={3}
          maxRows={Infinity}
          gridProps={{ xs: 12 }}
          maxCharacterLimit={4000}
        />

        <Grid item xs={12} md={3}>
          <FormControlLabel
            label={
              <Typography variant='body2' sx={{ fontSize: '0.7rem', fontWeight: 'normal' }}>
                Show Map
              </Typography>
            }
            control={
              <Checkbox
                id='showDiscoveryMap'
                name='showDiscoveryMap'
                checked={showDiscoveryMap}
                onChange={handleShowMapCheckboxChange}
                onBlur={formik.handleBlur}
              />
            }
          />
        </Grid>

        {showDiscoveryMap && (
          <Grid item xs={12}>
            <AddressMap
              addressLine1={discovery?.foundOnsetIllnessAddress?.[0]?.addressLine1 || ''}
              addressLine2={discovery?.foundOnsetIllnessAddress?.[0]?.addressLine2 || ''}
              city={discovery?.foundOnsetIllnessAddress?.[0]?.city || ''}
              state={'New Jersey'} //currently got only seq but map using the name as prop so just hardcoded name. will fix it later to use Seq instead of name.
              zipcode={discovery?.foundOnsetIllnessAddress?.[0]?.zip ?? ''}
            />
          </Grid>
        )}
      </Grid>

      <InformantDialog
        isInformantDialogOpen={isInformantDialogOpen}
        setIsInformantDialogOpen={setIsInformantDialogOpen}
        selectedInformant={selectedInformant}
        setSelectedInformant={setSelectedInformant}
        handleNewContactCallback={handleNewContactSubmit}
      />
    </form>
  );
}
