import { Description } from '@mui/icons-material';
import {
  Breadcrumbs,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useUnsavedChanges } from '../Context/UnsavedChangesContext';
import { useAuth } from '../utils/auth/AuthService';
import { CaseViewOptionsAutocomplete } from '../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../utils/components/FormikTextField';
import { MEAction } from '../utils/constants/MEAction';
import CaseViewDateTimeField from './CaseView/CaseViewDateTimeField';

interface PathologyCardProps {
  formikFields: {
    decedentDateOfDeath?: string;
    decedentUnderlyingIllness?: string;
    decedentOtherUnderlyingIllness?: string;
    decedentMannerOfDeath?: string;
    decedentCauseOfDeath?: string;
    decedentDrugToxicityConfirmed?: string;
    decedentInfectiousDiseaseConfirmed?: string;
    decedentOpiodConfirmed?: string;
  };
}

export function PathologyCard({ formikFields }: PathologyCardProps) {
  const formik = useFormikContext<any>();
  const { setUnsavedChanges } = useUnsavedChanges();
  const { user } = useAuth();
  const authorizedToEdit =
    user?.roleCheck([
      '2D5', // Investigator
      '451', // System Admin
      'MES', // Pathologist
      'c97', // Administrative Assistant,
      '08b', // HISTORIC-DATAENTRYONLY
    ]) || false;

  const displayConfig = {
    decedentDateOfDeath: Boolean(formikFields.decedentDateOfDeath),
    decedentUnderlyingIllness: Boolean(formikFields.decedentUnderlyingIllness),
    decedentOtherUnderlyingIllness: Boolean(formikFields.decedentOtherUnderlyingIllness),
    decedentMannerOfDeath: Boolean(formikFields.decedentMannerOfDeath),
    decedentCauseOfDeath: Boolean(formikFields.decedentCauseOfDeath),
    decedentDrugToxicityConfirmed: Boolean(formikFields.decedentDrugToxicityConfirmed),
    decedentInfectiousDiseaseConfirmed: Boolean(formikFields.decedentInfectiousDiseaseConfirmed),
    decedentOpiodConfirmed: Boolean(formikFields.decedentOpiodConfirmed),
  };

  const { value: decedentDateOfDeathValue } = formik.getFieldProps(
    formikFields.decedentDateOfDeath
  );
  const { setValue: setDecedentDateOfDeath } = formik.getFieldHelpers(
    formikFields.decedentDateOfDeath
  );

  const { value: decedentUnderlyingIllnessValue } = formik.getFieldProps(
    formikFields.decedentUnderlyingIllness
  );
  const { setValue: setUnderlyingIllness } = formik.getFieldHelpers(
    formikFields.decedentUnderlyingIllness
  );

  const { value: decedentOtherIllnessValue } = formik.getFieldProps(
    formikFields.decedentOtherUnderlyingIllness
  );
  const { setValue: setOtherUnderlyingIllness } = formik.getFieldHelpers(
    formikFields.decedentOtherUnderlyingIllness
  );

  const Selected_Underlying_Illness = useMemo(() => {
    return formik.values?.caseMAIDDetails?.underlyingIllness?.optionSeq?.toUpperCase() || '';
  }, [formik.values?.caseMAIDDetails?.underlyingIllness]);

  const { value: decedentMannerOfDeathValue } = formik.getFieldProps(
    formikFields.decedentMannerOfDeath
  );
  const { setValue: setMannerOfDeath } = formik.getFieldHelpers(formikFields.decedentMannerOfDeath);

  const { value: decedentCauseOfDeathValue } = formik.getFieldProps(
    formikFields.decedentCauseOfDeath
  );
  const { setValue: setCauseOfDeath } = formik.getFieldHelpers(formikFields.decedentCauseOfDeath);

  const { value: decedentDrugToxicityConfirmedValue } = formik.getFieldProps(
    formikFields.decedentDrugToxicityConfirmed
  );
  const { setValue: setDrugToxicityConfirmed } = formik.getFieldHelpers(
    formikFields.decedentDrugToxicityConfirmed
  );

  const { value: decedentInfectiousDiseaseConfirmedValue } = formik.getFieldProps(
    formikFields.decedentInfectiousDiseaseConfirmed
  );
  const { setValue: setInfectiousDiseaseConfirmed } = formik.getFieldHelpers(
    formikFields.decedentInfectiousDiseaseConfirmed
  );

  const { value: decedentOpiodConfirmedValue } = formik.getFieldProps(
    formikFields.decedentOpiodConfirmed
  );
  const { setValue: setOpiodConfirmed } = formik.getFieldHelpers(
    formikFields.decedentOpiodConfirmed
  );

  const showOnSameRow =
    displayConfig.decedentMannerOfDeath &&
    displayConfig.decedentCauseOfDeath &&
    displayConfig.decedentUnderlyingIllness &&
    displayConfig.decedentDateOfDeath;

  const showOtherIllness =
    Selected_Underlying_Illness === MEAction.Constants.MAiD.UnderlyingIllness.Other;

  return (
    <Grid container spacing={2} direction='row' justifyContent='space-around' alignItems='center'>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
          <Description sx={{ color: 'gray' }} />
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Pathology</strong>
          </Typography>
        </Stack>
      </Grid>

      {displayConfig.decedentMannerOfDeath && (
        <Grid item xs={12} sm={6} md={showOnSameRow ? 3 : 6}>
          <CaseViewOptionsAutocomplete
            label={
              <Breadcrumbs>
                <Typography color='text.primary'>Manner of Death</Typography>
              </Breadcrumbs>
            }
            optionsEndpoint='getmannerofdeathoptions'
            formikField={formikFields.decedentMannerOfDeath}
            authorizedToEdit={authorizedToEdit}
            textFieldProps={{
              variant: 'outlined',
            }}
          />
        </Grid>
      )}
      {displayConfig.decedentCauseOfDeath && (
        <Grid item xs={12} sm={6} md={showOnSameRow ? 3 : 6}>
          <FormikTextField
            label={
              <Breadcrumbs>
                <Typography color='text.primary'>Cause of Death</Typography>
              </Breadcrumbs>
            }
            formikField={formikFields.decedentCauseOfDeath}
            authorizedToEdit={authorizedToEdit}
          />
        </Grid>
      )}
      {displayConfig.decedentUnderlyingIllness && (
        <Grid
          item
          xs={12}
          sm={showOtherIllness ? 6 : 12}
          md={showOnSameRow ? (showOtherIllness ? 1.5 : 3) : 6}
        >
          <CaseViewOptionsAutocomplete
            label='Decedent Underlying Illness'
            optionsEndpoint='getmaidunderlyingillnessoptions'
            formikField={formikFields.decedentUnderlyingIllness}
            authorizedToEdit={authorizedToEdit}
          />
        </Grid>
      )}

      {displayConfig.decedentUnderlyingIllness &&
        displayConfig.decedentOtherUnderlyingIllness &&
        Selected_Underlying_Illness === MEAction.Constants.MAiD.UnderlyingIllness.Other && (
          <Grid item xs={12} sm={6} md={showOnSameRow ? 1.5 : 6}>
            <FormikTextField
              label='Other Underlying Illness'
              formikField={formikFields.decedentOtherUnderlyingIllness}
              authorizedToEdit={authorizedToEdit}
            />
          </Grid>
        )}
      {displayConfig.decedentDateOfDeath && (
        <CaseViewDateTimeField
          xs={12}
          md={3}
          label='Date of Death'
          authorizedToEdit={authorizedToEdit}
          fieldName={formikFields.decedentDateOfDeath}
          sm={undefined}
          lg={undefined}
          xl={undefined}
          sx={undefined}
        />
      )}
      {displayConfig.decedentDrugToxicityConfirmed && (
        <Grid item xs={12} sm={4} md='auto'>
          <FormControl component='fieldset'>
            <FormLabel>Drug Toxicity Confirmed</FormLabel>
            <RadioGroup
              name={formikFields.decedentDrugToxicityConfirmed}
              value={
                decedentDrugToxicityConfirmedValue != null
                  ? decedentDrugToxicityConfirmedValue
                  : null
              }
              onChange={e => {
                formik.handleChange(e);
                setUnsavedChanges();
              }}
              row
            >
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value={true}
                control={<Radio />}
                label='Yes'
              />
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value={false}
                control={<Radio />}
                label='No'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {displayConfig.decedentInfectiousDiseaseConfirmed && (
        <Grid item xs={12} sm={4} md='auto'>
          <FormControl component='fieldset'>
            <FormLabel>
              <Breadcrumbs>
                <Typography color='text.secondary'>Infectious Disease Confirmed</Typography>
              </Breadcrumbs>
            </FormLabel>
            <RadioGroup
              name={formikFields.decedentInfectiousDiseaseConfirmed}
              value={
                decedentInfectiousDiseaseConfirmedValue != null
                  ? decedentInfectiousDiseaseConfirmedValue
                  : null
              }
              onChange={e => {
                formik.handleChange(e);
                setUnsavedChanges();
              }}
              row
            >
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value={true}
                control={<Radio />}
                label='Yes'
              />
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value={false}
                control={<Radio />}
                label='No'
              />
            </RadioGroup>
            {(decedentInfectiousDiseaseConfirmedValue === true ||
              decedentInfectiousDiseaseConfirmedValue === 'true') && (
              <TextField
                fullWidth
                id='casePathology.otherInfectiousDiseaseConfirmed'
                name='casePathology.otherInfectiousDiseaseConfirmed'
                label='Disease name'
                size='small'
                value={formik.values?.casePathology?.otherInfectiousDiseaseConfirmed || ''}
                onChange={formik.handleChange}
                onBlur={setUnsavedChanges}
                disabled={!formik.status.editing || !authorizedToEdit}
              />
            )}
          </FormControl>
        </Grid>
      )}
      {displayConfig.decedentOpiodConfirmed && (
        <Grid item xs={12} sm={4} md='auto'>
          <FormControl component='fieldset'>
            <FormLabel>
              <Breadcrumbs>
                <Typography color='text.secondary'>Opioid Death Confirmed</Typography>
              </Breadcrumbs>
            </FormLabel>
            <RadioGroup
              name={formikFields.decedentOpiodConfirmed}
              value={decedentOpiodConfirmedValue != null ? decedentOpiodConfirmedValue : null}
              onChange={e => {
                formik.handleChange(e);
                setUnsavedChanges();
              }}
              row
            >
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value={true}
                control={<Radio />}
                label='Yes'
              />
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value={false}
                control={<Radio />}
                label='No'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
}
