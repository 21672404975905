import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Grid,
  Button,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Divider,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Formik, Form } from 'formik';
import axios from 'axios';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { NotificationTemplateBodyTags } from '../../../CMSAdmin/Menu/constants';
import { useNavigate } from 'react-router';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  DialogContentText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import authHeader from '../../../../services/auth-header';
import { GlobalContext } from '../../../../Context/GlobalContext';
import Breadcrumb from '../../../../components/AdministrationModules/Breadcrumb';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEditTemplateView = () => {
  const { notificationDetails } = useContext(GlobalContext);
  let existingNotification = notificationDetails;
  const { notificationRecipientLineSeq, assignmentTypeSeq, userInfoSeq } =
    existingNotification?.[0]?.notificationTemplateRecipient?.[0] ?? {};
  const { userSeq, userOnUserInfoSeq } =
    existingNotification?.[0]?.notificationTemplateRecipient?.[0]?.userOnUserInfo?.[0] ?? {};
  const {
    body,
    countyName,
    countySeq,
    deliveryTypeName,
    id,
    notificationDeliveryTypeSeq,
    notificationTemplateName,
    notificationTemplateRecipient,
    notificationTemplateSeq,
    publiclyAvailable,
    status,
    subject,
  } = existingNotification?.[0] ?? {};
  const [usersExist, setUsersExist] = useState({});
  const navigate = useNavigate();
  const [nodePermission, setNodePermission] = useState({});

  const ReceipientLine = {
    to: '19528134-4320-4686-b270-fff10269be01',
    cc: '19528134-4320-4686-b270-fff10269be02',
    bcc: '19528134-4320-4686-b270-fff10269be03',
  };

  const fetchExistingTypeSeq = lineSequence => {
    const notificationTemplate = existingNotification?.[0]?.notificationTemplateRecipient?.filter(
      notificationType => notificationType?.notificationRecipientLineSeq === lineSequence
    );
    return notificationTemplate?.[0] ?? {};
  };

  const guid = '00000000-0000-0000-0000-000000000000';
  const [userInformation, setUserInformation] = useState([]);
  const [notification, setNotification] = useState({
    body: body,
    notificationTemplateName: notificationTemplateName,
    countyName: countyName,
    countySeq: countySeq,
    deliveryTypeName: deliveryTypeName,
    countyOptions: [],
    notificationTemplateRecipient: notificationTemplateRecipient,
    notificationDeliveryTypeSeq: notificationDeliveryTypeSeq,
    notificationRecipientLineSeq: notificationRecipientLineSeq,
    userInfoSeq: userInfoSeq,
    assignmentTypeSeq: assignmentTypeSeq,
    notificationRecipientType: [],
    userOnUserInfoSeq: userOnUserInfoSeq,
    notificationRecipientTypeSeq: fetchExistingTypeSeq(ReceipientLine?.['to'])
      ?.notificationRecipientTypeSeq,
    notificationRecipientTypeSeqCc: fetchExistingTypeSeq(ReceipientLine?.['cc'])
      ?.notificationRecipientTypeSeq,
    notificationRecipientTypeSeqBcc: fetchExistingTypeSeq(ReceipientLine?.['bcc'])
      ?.notificationRecipientTypeSeq,
    userCounty: [],
    userSeq: userSeq,
    emailToDept: fetchExistingTypeSeq(ReceipientLine?.['to'])?.assignmentTypeSeq,
    emailCcDept: fetchExistingTypeSeq(ReceipientLine?.['cc'])?.assignmentTypeSeq,
    emailBccDept: fetchExistingTypeSeq(ReceipientLine?.['bcc'])?.assignmentTypeSeq,
    departmentOptions: [],
    subject: subject,
    toUsersEmail: [],
    cCUsersEmail: [],
    bCCUsersEmail: [],
    currentBccUsersEmail: [],
    currentCcUsersEmail: [],
    currentUsersEmail: [],
  });

  const [showApi, setShowApi] = useState();
  const [openapi, setOpenapi] = useState(false);

  useEffect(() => {
    if (existingNotification && notification?.userCounty.length > 0) {
      fetchUsersName();
    }
  }, [existingNotification, notification?.userCounty]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const selectedTep = notificationDetails;
  const [textBody, setTextBody] = useState(selectedTep?.[0]?.body);
  const [subjectCursor, setSubjectCursor] = useState(0);
  const [subjectBody, setSubjectBody] = useState(notification?.subject ?? '');
  let bodyCursorPos = 0;

  const updateBodyCursor = e => {
    bodyCursorPos = e.target.selectionStart;
  };

  const onBodySelect = e => {
    const bodyTag = e.target.value;
    const updatedBody = insertAtIndex(textBody, bodyTag, bodyCursorPos);
    setTextBody(updatedBody);
  };

  const onSubjectSelect = e => {
    const bodyTag = e.target.value;
    const updatedBody = insertAtIndex(notification?.subject, bodyTag, subjectCursor);
    setNotification(prev => {
      return {
        ...prev,
        subject: updatedBody,
      };
    });
    setSubjectBody(updatedBody);
  };

  const insertAtIndex = (str, substring, index) => {
    if (!str) return '';
    return str.slice(0, index) + substring + str.slice(index);
  };
  

  const fetchAllDetails = async () => {
    const county = await axios.get(`${import.meta.env.VITE_API_URL}GetAllCounty`, {
      headers: authHeader(),
    });
    const assignmentType = await axios.get(
      `${import.meta.env.VITE_API_URL}GetMasterData?tableName=AssignmentType`,
      { headers: authHeader() }
    );
    const notificationRecipientType = await axios.get(
      `${import.meta.env.VITE_API_URL}GetMasterData?tableName=NotificationRecipientType`,
      { headers: authHeader() }
    );
    const userCountyDetails = await axios.get(
      `${import.meta.env.VITE_API_URL}GetUserCountyDetails`,
      { headers: authHeader() }
    );
    const notificationLine = await axios.get(
      `${import.meta.env.VITE_API_URL}GetMasterData?tableName=notificationRecipientLine`,
      { headers: authHeader() }
    );
    const filterCountyOptions = county.data.data[0].children.map(county => {
      return { countyName: county.countyName, countySeq: county.countySeq };
    });

    const activeNotificationReceipts = notificationRecipientType.data.data.filter(
      receipt => receipt.status === 'Active'
    );

    const userCounty = userCountyDetails.data.data?.map(userCounty => {
      const mappedCounty = {
        userName: userCounty.username,
        userSeq: userCounty.userseq,
      };
      return mappedCounty;
    });

    //set Department Data
    const filterDepartment = assignmentType.data.data.map(dept => {
      const updatedDept = {
        departmentName: dept.assignmentTypeName,
        departmentSeq: dept.assignmentTypeSeq,
      };
      return updatedDept;
    });

    setNotification(prev => {
      return {
        ...prev,
        departmentOptions: filterDepartment,
      };
    });

    setNotification(prev => {
      return {
        ...prev,
        countyOptions: filterCountyOptions,
        notificationRecipientType: activeNotificationReceipts,
        userCounty: userCounty,
      };
    });
  };

  const handleMultiUsers = (event, type) => {
    const {
      target: { value },
    } = event;

    if (type === 'to') {
      setNotification(prev => {
        return {
          ...prev,
          toUsersEmail: typeof value === 'string' ? value.split(',') : value,
        };
      });
    } else if (type === 'cc') {
      setNotification(prev => {
        return {
          ...prev,
          cCUsersEmail: typeof value === 'string' ? value.split(',') : value,
        };
      });
    } else {
      setNotification(prev => {
        return {
          ...prev,
          bCCUsersEmail: typeof value === 'string' ? value.split(',') : value,
        };
      });
    }
  };

  useEffect(() => {
    fetchAllDetails();
    // if (permissions?.['Notification Template']?.accessProvided && permissions?.['Notification Template']?.accessProvided !== '') {
    //     setNodePermission(permissions?.['Notification Template']);
    // } else if (permissions?.['Notification']?.accessProvided && permissions?.['Notification']?.accessProvided !== '') {
    //     setNodePermission(permissions?.['Notification']);
    // }
  }, []);

  const fetchUsersName = () => {
    //map the sequences to the users and push to object

    let usersObject = {};
    existingNotification?.[0]?.notificationTemplateRecipient?.forEach(
      (notificationFromRedux, index) => {
        let userNameInUserInfo = [];
        let multiUsersInformation = {};

        if (notificationFromRedux?.userOnUserInfo) {
          for (let user of notification?.userCounty) {
            for (let userOfUserInfo of notificationFromRedux?.userOnUserInfo) {
              if (user?.userSeq == userOfUserInfo?.userSeq && userOfUserInfo?.status === 'Active') {
                userNameInUserInfo.push(user?.userName);
                multiUsersInformation = {
                  ...multiUsersInformation,
                  [user?.userName]: userOfUserInfo?.userOnUserInfoSeq,
                };
              }
            }
          }
        }
        usersObject = {
          ...usersObject,
          [notificationFromRedux?.notificationRecipientLineSeq]: multiUsersInformation,
        };
        userNameInUserInfo = userNameInUserInfo.filter(
          (item, index) => userNameInUserInfo.indexOf(item) === index
        );

        setUsersExist(usersObject);

        if (notificationFromRedux?.notificationRecipientLineSeq === ReceipientLine?.['to']) {
          setNotification(prev => {
            return {
              ...prev,
              toUsersEmail: userNameInUserInfo,
              currentUsersEmail: userNameInUserInfo,
              toUsersInformation: notificationFromRedux,
            };
          });
        } else if (notificationFromRedux?.notificationRecipientLineSeq === ReceipientLine?.['cc']) {
          setNotification(prev => {
            return {
              ...prev,
              cCUsersEmail: userNameInUserInfo,
              currentCcUsersEmail: userNameInUserInfo,
              ccUsersInformation: notificationFromRedux,
            };
          });
        } else {
          setNotification(prev => {
            return {
              ...prev,
              bCCUsersEmail: userNameInUserInfo,
              currentBccUsersEmail: userNameInUserInfo,
              bccUsersInformation: notificationFromRedux,
            };
          });
        }
      }
    );
  };

  //utility function to add notificaiton template recipient payload
  const checkStatus = (notificationUsers, currentUser) => {
    const { users, currentUsers } = notificationUsers ?? {};
    if (currentUsers?.includes(currentUser) && !users?.includes(currentUser)) {
      return false;
    } else return true;
  };

  const addUserInfo = notificationUsers => {
    let userInfo = [];
    const removeDuplicatedUsers = notificationUsers?.changedAndApiUsers?.filter(
      (user, index) => notificationUsers?.changedAndApiUsers?.indexOf(user) === index
    );

    for (let userCounty of notification?.userCounty) {
      for (let userName of removeDuplicatedUsers) {
        if (userCounty?.userName == userName) {
          let userOnUserInfoSequence = usersExist?.[notificationUsers?.lineSeq]?.[userName];

          if (!userOnUserInfoSequence) {
            userOnUserInfoSequence = guid;
          }
          userInfo?.push({
            userseq: userCounty?.userSeq ?? guid,
            userInfoSeq: fetchExistingTypeSeq(notificationUsers?.lineSeq)?.userInfoSeq ?? guid,
            status: checkStatus(notificationUsers, userName) ? 'Active' : 'InActive',
            userOnUserInfoSeq: userOnUserInfoSequence,
          });
        }
      }
    }
    setUserInformation(userInfo);

    return userInfo;
  };

  const addNotificationTemplate = () => {
    const {
      notificationRecipientTypeSeq,
      notificationRecipientTypeSeqCc,
      notificationRecipientTypeSeqBcc,
    } = notification ?? {};
    const notificationReceipt = [
      notificationRecipientTypeSeq,
      notificationRecipientTypeSeqCc,
      notificationRecipientTypeSeqBcc,
    ];
    const notificationLength = notificationReceipt?.filter(item => !!item)?.length;
    let i = 0;
    let notificationTemplateList = [];

    const mapData = {
      0: {
        recipientType: notification?.notificationRecipientTypeSeq ?? guid,
        assignmentType: notification?.emailToDept ?? guid,
        users: notification?.toUsersEmail,
        currentUsers: notification?.currentUsersEmail,
        lineSeq: ReceipientLine?.['to'],
        userInfoSeq: fetchExistingTypeSeq(ReceipientLine?.['to'])?.userInfoSeq ?? guid,
        notificationTemplateSeq:
          fetchExistingTypeSeq(ReceipientLine?.['to'])?.notificationTemplateSeq ?? guid,
        notificationTemplateRecipientSeq:
          fetchExistingTypeSeq(ReceipientLine?.['to'])?.notificationTemplateRecipientSeq ?? guid,
        index: 0,
        changedAndApiUsers: [...notification?.toUsersEmail, ...notification?.currentUsersEmail],
      },
      1: {
        recipientType: notification?.notificationRecipientTypeSeqCc ?? guid,
        assignmentType: notification?.emailCcDept ?? guid,
        users: notification?.cCUsersEmail,
        currentUsers: notification?.currentCcUsersEmail,
        lineSeq: ReceipientLine?.['cc'],
        userInfoSeq: fetchExistingTypeSeq(ReceipientLine?.['cc'])?.userInfoSeq ?? guid,
        notificationTemplateSeq:
          fetchExistingTypeSeq(ReceipientLine?.['cc'])?.notificationTemplateSeq ?? guid,
        notificationTemplateRecipientSeq:
          fetchExistingTypeSeq(ReceipientLine?.['cc'])?.notificationTemplateRecipientSeq ?? guid,
        index: 1,
        changedAndApiUsers: [...notification?.cCUsersEmail, ...notification?.currentCcUsersEmail],
      },
      2: {
        recipientType: notification?.notificationRecipientTypeSeqBcc ?? guid,
        assignmentType: notification?.emailBccDept ?? guid,
        users: notification?.bCCUsersEmail,
        currentUsers: notification?.currentBccUsersEmail,
        lineSeq: ReceipientLine?.['bcc'],
        userInfoSeq: fetchExistingTypeSeq(ReceipientLine?.['bcc'])?.userInfoSeq ?? guid,
        notificationTemplateSeq:
          fetchExistingTypeSeq(ReceipientLine?.['bcc'])?.notificationTemplateSeq ?? guid,
        notificationTemplateRecipientSeq:
          fetchExistingTypeSeq(ReceipientLine?.['bcc'])?.notificationTemplateRecipientSeq ?? guid,
        index: 2,
        changedAndApiUsers: [...notification?.bCCUsersEmail, ...notification?.currentBccUsersEmail],
      },
    };

    const fetchUserInfoSeq = index => {
      const sequence = Object.keys(ReceipientLine)?.filter(
        (receipient, position) => position === index
      );

      return mapData?.[index]?.userInfoSeq;
    };

    while (i < notificationLength) {
      const response = addUserInfo(mapData?.[i]);
      const receipientLine = Object.values(ReceipientLine);
      notificationTemplateList?.push({
        notificationTemplateSeq: mapData?.[i]?.notificationTemplateSeq,
        notificationRecipientTypeSeq: mapData?.[i]?.recipientType,
        notificationRecipientLineSeq: mapData?.[i]?.lineSeq,
        assignmentTypeSeq: mapData[i].assignmentType,
        notificationTemplateRecipientSeq: mapData[i]?.notificationTemplateRecipientSeq,
        userInfoSeq: fetchUserInfoSeq(i),
        status: 'Active',
        userOnUserInfo: response,
      });

      i++;
    }
    return notificationTemplateList;
  };

  const saveData = async e => {
    const { notificationTemplateName, countySeq, subject } = notification;
    const deliveryTypeSequence = '8a4373ce-be9c-4c2a-aa10-56441b6cdc01';
    e.preventDefault();

    const notificationData = [
      {
        notificationTemplateSeq: notificationTemplateSeq ?? id,
        notificationDeliveryTypeSeq: notificationDeliveryTypeSeq ?? deliveryTypeSequence,
        notificationTemplateName: notificationTemplateName,
        subject: subject,
        body: textBody,
        status: 'Active',
        publiclyAvailable: publiclyAvailable ?? 'True',
        countySeq: countySeq ?? guid,
        notificationTemplateRecipient: addNotificationTemplate(),
      },
    ];

    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}SaveMasterData?tableName=notificationTemplate`,
      notificationData,
      { headers: authHeader() }
    );
    if (response.data.status == 0) {
      setTimeout(() => {
        navigate('/sysadmin/notificationtemplate');
      }, [1500]);
    }
    setOpenapi(true);
    setShowApi(response.data.message);
  };

  const [caseVariable, setCaseVariable] = useState('');
  const [subjectCaseVariable, setSubjectCaseVariable] = useState('');

  const handleBodySelect = selected => {
    setTextBody(selected.target.value);
    setCaseVariable(selected.target.value);
    onBodySelect(selected);
  };

  const handleSubjectSelect = selected => {
    setSubjectBody(selected.target.value);
    setSubjectCaseVariable(selected.target.value);
    onSubjectSelect(selected);
  };

  // if (!nodePermission?.view) {
  //     return <AccessDenied />;
  // } else {
  const MENU_OBJECT = {
    Home: '/home',
    'Notificaton Template': '/sysadmin/notificationtemplate',
    'Add Edit Notification': '',
  };

  return (
    <>
      {/* // <div className='container my-5 py-5'> */}
      <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {' '}
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0, mb: 3 }} />
              <Formik onSubmit={saveData} enableReinitialize>
                {() => (
                  <Form onSubmit={saveData}>
                    <MainCard
                      sx={{ border: 'none', boxShadow: 'none' }}
                      title={
                        existingNotification.length > 0 ? (
                          'Modify Notification Template'
                        ) : (
                          <strong>Enter New Template</strong>
                        )
                      }
                      secondary={
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            sx={{ mr: 2 }}
                            onClick={() => {
                              window.location.reload();
                            }}
                            // disabled={!nodePermission?.add}
                          >
                            {' '}
                            Add
                          </Button>

                          <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            // disabled={
                            //   existingNotification.length > 0
                            //     ? !nodePermission?.edit
                            //     : !nodePermission?.add
                            // }
                          >
                            {' '}
                            Save
                          </Button>
                        </div>
                      }
                    >
                      <div className='modal'>
                        <Dialog
                          open={openapi}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={() => setOpenapi(false)}
                          aria-describedby='alert-dialog-slide-description'
                        >
                          <DialogTitle></DialogTitle>
                          <DialogContent>
                            <DialogContentText
                              id='alert-dialog-slide-description'
                              // style={{ color: isErrorShowApi ? 'red' : '' }}
                            >
                              {showApi}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <IconButton onClick={() => setOpenapi(false)}>
                              <CloseIcon />
                            </IconButton>
                          </DialogActions>
                        </Dialog>
                      </div>

                      {/* <Typography
                variant='h5'
                color='#349cec'
                sx={{ fontWeight: 'bold' }}
              >
                {existingNotification.length > 0
                  ? 'Modify Notification Template'
                  : 'Enter New Template'}
              </Typography> */}
                      <br />
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4} sm={12} lg={4}>
                            <FormControl fullWidth sx={{ minWidth: '100%' }}>
                              <InputLabel id='demo-simple-select-label'>Delivery Type</InputLabel>
                              <Select
                                name='deliveryType'
                                defaultValue={notification.deliveryTypeName ?? 'E-mail'}
                                value={notification.deliveryTypeName ?? 'E-mail'}
                                label='Delivery Type'
                                required
                                disabled
                              >
                                <MenuItem
                                  value={notification.deliveryTypeName ?? 'E-mail'}
                                  name={notification.deliveryTypeName ?? 'E-mail'}
                                >
                                  {notification.deliveryTypeName ?? 'E-mail'}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} sm={12} lg={4}>
                            <FormControl sx={{ minWidth: '100%' }}>
                              <TextField
                                id='outlined-basic'
                                label='Template Name'
                                variant='outlined'
                                required
                                name='notificationTemplateName'
                                value={notification.notificationTemplateName}
                                onChange={e => {
                                  setNotification(prev => {
                                    return {
                                      ...prev,
                                      notificationTemplateName: e.target.value,
                                    };
                                  });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} sm={12} lg={4}>
                            <FormControl fullWidth sx={{ minWidth: '100%' }}>
                              <InputLabel id='demo-simple-select-label'>Jurisdiction</InputLabel>
                              <Select
                                displayEmpty
                                name='county'
                                value={notification.countySeq}
                                label='Jurisdiction'
                                onChange={e =>
                                  setNotification(prev => {
                                    return { ...prev, countySeq: e.target.value };
                                  })
                                }
                              >
                                {notification.countyOptions
                                  ? notification.countyOptions.map(county => {
                                      return (
                                        <MenuItem value={county.countySeq} name={county.countyName}>
                                          {county.countyName}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Box sx={{ mt: 4, minWidth: '98%', ml: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4} sm={12} lg={4}>
                                <FormControl fullWidth sx={{ minWidth: '100%' }}>
                                  <InputLabel id='demo-simple-select-label' required>
                                    To
                                  </InputLabel>
                                  <Select
                                    displayEmpty
                                    name='to'
                                    value={notification.notificationRecipientTypeSeq ?? ''}
                                    label='To'
                                    required
                                    onChange={e =>
                                      setNotification(prev => {
                                        return {
                                          ...prev,
                                          notificationRecipientTypeSeq: e.target.value,
                                        };
                                      })
                                    }
                                  >
                                    {notification.notificationRecipientType
                                      ? notification.notificationRecipientType.map(
                                          notificationReceipt => {
                                            return (
                                              <MenuItem
                                                value={
                                                  notificationReceipt?.notificationRecipientTypeSeq ??
                                                  ''
                                                }
                                                name={
                                                  notificationReceipt?.notificationRecipientTypeName ??
                                                  ''
                                                }
                                              >
                                                {notificationReceipt?.notificationRecipientTypeName}
                                              </MenuItem>
                                            );
                                          }
                                        )
                                      : null}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12} sm={12} lg={4}>
                                {notification.userCounty ? (
                                  <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id='demo-multiple-checkbox-label' required>
                                      To User
                                    </InputLabel>
                                    <Select
                                      labelId='demo-multiple-checkbox-label'
                                      id='demo-multiple-checkbox'
                                      multiple
                                      value={notification.toUsersEmail ?? []}
                                      onChange={e => handleMultiUsers(e, 'to')}
                                      input={<OutlinedInput label='To User' />}
                                      renderValue={selected => selected.join(', ')}
                                      MenuProps={MenuProps}
                                    >
                                      {notification.userCounty.map(county => (
                                        <MenuItem
                                          key={county.userSeq}
                                          value={county.userName}
                                          name={county.userName}
                                        >
                                          <Checkbox
                                            checked={
                                              notification?.toUsersEmail?.indexOf(county.userName) >
                                              -1
                                            }
                                          />
                                          <ListItemText primary={county.userName} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={12} sm={12} lg={4}>
                                <FormControl fullWidth sx={{ width: '100%' }}>
                                  <InputLabel id='demo-simple-select-label' required>
                                    Department
                                  </InputLabel>
                                  <Select
                                    displayEmpty
                                    name='department'
                                    value={notification.emailToDept ?? ''}
                                    label='Department'
                                    required
                                    onChange={e => {
                                      setNotification(prev => {
                                        return {
                                          ...prev,
                                          emailToDept: e.target.value,
                                        };
                                      });
                                    }}
                                  >
                                    {notification.departmentOptions
                                      ? notification.departmentOptions.map(dept => {
                                          return (
                                            <MenuItem
                                              value={dept?.departmentSeq ?? ''}
                                              name={dept?.departmentName ?? ''}
                                            >
                                              {dept?.departmentName}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box sx={{ flexGrow: 1, mt: 4, ml: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12} sm={12} lg={4}>
                                <FormControl fullWidth sx={{ width: '100%' }}>
                                  <InputLabel id='demo-simple-select-label'>CC</InputLabel>
                                  <Select
                                    displayEmpty
                                    name='cc'
                                    value={notification.notificationRecipientTypeSeqCc ?? ''}
                                    label='CC'
                                    onChange={e =>
                                      setNotification(prev => {
                                        return {
                                          ...prev,
                                          notificationRecipientTypeSeqCc: e.target.value,
                                        };
                                      })
                                    }
                                  >
                                    {notification.notificationRecipientType
                                      ? notification.notificationRecipientType.map(
                                          notificationReceipt => {
                                            return (
                                              <MenuItem
                                                value={
                                                  notificationReceipt?.notificationRecipientTypeSeq ??
                                                  ''
                                                }
                                                name={
                                                  notificationReceipt?.notificationRecipientTypeName ??
                                                  ''
                                                }
                                              >
                                                {notificationReceipt?.notificationRecipientTypeName}
                                              </MenuItem>
                                            );
                                          }
                                        )
                                      : null}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12} sm={12} lg={4}>
                                {notification.userCounty ? (
                                  <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id='demo-multiple-checkbox-label'>
                                      CC User
                                    </InputLabel>
                                    <Select
                                      labelId='demo-multiple-checkbox-label'
                                      id='demo-multiple-checkbox'
                                      multiple
                                      value={notification.cCUsersEmail ?? ''}
                                      onChange={e => handleMultiUsers(e, 'cc')}
                                      input={<OutlinedInput label='CC User' />}
                                      renderValue={selected => selected.join(', ')}
                                      MenuProps={MenuProps}
                                    >
                                      {notification.userCounty.map(county => (
                                        <MenuItem key={county.userSeq} value={county.userName}>
                                          <Checkbox
                                            checked={
                                              notification?.cCUsersEmail?.indexOf(county.userName) >
                                              -1
                                            }
                                          />
                                          <ListItemText primary={county.userName} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={12} sm={12} lg={4}>
                                <FormControl fullWidth sx={{ minWidth: '100%' }}>
                                  <InputLabel id='demo-simple-select-label'>Department</InputLabel>
                                  <Select
                                    displayEmpty
                                    name='department'
                                    value={notification.emailCcDept ?? ''}
                                    label='Department'
                                    required={
                                      notification?.notificationRecipientTypeSeqCc ? true : false
                                    }
                                    onChange={e => {
                                      setNotification(prev => {
                                        return {
                                          ...prev,
                                          emailCcDept: e.target.value,
                                        };
                                      });
                                    }}
                                  >
                                    {notification.departmentOptions
                                      ? notification.departmentOptions.map(dept => {
                                          return (
                                            <MenuItem
                                              value={dept?.departmentSeq ?? ''}
                                              name={dept?.departmentName ?? ''}
                                            >
                                              {dept?.departmentName}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                      <Box sx={{ flexGrow: 1, mt: 4 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} sm={12} lg={4}>
                            <FormControl fullWidth sx={{ width: '100%' }}>
                              <InputLabel id='demo-simple-select-label'>BCC</InputLabel>
                              <Select
                                displayEmpty
                                name='bcc'
                                value={notification.notificationRecipientTypeSeqBcc ?? ''}
                                label='BCC'
                                onChange={e =>
                                  setNotification(prev => {
                                    return {
                                      ...prev,
                                      notificationRecipientTypeSeqBcc: e.target.value,
                                    };
                                  })
                                }
                              >
                                {notification.notificationRecipientType
                                  ? notification.notificationRecipientType.map(
                                      notificationReceipt => {
                                        return (
                                          <MenuItem
                                            value={
                                              notificationReceipt?.notificationRecipientTypeSeq ??
                                              ''
                                            }
                                            name={
                                              notificationReceipt?.notificationRecipientTypeName ??
                                              ''
                                            }
                                          >
                                            {notificationReceipt?.notificationRecipientTypeName}
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : null}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} sm={12} lg={4}>
                            {notification.userCounty ? (
                              <FormControl sx={{ width: '100%' }}>
                                <InputLabel id='demo-multiple-checkbox-label'>BCC User</InputLabel>
                                <Select
                                  labelId='demo-multiple-checkbox-label'
                                  id='demo-multiple-checkbox'
                                  multiple
                                  value={notification.bCCUsersEmail ?? ''}
                                  onChange={e => handleMultiUsers(e, 'bcc')}
                                  input={<OutlinedInput label='Bcc User' />}
                                  renderValue={selected => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {notification.userCounty.map(county => (
                                    <MenuItem key={county.userSeq} value={county.userName}>
                                      <Checkbox
                                        checked={
                                          notification?.bCCUsersEmail?.indexOf(county.userName) > -1
                                        }
                                      />
                                      <ListItemText primary={county.userName} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} md={12} sm={12} lg={4}>
                            <FormControl fullWidth sx={{ width: '100%' }}>
                              <InputLabel id='demo-simple-select-label'>Department</InputLabel>
                              <Select
                                displayEmpty
                                name='department'
                                value={notification.emailBccDept ?? ''}
                                label='Department'
                                required={
                                  notification?.notificationRecipientTypeSeqBcc ? true : false
                                }
                                onChange={e => {
                                  setNotification(prev => {
                                    return {
                                      ...prev,
                                      emailBccDept: e.target.value,
                                    };
                                  });
                                }}
                              >
                                {notification.departmentOptions
                                  ? notification.departmentOptions.map(dept => {
                                      return (
                                        <MenuItem
                                          value={dept?.departmentSeq ?? ''}
                                          name={dept?.departmentName ?? ''}
                                        >
                                          {dept?.departmentName}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Box sx={{ display: 'flex', marginTop: '2%', marginLeft: '-0.5%' }}>
                          <Box>
                            <FormControl
                              sx={{
                                m: 1,
                                minWidth: 200,
                                fontFamily: 'Roboto , Helvetica , Arial , sans-serif !important',
                              }}
                            >
                              <InputLabel id='demo-simple-select-label'>Case Variable</InputLabel>
                              <Select
                                label='Case Variable'
                                name='body'
                                value={subjectCaseVariable}
                                onChange={handleSubjectSelect}
                                style={{ float: 'right' }}
                              >
                                {NotificationTemplateBodyTags.map(ele => {
                                  return (
                                    <MenuItem key={ele.value} value={ele.value} name={ele.title}>
                                      {ele.title}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ width: '100%' }}>
                            <FormControl sx={{ m: 1, width: '100%' }}>
                              <TextField
                                name='Subject'
                                label='Subject'
                                variant='outlined'
                                focused
                                value={notification?.subject}
                                fullWidth
                                required
                                onKeyUp={e => setSubjectCursor(e.target.selectionStart)}
                                onMouseUp={e => setSubjectCursor(e.target.selectionStart)}
                                onChange={e =>
                                  setNotification(prev => {
                                    return {
                                      ...prev,
                                      subject: e.target.value,
                                    };
                                  })
                                }
                              />
                            </FormControl>
                          </Box>
                        </Box>
                        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                        <Grid container mt={{ lg: 5, sm: 2, md: 4, xs: 2 }}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div style={{ marginTop: '1%' }}>
                              <label htmlFor='body' required>
                                <Typography
                                  variant='h5'
                                  // color='#349cec'
                                  sx={{ fontWeight: 'bold' }}
                                  required
                                >
                                  <div style={{ display: 'flex' }}>
                                    Body <p style={{ color: '#FF0000', marginLeft: '10px' }}>*</p>
                                  </div>
                                </Typography>
                              </label>
                            </div>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormControl
                              fullWidth
                              sx={{
                                mb: 4,
                                width: '100%',
                                fontFamily: 'Roboto , Helvetica , Arial , sans-serif !important',
                              }}
                            >
                              <InputLabel id='demo-simple-select-label'>Case Variable</InputLabel>
                              <Select
                                label='Case Variable'
                                name='body'
                                value={caseVariable}
                                onChange={handleBodySelect}
                                style={{ float: 'right' }}
                              >
                                {NotificationTemplateBodyTags.map(ele => {
                                  return (
                                    <MenuItem key={ele.value} value={ele.value} name={ele.title}>
                                      {ele.title}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <textarea
                          id='w3review'
                          name='body'
                          rows='15'
                          cols='50'
                          required
                          style={{ width: '100%' }}
                          onChange={e => setTextBody(e.target.value)}
                          value={textBody}
                          onKeyUp={e => updateBodyCursor(e)}
                          onMouseUp={e => updateBodyCursor(e)}
                        ></textarea>
                      </Box>
                    </MainCard>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* // </div> */}
    </>
  );
  // }
};
export default AddEditTemplateView;
