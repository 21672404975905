import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from '../../../../../../utils/auth/AuthService';
import { useConversation } from '../ConversationsContext';
import { ConversationTypes } from '../conversation.types';

interface NewConversationProps {
  open?: boolean;
  onClose?: () => void;
  isDialog?: boolean;
}

export type ConversationUser = {
  userSeq: string;
  userName: string;
  personFirstName: string;
  personLastName: string;
};

export function NewConversation({ open = true, onClose, isDialog = true }: NewConversationProps) {
  const { user } = useAuth();
  const location = useLocation();
  const { createChat, creatingConversation, supportAgentName, availableUsers } = useConversation();
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');
  const [participants, setParticipants] = useState<ConversationUser[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAttachFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      setAttachments(prev => [...prev, ...newFiles]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const isFeedbackChat = (p: ConversationUser[]) => {
    if (p.findIndex(u => u.userSeq === 'Support') > -1) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!topic.trim()) return;

    try {
      setIsLoading(true);
      // await createConversation({
      //   topic,
      //   message,
      //   conversationType: isFeedbackChat(participants)
      //     ? ConversationTypes.Feedback
      //     : ConversationTypes.Chat,
      //   attachments,
      //   participantUserSeqs: participants.map(p => p.userSeq),
      // });

      await createChat(
        {
          topic,
          messages: [
            {
              messageContent: message,
              sender: { userSeq: user?.userSeq },
              readBy: [{ userSeq: user?.userSeq }],
              attachments: attachments || [],
            },
          ],
          creator: {
            userSeq: user?.userSeq,
          },
          type: {
            conversationTypeSeq: isFeedbackChat(participants)
              ? ConversationTypes.Feedback
              : ConversationTypes.Chat,
          },
          isOpen: isFeedbackChat(participants) ? true : undefined,
          isActive: true,
          participants: [
            ...participants.filter(p => p.userSeq !== 'Support').map(p => ({ userSeq: p.userSeq })),
            { userSeq: user?.userSeq },
          ],
        },
        {
          caseId: location.state?.cmscaseid || null,
          pathname: location.pathname,
        }
      );
      setTopic('');
      setMessage('');
      setParticipants([]);
      setAttachments([]);
      if (onClose && isDialog) {
        onClose();
      }
    } catch (error) {
      console.error('Error creating chat:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortAutoCompleteOptions = (options: ConversationUser[]) => {
    return options.sort((a, b) => {
      // Support agent always comes first
      if (a.userSeq === 'Support') return -1;
      if (b.userSeq === 'Support') return 1;
      // For other users, sort by name
      return `${a.personFirstName} ${a.personLastName}`.localeCompare(
        `${b.personFirstName} ${b.personLastName}`
      );
    });
  };

  const content = (
    <Box component='div' sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box component='div' sx={{ position: 'relative', mb: 2 }}>
        <Typography variant='h6' component='h2'>
          New Chat
        </Typography>

        <Typography variant='subtitle1' component='h2' sx={{ mt: 1, color: 'text.secondary' }}>
          <strong>Need help? </strong>Start a chat with {supportAgentName}
        </Typography>
      </Box>
      <Autocomplete
        multiple
        options={sortAutoCompleteOptions(availableUsers)}
        getOptionLabel={(option: ConversationUser) => {
          if (option.userSeq === 'Support') {
            return supportAgentName || '';
          }
          return `${option.personFirstName} ${option.personLastName} (${option.userName})`;
        }}
        value={participants}
        onChange={(_, newValue) => setParticipants(newValue)}
        renderInput={params => <TextField {...params} label='To' placeholder='Enter a name' />}
        renderTags={(value: ConversationUser[], getTagProps) =>
          value.map((option, index) => {
            if (option.userSeq === 'Support') {
              return <Chip label={supportAgentName} {...getTagProps({ index })} />;
            }

            return (
              <Chip
                label={`${option.personFirstName} ${option.personLastName}`}
                {...getTagProps({ index })}
              />
            );
          })
        }
        disabled={creatingConversation}
      />
      <TextField
        label='Subject'
        value={topic}
        onChange={e => setTopic(e.target.value)}
        fullWidth
        required
        disabled={creatingConversation}
      />

      <TextField
        label='Message'
        value={message}
        onChange={e => setMessage(e.target.value)}
        multiline
        rows={4}
        fullWidth
        disabled={creatingConversation}
      />

      <Box component='div'>
        <input
          type='file'
          ref={fileInputRef}
          onChange={handleAttachFiles}
          style={{ display: 'none' }}
          multiple
          disabled={creatingConversation}
        />
      </Box>

      {attachments.length > 0 && (
        <Box component='div' sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {attachments.map((file, index) => (
            <Chip key={index} label={file.name} onDelete={() => handleRemoveAttachment(index)} />
          ))}
        </Box>
      )}

      <Box component='div' sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
        {onClose && isDialog && (
          <Button onClick={onClose} disabled={creatingConversation}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          variant='contained'
          disabled={!topic.trim() || isLoading || creatingConversation || participants.length <= 0}
        >
          Start Chat
        </Button>
      </Box>
    </Box>
  );

  if (isDialog) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }

  return (
    <Paper
      variant='outlined'
      elevation={0}
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        mx: { xs: 2, sm: 4 },
        mb: 2,
        maxWidth: '100%',
        margin: '0 auto',
        p: 2,
        mt: 2,
      }}
    >
      {content}
    </Paper>
  );
}
