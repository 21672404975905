import { DoneAll } from '@mui/icons-material';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { differenceInDays, isSameDay, parseISO } from 'date-fns';
import { User } from '../../../../types/User';
import { useAuth } from '../../../../utils/auth/AuthService';
import EnhancedRichTextEditor from '../../../../utils/components/EnhancedRichTextEditor';
import {
  ChatMessage,
  ChatParticipant,
  ChatParticipantHistory,
  ConversationTypes,
  shouldMaskNameAsSupportAgent,
} from './Conversations/conversation.types';
import { ConversationHeader } from './Conversations/ConversationHeader';
import { formatMessageDateAlwaysIncludeTime } from './Conversations/ConversationMessages';
import { useConversation } from './Conversations/ConversationsContext';
import MessageContent from './Conversations/MessageContent';
import { TypingIndicator } from './Conversations/TypingIndicator';

type MessageAreaProps = {
  DefaultComponent?: React.ReactNode;
  onBackClick: () => void;
  messagesEndRef: React.MutableRefObject<HTMLDivElement>;
};

type TimelineEvent =
  | { type: 'message'; timestamp: string; data: ChatMessage }
  | { type: 'participant'; timestamp: string; data: ChatParticipantHistory };

type EventGroup =
  | { type: 'message'; events: Array<{ type: 'message'; timestamp: string; data: ChatMessage }> }
  | {
      type: 'participant';
      events: Array<{ type: 'participant'; timestamp: string; data: ChatParticipantHistory }>;
    };

const getParticipantName = (
  participant: Partial<ChatParticipant>,
  conversationTypeSeq: string,
  user: User,
  supportAgentName: string
): string => {
  return shouldMaskNameAsSupportAgent({ sender: participant }, conversationTypeSeq, user)
    ? supportAgentName
    : `${participant.personFirstName} ${participant.personLastName} (${participant.userName})`;
};

const getParticipantEventMessage = (
  event: ChatParticipantHistory,
  conversationTypeSeq: string,
  user: User,
  supportAgentName: string
): string => {
  const participantName = getParticipantName(event, conversationTypeSeq, user, supportAgentName);
  const addedByName = event.addedBy
    ? getParticipantName(event.addedBy, conversationTypeSeq, user, supportAgentName)
    : null;
  const removedByName = event.removedBy
    ? getParticipantName(event.removedBy, conversationTypeSeq, user, supportAgentName)
    : null;

  if (event.removedBy) {
    if (event.removedBy.userSeq.toLowerCase() === event.userSeq.toLowerCase()) {
      return `${participantName} left`;
    }
    return `${removedByName} removed ${participantName}`;
  }

  // Handle history sharing information when a participant is added
  if (event.addedBy) {
    const historyAccess = getHistoryAccessMessage(
      event.participantCanViewMessageHistoryUpToTimestamp
    );
    return `${addedByName} added ${participantName}${historyAccess}`;
  }

  return `${participantName} joined the conversation`;
};

const getHistoryAccessMessage = (historyTimestamp?: string): string => {
  if (!historyTimestamp) {
    return '';
  }

  if (historyTimestamp === 'ALL') {
    return ' and shared all message history';
  }

  const daysAgo = differenceInDays(new Date(), parseISO(historyTimestamp));
  if (daysAgo === 0) {
    return '';
  } else if (daysAgo === 1) {
    return ' and shared message history from the last day';
  } else {
    return ` and shared message history from the last ${daysAgo} days`;
  }
};

const ParticipantEvent = ({
  event,
  supportAgentName,
  user,
  conversationTypeSeq,
}: {
  event: ChatParticipantHistory;
  supportAgentName: string;
  user: any;
  conversationTypeSeq: string;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        my: 1,
      }}
    >
      <Typography
        variant='body2'
        sx={{
          color: 'text.secondary',
          fontSize: '0.75rem',
          px: 2,
          py: 0.5,
          borderRadius: 4,
        }}
      >
        <Stack alignItems='center' spacing={0}>
          <div>{formatMessageDateAlwaysIncludeTime(event.timestamp)}</div>
          <div>
            {getParticipantEventMessage(event, conversationTypeSeq, user, supportAgentName)}
          </div>
        </Stack>
      </Typography>
    </Box>
  );
};

export const MessageArea = ({
  DefaultComponent = <></>,
  onBackClick = () => {},
  messagesEndRef,
}: MessageAreaProps) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedChat, supportAgentName, typingParticipants, handleMessageRead, sendMessage } =
    useConversation();

  if (!selectedChat) return DefaultComponent || null;

  const isSender = (m: ChatMessage) =>
    m.sender.userSeq.toLowerCase() === user.userSeq?.toLowerCase();

  // Combine messages and participant history events
  const allEvents: TimelineEvent[] = [
    ...selectedChat.messages.map(m => ({
      type: 'message' as const,
      timestamp: m.sentAtTimestamp,
      data: m as ChatMessage, // Assert that the message meets the full type requirements
    })),
    ...(selectedChat.participantHistory || [])
      .filter((p): p is ChatParticipantHistory => {
        // Filter out any participant history entries that don't have required fields
        return !!p && !!p.timestamp && !!p.chatParticipantHistorySeq;
      })
      .map(p => ({
        type: 'participant' as const,
        timestamp: p.timestamp,
        data: p,
      })),
  ].sort((a, b) => parseISO(a.timestamp).getTime() - parseISO(b.timestamp).getTime());

  // Group messages while keeping participant events separate
  const eventGroups = allEvents.reduce<EventGroup[]>((groups, event, index) => {
    if (event.type === 'participant') {
      groups.push({ type: 'participant', events: [event] });
      return groups;
    }

    const prevEvent = allEvents[index - 1];
    const lastGroup = groups[groups.length - 1];
    const shouldGroup =
      lastGroup?.type === 'message' &&
      prevEvent?.type === 'message' &&
      event.data.sender.userSeq === prevEvent.data.sender.userSeq &&
      isSameDay(parseISO(event.timestamp), parseISO(prevEvent.timestamp));

    if (shouldGroup && lastGroup?.type === 'message') {
      lastGroup.events.push(event as { type: 'message'; timestamp: string; data: ChatMessage });
    } else {
      groups.push({
        type: 'message',
        events: [event as { type: 'message'; timestamp: string; data: ChatMessage }],
      });
    }
    return groups;
  }, []);

  return (
    <>
      <ConversationHeader
        onBackClick={onBackClick}
        isMobile={isMobile}
        supportAgentName={supportAgentName}
      />
      <Box
        component='div'
        sx={{
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        }}
      >
        <Box
          component='div'
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
          style={{ transition: 'none' }}
        >
          {eventGroups.map((group, groupIndex) => {
            if (group.type === 'participant') {
              const event = group.events[0].data;
              return (
                <ParticipantEvent
                  key={`participant-${event.chatParticipantHistorySeq}`}
                  event={event}
                  supportAgentName={supportAgentName}
                  user={user}
                  conversationTypeSeq={selectedChat.type.conversationTypeSeq}
                />
              );
            }

            const firstMessage = group.events[0].data;
            const isUserSender = isSender(firstMessage);

            return (
              <Box
                key={firstMessage.messageSeq}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: isUserSender ? 'flex-end' : 'flex-start',
                }}
              >
                <Stack direction='row' spacing={1.5}>
                  {!isUserSender && (
                    <Typography variant='body2' color='text.secondary' sx={{ mb: 0.5 }}>
                      {shouldMaskNameAsSupportAgent(
                        firstMessage,
                        selectedChat.type.conversationTypeSeq,
                        user
                      )
                        ? supportAgentName
                        : `${firstMessage.sender.personFirstName} ${firstMessage.sender.personLastName}`}
                    </Typography>
                  )}
                  <Typography variant='body2' color='text.secondary' sx={{ mb: 0.5 }}>
                    {formatMessageDateAlwaysIncludeTime(firstMessage.sentAtTimestamp)}
                  </Typography>
                </Stack>

                {group.events.map(event => (
                  <Box
                    key={event.data.messageSeq}
                    sx={{
                      px: 1.5,
                      py: 1,
                      mb: 0.5,
                      borderRadius: 2.5,
                      bgcolor: isUserSender
                        ? user.isDarkMode
                          ? 'rgb(10, 132, 255)'
                          : 'rgb(0, 122, 255)'
                        : user.isDarkMode
                        ? 'rgb(58, 58, 60)'
                        : 'rgb(229, 229, 234)',
                      color: isUserSender
                        ? 'white'
                        : user.isDarkMode
                        ? 'rgb(229, 229, 234)'
                        : 'black',
                    }}
                  >
                    <MessageContent
                      message={event.data}
                      onMessageRead={messageSeq => handleMessageRead(messageSeq, user.userSeq)}
                    />
                  </Box>
                ))}

                {groupIndex === eventGroups.length - 1 &&
                  user.roleCheck(['SYSTEMS-ADMIN', '8f7']) &&
                  group.events[group.events.length - 1].data.readByCount > 0 && (
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{
                        fontSize: '0.6rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                      }}
                    >
                      <DoneAll sx={{ fontSize: '1rem' }} />
                      {group.events[group.events.length - 1].data.readBy
                        .filter(
                          reader =>
                            reader.userSeq.toLowerCase() !== user.userSeq.toLowerCase() &&
                            reader.userSeq.toLowerCase() !==
                              firstMessage.sender.userSeq.toLowerCase()
                        )
                        .map(reader => reader.userName)
                        .join(', ')}
                    </Typography>
                  )}
              </Box>
            );
          })}
          <div ref={messagesEndRef} />
        </Box>
        <TypingIndicator
          typingParticipants={typingParticipants[selectedChat.conversationSeq] || []}
          supportAgentName={supportAgentName}
          conversationTypeSeq={selectedChat?.type?.conversationTypeSeq || ''}
          currentUserSeq={user?.userSeq || ''}
        />
      </Box>
      <EnhancedRichTextEditor
        isConversationClosed={!selectedChat.isOpen}
        preventEscapeKeyClosing={false}
        onSend={async (content, attachments) => {
          await sendMessage({
            conversationSeq: selectedChat.conversationSeq,
            chatMessage: {
              attachments: attachments,
              messageContent: content,
              readBy: [
                {
                  userSeq: user?.userSeq,
                },
              ],
              sender: {
                userSeq: user?.userSeq,
              },
            },
          });
        }}
        requireConfirmation={selectedChat?.type.conversationTypeSeq !== ConversationTypes.Chat}
        placeholder='Type a message...'
        showPreview={false}
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          zIndex: 10,
        }}
      />{' '}
    </>
  );
};
