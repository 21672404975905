import AssignmentIcon from '@mui/icons-material/Assignment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HomeIcon from '@mui/icons-material/Home';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import dimensions from '../../utils/dimensions';
import AppTitle from './AppTitle';
import Search from './Search';
import { UserMenuDrawer } from './UserMenuDrawer';
import { useAuth } from '../../utils/auth/AuthService';
import { useIncident } from '../../views/DVCS/IncidentContext';
import { Flood, People } from '@mui/icons-material';

const CaseNavigationItems = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />,
    authorizedRoles: ['DASHBOARD'],
  },
  {
    text: 'Daily Exams',
    path: '/dailyexamlist',
    icon: <DashboardIcon />,
    authorizedRoles: ['DAILY-EXAM-LIST'],
  },
  {
    text: 'New Case',
    path: '/caseintake',
    icon: <AddIcon />,
    authorizedRoles: ['CREATE-CASE'],
  },
  {
    text: 'Your Cases',
    path: '/yourcasesandreports',
    icon: <AssignmentIcon />,
  },
  {
    text: 'Search Cases',
    path: '/casesearch',
    icon: <SearchIcon />,
  },

  {
    text: 'Reports',
    path: '/reports',
    icon: <DescriptionIcon />,
    authorizedRoles: ['REPORTS-MENU-ACCESS'],
  },

  {
    text: 'Spt Agent',
    path: '/support',

    icon: <SupportAgentIcon />,
    authorizedRoles: ['8f7'],
  },

  {
    text: 'Sys Admin',
    path: '/sysadmin',
    icon: <AdminPanelSettingsIcon />,
    authorizedRoles: ['d1582600-5f86-49dd-bab7-6f7205bfeffd'],
  },
  {
    text: 'DVCS',
    path: '/dvcs/home',
    icon: <Flood />,
    authorizedRoles: ['b06'],
  },
];

const DisasterNavigationItems = [
  {
    text: 'Dashboard',
    path: '/dvcs/dashboard',
    icon: <DashboardIcon />,
  },
  {
    text: 'Cases',
    path: '/dvcs/cases',
    icon: <AssignmentIcon />,
  },
  {
    text: 'Missing Persons',
    path: '/dvcs/missing-persons',
    icon: <SearchIcon />,
  },
  {
    text: 'Call Center',
    path: '/dvcs/call-center-interface',
    icon: <AssignmentIcon />,
  },
  {
    text: 'Family Assistance',
    path: '/dvcs/fac',
    icon: <People />,
  },
];

export default function DVCSMobileAppBar() {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { incident, setIncident, incidentOptions } = useIncident();

  const handleOpenUserMenu = () => {
    setUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setUserMenuOpen(false);
  };

  const CaseNavigationList = () => {
    return (
      <Box component='div'>
        <List>
          <Divider />
          {CaseNavigationItems.map((item, index) => {
            const isAfterReports = item.text === 'Reports'; // Check if the current item is "Reports"
            return item.authorizedRoles != null ? (
              user?.roleCheck(item.authorizedRoles) && (
                <Box component='div' key={item.path}>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{ px: 1 }}
                      onClick={() => {
                        navigate(item.path);
                        setMobileMenuOpen(false);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} sx={{ mr: 'auto' }} />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  {isAfterReports && <Divider />} {/* Add a second divider after "Reports" */}
                </Box>
              )
            ) : (
              <Box component='div' key={item.path}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: 1 }}
                    onClick={() => {
                      navigate(item.path);
                      setMobileMenuOpen(false);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} sx={{ mr: 'auto' }} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                {isAfterReports && <Divider />} {/* Add a second divider after "Reports" */}
              </Box>
            );
          })}
        </List>
      </Box>
    );
  };

  const DisasterNavigationList = () => {
    return (
      <Box component='div'>
        <List>
          <Divider />
          {DisasterNavigationItems.map((item, index) => (
            <Box component='div' key={item.path}>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ px: 1 }}
                  onClick={() => {
                    navigate(item.path);
                    setMobileMenuOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} sx={{ mr: 'auto' }} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box
      component='div'
      sx={{
        position: 'sticky',
        top: 0,
        px: 2,
        display: { xs: 'flex', lg: 'none' },
        zIndex: 1005,
        height: dimensions.app_bar_height,
        backgroundColor: theme.palette.mode === 'dark' ? '#AA5030' : '#CC8040',
        // backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderBottom: '1px solid rgba(112, 112, 112, 0.7)',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ height: '100%', width: '100%' }}
      >
        <IconButton onClick={() => setMobileMenuOpen(true)} size='large'>
          <MenuIcon fontSize='inherit' />
        </IconButton>

        <Drawer
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Stack sx={{ p: 1, pt: 2 }} spacing={4} alignItems='center'>
            <AppTitle />

            <Stack>
              <Typography variant='subtitle1' sx={{ pl: 1 }}>
                Find a case
              </Typography>
              <Search
                menuOpen={mobileMenuOpen}
                setMenuOpen={setMobileMenuOpen}
                startAdornment={<SearchIcon />}
                includePrevNextArrows
              />
            </Stack>

            <Stack sx={{ width: '100%' }}>
              <Typography variant='subtitle1' sx={{ pl: 1 }}>
                Case Navigation
              </Typography>

              <CaseNavigationList />
            </Stack>

            {/* <Divider sx={{ opacity: 1, borderColor: '#fff' }} /> */}

            <Stack sx={{ width: '100%' }} spacing={1}>
              <Typography variant='subtitle1' sx={{ pl: 1 }}>
                Disaster Navigation
              </Typography>

              <Autocomplete
                options={incidentOptions}
                value={incident}
                renderInput={params => <TextField {...params} label='Active Incident' />}
                getOptionLabel={o => o.incidentDescription ?? ''}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Stack>
                      <Typography>{option.incidentDescription}</Typography>
                      <Typography sx={{ opacity: 0.6 }}>{option.groupID}</Typography>
                    </Stack>
                  </li>
                )}
                onChange={(event, value) => setIncident(value)}
                sx={{ width: 230, pt: 1 }}
              />

              <DisasterNavigationList />
            </Stack>

            {/* <Stack sx={{ width: '100%' }}>
              <Typography variant='subtitle1' sx={{ pl: 1 }}>
                Case Tools
              </Typography>

              <Typography variant='body1' sx={{ pl: 1 }}>
                <i>Coming soon...</i>
              </Typography>
            </Stack> */}
          </Stack>
        </Drawer>

        <AppTitle />

        <UserMenuDrawer
          handleCloseUserMenu={handleCloseUserMenu}
          handleOpenUserMenu={handleOpenUserMenu}
          open={userMenuOpen}
        />
      </Stack>
    </Box>
  );
}
