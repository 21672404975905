import SearchIcon from '@mui/icons-material/Search';
import {
  InputBase,
  CircularProgress,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { useAuth } from '../../utils/auth/AuthService';

// updated unified search bar
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.selected,
    // opacity: theme.palette.action.focusOpacity,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      // opacity: theme.palette.action.hoverOpacity
    },
    color: theme.palette.text,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    // color: palette.surface_2_foreground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '11ch',
      },
      [theme.breakpoints.up('lg')]: {
        width: '17ch',
      },
    },
  }));

  const SearchCase = ({ onLinkedCaseIdChange }) => {
    const [caseData, setCaseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [linkedCaseId, setLinkedCaseId] = useState(null);
    const { user } = useAuth();
    const numSearchYears = 5;
    const { VITE_API_URL } = import.meta.env;


    const handleRowClick = (params) => {
        const { caseid, caseSeq } = params;
        setLinkedCaseId(caseid);
        onLinkedCaseIdChange({ caseid, caseSeq });
      };

    const handleSubmit = (event) => {
      event.preventDefault(); // Prevents the default form submission behavior
      const inputValue = event.target.elements['search-case'].value.trim();
  
      // Regular expression to match the nn-nn-nnnn format
      // const cmsCaseFormat = /^\d{2}-\d{2}-\d{4}$/;
      const cmsCaseFormat = /^(?!\w\*).{2}-\d{2}-\d{4}$/;


      let caseId;
      let lastName;

      // Check if input matches the CMS case format
      if (cmsCaseFormat.test(inputValue)) {
         caseId = inputValue
      } else if (/^\d{8}$/.test(inputValue)) { // Check for purely numeric input with exactly 8 digits
        // Format into nn-nn-nnnn and call OpenCMSCase
        const formattedCaseId = `${inputValue.substring(0, 2)}-${inputValue.substring(2, 4)}-${inputValue.substring(4)}`;
        caseId = formattedCaseId
      } else if (/^\d+$/.test(inputValue)) { // Check for other purely numeric inputs
        caseId = inputValue
      } else if (/\D/.test(inputValue)) {
        lastName = inputValue
      }

      CaseSearch(caseId, lastName);
    };

    const CaseSearch = async (caseId, lastname) => {
        try {
            const requestBody = {
                JdxList: user.jdxAccessList.map(jurisdiction => jurisdiction.jdxSeq),
                CaseId: caseId,
                LastName: lastname,
                NumberOfYears: numSearchYears,
            };
    
            setLoading(true);
            const response = await fetch(`${VITE_API_URL}casesearch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + user.token,
                },
                body: JSON.stringify(requestBody),
            });
    
            if (response.ok) {
                const responseData = await response.json();
                setCaseData(responseData); // Set the data state with API response
            } else {
                // Handle HTTP errors
                console.error('HTTP error:', response.status);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false);
        }
    };
  
    const columns = [
        {
            field: 'caseNo',
            headerName: 'Case ID',
            width: 105,
            editable: false,
            align: 'left',
            headerAlign: 'left',
            headerClassName: 'header-theme',
            renderCell: (params) => {
                return (
                    <b> {params.row.caseid} </b>
                );
            },
        },
        // { field: 'caseSeq', headerName: 'CaseSeq', width: 150 },
        // { field: 'caseid', headerName: 'Case ID', width: 150 },
        {
            field: 'combinedName',
            headerName: 'Decedent Name',
            width: 200,
            renderCell: (params) => {
                // Assuming params.row contains the data for the current row
                const lastName = params.row.lastName || '';
                const firstName = params.row.firstName || '';
                return <span>{`${lastName}, ${firstName}`}</span>;
            },
        },
        {
            field: 'pronouncementDateTime',
            headerName: 'Date Pronounced',
            width: 150,
            renderCell: (params) => {
                // Check if the value is a valid date
                const date = new Date(params.value);
                if (isNaN(date.getTime())) {
                    // If it's not a valid date, return a default value or an indication of invalid date
                    return <span></span>;
                } else {
                    // If it is a valid date, format and return it
                    const formattedDate = date.toISOString().split('T')[0];
                    return <span>{formattedDate}</span>;
                }
            }
        },
        // { field: 'lastName', headerName: 'Last Name', width: 150 },
        // { field: 'firstName', headerName: 'First Name', width: 150 },
        // { field: 'dateOfBirth', headerName: 'DOB', width: 150 },
        { field: 'jurisdictionName', headerName: 'Jurisdiction', width: 150 },
        {
            field: 'dateOfBirth',
            headerName: 'BirthDate',
            width: 150,
            renderCell: (params) => {
                // Check if the value is a valid date
                const date = new Date(params.value);
                if (isNaN(date.getTime())) {
                    // If it's not a valid date, return a default value or an indication of invalid date
                    return <span></span>;
                } else {
                    // If it is a valid date, format and return it
                    const formattedDate = date.toISOString().split('T')[0];
                    return <span>{formattedDate}</span>;
                }
            }
        },

        // { field: 'middleName', headerName: 'Middle Name', width: 150 },
   
        {
            field: 'meNotifiedDateTime',
            headerName: 'ME Notified Date',
            width: 150,
            renderCell: (params) => {
                // Check if the value is a valid date
                const date = new Date(params.value);
                if (isNaN(date.getTime())) {
                    // If it's not a valid date, return a default value or an indication of invalid date
                    return <span></span>;
                } else {
                    // If it is a valid date, format and return it
                    const formattedDate = date.toISOString().split('T')[0];
                    return <span>{formattedDate}</span>;
                }
            }
        },
        // Add other columns as needed
    ];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Search style={{ margin: '0.5rem', marginLeft: 'auto' }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <form onSubmit={handleSubmit}>
              <StyledInputBase
                key='search-case'
                name='search-case' // Name attribute to reference the input field in handleSubmit
                placeholder='Case ID or Last Name'
                inputProps={{ 'aria-label': 'search' }}
              />
            </form>
          </Search>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            disabled='true'
            fullWidth
            id='linkedCaseId'
            name='linkedCaseId'
            label='Transfer CaseId'
            placeholder='Transfer CaseId'
            variant='outlined'
            type='text'
            value={linkedCaseId || null}
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>

        <Grid item xs={12}>
          <SiloDataGrid
            loading={loading}
            listresults={caseData}
            columnsInput={columns}
            pageSize={10}
            idcolumn='caseid'
            gridcolor='#DB9E09'
            title=' '
            tooltip='Quick search by Name'
            rowsperpage={5}
            onRowClick={(params) => handleRowClick(params.row)}
          />
        </Grid>
      </Grid>
    );
  };

  export default SearchCase;