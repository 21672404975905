// import AWS from 'aws-sdk';
// import L, { LatLngTuple } from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import React, { useEffect, useRef, useState } from 'react';
// import { MapContainer, Marker, TileLayer } from 'react-leaflet';
// import mapMarker from '../../../../assets/images/map-marker.png';

// interface AddressMapProps {
//   addressLine1: string;
//   addressLine2?: string;
//   city: string;
//   state: string;
//   zipcode: string;
// }

// export const AddressMap: React.FC<AddressMapProps> = ({
//   addressLine1 = '',
//   addressLine2 = undefined,
//   city = '',
//   state = '',
//   zipcode = '',
// }) => {
//   const [coordinates, setCoordinates] = useState<LatLngTuple | null>(null);
//   const mapRef = useRef<L.Map | null>(null);

//   useEffect(() => {
//     const fetchCoordinates = async () => {
//       try {
//         const location = new AWS.Location({
//           region: 'us-east-1',
//           credentials: new AWS.CognitoIdentityCredentials({
//             IdentityPoolId: 'us-east-1:269924ac-7862-42d3-9a9c-4bd1c1861f39',
//           }),
//         });

//         const params: AWS.Location.SearchPlaceIndexForTextRequest = {
//           IndexName: 'MedexLabPlaceIndex',
//           Text: `${addressLine1} ${addressLine2} ${city} ${state} ${zipcode}`,

//           BiasPosition: [-74.450507, 40.181042],
//           MaxResults: 1,
//         };

//         const data = await location.searchPlaceIndexForText(params).promise();

//         if (data.Results && data.Results.length > 0) {
//           const place = data.Results[0].Place;
//           if (place && place.Geometry && place.Geometry.Point) {
//             const [Longitude, Latitude] = place.Geometry.Point;
//             setCoordinates([Latitude, Longitude]);
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching coordinates:', error);
//       }
//     };

//     fetchCoordinates();
//   }, [addressLine1, addressLine2, city, state, zipcode]);

//   useEffect(() => {
//     if (mapRef.current && coordinates) {
//       mapRef.current.setView(coordinates, 14);
//     }
//   }, [coordinates]);

//   return (
//     <div style={{ height: '400px', zIndex: 1 }}>
//       <MapContainer
//         center={coordinates || [0, 0]}
//         zoom={14}
//         style={{ height: '100%' }}
//         ref={mapRef}
//       >
//         <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
//         {coordinates && <Marker icon={customIcon()} position={coordinates} />}
//       </MapContainer>
//     </div>
//   );
// };

// const customIcon = () =>
//   L.divIcon({
//     className: 'custom-icon',
//     html: `<div style="position: relative;">
//         <img src="${mapMarker}" style="position: absolute;" width="41" height="41" />
//         <div id="textContainer" class="text-container" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, 20%);
//           color: white; font-size:'18px'; font-weight: bold; margin: 0;"></div>
//       </div>`,
//     iconSize: [41, 41],
//     iconAnchor: [20, 41], // Adjust the iconAnchor to center the marker
//     popupAnchor: [1, -34],
//     shadowSize: [41, 41],
//   });

import L, { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import mapMarker from '../../../../assets/images/map-marker.png';
import { LocationClient, SearchPlaceIndexForTextCommand } from '@aws-sdk/client-location';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

interface AddressMapProps {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipcode: string;
}

const locationClient = new LocationClient({
  region: 'us-east-1',
  credentials: fromCognitoIdentityPool({
    identityPoolId: 'us-east-1:269924ac-7862-42d3-9a9c-4bd1c1861f39',
    clientConfig: { region: 'us-east-1' },
  }),
});

export const AddressMap: React.FC<AddressMapProps> = ({
  addressLine1 = '',
  addressLine2 = undefined,
  city = '',
  state = '',
  zipcode = '',
}) => {
  const [coordinates, setCoordinates] = useState<LatLngTuple | null>(null);
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const params = {
          IndexName: 'MedexLabPlaceIndex',
          Text: `${addressLine1} ${addressLine2} ${city} ${state} ${zipcode}`,
          BiasPosition: [-74.450507, 40.181042],
          MaxResults: 1,
        };

        const command = new SearchPlaceIndexForTextCommand(params);
        const data = await locationClient.send(command);

        if (data.Results && data.Results.length > 0) {
          const place = data.Results[0].Place;
          if (place && place.Geometry && place.Geometry.Point) {
            const [Longitude, Latitude] = place.Geometry.Point;
            setCoordinates([Latitude, Longitude]);
          }
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    fetchCoordinates();
  }, [addressLine1, addressLine2, city, state, zipcode]);

  useEffect(() => {
    if (mapRef.current && coordinates) {
      mapRef.current.setView(coordinates, 14);
    }
  }, [coordinates]);

  return (
    <div style={{ height: '400px', zIndex: 1 }}>
      <MapContainer
        center={coordinates || [0, 0]}
        zoom={14}
        style={{ height: '100%' }}
        ref={mapRef}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        {coordinates && <Marker icon={customIcon()} position={coordinates} />}
      </MapContainer>
    </div>
  );
};

const customIcon = () =>
  L.divIcon({
    className: 'custom-icon',
    html: `<div style="position: relative;">
        <img src="${mapMarker}" style="position: absolute;" width="41" height="41" />
        <div id="textContainer" class="text-container" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, 20%);
          color: white; font-size:'18px'; font-weight: bold; margin: 0;"></div>
      </div>`,
    iconSize: [41, 41],
    iconAnchor: [20, 41], // Adjust the iconAnchor to center the marker
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
