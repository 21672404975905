import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteCellDisplay } from '../../../../../utils/components/CrudDataGrid/AutocompleteCellWithTextField';
import { renderAutocompleteEditCell } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import { renderAutocompleteEditCellWithTextField } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCellWithTextField';
import {
  handleAutocompleteChange,
  handleCheckboxChange,
  handleNumericTextFieldChange,
  handleTextFieldChange,
} from '../../../../../utils/components/DialogCrudDataGrid';
import NumericTextField from '../../../../../utils/components/NumericTextField';
import { PropertyInventoryDataGrid } from './PropertyInventoryDataGrid';

export function ClothingDataGrid() {
  const [clothingOptions, setClothingOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext<CMSCase>();

  useEffect(() => {
    fetchClothingOptions();
    fetchColorOptions();
  }, []);

  const fetchClothingOptions = () => {
    fetch(VITE_API_URL + 'getclothingtypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setClothingOptions(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const fetchColorOptions = () => {
    fetch(VITE_API_URL + 'getcoloroptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setColorOptions(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const propertyClothingColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'clothingItem',
      headerName: 'Clothing Item',

      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: clothingOptions,
      // renderEditCell: params => renderAutocompleteEditCell(params, 'Clothing Item'),
      renderEditCell: params => {
        const textFieldText = params.row?.otherClothing;
        return renderAutocompleteEditCellWithTextField({
          ...params,
          textFieldText,
          rowField: 'otherClothing',
        });
      },
      renderCell: params => {
        const textFieldText = params.row?.otherClothing;
        return renderAutocompleteCellDisplay({
          ...params,
          textFieldText,
        });
      },
      toggleTextfieldoption: 'Other',
    },
    {
      flex: 0.4,
      minWidth: 120,
      field: 'number',
      headerName: 'Number',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'color',
      headerName: 'Color',

      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: colorOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    // {
    //   flex: 1,
    //   minWidth: 80,
    //   field: 'styleType',
    //   headerName: 'Style/Type',
    //   headerAlign: 'left',
    //   align: 'left',
    //   type: 'string',
    //
    // },
    // {
    //   flex: 1,
    //   minWidth: 80,
    //   field: 'size',
    //   headerName: 'Size',
    //   headerAlign: 'left',
    //   align: 'left',
    //   type: 'string',
    //
    // },

    // {
    //   flex: 1,
    //   minWidth: 80,
    //   field: 'markings',
    //   headerName: 'Markings',
    //   headerAlign: 'left',
    //   align: 'left',
    //   type: 'string',
    //
    // },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
  ];

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={clothingOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.clothingItem || null}
            onChange={(event, value) =>
              handleAutocompleteChange(
                event,
                value,
                'clothingItem',
                localData,
                setLocalData,
                onChange
              )
            }
            renderInput={params => (
              <TextField {...params} label='Description' margin='dense' fullWidth />
            )}
          />
        </Grid>

        {localData?.clothingItem?.optionName === 'Other' && (
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              value={localData?.otherClothing ?? ''}
              onChange={event =>
                handleTextFieldChange(event, 'otherClothing', localData, setLocalData, onChange)
              }
              label='Other NM'
              fullWidth
              margin='dense'
            />
          </Grid>
        )}

        <Grid item xs={12} md={6} xl={3}>
          <NumericTextField
            value={localData?.number ?? 0}
            onChange={value =>
              handleNumericTextFieldChange(value, 'number', localData, setLocalData, onChange)
            }
            margin='dense'
            fullWidth
            label='Number'
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={colorOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.color || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'color', localData, setLocalData, onChange)
            }
            renderInput={params => <TextField {...params} label='Color' margin='dense' fullWidth />}
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Funeral Home'
            control={
              <Checkbox
                checked={localData?.funeralHome ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'funeralHome', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Law Enforcement'
            control={
              <Checkbox
                checked={localData?.releasedtoLE ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'releasedtoLE', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Retained'
            control={
              <Checkbox
                checked={localData?.retained ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'retained', localData, setLocalData, onChange)
                }
              />
            }
            sx={{ pr: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={localData?.note ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'note', localData, setLocalData, onChange)
            }
            label='Notes'
            fullWidth
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <PropertyInventoryDataGrid
      rows={formik.values?.caseMortuary?.propertyInventory?.propertyClothing ?? []}
      columns={propertyClothingColumns}
      idColumn='propertyClothingSeq'
      getApi='getpropertyclothing'
      createApi='createpropertyclothing'
      updateApi='updatepropertyclothing'
      deleteApi='deletepropertyclothing'
      title='Clothing Item'
      fieldPath='propertyClothing'
      dialogContent={dialogContent}
    />
  );
}
