import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Chip,
  Hidden,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

export default function CustomHeader({
  title = '',
  description = '',
  handleMenuClick,
  endAdornment = <></>,
  showMenuButton = true,
  inDevelopment = false,
  alert = null,
}: {
  title?: any;
  description?: any;
  handleMenuClick?: any;
  endAdornment?: React.ReactNode;
  showMenuButton?: boolean;
  inDevelopment?: boolean;
  alert?: React.ReactNode;
}) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box component='div'>
      <Box
        component='div'
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}
      >
        <Stack direction='row' spacing={2} alignItems='center'>
          <Stack>
            <Typography variant='h5' component='h1'>
              <b>{title}</b>
            </Typography>
            <Typography variant='subtitle1' color='gray'>
              {description}
            </Typography>
          </Stack>
          {inDevelopment && <Chip label='IN DEVELOPMENT' variant='outlined' color='info' />}
        </Stack>

        {endAdornment}
        <Box
          component='div'
          sx={{ display: { xs: showMenuButton ? 'block' : 'none', md: 'none' } }}
        >
          <Tooltip title='Show Case Menu'>
            <IconButton size={isMediumScreen ? 'medium' : 'large'} onClick={handleMenuClick}>
              <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {alert}

      <hr style={{ borderColor: '#555', marginBottom: '1rem' }} />
    </Box>
  );
}
