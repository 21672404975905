import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import MasterCaseListView from '../Reports/MasterCaseListView';

export function CaseListView() {
  return (
    <Box component='div' sx={{ margin: 0 }}>
      <Stack sx={{ margin: 2 }}>
        <Stack direction='row' spacing={1}>
          <Typography variant='h5' component='h1'>
            <b>All Cases List</b>
          </Typography>{' '}
          <Chip variant='outlined' color='info' label='IN-DEVELOPMENT' size='small' />
        </Stack>

        <Typography variant='subtitle1' color='gray'>
          View and analyze all cases
        </Typography>
        <Divider sx={{ borderColor: '#555', mx: 0, mt: 1 }} />
      </Stack>
      <Box component='div' sx={{ ml: 2, mr: 2 }}>
        <MasterCaseListView />
      </Box>
    </Box>
  );
}
