import React, { useEffect, useState } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useAuth } from '../../utils/auth/AuthService';

export default function CaseViewTimeline(props) {
  const formik = useFormikContext();
  const auth = useAuth();
  const theme = useTheme();
  const { VITE_API_URL } = import.meta.env;
  const [timelineData, setTimelineData] = useState(null);

  useEffect(() => {
    // GET TIMELINE DATA
    fetch(VITE_API_URL + 'GetCaseTimeline?caseID=' + formik.values?.caseSummary?.caseNumber, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth.user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (data.message != null) {
          // alert(data.message);
        } else {
          setTimelineData(data);
        }
      })
      .catch(e => {
        // alert(e);
      });
  }, []);

  const timelineNames = {
    pronounciationDateTime: 'Pronounced',
    meNotified: 'Case Reported',
    intakeDateTime: 'Case Created',
    sceneDepartedDateTime: 'Departed Scene',
    transportedToMEDateTime: 'Transported to ME',
    examStartDateTime: 'Exam Started',
    examEndDateTime: 'Exam Ended',
    releaseDateTime: 'Decedent Checked Out',
    caseSignedAndFiledDate: 'Case Signed And Filed',
  };

  const timelineModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '92%',
    maxWidth: 400,
    bgcolor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  const handleCloseTimelineModal = () => {
    props.setIsOpen(false);
  };

  const PopulatedTimeline = () => {
    if (timelineData == null) {
      return <p>Loading...</p>;
    }

    var extractedTimeline = timelineData[0];

    return (
      <Timeline style={{ margin: 0, padding: 0 }}>
        {Object.keys(extractedTimeline).map(function (key) {
          if (extractedTimeline[key] == null) {
            return null;
          }
          return (
            <TimelineItem sx={{ minHeight: '40px' }}> {/* Reduced minHeight */}
              <TimelineContent
                sx={{
                  textAlign: 'left',
                  fontSize: '9pt',
                  marginRight: 2,
                  maxWidth: 150,
                  py: 0.5, // Reduced vertical padding
                }}
              >
                {timelineNames[key]}
              </TimelineContent>

              {key == 'caseSignedAndFiledDate' ? (
                <TimelineSeparator>
                  <TimelineDot sx={{ my: 0.5 }} /> {/* Reduced margin */}
                </TimelineSeparator>
              ) : (
                <TimelineSeparator>
                  <TimelineDot sx={{ my: 0.5 }} /> {/* Reduced margin */}
                  <TimelineConnector />
                </TimelineSeparator>
              )}

              <TimelineOppositeContent
                sx={{
                  textAlign: 'right',
                  fontSize: '9pt',
                  m: 0,
                  p: 0,
                  mt: 0.75,
                  ml: 0,
                }}
                color='text.secondary'
              >
                {extractedTimeline[key]}
              </TimelineOppositeContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };

  return (
    <Modal open={props.isOpen} onClose={handleCloseTimelineModal}>
      <Box sx={timelineModalStyle}>
        <Typography
          variant='h6'
          component='h2'
          sx={{ textAlign: 'center', mb: 1, mt: 0, fontWeight: 400 }}
        >
          Case Timeline
        </Typography>
        <hr />

        <PopulatedTimeline />
      </Box>
    </Modal>
  );
}