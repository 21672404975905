import { Box } from '@mui/material';
import { useInView } from 'framer-motion';
import { RichTextReadOnly } from 'mui-tiptap';
import { useEffect, useRef } from 'react';
import useExtensions from '../../../../Home/useExtensions';
import { ChatMessage } from './conversation.types';

function MessageContent({
  message,
  onMessageRead,
}: {
  message: ChatMessage;
  onMessageRead?: (messageSeq: string) => void;
}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });
  const hasTriggeredRead = useRef(false);
  const extensions = useExtensions({});

  useEffect(() => {
    if (isInView && onMessageRead && !hasTriggeredRead.current) {
      hasTriggeredRead.current = true;
      onMessageRead(message.messageSeq);
    }
  }, [isInView, message.messageSeq, onMessageRead]);

  return (
    <div ref={ref}>
      {message.messageContent ? (
        <Box
          component='div'
          sx={{
            '& .ProseMirror': {
              '& p': {
                margin: 0,
                padding: 0,
              },
              '& *:first-child': {
                marginTop: 0,
              },
              '& *:last-child': {
                marginBottom: 0,
              },
              '& img': {
                border: 'none',
                maxWidth: '100%',
              },
            },
          }}
        >
          <RichTextReadOnly
            key={message.messageSeq}
            content={message.messageContent}
            extensions={extensions}
          />
        </Box>
      ) : null}
    </div>
  );
}

export default MessageContent;
