import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useState } from 'react';

interface CharacterLimitedTextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  characterLimit: number;
  [x: string]: any;
  TextFieldProps?: TextFieldProps;
}

const CharacterLimitedTextField: React.FC<CharacterLimitedTextFieldProps> = ({
  value,
  onChange,
  characterLimit,
  TextFieldProps,
  ...props
}) => {
  const [currentLength, setCurrentLength] = useState(value?.length ?? 0);
  const [error, setError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= characterLimit) {
      if (error) {
        setError(false);
      }
      setCurrentLength(newValue.length);
      onChange(event);
    } else {
      setError(true);
    }
  };

  const getHelperText = () => {
    if (error) {
      return 'Exceeds character limit';
    }
    return `${currentLength}/${characterLimit}`;
  };

  return (
    <TextField
      {...props}
      {...TextFieldProps}
      value={value ?? ''}
      onChange={handleChange}
      helperText={getHelperText()}
      error={error}
    />
  );
};

export default CharacterLimitedTextField;
