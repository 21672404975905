import React from 'react';
import { useEffect, useState } from 'react';
// project imports
// import MainCard from '../../../../../../components/AdministrationModules/MainCard';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import {
  activeData,
  deleteData,
  getAdminData,
} from '../../../../../services/utility-api';
import AddEdit from './AddEdit';

// material-ui
import { Grid, Box, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ReferenceMasterTableFilter from '../ReferenceMasterTableFilter';
import AccessDenied from '../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../components/AdministrationModules/Breadcrumb';
// import AccessDenied from 'ui-component/AccessDenied';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const CurrencyDenomination = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const referencePermissions = permissions?.['Reference Data'];
  const referenceMasterTableName = 'Denomination';
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [isCounty, setIsCounty] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

  const [columns, setColumns] = useState([
    {
      field: 'fetchcountryName',
      headerName: 'Country',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'denominationValue',
      headerName: 'Denomination Value',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'denominationOrder',
      headerName: 'List Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [countyNames, setCountyNames] = useState({});
  const [countryNames, setCountryNames] = useState({});
  const MENU_OBJECT = {
    Home: '/support',
    'Reference Data': '/support',
    'Currency Denomination': '',
  };
  let isStatusColumnAdded = false;
  columns.forEach((column) => {
    if (column.field == 'status') {
      isStatusColumnAdded = true;
    }
  });
  if (!isStatusColumnAdded) {
    setColumns((prev) => [
      ...prev,
      {
        field: 'status',
        headerName: 'Status',
        // headerClassName: 'super-app-theme--header',
        renderCell: (cellValues) => {
          const handleClick = async (row) => {
            // if (!permissions?.['Reference Data']?.edit) return;
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row?.denominationSeq,
              status: row.status,
            });
          };

          return Number(
            cellValues.row[
            referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Order'
            ]
          ) > 90000 ? (
            <></>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClick(cellValues.row);
              }}
            //   disabled={!permissions?.['Reference Data']?.edit}
            >
              {cellValues.row.status === 'Active' ? (
                <Tooltip title='Active'>
                  <LockIcon />
                </Tooltip>
              ) : (
                <Tooltip title='InActive'>
                  <DoneIcon />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ]);
  }

  const getAllData = async () => {
    const response = await getAdminData('Denomination');
    const countryResponse = await getAdminData('country');

    const tempCountryNames = {};
    countryResponse.data.data.forEach((country) => {
      tempCountryNames[country.countrySeq] = {
        countrySeq: country.countrySeq,
        countryName: country.countryName,
        countryCode: country.countryCode,
        order: country.order,
      };
    });

    setCountryNames(tempCountryNames);
    let data = response.data.data.map((row) => {
      const newObj = {};
      Object.keys(row).map((key) => {
        newObj[key] = row[key];
      });
      newObj.id = newObj['denominationSeq'];
      newObj.countryName = tempCountryNames;
      if (newObj['denominationOrder'])
        newObj['denominationOrder'] = Number(newObj['denominationOrder']);
      return newObj;
    });

    let maxOrder = 0;
    for (let i = 0; i < data.length; i++) {
      if (
        Number(data[i].denominationOrder) > maxOrder &&
        Number(data[i].denominationOrder) < 90000
      )
        maxOrder = Number(data[i].denominationOrder);
    }
    setMaxOrder(maxOrder);

    const updatedDenomination = data?.map((denomination) => {
      const newDenomination = {
        ...denomination,
        fetchcountryName:
          denomination?.countryName?.[denomination.countrySeq]?.countryName,
      };
      return newDenomination;
    });

    setRows(updatedDenomination);
    setRowsToShow(
      updatedDenomination.filter(
        (row) => row['status'].toLowerCase() == 'active'
      )
    );
  };

  const editRow = (params) => {
    if (referencePermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };
  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: 'Denomination',
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: 'Denomination',
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  if (!referencePermissions?.view) {
    return (<>
      <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
      <AccessDenied />
    </>)
  }
  else {
    return (
     // <div className='container my-5 py-5'>
     <Grid container spacing={2} sx={{mt: '10px', padding: '16px'}}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {/* <MainCard> */}
                  <Box
                    // sx={{
                    //   width: '100%',
                    //   '& .super-app-theme--header': {
                    //     backgroundColor: 'rgb(2, 136, 209)',
                    //     color: '#fff',
                    //   },
                    // }}
                    style={{ tr: 'hover', cursor: 'pointer' }}
                  >
                <ReferenceMasterTableFilter
                  rows={rows}
                  isJdx={false}
                  jurisdictionOptions={[]}
                  setRowsToShow={setRowsToShow}
                />
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field: 'fetchcountryName',
                            sort: 'asc',
                          },
                        ],
                      },
                    }}
                    rowHeight={35}
                    rows={rowsToShow}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick={false}
                    onRowClick={editRow}
                    // sx={{
                    //   m: 2,
                    //   marginLeft: '0px',
                    //   fontSize: '14px',
                    //   fontFamily:
                    //     'Roboto , Helvetica , Arial , sans-serif !important',
                    //   height: 500,
                    //   width: '100%',
                    //   direction: 'row',
                    //   overflow: 'hidden',
                    //   borderRadius: '6px',
                    //   backgroundColor: 'white',
                    //   boxShadow: '0px 0px 10px #ddd',
                    // }}
                  />
                </Box>
                <Box>
                  <Button
                    variant='contained'
                    sx={{ marginTop: '7px' }}
                    onClick={() => {
                      setIsAddEditOn(false);
                      setTimeout(() => {
                        setEditObj({});
                        setIsAddEditOn(true);
                      }, 200);
                    }}
                    disabled={!referencePermissions?.add}
                  >
                    Add Denomination
                  </Button>
                </Box>
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                {isAddEditOn && editObj && (
                  <AddEdit
                    maxOrder={maxOrder}
                    editObj={editObj}
                    referenceMasterTableName='Denomination'
                    setIsAddEditOn={setIsAddEditOn}
                    getAllData={getAllData}
                    isCounty={isCounty}
                    countryNames={countryNames}
                    rows={rows}
                    referencePermissions={referencePermissions}
                  />
                )}
                <div className='modal'>
                  <Dialog
                    open={openActiveInActiveDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenActiveInActiveDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        Are you sure you want to{' '}
                        {activeInactiveClickData?.status === 'Active'
                          ? 'InActive '
                          : 'Active'}{' '}
                        this denomination?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenActiveInActiveDialogBox(false)}
                      >
                        No
                      </Button>
                      <Button onClick={changeActiveInActiveClick}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className='modal'>
                  <Dialog
                    open={openApiDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenApiDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        {dialogContents}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IconButton onClick={() => setOpenApiDialogBox(false)}>
                        <CloseIcon />
                      </IconButton>
                    </DialogActions>
                  </Dialog>
                </div>
              {/* </MainCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // </div>
    );
  }
};
export default CurrencyDenomination;
