import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import GavelIcon from '@mui/icons-material/Gavel';
import ScienceIcon from '@mui/icons-material/Science';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
  Alert,
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridRenderEditCellParams,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import ScrollingAlert from '../../components/ScrollingAlert';
import { useAuth } from '../../utils/auth/AuthService';
import CustomHeader from '../../utils/components/CustomHeader';
import { DVCSAlert } from '../DVCS/DVCSAdminView';
import { useIncident } from '../DVCS/IncidentContext';

interface Family {
  id: number;
  name: string;
  contact: string;
  status: 'Active' | 'Pending' | 'Closed';
  incident: string;
  notes: string;
  appointment: Date;
}

/**
 * Custom cell editor for the Appointment column.
 * This version forces the DatePicker popup open immediately.
 */
function DateEditInputCell(props: GridRenderEditCellParams<Date, Family>) {
  const { id, value, field, api } = props;
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  const handleChange = (newValue: Date | null) => {
    // Update the cell value immediately.
    (api as any).setEditCellValue({ id, field, value: newValue || new Date() });
  };

  const handleClose = () => {
    setOpen(false);
    (api as any).commitCellChange({ id, field });
    (api as any).setCellMode(id, field, 'view');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        open={open}
        onClose={handleClose}
        closeOnSelect={false}
        // @ts-ignore
        value={value || null}
        onChange={handleChange}
        views={['year', 'month', 'day', 'minutes', 'hours']}
        ampm
        amPmInClock
        s
        slotProps={{
          textField: {
            variant: 'standard',
            autoFocus: true,
            inputRef: textFieldRef,
          },
          shortcuts: {
            items: [
              {
                label: 'Today',
                getValue: () => new Date(),
              },
              {
                label: 'Yesterday',
                getValue: () => {
                  const date = new Date();
                  date.setDate(date.getDate() - 1);
                  return date;
                },
              },
              {
                label: 'Last Week',
                getValue: () => {
                  const date = new Date();
                  date.setDate(date.getDate() - 7);
                  return date;
                },
              },
              {
                label: 'Last Month',
                getValue: () => {
                  const date = new Date();
                  date.setMonth(date.getMonth() - 1);
                  return date;
                },
              },
              {
                label: 'Last Year',
                getValue: () => {
                  const date = new Date();
                  date.setFullYear(date.getFullYear() - 1);
                  return date;
                },
              },
            ],
          },
        }}
      />
    </LocalizationProvider>
  );
}

const columns: GridColDef<Family>[] = [
  { field: 'id', headerName: 'Case ID', flex: 1 },
  { field: 'name', headerName: 'Family Name', flex: 2 },
  { field: 'contact', headerName: 'Contact', flex: 2 },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: params => {
      const status = params.value;
      let chipColor: 'success' | 'warning' | 'error' | 'default' = 'default';
      if (status === 'Active') chipColor = 'success';
      if (status === 'Pending') chipColor = 'warning';
      if (status === 'Closed') chipColor = 'error';

      return <Chip label={status} color={chipColor} variant='outlined' size='small' />;
    },
  },
  {
    field: 'appointment',
    headerName: 'Appointment',
    flex: 2,
    editable: true,
    type: 'dateTime',
    valueFormatter: params =>
      // @ts-ignore
      params.value ? format(new Date(params.value), 'MM/dd/yyyy hh:mm a') : '',
    renderCell: params => (
      <Box component='div' sx={{ cursor: 'pointer', width: '100%', height: '100%' }}>
        {params.value ? format(new Date(params.value), 'MM/dd/yyyy hh:mm a') : ''}
      </Box>
    ),
    // @ts-ignore
    renderEditCell: (params: GridRenderEditCellParams<Date, Family>) => (
      <DateEditInputCell {...params} />
    ),
  },
];

export function FamilyAssistanceCenterView() {
  const { incident } = useIncident();
  const [familyData, setFamilyData] = useState<Family[]>([]);
  const [selectedFamily, setSelectedFamily] = useState<Family | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const [alert, setAlert] = useState<DVCSAlert>({ alertText: '', alertSeverity: 'info' });

  const fetchAlert = async () => {
    await fetch(VITE_API_URL + `dvcs/getalert`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAlert(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchAlert();
  }, []);

  // Generate mock data whenever the incident changes.
  useEffect(() => {
    if (incident?.incidentDescription) {
      setFamilyData(generateMockFamilyData(incident.incidentDescription, 6));
    } else {
      setFamilyData([]);
    }
  }, [incident]);

  // Row click opens the detail drawer.
  const handleRowClick = (params: GridRowParams<Family>) => {
    setSelectedFamily(params.row);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedFamily(null);
  };

  return (
    <Box component='div' sx={{ m: 0, p: 2 }}>
      <CustomHeader
        title='Family Assistance Center (FAC)'
        description='Manage support for families affected by events'
        alert={
          alert.alertText !== '' && (
            <ScrollingAlert
              severity={alert.alertSeverity}
              message={alert.alertText ?? ''}
              speed={4}
            />
          )
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Alert
            severity='info'
            sx={{ mb: 2, borderLeft: '6px solid #1976d2' }}
            icon={false}
            action={
              <IconButton aria-label='close' color='inherit' size='small'>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            <strong>{format(new Date(), 'MM/dd/yyyy hh:mm a')}: </strong>
            This is a sample alert
          </Alert>
        </Grid>

        <Grid item xs={12} md={9}>
          <DataGridPremium
            rows={familyData}
            columns={columns}
            getRowId={row => row.id}
            disableRowSelectionOnClick
            autoHeight
            onRowClick={handleRowClick}
            // Intercept clicks on cells.
            onCellClick={(params, event) => {
              if (params.field === 'appointment') {
                event.stopPropagation();
                // Immediately switch the appointment cell into edit mode.
                (params.api as any).setCellMode(params.id, params.field, 'edit');
              }
            }}
            slots={{ toolbar: GridToolbar, footer: () => <></> }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 1 },
              },
            }}
            initialState={{
              sorting: { sortModel: [{ field: 'incident', sort: 'asc' }] },
              density: 'compact',
            }}
            sx={{ '& .MuiDataGrid-root': { border: 'none' } }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' gutterBottom>
              Resources
            </Typography>
            <List dense>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <SupportAgentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary='Counseling Service' secondary='+1 (800) 123-4567' />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AttachMoneyIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary='Financial Aid' secondary='+1 (800) 789-0123' />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <GavelIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary='Legal Assistance' secondary='legalhelp@fac.org' />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ScienceIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary='DNA Collection Centers' secondary='Multiple Locations' />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{ sx: { width: 360 } }}
      >
        {selectedFamily && (
          <Box
            component='div'
            sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1}>
              <Typography variant='h6'>Case #{selectedFamily.id}</Typography>
              <IconButton onClick={handleCloseDrawer}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ mb: 2 }} />

            <Stack spacing={2}>
              <Box component='div'>
                <Typography variant='caption' color='text.secondary'>
                  Family Name
                </Typography>
                <Typography variant='body1'>{selectedFamily.name}</Typography>
              </Box>

              <Box component='div'>
                <Typography variant='caption' color='text.secondary'>
                  Contact
                </Typography>
                <Typography variant='body1'>{selectedFamily.contact}</Typography>
              </Box>

              <Stack>
                <Typography variant='caption' color='text.secondary'>
                  Status
                </Typography>
                <Chip
                  label={selectedFamily.status}
                  size='small'
                  sx={{ mt: 0.5, maxWidth: '14ch' }}
                />
              </Stack>

              <Box component='div'>
                <Typography variant='caption' color='text.secondary'>
                  Incident
                </Typography>
                <Typography variant='body1'>{selectedFamily.incident}</Typography>
              </Box>

              <Box component='div'>
                <Typography variant='caption' color='text.secondary'>
                  Notes
                </Typography>
                <Typography variant='body1'>{selectedFamily.notes}</Typography>
              </Box>

              <Box component='div'>
                <Typography variant='caption' color='text.secondary'>
                  Appointment
                </Typography>
                <Typography variant='body1'>
                  {selectedFamily.appointment
                    ? format(new Date(selectedFamily.appointment), 'MM/dd/yyyy hh:mm a')
                    : ''}
                </Typography>
              </Box>
            </Stack>
          </Box>
        )}
      </Drawer>
    </Box>
  );
}

/**
 * Generates mock family data.
 */
function generateMockFamilyData(incidentName: string, count = 100): Family[] {
  const statuses: Family['status'][] = ['Active', 'Pending', 'Closed'];
  const familyCount = Math.min(count, familyNames.length);
  const shuffledNames = shuffleArray(familyNames);
  const shuffledNotes = shuffleArray(mockNotes);

  return Array.from({ length: familyCount }, (_, i) => ({
    id: i + 1,
    name: shuffledNames[i % shuffledNames.length],
    contact: `555-000-${1000 + i}`,
    status: statuses[Math.floor(Math.random() * statuses.length)],
    incident: incidentName,
    notes: shuffledNotes[i % shuffledNotes.length],
    appointment: new Date(Date.now() + Math.floor(Math.random() * 10 * 24 * 60 * 60 * 1000)),
  }));
}

// Utility function to shuffle an array.
function shuffleArray<T>(array: T[]): T[] {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

const familyNames: string[] = [
  'Anderson',
  'Brown',
  'Clark',
  'Davis',
  'Evans',
  'Franklin',
  'Garcia',
  'Harris',
];

const mockNotes: string[] = [
  'Scheduled a follow-up meeting next week.',
  'Awaiting documents from the family.',
  'Family requires immediate financial assistance.',
];
