import DeleteIcon from '@mui/icons-material/Delete';
import DriveFolderIconUpload from '@mui/icons-material/DriveFolderUpload';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../../../../utils/auth/AuthService';

const { VITE_API_URL } = import.meta.env;
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSuccessUpload: () => void;
}

interface FileWithPreview extends File {
  preview: string;
}

const baseStyle = {
  minWidth: 550,
  minHeight: 400,
  border: '3px dashed #ccc',
  backgroundColor: '#eee',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border .24s ease-in-out',
};

const VideoDropzone: React.FC<Props> = ({ isOpen, setIsOpen, onSuccessUpload }) => {
  const { user } = useAuth(); // Make sure your useAuth hook is typed correctly
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPreview[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  // const [files, setFiles] = useState<File[]>([]);

  const handleClose = (): void => {
    setIsOpen(false);
    setAcceptedFiles([]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { 'video/mp4': ['.mp4'] },
    onDrop: (accepted: File[]) => {
      const mappedFiles: FileWithPreview[] = accepted.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setAcceptedFiles(mappedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? { borderColor: '#2196f3' } : {}),
      ...(isDragAccept ? { borderColor: '#00e676' } : {}),
      ...(isDragReject ? { borderColor: '#ff1744' } : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleUpload = async (): Promise<void> => {
    const controller = new AbortController();
    const { signal } = controller;
    setAbortController(controller);
    setUploadInProgress(true);

    const formData = new FormData();
    acceptedFiles.forEach((file, index) => {
      formData.append(`qryParams[${index}].Video`, file);

      const userSeqValue = user?.userSeq ? user.userSeq.toString() : 'defaultUserSeqValue';
      formData.append(`qryParams[${index}].UserSeq`, userSeqValue);
    });

    try {
      const response = await fetch(`${VITE_API_URL}videos/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: formData,
        signal,
      });

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Upload successful:', result);
      onSuccessUpload();
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Upload canceled');
      } else if (error instanceof Error) {
        console.error('Error uploading video:', error.message);
      } else {
        console.error('An unexpected error occurred');
      }
    } finally {
      setUploadInProgress(false);
      handleClose();
      setAbortController(null);
    }
  };

  useEffect(() => {
    return () => acceptedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }, [acceptedFiles]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Upload Video</DialogTitle>
      <DialogContent>
        <Box component='div' {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Box sx={{ textAlign: 'center' }} component='div'>
            <DriveFolderIconUpload sx={{ mt: 2, color: '#aaa', fontSize: 60 }} />
            <Typography>Drag & drop some files here, or click to select files</Typography>
            <Typography variant='body2' sx={{ marginTop: 2 }}>
              (Only *.mp4 videos will be accepted)
              <Typography variant='body2' sx={{ marginTop: 2, color: 'orange' }}>
                Depending on the file size, uploads might take a while. Please be patient!
              </Typography>
            </Typography>
          </Box>
        </Box>
        {acceptedFiles.length > 0 && (
          <List>
            {acceptedFiles.map((file, index) => (
              <ListItem key={index}>
                <DriveFolderIconUpload />
                <ListItemText primary={file.name} secondary={`Size: ${file.size} bytes`} />
                <IconButton
                  edge='end'
                  onClick={() => {
                    setAcceptedFiles(current => current.filter(f => f !== file));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        {uploadInProgress && (
          <Button
            onClick={() => {
              abortController?.abort(); // Abort the ongoing fetch request
              setUploadInProgress(false);
            }}
            color='secondary'
          >
            Cancel Upload
          </Button>
        )}
        {!uploadInProgress && <Button onClick={handleClose}>Close</Button>}
        <Button
          startIcon={<DriveFolderIconUpload />}
          onClick={handleUpload}
          color='primary'
          disabled={uploadInProgress || acceptedFiles.length === 0}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoDropzone;
