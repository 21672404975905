import { Button, Modal, Stack, useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { animated, useSpring } from '@react-spring/web';
import React from 'react';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { children, in: open, onClick, onEnter, onExited, ownerState, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

interface TimeoutWarningModalProps {
  isOpen: boolean;
  onExtend: () => void;
  onLogout: () => void;
  remainingTime: number;
}

export const TimeoutWarningModal = ({
  isOpen,
  onExtend,
  onLogout,
  remainingTime,
}: TimeoutWarningModalProps) => {
  const theme = useTheme();
  const { hasUnsavedChanges } = useUnsavedChanges();

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Custom onClose handler to prevent dismissal via backdrop click or ESC key
  const handleClose = (event: React.SyntheticEvent<any>, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      // Ignore the event to prevent modal from closing
      return;
    }
    // Optionally, handle other reasons if necessary
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose} // Prevents closing via backdrop or ESC
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
      sx={{ alignItems: 'center', justifyContent: 'center' }}
      disableEscapeKeyDown // Additionally ensures ESC doesn't close the modal
    >
      <Fade in={isOpen}>
        <Stack
          spacing={4}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', md: '400px' },
            background: theme.palette.background.default,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant='h5' component='h2'>
            Session Expiring Soon!
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {hasUnsavedChanges ? (
              <span style={{ color: 'red' }}>You have unsaved changes!</span>
            ) : null}
            <br />
            Your session will expire soon. Do you want to log out or continue with your work?
          </Typography>
          <Stack direction='row' spacing={2} justifyContent='flex-end'>
            <Button variant='outlined' color='error' onClick={onLogout}>
              Log out ({formatTime(remainingTime)})
            </Button>
            <Button variant='contained' color='primary' onClick={onExtend}>
              Extend and Continue
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};
