import { Box, Chip, Paper, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';

export function HomicidesChiclet() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(0);
  const navigate = useNavigate();
  const { VITE_API_URL } = import.meta.env;

  const handleChicletClick = () => {
    navigate('/reports/homicide');
  };

  useEffect(() => {
    if (user === null) return;

    if (user && user.token) {
      fetch(VITE_API_URL + 'getHomicidesChiclet', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setData(data);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      console.log('user or token is null');
    }
  }, [user]);

  return loading ? (
    <Skeleton
      variant='rounded'
      width='100%'
      height='100%'
      sx={{
        borderRadius: 30,
      }}
    />
  ) : (
    <Paper sx={{ p: 0, borderRadius: 20 }}>
      <Chip
        sx={{
          height: '100%',
          width: '100%',
        }}
        label={
          <Box component='div' sx={{ xs: { p: 0, m: 0 }, md: { p: 0.5, m: 0.5 } }}>
            <Typography variant='h6'>
              Homicides: <b>{data}</b>
            </Typography>
            <Typography variant='caption'>
              <i>YTD</i>
            </Typography>
          </Box>
        }
        onClick={handleChicletClick}
      />
    </Paper>
  );
}
