import { Typography } from '@mui/material';
import CustomHeader from '../../utils/components/CustomHeader';
import ReportDataGrid from '../../utils/components/ReportDataGrid/ReportDataGrid';
import { useState } from 'react';

export default function AccountRequestView() {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <CustomHeader
        title='Account Requests'
        description='View and manage account change requests'
      />

      <ReportDataGrid
        // title='Account Requests'
        listresults={[]}
        columnsInput={[]}
        loading={loading}
        idcolumn=''
      />
    </>
  );
}
