import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Snackbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Document, Font, Page, StyleSheet, Text, View, pdf, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import SansBold from '../../assets/fonts/Open_Sans/static/OpenSans-Bold.ttf';
import SansRegular from '../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf';
import { useAuth } from '../auth/AuthService';
import { convertSvgToPng } from '../../utils/functions/convertSvgToPng';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: SansRegular, fontWeight: 'normal' },
    { src: SansBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 11,
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    paddingBottom: 20,
  },
  stamp: {
    height: '1.5in',
    backgroundColor: '#eeeeee',
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  row: {
    position: 'relative',
    marginLeft: 5,
  },
  contentRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  keyValuePair: {
    flexDirection: 'row',
  },
  contentKeyLeft: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  contentValueLeft: {
    marginLeft: 3,
  },
  contentKeyRight: {
    position: 'absolute',
    left: 400,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  contentValueRight: {
    position: 'absolute',
    left: 435,
    fontWeight: 300,
  },
  boldUnderlineText: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  boldColumn: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    width: 120,
  },
  content: {
    width: 120,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 12,
  },
  text: {
    lineHeight: 1.4,
  },
  sansText: {
    // fontFamily: 'Open Sans',
    // fontWeight: 'normal',
    lineHeight: 1.4,
  },
  spaceBreak: {
    marginTop: 20,
  },
  smallSpaceBreak: {
    marginTop: 10,
  },
  section: {
    marginBottom: 5,
    marginLeft: 5,
  },
  footer: {
    marginTop: 10,
    borderTop: 1,
    paddingTop: 2,
    textAlign: 'left',
    fontSize: 8,
  },
  redacted: {
    width: 50,
    height: 10,
    backgroundColor: 'black',
  },
  boldText: {
    fontWeight: 'bold',
  },
  headerContainer: {
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 100, 
    height: 100, 
    marginBottom: 8,
  },
  reportText: {
    fontSize: 11,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 8,
    paddingBottom: 5,
    lineHeight: 1.05,
  },
});

const formatName = name => {
  if (!name) return ''; // Return an empty string if no name is provided

  const [lname, fname] = name.split(',').map(part => part.trim());

  return `${fname} ${lname}`;
};

const splitDateTime = dateTimeString => {
  if (!dateTimeString) {
    return { date: null, time: null };
  }

  const [date, timeWithMilliseconds] = dateTimeString.split('T');
  const time = timeWithMilliseconds?.split('.')?.[0];

  return { date, time };
};

const INVDataSheet: React.FC<{
  formik: ReturnType<typeof useFormikContext<any>>;
  bodyLocationState: string;
  bodyLocationCountry: string;
  redactedFields: string[];
  reportHeader: string;
  reportAddress: string;
}> = ({
  formik,
  bodyLocationState,
  bodyLocationCountry,
  redactedFields,
  reportHeader,
  reportAddress,
}) => {
  const caseInvest = formik.values?.caseInvestigations;
  const caseDemo = formik.values?.caseDemographics;
  const logoImage = convertSvgToPng(formik.values?.caseSummary.jdxLogo);

  const { date, time } = splitDateTime(caseInvest?.meNotified);

  const next_of_kin = formik.values?.caseDemographics?.informantList
    ?.filter(informant => informant.nextOfKin == 1)
    .map(informant => ({
      name: `${informant.personLastName}, ${informant.personFirstName}`,
      relationship: informant?.relship?.optionName || null,
      contactInfo: informant?.informantAddress?.contactItem?.[0]?.contactItemDetails || null,
    }));

  const identification = caseDemo?.identifications
    ?.filter(identif => identif.isAccept == true)
    .map(identif => {
      const kinMatch = caseDemo?.informantList.find(
        informant => informant.informantSeq === `${identif?.identifiedBy?.optionSeq}`
      );

      return {
        name: `${identif?.identifiedBy?.optionName}`,
        modality: identif?.modality?.optionName || null,
        relationship: kinMatch?.relship?.optionName || '',
      };
    });

  const medications = caseInvest?.medicationHistory?.map(med => med.medicationName).join(', ');

  const medicalHistory = caseInvest?.pastMedicalHistory
    ?.map(med =>
      med.optionName === 'Other' ? caseInvest?.pastMedicalHistoryOther : med.optionName
    )
    .join(', ');

  const processList = (sourceList, fieldName, otherFormikField) => {
    return (
      sourceList
        ?.map(item => {
          let itemName = item[fieldName]?.optionName;

          if (itemName == 'Other' && item[otherFormikField]) {
            itemName = item[otherFormikField];
          }

          return itemName ? { item: itemName } : null;
        })
        .filter(Boolean) || []
    );
  };

  const clothingList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyClothing,
    'clothingItem',
    'otherClothing'
  );
  const documentList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyDocument,
    'documentItem',
    'otherDocument'
  );
  const currencyList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyCurrency,
    'documentItem',
    'otherDocument'
  );
  const jewelryList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyJewelry,
    'jewelryItem',
    'otherJewelry'
  );
  const evidenceList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyEvidence,
    'evidenceItem',
    'otherEvidence'
  );
  const otherPIList = processList(
    formik.values?.caseMortuary?.propertyInventory?.otherPI,
    'personalItem',
    'otherPropInventory'
  );

  const allItemsList = {
    clothing: clothingList.length > 0 ? clothingList : null,
    documents: documentList?.length > 0 ? documentList : null,
    evidence: evidenceList?.length > 0 ? evidenceList : null,
    jewelry: jewelryList?.length > 0 ? jewelryList : null,
    otherPI: otherPIList?.length > 0 ? otherPIList : null,
  };

  const formatLocation = (address, city, state, zip) => {
    const addressParts = [
      address?.toUpperCase() ?? '',
      city?.toUpperCase() ?? '',
      state?.toUpperCase() ?? '',
      zip?.toUpperCase() ?? '',
    ];

    const formattedAddress = addressParts.filter(Boolean).join(', ').trim();

    return formattedAddress || '';
  };

  const formatTextWithBoldDates = text => {
    // Accepts 8/10/21, 08/10/2021, 8-10-21, 13:13, 1:13PM, 01:13PM
    const dateTimeRegex =
      /\b(\d{1,2}\/\d{1,2}\/\d{2,4}|\d{1,2}-\d{1,2}-\d{2,4}|\d{1,2}:\d{2}(?:\s?[AP]M)?)\b/g;

    const parts = text.split(dateTimeRegex).map((part, index) => {
      if (dateTimeRegex.test(part)) {
        return (
          <Text key={index} style={styles.contentKeyLeft}>
            {part}
          </Text>
        );
      }
      return <Text key={index}>{part}</Text>;
    });

    return <Text>{parts}</Text>;
  };
   
  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.stamp}>
  <View style={{ position: 'relative', width: '100%', height: '100%' }}>
    {/* Logo on the Left */}
    {logoImage && (
      <Image
        src={logoImage}
        style={{
          width: 100, 
          height: 100, 
          position: 'absolute', 
          left: 20,
          top: '50%', 
          transform: 'translateY(-50%)',
        }}
      />
    )}

    {/* Centered Header */}
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', 
      }}
    >
      <Text style={styles.reportText}>{reportHeader ?? ''}</Text>
      <Text style={styles.reportText}>{reportAddress ?? ''}</Text>
    </View>
  </View>
</View>
        {/* Title */}
        <Text style={styles.title}>INVESTIGATIVE DATA SHEET</Text>

        {/* Content Row */}
        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>Pronouncement Date:</Text>
            <Text style={styles.contentValueLeft}>
              {formik.values?.caseDemographics?.pronouncer?.pronounciationDateTime
                ? dayjs(formik.values?.caseDemographics?.pronouncer?.pronounciationDateTime).format(
                    'MM/DD/YYYY HH:mm'
                  )
                : 'Unknown at this time.'}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>DECEDENT:</Text>
            {redactedFields && redactedFields.includes('decedentName') ? (
              formik.values?.caseSummary.decedentInfo?.decedentName ? (
                <View style={styles.redacted} />
              ) : (
                <Text style={styles.contentValueLeft}>-</Text>
              )
            ) : (
              (
                <Text style={styles.contentValueLeft}>
                  {formik.values?.caseSummary.decedentInfo?.decedentName}
                </Text>
              ) || <Text style={styles.contentValueLeft}>-</Text>
            )}
          </View>
          <Text style={styles.contentKeyRight}>DATE:</Text>
          <Text style={styles.contentValueRight}>{date && date}</Text>
        </View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>CASEID:</Text>
            <Text style={styles.contentValueLeft}>
              {formik.values?.caseSummary?.caseNumber ?? '-'}
            </Text>
          </View>

          <Text style={styles.contentKeyRight}>TIME:</Text>
          <Text style={styles.contentValueRight}>{time && time}</Text>
        </View>

        <View style={styles.spaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>CASE SYNOPSIS:</Text>
          <Text style={styles.text}>{formik.values?.caseInvestigations?.synopsis ?? '-'}</Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>INITIAL REPORTER:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest?.caseReporter && formatName(caseInvest?.caseReporter) + ', '}
              {formik.values?.caseReporterSummary?.agency?.optionName ?? ''}
            </Text>
          </View>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>LOCATION OF BODY:</Text>

            {redactedFields && redactedFields.includes('bodyLocation') ? (
              caseInvest?.bodyLocation[0]?.addressLine1 ? (
                <View style={styles.redacted} />
              ) : (
                <Text style={styles.contentValueLeft}></Text>
              )
            ) : (
              (
                <Text style={styles.contentValueLeft}>
                  {formatLocation(
                    caseInvest?.bodyLocation[0]?.addressLine1,
                    caseInvest?.bodyLocation?.[0]?.city,
                    bodyLocationState,
                    caseInvest?.bodyLocation?.[0]?.zip
                  )}
                </Text>
              ) || <Text style={styles.contentValueLeft}></Text>
            )}
          </View>
        </View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>INITIAL INFORMATION REPORTED:</Text>
          <Text style={styles.text}>
            {caseInvest?.deathInvestigationAdditionalInformation?.initialInfo ?? ''}
          </Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.section}>
          <Text style={styles.boldUnderlineText}>INVESTIGATION:</Text>
          {formatTextWithBoldDates(
            caseInvest?.deathInvestigationAdditionalInformation?.investigationInfo ?? ''
          )}
        </View>

        <View style={styles.smallSpaceBreak}></View>

        {/** Next of kin */}
        <View style={styles.section}>
          {next_of_kin?.length === 0 ||
            (next_of_kin == undefined && (
              <View style={{ flexDirection: 'row' }}>
                <View style={styles.keyValuePair}>
                  <Text style={styles.boldUnderlineText}>NEXT OF KIN:</Text>
                  <Text style={styles.contentValueLeft}> </Text>
                </View>
              </View>
            ))}

          {next_of_kin?.length > 0 && (
            <View>
              <Text style={styles.boldUnderlineText}>NEXT OF KIN:</Text>

              {/* Table Headers */}
              <View style={styles.contentRow}>
                <Text style={styles.boldColumn}>Name</Text>
                <Text style={styles.boldColumn}>Relationship</Text>
                <Text style={styles.boldColumn}>Contact Info</Text>
              </View>

              {/* Table Content */}
              {next_of_kin.map((kin, index) => (
                <View key={index} style={styles.contentRow}>
                  <Text style={styles.content}>{kin.name ?? '-'}</Text>
                  <Text style={styles.content}>{kin.relationship ?? '-'}</Text>
                  {redactedFields && redactedFields.includes('contactInfo') ? (
                    kin.contactInfo ? (
                      <View style={styles.redacted} />
                    ) : (
                      <Text style={styles.content}>-</Text>
                    )
                  ) : (
                    <Text style={styles.content}>{kin.contactInfo || '-'}</Text>
                  )}
                </View>
              ))}
            </View>
          )}
        </View>

        {/**Identification */}
        <View style={styles.section}>
          {identification?.length === 0 ||
            (identification == undefined && (
              <View style={{ flexDirection: 'row' }}>
                <View style={styles.keyValuePair}>
                  <Text style={styles.boldUnderlineText}>IDENTIFIED BY:</Text>
                  <Text style={styles.contentValueLeft}></Text>
                </View>
              </View>
            ))}

          {identification?.length > 0 && (
            <View>
              <Text style={styles.boldUnderlineText}>IDENTIFIED BY:</Text>

              {/* Table Headers */}
              <View style={styles.contentRow}>
                <Text style={styles.boldColumn}>Identified By</Text>
                <Text style={styles.boldColumn}>Relationship</Text>
                <Text style={styles.boldColumn}>Modality</Text>
              </View>

              {identification?.map((id, index) => (
                <View style={styles.contentRow}>
                  <Text style={styles.content}>{id.name ?? ' '}</Text>
                  <Text style={styles.content}>{id.relationship ?? ' '}</Text>
                  <Text style={styles.content}>{id.modality ?? ' '}</Text>
                </View>
              ))}
            </View>
          )}
        </View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>CONFIRMED ID DIRECTLY FROM SOURCE:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest?.deathInvestigationAdditionalInformation?.confirmedIdFromSource
                ? 'Yes'
                : 'No'}
            </Text>
          </View>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>WITNESS/FAMILY STATEMENT:</Text>
          <Text style={styles.text}>
            {caseInvest?.deathInvestigationAdditionalInformation?.witnessFamilyStatement ??
              'None At This Time'}
          </Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>PMD:</Text>
            <Text style={styles.contentValueLeft}>
              {formik.values?.caseDemographics?.privatePhysician?.lastName &&
              formik.values?.caseDemographics?.privatePhysician?.firstName
                ? `${formik.values?.caseDemographics?.privatePhysician?.firstName} ${formik.values?.caseDemographics?.privatePhysician?.lastName}`
                : ' '}
            </Text>
          </View>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>MEDICAL HX:</Text>

          {redactedFields && redactedFields.includes('medicalHistory') ? (
            medicalHistory ? (
              <View style={styles.redacted} />
            ) : (
              <Text style={styles.text}></Text>
            )
          ) : (
            <Text style={styles.text}>{medicalHistory || ' '}</Text>
          )}
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>MEDICATIONS:</Text>
          <Text style={styles.text}>{medications || ' '}</Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>SOCIAL HX:</Text>
          <Text style={styles.text}>{caseInvest?.pastSocialHistory ?? ' '}</Text>
        </View>
        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.section}>
          {/* Check if there are any items in the allItemsList */}
          {Object.entries(allItemsList).some(([_, items]) => items && items.length > 0) ? (
            <View>
              <Text style={styles.boldUnderlineText}>PROPERTY COLLECTED:</Text>

              {Object.entries(allItemsList).map(([category, items], index) => {
                if (items && items.length > 0) {
                  return (
                    <View key={index} style={{ flexDirection: 'row' }}>
                      <Text style={styles.contentKeyLeft}>{category.toUpperCase()}:</Text>
                      <Text style={styles.contentValueLeft}>
                        {items.map(item => item.item).join(', ')}
                      </Text>
                    </View>
                  );
                } else {
                  return null;
                }
              })}
            </View>
          ) : (
            // Render fallback if no items exist
            <View style={{ flexDirection: 'row' }}>
              <View style={styles.keyValuePair}>
                <Text style={styles.boldUnderlineText}>PROPERTY COLLECTED:</Text>
                <Text style={styles.contentValueLeft}>None</Text>
              </View>
            </View>
          )}
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>LIVERY:</Text>
            <Text style={styles.contentValueLeft}>{caseInvest?.liveryUser?.optionName ?? ' '}</Text>
          </View>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>MLDI:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest.assignedInvestigator.optionName ?? ' '}
            </Text>
          </View>
          <Text style={styles.contentKeyRight}>POC:</Text>
          <Text style={styles.contentValueRight}>
            {formik.values?.caseMortuary?.assignedUser.optionName ?? ' '}
          </Text>
        </View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>DECEDENT:</Text>
            {redactedFields && redactedFields.includes('decedentName') ? (
              formik.values?.caseSummary.decedentInfo?.decedentName ? (
                <View style={styles.redacted} />
              ) : (
                <Text style={styles.contentValueLeft}>-</Text>
              )
            ) : (
              (
                <Text style={styles.contentValueLeft}>
                  {formik.values?.caseSummary.decedentInfo?.decedentName}
                </Text>
              ) || <Text style={styles.contentValueLeft}>-</Text>
            )}
          </View>
          <Text style={styles.contentKeyRight}>CASE# </Text>
          <Text style={styles.contentValueRight}>
            {formik.values?.caseSummary?.caseNumber ?? ' '}
          </Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>
        <View style={styles.spaceBreak}></View>

        <View style={{ marginTop: 10, borderTopWidth: 1, paddingTop: 2, alignItems: 'center' }}>
          <Text style={{ fontSize: 8, fontWeight: 'bold', color: '#888' }}>
            INFORMATION IS PRELIMINARY AND SUBJECT TO CHANGE
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const GenerateINVDataSheetButton: React.FC<{ label: string }> = ({ label }) => {
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext<any>();
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
  const [redactDialogOpen, setRedactDialogOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [reportHeader, setReportHeader] = useState([]);
  type SeverityType = 'error' | 'info' | 'success' | 'warning';
  const [alertState, setAlertState] = useState<{
    open: boolean;
    severity: SeverityType;
    message: string;
  }>({
    open: false,
    severity: 'success',
    message: '',
  });

  const showAlert = (message, severity) => {
    setAlertState({ open: true, message, severity });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchStateOptions = async () => {
    fetch(VITE_API_URL + 'getstateoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setStateOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };
  const fetchCountryOptions = async () => {
    fetch(VITE_API_URL + 'getcountryoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCountryOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };
  const fetchReportHeader = async () => {
    const qryParams = {
      CountySeq: formik?.values?.caseSummary?.jdxSeq,
    };

    const url = `${VITE_API_URL}getreportheader?${new URLSearchParams(qryParams)}`;

    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setReportHeader(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchStateOptions();
    fetchCountryOptions();
    fetchReportHeader();
  }, [formik.values]);

  const matchSeq = (options, seq) => {
    return options.find(option => option.optionSeq === seq)?.optionName || '';
  };

  const stateseqToFind =
    formik.values?.caseInvestigations?.bodyLocation?.[0]?.stateSeq?.toUpperCase() || '';
  const countrySeqToFind =
    formik.values?.caseInvestigations?.bodyLocation?.[0]?.countrySeq?.toUpperCase() || '';

  const bodyLocationState = matchSeq(stateOptions, stateseqToFind);
  const bodyLocationCountry = matchSeq(countryOptions, countrySeqToFind);

  const formattedReportHeader = reportHeader?.[0]?.reportHeaderName
    ? reportHeader?.[0]?.reportHeaderName.replace(/<br\/>/g, '\n')
    : undefined;

  // console.log('I yearn for the selected fields: ', selectedFields);
  useEffect(() => {
    const generatePdfBlob = async () => {
      const blob = await pdf(
        <INVDataSheet
          formik={formik}
          bodyLocationState={bodyLocationState}
          bodyLocationCountry={bodyLocationCountry}
          redactedFields={selectedFields}
          reportHeader={formattedReportHeader}
          reportAddress={reportHeader?.[0]?.reportAddress}
        />
      ).toBlob();

      const blobUrl = URL.createObjectURL(blob);
      setPdfBlobUrl(blobUrl);
    };

    generatePdfBlob();
  }, [
    formik.values,
    bodyLocationState,
    bodyLocationCountry,
    selectedFields,
    formattedReportHeader,
    reportHeader?.[0]?.reportAddress,
  ]);

  const handleClick = async () => {
    if (!user || !user.token || !user.userSeq) {
      alert("User information is missing. Please ensure you're logged in.");
      return;
    }

    if (uploadInProgress) {
      alert('please wait while we upload the document');
      return;
    }

    setOpenModal(true);
  };

  const handleFileUpload = async pdfFile => {
    setUploadInProgress(true);
    setUploadSuccess(false);

    const formDataUploadFiles = new FormData();
    formDataUploadFiles.append(
      'FILEPARAMS[0].CASENO',
      formik.values?.caseSummary?.caseInformation?.caseID
    );
    formDataUploadFiles.append(
      'FILEPARAMS[0].CASESEQ',
      formik.values?.caseSummary?.caseInformation?.caseSeq
    );
    formDataUploadFiles.append(
      'FILEPARAMS[0].FILERELATEDTO',
      'd3bc96a5-79e9-411f-8c3e-0c3c8ad97e53'
    );
    formDataUploadFiles.append('FILEPARAMS[0].FILERELATEDTONAME', 'Data Sheet');
    formDataUploadFiles.append('FILEPARAMS[0].FILE', pdfFile);
    formDataUploadFiles.append('USERSEQ', user?.userSeq);

    try {
      const response = await fetch(VITE_API_URL + 'fileuploads', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formDataUploadFiles,
      });

      const data = await response.json();
      if (response.ok && data.status === 0) {
        setUploadSuccess(true);
      }
    } catch (error) {
      alert('Error during file upload: ' + error.message);
    } finally {
      setUploadInProgress(false);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDownload = file_name => {
    if (pdfBlobUrl) {
      const a = document.createElement('a');
      a.href = pdfBlobUrl;
      a.download = `${file_name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleSaveToMedexLab = async file_name => {
    if (pdfBlobUrl) {
      try {
        const blob = await fetch(pdfBlobUrl).then(r => r.blob());
        const pdfFile = new File([blob], `${file_name}.pdf`, {
          type: 'application/octet-stream',
        });

        const result = (await formik.submitForm()) as unknown as boolean;

        if (result) {
          await handleFileUpload(pdfFile);
          showAlert('File successfully saved to MedexLab', 'success');
        } else {
          showAlert('Could Not Save. Please Double Check Your Information In MedexLab', 'error');
        }
      } catch (error) {
        showAlert(`An error occurred: ${error.message}`, 'error');
      } finally {
        handleClose();
      }
    } else {
      showAlert('Failed to generate the PDF file. Please try again.', 'error');
    }
  };

  const handleRedactFields = () => {
    setRedactDialogOpen(true);
  };

  const handleCloseRedactDialog = () => {
    setRedactDialogOpen(false);
  };

  const redactionOptions = [
    { label: 'Decedent Name', value: 'decedentName' },
    { label: 'Body Location', value: 'bodyLocation' },
    { label: 'Contact Info', value: 'contactInfo' },
    { label: 'Medical History', value: 'medicalHistory' },
  ];

  const preview_name = formik.values?.caseSummary?.caseNumber
    ? selectedFields.length > 0
      ? `${formik.values.caseSummary.caseNumber}-Investigative Data Sheet-REDACTED`
      : `${formik.values.caseSummary.caseNumber}-Investigative Data Sheet`
    : 'Investigative Data Sheet';

  return (
    <div style={{ textAlign: 'center' }}>
      <Button variant='contained' color='primary' onClick={handleClick} disabled={isUploading}>
        {isUploading ? 'File Saved' : label}
      </Button>

      {isUploading && (
        <div style={{ marginTop: '10px' }}>
          <CircularProgress size={24} />
        </div>
      )}

      {uploadSuccess && (
        <div style={{ marginTop: '10px', color: 'green' }}>
          <CheckCircleIcon style={{ verticalAlign: 'middle' }} />
          <span style={{ marginLeft: '5px' }}>File Uploaded Successfully!</span>
        </div>
      )}

      <Dialog open={openModal} onClose={handleClose} maxWidth='md' fullWidth>
        <Box display='flex' alignItems='center' justifyContent='space-between' padding='16px'>
          <DialogTitle style={{ margin: 0, padding: 0 }}>{preview_name}</DialogTitle>
          <Box>
            <Dialog open={redactDialogOpen} onClose={handleCloseRedactDialog}>
              <DialogTitle>Select Fields to Redact</DialogTitle>
              <DialogContent>
                <FormGroup>
                  {redactionOptions.map(field => (
                    <FormControlLabel
                      key={field.value}
                      control={
                        <Checkbox
                          checked={selectedFields.includes(field.value)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const checked = event.target.checked;
                            setSelectedFields(prev => {
                              if (checked) {
                                return [...prev, field.value];
                              } else {
                                return prev.filter(value => value !== field.value);
                              }
                            });
                          }}
                        />
                      }
                      label={field.label}
                    />
                  ))}
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRedactDialog}>Close</Button>
              </DialogActions>
            </Dialog>

            <Button onClick={handleRedactFields} color='primary' style={{ marginRight: 8 }}>
              Redact
            </Button>

            <Button
              onClick={() => handleDownload(preview_name)}
              color='primary'
              style={{ marginRight: 8 }}
            >
              Download PDF
            </Button>
            <Button
              onClick={() => handleSaveToMedexLab(preview_name)}
              color='secondary'
              style={{ marginRight: 8 }}
            >
              Save to Documents
            </Button>
            <Button onClick={handleClose} color='error'>
              Close
            </Button>
          </Box>
        </Box>
        <DialogContent dividers>
          {pdfBlobUrl && (
            <iframe
              src={`${pdfBlobUrl}#zoom=page-fit&view=FitH&toolbar=0`}
              style={{
                width: isMobile ? '70%' : '100%',
                minHeight: isMobile ? '30vh' : '80vh',
                border: 'none',
              }}
            />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            minWidth: '1200px',
            textAlign: 'center',
          },
        }}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
          sx={{
            width: '100%',
            fontSize: '1.2rem',
          }}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GenerateINVDataSheetButton;
