import { DoneAll } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { format, isSameDay, parseISO } from 'date-fns';
import { AnimatePresence, motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useRef } from 'react';
import MessageContent from './MessageContent';
import { shouldMaskNameAsSupportAgent } from './conversation.types';

const SwipeableMessage = ({ message, isUserSender, user, children }) => {
  const x = useMotionValue(0);
  const opacity = useTransform(
    x,
    isUserSender ? [-50, 0, 50] : [-50, 0, 50],
    isUserSender ? [1, 0, 0] : [0, 0, 1]
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <motion.div style={{ opacity: isUserSender ? opacity : 0 }}>
        <Typography
          variant='body2'
          sx={{
            position: 'absolute',
            right: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            pr: 1,
            color: 'text.secondary',
            fontSize: '0.75rem',
          }}
        >
          {formatMessageDateAlwaysIncludeTime(message.sentAt)}
        </Typography>
      </motion.div>

      <motion.div style={{ opacity: isUserSender ? 0 : opacity }}>
        <Typography
          variant='body2'
          sx={{
            position: 'absolute',
            left: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            pl: 1,
            color: 'text.secondary',
            fontSize: '0.75rem',
          }}
        >
          {formatMessageDateAlwaysIncludeTime(message.sentAt)}
        </Typography>
      </motion.div>

      <motion.div
        drag='x'
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.1}
        style={{ x }}
        whileTap={{ cursor: 'grabbing' }}
      >
        {children}
      </motion.div>
    </Box>
  );
};

const ParticipantHistoryEvent = ({ event, supportAgentName, user }) => {
  const getEventMessage = () => {
    const participantName = shouldMaskNameAsSupportAgent(
      { sender: event },
      event.conversationSeq,
      user
    )
      ? supportAgentName
      : `${event.personFirstName} ${event.personLastName}`;

    const addedByName = event.addedBy
      ? shouldMaskNameAsSupportAgent({ sender: event.addedBy }, event.conversationSeq, user)
        ? supportAgentName
        : `${event.addedBy.personFirstName} ${event.addedBy.personLastName}`
      : null;

    const removedByName = event.removedBy
      ? shouldMaskNameAsSupportAgent({ sender: event.removedBy }, event.conversationSeq, user)
        ? supportAgentName
        : `${event.removedBy.personFirstName} ${event.removedBy.personLastName}`
      : null;

    if (event.removedBy) {
      return `${removedByName} removed ${participantName}`;
    } else if (event.addedBy) {
      return `${addedByName} added ${participantName}`;
    } else {
      return `${participantName} joined the conversation`;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        my: 1,
      }}
    >
      <Typography
        variant='body2'
        sx={{
          color: 'text.secondary',
          fontSize: '0.75rem',
          bgcolor: theme =>
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)',
          px: 2,
          py: 0.5,
          borderRadius: 4,
        }}
      >
        {getEventMessage()} • {formatMessageDateAlwaysIncludeTime(event.timestamp)}
      </Typography>
    </Box>
  );
};

const ConversationMessages = ({
  messages,
  participantHistory = [],
  isSender,
  user,
  supportAgentName,
  handleMessageRead,
}) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Combine messages and participant history events, sort by timestamp
  const allEvents = [
    ...messages.map(m => ({
      type: 'message',
      timestamp: m.sentAt,
      data: m,
    })),
    ...participantHistory.map(p => ({
      type: 'participant',
      timestamp: p.timestamp,
      data: p,
    })),
  ].sort((a, b) => parseISO(a.timestamp).getTime() - parseISO(b.timestamp).getTime());

  // Group messages while preserving participant history events
  const eventGroups = allEvents.reduce((groups, event, index) => {
    if (event.type === 'participant') {
      groups.push([event]);
      return groups;
    }

    const prevEvent = allEvents[index - 1];
    const shouldGroup =
      prevEvent?.type === 'message' &&
      event.type === 'message' &&
      event.data.senderUserSeq === prevEvent.data.senderUserSeq &&
      isSameDay(parseISO(event.timestamp), parseISO(prevEvent.timestamp));

    if (shouldGroup) {
      groups[groups.length - 1].push(event);
    } else {
      groups.push([event]);
    }
    return groups;
  }, []);

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <AnimatePresence>
        {eventGroups.map((group, groupIndex) => {
          const firstEvent = group[0];

          // Handle participant history event
          if (firstEvent.type === 'participant') {
            return (
              <motion.div
                key={`participant-${firstEvent.data.chatParticipantHistorySeq}`}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
              >
                <ParticipantHistoryEvent
                  event={firstEvent.data}
                  supportAgentName={supportAgentName}
                  user={user}
                />
              </motion.div>
            );
          }

          // Handle message group
          const isUserSender =
            firstEvent.data.senderUserSeq.toLowerCase() === user.userSeq?.toLowerCase();

          return (
            <motion.div
              key={`message-${firstEvent.data.messageSeq}`}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: isUserSender ? 'flex-end' : 'flex-start',
                  gap: 0.5,
                }}
              >
                <Typography variant='body2' color='text.secondary'>
                  {shouldMaskNameAsSupportAgent(
                    firstEvent.data,
                    firstEvent.data.conversationType?.conversationTypeSeq,
                    user
                  )
                    ? supportAgentName
                    : firstEvent.data.senderName}
                  {' • '}
                  {formatMessageDateAlwaysIncludeTime(firstEvent.data.sentAt)}
                </Typography>

                {group.map(event => (
                  <SwipeableMessage
                    key={event.data.messageSeq}
                    message={event.data}
                    isUserSender={isUserSender}
                    user={user}
                  >
                    <Box
                      sx={{
                        px: 1.5,
                        py: 1,
                        borderRadius: 2.5,
                        bgcolor: isUserSender
                          ? user.isDarkMode
                            ? 'rgb(10, 132, 255)'
                            : 'rgb(0, 122, 255)'
                          : user.isDarkMode
                          ? 'rgb(58, 58, 60)'
                          : 'rgb(229, 229, 234)',
                        color: isUserSender
                          ? 'white'
                          : user.isDarkMode
                          ? 'rgb(229, 229, 234)'
                          : 'black',
                        mb: 0.5,
                        touchAction: 'pan-x',
                      }}
                    >
                      <MessageContent
                        message={event.data}
                        onMessageRead={messageSeq => handleMessageRead(messageSeq, user.userSeq)}
                      />
                    </Box>
                  </SwipeableMessage>
                ))}

                {groupIndex === eventGroups.length - 1 &&
                  user.roleCheck(['451', '8f7']) &&
                  group[group.length - 1].data.readByCount > 0 && (
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{
                        fontSize: '0.6rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                      }}
                    >
                      <DoneAll sx={{ fontSize: '1rem' }} />
                      {group[group.length - 1].data.readBy
                        .filter(
                          reader => reader.userSeq.toLowerCase() !== user.userSeq.toLowerCase()
                        )
                        .map(reader => reader.userName)
                        .join(', ')}
                    </Typography>
                  )}
              </Box>
            </motion.div>
          );
        })}
      </AnimatePresence>
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default ConversationMessages;

export const formatMessageDateAlwaysIncludeTime = dateStr => {
  const date = parseISO(dateStr);
  const now = new Date();

  const isToday = date.toDateString() === now.toDateString();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);
  const isYesterday = date.toDateString() === yesterday.toDateString();
  const diffTime = now.getTime() - date.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const isThisYear = date.getFullYear() === now.getFullYear();

  if (isToday) {
    return format(date, 'h:mm a');
  }
  if (isYesterday) {
    return `Yesterday ${format(date, 'h:mm a')}`;
  }
  if (diffDays < 7) {
    return format(date, 'EEEE h:mm a');
  }
  if (isThisYear) {
    return format(date, 'MM/dd h:mm a');
  }
  return format(date, 'MM/dd/yyyy h:mm a');
};
