import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useAuth } from '../auth/AuthService';

export default function CustomTabPanel(props) {
  const { children, value, index, authorizedRoles, ...other } = props;
  const { user } = useAuth();

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3.1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
