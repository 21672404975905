import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { FormControlLabel, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

interface ReferenceMasterTableFilterProps {
  rows: any[]; 
  isCounty: boolean;
  countyNames: any; 
  setRowsToShow: (rows: any[]) => void;
}

const ReferenceTableFilter: React.FC<ReferenceMasterTableFilterProps> = ({
  rows,
  isCounty,
  countyNames,
  setRowsToShow,
}) => {
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');
  const [countyValue, setCountyValue] = useState<any | null>(null);
  const [searchName, setSearchName] = useState<string>('');

  const onFilter = () => {
    let newRows = rows;
    if (countyValue) {
      newRows = newRows.filter(row => row.countySeq == countyValue?.value);
    }

    if (searchName) {
      const searchLower = searchName?.toLowerCase();
      newRows = newRows?.filter(row =>
        Object.values(row).some(val =>
          String(val).toLowerCase().includes(searchLower)
        )
      );
      // newRows = newRows.filter(row => String(row.name)?.toLowerCase().includes(searchLower));
    }

    if (activeInActiveFilter == 'all') setRowsToShow(newRows);
    else if (activeInActiveFilter == 'Active')
      setRowsToShow(newRows.filter(row => row.status.toLowerCase() == 'active'));
    else setRowsToShow(newRows.filter(row => row.status.toLowerCase() != 'active'));
  };

  useEffect(() => {
    onFilter();
  }, [rows, activeInActiveFilter, countyValue, searchName]);

  const FormikAutocomplete = ({ ...props }) => {
    return (
      <Autocomplete
        {...props}
        onChange={(e, v) => {
          setCountyValue(v);
        }}
        value={countyValue?.label || ''}
        getOptionSelected={(item: any, current: any) => item.value === current.value}
        renderInput={props => <TextField {...props} label='Choose a Jurisdiction' />}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        {isCounty && (
          <Grid item lg={3} md={6} sm={12}>
            <FormControl error required component='fieldset' variant='standard'>
              <TextField
                name='county'
                style={{ width: '250px' }}
                component={FormikAutocomplete}
                label='Jurisdiction'
                options={Object.keys(countyNames).map(countyName => ({
                  label: countyNames[countyName].countyName,
                  value: countyNames[countyName].countySeq,
                  code: countyNames[countyName].code,
                }))}
                textFieldProps={{
                  variant: 'outlined',
                }}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item lg={3} md={6} sm={12}>
          <TextField
            fullWidth
            placeholder='Search'
            size='small'
            onChange={e => setSearchName(e.target.value)}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isActive'
            onChange={e => setActiveInActiveFilter(e.target.value)}
            value={activeInActiveFilter}
            sx={{ marginLeft: 1 }}
          >
            {' '}
            <FormControlLabel value='Active' control={<Radio />} label='Active Only' />{' '}
            <FormControlLabel value='Inactive' control={<Radio />} label='Inactive Only' />{' '}
            <FormControlLabel value='all' control={<Radio />} label='All' />{' '}
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default ReferenceTableFilter;
