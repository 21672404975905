import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  FormGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../services/utility-api';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';
import { HexColorPicker } from "react-colorful";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  isCode,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  departmentTypes,
  referencePermissions
}) => {
  const id = '00000000-0000-0000-0000-000000000000';
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [fromTime, setFromTime] = useState(editObj?.fromTime);
  useEffect(() => { }, [editObj]);

  const onSave = async (values) => {

    try {
      const response = await saveAdminData({
        values: {
          ...values,
          [referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Seq']:
            values[
            referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      setDialogContents(response.message);
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} mb={2} mt={2}>
          <Typography variant='h5'>
            <strong>
              {Object.keys(editObj).length ? 'Modify ' : 'Enter New Jurisdiction and Department Wise '}{' '}
              {referenceMasterTableName
                .split('')
                .map((alpha) => {
                  if (alpha <= 'Z') return ' ' + alpha;
                  return alpha;
                })
                .join('')
                .trim()}
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...editObj,
          colorCodeName: editObj['colorCodeName'] ?? '',
          colorCodeOrder: editObj?.colorCodeOrder
            ? Number(editObj.colorCodeOrder)
            : maxOrder + 10,
          assignmentTypeSeq: editObj?.assignmentTypeSeq ?? '',
          fromTime: editObj?.fromTime ?? '',
          toTime: editObj?.toTime ?? '',
        }}
        validationSchema={Yup.object().shape({
          colorCodeName: Yup.string()
            .required(`Color Code is required`)
            .nullable()
            .max(
              referenceTypeFieldLengthChecks[`colorCodeName`],
              `Color Code Name can not be more than ${referenceTypeFieldLengthChecks['colorCodeName']}`
            ),
          colorCodeOrder: Yup.number()
            .required('List Order is Required')
            .nullable()
            .max(99999, 'Clothing Type Order can not be more than 99999'),
          assignmentTypeSeq: Yup.string()
            .required('Department is Required')
            .nullable(),
          fromTime: Yup.number()
            .required('From time is Required')
            .nullable()
            .min(0, 'From time can not be less than 0')
            .max(99999, 'From time is required'),
          toTime: Yup.number()
            .required('To time is Required')
            .nullable()
            .min(fromTime, `To time can not be less than ${fromTime}`)
            .max(99999, 'From time is required'),
          countySeq: isCounty
            ? Yup.string().required('Jurisdiction is Required').nullable()
            : Yup.string().nullable(),
        })}
        onSubmit={onSave}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          handleChange,
          setFieldValue
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {isCounty && (
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth focused sx={{ minWidth: '88%' }}>
                      <InputLabel htmlFor='countySeq' id='countySeq'>
                        Jurisdiction
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        variant='outlined'
                        label='Jurisdiction'
                        focused
                        displayEmpty
                        size='large'
                        name={`countySeq`}
                        value={values?.countySeq || ''}
                      >
                        {Object.keys(countyNames).map((item, z) => {
                          return (
                            <MenuItem
                              key={countyNames[item].countySeq}
                              value={countyNames[item].countySeq}
                            >
                              {countyNames[item].countyName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={12} xs={12} md={6} lg={6}>
                  <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} md={7} lg={7}>
                      <FormControl
                        fullWidth
                        error
                        required
                        component='fieldset'
                        variant='standard'
                      >
                        <TextField
                          required
                          focused
                          id='outlined-basic'
                          label={referenceMasterTableName
                            .split('')
                            .map((alpha) => {
                              if (alpha <= 'Z') return ' ' + alpha;
                              return alpha;
                            })
                            .join('')
                            .trim()}
                          disabled={true}
                          variant='outlined'
                          name={'colorCodeName'}
                          value={values['colorCodeName']}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} xs={12} md={5} lg={5}>
                      <HexColorPicker style={{ width: '100%' }} color={values['colorCodeName']} onChange={(e) => {
                        // values['colorCodeName'] = e.split('#')[1].toString();
                        setFieldValue('colorCodeName', e.split('#')[1].toUpperCase().toString());
                      }} />
                    </Grid>
                  </Grid>
                  <ErrorMessage
                    name={'colorCodeName'}
                    render={(msg) => (
                      <FormHelperText error>{msg}</FormHelperText>
                    )}
                  />
                </Grid>

                <Grid item sm={12} xs={12} md={6} lg={6}>
                  <FormControl
                    required
                    fullWidth
                    focused
                    variant='outlined'
                  >
                    <InputLabel htmlFor='reasonTypeSeq' id='reasonTypeSeq'>
                      Department
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      variant='outlined'
                      focused
                      label='Department'
                      displayEmpty
                      size='large'
                      name={`assignmentTypeSeq`}
                      value={values?.assignmentTypeSeq || ''}
                    >
                      {departmentTypes.map((item) => {
                        return (
                          <MenuItem
                            key={item.assignmentTypeSeq}
                            value={item.assignmentTypeSeq}
                          >
                            {item.assignmentTypeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name={`assignmentTypeSeq`}
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='List Order'
                      variant='outlined'
                      name='colorCodeOrder'
                      type='number'
                      value={values.colorCodeOrder}
                      onChange={handleChange}
                      disabled={
                        values[
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                        ] == 'Other' ||
                        values[
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                        ] == 'Unknown'
                      }
                    />
                    <ErrorMessage
                      name='colorCodeOrder'
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='From Time (Hrs.)'
                      variant='outlined'
                      name='fromTime'
                      type='number'
                      value={values.fromTime}
                      onChange={(e) => {
                        handleChange(e);
                        setFromTime(e.target.value);
                      }}
                    />
                    <ErrorMessage
                      name='fromTime'
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='To Time (Hrs.)'
                      variant='outlined'
                      name='toTime'
                      type='number'
                      value={values.toTime}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='toTime'
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={handleSubmit}
                      disabled={!referencePermissions?.add}
                    >
                      {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <div className='modal'>
        <Dialog
          open={openApiDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenApiDialogBox(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {dialogContents}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenApiDialogBox(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddEdit;
