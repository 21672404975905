import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Document, Font, Page, StyleSheet, Text, View, usePDF, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import SansBold from '../../assets/fonts/Open_Sans/static/OpenSans-Bold.ttf';
import SansItalic from '../../assets/fonts/Open_Sans/static/OpenSans-Italic.ttf';
import SansRegular from '../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf';
import { CaseSummary } from '../../types/CaseSummary.type';
import { useAuth } from '../auth/AuthService';
import { triggerPrintDialog } from '../functions/triggerPrintDialog';
import { convertSvgToPng } from '../../utils/functions/convertSvgToPng';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: SansRegular, fontWeight: 'normal' },
    { src: SansBold, fontWeight: 'bold' },
    { src: SansItalic, fontWeight: 'normal', fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 10,
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    paddingBottom: 60,
  },
  stamp: {
    height: '1.3in',
    backgroundColor: '#eeeeee',
    marginBottom: 7,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontSize: 8,
    textAlign: 'center',
    marginBottom: 12,
    // textDecoration: 'underline',
    fontWeight: 'bold',
    paddingBottom: 3,
  },
  reportText: {
    fontSize: 8,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 8,
    paddingBottom: 4,
    lineHeight: 1,
  },
  section: {
    marginBottom: 3,
  },
  sectionTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  contentRow: {
    flexDirection: 'row',
    marginBottom: 2,
    flexWrap: 'wrap',
  },
  contentKey: {
    fontSize: 10,
    width: 120,
    fontWeight: 600,
  },
  contentKey2: {
    fontSize: 8,
    marginRight: 5,
    fontWeight: 600,
  },
  contentValue2: {
    fontSize: 8,
    textAlign: 'left',
    marginRight: 15,
  },
  dateTime: {
    fontSize: 8,
    textAlign: 'left',
    flexShrink: 1,
  },
  caseInformationKey: {
    width: 240,
    fontWeight: 600,
  },
  nextOfKinTitle: {
    fontSize: 8,
    fontWeight: 'normal',
    marginBottom: 2,
    textDecoration: 'underline',
  },
  nextOfKinValue: {
    width: 120,
  },
  contentValue: {
    flex: 1,
  },
  synopsisContent: {
    fontSize: 8,
    textAlign: 'justify',
    flexWrap: 'wrap',
    lineHeight: 1,
  },
  footer: {
    position: 'absolute',
    bottom: 2.5,
    left: 5,
    right: 5,
    // marginHorizontal: 5,
    marginBottom: 5,
    paddingHorizontal: 7.5,
    paddingVertical: 5,
    backgroundColor: '#eeeeee',
  },
  footerGeneratedBy: {
    fontSize: 6,
    textAlign: 'left',
    marginBottom: 2, // Space between the two footer lines
  },
  footerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 8,
  },
  footerLeft: {
    textAlign: 'left',
    flex: 1,
  },
  footerCenter: {
    textAlign: 'center',
    flex: 1,
  },
  footerRight: {
    textAlign: 'right',
    flex: 1,
  },
  fillableFieldContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    paddingTop: 5,
  },
  fillableFieldWrapper: {
    alignItems: 'center',
    width: '30%',
    marginBottom: 0,
  },
  fillableField: {
    width: '100%',
    borderBottom: 1,
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 3,
    lineHeight: 1,
  },
  fillableFieldText: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 4,
  },
  fullWidthRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
    width: '100%',
  },
  halfWidthBlock: {
    width: '48%',
  },
  line: {
    width: '100%',
    height: 0.5,
    marginTop: 1,
    marginBottom: 3,
    backgroundColor: 'black',
  },
  redacted: {
    width: 50,
    height: 15,
    backgroundColor: 'black',
  },
  logo: {
    width: 100, 
    height: 100, 
    marginBottom: 8,
  },
  headerContainer: {
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const formatDateTime = dateTime => {
  if (!dateTime) return '-';

  const date = dayjs(dateTime);

  if (!date.isValid()) return '-';

  return date.format('MM/DD/YYYY HH:mm');
};

const formatLocation = (address, city, state, zip, apt = '', floor = '') => {
  const addressParts = [];

  // Add the main address components, converting to uppercase and ensuring they are not null/undefined
  if (address) addressParts.push(address.toUpperCase());
  if (city) addressParts.push(city.toUpperCase());
  if (state) addressParts.push(state.toUpperCase());
  if (zip) addressParts.push(zip.toUpperCase());

  // Conditionally add apartment and floor with context if they are provided
  const aptFormatted = apt ? `APT ${apt.toUpperCase()}` : '';
  const floorFormatted = floor ? `FLOOR ${floor.toUpperCase()}` : '';

  // Combine apartment and floor with a comma if both are present
  if (aptFormatted && floorFormatted) {
    addressParts.push(`${aptFormatted}, ${floorFormatted}`);
  } else if (aptFormatted) {
    addressParts.push(aptFormatted);
  } else if (floorFormatted) {
    addressParts.push(floorFormatted);
  }

  // Join all parts with a comma and space, then trim any extra whitespace
  const formattedAddress = addressParts.join(', ').trim();

  // Return the formatted address or '-' if it's empty
  return formattedAddress || '-';
};

type CaseSummaryPDFProps = {
  caseSummary: CaseSummary;
  redactedFields: string[];
};

const CaseSummaryPDF: React.FC<CaseSummaryPDFProps> = ({ caseSummary, redactedFields}) => {
  let injuryByLabel, injuryOnLabel, injuryLabel;
  const foundOnsetIllnessByType = caseSummary.injury.extra;
  const logoImage = convertSvgToPng(caseSummary.jdxLogo);
  if (foundOnsetIllnessByType) {
    injuryByLabel =
      foundOnsetIllnessByType === 'I'
        ? 'Onset Of Illness By'
        : foundOnsetIllnessByType === 'O'
        ? 'Injury Observed By'
        : 'Found By';

    injuryOnLabel =
      foundOnsetIllnessByType === 'I'
        ? 'Onset Of Illness On'
        : foundOnsetIllnessByType === 'O'
        ? 'Injury Observed On'
        : 'Found On';

    injuryLabel =
      foundOnsetIllnessByType === 'I'
        ? 'Onset Of Illness'
        : foundOnsetIllnessByType === 'O'
        ? 'Injury Observed'
        : 'Found';
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.stamp}>
  <View style={{ position: 'relative', width: '100%', height: '100%' }}>
    {/* Logo on the Left */}
    {logoImage && (
      <Image
        src={logoImage}
        style={{
          width: 100,
          height: 100, 
          position: 'absolute', 
          left: 20, 
          top: '50%',
          transform: 'translateY(-50%)', 
        }}
      />
    )}

    {/* Centered Header */}
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', 
      }}
    >
            <Text style={styles.reportText}>{caseSummary.report_header ?? ''}</Text>
            <Text style={styles.reportText}>{caseSummary.report_address ?? ''}</Text>
          </View>
        </View>
      </View>
        <View style={styles.section}>
          <Text style={styles.title}>REPORT OF INVESTIGATION BY MEDICAL EXAMINER</Text>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Case #</Text>
                <Text style={styles.contentValue2}>{caseSummary.case_number}</Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Jurisdiction:</Text>
                <Text style={styles.contentValue2}>{caseSummary.jurisdiction?.toUpperCase()}</Text>
              </View>
            </View>
          </View>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Action Taken:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.ME_action ?? '-'}
                </Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Assigned MDI:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.assigned_investigator ?? '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.fullWidthRow}>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Reason For Investigation:</Text>
            <Text style={styles.contentValue2}>
              {caseSummary.case_information.investigation_reason ?? '-'}
            </Text>
          </View>
        </View>

        <View style={styles.fullWidthRow}>
          <View style={styles.halfWidthBlock}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Pronouncing Physician:</Text>
              <Text style={styles.contentValue2}>
                {caseSummary.case_information.pronouncing_physician ?? ''}
              </Text>
            </View>
          </View>

          <View style={styles.halfWidthBlock}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Pronounced Date:</Text>
              <Text style={styles.contentValue2}>
                {caseSummary.case_information.pronouncing_date_and_time
                  ? formatDateTime(caseSummary.case_information.pronouncing_date_and_time)
                  : '-'}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.fullWidthRow}>
          <View style={styles.halfWidthBlock}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Death Certificate#:</Text>
              <Text style={styles.contentValue2}>
                {caseSummary.case_information.edrs_case_number ?? ''}
              </Text>
            </View>
          </View>

          <View style={styles.halfWidthBlock}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Lab#:</Text>
              <Text style={styles.contentValue2}>
                {caseSummary.case_information.lab_number ?? ''}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          {/* <Text style={styles.sectionTitle}>Decedent Information</Text> */}

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Decedent Name:</Text>
                {redactedFields && redactedFields.includes('decedentName') ? (
                  caseSummary.decedent_information.decedent_name ? (
                    <View style={styles.redacted} />
                  ) : (
                    <Text style={styles.contentValue2}>-</Text>
                  )
                ) : (
                  // @ts-ignore
                  (
                    <Text style={styles.contentValue2}>
                      {caseSummary.decedent_information.decedent_name}
                    </Text>
                  ) || <Text style={styles.contentValue2}>-</Text>
                )}
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>aka:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.decedent_information.aka?.toUpperCase() ?? ''}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Residence:</Text>
              {redactedFields && redactedFields.includes('residenceLocation') ? (
                caseSummary?.residence?.address ? (
                  <View style={styles.redacted} />
                ) : (
                  <Text style={styles.contentValue2}>-</Text>
                )
              ) : (
                // @ts-ignore
                (
                  <Text style={styles.contentValue2}>
                    {formatLocation(
                      caseSummary.residence.address,
                      caseSummary.residence.city_or_town,
                      caseSummary.residence.state,
                      caseSummary.residence.zip,
                      caseSummary.residence.apt_suite,
                      caseSummary.residence.floor
                    )}
                  </Text>
                ) || <Text style={styles.contentValue2}>-</Text>
              )}
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Birth Date:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.birth_date?.toUpperCase() ?? ''}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Age:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.age ?? ''}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>SSN:</Text>
                {redactedFields && redactedFields.includes('ssn') ? (
                  caseSummary.demographic_information.ssn ? (
                    <View style={styles.redacted} />
                  ) : (
                    <Text style={styles.contentValue2}>-</Text>
                  )
                ) : (
                  <Text style={styles.contentValue2}>
                    {caseSummary.demographic_information.ssn || ''}
                  </Text>
                )}
              </View>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Marital Status:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.marital_status ?? ''}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Gender:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.gender ?? ''}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Ethnicity:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.ethnicity_or_race ?? ''}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.fullWidthRow}>
          <View style={styles.halfWidthBlock}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Private MD:</Text>
              <Text style={styles.contentValue}>
                {caseSummary.demographic_information.private_physician ?? ''}
              </Text>
            </View>
          </View>

          <View style={styles.halfWidthBlock}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Occupation:</Text>
              <Text style={styles.contentValue}>
                {caseSummary.demographic_information.occupation ?? ''}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Next Of Kin</Text>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey}>Name</Text>
            <Text style={styles.contentKey}>Relationship</Text>
            <Text style={styles.contentKey}>Contact Info</Text>
          </View>

          {caseSummary.next_of_kin_information?.map((kin, index) => (
            <View style={styles.contentRow}>
              <Text style={styles.nextOfKinValue}>{kin.name ?? '-'}</Text>
              <Text style={styles.nextOfKinValue}>{kin.relationship ?? '-'}</Text>
              {redactedFields && redactedFields.includes('contactInfo') ? (
                kin.phone_number ? (
                  <View style={styles.redacted} />
                ) : (
                  <Text style={styles.nextOfKinValue}>-</Text>
                )
              ) : (
                <Text style={styles.nextOfKinValue}>{kin.phone_number || ''}</Text>
              )}
            </View>
          ))}
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          {/* <Text style={styles.sectionTitle}>Identification Of Decedent</Text> */}
          <View style={styles.contentRow}>
            <Text style={styles.contentKey}>Identified By</Text>
            <Text style={styles.contentKey}>Relationship</Text>
            <Text style={styles.contentKey}>Modality</Text>
          </View>

          {caseSummary.identification_information?.map((id, index) => (
            <View style={styles.contentRow}>
              <Text style={styles.nextOfKinValue}>{id.name ?? '-'}</Text>
              <Text style={styles.nextOfKinValue}>{id.relationship ?? '-'}</Text>
              <Text style={styles.nextOfKinValue}>{id.modality ?? '-'}</Text>
            </View>
          ))}
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          {/* <Text style={styles.sectionTitle}>Scene Examination</Text> */}
          <View style={styles.contentRow}>
            <Text style={styles.contentKey}>Scene Examined By</Text>
            <Text style={styles.contentKey}>Date & Time</Text>
          </View>

          {caseSummary.scene_examined_by?.map((scene, index) => (
            <View style={styles.contentRow}>
              <Text style={styles.nextOfKinValue}>{scene.notified_by ?? '-'}</Text>
              <Text style={styles.dateTime}>{formatDateTime(scene.date) ?? '-'}</Text>
            </View>
          ))}
        </View>

        <View style={styles.line}></View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Synopsis</Text>
          <View style={styles.synopsisContent}>
            <Text style={styles.synopsisContent}>
              {caseSummary.case_synopsis?.split('').map((s, idx) => {
                return (
                  <Text style={styles.synopsisContent} key={idx}>
                    {s}
                  </Text>
                );
              })}
            </Text>
          </View>
        </View>

        <View style={styles.line}></View>
        {/* Case Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Discovery Information:</Text>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Last Known To Be Alive On:</Text>
                <Text style={styles.contentValue2}>
                {formatDateTime(caseSummary.last_date_alive.date)}
                </Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Last Known To be Alive By:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.last_date_alive.notified_by ?? ''}
                </Text>
              </View>
            </View>
          </View>

          {/* <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Physician:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.physician ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>POC:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.physician_to_whom_case_was_released ?? '-'}
                </Text>
              </View>
            </View>
          </View> */}

          <View>
            {foundOnsetIllnessByType && (
              <>
                <View style={styles.fullWidthRow}>
                  {/* <View style={styles.halfWidthBlock}>
                    <View style={styles.contentRow}>
                      <Text style={styles.contentKey2}>Discovered:</Text>
                      <Text style={styles.contentValue}>{injuryLabel}</Text>
                    </View>
                  </View> */}

                  <View style={styles.halfWidthBlock}>
                    <View style={styles.contentRow}>
                      <Text style={styles.contentKey2}>{injuryOnLabel}: </Text>
                      <Text style={styles.contentValue2}>
                        {formatDateTime(caseSummary.injury.date)}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.halfWidthBlock}>
                    <View style={styles.contentRow}>
                      <Text style={styles.contentKey2}>{injuryByLabel}: </Text>
                      <Text style={styles.contentValue2}>{caseSummary.injury.notified_by}</Text>
                    </View>
                  </View>
                </View>
              </>
            )}

            <View style={styles.fullWidthRow}>
              <View style={styles.halfWidthBlock}>
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey2}>Police Notified On:</Text>
                  <Text style={styles.contentValue2}>
                  {formatDateTime(caseSummary.police_notified.date)}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Police Notified By:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.police_notified.notified_by ?? ''}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Police Case#:</Text>
                <Text style={styles.contentValue}>{caseSummary.police_notified.extra ?? ''}</Text>
              </View>
            </View>
          </View>
          <View>
            {foundOnsetIllnessByType && (
              <>
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey2}>Onset/Injury Location: </Text>
                  <Text style={styles.contentValue2}>{caseSummary.injury.location ?? ''}</Text>
                </View>
              </>
            )}
          </View>

          {foundOnsetIllnessByType && (
            <View>
              <Text style={styles.contentKey2}>Location where found description:</Text>
              <Text style={styles.contentValue2}>
                {caseSummary.location_where_found_description?.split('').map((s, idx) => {
                  return <Text key={idx}>{s}</Text>;
                })}
              </Text>
            </View>
          )}

          <View style={styles.line}></View>
          <Text style={styles.sectionTitle}>Manner & Cause Information:</Text>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Manner of Death:</Text>
            <Text style={styles.contentValue2}>
              {caseSummary.case_information.manner_of_death ?? ''}
            </Text>
          </View>

          <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Cause of Death: </Text>
            <Text style={styles.contentValue2}>
              {caseSummary.case_information.cause_of_death ?? ''}
            </Text>
          </View>

          <View style={styles.halfWidthBlock}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Contributory:</Text>
              <Text style={styles.contentValue2}>
                {caseSummary.case_information.contributory ?? ''}
              </Text>
            </View>
          </View>

          {/* Condition of body, unnatural death, and circumstances all conditionally rendered based if not null */}
          {caseSummary.case_information?.condition_of_body ? (
            <View style={styles.fullWidthRow}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Condition Of Body:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.condition_of_body}
                </Text>
              </View>
            </View>
          ) : null}

          {caseSummary.case_information?.unnatural_death ? (
            <View style={styles.fullWidthRow}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Unnatural Death Categories:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.unnatural_death}
                </Text>
              </View>
            </View>
          ) : null}

          {caseSummary.case_information?.circumstances_related_to_death ? (
            <View style={styles.fullWidthRow}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Circumstances Related To Death:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.circumstances_related_to_death}
                </Text>
              </View>
            </View>
          ) : null}

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Exam Date:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.exam_date_and_time
                    ? formatDateTime(caseSummary.case_information.exam_date_and_time)
                    : ''}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Exam Type:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_information.type_of_exam ?? ''}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.line}></View>
          <View style={styles.fullWidthRow}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Body Location: </Text>
              {redactedFields && redactedFields.includes('bodyLocation') ? (
                caseSummary.body_location_information.address ? (
                  <View style={styles.redacted} />
                ) : (
                  <Text style={styles.contentValue2}>' '</Text>
                )
              ) : (
                // @ts-ignore
                (
                  <Text style={styles.contentValue2}>
                    {formatLocation(
                      caseSummary.body_location_information.address,
                      caseSummary.body_location_information.city,
                      caseSummary.body_location_information.state,
                      caseSummary.body_location_information.zipcode,
                      caseSummary.body_location_information.apt,
                      caseSummary.body_location_information.floor
                    )}
                  </Text>
                ) || <Text style={styles.contentValue2}>-</Text>
              )}
            </View>
          </View>

          <View style={styles.line}></View>

          <View style={styles.fullWidthRow}>
            <View style={styles.contentRow}>
              <Text style={styles.contentKey2}>Check-out Status:</Text>
              <Text style={styles.contentValue2}>
                {caseSummary.case_information.check_out_status ?? ''}
              </Text>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2} break>
                  Check-out Authorized by:
                </Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.check_out_authorized_by ?? ''}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Checked-out to:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.check_out_to ?? ''}
                </Text>
              </View>
            </View>
          </View>

          {/* <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Tissue Number:</Text>
            <Text style={styles.contentValue2}>
              {caseSummary.case_information.tissue_number ?? '-'}
            </Text>
          </View> */}

          <View style={styles.fillableFieldContainer}>
            <View style={styles.fillableFieldWrapper}>
              <Text style={styles.fillableField}>
                {caseSummary.case_information.physician ?? ' '}
              </Text>
              <Text style={styles.fillableFieldText}>Medical Examiner</Text>
            </View>
            <View style={styles.fillableFieldWrapper}>
              <Text style={styles.fillableField}>
                {caseSummary.case_information.exam_date_and_time
                  ? dayjs(caseSummary.case_information.exam_date_and_time).format('MM/DD/YYYY')
                  : ' '}
              </Text>
              <Text style={styles.fillableFieldText}>Date</Text>
            </View>
            <View style={styles.fillableFieldWrapper}>
              <Text style={styles.fillableField}>{caseSummary.jurisdiction ?? ' '} </Text>
              <Text style={styles.fillableFieldText}>Jurisdiction</Text>
            </View>
          </View>

          {/* <View style={styles.footer}>
            <Text>
              Generated by {caseSummary.requesting_user ?? '-'} on {getTodaysDate()}
            </Text>
          </View> */}

          {/* <View style={styles.fullWidthRow}> */}
          {/* <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Name:</Text>
                {redactedFields && redactedFields.includes('decedentName') ? (
                  caseSummary.decedent_information.decedent_name ? (
                    <View style={styles.redacted} />
                  ) : (
                    <Text style={styles.contentValue2}>-</Text>
                  )
                ) : (
                  (
                    <Text style={styles.contentValue2}>
                      {caseSummary.decedent_information.decedent_name}
                    </Text>
                  ) || <Text style={styles.contentValue2}>-</Text>
                )}
              </View>
            </View> */}

          {/* <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Case Number:</Text>
                <Text style={styles.contentValue}>{caseSummary.case_number}</Text>
              </View>
            </View> */}
          {/* </View> */}
        </View>
        {/* Footer */}
        <Footer caseSummary={caseSummary} redactedFields={redactedFields} />
      </Page>
    </Document>
  );
};
// Updated Footer component
const Footer = ({
  caseSummary,
  redactedFields,
}: {
  caseSummary: CaseSummary;
  redactedFields: string[];
}) => (
  <View style={styles.footer} fixed>
    <View style={{ ...styles.footerContent, marginBottom: 2 }}>
      {/* Decedent Name Section */}
      <View style={styles.footerLeft}>
        {redactedFields.includes('decedentName') &&
        caseSummary.decedent_information.decedent_name ? (
          <View style={styles.redacted} />
        ) : (
          <Text>{caseSummary.decedent_information.decedent_name || 'N/A'}</Text>
        )}
      </View>

      {/* Case ID Section */}
      <Text style={styles.footerCenter}>Case ID: {caseSummary.case_number}</Text>

      {/* Page Number Section */}
      <Text
        style={styles.footerRight}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
      />
    </View>
    {/* Thin Horizontal Line */}
    <View style={styles.line} />
    {/* "Generated by" Section */}S
    <Text style={{ ...styles.footerGeneratedBy, fontStyle: 'italic' }}>
      Document genereated by {caseSummary.requesting_user ?? '-'} on {getTodaysDate()}
    </Text>
  </View>
);

function getTodaysDate() {
  const suffix = (n: number) => {
    if (n >= 11 && n <= 13) {
      return 'th';
    }
    switch (n % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDate = new Date();
  const date = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  let hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${month} ${date}${suffix(date)}, ${year} at ${hours}:${formattedMinutes} ${ampm} EST`;
}


export const PrintRIMEButton = ({ caseSummary, isMediumScreen }) => {
  const formik = useFormikContext();
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [redactDialogOpen, setRedactDialogOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [instance, updateInstance] = usePDF({
    document: <CaseSummaryPDF caseSummary={caseSummary} redactedFields={selectedFields} />,
  });
  const [notificationDialog, setNotificationDialog] = useState({
    open: false,
    message: '',
    title: '',
  });
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;

  useEffect(() => {
    updateInstance(<CaseSummaryPDF caseSummary={caseSummary} redactedFields={selectedFields} />);
  }, [caseSummary, selectedFields]);

  const handleFileUpload = async pdfFile => {
    setIsUploading(true);

    const formDataUploadFiles = new FormData();
    formDataUploadFiles.append('FILEPARAMS[0].CASENO', caseSummary?.case_number);
    formDataUploadFiles.append('FILEPARAMS[0].CASESEQ', caseSummary?.case_seq);
    formDataUploadFiles.append(
      'FILEPARAMS[0].FILERELATEDTO',
      '77d4218a-a421-455d-877d-e992126980f3' // will only ever be a rime report
    );
    formDataUploadFiles.append('FILEPARAMS[0].FILERELATEDTONAME', 'Rime Report');
    formDataUploadFiles.append('FILEPARAMS[0].FILE', pdfFile);
    formDataUploadFiles.append('USERSEQ', user?.userSeq);

    try {
      const response = await fetch(VITE_API_URL + 'fileuploads', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formDataUploadFiles,
      });

      const data = await response.json();
      if (response.ok && data.status === 0) {
        setNotificationDialog({
          open: true,
          message: 'File uploaded successfully!',
          title: 'success',
        });
      } else {
        throw new Error(data.message || 'Unknown error occurred during file upload');
      }
    } catch (error) {
      setNotificationDialog({
        open: true,
        message: `Error during file upload: ${error.message}`,
        title: 'error',
      });
    }
  };

  const handleSaveToDocuments = async () => {
    if (instance.blob) {
      let pdfFileInstance;

      const isRedacted = selectedFields.length > 0;

      const fileName = isRedacted
        ? `${caseSummary?.case_number}-RIME-REDACTED.pdf`
        : `${caseSummary?.case_number}-RIME.pdf`;

      pdfFileInstance = new File([instance.blob], fileName, { type: 'application/pdf' });

      const result = (await formik.submitForm()) as unknown as boolean;

      console.log('result val: ', result);

      if (result) {
        handleFileUpload(pdfFileInstance);
      } else {
        setNotificationDialog({
          open: true,
          message: `Could Not Save. Please Double Check Your Information In MedexLab`,
          title: 'Error',
        });
      }
    } else {
      setNotificationDialog({
        open: true,
        message: 'Failed to generate the PDF file',
        title: 'Error',
      });
    }
  };

  const handleDownloadPDF = () => {
    if (instance.blob) {
      const link = document.createElement('a');
      link.href = instance.url;

      const fileName =
        selectedFields.length > 0
          ? `${caseSummary?.case_number}-RIME-REDACTED.pdf`
          : `${caseSummary?.case_number}-RIME.pdf`;

      link.download = `${fileName}.pdf`;
      link.click();
    } else {
      setNotificationDialog({
        open: true,
        message: 'Failed to generate the PDF file for download',
        title: 'Error',
      });
    }
  };

  // leave here for when/if we want to add the printing functionality for the RIME
  const handlePrint = () => {
    if (instance.blob) {
      triggerPrintDialog({ blob: instance.blob });
    } else {
      setNotificationDialog({
        open: true,
        message: 'Failed to generate the PDF file for printing',
        title: 'error',
      });
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleRedactFields = () => {
    setRedactDialogOpen(true);
  };

  const handleCloseRedactDialog = () => {
    setRedactDialogOpen(false);
  };

  const handleApplyRedactions = () => {
    setRedactDialogOpen(false);
  };

  const handleCloseNotificationDialog = () => {
    setNotificationDialog(prevState => ({ ...prevState, open: false }));
    setTimeout(() => {
      setNotificationDialog({ open: false, message: '', title: '' });
    }, 200);
  };

  const redactionOptions = [
    { label: 'Decedent Name', value: 'decedentName' },
    { label: 'SSN', value: 'ssn' },
    { label: 'Residence Location', value: 'residenceLocation' },
    { label: 'Body Location', value: 'bodyLocation' },
    { label: 'Contact Info', value: 'contactInfo' },
  ];

  return (
    <>
      <Tooltip title='Print RIME'>
        <IconButton
          size={isMediumScreen ? 'medium' : 'large'}
          sx={{ width: 'max-content', height: 'max-content' }}
          onClick={handleOpenDialog}
        >
          <PrintIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleCloseDialog} maxWidth='lg' fullWidth>
        <Box display='flex' alignItems='center' justifyContent='space-between' padding='16px'>
          <DialogTitle style={{ margin: 0, padding: 0 }}>
            {caseSummary.case_number} RIME Preview
          </DialogTitle>
          <Box>
            <Button onClick={handleRedactFields} color='primary' style={{ marginRight: 8 }}>
              Redact Fields
            </Button>
            <Button onClick={handleDownloadPDF} color='primary' style={{ marginRight: 8 }}>
              Download PDF
            </Button>
            <Button
              onClick={handleSaveToDocuments}
              color='secondary'
              style={{ marginRight: 8 }}
              disabled={isUploading}
            >
              {isUploading ? 'Saving...' : 'Save to Documents'}
            </Button>
            <Button onClick={handleCloseDialog} color='error'>
              Close
            </Button>
          </Box>
        </Box>
        <DialogContent dividers>
          <iframe
            title='PDF Preview'
            src={`${instance.url}#zoom=page-fit&view=FitH&toolbar=0`}
            style={{
              width: '100%',
              minHeight: '90vh',
              border: 'none',
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Redact Fields Dialog */}
      <Dialog open={redactDialogOpen} onClose={handleCloseRedactDialog}>
        <DialogTitle>Select Fields to Redact</DialogTitle>
        <DialogContent>
          <FormGroup>
            {redactionOptions.map(field => (
              <FormControlLabel
                key={field.value}
                control={
                  <Checkbox
                    checked={selectedFields.includes(field.value)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const checked = event.target.checked;
                      setSelectedFields(prev => {
                        if (checked) {
                          return [...prev, field.value];
                        } else {
                          return prev.filter(value => value !== field.value);
                        }
                      });
                    }}
                  />
                }
                label={field.label}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRedactDialog}>Close</Button>
          {/* <Button onClick={handleApplyRedactions} color='primary'>
            Apply Redactions
          </Button> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={notificationDialog.open}
        onClose={handleCloseNotificationDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{notificationDialog.title}</DialogTitle>
        <DialogContent>
          <Typography id='alert-dialog-description'>{notificationDialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotificationDialog} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
