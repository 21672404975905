import React, { useState } from 'react';
import { Button, Stack, Alert, Card, CardContent, CardHeader, Collapse, Typography } from '@mui/material';
//@ts-ignore
import MLDropzone from '../../utils/components/MLDropzone';

interface BulkFileUploadProps {
  isLab: boolean;
}

export default function BulkFileUpload({ isLab = false }: BulkFileUploadProps) {
  const [open, setOpen] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);


  const handleSuccessfulUpload = () => {
    setShowSuccessAlert(true);
  };

  return (
    <Card>
      <CardHeader
        title="Bulk Upload Case Documents"
        subheader="Upload multiple case documents to MedexLab at once"
      />
      <CardContent>
        <Stack spacing={2}>
          <Collapse in={showSuccessAlert}>
            <Alert
              severity="success"
              onClose={() => setShowSuccessAlert(false)}
            >
              All accepted files uploaded successfully!
            </Alert>
          </Collapse>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Upload Documents
          </Button>

          <MLDropzone
            isOpen={open}
            setIsOpen={setOpen}
            documentTypeRequired={true}
            onSuccessUpload={handleSuccessfulUpload}
            isLab={isLab}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
