import { Node, mergeAttributes } from '@tiptap/core';

export const Video = Node.create({
  name: 'video',

  group: 'block',

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      controls: {
        default: true,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'video' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['video', mergeAttributes(HTMLAttributes)];
  },
});
