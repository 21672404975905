import { Avatar, AvatarProps } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';

interface UserAvatarProps extends AvatarProps {
  initials?: string;
  userseq?: string;
}

export function UserAvatar(props: UserAvatarProps) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState('');

  const transformedInitials = props.initials?.toUpperCase() ?? '';

  const fetchAvatar = async () => {
    try {
      const url = `${import.meta.env.VITE_FASTAPI_URL}users/user-profile-picture/${props.userseq}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + auth.user?.accessToken,
          'Content-Type': 'application/json',
        },
      });

      const profilePicture = response.data.profile_picture;
      if (profilePicture) {
        const encodedSvg = encodeURIComponent(profilePicture);
        const dataUrl = `data:image/svg+xml;charset=utf-8,${encodedSvg}`;
        setSrc(dataUrl);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvatar();
  }, [props.userseq]);

  return (
    <Avatar {...props} sx={{ ...props.sx, fontSize: '0.7rem' }} src={src}>
      {transformedInitials}
    </Avatar>
  );
}
