import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  OutlinedInput,
  FormHelperText,
  Alert,
  InputAdornment,
  IconButton,
  Link,
  Select,
  MenuItem,
  useMediaQuery,
  InputLabel,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';

interface ReferenceMasterTableFilterProps {
  rows: any[]; 
  isJdx: boolean;
  jurisdictionOptions: Option[]; 
  setRowsToShow: (rows: any[]) => void;
}

interface Option {
  optionName: string | null;
  optionSeq: string | null;
  isActive: boolean | null;
}

const ReferenceMasterTableFilter: React.FC<ReferenceMasterTableFilterProps> = ({
  rows,
  isJdx,
  jurisdictionOptions,
  setRowsToShow,
}) => {
  const [jurisdiction, setJurisdiction] = useState<Option | null>(null);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');
  const onFilter = () => {
    let newRows = [...rows];
    if (jurisdiction) {
      newRows = newRows.filter(row => row.jurisdiction.optionSeq == jurisdiction.optionSeq);
    }
    if (activeInActiveFilter == 'all') setRowsToShow(newRows);
    else if (activeInActiveFilter == 'Active')
      setRowsToShow(newRows.filter(row => row.status.toLowerCase() == 'active'));
    else if (activeInActiveFilter == 'InActive')
      setRowsToShow(newRows.filter(row => row.status.toLowerCase() == 'inactive'));
  };

  useEffect(() => {
    onFilter();
  }, [rows, activeInActiveFilter, jurisdiction]);

  
  return (
      <Grid container spacing={2}>
        {isJdx && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Autocomplete
              id='jurisdiction'
              options={jurisdictionOptions || []}
              value={jurisdiction || null}
              isOptionEqualToValue={(option, value) =>
                option?.optionSeq?.toLowerCase() === value?.optionSeq?.toLowerCase()
              }
              size='small'
              getOptionLabel={option => option?.optionName || ''}
              onChange={(e, newValue) => setJurisdiction(newValue)}
              fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  label='Filter Jurisdiction'
                  id='jurisdiction'
                  name='jurisdiction'
                  placeholder='Jurisdiction'
                />
              )}
            />
          </Grid>
        )}
        <Grid item md={6} sm={12} xs={12}>
          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isActive'
            onChange={e => setActiveInActiveFilter(e.target.value)}
            value={activeInActiveFilter}
            sx={{ marginLeft: 1 }}
          >
            {' '}
            <FormControlLabel value='Active' control={<Radio />} label='Active Only' />{' '}
            <FormControlLabel value='InActive' control={<Radio />} label='Inactive Only' />{' '}
            <FormControlLabel value='all' control={<Radio />} label='All' />{' '}
          </RadioGroup>
        </Grid>
      </Grid>
  );
};

export default ReferenceMasterTableFilter;
