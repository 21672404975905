import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { ChatMessage, ChatParticipant, shouldMaskNameAsSupportAgent } from './conversation.types';
import { useConversation } from './ConversationsContext';

interface TypingIndicatorProps {
  typingParticipants: ChatParticipant[];
  supportAgentName: string | null;
  conversationTypeSeq: string;
  currentUserSeq: string;
}

export const TypingIndicator = ({
  typingParticipants,
  supportAgentName,
  conversationTypeSeq,
  currentUserSeq,
}: TypingIndicatorProps) => {
  if (!typingParticipants?.length) return null;

  const { user } = useAuth();
  const { typingParticipants: typers, selectedChat } = useConversation();
  const peopleThatAreTyping = typers[selectedChat.conversationSeq] || [];

  const typingUserNames = peopleThatAreTyping
    .map(participant => {
      // console.log(participant);
      if (
        shouldMaskNameAsSupportAgent(
          { sender: { userSeq: participant.userSeq } } as unknown as ChatMessage,
          conversationTypeSeq,
          user
        )
      ) {
        return supportAgentName;
      }

      return `${participant.personFirstName} ${participant.personLastName}`;
    })
    .filter(Boolean);

  if (!typingUserNames.length) return null;

  let typingText = '';
  if (typingUserNames.length === 1) {
    typingText = `${typingUserNames[0]} is typing`;
  } else if (typingUserNames.length === 2) {
    typingText = `${typingUserNames[0]} and ${typingUserNames[1]} are typing`;
  } else {
    typingText = 'Several people are typing';
  }

  return (
    <Box sx={{ px: 2, py: 1, minHeight: '24px' }}>
      <Typography
        variant='caption'
        color='text.secondary'
        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
      >
        {typingText}
        <TypingAnimation />
      </Typography>
    </Box>
  );
};

const TypingAnimation = () => {
  return (
    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
      {[0, 1, 2].map(i => (
        <motion.div
          key={i}
          initial={{ opacity: 0.3 }}
          animate={{ opacity: 1 }}
          transition={{
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 0.5,
            delay: i * 0.2,
          }}
          style={{
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
          }}
        />
      ))}
    </Box>
  );
};
