import axios from 'axios';

const { VITE_API_URL } = import.meta.env;

const login = (username, password) => {
  console.warn('This function is obsolete. Use useAuth() instead.');
  //alert(VITE_API_URL)

  var inputData = { inputData: { userName: username, password: password } };
  return (
    axios
      //.post(VITE_API_URL + "UserCredentials/UserCredential", JSON.stringify(inputData), { headers: { 'Content-Type': 'application/json' } })
      .post(VITE_API_URL + 'user/userlogin', JSON.stringify(inputData), {
        headers: { 'Content-Type': 'application/json' },
      })

      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log('Error1', error.response.data);
          console.log('Error2', error.response.status);
          console.log('Error3', error.response.headers);
          console.log('Error7', error.message);
          return error;
        } else if (('Error4', error.request)) {
          // The request was made but no response was received
          console.log(error.request);
          return error.response;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error5', error.message);
          return error.response;
        }
      })
      .then((response) => {
        // alert(response.data.message)
        //return response.data;
        if (response.data.status == '0') {
          //returnVal = true;

          console.log(response.data);
          localStorage.setItem('user', JSON.stringify(response.data.data));
          return response.data;
        } else {
          console.log('Error6', response.data.message);
          return response.data;
        }
      })
      .catch((e) => {
        console.log('error:', e);
      })
  );
};
const logout = () => {
  console.warn('This function is obsolete. Use useAuth() instead.');
  // alert()
  localStorage.removeItem('user');
};
const getCurrentUser = () => {
  console.warn('This function is obsolete. Use useAuth() instead.');

  return JSON.parse(localStorage.getItem('user'));
};
export default {
  login,
  logout,
  getCurrentUser,
};
