import { NavigateFunction } from 'react-router';
import { MedexResponse, ResponseStatus } from '../../../types/MedexResponse.type';
import { User } from '../../../types/User';
import { AuthAction } from '../reducers/authReducer';
import { UserAction, UserState } from '../reducers/userReducer';
const { VITE_API_URL } = import.meta.env;
/**
 * Hook to handle user login.
 *
 * @param authDispatch Dispatch function for authentication-related actions.
 * @param userDispatch Dispatch function for user-related actions.
 * @param userState Current user state.
 * @param navigate Function to navigate to different routes.
 *
 * @return A function to initiate the login process.
 */
export function useLogin(
  authDispatch: React.Dispatch<AuthAction>,
  userDispatch: React.Dispatch<UserAction>,
  userState: UserState,
  navigate: NavigateFunction
) {
  /**
   * Function to handle user login.
   * - Sends user credentials to the server for authentication.
   * - Updates user and auth states based on server response.
   *
   * @param {Object} params User credentials.
   * @param {string} params.username User's username.
   * @param {string} params.password User's password.
   *
   * @return User data on successful login or null on failure.
   */
  const login = async ({ username, password }: { username: string; password: string }) => {
    // const auth = useAuth();
    try {
      authDispatch({ type: 'SET_LOGIN_STATE', payload: { state: 'awaiting response' } });
      // Construct the login endpoint URL
      const endpoint = `${VITE_API_URL}user/login`;

      // Send a POST request to the login endpoint with the user credentials
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      // Parse the response JSON
      const { status, data, errorCode, message }: MedexResponse = await response.json();
      // If the login is successful, update the user and auth states
      // and redirect to the 2-factor authentication page.
      // Otherwise, reset the auth state.
      if (status === ResponseStatus.SUCCESS) {
        const user = new User({
          ...data.user,
          token: data.auth_token,
          auth_token: data.auth_token,
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        });
        if (message == 'ToChangePasswordPage') {
          userDispatch({
            type: 'SET_USER',
            payload: {
              ...data.user,
              // token: data.access_token,
              authToken: data.auth_token,
              // accessToken: data.access_token,
              // refreshToken: data.refresh_token,
            },
          });
          const endpoint = `${VITE_API_URL}user/login/forgotpassword/requestcode`;
          const codeSent = await fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userName: data.user.userName }),
          });
          if (codeSent) {
            navigate('/login/forgot-password/verify');
          }

          // navigate('/login/forgot-password/change');
          return userState.user;
        } else {
          if (user.enable_2FA_bypass === true) {
            authDispatch({ type: 'SET_AUTHENTICATED', payload: true });
            authDispatch({ type: 'SET_PASSED_2FA', payload: true });
            userDispatch({
              type: 'SET_USER',
              payload: {
                ...data.user,
                token: data.access_token,
                authToken: data.auth_token,
                accessToken: data.access_token,
                refreshToken: data.refresh_token,
              },
            });
            userDispatch({ type: 'SET_ACCESS_TOKEN', payload: data.access_token });
            userDispatch({ type: 'SET_REFRESH_TOKEN', payload: data.refresh_token });
            userDispatch({
              type: 'SET_AUTH_TOKEN',
              payload: data.auth_token,
            });

            authDispatch({ type: 'SET_READY', payload: true });

            navigate('/home');
            return userState.user;
          } else {
            userDispatch({
              type: 'SET_USER',
              payload: user,
            });
            authDispatch({ type: 'SET_AUTHENTICATED', payload: false });
            authDispatch({ type: 'SET_VERIFYING_CODE', payload: true });
            authDispatch({ type: 'SET_READY', payload: true });
            authDispatch({ type: 'RESET_LOGIN_ERROR_MESSAGE' });

            navigate('/login/2fa');
            return userState.user;
          }
        }
      } else {
        authDispatch({ type: 'RESET_AUTH' }); // Reset the auth state if login fails
        authDispatch({
          type: 'SET_LOGIN_STATE',
          payload: {
            state: 'error',
            message:
              typeof message === 'string' ? message : 'Could not login. Please try again later.',
          },
        });
        authDispatch({
          type: 'SET_LOGIN_ERROR_MESSAGE',
          payload:
            typeof message === 'string' ? message : 'Could not login. Please try again later.',
        });
        authDispatch({ type: 'SET_READY', payload: true });
        return null;
      }
    } catch (error) {
      console.error(error);
      authDispatch({ type: 'RESET_AUTH' }); // Reset the auth state if login fails
      authDispatch({ type: 'SET_READY', payload: true });
      authDispatch({
        type: 'SET_LOGIN_STATE',
        payload: {
          state: 'error',
          message: typeof error === 'string' ? error : 'Could not login. Please try again later.',
        },
      });
      authDispatch({
        type: 'SET_LOGIN_ERROR_MESSAGE',
        payload: typeof error === 'string' ? error : 'Could not login. Please try again later.',
      });

      return null;
    } finally {
      authDispatch({ type: 'SET_LOGIN_STATE', payload: { state: 'init' } });
    }
  };

  // Return the login function from the hook
  return login;
}
