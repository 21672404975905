import { Check, Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Skeleton,
  Slide,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AutocompleteWithOther from '../../components/AutocompleteWithOther';
import { ActionList } from '../../components/CaseView/CaseViewDateTimeField';
import { useAuth } from '../../utils/auth/AuthService';
import AddressField from '../../utils/components/AddressFieldWithSearch';
import CustomHeader from '../../utils/components/CustomHeader';
import LoadingIconButton from '../../utils/components/LoadingIconButton';
import NumericTextField from '../../utils/components/NumericTextField';
import { Question, useIncident } from './IncidentContext';
import { RichTextReadOnly } from 'mui-tiptap';
import useExtensions from '../Home/useExtensions';
import { MissingPerson } from './MissingPersons';
import { DVCSAlert } from './DVCSAdminView';
import ScrollingAlert from '../../components/ScrollingAlert';

const CallCenterInterface = () => {
  const { incident } = useIncident();
  const [raceOptions, setRaceOptions] = useState<Option[]>([]);
  const [genderOptions, setGenderOptions] = useState<Option[]>([]);
  const [eyeColorOptions, setEyeColorOptions] = useState<Option[]>([]);
  const [hairColorOptions, setHairColorOptions] = useState<Option[]>([]);
  const [languageOptions, setLanguageOptions] = useState<Option[]>([]);
  const [relationshipOptions, setRelationshipOptions] = useState<Option[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const [alert, setAlert] = useState<DVCSAlert>({ alertText: '', alertSeverity: 'info' });

  const fetchAlert = async () => {
    await fetch(VITE_API_URL + `dvcs/getalert`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAlert(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchAlert();
  }, []);

  const formik = useFormik<MissingPerson>({
    initialValues: {
      incidentSeq: '',

      // Demographics
      missingPersonSeq: uuidv4(),
      firstName: '',
      lastName: '',
      alsoKnownAs: '',
      age: '',
      dateOfBirth: null,
      race: null,
      raceOther: '',
      gender: null,
      genderOther: '',
      heightInFeet: '',
      heightInInches: '',
      weightInPounds: '',
      eyeColor: null,
      eyeColorOther: '',
      hairColor: null,
      hairColorOther: '',
      primaryLanguage: null,
      primaryLanguageOther: '',

      // Additional Information
      isLastKnownLocationAddress: false,
      lastKnownLocationText: '',
      lastKnownLocationAddress: {
        addressText: null,
        data: {
          addressLine1: null,
          addressLine2: null,
          address: null,
          aptSuite: null,
          floor: null,
          cityTown: null,
          state: null,
          zipCode: null,
          country: null,
        },
      },
      residenceAddress: {
        addressText: null,
        data: {
          addressLine1: null,
          addressLine2: null,
          address: null,
          aptSuite: null,
          floor: null,
          cityTown: null,
          state: null,
          zipCode: null,
          country: null,
        },
      },
      callerName: '',
      relationshipToCaller: null,
      relationshipToCallerOther: '',
      dateLastSeen: null,
      victimPhoneNumber: '',
      identifyingFeatures: '',
      clothingDescription: '',
      accessories: '',
      medicationsNeeded: '',
      otherInformation: '',
      contactNumber: '',
      status: 'Missing',

      // Grading Responses
      gradingResponses: [],
    },

    onSubmit: async values => {
      await saveDetails(values);
    },
  });

  useEffect(() => {
    const fetchRaceOptions = async () => {
      fetch(VITE_API_URL + 'getraceoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setRaceOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchGenderOptions = async () => {
      fetch(VITE_API_URL + 'getgenderoptionswithseq', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setGenderOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchEyeColorOptions = async () => {
      fetch(VITE_API_URL + 'geteyecoloroptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setEyeColorOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchHairColorOptions = async () => {
      fetch(VITE_API_URL + 'gethaircoloroptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setHairColorOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchLanguageOptions = async () => {
      fetch(VITE_API_URL + 'getlanguageoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setLanguageOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchRelationshipOptions = async () => {
      fetch(VITE_API_URL + 'gettypicalrelationshipoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setRelationshipOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    fetchRaceOptions();
    fetchGenderOptions();
    fetchEyeColorOptions();
    fetchHairColorOptions();
    fetchLanguageOptions();
    fetchRelationshipOptions();
  }, []);

  const saveDetails = async (values: any) => {
    setLoadingSubmit(true);

    const updatedValues = { ...values, incidentSeq: incident?.incidentGroupSeq };

    try {
      const response = await fetch(
        VITE_API_URL + `dvcs/logmissingperson?userSeq=${user?.userSeq}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`, // Assuming you store the JWT in localStorage
          },
          body: JSON.stringify(updatedValues),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to save details: ${response.statusText}`);
      }

      const result = await response.json();
      setShowSuccess(true);
      formik.resetForm();
      formik.setFieldValue('missingPersonSeq', uuidv4());
      return result;
    } catch (error) {
      console.error('Error saving details:', error);
      throw error;
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Box component='div' sx={{ m: 0, px: 2, pt: 2 }}>
        <CustomHeader
          title='Call Center'
          description={`Active Incident: ${
            incident === null ? 'None' : `${incident?.incidentDescription} (${incident?.groupID})`
          }`}
          showMenuButton={false}
          alert={
            alert.alertText !== '' && (
              <ScrollingAlert
                severity={alert.alertSeverity}
                message={alert.alertText ?? ''}
                speed={4}
              />
            )
          }
        />
      </Box>

      <Slide direction='right' in={showSuccess} mountOnEnter unmountOnExit>
        <Alert
          icon={<Check fontSize='inherit' />}
          severity='success'
          onClose={() => setShowSuccess(false)}
          sx={{ m: 2, alignItems: 'center' }}
        >
          <Typography>
            Successfully added new missing person to incident {incident?.groupID}.
          </Typography>
        </Alert>
      </Slide>

      <Slide direction='right' in={incident === null} mountOnEnter unmountOnExit>
        <Alert severity='info' sx={{ m: 2, alignItems: 'center' }}>
          <Typography>Please select an incident to add a missing person.</Typography>
        </Alert>
      </Slide>
      <Stack spacing={2} sx={{ px: 2, pb: 2 }}>
        <Card>
          <CallCenterInterfaceForm
            formik={formik}
            loadingSubmit={loadingSubmit}
            raceOptions={raceOptions}
            genderOptions={genderOptions}
            languageOptions={languageOptions}
            eyeColorOptions={eyeColorOptions}
            hairColorOptions={hairColorOptions}
            relationshipOptions={relationshipOptions}
          />
        </Card>
      </Stack>
    </FormikProvider>
  );
};

export function CallCenterInterfaceForm({
  formik,
  loadingSubmit,
  raceOptions,
  genderOptions,
  languageOptions,
  eyeColorOptions,
  hairColorOptions,
  relationshipOptions,
  onSubmit,
}: any) {
  const { incident, gradingQuestions, loadingGradingQuestions } = useIncident();
  const extensions = useExtensions();

  const handleSubmit = async () => {
    await formik.submitForm();
    onSubmit();
  };

  useEffect(() => {
    console.log();
    if (formik.values?.gradingResponses?.length === 0) {
      console.log('got here adding grading questions');
      formik.setFieldValue(
        'gradingResponses',
        gradingQuestions.map((q: Question) => ({
          gradingAnswerSeq: uuidv4(),
          gradingQuestionSeq: q.questionSeq,
          responseText: '',
        }))
      );
    }
  }, [gradingQuestions]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <CardHeader title='Demographics' />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Caller Name'
                  id='callerName'
                  name='callerName'
                  value={formik.values?.callerName}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <AutocompleteWithOther
                  options={relationshipOptions}
                  autocompleteValue={formik.values?.relationshipToCaller}
                  onChangeAutocomplete={(_, value) =>
                    formik.setFieldValue('relationshipToCaller', value)
                  }
                  otherValue={formik.values?.relationshipToCallerOther}
                  onChangeOther={event =>
                    formik.setFieldValue('relationshipToCallerOther', event.target.value)
                  }
                  getOptionLabel={(option: Option) => option.optionName ?? ''}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label='Relationship' placeholder='Relationship' />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Caller Contact Info'
                  id='contactNumber'
                  name='contactNumber'
                  value={formik.values?.contactNumber}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Victims First Name'
                  id='firstName'
                  name='firstName'
                  value={formik.values?.firstName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Last Name'
                  id='lastName'
                  name='lastName'
                  value={formik.values?.lastName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Also Known As'
                  id='alsoKnownAs'
                  name='alsoKnownAs'
                  value={formik.values?.alsoKnownAs}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <NumericTextField
                    fullWidth
                    label='Age'
                    value={formik.values?.age}
                    onChange={value => formik.setFieldValue('age', value)}
                  />
                  <Typography>years</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name='dateOfBirth'
                    label='Date of Birth'
                    value={
                      formik.values?.dateOfBirth !== null ? dayjs(formik.values?.dateOfBirth) : null
                    }
                    onChange={data => {
                      formik.setFieldValue('dateOfBirth', data);
                    }}
                    slots={{
                      actionBar: ActionList,
                    }}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <AutocompleteWithOther
                  options={raceOptions}
                  autocompleteValue={formik.values?.race}
                  onChangeAutocomplete={(_, value) => formik.setFieldValue('race', value)}
                  otherValue={formik.values?.raceOther}
                  onChangeOther={event => formik.setFieldValue('raceOther', event.target.value)}
                  getOptionLabel={(option: Option) => option.optionName ?? ''}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label='Race/Ethnicity' placeholder='Race/Ethnicity' />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AutocompleteWithOther
                  options={genderOptions}
                  autocompleteValue={formik.values?.gender}
                  onChangeAutocomplete={(_, value) => formik.setFieldValue('gender', value)}
                  otherValue={formik.values?.genderOther}
                  onChangeOther={event => formik.setFieldValue('genderOther', event.target.value)}
                  getOptionLabel={(option: Option) => option.optionName ?? ''}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label='Gender' placeholder='Gender' />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <NumericTextField
                    fullWidth
                    label='Height in Feet'
                    value={formik.values?.heightInFeet}
                    onChange={value => formik.setFieldValue('heightInFeet', value)}
                  />
                  <Typography>ft.</Typography>
                  <NumericTextField
                    fullWidth
                    label='Height in Inches'
                    value={formik.values?.heightInInches}
                    onChange={value => formik.setFieldValue('heightInInches', value)}
                  />
                  <Typography>in.</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <NumericTextField
                    fullWidth
                    label='Weight'
                    value={formik.values?.weightInPounds}
                    onChange={value => formik.setFieldValue('weightInPounds', value)}
                  />
                  <Typography>lbs.</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <AutocompleteWithOther
                  options={eyeColorOptions}
                  autocompleteValue={formik.values?.eyeColor}
                  onChangeAutocomplete={(_, value) => formik.setFieldValue('eyeColor', value)}
                  otherValue={formik.values?.eyeColorOther}
                  onChangeOther={event => formik.setFieldValue('eyeColorOther', event.target.value)}
                  getOptionLabel={(option: Option) => option.optionName ?? ''}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label='Eye Color' placeholder='Eye Color' />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AutocompleteWithOther
                  options={hairColorOptions}
                  autocompleteValue={formik.values?.hairColor}
                  onChangeAutocomplete={(_, value) => formik.setFieldValue('hairColor', value)}
                  otherValue={formik.values?.hairColorOther}
                  onChangeOther={event =>
                    formik.setFieldValue('hairColorOther', event.target.value)
                  }
                  getOptionLabel={(option: Option) => option.optionName ?? ''}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label='Hair Color' placeholder='Hair Color' />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AutocompleteWithOther
                  options={languageOptions}
                  autocompleteValue={formik.values?.primaryLanguage}
                  onChangeAutocomplete={(_, value) =>
                    formik.setFieldValue('primaryLanguage', value)
                  }
                  otherValue={formik.values?.primaryLanguageOther}
                  onChangeOther={event =>
                    formik.setFieldValue('primaryLanguageOther', event.target.value)
                  }
                  getOptionLabel={(option: Option) => option.optionName ?? ''}
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Primary Language'
                      placeholder='Primary Language'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardHeader title='Additional Information' />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack direction='row' spacing={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        value={formik.values?.isLastKnownLocationAddress}
                        onChange={event => {
                          formik.setFieldValue('isLastKnownLocationAddress', event.target.checked);
                        }}
                      />
                    }
                    label='Is Address?'
                    labelPlacement='bottom'
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '8pt',
                        whiteSpace: 'nowrap',
                      },

                      // Some magic to reduce the padding around the switch
                      '& .MuiButtonBase-root': {
                        p: 0,
                      },
                      '& .MuiSwitch-root': {
                        p: 0.4,
                        height: '20px',
                        width: '40px',
                      },
                    }}
                  />

                  {formik.values?.isLastKnownLocationAddress ? (
                    <AddressField
                      placeholder='Last Known Location'
                      fieldName='lastKnownLocationAddress'
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label='Last Known Location'
                      id='lastKnownLocationText'
                      name='lastKnownLocationText'
                      value={formik.values?.lastKnownLocationText}
                      onChange={formik.handleChange}
                    />
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <AddressField placeholder='Residence Address' fieldName='residenceAddress' />
              </Grid>

              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name='dateLastSeen'
                    label='Date Last Seen'
                    value={
                      formik.values?.dateLastSeen !== null
                        ? dayjs(formik.values?.dateLastSeen)
                        : null
                    }
                    onChange={data => {
                      formik.setFieldValue('dateLastSeen', data);
                    }}
                    slots={{
                      actionBar: ActionList,
                    }}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Missing Person Phone Number'
                  id='victimPhoneNumber'
                  name='victimPhoneNumber'
                  value={formik.values?.victimPhoneNumber}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label='Identifying Features'
                  id='identifyingFeatures'
                  name='identifyingFeatures'
                  value={formik.values?.identifyingFeatures}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label='Clothing Description'
                  id='clothingDescription'
                  name='clothingDescription'
                  value={formik.values?.clothingDescription}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label='Accessories'
                  id='accessories'
                  name='accessories'
                  value={formik.values?.accessories}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label='Medications Needed'
                  id='medicationsNeeded'
                  name='medicationsNeeded'
                  value={formik.values?.medicationsNeeded}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label='Other Info'
                  id='otherInformation'
                  name='otherInformation'
                  value={formik.values?.otherInformation}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardHeader
            title='Grading Questions'
            action={
              <LoadingIconButton
                loading={loadingSubmit}
                onClick={handleSubmit}
                disabled={incident === null}
              >
                <Save />
              </LoadingIconButton>
            }
          />
          <Divider />

          <CardContent>
            {loadingGradingQuestions ? (
              Array.from({ length: 5 }, (_, index) => (
                <Stack spacing={0} sx={{ mb: 3 }}>
                  <Skeleton variant='text' />
                  <Skeleton variant='text' width='60%' />
                  <Skeleton variant='rounded' height={100} sx={{ mt: 1.5 }} />
                </Stack>
              ))
            ) : (
              <Stack spacing={2}>
                {gradingQuestions?.map((question, index) => (
                  <>
                    <RichTextReadOnly content={question.questionText} extensions={extensions} />
                    <TextField
                      id={`gradingResponses[${index}].responseText`}
                      name={`gradingResponses[${index}].responseText`}
                      value={formik.values?.gradingResponses?.[index]?.responseText ?? ''}
                      onChange={event => {
                        console.log(event);
                        console.log(
                          formik.values?.gradingResponses?.[index]?.responseText ?? 'nothing here'
                        );

                        const updatedValues = { ...formik.values };
                        if (
                          updatedValues.gradingResponses?.[index]?.gradingAnswerSeq === null ||
                          updatedValues.gradingResponses?.[index]?.gradingAnswerSeq === undefined
                        ) {
                          console.log('got here 1');
                          console.log(updatedValues.gradingResponses);
                          updatedValues.gradingResponses[index].gradingAnswerSeq = uuidv4();
                        }

                        if (
                          updatedValues.gradingResponses?.[index]?.gradingQuestionSeq === null ||
                          updatedValues.gradingResponses?.[index]?.gradingQuestionSeq === undefined
                        ) {
                          console.log('got here 2');
                          updatedValues.gradingResponses[index].gradingQuestionSeq =
                            question.questionSeq;
                        }

                        console.log('got here 3');
                        updatedValues.gradingResponses[index].responseText = event.target.value;

                        console.log('got here 4');
                        formik.setValues(updatedValues);
                      }}
                      multiline
                      rows={4}
                    />
                  </>
                )) ?? <></>}
              </Stack>
            )}
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
}

export default CallCenterInterface;
