import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormHelperText,
  TextField,
  ClickAwayListener,
  Popper,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Form } from 'formik';
import FormControl from '@mui/material/FormControl';
import { saveAdminData } from '../../../../services/utility-api';
import AddressData from '../../../../components/AdministrationModules/AddressData/AddressView';
import ContactData from '../../../../components/AdministrationModules/ContactData/ContactView';
import ApiNotification from '../../../../components/DialogBox';
import ContactTab from '../../../../components/AdministrationModules/ContactComponents/ContactTab';

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const AddEdit = ({ editObj, county, getAllData, setIsAddEditOn, allRow, labListPermissions }) => {
  console.log('editObj', editObj);
  const id = '00000000-0000-0000-0000-000000000000';
  const [dropdownopen, setDropdownopen] = useState(false);
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [countyNameMapping, setCountyNameMapping] = useState({});
  const [contactsError, setContactsError] = useState({});
  const [contactsErrorMessage, setContactsErrorMessage] = useState('');
  const [contactsErrorModal, setContactsErrorModal] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {}, [editObj]);

  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const referencePermissions = permissions?.['Lab List'];

  const handleClickAway = e => {
    setDropdownopen(false);
  };

  const initialValues = {
    ...editObj,
    countys: editObj.labOnCounty ?? [],
    address: editObj?.address ?? [],
    contactItem: editObj?.contactItem ?? [],
  };

  const validationSchema = Yup.object({
    labName: Yup.string().required('Lab name is required').nullable(),
    labOnCounty: Yup.array().min(1, 'Jurisdiction is required').nullable(),
    personLastName: Yup.string().required('Last name is required').nullable(),
    address: Yup.array()
      .of(
        Yup.object().shape({
          addressTypeSeq: Yup.string().nullable(),
          addressLine1: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
              addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
            } else {
              return Yup.string().required('Address line 1 is required');
            }
          }),
          apartmentNo: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
              addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
            } else {
              return Yup.string().max(10, '').nullable();
            }
          }),
          floor: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
              addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
            } else {
              return Yup.string().max(10, '').nullable();
            }
          }),
          zip: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
              addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
            } else {
              return Yup.string().max(10, '').nullable();
            }
          }),
          city: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
              addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
            } else {
              return Yup.string().required('City is required');
            }
          }),
        })
      )
      .min(1, 'Address is a required field')
      .nullable(),
    contactItem: Yup.array()
      .of(
        Yup.object().shape({
          contactItemTypeSeq: Yup.string().required('Contact type is required'),
          contactItemDetails: Yup.string().required('Contact Details is required'),
        })
      )
      .nullable(),
    // .min(1, 'Contact is a required field')
  });

  useEffect(() => {
    const tempCountyNameMapping = {};
    Object.keys(county).map(countySeq => {
      tempCountyNameMapping[county[countySeq]['countyName']] = county[countySeq];
    });
    setCountyNameMapping(tempCountyNameMapping);
  }, [county]);

  // updated address field payload
  const updateStatusOfAddressFields = values => {
    const valuesAddressSeq = values?.address?.map(item => item.addressSeq);

    editObj?.address?.filter(item => {
      if (!valuesAddressSeq?.includes(item.addressSeq)) {
        values?.address?.push({ ...item, status: 'InActive' });
        return { ...item, status: 'InActive' };
      }
    });
    return values?.address;
  };

  // updated contact field payload
  const updateStatusOfContactFields = values => {
    const valueContactSeq = values?.contactItem?.map(item => item.contactItemSeq);

    editObj?.contactItem?.filter(item => {
      if (!valueContactSeq?.includes(item.contactItemSeq)) {
        values?.contactItem?.push({ ...item, isActive: false,  status: 'InActive', });
        return { ...item, isActive: false,  status: 'InActive', };
      }
    });

    return values?.contactItem;
  };

  const sendContactsError = (values) => {
    if (values?.contactItem?.length > 0) {
      if (
        (contactsError.name && !contactsError?.value) ||
        (!contactsError.name && contactsError?.value)
      ) {
        setContactsErrorModal(true);
        setContactsErrorMessage('Contact Field is required');
        return true;
      } else if (
        contactsError?.name?.toLowerCase() === 'email' &&
        !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          contactsError?.value
        )
        // !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(contactsError?.value)
      ) {
        setContactsErrorModal(true);
        setContactsErrorMessage('Please enter valid email address');
        return true;
      }
      return false;
    }
    return false;
  };
  const onSave = async values => {
    const checkContactValidation = sendContactsError(values);
    if (!checkContactValidation) {
      const newLiveryCounty = [];

      editObj?.labOnCounty?.map(coun => {
        let foundMatchingCounty = false;

        values?.labOnCounty?.map(val => {
          if (coun.countySeq === countyNameMapping[val]['countySeq']) {
            foundMatchingCounty = true;
          }
        });
        if (foundMatchingCounty) {
          newLiveryCounty.push({
            labSeq: values?.labSeq,
            countySeq: coun.countySeq,
            status: 'Active',
          });
        } else {
          newLiveryCounty.push({
            labSeq: values?.labSeq,
            countySeq: coun.countySeq,
            status: 'InActive',
          });
        }
      });

      values?.labOnCounty?.map(val => {
        let foundMatchingCounty = false;

        editObj?.labOnCounty?.map(coun => {
          if (coun.countySeq === countyNameMapping[val]['countySeq']) {
            foundMatchingCounty = true;
          }
        });
        if (!foundMatchingCounty) {
          newLiveryCounty.push({
            labSeq: id,
            countySeq: countyNameMapping[val]['countySeq'],
            status: 'Active',
          });
        }
      });


      const updatedContact = updateStatusOfContactFields(values);
      const updatedAddress = updateStatusOfAddressFields(values);
      const postObj = {
        labSeq: Object.keys(editObj).length === 0 ? id : editObj?.labSeq,

        labName: values?.labName,

        personSeq: Object.keys(editObj).length === 0 ? id : editObj?.personSeq,


        countySeq: '00000000-0000-0000-0000-000000000000',
        status: 'Active',
        contactInfoSeq: Object.keys(editObj).length === 0 ? id : editObj?.contactInfoSeq,
        labOrder: null,
        person: {
          personSeq: Object.keys(editObj).length === 0 ? id : editObj?.personSeq,
          personFirstName: values?.personFirstName,
          personLastName: values?.personLastName,
          personMiddleName: values?.personMiddleName,
          personSalutationSeq: '00000000-0000-0000-0000-000000000000',
          licenseNo: '',
          fdnylicenseNo: '',
          personGenderCode: '',
          personRaceSeq: '00000000-0000-0000-0000-000000000000',
          personOtherRace: '',
          personSuffix: values?.personSuffix,
          personTitle: '',
          personMaidenName: '',
          personContactInfoSeq: '00000000-0000-0000-0000-000000000000',
          caseWorksheetSeq: '00000000-0000-0000-0000-000000000000',
          contactItem: null,
          address: null,
        },
        labOnCounty: newLiveryCounty,
        address: updatedAddress,
        // contactItem: contacts || [],
        contactItem: updatedContact || [],
      };
      try {
        if (
          allRow
            ?.filter(data => data?.labSeqSeq !== values?.labSeqSeq)
            ?.findIndex(data => data?.labName.toLowerCase() === postObj?.labName.toLowerCase()) !==
          -1
        ) {
          setDialogContents('Duplicate Lab Name!');
          setOpenApiDialogBox(true);
        } else {
          const response = await saveAdminData({
            values: postObj,
            tableName: 'lab',
          });
          if (response.status == 0) {
            setTimeout(() => {
              setIsAddEditOn(false);
              getAllData();
            }, 1700);
          }
          setDialogContents(response.message);
          setOpenApiDialogBox(true);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const checkAllChange = (event, handleChange) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      let newdata = [...Object.keys(county).map(countySeq => county[countySeq]['countyName'])];
      delete newdata[0];
      handleChange({ target: { value: newdata, name: 'labOnCounty' } });
    } else {
      handleChange({ target: { value: [], name: 'labOnCounty' } });
    }
  };
  return (
    <>
      {/* <Paper elevation={3} spacing={2} sx={{ padding: '16px' }}> */}
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>{Object.keys(editObj).length ? 'Modify' : 'Enter New'} Lab</strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            ...initialValues,
            labOnCounty: initialValues.countys
              ? initialValues.countys.map(
                  lab =>
                    county &&
                    Object.keys(county) &&
                    county[lab.countySeq] &&
                    county[lab.countySeq]['countyName']
                )
              : [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          limitTags={9}
                          id='checkboxes-tags-demo'
                          options={
                            Object.keys(county).map(countySeq => county[countySeq]['countyName']) ||
                            []
                          }
                          value={values.labOnCounty}
                          open={dropdownopen}
                          name='labOnCounty'
                          onChange={(event, newValue, reason) => {
                            if (reason === 'selectOption') {
                              handleChange({
                                target: {
                                  value: newValue,
                                  name: 'labOnCounty',
                                },
                              });
                            } else if (reason === 'removeOption') {
                              setCheckAll(false);
                              handleChange({
                                target: {
                                  value: newValue,
                                  name: 'labOnCounty',
                                },
                              });
                            } else if (reason === 'clear') {
                              handleChange({
                                target: { value: [], name: 'labOnCounty' },
                              });
                              setCheckAll(false);
                            }
                          }}
                          onClose={(e, reason) => {
                            if (reason === 'escape') {
                              setDropdownopen(false);
                            }
                          }}
                          onOpen={() => {
                            setDropdownopen(true);
                          }}
                          PopperComponent={param => (
                            <PopperStyledComponent {...param}>
                              <Box {...param} />
                              <Divider />
                            </PopperStyledComponent>
                          )}
                          getOptionLabel={option => {
                            return option ? option?.toString() : [];
                          }}
                          renderOption={(props, option, { selected }) => (
                            <>
                              <li
                                {...props}
                                style={{
                                  marginLeft:
                                    option == 'New Jersey' ||
                                    option == 'NJ State Case' ||
                                    option == 'MAiD Case'
                                      ? ''
                                      : '15px',
                                }}
                              >
                                {option === 'Select All' ? (
                                  <Checkbox
                                    checked={checkAll}
                                    onChange={e => checkAllChange(e, handleChange)}
                                    id='check-all'
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={e => e.preventDefault()}
                                  />
                                ) : (
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected || checkAll}
                                  />
                                )}
                                {option}
                              </li>
                            </>
                          )}
                          style={{ width: '100%' }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Jurisdiction'
                              required
                              placeholder='Select Jurisdiction'
                            />
                          )}
                        />
                      </Box>
                    </ClickAwayListener>
                    <ErrorMessage
                      name='labOnCounty'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={0} mt={2} spacing={2}>
                    <Typography variant='h6'>
                      <strong>Lab</strong>
                    </Typography>
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        required
                        id='labName'
                        label='Lab Name'
                        variant='outlined'
                        name='labName'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.labName}
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                      />
                    </FormControl>
                    <p style={{ color: '#D32F2F', fontSize: '0.75rem' }}>{errors?.labName}</p>
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}></Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        focused
                        required
                        id='personLastName'
                        label='Last Name'
                        variant='outlined'
                        name='personLastName'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.personLastName}
                        onChange={handleChange}
                      />
                      <p style={{ color: '#D32F2F', fontSize: '0.75rem' }}>
                        {errors?.personLastName}
                      </p>
                      {/* <ErrorMessage
                        name='personLastName'
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      /> */}
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        focused
                        id='personSuffix'
                        label='Suffix'
                        variant='outlined'
                        name='personSuffix'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.personSuffix}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        focused
                        id='personFirstName'
                        label='First Name'
                        variant='outlined'
                        name='personFirstName'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.personFirstName}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        focused
                        id='personMiddleName'
                        label='Middle Name'
                        variant='outlined'
                        name='personMiddleName'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.personMiddleName}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <FormControl
                      error
                      required
                      component='fieldset'
                      variant='standard'
                      sx={{ marginTop: '10px' }}
                    >
                      <AddressData
                        addresses={values?.address}
                        nursingHomeSeq={values.nursingHomeSeq || id}
                        funeralHomeSeq={values.funeralHomeSeq || id}
                        lawEnforcementSeq={values.lawEnforcementSeq || id}
                        contactInfoSeq={values.contactInfoSeq || id}
                        handleChange={data =>
                          handleChange({
                            target: {
                              value: data,
                              name: 'address',
                            },
                          })
                        }
                      />
                      {typeof errors.address == 'string' && (
                        <ErrorMessage
                          name='address'
                          render={msg => <FormHelperText error>{msg}</FormHelperText>}
                        />
                      )}
                    </FormControl>
                  </Grid>
                  <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <FormControl
                      fullWidth
                      error
                      required
                      component='fieldset'
                      variant='standard'
                      sx={{ paddingTop: '0px' }}
                    >
                      {/* <ContactData
                        contactItem={values?.contactItem || []}
                        handleChange={(data) =>
                          handleChange({
                            target: {
                              value: data,
                              name: 'contactItem',
                            },
                          })
                        }
                      /> */}
                      <ContactTab
                        contactItem={values?.contactItem || []}
                        handleChange={data =>
                          handleChange({
                            target: {
                              value: data,
                              name: 'contactItem',
                            },
                          })
                        }
                        setContactDetailsError={err => setContactsError(err)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Button
                        onClick={handleSubmit}
                        type='submit'
                        variant='contained'
                        sx={{ minWidth: '125px', marginTop: '7px' }}
                        disabled={!labListPermissions?.edit && !labListPermissions?.add}
                      >
                        {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      {/* </Paper> */}
      <ApiNotification
        openApiDialogBox={openApiDialogBox}
        setOpenApiDialogBox={setOpenApiDialogBox}
        dialogContents={dialogContents}
        closeDialogBox={() => {
          setOpenApiDialogBox(false);
        }}
      />
      {contactsError ? (
        <ApiNotification
          openApiDialogBox={contactsErrorModal}
          closeDialogBox={() => setContactsErrorModal(false)}
          dialogContents={contactsErrorMessage}
        />
      ) : null}
    </>
  );
};
export default AddEdit;
