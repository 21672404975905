import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// material-ui
import { Grid, Box, Button, IconButton, Tooltip, Divider } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';

// project imports
import MainCard from '../../../../components/AdministrationModules/MainCard';
import ReferenceMasterTableFilter from './ReferenceMasterTableFilter';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import AddEdit from './AddEdit';
import {
  activeData,
  deleteData,
  getAdminData,
  getAllCounty,
} from '../../../../services/utility-api';
import { SlideProps } from '@mui/material/Slide';
import { nameMappingReferenceMaterTable } from '../referenceTypes';
import AccessDenied from '../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../components/AdministrationModules/Breadcrumb';
import DataGridComponent from '../../../../components/AdministrationModules/DataGridComponent';
import { useAuth } from '../../../../utils/auth/AuthService';

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ReferenceMasterTableType1 = () => {
  const { user } = useAuth();
  let { referenceMasterTableName = '' } = useParams<{ referenceMasterTableName: string }>();
  const authorizedToEdit = user?.roleCheck(['8f7']) || false;
  const [isAddEditOn, setIsAddEditOn] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [isCode, setIsCode] = useState(false);
  const [isCounty, setIsCounty] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] = useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState<{
      icon?: string;
      seq?: string;
      status?: string;
    }>({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');

  const masterTableName = nameMappingReferenceMaterTable[referenceMasterTableName || ''] || referenceMasterTableName;

  const fetchTableName = () => {
    const tableName = ['usarmyagency'];
    if (tableName.includes(referenceMasterTableName!?.toLowerCase())) {
      referenceMasterTableName = 'usArmyAgency';
    }
  };

  const fetchField = () => {
    const referenceTable = ['medicationtype', , 'ageestimate'];
    if (referenceTable.includes(referenceMasterTableName!?.toLowerCase())) {
      return 'order';
    } else {
      return (
        referenceMasterTableName[0]?.toLowerCase() + referenceMasterTableName!.slice(1) + 'Order'
      );
    }
  };
  const fetchNameField = () => {
    const referenceTable = ['securityquestion'];
    if (referenceTable.includes(referenceMasterTableName.toLowerCase())) {
      return (
        referenceMasterTableName[0]?.toLowerCase() + referenceMasterTableName.slice(1) + 'Details'
      );
    } else {
      return referenceMasterTableName[0]?.toLowerCase() + referenceMasterTableName.slice(1) + 'Name';
    }
  };

  const fetchHeaderName = () => {
    const referenceTable = ['usarmyagency'];
    if (referenceTable.includes(referenceMasterTableName?.toLowerCase())) {
      let name = masterTableName
        .split('')
        .map(alpha => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim();
      name = name.split(' ')[0].toUpperCase() + ' ' + name.split(' ')[1] + ' ' + name.split(' ')[2];
      return name;
    } else {
      const masterTableListName = masterTableName
        .split('')
        .map(alpha => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim();
      return masterTableListName.toLowerCase().includes('county')
        ? masterTableListName.replace(/County/g, 'Jurisdiction')
        : masterTableListName;
    }
  };

  const [columns, setColumns] = useState([
    {
      field: fetchNameField(),
      headerName: fetchHeaderName(),
      // headerClassName: 'super-app-theme--header',
      minWidth: 250,
      align: 'left',
      flex: 5,
    },
    {
      field: fetchField(),
      headerName: 'List Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const [rowsToShow, setRowsToShow] = useState<any[]>([]);
  const [countyNames, setCountyNames] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const MENU_OBJECT = {
    Home: '/support',
    'Reference Data': '/support',
    [fetchHeaderName()]: '',
  };

  const getAllData = async () => {
    setIsLoading(true);
    const response = await getAdminData(referenceMasterTableName);
    const countyResponse : any = await getAllCounty();

    setCountyNames(countyResponse);
    const data = response.data.data.map((row: Record<string, any>) => {
      const newObj: Record<string, any> = {};
      Object.keys(row).map(key => {
        newObj[key] = row[key];
      });
      newObj.id =
        newObj[
          referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
        ];
      if (
        newObj[
          referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Order'
        ]
      )
        newObj[
          referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Order'
        ] = Number(
          newObj[
            referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Order'
          ]
        );
      return newObj;
    });

    let isCodeColumnAdded = false;
    columns.forEach(column => {
      if (
        column.field ==
        referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Code'
      ) {
        isCodeColumnAdded = true;
      }
    });
    if (
      data[0].hasOwnProperty(
        referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Code'
      ) &&
      !isCodeColumnAdded
    ) {
      setColumns(prev => [
        {
          field:
            referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Code',
          headerName:
            referenceMasterTableName
              .split('')
              .map(alpha => {
                if (alpha <= 'Z') return ' ' + alpha;
                return alpha;
              })
              .join('')
              .trim() + ' Code',
          // headerClassName: 'super-app-theme--header',
          minWidth: 250,
          align: 'left',
          flex: 5,
        },
        ...prev,
      ]);
      setIsCode(true);
    }

    let isCountyColumnAdded = false;
    columns.forEach(column => {
      if (column.field == 'countySeq') {
        isCountyColumnAdded = true;
      }
    });
    if (data[0].hasOwnProperty('countySeq') && !isCountyColumnAdded) {
      setColumns(prev => [
        ...prev,
        {
          field: 'countySeq',
          headerName: 'Jurisdiction',
          // headerClassName: 'super-app-theme--header',
          minWidth: 150,
          align: 'left',
          flex: 5,
          renderCell: (cellValues : any) => {
            return <>{countyResponse[cellValues.row.countySeq]?.countyName}</>;
          },
        },
      ]);
      setIsCounty(true);
    }

    let isStatusColumnAdded = false;
    columns.forEach(column => {
      if (column.field == 'status') {
        isStatusColumnAdded = true;
      }
    });
    if (!isStatusColumnAdded) {
      setColumns(prev => [
        ...prev,
        {
          field: 'status',
          headerName: 'Status',
          minWidth: 100,
          align: 'left',
          flex: 1,
          renderCell: (cellValues: any) => {
            const handleClick = async (row: any) => {
              setOpenActiveInActiveDialogBox(true);
              const newIconData = row.status === 'Active' ? 'locked' : 'done';
              setActiveInactiveClickData({
                icon: newIconData,
                seq: row[
                  referenceMasterTableName[0].toLowerCase() +
                    referenceMasterTableName.slice(1) +
                    'Seq'
                ],
                status: row.status,
              });
            };

            return Number(
              cellValues.row[
                referenceMasterTableName[0].toLowerCase() +
                  referenceMasterTableName.slice(1) +
                  'Order'
              ]
            ) > 90000 ? (
              <></>
            ) : (
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClick(cellValues.row);
                }}
                color='primary'
                size='small'
              >
                {cellValues.row.status === 'Active' ? (
                  <Tooltip title='Inactive'>
                    <LockIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title='Active'>
                    <DoneIcon />
                  </Tooltip>
                )}
              </IconButton>
            );
          },
        },
      ]);
    }

    let maxOrder = 0;
    for (let i = 0; i < data.length; i++) {
      if (Number(data[i][fetchField()]) > maxOrder && Number(data[i][fetchField()]) < 90000) {
        maxOrder = Number(data[i][fetchField()]);
      }
    }
    setMaxOrder(maxOrder);
    setRows(data);
    setRowsToShow(data.filter((row: any) => row['status'].toLowerCase() == 'active'));
    setIsLoading(false);
  };

  const editRow = (params: any) => {
    if (authorizedToEdit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };

  useEffect(() => {
    getAllData();
    fetchTableName();
  }, []);

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };

  if (!authorizedToEdit) {
    return (
      <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
        <Grid item xs={12} md={12}>
          <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
        </Grid>
        <Grid item xs={12} md={12}>
          <AccessDenied />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={2} sx={{ padding: '16px' }}>
        <Grid item xs={12} md={12}>
          <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3 }} />
        </Grid>
        <Grid item xs={12}>
          <Box component={'div'} sx={{ tr: 'hover', cursor: 'pointer' }}>
            <ReferenceMasterTableFilter
              rows={rows}
              isCounty={referenceMasterTableName !== 'SurgeryType' ? isCounty : false}
              countyNames={countyNames}
              setRowsToShow={setRowsToShow}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <DataGridComponent
            sortModel={[
              {
                field: fetchNameField(),
                sort: 'asc',
              },
            ]}
            listresults={rowsToShow}
            columnsInput={columns}
            idcolumn='id'
            gridheight={rowsToShow.length > 0 ? 'auto' : 130}
            gridcolor='#36454f'
            rowsperpage={10}
            onRowClick={editRow}
            loading={isLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant='contained'
            onClick={() => {
              setIsAddEditOn(false);
              setTimeout(() => {
                setEditObj({});
                setIsAddEditOn(true);
              }, 200);
            }}
            disabled={!authorizedToEdit}
          >
            Add {fetchHeaderName()}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 1, pb: 0 }} />
          {isAddEditOn && editObj && (
            <Box component={'div'}>
              <AddEdit
                rows={rows}
                editObj={editObj}
                isCounty={referenceMasterTableName !== 'SurgeryType' ? isCounty : false}
                isCode={isCode}
                referenceMasterTableName={referenceMasterTableName}
                masterTableName={masterTableName}
                maxOrder={maxOrder}
                setIsAddEditOn={setIsAddEditOn}
                getAllData={getAllData}
                countyNames={countyNames}
              />
            </Box>
          )}
        </Grid>

        <div className='modal'>
          <Dialog
            open={openActiveInActiveDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenActiveInActiveDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                Are you sure you want to{' '}
                {activeInactiveClickData?.status === 'Active' ? 'InActive ' : 'Active'} this{' '}
                {masterTableName
                  .split('')
                  .map(alpha => {
                    if (alpha <= 'Z') return ' ' + alpha;
                    return alpha;
                  })
                  .join('')
                  .trim()}
                ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>No</Button>
              <Button onClick={changeActiveInActiveClick}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>

        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
        {/* </MainCard> */}
      </Grid>
    );
  }
};

export default ReferenceMasterTableType1;
