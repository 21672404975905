import { Box, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormikContext } from 'formik';

export default function DecedentNameHUD() {
  const formik = useFormikContext<any>();
  const theme = useTheme();

  return (
    <div>
      <Box
        component={Paper}
        elevation={0}
        sx={{ p: 0, m: 0, backgroundColor: theme.palette.mode === 'light' ? '#e3f2fd' : '#213a52' }}
      >
        <Stack spacing={0.5} sx={{ pt: 0.5 }}>
          <Grid container justifyContent='space-evenly' spacing={0.5}>
            <Grid>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  Last Name:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                  {formik.values?.caseDemographics?.decedentPerson?.lastName || '--'}
                </Typography>
              </Stack>
            </Grid>

            <Grid>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  First Name:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                  {formik.values?.caseDemographics?.decedentPerson?.firstName || '--'}
                </Typography>
              </Stack>
            </Grid>

            {/* <Grid>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  Suffix:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                  {formik.values?.caseDemographics?.decedentPerson?.suffix || '--'}
                </Typography>
              </Stack>
            </Grid> */}

            <Grid>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  Middle Name:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                  {formik.values?.caseDemographics?.decedentPerson?.middleName || '--'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Divider sx={{ opacity: 1, mt: 1, mb: 2 }} />
      </Box>
    </div>
  );
}
