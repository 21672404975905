import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridApiContext,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridTreeNodeWithRender,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import { GuidEmptySeq } from '../../../utils/constants/GuidEmptySeq';
import { Option } from '../../Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { LiveryCaseWithStatusOptions } from '../LiveryCheckInView';
import { AddressCell } from './AddressCell';
import { LegacyLiveryStatus, LiveryAPI, LiveryCase } from './livery.api';
import { YourLiveryDataGridNoRowsOverlay } from './YourLiveryDataGirdNoRowsOverlay';
import { YourLiveryDataGridAcceptedActions } from './YourLiveryDataGridAcceptedActions';
import { YourLiveryDataGridDetailPane } from './YourLiveryDataGridDetailPane';

export function YourLiveryDataGrid({
  liveryCases = [],
  filterModel = {
    items: [
      {
        field: 'status',
        operator: 'equals',
        value: 'assigned',
      },
    ],
  },
  setFilterModel,
  loading = true,
  refetchCases,
}: {
  liveryCases: LiveryCaseWithStatusOptions[];
  filterModel: GridFilterModel;
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  loading: boolean;
  refetchCases: () => Promise<void>;
}) {
  const theme = useTheme();
  const isMobile = true;

  // Apply filter to liveryCases
  const filteredCases = useMemo(() => {
    return liveryCases.filter(liveryCase => {
      return filterModel.items.every(filterItem => {
        if (filterItem.field in liveryCase) {
          const value = liveryCase[filterItem.field as keyof LiveryCaseWithStatusOptions];
          switch (filterItem.operator) {
            case 'equals':
              return value === filterItem.value;
            case 'contains':
              return (
                typeof value === 'string' &&
                value.toLowerCase().includes(filterItem.value.toLowerCase())
              );
            // Add more operators as needed
            default:
              return true;
          }
        }
        return true;
      });
    });
  }, [liveryCases, filterModel]);

  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const handleDetailPanelExpandedRowIdsChange = (
    ids: GridRowId[],
    details: GridCallbackDetails<any>
  ) => {
    setExpandedRowIds(ids);
  };

  const getDetailPanelContent = useCallback(
    ({ params }: { params: GridRowParams<LiveryCase> }) => (
      <Collapse in={expandedRowIds.includes(params.row.caseSeq)} unmountOnExit={false}>
        <Box component='div' sx={{ p: 0, mb: 2 }}>
          <Stack justifyContent='flex-start' justifyItems='flex-start'>
            <YourLiveryDataGridDetailPane {...params} />

            <Typography variant='caption' color='text.secondary' mt={1} ml={1}>
              Assigned on{' '}
              {new Date(
                params.row.activityLog.find(
                  a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED
                )?.createdOn ?? Date.now()
              ).toLocaleDateString()}{' '}
              by{' '}
              {
                params.row.activityLog.find(
                  a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED
                )?.loggedByUsername
              }
            </Typography>
          </Stack>
        </Box>
        <Divider component='hr' variant='fullWidth' />
      </Collapse>
    ),
    [expandedRowIds]
  );

  const MobileListItem = ({ liveryCase }: { liveryCase: LiveryCaseWithStatusOptions }) => {
    const activityLog = liveryCase.activityLog ?? [];
    const assignedActivity =
      activityLog.find(a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED) ?? null;

    const assignedOnDate = assignedActivity?.setAt
      ? dayjs(assignedActivity.setAt).format('YYYY-MM-DDTHH:mm:ss')
      : 'Unknown';

    const mockRowParams: GridRowParams<LiveryCaseWithStatusOptions> = {
      id: liveryCase.caseSeq,
      columns: columns,
      row: liveryCase,
      // @ts-ignore
      api: {
        getCellMode: () => 'view',
        setCellMode: () => {},
        commitCellChange: () => Promise.resolve(),
      } as any,
    };

    const mockRenderCellParams: GridRenderCellParams<
      LiveryCaseWithStatusOptions,
      any,
      any,
      GridTreeNodeWithRender
    > = {
      id: liveryCase.caseSeq,
      field: '',
      row: liveryCase,
      value: null,
      // @ts-ignore
      api: mockRowParams.api,
      cellMode: 'view',
      colDef: {} as any,
      rowNode: {} as any,
      formattedValue: null,
      hasFocus: false,
      isEditable: false,
      tabIndex: 0,
    };

    const otherItemsRequested =
      (typeof liveryCase.otherRequestedItems === 'string' &&
        liveryCase.otherRequestedItems.length > 0) ||
      false;
    let requestedItems: Option[] =
      liveryCase.requestedItems.filter(item => item.optionName !== 'Others') || [];
    if (otherItemsRequested) {
      requestedItems.push({
        optionSeq: GuidEmptySeq,
        isActive: true,
        // @ts-ignore
        optionName: liveryCase.otherRequestedItems,
      });
    }

    return (
      <ListItem alignItems='flex-start'>
        <ListItemText
          primary={liveryCase.caseID + ' — ' + liveryCase.decedentFullName}
          secondary={
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={0} justifyContent='center'>
                    <Grid item xs={3}>
                      <Typography variant='body2' color='text.primary'>
                        Assigned on:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {assignedOnDate}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='body2' color='text.primary'>
                        MLDI:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {liveryCase.assignedMLDI || 'None'}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='body2' color='text.primary'>
                        Notes from MLDI:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {liveryCase?.liveryNotes || 'None'}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='body2' color='text.primary'>
                        Pickup address:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <AddressCell address={liveryCase.address} />
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant='body2' color='text.primary'>
                        Requested Items:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {requestedItems.length === 0 && <p>None</p>}
                      <Stack direction='row' spacing={1}>
                        {requestedItems.map((item, index) => (
                          <>
                            {item.optionName?.toUpperCase()}
                            {index === requestedItems.length - 1 ? '' : ', '}
                          </>
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>

                  <br />
                </Grid>
                <Grid item xs={12}>
                  {liveryCase.status === 'assigned' && (
                    <AssignedActions {...mockRenderCellParams} />
                  )}
                  {liveryCase.status === 'accepted' && (
                    <YourLiveryDataGridAcceptedActions {...mockRenderCellParams} />
                  )}
                </Grid>
              </Grid>
            </>
          }
        />
      </ListItem>
    );
  };

  const apiRef = useGridApiRef();

  return (
    <Box component='div' height='100%'>
      {isMobile && (
        <>
          {loading ? (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {[...Array(3)].map((_, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems='flex-start'>
                    <ListItemText
                      primary={<Skeleton width='50%' />}
                      secondary={
                        <>
                          <Skeleton width='100%' />
                          <Skeleton width='80%' />
                          <Skeleton width='90%' />
                        </>
                      }
                    />
                  </ListItem>
                  <Divider component='li' />
                </React.Fragment>
              ))}
            </List>
          ) : filteredCases.length === 0 ? (
            <Typography variant='body1' textAlign='center' mt={2}>
              No cases found.
            </Typography>
          ) : (
            <GridApiContext.Provider value={apiRef.current}>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {filteredCases.map(liveryCase => (
                  <React.Fragment key={liveryCase.caseSeq}>
                    <MobileListItem liveryCase={liveryCase} />
                    <Divider component='li' />
                  </React.Fragment>
                ))}
              </List>
            </GridApiContext.Provider>
          )}
        </>
      )}

      <Box
        component='div'
        sx={{
          visibility: isMobile ? 'hidden' : 'visible', // Hide DataGrid on mobile
          pointerEvents: isMobile ? 'none' : 'auto', // Disable interaction on mobile
          position: isMobile ? 'absolute' : 'relative', // Remove it from layout but keep it mounted
          height: isMobile ? 0 : 'auto',
        }}
      >
        <DataGridPremium
          apiRef={apiRef}
          density='comfortable'
          rows={liveryCases}
          columns={columns}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
            columns: {
              columnVisibilityModel: {
                status: false,
              },
            },
          }}
          slots={{
            noRowsOverlay: YourLiveryDataGridNoRowsOverlay,
            noResultsOverlay: YourLiveryDataGridNoRowsOverlay,
          }}
          slotProps={{
            row: {
              // @ts-ignore
              refetchCases,
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          disableDensitySelector={true}
          disableColumnSelector={true}
          disableMultipleRowSelection={true}
          disableColumnMenu={true}
          hideFooter={true}
          hideFooterRowCount={true}
          autoHeight={true}
          rowSelection={false}
          editMode='row'
          disableColumnResize={true}
          disableColumnReorder={true}
          loading={loading}
          getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          getDetailPanelContent={params => getDetailPanelContent({ params })}
          getDetailPanelHeight={() => 'auto'}
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          onCellDoubleClick={params => {
            const currentExpandedRowIds = expandedRowIds || [];
            const isExpanded = currentExpandedRowIds.includes(params.id);
            setExpandedRowIds(
              isExpanded
                ? currentExpandedRowIds.filter(id => id !== params.id)
                : [...currentExpandedRowIds, params.id]
            );
          }}
          getRowId={row => row.caseSeq}
          sx={{
            border: 'none',
            '& .MuiDataGrid-main': {
              border: 'none',
              overflow: 'hidden',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              borderTop: 'none',
              overflow: 'hidden',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
            },
            '& .MuiDataGrid-cell': {
              overflow: 'hidden',
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .even': {
              backgroundColor: theme =>
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'rgba(255, 255, 255, 0.04)',
            },
            '& .odd': {
              backgroundColor: theme =>
                theme.palette.mode === 'light' ? 'white' : theme.palette.background.default,
            },

            // '& .MuiDataGrid-row': {
            //   '&:nth-of-type(even)': {
            //     backgroundColor: theme =>
            //       theme.palette.mode === 'light'
            //         ? 'rgba(0, 0, 0, 0.04)'
            //         : 'rgba(255, 255, 255, 0.04)',
            //     '& .MuiCollapse-root': {
            //       backgroundColor: 'inherit',
            //     },
            //   },
            //   '&:nth-of-type(odd)': {
            //     backgroundColor: theme =>
            //       theme.palette.mode === 'light' ? 'white' : theme.palette.background.default,
            //     '& .MuiCollapse-root': {
            //       backgroundColor: 'inherit',
            //     },
            //   },
            // },
          }}
        />
      </Box>
    </Box>
  );
}

const columns: GridColDef<LiveryCaseWithStatusOptions>[] = [
  { field: 'caseID', headerName: 'CaseID', sortable: false, editable: false },
  {
    field: 'pickupAddressSeq',
    headerName: 'Pickup',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 1,
    renderCell: params => <AddressCell address={params.row.address} />,
  },
  // {
  //   field: 'deliveryAddressSeq',
  //   headerName: 'Delivery',
  //   align: 'left',
  //   sortable: false,
  //   editable: false,
  //   flex: 1,
  //   renderCell: params => <AddressCell addressSeq={params.row.deliveryAddressSeq} />,
  // },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 0.5,
  },
  {
    field: 'decedentFullName',
    headerName: 'Decedent',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 0.5,
  },
  {
    field: 'setAt',
    headerName: 'Date Assigned',
    type: 'string',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 0.5,
    valueGetter: (_, row) => {
      const activityLog = row.activityLog ?? [];
      const assignedActivity =
        activityLog.find(a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED) ?? null;
      if (assignedActivity) {
        const localDate = assignedActivity.setAt
          ? dayjs(assignedActivity.setAt).format('YYYY-MM-DDTHH:mm:ss')
          : 'Unknown';

        return localDate;
      }
      return 'Unknown';
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 3,
    renderCell: params => (
      <>
        {params.row.status === 'assigned' && <AssignedActions {...params} />}
        {params.row.status === 'accepted' && <YourLiveryDataGridAcceptedActions {...params} />}
      </>
    ),
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: params => <CustomDetailPanelToggle id={params.id} value={params.value} />,
  },
];

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size='small'
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <Tooltip arrow title={isExpanded ? 'Less information' : 'More information'}>
        <ExpandMoreRounded
          sx={{
            overflow: 'hidden',
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme: {
              transitions: {
                create: (arg0: string, arg1: { duration: any }) => any;
                duration: { shortest: any };
              };
            }) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize='inherit'
        />
      </Tooltip>
    </IconButton>
  );
}

function AssignedActions(
  params: GridRenderCellParams<LiveryCaseWithStatusOptions, any, any, GridTreeNodeWithRender>
) {
  const auth = useAuth();
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openDeferDialog, setOpenDeferDialog] = useState(false);

  const handleAcceptClick = () => {
    setOpenAcceptDialog(true);
  };
  const handleDeferClick = () => {
    setOpenDeferDialog(true);
  };

  const handleAcceptConfirm = async () => {
    try {
      await LiveryAPI.updateLiveryStatusForCase({
        accessToken: auth.user?.accessToken!,
        caseSeq: params.row.caseSeq,
        statusSeq: LegacyLiveryStatus.ACCEPTED,
        userSeq: auth.user?.userSeq!,
      });
    } catch {
    } finally {
      setOpenAcceptDialog(false);
      params.row.refetchCases();
    }
  };

  const handleDeferConfirm = async () => {
    try {
      await LiveryAPI.updateLiveryStatusForCase({
        accessToken: auth.user?.accessToken!,
        caseSeq: params.row.caseSeq,
        statusSeq: LegacyLiveryStatus.DEFERRED,
        userSeq: auth.user?.userSeq!,
      });
    } catch {
    } finally {
      setOpenDeferDialog(false);
      params.row.refetchCases();
    }
  };

  return (
    <>
      <Stack direction='row' spacing={1}>
        <Button variant='contained' size='small' sx={{ mr: 1 }} onClick={handleAcceptClick}>
          Accept
        </Button>
        <Button variant='text' color='error' size='small' onClick={handleDeferClick}>
          Defer
        </Button>
      </Stack>

      <Dialog open={openAcceptDialog} onClose={() => setOpenAcceptDialog(false)}>
        <DialogTitle>Accept Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to accept this case?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAcceptDialog(false)}>Cancel</Button>
          <Button onClick={handleAcceptConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeferDialog} onClose={() => setOpenDeferDialog(false)}>
        <DialogTitle>Defer Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to defer this case?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeferDialog(false)}>Cancel</Button>
          <Button onClick={handleDeferConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function createMockGridParams(
  liveryCase: LiveryCaseWithStatusOptions,
  refetchCases: () => Promise<void>
): GridRenderCellParams<LiveryCaseWithStatusOptions, any, any, GridTreeNodeWithRender> {
  return {
    id: liveryCase.caseSeq,
    field: '',
    row: liveryCase,
    value: null,
    api: {
      getCellMode: () => 'view',
      setCellMode: () => {},
      commitCellChange: () => Promise.resolve(),
      updateRows: () => {},
    } as any,
    cellMode: 'view',
    colDef: {} as any,
    rowNode: {
      id: liveryCase.caseSeq,
      parent: null,
      depth: 0,
      position: 0,
    } as any,
    formattedValue: null,
    hasFocus: false,
    isEditable: false,
    tabIndex: 0,
  };
}
