import React from 'react';
import { AssignedDailyExamsDataGrid } from '../../components/DashboardDataGrids/AssignedDailyExamsDataGrid';
import { AssignedToLiveryDataGrid } from '../../components/DashboardDataGrids/AssignedToLiveryDataGrid';
import { AwaitingIDConfirmationDataGrid } from '../../components/DashboardDataGrids/AwaitingIDConfirmationDataGrid';
import { OpenFacilitatedExamsDataGrid } from '../../components/DashboardDataGrids/OpenFacilitatedExamsDataGrid';
import { InStorageDataGrid } from '../../components/DashboardDataGrids/InStorageDataGrid';
import { OpenIdentificationsDataGrid } from '../../components/DashboardDataGrids/OpenIdentificationsDataGrid';
import { OpenCasesDataGrid } from '../../components/DashboardDataGrids/OpenCasesDataGrid';
import { OpenXrayRequestsDataGrid } from '../../components/DashboardDataGrids/OpenXrayRequestsDataGrid';
import { ReadyForCheckoutDataGrid } from '../../components/DashboardDataGrids/ReadyForCheckoutDataGrid';
import { ReleaseDataGrid } from '../../components/DashboardDataGrids/ReleaseDataGrid';
import { TransferredCasesDataGrid } from '../../components/DashboardDataGrids/TransferredCasesDataGrid';
import { UnidentifiedCasesDataGrid } from '../../components/DashboardDataGrids/UnidentifiedCasesDataGrid';
import { AvailableFileRequestsDataGrid } from '../../components/DashboardDataGrids/AvailableFileRequest';
import { OpenRecordReviewsDataGrid } from '../../components/DashboardDataGrids/OpenRecordReviewsDataGrid';
import { OverduePathologyReportsDataGrid } from '../../components/DashboardDataGrids/OverduePathologyReportsDataGrid';
import { IncidentGroupsDataGrid } from '../../components/DashboardDataGrids/IncidentGroupsDataGrid';

export const DASHBOARD_SILO_SEQS = {
  TODAYS_EXAMS: 'a070f979-8812-41d0-a031-6e9c83163758',
  READY_FOR_CHECKOUT: '1ca75145-59d2-4ab4-a2f5-009cc4f12423', // in db
  OPEN_CASES: '1e8931e1-cf17-4c38-a612-b449ace64cd3',
  TRANSFERRED_CASES: '409997bb-74f4-42c1-8dd7-cce2052a20a8', // in db
  ASSIGNED_TO_LIVERY: '04fe8cb3-ef21-44d3-badf-6cb3a6e490b4', // in db
  OPEN_FACILITATED_EXAMS: '8f5c9dc6-7a5b-4209-82b6-82e867aaad30', // in db
  IN_STORAGE: 'ef453ca3-ec0b-4c63-a0fa-ae53c4f0f747', // in db
  RELEASE: '8ee0ce58-62aa-4a5b-bc12-ab6bd08a72a2',
  OPEN_IDENTIFICATIONS: '7441ea7e-16a0-4c49-bb76-9c6da5b683da',
  AWAITING_ID_CONFIRMATION: 'bbb0a955-692c-44c9-90bc-3fac1288ab3d',
  UNIDENTIFIED: '9d9ec7ab-a65b-430c-b08d-b3951f3a3174',
  OPEN_XRAY_REQUESTS: 'b9f3eaa3-a0e0-4f5b-95f1-21f5ba019d25',
  AVAILABLE_FILE_REQUESTS: '41be4c66-f358-460d-b09a-f89ef8e90eb4',
  OPEN_RECORD_REVIEWS: '3a150c9e-23a9-479c-8866-841d6932c349',
  OVERDUE_PATHOLOGY_REPORTS: 'd5bb918c-dadd-4c7a-b80a-c70cee2fbe70',
  MASS_CASUALTY_INCIDENTS: '6863c2b7-35d4-446a-ac49-4f57a5019f5c',
} as const;

export const dashboardSiloSeqsMap = new Map<keyof typeof DASHBOARD_SILO_SEQS, string>();

Object.entries(DASHBOARD_SILO_SEQS).forEach(([key, value]) => {
  dashboardSiloSeqsMap.set(key as keyof typeof DASHBOARD_SILO_SEQS, value);
});

export interface DashboardSilo {
  dashboardSiloName: string;
  dashboardSiloSeq: string;
  endpoint: string;
  Component: React.FC<any>;
  isLoading?: boolean;
  data?: any;
  defaultSize: number;
  id: string;
  layoutOrder: number;
}

export const defaultSiloMap: DashboardSilo[] = [
  {
    dashboardSiloName: `Today's Exams`,
    endpoint: 'getAssignedDailyExams',
    Component: React.memo(AssignedDailyExamsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.TODAYS_EXAMS,
    id: DASHBOARD_SILO_SEQS.TODAYS_EXAMS,
    layoutOrder: -1,
  },
  {
    dashboardSiloName: 'Ready For Checkout',
    endpoint: 'getReadyForCheckout',
    Component: React.memo(ReadyForCheckoutDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.READY_FOR_CHECKOUT,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.READY_FOR_CHECKOUT,
  },
  {
    dashboardSiloName: 'Open Cases',
    endpoint: 'GetOpenCases',
    Component: React.memo(OpenCasesDataGrid),
    defaultSize: 12,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.OPEN_CASES,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.OPEN_CASES,
  },
  {
    dashboardSiloName: 'Transferred Cases',
    endpoint: 'getTransferredCases',
    Component: React.memo(TransferredCasesDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.TRANSFERRED_CASES,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.TRANSFERRED_CASES,
  },
  {
    dashboardSiloName: 'Assigned To Livery',
    endpoint: 'getAssignedtoLivery',
    Component: React.memo(AssignedToLiveryDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.ASSIGNED_TO_LIVERY,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.ASSIGNED_TO_LIVERY,
  },
  {
    dashboardSiloName: 'Open Facilitated Exams',
    endpoint: 'getOpenFacilitatedExams',
    Component: React.memo(OpenFacilitatedExamsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.OPEN_FACILITATED_EXAMS,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.OPEN_FACILITATED_EXAMS,
  },
  {
    dashboardSiloName: 'In House',
    endpoint: 'getInHouseCases',
    Component: React.memo(InStorageDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.IN_STORAGE,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.IN_STORAGE,
  },
  {
    dashboardSiloName: 'Release',
    endpoint: 'getReleases',
    Component: React.memo(ReleaseDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.RELEASE,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.RELEASE,
  },
  {
    dashboardSiloName: 'Open Identifications',
    endpoint: 'getOpenIdentifications',
    Component: React.memo(OpenIdentificationsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.OPEN_IDENTIFICATIONS,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.OPEN_IDENTIFICATIONS,
  },
  {
    dashboardSiloName: 'Awaiting ID Confirmation',
    endpoint: 'getAwaitingIDConfirmation',
    Component: React.memo(AwaitingIDConfirmationDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.AWAITING_ID_CONFIRMATION,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.AWAITING_ID_CONFIRMATION,
  },
  {
    dashboardSiloName: 'Unidentified',
    endpoint: 'getunidentifiedcases',
    Component: React.memo(UnidentifiedCasesDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.UNIDENTIFIED,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.UNIDENTIFIED,
  },
  {
    dashboardSiloName: 'Open X-Ray Requests',
    endpoint: 'GetOpenXrayRequests',
    Component: React.memo(OpenXrayRequestsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.OPEN_XRAY_REQUESTS,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.OPEN_XRAY_REQUESTS,
  },
  {
    dashboardSiloName: 'Available File Requests',
    endpoint: 'GetAvailableFileRequests',
    Component: React.memo(AvailableFileRequestsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.AVAILABLE_FILE_REQUESTS,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.AVAILABLE_FILE_REQUESTS,
  },
  {
    dashboardSiloName: 'Open Record Reviews',
    endpoint: 'GetOpenRecordReviews',
    Component: React.memo(OpenRecordReviewsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.OPEN_RECORD_REVIEWS,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.OPEN_RECORD_REVIEWS,
  },
  {
    dashboardSiloName: 'Overdue Pathology Reports',
    endpoint: 'GetOverduePathologyReports',
    Component: React.memo(OverduePathologyReportsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.OVERDUE_PATHOLOGY_REPORTS,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.OVERDUE_PATHOLOGY_REPORTS,
  },
  {
    dashboardSiloName: 'Mass Casualty Incidents',
    endpoint: 'GetIncidentGroups',
    Component: React.memo(IncidentGroupsDataGrid),
    defaultSize: 6,
    dashboardSiloSeq: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
    layoutOrder: -1,
    id: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
  },
];

export interface DashboardSiloLayout {
  dashboardSiloSeq: string;
  dashboardSiloName: string;
  layoutOrder: number;
}

export function getCurrentDashboardLayout(currentSilos: DashboardSilo[]): DashboardSiloLayout[] {
  const layout: DashboardSiloLayout[] = [];

  currentSilos.forEach((silo, i) => {
    layout.push({
      dashboardSiloName: silo.dashboardSiloName,
      dashboardSiloSeq: silo.dashboardSiloSeq,
      layoutOrder: i + 1,
    });
  });

  return layout;
}
