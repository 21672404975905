import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import '../../App.css';
import { useAuth } from '../../utils/auth/AuthService';
import ReportsDrawer from '../../utils/components/ReportsDrawer';
import dimensions from '../../utils/dimensions';
import AdminReportsView from './AdminReportsView';
import BusinessReportsView from './BusinessReportsView';
import GeneralReportsView from './GeneralReportsView';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const drawerWidth = 240;

export default function ReportsHome() {
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menu2Open, setMenu2Open] = useState(false);
  const [drawerContents, setDrawerContents] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const auth = useAuth();

  const handleMenu2Click = () => {
    setMenu2Open(!menu2Open);
  };

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  const getTabIndex = tabName => {
    return drawerContents.findIndex(obj => obj.text == tabName);
  };

  useEffect(() => {
    var tempDrawerContents = [];

    if (auth.user?.roleCheck('GENERAL-REPORTS')) {
      tempDrawerContents.push({ text: 'General Analytics', icon: <ManageAccountsIcon /> });
    }
    if (auth.user?.roleCheck('SUPERVISOR-REPORTS')) {
      tempDrawerContents.push({ text: 'Admin Reports', icon: <SettingsIcon /> });
    }
    if (auth.user?.roleCheck('FINANCIAL-REPORTS')) {
      tempDrawerContents.push({ text: 'Business Analytics', icon: <PointOfSaleIcon /> });
    }

    setDrawerContents(tempDrawerContents);
  }, []);

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      {/* Mobile and Desktop Drawer*/}
      <ReportsDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      {/* Page Contents */}
      <Box
        sx={{
          width: '100%',
          maxWidth: isMobile ? '100%' : `calc(100% - ${dimensions.new_drawer_width})`,
        }}
      >
      

        {auth.user?.roleCheck('GENERAL-REPORTS') && (
          <TabPanel
            style={{ width: '100%', alignItems: 'center' }}
            value={currentTab}
            index={getTabIndex('General Analytics')}
          >
            <Card>
              <CardHeader
                title='General Analytical Reports & Extracts'
                action={
                  <IconButton
                    sx={{
                      ml: 'auto',
                      display: { xs: 'block', md: 'none' },
                    }}
                    onClick={handleMenuClick}
                  >
                    <MenuIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <GeneralReportsView />
              </CardContent>
            </Card>
          </TabPanel>
        )}
        {auth.user?.roleCheck('SUPERVISOR-REPORTS') && (
          <TabPanel
            style={{ width: '100%', alignItems: 'center' }}
            value={currentTab}
            index={getTabIndex('Admin Reports')}
          >
            <Card>
              <CardHeader
                title='Administrative & Staff Performance Reports'
                action={
                  <IconButton
                    sx={{
                      color: '#fff',
                      ml: 'auto',
                      display: { xs: 'block', md: 'none' },
                    }}
                    onClick={handleMenuClick}
                  >
                    <MenuIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <AdminReportsView />
              </CardContent>
            </Card>
          </TabPanel>
        )}
        {auth.user?.roleCheck('FINANCIAL-REPORTS') && (
          <TabPanel
            style={{ width: '100%', alignItems: 'center' }}
            value={currentTab}
            index={getTabIndex('Business Analytics')}
          >
            <Card>
              <CardHeader
                title='Business Analytics'
                action={
                  <IconButton
                    sx={{
                      color: '#fff',
                      ml: 'auto',
                      display: { xs: 'block', md: 'none' },
                    }}
                    onClick={handleMenuClick}
                  >
                    <MenuIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <BusinessReportsView />
              </CardContent>
            </Card>
          </TabPanel>
        )}
      </Box>
    </Box>
  );
}
