import { differenceInDays, format, formatDistanceToNow, parseISO } from 'date-fns';
import { Jurisdiction } from '../../../../../types/Jurisdiction.interface';
import { User } from '../../../../../types/User';

export interface ConversationType {
  conversationTypeSeq: string;
  typeName: string;
  description?: string;
}

export interface Conversation {
  conversationSeq: string;
  conversationType: ConversationType;
  createdByUserSeq: string;
  createdAt: string;
  topic: string;
  messages: ConversationMessage[];
  participants: ConversationParticipant[];
  unreadCount: number;
  messageCount: number;
  lastMessageSeq: string | null;
  isOpen: boolean;
  isActive: boolean;
}

export interface Attachment {
  attachmentId: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  url?: string;
  previewUrl?: string;
}

export interface ConversationMessage {
  messageSeq: string;
  senderUserSeq: string;
  senderName: string;
  content: string;
  sentAt: string;
  readByCount: number;
  readBy: Array<{
    userSeq: string;
    userName: string;
    personFirstName: string;
    personLastName: string;
    readTimestamp: string;
  }>;
  attachments?: Attachment[];
}
export interface ConversationParticipant {
  userSeq: string;
  userName: string;
  personFirstName: string;
  personLastName: string;
  joinedAt: string;
}

export const ConversationTypes = {
  Feedback: 'c39113b3-8fd0-47c4-bb9b-299f86e3fc94',
  New_Account_Request: 'fa7d4037-2a3f-49c9-9c04-392306c80b8e',
  Error_Report: '3af6f76f-0f7b-4560-81c9-5b96e5cf3a10',
  Chat: '80925c64-7809-494d-88ed-be2a31c50a12',
};

export const shouldMaskNameAsSupportAgent = (
  m: Partial<ChatMessage>,
  conversationTypeSeq: string,
  user: User
) => {
  const isSender = m?.sender?.userSeq?.toLowerCase() === user.userSeq.toLowerCase();
  // const isUserSystemAdmin = user.roleCheck(['SYSTEMS-ADMIN']);
  // const isNormalChat = conversationTypeSeq.toLowerCase() === ConversationTypes.Chat.toLowerCase();

  // if (isUserSystemAdmin) return false;
  // if (isNormalChat) return false;
  // if (isSender) return false;

  return (
    !user.roleCheck(['451', '8f7']) &&
    [
      ConversationTypes.Feedback,
      ConversationTypes.Error_Report,
      ConversationTypes.New_Account_Request,
    ].includes(conversationTypeSeq) &&
    !isSender
  );
};

export interface ChatRecord {
  conversationSeq: string;
  type: Partial<ConversationType>;
  creator: Partial<ChatParticipant>;
  createdAtTimestamp: string;
  topic?: string;
  participants: Partial<ChatParticipant>[];
  participantHistory: Partial<ChatParticipantHistory>[];
  messages: Partial<ChatMessage>[];
  lastMessageSeq?: string;
  messageCount: number;
  isOpen?: boolean;
  isActive: boolean;
}

export interface ConversationType {
  conversationTypeName: string;
  conversationTypeSeq: string;
  description?: string;
}

export interface ChatParticipant {
  userSeq: string;
  userName: string;
  personFirstName: string;
  personLastName: string;
  joinedAtTimestamp?: string;
  jurisdictions?: Jurisdiction[];
  phoneNumber?: string;
  emailAddress?: string;
  isActive?: boolean;
}

export interface ChatMessage {
  messageSeq: string;
  sentAtTimestamp: string;
  sender: Partial<ChatParticipant>;
  readBy: Partial<ChatMessageReadBy>[];
  readByCount: number;
  messageContent?: string;
  attachments: Partial<ChatMessageFileAttachment>[] | File[];
}

export interface ChatMessageReadBy extends ChatParticipant {
  readAtTimestamp?: string;
}

export interface ChatParticipantHistory extends ChatParticipant {
  chatParticipantHistorySeq: string;
  conversationSeq: string;
  participantCanViewMessageHistoryUpToTimestamp?: string;
  addedBy?: Partial<ChatParticipant>;
  removedBy?: Partial<ChatParticipant>;
  timestamp?: string;
  silent: boolean;
}

export interface CreateChatRequestMetadata {
  caseId?: string;
  pathname?: string;
}

export interface ChatMessageFileAttachment {
  attachmentId: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  url: string;
}

export interface SendChatMessageRequest {
  conversationSeq: string;
  chatMessage: Partial<ChatMessage>;
}

export interface ChatNotificationItem {
  id: string;
  message: string;
  messageRecord: ChatMessage | null;
  conversationSeq: string;
  createdAt: number;
  sender: Partial<ConversationParticipant>;
  isPaused: boolean;
}

export interface OnOtherUserAddedToChatEvent {
  conversationSeq: string;
  participants: ChatParticipant[];
  participantHistory: ChatParticipantHistory[];
}

export interface OnSelfAddedToChatEvent extends ChatRecord {}

export interface OnOtherUserRemovedFromChatEvent {
  conversationSeq: string;
  participant: ChatParticipant;
  participantHistory: ChatParticipantHistory[];
}

export interface OnSelfRemovedFromChatEvent {
  conversationSeq: string;
}

export const formatMessageTime = (dateStr: string) => {
  const date = parseISO(dateStr);
  const daysDiff = differenceInDays(new Date(), date);

  // Add check for messages within the last minute
  const diffInSeconds = (new Date().getTime() - date.getTime()) / 1000;
  if (diffInSeconds < 60) {
    return 'now';
  }

  if (daysDiff >= 7) {
    return format(date, 'dd/MM/yyyy hh:mm a');
  }

  const formatted = formatDistanceToNow(date, { addSuffix: true })
    .replace(' seconds', 's')
    .replace(' second', 's')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace('about ', '')
    .replace('less than ', '<')
    .replace('almost ', '~');

  return formatted;
};
