import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Environment, Grid, OrbitControls, PerspectiveCamera, Resize } from '@react-three/drei';
import { Canvas, dispose, extend } from '@react-three/fiber';
import { motion } from 'framer-motion';
import { motion as motion3d } from 'framer-motion-3d';
import { DBSchema, openDB } from 'idb';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import * as THREE from 'three';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useAuth } from '../../../../utils/auth/AuthService';

// @ts-ignore

const AnimatedStack = motion(Stack);
const AnimatedBox = motion(Box);

Object.entries(THREE).forEach(([name, value]) => {
  extend({ [name]: value });
});

extend({
  Grid: Grid,
});

interface ModelDB extends DBSchema {
  models: {
    key: string;
    value: string;
  };
}

const { VITE_API_URL } = import.meta.env;

export function GLTFModel({ modelSeq, modelUrl }: { modelSeq: string; modelUrl: string }) {
  const auth = useAuth();
  const [gltf, setGltf] = useState<GLTF | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Loading model, please wait...');
  const [indicator, setIndicator] = useState<'determinate' | 'indeterminate' | undefined>(
    'determinate'
  );
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<any>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const [studio, setStudio] = useState<any>(null);

  useEffect(() => {
    // @ts-ignore
    import('@pmndrs/assets/hdri/studio.exr').then(module => {
      setStudio(module.default);
    });
  }, []);

  const fetchAndLoadGltf = useCallback(async () => {
    const url = `${VITE_API_URL}getmodeldata?modelSeq=${modelSeq}`;
    try {
      setLoading(true);
      setError(null);

      const db = await openDB<ModelDB>('ModelDB', 1, {
        upgrade(db) {
          db.createObjectStore('models');
        },
      });

      // const cachedModel = (await db.get('models', modelSeq)) ?? null;
      const cachedModel = null;

      if (cachedModel) {
        setIndicator('indeterminate');
        setLoadingText('Loading model from cache...');
        const loader = new GLTFLoader();
        const gltf = await loader.parseAsync(cachedModel, '');
        setGltf(gltf);
      } else {
        setIndicator('indeterminate');
        setLoadingText('Downloading model from server, please wait...');
        const loader = new GLTFLoader();
        // loader.setRequestHeader({
        //   Authorization: 'Bearer ' + auth.user?.token,
        // });

        const gltf = await loader.loadAsync(modelUrl, progress => {
          if (indicator === 'indeterminate') {
            setIndicator('determinate');
          }
          const percentComplete = (progress.loaded / progress.total) * 100;
          setProgress(percentComplete);
        });
        await db.put('models', JSON.stringify(gltf.parser.json), modelSeq);
        setGltf(gltf);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAndLoadGltf();

    return () => {
      if (gltf) {
        setGltf(null);
        dispose(gltf);
      }
    };
  }, []);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense
        fallback={
          <AnimatedStack
            direction='row'
            spacing={2}
            justifyContent='center'
            alignItems='center'
            p={2}
          >
            <AnimatedBox component='div' position='relative' display='inline-flex'>
              <CircularProgress />
            </AnimatedBox>

            <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              Finishing up, please wait...
            </motion.p>
          </AnimatedStack>
        }
      >
        {!loading && studio ? (
          <Canvas key={modelSeq} frameloop='always' ref={canvasRef}>
            <Resize height width depth>
              <OrbitControls
                enableDamping={true}
                enablePan={false}
                enableZoom={true}
                enableRotate={true}
              />

              <PerspectiveCamera ref={cameraRef} makeDefault />
              <Environment
                files={studio}
                near={1}
                far={1000}
                resolution={1024}
                background={false}
                blur={0.5}
              />

              {gltf && <Model gltf={gltf} cameraRef={cameraRef} />}
            </Resize>
          </Canvas>
        ) : (
          <AnimatedStack
            direction='row'
            spacing={2}
            justifyContent='center'
            alignItems='center'
            p={2}
          >
            <AnimatedBox component='div' position='relative' display='inline-flex'>
              <CircularProgress variant={indicator} value={progress} />
              <AnimatedBox
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography variant='caption' component='div' color='textSecondary'>
                  {indicator === 'determinate' && `${Math.round(progress)}%`}
                </Typography>
              </AnimatedBox>
            </AnimatedBox>

            <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              {loadingText}
            </motion.p>
          </AnimatedStack>
        )}
      </Suspense>
    </ErrorBoundary>
  );
}

function Model({
  gltf,
  cameraRef,
}: {
  gltf: GLTF;
  cameraRef: React.RefObject<THREE.PerspectiveCamera>;
}) {
  const modelRef = useRef<THREE.Group | null>(null);

  useEffect(() => {
    if (modelRef.current && cameraRef.current) {
      const box = new THREE.Box3().setFromObject(modelRef.current);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());

      modelRef.current.position.set(-center.x, -center.y, -center.z);

      const fov = cameraRef.current.fov * (Math.PI / 180);
      const distance = Math.max(size.x, size.y, size.z) / (2 * Math.tan(fov / 2));
      const offset = distance * 0.4;
      cameraRef.current.position.z = distance + offset;
      cameraRef.current.updateProjectionMatrix();
    }
  }, [cameraRef]);

  return (
    <motion3d.primitive
      // @ts-ignore
      ref={modelRef}
      object={gltf.scene}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
    />
  );
}

function ErrorFallback({ error }: { error: Error }) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
}
