import * as url from '../URL_HELPERS';

const userString = localStorage.getItem('user');
const user = userString ? JSON.parse(userString) : null;

const { VITE_API_URL } = import.meta.env;

export async function updateDemographicsApi(payload: any) {
  return fetch(VITE_API_URL + url.UPDATE_Demographics, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + user.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => {
      if (res.status == 401) {
        throw new Error('You unauthorized to use this tool');
      } else if (res.status >= 400) {
        throw new Error('An error occured');
      }
      return res.json();
    })
    .catch((e) => {
      console.log(e);
    });
}
