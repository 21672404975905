import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { MedexResponse, ResponseStatus } from '../../../types/MedexResponse.type';
import { AuthAction } from '../reducers/authReducer';
import { UserAction, UserState } from '../reducers/userReducer';

const { VITE_API_URL } = import.meta.env;

export const useFetchWithRevalidation = (
  userState: UserState,
  userDispatch: React.Dispatch<UserAction>,
  authDispatch: React.Dispatch<AuthAction>
) => {
  const navigate = useNavigate();

  const isRefreshing = useRef(false);
  const refreshFailed = useRef(false);

  const requestQueue: Array<{
    input: RequestInfo;
    init?: RequestInit;
    resolve: (value: Response | PromiseLike<Response>) => void;
    reject: (reason?: any) => void;
  }> = [];

  const processQueue = (newAccessToken: string | null) => {
    if (!newAccessToken) {
      // Reject all queued requests if no new access token is available
      requestQueue.forEach(({ reject }) => reject(new Error('Failed to refresh access token')));
    } else {
      // Resolve all queued requests with the new access token
      requestQueue.forEach(({ input, init, resolve }) => {
        const authInit = {
          ...init,
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${newAccessToken}`,
          },
        };
        resolve(window.fetch(input, authInit));
      });
    }
    requestQueue.length = 0; // Clear the queue after processing
  };

  const revalidateToken = async () => {
    if (!userState.user?.refreshToken || isRefreshing.current || refreshFailed.current) return;

    isRefreshing.current = true;

    try {
      const endpoint = `${VITE_API_URL}user/login/revalidate`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          access_token: userState.user.accessToken,
          refresh_token: userState.user.refreshToken,
        }),
      });

      const { status, data }: MedexResponse = await response.json();

      if (status === ResponseStatus.SUCCESS) {
        const new_refresh_token = data.refresh_token;
        const new_access_token = data.access_token;

        // Update tokens in state
        userDispatch({ type: 'SET_REFRESH_TOKEN', payload: new_refresh_token });
        userDispatch({ type: 'SET_ACCESS_TOKEN', payload: new_access_token });

        // Process the queued requests with the new token
        processQueue(new_access_token);
      } else {
        refreshFailed.current = true; // Mark that refresh has failed
        processQueue(null);
        authDispatch({ type: 'RESET_AUTH' });
        userDispatch({ type: 'CLEAR_USER' });
        navigate('/login');
      }
    } catch (error) {
      refreshFailed.current = true; // Mark that refresh has failed
      processQueue(null);
    } finally {
      isRefreshing.current = false; // Reset the refreshing state
    }
  };

  useEffect(() => {
    const originalFetch = window.fetch;

    window.fetch = async (input: RequestInfo, init?: RequestInit) => {
      //   if (refreshFailed.current) {
      //     // Immediately navigate to login if refresh has failed before
      //     navigate('/login', { replace: true });
      //     return Promise.reject(new Error('Token refresh failed'));
      //   }

      const response = await originalFetch(input, init);

      if (response.status === 401) {
        return new Promise<Response>((resolve, reject) => {
          requestQueue.push({ input, init, resolve, reject });
          revalidateToken(); // Trigger the revalidation process
        });
      }

      return response;
    };

    return () => {
      window.fetch = originalFetch;
    };
  }, [userState.user?.accessToken, userState.user?.refreshToken]);

  return { isRefreshing: isRefreshing.current };
};
