import { TextFields } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';

import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  TableBubbleMenu,
  type RichTextEditorRef,
} from 'mui-tiptap';
import EditorMenuControls from '../../Home/EditorMenuControls';
import useExtensions from '../../Home/useExtensions';

export default function BulkMessageView() {
  const { user } = useAuth();
  const [userProfile, setuserProfile] = useState([]);
  const [allRecipients, setAllRecipients] = useState([]);
  const [sendToAll, setSendToAll] = useState(false);
  const [messageRecipients, setMessageRecipients] = useState<any[]>([]);
  const [messageSubject, setMessageSubject] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [messageSentAlert, setMessageSentAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);

  const { VITE_API_URL } = import.meta.env;

  const handleRecipientsChange = (
    event: React.SyntheticEvent<Element, Event>,
    data: ((prevState: never[]) => never[]) | (string | never[])[]
  ) => {
    // @ts-ignore
    setMessageRecipients(data);
  };

  const handleSendToAllChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setSendToAll(event.target.checked);

    if (event.target.checked) {
      setMessageRecipients(allRecipients);
    } else {
      setMessageRecipients([]);
    }
  };

  const handleSubjectChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setMessageSubject(event.target.value);
  };

  const handleBodyChange = (content: string) => {
    setMessageBody(content);
  };

  const handleSendEmail = async () => {
    if (!messageBody.trim() || !messageSubject.trim() || messageRecipients.length === 0) {
      setErrorMessage('Please fill in all required fields');
      setErrorAlert(true);
      return;
    }

    const emailData = {
      Subject: messageSubject,
      Body: messageBody,
      RecipientEmails: messageRecipients.map(recipient => recipient?.primaryEmail),
    };

    try {
      setSendingEmail(true);
      const response = await fetch(VITE_API_URL + 'sendemail', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user?.accessToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (!response.ok) {
        throw new Error('An error occurred');
      }

      const result = await response.json();

      if (result.FailedSends > 0) {
        setErrorMessage(
          `${result.FailedSends} out of ${result.TotalRecipients} emails failed to send.`
        );
        setErrorAlert(true);
      } else {
        setMessageSentAlert(true);
        // Reset form
        setMessageBody('');
        setMessageSubject('');
        setMessageRecipients([]);
        setSendToAll(false);
      }
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e?.message as unknown as any);
        setErrorAlert(true);
      }
    } finally {
      setSendingEmail(false);
    }
  };

  useEffect(() => {
    if (user === null) return;

    fetch(VITE_API_URL + 'user/getallactiveusers', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAllRecipients(data);
      })
      .catch(e => {
        console.error('Error fetching recipients:', e);
      });
  }, [user]);

  useEffect(() => {
    if (user === null) return;

    if (!userProfile.length) {
      let formData = new FormData();
      // @ts-ignore
      formData.append('userSeq', user !== null ? user.userSeq : null);
      fetch(VITE_API_URL + 'user/getuserprofile', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status === 401) {
            throw new Error('You are unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occurred');
          }
          return res.json();
        })
        .then(data => {
          setuserProfile(data);
        })
        .catch(e => {
          console.error('Error fetching user profile:', e);
        });
    }
  }, [user]);

  return (
    <div>
      <Stack>
        <Typography variant='h5' component='h1'>
          <b>Bulk Messaging</b>
        </Typography>
        <Typography variant='subtitle1' color='gray'>
          Send E-Mail or Text (SMS) to Users
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Autocomplete
            freeSolo
            multiple
            fullWidth
            id='recipients-select'
            limitTags={3}
            options={allRecipients}
            value={messageRecipients}
            getOptionLabel={option =>
              // @ts-ignore
              option.personLastName + ', ' + option.personFirstName + ` (${option?.primaryEmail})`
            }
            onChange={(event, data) => handleRecipientsChange(event, data)}
            renderInput={params => (
              <TextField
                {...params}
                variant='standard'
                label='Recipients'
                placeholder='Recipients'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            label='Select All Recipients'
            control={<Checkbox checked={sendToAll} onChange={handleSendToAllChange} />}
            style={{ marginTop: 10 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id='subject'
            label='Subject'
            value={messageSubject}
            onChange={handleSubjectChange}
            variant='filled'
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <SimpleRichTextEditor
            value={messageBody}
            onChange={handleBodyChange}
            placeholder='Type your message...'
            sx={{
              mb: 2,
              '& .ProseMirror': {
                minHeight: '200px',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            disabled={sendingEmail}
            color='primary'
            variant='contained'
            size='large'
            onClick={handleSendEmail}
          >
            Send Bulk Email
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={messageSentAlert}
        autoHideDuration={5000}
        onClose={() => setMessageSentAlert(false)}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert color='success' style={{ marginTop: 20 }}>
          Bulk email sent successfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorAlert}
        autoHideDuration={5000}
        onClose={() => setErrorAlert(false)}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert color='error' style={{ marginTop: 20 }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

interface RichTextEditorProps {
  value?: string;
  onChange?: (content: string) => void;
  placeholder?: string;
  sx?: any;
}
function SimpleRichTextEditor({
  value = '',
  onChange,
  placeholder = 'Type a message...',
  sx,
}: RichTextEditorProps) {
  const extensions = useExtensions({ placeholder });
  const rteRef = useRef<RichTextEditorRef>(null);
  const [showMenuBar, setShowMenuBar] = useState(true);

  const handleChange = useCallback(() => {
    const content = rteRef.current?.editor?.getHTML() || '';
    onChange?.(content);
  }, [onChange]);

  return (
    <Box
      component='div'
      sx={{
        '& .ProseMirror': {
          minHeight: '200px',
          '& h1, & h2, & h3, & h4, & h5, & h6': {
            scrollMarginTop: showMenuBar ? 50 : 0,
          },
        },
        ...sx,
      }}
    >
      <RichTextEditor
        ref={rteRef}
        extensions={extensions}
        content={value}
        onUpdate={handleChange}
        renderControls={() => <EditorMenuControls />}
        RichTextFieldProps={{
          variant: 'outlined',
          MenuBarProps: { hide: !showMenuBar },
          footer: (
            <Stack
              direction='row'
              spacing={2}
              sx={{
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                borderTopColor: theme => theme.palette.divider,
                py: 1,
                px: 1.5,
              }}
            >
              <MenuButton
                value='formatting'
                tooltipLabel={showMenuBar ? 'Hide formatting' : 'Show formatting'}
                size='small'
                onClick={() => setShowMenuBar(prev => !prev)}
                selected={showMenuBar}
                IconComponent={TextFields}
              />
            </Stack>
          ),
        }}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </RichTextEditor>
    </Box>
  );
}
