import InfoIcon from '@mui/icons-material/Info';
import authHeader from '../../../services/auth-header';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  Slide,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ViolentDeathView() {
  const { user } = useAuth();
  const [userReady, setUserReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [mannerOfDeathOptions, setMannerOfDeathOptions] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const [unnaturalDeathOptions, setUnnaturalDeathOptions] = useState([]);
  const [mannerOfDeath, setMannerOfDeath] = useState([]);
  const [drug, setDrug] = useState([]);
  const [unnaturalDeath, setUnnaturalDeath] = useState([]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [friendlyHeader, setFriendlyHeader] = useState(true);
  const [friendlyColumns, setFriendlyColumns] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      bgColor: 'black',
      width: 105,
      editable: false,
      renderCell: params => (
        <b onClick={event => handleCaseClick(event, params)}>{params.formattedValue}</b>
      ),
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'personFirstName',
      headerName: 'First Name',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'personLastName',
      headerName: 'Last Name',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'personMiddleName',
      headerName: 'Middle Name',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 80,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'ageUnitName',
      headerName: 'Age Unit',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'raceName',
      headerName: 'Race',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'genderName',
      headerName: 'Gender',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'maritalStatusName',
      headerName: 'Marital Status',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'ssn',
      headerName: 'SSN',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'occupationName',
      headerName: 'Occupation',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decedentAka',
      headerName: 'Also Known As',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
      width: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'pronouncementDateTime',
      headerName: 'Pronouncement Date/Time',
      width: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decAddressline',
      headerName: 'Deceased Address Line',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decCity',
      headerName: 'Deceased City',
      flex: 1,
      minWidth: 120,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decState',
      headerName: 'Deceased State',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decZip',
      headerName: 'Deceased ZIP',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseSynopsis',
      headerName: 'Case Synopsis',
      flex: 2,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobAddressline',
      headerName: 'LOB Address Line',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobAddressline2',
      headerName: 'LOB Address Line 2',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobCity',
      headerName: 'LOB City',
      flex: 1,
      minWidth: 120,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobZip',
      headerName: 'LOB ZIP',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseReporterTypeName',
      headerName: 'Case Reporter Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'hcfName',
      headerName: 'HCF Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetAddress',
      headerName: 'Onset Address',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetCityZip',
      headerName: 'Onset City ZIP',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetZip',
      headerName: 'Onset ZIP',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetAddressType',
      headerName: 'Onset Address Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetState',
      headerName: 'Onset State',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'causeOfDeath',
      headerName: 'Cause of Death',
      flex: 2,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'mannerOfDeathName',
      headerName: 'Manner of Death',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseMEFindingTypeName',
      headerName: 'Case ME Finding Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'assignedME',
      headerName: 'Assigned ME',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 2,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'examEndDateTime',
      headerName: 'Exam End Date/Time',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'udCategoryName',
      headerName: 'UD Category Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'passengerType',
      headerName: 'Passenger Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'transportationType',
      headerName: 'Transportation Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseStatus',
      headerName: 'Case Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'contributory',
      headerName: 'Contributory',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'isTeleMetryUsed',
      headerName: 'Telemetry Used',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'reasonForNoPronouncementName',
      headerName: 'Reason for No Pronouncement',
      flex: 2,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  const { VITE_API_URL } = import.meta.env;

  const handleJdxChange = (event, data) => setSelectedJdxs(data);
  const handleStartDateChange = event => setStartDate(event.target.value || '');
  const handleEndDateChange = event => setEndDate(event.target.value || '');
  const handleMannerOfDeathChange = (event, data) => setMannerOfDeath(data);
  const handleDrugChange = (event, data) => setDrug(data);
  const handleUnnaturalDeathChange = (event, data) => setUnnaturalDeath(data);

  const friendlyHeaderToggle = event => setFriendlyHeader(event.target.checked);

  const dismissDateAlert = () => setShowErrorAlert(false);

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setSelectedJdxs([]);
    setMannerOfDeath([]);
    setDrug([]);
    setUnnaturalDeath([]);
    setResultsVisible(false);
  };

  const handleSelectAndDeselectAllJurisdictions = () => {
    if (selectedJdxs == authorizedJdxs) {
      setSelectedJdxs([]);
    } else {
      setSelectedJdxs(authorizedJdxs);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const errors = {};

    if (!startDate || !endDate) {
      errors.date = 'Call taken range must be within 1 year.';
    }

    if (selectedJdxs.length === 0) {
      errors.jurisdiction = 'Please select at least one jurisdiction.';
    }

    if (mannerOfDeath.length === 0) {
      errors.mannerOfDeath = 'Please select at least one Manner of Death.';
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      setShowErrorAlert(true);
      return;
    }

    setLoading(true);
    setResultsVisible(true);

    const inputData = {
      jurisdiction: selectedJdxs.map(i => i.jdxSeq).toString(),
      mannerofdeath: mannerOfDeath.map(i => i.optionSeq).toString(),
      startdate: startDate,
      enddate: endDate,
      drug: drug.map(i => i.optionSeq).toString(),
      uodcategory: unnaturalDeath.map(i => i.optionSeq).toString(),
    };

    try {
      let formData = new FormData();
      formData.append('qryParams', JSON.stringify(inputData));
      console.log(formData);
      const response = await fetch(VITE_API_URL + 'ViolentDeath', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      });
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Error fetching results:', error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!user) return;

    const fetchOptions = async () => {
      try {
        const [mannerRes, drugRes, unnaturalRes] = await Promise.all([
          axios.get(`${VITE_API_URL}getmannerofdeathoptions`),
          axios.get(`${VITE_API_URL}getdrugoptions`),
          axios.get(`${VITE_API_URL}getudcategoryoptions`),
        ]);

        setMannerOfDeathOptions(mannerRes.data);
        setDrugOptions(drugRes.data);
        setUnnaturalDeathOptions(unnaturalRes.data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
    setUserReady(true);

    const updatedColumns = columns.map(column => ({ ...column, headerName: null }));
    setFriendlyColumns(columns);
    setReportColumns(updatedColumns);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Violent Death Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            title='This report provides data on violent and unnatural deaths for the chosen jurisdictions'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />
      <Divider variant='middle' sx={{ my: 2 }} />
      <CardContent>
        <Slide direction='right' in={showErrorAlert}>
          <Alert severity='warning' onClose={dismissDateAlert}>
            {Object.values(validationErrors).map((err, idx) => (
              <div key={idx}>{err}</div>
            ))}
          </Alert>
        </Slide>
        <Grid container spacing={2}>
          {/* Form Fields */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='jurisdictions'
              // name='jurisdictions'
              options={authorizedJdxs.sort((a, b) => a.jdxName.localeCompare(b.jdxName))}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => {
                if (
                  option.jdxName ==
                  authorizedJdxs.sort((a, b) => a.jdxName.localeCompare(b.jdxName))[0].jdxName
                ) {
                  return (
                    <Stack>
                      <Button
                        sx={{
                          backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#cccccc',
                          color: theme.palette.text.primary,
                          p: 1,
                          m: 0.5,
                          boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)',
                        }}
                        onClick={handleSelectAndDeselectAllJurisdictions}
                      >
                        {JSON.stringify(selectedJdxs) == JSON.stringify(authorizedJdxs) ? (
                          <Typography variant='button'>Deselect All</Typography>
                        ) : (
                          <Typography variant='button'>Select All</Typography>
                        )}
                      </Button>
                      <li {...props}>
                        <Checkbox
                          icon={<MdCheckBoxOutlineBlank />}
                          checkedIcon={<MdOutlineCheckBox />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.jdxName}
                      </li>
                    </Stack>
                  );
                }
                return (
                  <li {...props}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.jdxName}
                  </li>
                );
              }}
              fullWidth
              renderInput={params => (
                <TextField {...params} label='Jurisdictions' placeholder='Jurisdictions' />
              )}
              // ListboxComponent={'ul'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Exam Start From'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Exam Start To'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              id='mannerofdeath'
              options={mannerOfDeathOptions}
              value={mannerOfDeath}
              size='small'
              getOptionLabel={option => option.optionName.toString()}
              onChange={(event, data) => handleMannerOfDeathChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='Manner Of Death' placeholder='Manner Of Death' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              id='drugs'
              options={drugOptions}
              value={drug}
              size='small'
              getOptionLabel={option => option.optionName.toString()}
              onChange={(event, data) => handleDrugChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Drugs' placeholder='Drugs' />}
              ListboxComponent={'ul'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              id='unnaturaldeath'
              options={unnaturalDeathOptions}
              value={unnaturalDeath}
              size='small'
              getOptionLabel={option => option.optionName.toString()}
              onChange={(event, data) => handleUnnaturalDeathChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Category of Unnatural Death'
                  placeholder='Category of Unnatural Death'
                />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              control={<Switch checked={friendlyHeader} onChange={friendlyHeaderToggle} />}
              label='Friendly Headers'
            />
          </Grid>
        </Grid>
        <Stack direction='row' sx={{ justifyContent: 'right', mt: 2 }} spacing={2}>
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant='outlined' color='secondary' onClick={clearAll}>
            Clear
          </Button>
        </Stack>
        <Collapse in={resultsVisible} sx={{ mt: 4 }}>
          <ReportDataGrid
            loading={loading}
            listresults={results ?? []}
            idcolumn='caseID'
            columnsInput={friendlyHeader ? friendlyColumns : reportColumns}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
