import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';

import '../../../../App.css';
import { useAuth } from '../../../../utils/auth/AuthService';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabPMTurnaroundTimeView() {

    const theme = useTheme();

    const handleCaseClick = (event, data) => {
        if (import.meta.env.MODE === 'production') {
          window.open('/medexlab/caseview?id=' + data.formattedValue);
        } else {
          window.open('/caseview?id=' + data.formattedValue);
        }
      };

    const columns = [
        {
          field: 'labCase',
          headerName: 'Lab Case',
          width: 105,
          editable: false,
          renderCell: params => {
            return (
              <NavLink
                color='primary'
                onClick={event => {
                  handleCaseClick(event, params);
                }}
              >
                <b>{params.formattedValue}</b>
              </NavLink>
            );
          },
          align: 'center',
          headerAlign: 'center',
          headerClassName: 'header-theme',
        },
        {
          field: 'departmentCaseNumber',
          headerName: 'Department Case Number',
          flex: 1,
          minWidth: 100,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          headerClassName: 'header-theme',
        },
        {
          field: 'dateAssignedToSection',
          headerName: 'Date Assigned To Section',
          flex: 1,
          minWidth: 150,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          headerClassName: 'header-theme',
        },
        {
          field: 'dateAssignedToAnalyst',
          headerName: 'Date Assigned To Analyst',
          flex: 1,
          minWidth: 150,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          headerClassName: 'header-theme',
        },
        {
          field: 'dateCompleted',
          headerName: 'Date Completed',
          flex: 1,
          minWidth: 150,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          headerClassName: 'header-theme',
        },
        {
            field: 'analystAssigned',
            headerName: 'Analyst Assigned',
            flex: 1,
            minWidth: 150,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'header-theme',
          },
        {
          field: 'turnaroundTime',
          headerName: 'Turnaround Time (Days)',
          flex: 1,
          minWidth: 80,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          headerClassName: 'header-theme',
        },
      ];

    return(
        <Card
        raised
        sx={{
          my: 3,
          mx: 'auto',
          width: { xs: '95%', lg: '75%' },
        }}
      >
        <CardHeader
          title='eLab PM Case Turnaround Time Report'
          sx={{
            mx: 'auto',
            textAlign: 'center',
            color: theme.palette.primary.main,
          }}
          action={
            <Tooltip
              sx={{ color: theme.palette.text.primary }}
              title='This report measures how long it takes on average for an eLab PM case to complete'
              placement='right-end'
            >
              <InfoIcon />
            </Tooltip>
          }
        />
  
        <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />
  
          <CardContent>
            <div>eLab PM Report Currently Under Construction</div>
          </CardContent>
      </Card>
    );
}