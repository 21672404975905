import { Alert, Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';

const { VITE_API_URL } = import.meta.env;

export function UnlockAccountButton({ userid }: { userid: string }) {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const unlockAccount = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${VITE_API_URL}user/unlock`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: JSON.stringify({
          userName: userid,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setSnackbar({
        open: true,
        message: data?.message,
        severity: data?.success ? 'success' : 'error',
      });
    } catch (error) {
      console.error('Error unlocking account:', error);

      setSnackbar({
        open: true,
        message: `Failed to reset failed login attempts for ${userid}. Please try again.`,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Button disabled={loading} onClick={unlockAccount} variant='contained' fullWidth>
        {loading ? 'Resetting...' : `Reset Failed Login Attempts for ${userid}`}
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
