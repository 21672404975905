import { Box, Skeleton } from '@mui/material';

export const ConversationListSkeleton = () => (
  <Box sx={{ p: 2 }}>
    {[1, 2, 3].map(i => (
      <Box key={i} sx={{ mb: 2 }}>
        <Skeleton variant='text' width='60%' height={24} />
        <Skeleton variant='text' width='90%' height={20} />
        <Skeleton variant='text' width='40%' height={20} />
      </Box>
    ))}
  </Box>
);

export const MessageSkeleton = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
    <Skeleton variant='text' width={100} height={20} />
    <Skeleton variant='rectangular' width='60%' height={60} sx={{ borderRadius: 2 }} />
  </Box>
);
