import { Add } from '@mui/icons-material';
import { Alert, Box, Button, Chip, Snackbar, Stack, Typography } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { memo, useEffect, useState } from 'react';
import { useOptionsAPI } from '../../utils/api/useOptions.hook';
import { useAuth } from '../../utils/auth/AuthService';
import CustomPagination from '../../utils/components/CustomPagination';
import { CallCenterUserDialog, UserProfile } from './CallCenterUserDialog';

export interface CallCenterUserOption {
  userSeq: string;
  userID: string;
  name: string;
  initials: string;
  isActive: boolean;
}

const columns: GridColDef[] = [
  { field: 'userID', headerName: 'UserID', width: 150 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'initials', headerName: 'Initials', width: 150 },
  {
    field: 'isActive',
    headerName: 'Status',
    width: 150,
    renderCell: ({ value }) => (
      <Chip
        variant='outlined'
        size='small'
        color={value ? 'success' : 'default'}
        label={value ? 'Active' : 'Inactive'}
      />
    ),
  },
];

const { VITE_API_URL } = import.meta.env;

export function CallCenterUsersDataGrid() {
  const { user } = useAuth();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });

  const { options: addressTypeOptions } = useOptionsAPI('getaddresstypeoptions');
  const { options: stateOptions } = useOptionsAPI('getstateoptions');
  const { options: countryOptions } = useOptionsAPI('getcountryoptions');

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<CallCenterUserOption[]>([]);
  const [selectedCallCenterUser, setSelectedCallCenterUser] = useState<CallCenterUserOption | null>(
    null
  );
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const fetchUserProfile = async (userSeq: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${VITE_API_URL}GetUserProfiles?userSeq=${userSeq}&isIncludeSecurityQuestion=false`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setSelectedUser(data);
        setDialogOpen(true);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);

    try {
      const response = await fetch(VITE_API_URL + 'get-dvcs-call-center-user-options', {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setRows(data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (params: any) => {
    setSelectedCallCenterUser(params.row);
    fetchUserProfile(params.row.userSeq);
  };

  const handleDialogClose = (status?: { success: boolean; message: string }) => {
    setDialogOpen(false);
    setAddDialogOpen(false);
    setSelectedUser(null);

    if (status?.message) {
      setSnackbar({
        open: true,
        message: status.message,
        severity: status.success ? 'success' : 'error',
      });
    }

    fetchUsers();
  };

  const onClickAddNewUser = () => {
    setAddDialogOpen(true);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box component='div'>
      <DataGridPremium
        loading={loading}
        columns={columns}
        sx={{ height: 'min-content' }}
        rows={rows}
        getRowId={row => row?.userSeq}
        onRowClick={handleRowClick}
        pageSizeOptions={[5, 10, 15, 20, 50, 100]}
        pagination
        paginationModel={paginationModel}
        slotProps={{
          toolbar: {
            // @ts-ignore
            onClickAddNewUser: onClickAddNewUser,
          },
        }}
        slots={{
          // @ts-ignore
          toolbar: CustomGridToolbar,
          pagination: CustomPagination,
        }}
        density='compact'
      />
      {selectedUser && (
        <CallCenterUserDialog
          mode='edit'
          initialData={selectedUser}
          addressTypeOptions={addressTypeOptions}
          stateOptions={stateOptions}
          countryOptions={countryOptions}
          open={dialogOpen}
          selectedUser={selectedCallCenterUser}
          onClose={handleDialogClose}
        />
      )}

      <CallCenterUserDialog
        mode='add'
        addressTypeOptions={addressTypeOptions}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        open={addDialogOpen}
        selectedUser={selectedCallCenterUser}
        onClose={handleDialogClose}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={snackbar.severity} variant='filled' sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
interface CustomGridToolbarProps {
  onClickAddNewUser: () => void;
}

const CustomGridToolbar: React.FC<CustomGridToolbarProps> = memo(({ onClickAddNewUser }) => {
  return (
    <GridToolbarContainer>
      <Stack sx={{ width: '100%' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack sx={{ px: 1 }}>
            <Typography variant='h6'>DVCS Call Center Users</Typography>
          </Stack>
          <GridToolbarQuickFilter sx={{ my: 1 }} />

          <Button variant='text' startIcon={<Add />} onClick={() => onClickAddNewUser()}>
            Add New User
          </Button>
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
});
