// src/views/Reports/MasterCaseListView.tsx

import { Search, Settings } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  debounce,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  LinearProgress,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Jurisdiction } from '../../types/Jurisdiction.interface';
import { useAuth } from '../../utils/auth/AuthService';
import CustomPagination from '../../utils/components/CustomPagination';

// Define the JurisdictionCase interface
export interface JurisdictionCase {
  caseId?: string;
  decedentLastName?: string;
  decedentFirstName?: string;
  age?: string;
  decedentRace?: string;
  decedentGender?: string;
  dateOfDeath?: Date | null;
  placeOfDeath?: string;
  placeOfDeathCity?: string;
  decedentResidenceCity?: string;
  jurisdiction?: string;
  examType?: string;
  examStartedOn?: Date | null;
  examPhysician?: string;
  mannerOfDeath?: string;
  // Derived field (optional)
  decedentName?: string;
}

interface CustomGridToolbarProps {
  numberOfCases: number;
  selectedJurisdictions: Jurisdiction[];
  allJurisdictions: Jurisdiction[];
  onJurisdictionChange: (jurisdictions: Jurisdiction[]) => void;
  exporting: boolean;
  handleExportAll: () => void;
  exportProgress: number;
  dateRange: DateRange<Dayjs>;
  onDateRangeChange: (newRange: DateRange<Dayjs>) => void;
}

const CustomGridToolbar: React.FC<CustomGridToolbarProps> = memo(
  ({
    numberOfCases,
    selectedJurisdictions,
    allJurisdictions,
    onJurisdictionChange,
    exporting,
    handleExportAll,
    exportProgress,
    dateRange,
    onDateRangeChange,
  }) => {
    return (
      <GridToolbarContainer sx={{ py: 1 }}>
        <Typography variant='h6' sx={{ px: 1 }}>
          {numberOfCases} cases
        </Typography>

        <Stack sx={{ width: '100%' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
              <JurisdictionsMenu
                selectedJurisdictions={selectedJurisdictions}
                allJurisdictions={allJurisdictions}
                onJurisdictionChange={onJurisdictionChange}
              />
              <GridToolbarColumnsButton />
            </Stack>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                value={dateRange}
                onChange={onDateRangeChange}
                closeOnSelect={false}
                showDaysOutsideCurrentMonth={true}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      width: '150px',
                      '& .MuiInputBase-input': {
                        padding: '4px 8px',
                        fontSize: '0.875rem',
                      },
                      '& .MuiInputBase-root': {
                        paddingRight: '4px',
                      },
                      '& .MuiInputAdornment-root': {
                        marginLeft: '2px',
                      },
                    },
                  },
                  field: {
                    format: 'MM/DD/YY',
                  },
                  shortcuts: {
                    items: Array.from({ length: 5 }, (_, index) => {
                      const year = dayjs().year() - index;
                      return {
                        label: year.toString(),
                        getValue: () => {
                          return [dayjs(`${year}-01-01`), dayjs(`${year}-12-31`)];
                        },
                      };
                    }),
                  },
                }}
                localeText={{ start: 'From', end: 'To' }}
                sx={{ mb: 0.5 }}
              />
            </LocalizationProvider>

            <Stack direction='row' spacing={1}>
              <GridToolbarExportContainer>
                <MenuItem disabled={exporting} onClick={handleExportAll}>
                  {exporting ? 'Exporting...' : 'Download as Excel'}
                </MenuItem>
                <GridCsvExportMenuItem />
                <GridPrintExportMenuItem />
              </GridToolbarExportContainer>
            </Stack>
          </Stack>
        </Stack>

        {/* Progress Bar */}
        {exporting && (
          <Box component='div' sx={{ width: '100%', mx: 1 }}>
            <Typography variant='body2' color='textSecondary'>
              Exporting: {exportProgress.toFixed(1)}%
            </Typography>
            <LinearProgress variant='determinate' value={exportProgress} />
          </Box>
        )}
      </GridToolbarContainer>
    );
  }
);

CustomGridToolbar.displayName = 'CustomGridToolbar';

// Define the JurisdictionsMenu component
interface JurisdictionsMenuProps {
  selectedJurisdictions: Jurisdiction[];
  allJurisdictions: Jurisdiction[];
  onJurisdictionChange: (jurisdictions: Jurisdiction[]) => void;
}

const JurisdictionsMenu = memo(
  ({ selectedJurisdictions, allJurisdictions, onJurisdictionChange }: JurisdictionsMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // Temporary state to hold selections while menu is open
    const [tempSelectedJurisdictions, setTempSelectedJurisdictions] = useState<Jurisdiction[]>([]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setTempSelectedJurisdictions(selectedJurisdictions);
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleJurisdictionToggle = (jurisdiction: Jurisdiction) => {
      const currentIndex = tempSelectedJurisdictions.findIndex(
        j => j.jdxCode === jurisdiction.jdxCode
      );
      let newSelectedJurisdictions = [...tempSelectedJurisdictions];

      if (currentIndex === -1) {
        newSelectedJurisdictions.push(jurisdiction);
      } else {
        newSelectedJurisdictions.splice(currentIndex, 1);
      }

      setTempSelectedJurisdictions(newSelectedJurisdictions);
    };

    const handleApply = () => {
      onJurisdictionChange(tempSelectedJurisdictions);
      handleClose();
    };

    const handleCancel = () => {
      handleClose();
    };

    return (
      <>
        <Button
          variant='text'
          startIcon={<Settings />}
          onClick={handleClick}
          aria-controls={open ? 'jurisdictions-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          Jurisdictions ({selectedJurisdictions.length})
        </Button>
        <Menu
          id='jurisdictions-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'jurisdictions-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box component='div' sx={{ px: 2, py: 1, maxHeight: 300, overflowY: 'auto' }}>
            <FormGroup>
              {allJurisdictions.map(jurisdiction => (
                <FormControlLabel
                  key={jurisdiction.jdxCode}
                  control={
                    <Checkbox
                      checked={tempSelectedJurisdictions.some(
                        j => j.jdxCode === jurisdiction.jdxCode
                      )}
                      onChange={() => handleJurisdictionToggle(jurisdiction)}
                    />
                  }
                  label={<ListItemText primary={jurisdiction.jdxName} />}
                />
              ))}
            </FormGroup>
          </Box>
          <Divider component='hr' />
          <Box component='div' sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <Button onClick={handleCancel} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleApply} color='primary' variant='contained' sx={{ ml: 1 }}>
              Apply
            </Button>
          </Box>
        </Menu>
      </>
    );
  }
);

JurisdictionsMenu.displayName = 'JurisdictionsMenu';

const MasterCaseListView: React.FC = () => {
  const [cases, setCases] = useState<JurisdictionCase[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<Jurisdiction[]>(
    user?.jdxAccessList || []
  );
  const [allJurisdictions, setAllJurisdictions] = useState<Jurisdiction[]>(
    user?.jdxAccessList || []
  );

  const [exporting, setExporting] = useState<boolean>(false);
  const [exportProgress, setExportProgress] = useState<number>(0);
  const [notification, setNotification] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'info';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [searchTerm, setSearchTerm] = useState<string>('');

  const exportApiRef = useGridApiRef();
  const fetchRowCount = useCallback(async () => {
    try {
      const startDate = dateRange[0]?.toISOString();
      const endDate = dateRange[1]?.toISOString();

      const response = await fetch(
        `${import.meta.env.VITE_FASTAPI_URL}GetJurisdictionCaseCount?${
          searchTerm ? `searchTerm=${encodeURIComponent(searchTerm)}&` : ''
        }start_date=${startDate}&end_date=${endDate}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedJurisdictions),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch the total case count.');
      }
      const countText = await response.text();
      const count = parseInt(countText, 10);
      setTotalRows(count);
    } catch (error) {
      setTotalRows(0);
      console.error('Error fetching row count:', error);
      setNotification({
        open: true,
        message: 'Failed to fetch the total case count.',
        severity: 'error',
      });
    }
  }, [selectedJurisdictions, user?.accessToken, dateRange, searchTerm]);
  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      setPaginationModel(prev => ({ ...prev, page: 0 }));
      fetchRowCount();
    }, 300),
    [fetchRowCount]
  );
  const fetchCases = useCallback(async () => {
    setLoading(true);
    try {
      const startDate = dateRange[0]?.toISOString();
      const endDate = dateRange[1]?.toISOString();

      const apiUrl = import.meta.env.VITE_FASTAPI_URL;
      const endpoint = searchTerm ? 'SearchJurisdictionCases' : 'GetAllJurisdictionCases';

      const response = await fetch(
        `${apiUrl}${endpoint}?offset=${paginationModel.page * paginationModel.pageSize}&limit=${
          paginationModel.pageSize
        }&start_date=${startDate}&end_date=${endDate}${
          searchTerm ? `&searchTerm=${encodeURIComponent(searchTerm)}` : ''
        }`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedJurisdictions),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch cases.');
      }

      const data: JurisdictionCase[] = await response.json();
      setCases(data);
    } catch (error) {
      console.error('Error fetching cases:', error);
      setNotification({
        open: true,
        message: 'Failed to fetch cases.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    selectedJurisdictions,
    user?.accessToken,
    dateRange,
    searchTerm,
  ]);

  const handleCaseClick = useCallback(
    (caseId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      navigate('/caseview', { state: { cmscaseid: caseId } });
    },
    [navigate]
  );

  useEffect(() => {
    fetchRowCount();
  }, [fetchRowCount]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const fetchAllJurisdictionCases = useCallback(async (): Promise<JurisdictionCase[]> => {
    const allCases: JurisdictionCase[] = [];
    const pageSize = 500;
    let offset = 0;
    let hasMore = true;

    const startDate = dateRange[0]?.toISOString();
    const endDate = dateRange[1]?.toISOString();

    while (hasMore) {
      if (offset > 0) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const response = await fetch(
        `${
          import.meta.env.VITE_FASTAPI_URL
        }GetAllJurisdictionCases?offset=${offset}&limit=${pageSize}&start_date=${startDate}&end_date=${endDate}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedJurisdictions),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data at offset ${offset}.`);
      }

      const data: JurisdictionCase[] = await response.json();
      allCases.push(...data);

      if (data.length < pageSize) {
        hasMore = false;
      } else {
        offset += pageSize;
      }

      const progress = Math.min((allCases.length / totalRows) * 100, 99.9);
      setExportProgress(progress);
    }

    return allCases;
  }, [selectedJurisdictions, user?.accessToken, totalRows, dateRange]);

  // Add date range change handler
  const handleDateRangeChange = useCallback((newRange: DateRange<Dayjs>) => {
    setDateRange(newRange);
  }, []);

  const handleExportAll = useCallback(async () => {
    setExporting(true);
    setExportProgress(0);
    try {
      const allData = await fetchAllJurisdictionCases();

      if (allData.length === 0) {
        setNotification({ open: true, message: 'No data available to export.', severity: 'info' });
        setExporting(false);
        return;
      }

      exportApiRef.current.setRows(allData);
      exportApiRef.current.forceUpdate();
      await exportApiRef.current.exportDataAsExcel({
        fileName: `All-Cases-List_${new Date(Date.now()).toLocaleDateString()}`,
      });

      exportApiRef.current.forceUpdate();
      exportApiRef.current.setRows([]);
      exportApiRef.current.forceUpdate();

      setExporting(false);
      setExportProgress(0);
      setNotification({
        open: true,
        message: 'Export completed successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error exporting all cases:', error);
      setNotification({
        open: true,
        message: `Export failed: ${(error as Error).message}`,
        severity: 'error',
      });
      setExporting(false);
      setExportProgress(0);
    }
  }, [fetchAllJurisdictionCases]);

  const columns: GridColDef<JurisdictionCase>[] = useMemo(
    () => [
      {
        field: 'caseId',
        headerName: 'Case ID',
        width: 105,
        editable: false,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Button
            variant='text'
            color='primary'
            onClick={handleCaseClick(params.value || '')}
            style={{ fontFamily: '"DataGrid", monospace', fontSize: '0.7rem' }}
          >
            <b>{params.value}</b>
          </Button>
        ),
        type: 'string',
        valueGetter: (value, row) => row.caseId,
      },
      {
        field: 'decedentName',
        headerName: 'Name',
        editable: false,
        width: 150,
        type: 'string',
        renderCell: params => `${params.row.decedentLastName}, ${params.row.decedentFirstName}`,
        valueGetter: (value, row) => `${row.decedentLastName}, ${row.decedentFirstName}`,
      },
      {
        field: 'age',
        headerName: 'Age',
        width: 100,
        editable: false,
        type: 'string',
        valueGetter: (value, row) => row.age,
      },
      {
        field: 'decedentRace',
        headerName: 'Race',
        minWidth: 100,
        editable: false,
        type: 'string',
      },
      {
        field: 'decedentGender',
        headerName: 'Gender',
        width: 100,
        editable: false,
        type: 'string',
      },
      {
        field: 'dateOfDeath',
        headerName: 'Date of Death',
        width: 140,
        editable: false,
        type: 'string',
        renderCell: ({ value }) => (value ? new Date(value).toLocaleDateString() : ''),
      },
      {
        field: 'placeOfDeath',
        headerName: 'Place of Death',
        minWidth: 180,
        editable: false,
        type: 'string',
      },
      {
        field: 'placeOfDeathCity',
        headerName: 'City of Death',
        minWidth: 140,
        editable: false,
        type: 'string',
      },
      {
        field: 'decedentResidenceCity',
        headerName: 'Residence',
        minWidth: 140,
        editable: false,
        type: 'string',
      },
      {
        field: 'jurisdiction',
        headerName: 'Jurisdiction',
        width: 140,
        editable: false,
        type: 'string',
      },
      {
        field: 'examType',
        headerName: 'Exam Type',
        width: 120,
        editable: false,
        type: 'string',
      },
      {
        field: 'examPhysician',
        headerName: 'Pathologist',
        minWidth: 150,
        editable: false,
        type: 'string',
      },
      {
        field: 'mannerOfDeath',
        headerName: 'Manner of Death',
        minWidth: 150,
        editable: false,
        type: 'string',
      },
    ],
    [handleCaseClick]
  );

  return (
    <Stack spacing={2}>
      <SearchBar onSearch={debouncedSearch} />

      <div style={{ height: '100%', width: '100%' }}>
        <DataGridPremium
          rows={cases}
          columns={columns}
          rowCount={totalRows}
          pageSizeOptions={[5, 10, 15, 20, 50, 100]}
          pagination
          paginationModel={paginationModel}
          paginationMode='server'
          getRowId={(row: JurisdictionCase) => row.caseId || ''}
          onPaginationModelChange={setPaginationModel}
          loading={loading}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'linear-progress',
            },
            toolbar: {
              // @ts-ignore
              numberOfCases: totalRows,
              selectedJurisdictions,
              allJurisdictions,
              onJurisdictionChange: setSelectedJurisdictions,
              exporting,
              handleExportAll,
              exportProgress,
              dateRange,
              onDateRangeChange: handleDateRangeChange,
            },
          }}
          slots={{
            // @ts-ignore
            toolbar: CustomGridToolbar,
            pagination: CustomPagination,
          }}
          density='compact'
          sx={{ backgroundColor: 'background.paper' }}
        />
      </div>

      {/* Hidden DataGridPremium for Exporting */}
      <Box component='div' sx={{ visibility: 'hidden', position: 'absolute', top: -9999 }}>
        <DataGridPremium
          apiRef={exportApiRef}
          getRowId={(row: JurisdictionCase) => row.caseId || `no-id-${Math.random()}`}
          columns={columns}
          hideFooter
        />
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setNotification(prev => ({ ...prev, open: false }))}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar = ({ onSearch }: SearchBarProps) => {
  return (
    <TextField
      fullWidth
      placeholder='Search cases by name, CaseID, or any field...'
      size='small'
      onChange={e => onSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: 'background.paper',
        mb: 2,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'divider',
          },
          '&:hover fieldset': {
            borderColor: 'primary.main',
          },
        },
      }}
    />
  );
};

export default memo(MasterCaseListView);
