import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  Close,
  EditRounded,
  PanToolAltRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Bounds, Environment, OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { motion } from 'framer-motion';
import React, { Ref, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../../../../utils/auth/AuthService';
import { ModelViewerAPI } from './modelviewer.api';

interface UploadModelDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchModels: any;
}

const { VITE_API_URL } = import.meta.env;

export function UploadModelDialog({ open, setOpen, fetchModels }: UploadModelDialogProps) {
  const theme = useTheme();
  const [usdzFile, setUsdzFile] = useState<File | null>(null);
  const [gltfFile, setGltfFile] = useState<File | null>(null);
  const [glbFile, setGlbFile] = useState<File | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [title, setTitle] = useState<string>('');
  const [gltfDownloadUrl, setGltfDownloadUrl] = useState<string | null>(null);
  const [capturedThumbnailDataUrl, setCapturedThumbnailDataUrl] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);

  const showSuccessToast = () => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const [submitting, setSubmitting] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [successMessage]);

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach(file => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'usdz') {
        setUsdzFile(file);
        setSuccessMessage('USDZ file added successfully.');
        if (!title) {
          setTitle(file.name);
        }
      } else if (fileExtension === 'gltf') {
        setGltfFile(file);
        setSuccessMessage('GLTF file added successfully.');
        if (!title) {
          setTitle(file.name);
        }
        handleLoadModel(file);
      } else if (fileExtension === 'glb') {
        setGlbFile(file);
        setSuccessMessage('GLB file added successfully.');
        if (!title) {
          setTitle(file.name);
        }
        handleLoadModel(file);
      } else {
        setErrorMessage('Unsupported file type. Please upload a USDZ, GLTF, or GLB file.');
      }
    });
  };

  const handleLoadModel = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result as string;
      setGltfDownloadUrl(dataUrl);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'model/usdz': ['.usdz'],
      'model/gltf+json': ['.gltf'],
      'model/gltf-binary': ['.glb'],
    },
    onDrop,
  });

  function handleClose() {
    setOpen(false);
    setUsdzFile(null);
    setGltfFile(null);
    setGlbFile(null);
    setErrorMessage(null);
    setSuccessMessage(null);
    setTitle('');
    setGltfDownloadUrl(null);
    setCapturedThumbnailDataUrl(null);
  }

  const auth = useAuth();

  async function handleUpload() {
    try {
      setErrorMessage(null);
      setSubmitting(true);
      if (usdzFile && (gltfFile || glbFile) && title) {
        if (!canvasRef.current) return;
        const dataURL = canvasRef.current.toDataURL('image/png', 1);

        const thumbnailBlob = await (await fetch(dataURL)).blob();
        const thumbnailFile = new File([thumbnailBlob], 'thumbnail.png', { type: 'image/png' });

        const modelFile = gltfFile || glbFile;

        await ModelViewerAPI.uploadModel({
          accessToken: auth.user?.accessToken!,
          modelName: title,
          files: [
            {
              file: usdzFile,
              modelFileDetailLevel: 'high',
            },
            {
              file: modelFile!,
              modelFileDetailLevel: 'high',
            },
            {
              file: thumbnailFile,
            },
          ],
        });

        showSuccessToast();
        fetchModels();
        handleClose();
      }
    } catch (error) {
      setErrorMessage(`Failed to upload model: ${error}. Please try again later`);
    } finally {
      setSubmitting(false);
    }
  }

  function handleRemoveFile(fileType: 'usdz' | 'gltf' | 'glb') {
    if (fileType === 'usdz') {
      setUsdzFile(null);
    } else if (fileType === 'gltf') {
      setGltfFile(null);
      setGltfDownloadUrl(null);
    } else if (fileType === 'glb') {
      setGlbFile(null);
      setGltfDownloadUrl(null);
    }
  }

  function getGltfOrGlbText() {
    if (glbFile)
      return (
        <Stack direction='row' spacing={2} alignItems='center'>
          <Chip variant='outlined' label='GLB' />
          <p>{glbFile.name}</p>
        </Stack>
      );
    if (gltfFile)
      return (
        <Stack direction='row' spacing={2} alignItems='center'>
          <Chip variant='outlined' label='GLTF' />
          <p>{gltfFile.name}</p>
        </Stack>
      );
    return 'GLTF or GLB version needed';
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Upload new 3D model</DialogTitle>

        <DialogContent>
          <Typography variant='body2' sx={{ pb: 2 }}>
            To ensure the best compatibility and performance across different platforms and devices,
            it is required you upload both a GLTF/GLB version and a USDZ version of your 3D model
          </Typography>

          <Typography variant='body2' sx={{ pb: 2 }}>
            For better organization, it is recommended to name the GLTF/GLB and USDZ files with the
            same name
          </Typography>

          {(!usdzFile || !(gltfFile || glbFile)) && (
            <div
              {...getRootProps()}
              style={{
                border: '2px dashed',
                borderColor:
                  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
                padding: '20px',
                textAlign: 'center',
                marginBottom: '20px',
                backgroundColor: isDragActive
                  ? theme.palette.action.hover
                  : theme.palette.background.default,
                color: theme.palette.text.primary,
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? <p>Drop files here...</p> : <p>Drag and drop model files here</p>}
            </div>
          )}
          <Collapse in={Boolean(successMessage)}>
            <Typography style={{ marginBottom: '10px', color: 'green' }}>
              {successMessage}
            </Typography>
          </Collapse>

          <Collapse in={Boolean(errorMessage)}>
            <Typography color='error' style={{ marginBottom: '10px' }}>
              {errorMessage}
            </Typography>
          </Collapse>

          <ListItemButton dense onClick={() => handleRemoveFile('usdz')} disabled={submitting}>
            <ListItemIcon>
              {usdzFile ? (
                <CheckBoxRounded fontSize='small' color='success' />
              ) : (
                <CheckBoxOutlineBlankRounded fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                usdzFile ? (
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Chip variant='outlined' label='USDZ' />
                    <p>{usdzFile.name}</p>
                  </Stack>
                ) : (
                  'USDZ version needed'
                )
              }
            />
            {usdzFile && <Close />}
          </ListItemButton>

          <ListItemButton
            dense
            onClick={() => {
              handleRemoveFile('gltf');
              handleRemoveFile('glb');
            }}
            disabled={submitting}
          >
            <ListItemIcon>
              {gltfFile || glbFile ? (
                <CheckBoxRounded fontSize='small' color='success' />
              ) : (
                <CheckBoxOutlineBlankRounded fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText primary={getGltfOrGlbText()} />
            {gltfFile && <Close />}
            {glbFile && <Close />}
          </ListItemButton>

          {gltfDownloadUrl && (
            <Box component='div' mt={2} display='flex' justifyContent='center'>
              <Box component='div' mt={2}>
                <Typography variant='subtitle2' color='darkgray'>
                  {gltfFile && `Previewing ${gltfFile.name}`}
                  {glbFile && `Previewing ${glbFile.name}`}
                </Typography>
                <Box component='div' width={350}>
                  <ModelPreviewCard
                    canvasRef={canvasRef}
                    gltfDownloadUrl={gltfDownloadUrl}
                    gltfFile={gltfFile}
                    glbFile={glbFile}
                    setTitle={setTitle}
                    title={title}
                  />
                </Box>
              </Box>
            </Box>
          )}

          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={handleUpload}
            disabled={!usdzFile || !(gltfFile || glbFile) || !title || submitting}
            style={{ marginTop: '20px' }}
            startIcon={submitting && <CircularProgress sx={{ fontSize: 20 }} />}
          >
            {submitting ? 'Uploading..' : 'Upload'}
          </Button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
        message='Model uploaded successfully'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
}

function Model({ url }: { url: string }) {
  const { scene } = useGLTF(url, true);

  return <primitive object={scene} />;
}

function ModelPreviewCard({
  canvasRef,
  gltfDownloadUrl,
  gltfFile,
  glbFile,
  title,
  setTitle,
}: {
  canvasRef: Ref<HTMLCanvasElement> | undefined;
  gltfDownloadUrl: string;
  gltfFile: File | null;
  glbFile: File | null;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}) {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [modelName, setModelName] = useState(title);

  useEffect(() => {
    const timer = setTimeout(() => {}, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (gltfFile || glbFile) {
      const fileName = (gltfFile || glbFile)!.name;
      // const fileNameWithoutExtension = fileName.replace(/\.(gltf|glb)$/, '');
      setModelName(normalizeTitle(fileName));
      setTitle(normalizeTitle(fileName));
    }
  }, [gltfFile, glbFile, setTitle]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModelName(event.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);

    setIsEditing(false);
    setTitle(modelName);
  };

  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Box
        component='div'
        sx={{
          backgroundColor: theme.palette.background.default,
          p: 3,
          borderRadius: '12px',
          display: 'flex',
        }}
      >
        <Stack>
          <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
            {isEditing ? (
              <input
                type='text'
                value={modelName}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                style={{
                  flexGrow: 1,
                  marginRight: '8px',
                  outline: 'none',
                  border: 'none',
                  backgroundColor: 'transparent',
                  fontFamily: theme.typography.h6.fontFamily,
                  fontSize: theme.typography.h6.fontSize,
                  fontWeight: theme.typography.h6.fontWeight,
                  lineHeight: theme.typography.h6.lineHeight,
                  color: theme.palette.text.primary,
                }}
                autoFocus
              />
            ) : (
              <Typography
                variant='h6'
                sx={{
                  flexGrow: 1,
                  mr: 1,
                  cursor: 'text',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={handleEditClick}
              >
                {modelName}
              </Typography>
            )}
            <IconButton onClick={handleEditClick} size='small'>
              <EditRounded />
            </IconButton>
          </Box>
          <div style={{ position: 'relative' }}>
            <Canvas
              ref={canvasRef}
              style={{ width: 300, height: 300, background: 'transparent' }}
              gl={{ preserveDrawingBuffer: true }}
            >
              <Environment
                preset='studio'
                near={1}
                far={1000}
                resolution={1024}
                background={false}
                blur={0.5}
              />
              <Bounds fit clip observe margin={1}>
                <Model url={gltfDownloadUrl} />
              </Bounds>
              <OrbitControls makeDefault />
            </Canvas>
            <CanvasOverlay />
          </div>
        </Stack>
      </Box>
    </Paper>
  );
}

function CanvasOverlay() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleInteraction = () => {
    setIsVisible(false);
  };

  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const iconVariants = {
    move: {
      x: [0, 10, 0, -10, 0],
      y: [0, -10, 0, 10, 0],
      opacity: [1, 0.5, 1, 0.5, 1],
      transition: {
        duration: 4,
        ease: 'easeInOut',
        times: [0, 0.25, 0.5, 0.75, 1],
        repeat: Infinity,
        repeatDelay: 1,
      },
    },
  };

  if (!isVisible) {
    return null;
  }

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      variants={variants}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
      }}
      onPointerDown={handleInteraction}
    >
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '8px 16px',
          borderRadius: '4px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <motion.div variants={iconVariants} animate='move' style={{ marginRight: '8px' }}>
          <PanToolAltRounded />
        </motion.div>
        Click and drag to rotate the model
      </div>
    </motion.div>
  );
}
function normalizeTitle(fileName: string): string {
  const fileNameWithoutExtension = fileName.replace(/.(usdz|gltf|glb)$/, '');
  const normalizedTitle = fileNameWithoutExtension.replace(/_/g, ' ');
  return normalizedTitle.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  );
}
