export interface NotificationTypeWithUserPreference {
  notificationTypeSeq: string;
  notificationTypeName: string;
  notificationTypeDescription: string;
  isActive: boolean;
  userSeq: string;
  inApp: boolean;
  email: boolean;
  textMessage: boolean;
}

export type NotificationDeliveryMethods = 'in-app' | 'email' | 'text message';

const { VITE_API_URL } = import.meta.env;

export async function getUserNotificationPreferences(
  accessToken: string,
  userSeq: string
): Promise<NotificationTypeWithUserPreference[]> {
  const url = `${VITE_API_URL}notifications/getusernotificationpreferences?userSeq=${userSeq}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data as NotificationTypeWithUserPreference[];
  } catch (error) {
    console.error('Error fetching notification type with preference:', error);
    throw error;
  }
}

export async function updateUserNotificationPreference(
  accessToken: string,
  preference: NotificationTypeWithUserPreference
): Promise<NotificationTypeWithUserPreference> {
  const url = `${VITE_API_URL}notifications/updatenotificationpreference`;

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(preference),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data as NotificationTypeWithUserPreference;
  } catch (error) {
    console.error('Error updating notification preference:', error);
    throw error;
  }
}

export async function updateUserSelectedDeliveryMethodStatusForNotificationType(
  accessToken: string,
  userSeq: string,
  notificationTypeSeq: string,
  deliveryMethod: NotificationDeliveryMethods,
  enabled: boolean
): Promise<NotificationTypeWithUserPreference> {
  const url = `${VITE_API_URL}notifications/UpdateUserSelectedDeliveryMethodStatusForNotificationType`;

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      userSeq,
      notificationTypeSeq,
      deliveryMethod,
      enabled,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}
