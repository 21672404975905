import React, { useContext, useMemo, useState } from 'react';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import axios from 'axios';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useDropzone } from 'react-dropzone';
import { GlobalContext } from '../../Context/GlobalContext';
import DeleteIcon from '@mui/icons-material/Delete';
import authHeader from '../../services/auth-header';
import { useAuth } from '../../utils/auth/AuthService.tsx';
import { AddRounded } from '@mui/icons-material';

const id = '00000000-0000-0000-0000-000000000000';
const guid = '00000000-0000-0000-0000-000000000000';

const baseStyle = {
  minWidth: 550,
  minHeight: 400,
  border: '3px dashed #ccc',
  borderColor: '#ccc',
  borderRadius: 1,
  padding: 30,
  backgroundColor: '#eee',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const FileUpload = ({
  photoTypeSeq,
  setFiles,
  setFilesToShow,
  documentType,
  module,
  permission,
}) => {
  const { user } = useAuth();
  const { profileInformation } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [fileInformation, setFileInformation] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const userPermissions = permissions?.['Users'];
  const handleFileUpload = (files) => {
    const allowedExtensions =
    // /(\.pdf|\.doc|\.docx|\.png|\.jpg|\.jpeg|\.xls|\.xlsx)$/i;
    /(\.pdf|\.png|\.jpg|\.jpeg)$/i;
    let canUpload = true;
    for (let i = 0; i < files.length; i++) {
      if (!allowedExtensions.exec(files[i].name)) {
        canUpload = false;
      }
    }

    if (!canUpload) {
      alert(
        // 'Please select a valid file types: .pdf, .doc, .docx, .png, .jpg, .xls, or .xlsx'
        'Please select a valid file types: .pdf, .png, .jpg or .jpeg'
      );
      event.value = '';
      return false;
    } else {
      setFileInformation(files);
    }
  };

  const SaveUserCertificate = () => {
    if (fileInformation.length === 0) return;
    for (let i = 0; i < fileInformation.length; i++) {
      const file = fileInformation[i];
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = btoa(
          new Uint8Array(reader.result).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
          }, '')
        );
        let saveEndpoint = module
          ? 'UploadResourceFile'
          : 'SaveUserCertificate';
        let getEndpoint = module
          ? 'GetUploadResourceByAssignmentType?isPhotoTab=true'
          : `GetAllUserCertificate?userSeq=${profileInformation?.userSeq}`;
        let saveData = {
          assignmentTypeSeq: photoTypeSeq ?? guid,
          photo: base64String,
          userSeq: module ? user?.userSeq : profileInformation?.userSeq,
          logInUserSeq: user?.userSeq,
          caseSeq: '',
          fileName: file.name,
          entityName: module ? module : '',
          photoInfo: '',
          photoSeq: id,
          uploadType: '',
          isResourceFile: '',
          isUsercertificate: 'true',
          recoverySeal: '',
          photoTypeSeq: photoTypeSeq ?? guid,
          certificateTypeSeq: photoTypeSeq ?? guid,
          photoLabel: module ? module : '',
          resourceTypeSeq: '',
          photoMimeType: file.type,
          filePath: '',
          isEsignature: '',
        };
        if (module) {
          saveData.isHyperLink = 'false';
          saveData.isResourceFile = 'true';
        }
        axios
          .post(`${import.meta.env.VITE_API_URL}${saveEndpoint}`, saveData, {
            headers: authHeader(),
          })
          .then((response) => {
            if (response?.data?.status === 0) {
              setIsOpen(false);
              axios
                .get(`${import.meta.env.VITE_API_URL}${getEndpoint}`, {
                  headers: authHeader(),
                })
                .then((response) => {
                  setFilesToShow(response.data.data);
                  setFiles(response.data.data);
                  setFileInformation([]);
                });
            }
          });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  var { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept:
        'application/pdf, video/mp4, model/vnd.usdz+zip, model/gltf+json, model/gltf-binary, .zip', // Accept specific MIME types for pdf, mp4, usdz, gltf, and any .zip file
      maxFiles: 50,
      maxSize: 20000000,
      onDrop: handleFileUpload,
    });

  const handleClose = () => {
    setIsOpen(false);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  if (isOpen) {
    return (
      <Box>
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle>File Upload {module} </DialogTitle>
          <DialogContent>
            <Box
              {...getRootProps({
                style,
              })}
            >
              <Box sx={{ display: 'block', textAlign: 'center' }}>
                <DriveFolderUploadIcon
                  fontSize='large'
                  sx={{ color: '#aaa', height: 50, width: 50 }}
                />
                <input {...getInputProps()} />
                <Typography variant='h5' sx={{ color: '#aaa' }}>
                  UPLOAD FILES
                </Typography>
                <Typography variant='subtitle' color='gray'>
                {/* (Only *.pdf, *.mp4, *.usdz, *.gltf files will be accepted) */}
                (Only *.pdf, *.png, *.jpg files will be accepted)
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          {fileInformation?.length > 0 ? (
            <List>
              {fileInformation?.map((fileObject, index) => (
                <ListItem key={fileObject.path}>
                  <ListItemText primary={fileObject.path} />
                  <DeleteIcon
                    onClick={() => {
                      const updatedAcceptedFiles = fileInformation.filter(
                        (file) => file.path !== fileObject.path
                      );
                      setFileInformation(updatedAcceptedFiles);
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ) : null}
          <DialogActions>
            <Button onClick={handleClose} color='error'>
              Cancel
            </Button>
            <Button onClick={SaveUserCertificate}>Upload</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  } else {
    return (
      <>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item>
            <label htmlFor='contained-button-file'>
              <Button
                variant='text'
                fullWidth
                component='span'
                onClick={() => setIsOpen(true)}
                startIcon={<AddRounded />}
                disabled={
                  (!permission.add && !permission?.edit) ||
                  documentType === 'all'
                }
              >
                Upload
              </Button>
            </label>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default FileUpload;
