import { Box, Chip, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { MdContentCopy, MdEmail, MdPerson, MdPhone } from 'react-icons/md';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { ChatParticipant } from './conversation.types';
import { useConversation } from './ConversationsContext';

interface CopyNotificationProps {
  position: { x: number; y: number };
}

const CopyNotification = ({ position }: CopyNotificationProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return createPortal(
    <Box
      component='div'
      sx={{
        position: 'fixed',
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: 'translate(-50%, -130%)',
        bgcolor: 'success.main',
        color: 'white',
        px: 1.5,
        py: 0.5,
        borderRadius: 1,
        fontSize: isMobile ? '0.8rem' : '0.7rem',
        fontWeight: 'medium',
        lineHeight: 1,
        boxShadow: theme.shadows[3],
        zIndex: 9999,
        pointerEvents: 'none',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          border: '4px solid transparent',
          borderTopColor: theme.palette.success.main,
        },
        animation: 'copyFadeInOut 0.8s ease-in-out forwards',
        '@keyframes copyFadeInOut': {
          '0%': {
            opacity: 0,
            transform: 'translate(-50%, -110%)',
          },
          '15%': {
            opacity: 1,
            transform: 'translate(-50%, -130%)',
          },
          '85%': {
            opacity: 1,
            transform: 'translate(-50%, -130%)',
          },
          '100%': {
            opacity: 0,
            transform: 'translate(-50%, -150%)',
          },
        },
      }}
    >
      Copied
    </Box>,
    document.body
  );
};

export function ChatParticipantChip({ participant }: { participant: Partial<ChatParticipant> }) {
  const { user } = useAuth();
  const { selectedChat, isUserOnline } = useConversation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout>();
  const [notifications, setNotifications] = useState<Array<{ id: number; x: number; y: number }>>(
    []
  );
  const notificationIdRef = useRef(0);
  const [hoveredField, setHoveredField] = useState<string | null>(null);

  const isCreator =
    selectedChat?.creator?.userSeq?.toLowerCase() === participant.userSeq?.toLowerCase();
  const isSupportAgent = user?.roleCheck(['451', '8f7']) || false;

  const handleTooltipMouseEnter = () => {
    if (!isMobile) {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
      setIsOpen(true);
    }
  };

  const handleTooltipMouseLeave = (e: React.MouseEvent) => {
    if (!isMobile) {
      const relatedTarget = e.relatedTarget as HTMLElement;
      const isChip = relatedTarget?.closest('[data-participant-chip="true"]');
      if (!isChip) {
        setIsOpen(false);
      }
    }
  };

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
      setIsOpen(true);
    }
  }, [isMobile]);

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) {
      hoverTimeoutRef.current = setTimeout(() => {
        setIsOpen(false);
      }, 100);
    }
  }, [isMobile]);

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    notifications.forEach(notification => {
      const timeout = setTimeout(() => {
        setNotifications(prev => prev.filter(n => n.id !== notification.id));
      }, 1500);
      timeouts.push(timeout);
    });

    return () => timeouts.forEach(clearTimeout);
  }, [notifications]);

  const copyToClipboard = async (text: string, event: React.MouseEvent) => {
    try {
      await navigator.clipboard.writeText(text);
      const element = event.target as HTMLElement;
      if (!element) return;

      const boxElement = element.closest('[data-copyable-field="true"]') as HTMLElement;
      if (!boxElement) return;

      const rect = boxElement.getBoundingClientRect();
      const isMobile = window.matchMedia('(max-width: 600px)').matches;

      const newNotification = {
        id: notificationIdRef.current++,
        x: isMobile ? rect.left + rect.width / 2 : window.scrollX + rect.left + rect.width / 2,
        y: isMobile ? rect.top - 10 : window.scrollY + rect.top,
      };

      setNotifications(prev => [...prev, newNotification]);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const CopyableField = ({
    icon,
    text,
    field,
  }: {
    icon: React.ReactNode;
    text: string;
    field: string;
  }) => (
    <Box
      component='div'
      data-copyable-field='true'
      onMouseEnter={() => setHoveredField(field)}
      onMouseLeave={() => setHoveredField(null)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'all 0.2s',
        p: 0.5,
        borderRadius: 1,
        position: 'relative',
        WebkitTapHighlightColor: 'transparent',
        '&:hover': {
          bgcolor: 'action.hover',
        },
        '@media (max-width: 600px)': {
          '&:active': {
            bgcolor: 'action.selected',
            transform: 'scale(0.97)',
            transition: 'transform 0.15s ease-in-out, background-color 0.15s ease-in-out',
          },
        },
      }}
      onClick={e => copyToClipboard(text, e)}
    >
      <Box component='div' sx={{ color: 'text.secondary', mr: 0.5, display: 'flex' }}>
        {icon}
      </Box>
      <Typography
        variant='body2'
        sx={{
          color: 'text.primary',
          fontSize: '0.8rem',
          wordBreak: 'break-all',
          flex: 1,
        }}
      >
        {text}
      </Typography>
      <Box
        component='div'
        sx={{
          opacity: hoveredField === field ? 1 : 0,
          transition: 'opacity 0.2s',
          ml: 1,
          display: 'flex',
          alignItems: 'center',
          color: 'text.secondary',
          '& svg': {
            fontSize: '0.9rem',
          },
        }}
      >
        <MdContentCopy size={14} />
      </Box>
    </Box>
  );

  const tooltipContent = isSupportAgent && (
    <Box
      component='div'
      data-tooltip-id={participant.userSeq}
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
      sx={{
        width: isMobile ? '100vw' : '300px',
        maxWidth: isMobile ? '100vw' : '300px',
        bgcolor: 'background.paper',
        p: 1.5,
        borderRadius: 1,
        boxShadow: t => t.shadows[3],
        border: t => `1px solid ${t.palette.divider}`,
      }}
    >
      <Typography
        sx={{
          fontSize: '0.9rem',
          fontWeight: 'bold',
          pb: 0.5,
          mb: 1,
          borderBottom: '1px solid',
          borderColor: 'divider',
          color: 'text.primary',
        }}
      >
        {participant.personFirstName} {participant.personLastName}
      </Typography>

      <Stack spacing={0}>
        <CopyableField
          icon={<MdPerson size={16} />}
          text={participant?.userName || 'N/A'}
          field='username'
        />
        <CopyableField
          icon={<MdEmail size={16} />}
          text={participant?.emailAddress || 'N/A'}
          field='email'
        />
        <CopyableField
          icon={<MdPhone size={16} />}
          text={participant?.phoneNumber || 'N/A'}
          field='phone'
        />

        <Box component='div' sx={{ mt: 1 }}>
          <Typography
            variant='body2'
            sx={{
              fontWeight: 'medium',
              mb: 0.5,
              color: 'text.primary',
              fontSize: '0.8rem',
            }}
          >
            Jurisdictions ({participant.jurisdictions?.length || 0})
          </Typography>
          <Box
            component='div'
            sx={{
              bgcolor: 'action.hover',
              p: 1,
              borderRadius: 1,
              maxHeight: '150px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[400],
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.grey[100],
                borderRadius: '4px',
              },
            }}
          >
            {participant.jurisdictions?.map(jdx => (
              <Typography
                key={jdx.jdxName}
                variant='body2'
                sx={{
                  py: 0.25,
                  color: 'text.primary',
                  fontSize: '0.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  '&:before': { content: '"•"', mr: 0.5, color: 'text.secondary' },
                }}
              >
                {jdx.jdxName}
              </Typography>
            ))}
          </Box>
        </Box>
      </Stack>
    </Box>
  );

  return (
    <>
      {notifications.map(notification => (
        <CopyNotification key={notification.id} position={notification} />
      ))}
      <Tooltip
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={tooltipContent}
        arrow
        placement={isMobile ? 'bottom' : 'bottom-start'}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -2],
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'transparent',
              '& .MuiTooltip-arrow': {
                color: 'background.paper',
                visibility: isOpen ? 'visible' : 'hidden',
              },
              p: 0,
              maxWidth: 'none',
            },
          },
          popper: {
            sx: {
              '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
                mt: -0.5,
              },
            },
          },
        }}
      >
        <Box
          component='div'
          data-participant-chip='true'
          data-participant-id={participant.userSeq}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => (isMobile ? setIsOpen(!isOpen) : undefined)}
          sx={{ position: 'relative' }}
        >
          <Chip
            key={participant.userSeq}
            label={`${participant.personFirstName} ${participant.personLastName} (${participant.userName})`}
            size='small'
            variant='outlined'
          />
          {isUserOnline(participant.userSeq || '') && (
            <Box
              component='div'
              sx={{
                position: 'absolute',
                bottom: -2,
                left: 2,
                width: 6,
                height: 6,
                bgcolor: 'success.main',
                borderRadius: '50%',
                boxShadow: t => `0 0 0 1px ${t.palette.background.paper}`,
              }}
            />
          )}
        </Box>
      </Tooltip>
    </>
  );
}

export default ChatParticipantChip;
