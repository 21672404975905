import React from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  FormHelperText,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Form } from 'formik';
import FormControl from '@mui/material/FormControl';
import authHeader from '../../../../services/auth-header';
import axios from 'axios';

const DocumentEditView = ({ editDocument, getAllData, certificateTypes, setEditDocument }) => {
  const validationSchema = Yup.object({
    userCertificateTypeSeq: Yup.string().required('Certificate type is required').nullable(),
  });

  const editCertificate = async values => {
    try {
      await axios.post(
        `${import.meta.env.VITE_API_URL}EditCertificate?photoSeq=${values.photoSeq}&certificateTypeSeq=${values.userCertificateTypeSeq}&Notes=${values.photoNotes}`,
        values,
        { headers: authHeader() }
      );
      getAllData();
      setEditDocument({});
    } catch (error) {
      console.log('error', error);
    }
  };

  const initialValues = editDocument.length ? editDocument[0] : {};

  return (
    <>
      {' '}
      {/* <Paper elevation={3} spacing={2} sx={{ padding: '16px' }}> */}
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5' component='h1'> <b>Edit Document</b></Typography>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            editCertificate(values);
          }}
        >
          {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => {
            return (
              <Form>
                {console.log(errors)}
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        required
                        id='dateTime'
                        label='Upload Date/Time'
                        variant='outlined'
                        name='dateTime'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        disabled
                        value={values?.dateTime || ''}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        required
                        id='userOrPhotographer'
                        label='User/Photographer'
                        variant='outlined'
                        name='userOrPhotographer'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        disabled
                        value={values?.photographer || ''}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12} mSd={6} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id={``}>Type</InputLabel>
                      <Select
                        labelId={`}`}
                        id={`userCertificateTypeSeq`}
                        label='Type'
                        name='userCertificateTypeSeq'
                        focus
                        value={values.userCertificateTypeSeq}
                        onChange={e =>
                          handleChange({
                            target: {
                              value: e.target.value,
                              name: 'userCertificateTypeSeq',
                            },
                          })
                        }
                      >
                        <MenuItem value=''>
                          <em>--Select Type--</em>
                        </MenuItem>

                        {certificateTypes.map(certificate => {
                          return (
                            <MenuItem
                              value={certificate.photoTypeSeq}
                              key={certificate.photoTypeSeq}
                            >
                              <em>{certificate.photoTypeName}</em>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        name={`type`}
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        id='photoLabel'
                        label='Label'
                        variant='outlined'
                        name='photoLabel'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        disabled
                        value={values?.photoLabel || ''}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        id='originalFileName'
                        label='Original File Name'
                        variant='outlined'
                        name='originalFileName'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        disabled
                        value={values?.originalFileName || ''}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        id='originalFileSize'
                        label='Original File Size'
                        variant='outlined'
                        name='originalFileSize'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        disabled
                        value={values?.originalFileSize || ''}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        // maxRows={8}
                        id='photoNotes'
                        label='Notes'
                        variant='outlined'
                        name='photoNotes'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values?.photoNotes || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <Button variant='contained' sx={{ minWidth: '125px', marginTop: '7px' }}>
                        Update
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      {/* </Paper> */}
    </>
  );
};
export default DocumentEditView;
