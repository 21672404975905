import {useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, Grid, TextField, Autocomplete, Typography, Box, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import * as Yup from 'yup';
import AddressData from './AdministrationModules/AddressComponents/AddressData';
import ContactsView from '../utils/components/ContactsView';
import { useAuth } from '../utils/auth/AuthService.tsx';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { json } from 'react-router';

const InformantDialog = ({
    isInformantDialogOpen,
    setIsInformantDialogOpen,
    selectedInformant = [], 
    setSelectedInformant = () => [], 
    handleNewContactCallback = () => {},
    showCopyAddressCheck = false,
    handleInformantSaveComplete = () => {},
}) => {
    const formik = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'));
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [identifierRelationshipOptions, setIdentifierRelationshipOptions] = useState([]);
  const [selectedContactTab, setSelectedContactTab] = useState(0);
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const hasSystemAdminRole = user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']);
  const hasMERole = user?.roleCheck(['caef1f91-c392-4b53-a466-a8f418d8d25a']);
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);
  const [contactsError, setContactsError] = useState([]);

  const id = '00000000-0000-0000-0000-000000000000';


  const handleContactTabChange = (event, newValue) => {
    setSelectedContactTab(newValue);
  };

  const dismissAlert = () => {
    setShowErrorAlert(false);
  };
  
  const handleInformantDialogClose = () => {
    setIsInformantDialogOpen(false);
    setShowErrorAlert(false);
    setValidationErrors([]);
    setSelectedContactTab(0);
  };

  const handleInformantInputChange = (newValue, fieldName) => {
    setSelectedInformant(prevInformant => {
      let updatedInformant = {
        ...prevInformant,
        [fieldName]: newValue,
        isChgInd: 1,
        nextOfKin:
          fieldName === 'relship' ? (newValue?.isNOKRelship ? 1 : 0) : prevInformant.nextOfKin,
      };
  
      if (fieldName === 'relship' && newValue?.optionSeq?.toUpperCase() !== '05AC97D0-B573-43E7-82B4-7B99764CCE94' && 
          newValue?.optionSeq?.toUpperCase() !== '6945FEDA-FAE1-4D31-A290-BE157DCF5734') {
        updatedInformant = {
          ...updatedInformant,
          otherRelship: '' 
        };
      }
  
      return updatedInformant;
    });
  };

  const informantValidation = async () => {
    try {
      const addressValidationSchema = Yup.object().shape({
        address: Yup.array().of(
          Yup.object().shape({
            addressTypeSeq: Yup.string().nullable(),
            addressLine1: Yup.string().when(['addressTypeSeq'], (addressTypeSeq, schema) => {
              return schema.test({
                name: 'addressLine1',
                test: function (value) {
                  const index = this.options?.index;
                  if (
                    addressTypeSeq[0] &&
                    addressTypeSeq[0] !== 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' &&
                    addressTypeSeq[0] !== 'eea37e74-0a50-419e-9de9-372d21d1f890' &&
                    addressTypeSeq[0] !== id
                  ) {
                    if (!value) {
                      return this.createError({
                        message: `Address ${index + 1} : Address line 1 is required`,
                        path: `address[${index}].addressLine1`,
                      });
                    }
                  }
                  return true;
                },
              });
            }),
            city: Yup.string()
              .when(['addressTypeSeq'], (addressTypeSeq, schema) => {
                return schema.test({
                  name: 'city',
                  test: function (value) {
                    const index = this.options.index;
                    if (
                      addressTypeSeq[0] &&
                      addressTypeSeq[0] !== 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' &&
                      addressTypeSeq[0] !== 'eea37e74-0a50-419e-9de9-372d21d1f890' &&
                      addressTypeSeq[0] !== id
                    ) {
                      if (!value) {
                        return this.createError({
                          message: `Address ${index + 1} : City is required`,
                          path: `address[${index}].city`,
                        });
                      }
                    }
                    return true;
                  },
                });
              })
              .when(['addressLine1'], (addressLine1, schema) => {
                return schema.test({
                  name: 'city',
                  test: function (value) {
                    const index = this.options.index;
                    if (addressLine1[0] && addressLine1[0] !== '' && addressLine1[0]?.toLowerCase() !== 'unknown' && addressLine1[0]?.toLowerCase() !== 'homeless') {
                      if (!value) {
                        return this.createError({
                          message: `Address ${index + 1} : City is required`,
                          path: `address[${index}].city`,
                        });
                      }
                    }
                    return true;
                  },
                });
              }),
          })
        ),
      });

      const mainFormValidationSchema = Yup.object().shape({
        relship: Yup.string().required('Relationship is required'),
        personLastName: Yup.string().when(['personFirstName'], (personFirstName, schema) => {
          return personFirstName
            ? schema
            : schema.required('Either Last Name or First Name is required');
        }),
        personFirstName: Yup.string().test(
          'exclusive-name',
          'Either Last Name or First Name is required',
          function (value) {
            const lastName = this.parent.personLastName;
            if (!value && !lastName) {
              return false;
            }
            return true;
          }
        ),
      });

      const validationSchema = Yup.object().shape({
        ...mainFormValidationSchema.fields,
        ...addressValidationSchema.fields,
      });

      const dataToValidate = {
        relship: selectedInformant?.relship?.optionSeq || '',
        personLastName: selectedInformant?.personLastName || '',
        personFirstName: selectedInformant?.personFirstName || '',
        address: selectedInformant?.informantAddress?.address || [],
      };

      await validationSchema.validate(dataToValidate, {
        abortEarly: false,
      });

      setShowErrorAlert(false);
      setValidationErrors([]);
      return true;
    } catch (error) {
      setValidationErrors(error.errors);
      setShowErrorAlert(true);
      return false;
    }
  };


  const handleInformantSave = async (informant) => {
    let formData = new FormData();
        formData.append('informant', JSON.stringify(informant));
        formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
        formData.append('decedentPersonSeq', formik.values?.caseDemographics?.decedentPerson?.personSeq);

    if (user && user.token) {
      fetch(VITE_API_URL + 'updateInformant', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then((res) => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then((data) => {
          formik.setFieldValue('caseDemographics.informantList', data);
          handleInformantSaveComplete('success', 'Contact saved successfully!');
        })
        .catch((e) => {
          console.error(e);
          handleInformantSaveComplete('error', 'Failed to save contact. Please try again.');
        })
        .finally(() => {       

        });
    } else {
      console.log('user or token is null');
    }
    return false;
  };


  const handleInformantSubmit = async () => {
    const isValid = await informantValidation();

    if (!isValid) {
      return;
    }

    if (selectedInformant.informantSeq) {
      await handleInformantSave(selectedInformant);
    } else {
      // Add new Informant
      const newInformant = {
        ...selectedInformant,
        informantSeq: uuidv4(),
        personSeq: uuidv4(),
        personContactInfoSeq: uuidv4(),
        isActive: 1,
        isReporter: 1,
        isNewInd: 1,
        isChgInd: 1,
        caseReporter:0,
        createdOn: format(new Date(), 'yyyy-MM-dd HH:mm'),
      };
      await handleInformantSave(newInformant);
      handleNewContactCallback(newInformant);
    }
    setIsInformantDialogOpen(false);
    setSelectedInformant([]);
  };

  //Handle Informat Address
  const handleAddressChange = data => {
    setSelectedInformant(prev => ({
      ...prev,
      isChgInd: 1,
      informantAddress: {
        ...prev.informantAddress,
        address: data,
      },
    }));
  };

  //Handle Informat Contact Info
  const handleContactChange = data => {
    setSelectedInformant(prev => ({
      ...prev,
      isChgInd: 1,
      informantAddress: {
        ...prev.informantAddress,
        contactItem: data,
      },
    }));
  };

  const fetchIdentifierRelship = async () => {
    fetch(VITE_API_URL + 'getidentifierrelationshipoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setIdentifierRelationshipOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };


  useEffect(() => {
    fetchIdentifierRelship();
    setSelectedContactTab(0);
  }, []);


  return (
    <Dialog
      open={isInformantDialogOpen}
      onClose={handleInformantDialogClose}
      PaperProps={{
        style: {
          minWidth: isMobile ? '95%' : isLaptop ? '80%' : '50%',
          margin: '0 auto',
        },
      }}
    >
      <DialogTitle>
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>Add/Edit Contact</strong>
        </Typography>
        {showErrorAlert && (
          <div style={{ paddingTop: '1rem' }}>
            <Alert severity='error' isOpen={showErrorAlert} onClose={dismissAlert}>
              {Object.keys(validationErrors).map(fieldName => (
                <div style={{ fontSize: '14px' }}>
                  <li key={fieldName}> {validationErrors[fieldName]}</li>
                </div>
              ))}
            </Alert>
          </div>
        )}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label='Last Name'
              name='personLastName'
              type='text'
              size='small'
              value={selectedInformant?.personLastName || ''}
              onChange={e => {
                handleInformantInputChange(e.target.value, 'personLastName');
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label='First Name'
              name='personFirstName'
              type='text'
              size='small'
              value={selectedInformant?.personFirstName || ''}
              onChange={e => {
                handleInformantInputChange(e.target.value, 'personFirstName');
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4} md={1}>
            <TextField
              label='Suffix'
              name='suffix'
              type='text'
              size='small'
              value={selectedInformant?.suffix || ''}
              onChange={e => {
                handleInformantInputChange(e.target.value, 'suffix');
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <TextField
              label='Middle Name'
              name='personMiddleName'
              type='text'
              size='small'
              value={selectedInformant?.personMiddleName || ''}
              onChange={e => {
                handleInformantInputChange(e.target.value, 'personMiddleName');
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Autocomplete
              id='relship'
              size='small'
              options={identifierRelationshipOptions}
              value={selectedInformant?.relship || null}
              onChange={(event, newValue) => {
                delete formik.errors.relship;
                handleInformantInputChange(newValue, 'relship');
              }}
              getOptionLabel={(option) => {
                if (
                  option.optionSeq?.toUpperCase() === "05AC97D0-B573-43E7-82B4-7B99764CCE94" ||
                  option.optionSeq?.toUpperCase() === "6945FEDA-FAE1-4D31-A290-BE157DCF5734"
                ) {
                  const originalOption = identifierRelationshipOptions.find(
                    (item) => item?.optionSeq?.toUpperCase() === option?.optionSeq?.toUpperCase()
                  );
                  return originalOption ? originalOption?.optionName : '';
                }
                return option.optionName;
              }}
              isOptionEqualToValue={(option, value) =>
                option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <div>
                      {'Relationship To Decedent '}
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position='end' sx={{ width: '60%', padding: 0, margin: 0 }}>
                        {params.InputProps.endAdornment}
                        {(selectedInformant?.relship?.optionSeq?.toUpperCase() === '05AC97D0-B573-43E7-82B4-7B99764CCE94' ||
                          selectedInformant?.relship?.optionSeq?.toUpperCase() === '6945FEDA-FAE1-4D31-A290-BE157DCF5734') && (
                          <TextField
                            fullWidth
                            size='small'
                            id='otherRelship'
                            name='otherRelship'
                            placeholder='Other Relationship'
                            variant='standard'
                            value={selectedInformant?.otherRelship || ''}
                            onChange={(e) => handleInformantInputChange(e.target.value, 'otherRelship')}                           
                            onClick={(e) => {
                              e.stopPropagation(); 
                              e.target.focus(); 
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <ContactsView
                contacts={selectedInformant?.informantAddress?.contactItem || []}
                handleChange={data => handleContactChange(data)}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <AddressData
              isMultipleOn={true}
              handleChange={data => handleAddressChange(data)}
              addresses={selectedInformant?.informantAddress?.address || []}
              title='Address'
              isDefaultState={false}
              showCopyAddressCheck = {showCopyAddressCheck}
            />
          </Grid>
        </Grid>
        <DialogActions style={{ justifyContent: 'right', marginTop: '1rem' }}>
          <Button onClick={handleInformantSubmit} variant='contained'>
            Submit
          </Button>
          <Button color='error' onClick={handleInformantDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default InformantDialog;