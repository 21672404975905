import InfoIcon from '@mui/icons-material/Info';
import authHeader from '../../../services/auth-header';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  Slide,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NavLink } from 'reactstrap';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ReasonForInvestigationView() {
  const { user } = useAuth();
  const [userReady, setuserReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [results, setResults] = useState([]);
  const [reasonForInvestigationOptions, setReasonForInvestigationOptions] = useState([]);
  const [reasonForInvestigation, setReasonForInvestigation] = useState([]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [dateRangeAlertVisible, setDateRangeAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  // Create formik object here
  const formik = useFormik({
    initialValues: {
      jurisdictions: [],
      reportedDateRange: [null, null],
      reasonsforinvestigation: [],
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const shortcutsItems = [
    // {
    //   label: 'This Week',
    //   getValue: () => {
    //     const today = dayjs();
    //     return [today.startOf('week'), today.endOf('week')];
    //   },
    // },
    // {
    //   label: 'Last Week',
    //   getValue: () => {
    //     const today = dayjs();
    //     const prevWeek = today.subtract(7, 'day');
    //     return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    //   },
    // },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: 'Last Month',
      getValue: () => {
        const today = dayjs();
        const endOfLastMonth = today.startOf('month').subtract(1, 'day');
        return [endOfLastMonth.startOf('month'), endOfLastMonth];
      },
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(30, 'day'), today];
      },
    },
    {
      label: 'Last 6 Months',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(6, 'month'), today];
      },
    },
    {
      label: 'Last Year',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(1, 'year'), today];
      },
    },
    {
      label: 'Reset',
      getValue: () => [null, null],
    },
  ];

  //Update columns with desired fields
  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 155,
      editable: false,
      renderCell: params => {
        const caseID = params.formattedValue || 'Unknown CaseID'; // Fallback
        return (
          <Button
            color='secondary'
            variant='text'
            onClick={event => {
              handleCaseClick(event, params);
            }}
            disabled={caseID === 'Unknown CaseID'} // Optionally disable if CaseID is invalid
          >
            <b>{caseID}</b>
          </Button>
        );
      },
    },
    {
      field: 'meaction',
      headerName: 'ME Action',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'reasonforinvestigation',
      headerName: 'Reason',
      flex: 1,
      minWidth: 180,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent Name',
      flex: 1,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'jdxname',
      headerName: 'Jurisdiction',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'race',
      headerName: 'Race',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'causeofdeath',
      headerName: 'Cause of Death',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'mannerofdeath',
      headerName: 'Manner of Death',
      flex: 1,
      minWidth: 180,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'menotified',
      headerName: 'Case Reported On',
      valueGetter: value => {
        return value || '';
      },
      valueFormatter: value => {
        const dateValue = value;
        if (dateValue) {
          // Parse the string into a Date object
          const date = new Date(dateValue);
          // Format the date using date-fns or any other formatting method
          return format(date, 'MM-dd-yyyy HH:mm'); // Adjust to your desired format
        }
        return ''; // Return empty string for null or undefined values
      },
      width: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  const { VITE_API_URL } = import.meta.env;

  //Update change functions to support all parameters
  const handleJdxChange = (event, data) => {
    formik.setFieldValue('jurisdictions', data);
  };

  const handleReasonForInvestigationChange = (event, data) => {
    formik.setFieldValue('reasonsforinvestigation', data);
  };

  //-------------------------------------------------

  const dismissDateAlert = () => {
    setDateRangeAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const handleSelectAndDeselectAllJurisdictions = () => {
    if (JSON.stringify(formik.values?.jurisdictions) == JSON.stringify(authorizedJdxs)) {
      formik.setFieldValue('jurisdictions', []);
    } else {
      formik.setFieldValue('jurisdictions', authorizedJdxs);
    }
  };

  const UlComponent = (props) => <ul {...props} />;

  //Update to clear all available search parameters
  const clearAll = () => {
    formik.resetForm();
    setResultsVisible(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    let formData = new FormData();
    var fieldRequired = false;

    console.log(formik.values?.reportedDateRange);

    if (formik.values?.jurisdictions.length == 0) {
      setJdxAlertVisible(true);
      fieldRequired = true;
    } else if (formik.values?.reportedDateRange.every(elem => elem === null)) {
      setDateRangeAlertVisible(true);
      fieldRequired = true;
    } else if (
      formik.values?.reportedDateRange.every(elem => elem !== null) &&
      formik.values?.reportedDateRange[1].diff(formik.values?.reportedDateRange[0], 'year', true) >
        1
    ) {
      setDateRangeAlertVisible(true);
      fieldRequired = true;
    }

    formData.append('qryParams', JSON.stringify(formik.values));

    if (fieldRequired) return;

    setloading(true);
    setResultsVisible(true);
    setJdxAlertVisible(false);
    setDateRangeAlertVisible(false);

    if (user && user.token) {
      await fetch(VITE_API_URL + 'reasonforinvestigationreport', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
          // 'Content-Type': 'application/json',
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setResults(data);
          setloading(false);
        })
        .catch(e => {
          console.log(formData);
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchReasonForInvestigationOptions = async () => {
      await fetch(import.meta.env.VITE_API_URL + 'getreasonforinvestigationoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setReasonForInvestigationOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    fetchReasonForInvestigationOptions();

    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);

    setuserReady(true);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Reason For Investigation Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This report provides a list of cases marked with the selected Reason(s) For Investigations'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <CardContent>
        <Slide direction='right' in={dateRangeAlertVisible} mountOnEnter unmountOnExit>
          <Alert severity='warning' onClose={dismissDateAlert}>
            The Reported on field range has a maximum 1 year range or a specific year dropdown must
            be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least one jurisdiction.
          </Alert>
        </Slide>

        <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

        <Grid container spacing={2}>
          <Grid xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs.sort((a, b) => a.jdxName.localeCompare(b.jdxName))}
              value={formik.values?.jurisdictions}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => {
                if (
                  option.jdxName ==
                  authorizedJdxs.sort((a, b) => a.jdxName.localeCompare(b.jdxName))[0].jdxName
                ) {
                  return (
                    <Stack>
                      <Button
                        sx={{
                          backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#cccccc',
                          color: theme.palette.text.primary,
                          p: 1,
                          m: 0.5,
                          boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)',
                        }}
                        onClick={handleSelectAndDeselectAllJurisdictions}
                      >
                        {JSON.stringify(formik.values?.jurisdictions) ==
                        JSON.stringify(authorizedJdxs) ? (
                          <Typography variant='button'>Deselect All</Typography>
                        ) : (
                          <Typography variant='button'>Select All</Typography>
                        )}
                      </Button>
                      <li {...props}>
                        <Checkbox
                          icon={<MdCheckBoxOutlineBlank />}
                          checkedIcon={<MdOutlineCheckBox />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.jdxName}
                      </li>
                    </Stack>
                  );
                }
                return (
                  <li {...props}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.jdxName}
                  </li>
                );
              }}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={UlComponent}
            />
          </Grid>

          <Grid xs={16} md={8}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                // id='reportedDateRange'
                name='reportedDateRange'
                value={formik.values?.reportedDateRange}
                onChange={data => formik.setFieldValue('reportedDateRange', data)}
                localeText={{ start: 'Reported From', end: 'Reported To' }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          {/* <Grid xs={12} md={6}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Case Reported From'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid xs={12} md={6}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Case Reported To'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid> */}

          <Grid xs={12} md={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              id='reasonsforinvestigation'
              options={reasonForInvestigationOptions}
              value={formik.values?.reasonsforinvestigation}
              size='small'
              fullWidth
              getOptionLabel={option => option.optionName.toString()}
              onChange={(event, data) => handleReasonForInvestigationChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Reason(s) for Investigation'
                  placeholder='Reason(s) for Investigation'
                />
              )}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={results}
            idcolumn='caseid'
            columnsInput={columns}
            title='Reason for Investigation Report' // Change to report name
            casecount
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've listed the detailed case records below, you can export the list to csv
                  format.
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
