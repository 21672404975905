import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { AddressFromAutocomplete } from '../../types/AddressFromAutocomplete.type';
import { useAuth } from '../../utils/auth/AuthService';
import CustomPagination from '../../utils/components/CustomPagination';
import { useIncident } from './IncidentContext';
import { FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { CallCenterInterfaceForm } from './CallCenterInterface';
import { Add, Delete } from '@mui/icons-material';
import { DVCSAlert } from './DVCSAdminView';
import ScrollingAlert from '../../components/ScrollingAlert';

export interface GradingResponse {
  gradingAnswerSeq: string;
  gradingQuestionSeq: string | null;
  responseText: string;
}

export interface MissingPerson {
  missingPersonSeq: string;
  incidentSeq: string;
  status: string;

  // Demographics Information
  firstName: string;
  lastName: string;
  alsoKnownAs: string;
  age: string;
  dateOfBirth: Dayjs | null;
  race: Option | null;
  raceOther: string;
  gender: Option | null;
  genderOther: string;
  heightInFeet: string;
  heightInInches: string;
  weightInPounds: string;
  eyeColor: Option | null;
  eyeColorOther: string;
  hairColor: Option | null;
  hairColorOther: string;
  primaryLanguage: Option | null;
  primaryLanguageOther: string;

  // Additional Information
  isLastKnownLocationAddress: boolean;
  lastKnownLocationText: string;
  lastKnownLocationAddress: AddressFromAutocomplete;
  residenceAddress: AddressFromAutocomplete;
  dateLastSeen: Dayjs | null;
  victimPhoneNumber: string;
  callerName: string;
  relationshipToCaller: Dayjs | null;
  relationshipToCallerOther: string;
  identifyingFeatures: string;
  clothingDescription: string;
  accessories: string;
  medicationsNeeded: string;
  otherInformation: string;
  contactNumber: string;

  // Grading Responses
  gradingResponses: GradingResponse[];
}

const handleAdd = (event: any, id: any) => {
  event.stopPropagation();
  console.log('Add clicked for ID:', id);
};

const handleDelete = (event: any, id: any) => {
  event.stopPropagation();
  console.log('Delete clicked for ID:', id);
};

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'age', headerName: 'Age', width: 200 },
  { field: 'gender', headerName: 'Gender', width: 200 },
  { field: 'race', headerName: 'Race', width: 200 },
  { field: 'lastKnownLocation', headerName: 'Last Known Location', width: 200 },
  {
    field: 'dateLastSeen',
    headerName: 'Date Last Seen',
    width: 200,
    valueFormatter: (value: string) => {
      return value ? dayjs(new Date(value)).format('MM/DD/YYYY') : '';
    },
  },
  {
    field: 'actions',
    headerName: 'Actions (IN DEVELOPMENT)',
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Stack direction='row' spacing={2} alignItems='center' sx={{ height: '100%' }}>
        <Tooltip title='Create Case'>
          <IconButton
            size='small'
            onClick={event => handleAdd(event, params.row.id)}
            color='primary'
          >
            <Add fontSize='small' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton
            size='small'
            onClick={event => handleDelete(event, params.row.id)}
            color='error'
          >
            <Delete fontSize='small' />
          </IconButton>
        </Tooltip>
      </Stack>
    ),
  },
];

const formatMissingPersonsData = (data: MissingPerson[]): any[] => {
  return data.map(person => {
    const name = () => {
      if (person.lastName && person.firstName && person.alsoKnownAs) {
        return `${person.lastName}, ${person.firstName} (${person.alsoKnownAs})`;
      }
      if (person.lastName && person.firstName) {
        return `${person.lastName}, ${person.firstName}`;
      }
      if (person.lastName) {
        return person.lastName;
      }
      if (person.firstName) {
        return person.firstName;
      }
      return '';
    };

    const lastKnownLocation =
      person.lastKnownLocationAddress !== null
        ? [
            person.lastKnownLocationAddress?.data?.addressLine1,
            person.lastKnownLocationAddress?.data?.addressLine2,
            person.lastKnownLocationAddress?.data?.cityTown,
            person.lastKnownLocationAddress?.data?.state?.optionName,
          ]
            .filter(Boolean)
            .join(' ')
        : person.lastKnownLocationText;

    return {
      id: person.missingPersonSeq,
      name: name(),
      age: person.age,
      gender: person.gender?.optionName ?? '',
      race: person.race?.optionName ?? '',
      lastKnownLocation: lastKnownLocation || 'Unknown',
      // dateLastSeen: person.dateLastSeen?.format('MM/DD/YYYY') ?? '',
      dateLastSeen: person.dateLastSeen,
      status: person.status,
    };
  });
};

const MissingPersons = () => {
  const { incident } = useIncident();
  const { VITE_API_URL } = import.meta.env;
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const { user } = useAuth();
  const [loadingMissingPersons, setLoadingMissingPersons] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState([]);

  const [raceOptions, setRaceOptions] = useState<Option[]>([]);
  const [genderOptions, setGenderOptions] = useState<Option[]>([]);
  const [eyeColorOptions, setEyeColorOptions] = useState<Option[]>([]);
  const [hairColorOptions, setHairColorOptions] = useState<Option[]>([]);
  const [languageOptions, setLanguageOptions] = useState<Option[]>([]);
  const [relationshipOptions, setRelationshipOptions] = useState<Option[]>([]);
  const [isLastKnownLocationAddress, setIsLastKnownLocationAddress] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'));
  const [alert, setAlert] = useState<DVCSAlert>({ alertText: '', alertSeverity: 'info' });

  const fetchAlert = async () => {
    await fetch(VITE_API_URL + `dvcs/getalert`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAlert(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchAlert();
  }, []);

  const formik = useFormik({
    initialValues: {
      // Demographics
      missingPersonSeq: uuidv4(),
      firstName: '',
      lastName: '',
      alsoKnownAs: '',
      age: '',
      dateOfBirth: null,
      race: null,
      raceOther: '',
      gender: null,
      genderOther: '',
      heightInFeet: '',
      heightInInches: '',
      weightInPounds: '',
      eyeColor: null,
      eyeColorOther: '',
      hairColor: null,
      hairColorOther: '',
      primaryLanguage: null,
      primaryLanguageOther: '',

      // Additional Information
      isLastKnownLocationAddress: false,
      lastKnownLocationText: '',
      lastKnownLocationAddress: {
        addressText: null,
        data: {
          addressLine1: null,
          addressLine2: null,
          address: null,
          aptSuite: null,
          floor: null,
          cityTown: null,
          state: null,
          zipCode: null,
          country: null,
        },
      },
      residenceAddress: {
        addressText: null,
        data: {
          addressLine1: null,
          addressLine2: null,
          address: null,
          aptSuite: null,
          floor: null,
          cityTown: null,
          state: null,
          zipCode: null,
          country: null,
        },
      },
      callerName: '',
      relationshipToCaller: null,
      relationshipToCallerOther: '',
      dateLastSeen: null,
      identifyingFeatures: '',
      clothingDescription: '',
      accessories: '',
      medicationsNeeded: '',
      otherInformation: '',
      contactNumber: '',
      status: 'Missing',
    },

    onSubmit: async values => {
      await saveDetails(values);
    },
  });

  useEffect(() => {
    const fetchRaceOptions = async () => {
      fetch(VITE_API_URL + 'getraceoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setRaceOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchGenderOptions = async () => {
      fetch(VITE_API_URL + 'getgenderoptionswithseq', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setGenderOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchEyeColorOptions = async () => {
      fetch(VITE_API_URL + 'geteyecoloroptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setEyeColorOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchHairColorOptions = async () => {
      fetch(VITE_API_URL + 'gethaircoloroptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setHairColorOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchLanguageOptions = async () => {
      fetch(VITE_API_URL + 'getlanguageoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setLanguageOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    const fetchRelationshipOptions = async () => {
      fetch(VITE_API_URL + 'gettypicalrelationshipoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setRelationshipOptions(data);
        })
        .catch(e => {
          //alert(e);
        });
    };

    fetchRaceOptions();
    fetchGenderOptions();
    fetchEyeColorOptions();
    fetchHairColorOptions();
    fetchLanguageOptions();
    fetchRelationshipOptions();
  }, []);

  const saveDetails = async (values: any) => {
    setLoadingSubmit(true);

    const updatedValues = { ...values, incidentSeq: incident?.incidentGroupSeq };

    try {
      const response = await fetch(VITE_API_URL + 'dvcs/logmissingperson', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`, // Assuming you store the JWT in localStorage
        },
        body: JSON.stringify(updatedValues),
      });

      if (!response.ok) {
        throw new Error(`Failed to save details: ${response.statusText}`);
      }

      const result = await response.json();
      setShowSuccess(true);
      return result;
    } catch (error) {
      console.error('Error saving details:', error);
      throw error;
    } finally {
      setLoadingSubmit(false);
    }
  };

  const fetchMissingPersons = async () => {
    setLoadingMissingPersons(true);

    if (!incident) {
      setFormattedData([]);
      setLoadingMissingPersons(false);
      return;
    }

    await fetch(VITE_API_URL + `dvcs/getmissingpersons?IncidentSeq=${incident?.incidentGroupSeq}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);

        setData(data);
        setFormattedData(formatMissingPersonsData(data));
        setLoadingMissingPersons(false);
      })
      .catch(e => {
        console.error(e);
        setLoadingMissingPersons(false);
      });
  };

  useEffect(() => {
    fetchMissingPersons();
  }, [incident]);

  const handleRowClick = (params: GridRowParams) => {
    const selectedRow = data.find((row: MissingPerson) => row.missingPersonSeq === params.id);
    if (selectedRow) {
      formik.setValues(selectedRow);
    }
    setDialogOpen(true);
  };

  return (
    <Box component='div' sx={{ p: 2 }}>
      {alert.alertText !== '' && (
        <Box component='div' sx={{ mb: 2 }}>
          <ScrollingAlert
            severity={alert.alertSeverity}
            message={alert.alertText ?? ''}
            speed={4}
          />
        </Box>
      )}

      <Slide direction='right' in={incident === null} mountOnEnter unmountOnExit>
        <Alert severity='info' sx={{ mb: 2, alignItems: 'center' }}>
          <Typography>Please select an incident to view missing persons.</Typography>
        </Alert>
      </Slide>

      <Card>
        <CardHeader
          title='Missing Persons'
          subheader={`Active Incident: ${
            incident === null ? 'None' : `${incident?.incidentDescription} (${incident?.groupID})`
          }`}
        />
        <Divider sx={{ opacity: 0.8 }} />
        <CardContent>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPremium
              loading={loadingMissingPersons}
              rows={formattedData}
              columns={columns}
              onRowClick={handleRowClick}
              getRowId={row => row.id}
              slots={{
                pagination: CustomPagination,
                // noRowsOverlay: () => <></>,
              }}
              slotProps={{
                loadingOverlay: {
                  variant: 'skeleton',
                  noRowsVariant: 'skeleton',
                },
              }}
            />
          </div>
        </CardContent>
      </Card>

      <FormikProvider value={formik}>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          PaperProps={{
            style: {
              minWidth: isMobile ? '95%' : isLaptop ? '70%' : '50%',
              margin: '0 auto',
              minHeight: '400px', // Stabilized height
            },
          }}
        >
          <CallCenterInterfaceForm
            formik={formik}
            loadingSubmit={loadingSubmit}
            raceOptions={raceOptions}
            genderOptions={genderOptions}
            languageOptions={languageOptions}
            eyeColorOptions={eyeColorOptions}
            hairColorOptions={hairColorOptions}
            relationshipOptions={relationshipOptions}
            onSubmit={async () => {
              await fetchMissingPersons();
              setDialogOpen(false);
            }}
          />
        </Dialog>
      </FormikProvider>
    </Box>
  );
};

export default MissingPersons;
