/**
 * TO DO: userRoles, requiredRole interfaces
 * @param userRoles
 * @param requiredRole
 * @returns
 */

import { User } from '../../types/User';

// @ts-ignore
export function roleCheck(userRoles, requiredRole) {
  var found = false;
  if (userRoles == null) return false;

  // @ts-ignore
  userRoles.forEach(role => {
    if (role.roleName == requiredRole) {
      found = true;
    }

    if (role?.roleCode === requiredRole) {
      found = true;
    }
  });
  return found;
}

const { VITE_API_URL } = import.meta.env;

export const hasRole = async (user: User, requiredRole: string) => {
  try {
    const endpoint = `${VITE_API_URL}user/rolecheck`;
    const body = {
      access_token: user.token,
      userSeq: user.userSeq,
      role: requiredRole,
    };

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    const json = await response.json();
    const data = json.data;

    if (response.ok && data) {
      if (user.token !== data.access_token) {
        return false;
      }

      if (user.userSeq !== data.userSeq) {
        return false;
      }

      if (requiredRole !== data.requestedRole) {
        return false;
      }

      if (data.hasRole) {
        return true;
      }
    }
  } catch (error) {
    console.error(error);
    return false;
  }

  return false;
};
