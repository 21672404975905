import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthService';

interface Option {
  isActive?: boolean;
  optionName: string;
  optionSeq: string;
  jdxCodes: string;
}

export type OptionTypes =
  | 'morgue techs'
  | 'morgue tech options'
  | 'exam types'
  | 'pathologists'
  | 'pathologist options'
  | 'investigators'
  | 'investigator options'
  | 'administrative assistants'
  | 'administrative assistant options'
  | 'agency types'
  | 'case reporter type options'
  | 'medical facility optionns'
  | 'law enforcement options'
  | 'nursing home options'
  | 'funeral home options'
  | 'me action types'
  | 'investigative reason options'
  | 'livery options'
  | 'livery service user options'
  | 'no livery reason options'
  | 'livery item options'
  | 'me action options';

interface OptionsConfig {
  jdxSeq?: string[];
  shouldFetch?: boolean;
  isActive?: boolean;
}
const { VITE_API_URL } = import.meta.env;

export function useOptions(optionType: OptionTypes, config?: OptionsConfig) {
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | any>(null);

  function buildJdxSeq(jdxSeq: string | string[] | null | undefined): string | null {
    if (!jdxSeq) {
      return null;
    }

    if (Array.isArray(jdxSeq)) {
      return jdxSeq.map(seq => `jdxList=${encodeURIComponent(seq)}`).join('&');
    } else {
      return `jdxList=${encodeURIComponent(jdxSeq)}`;
    }
  }

  const endpoint = () => {
    switch (optionType) {
      case 'exam types':
        return 'getexamtypeoptions';
      case 'morgue techs':
        return 'getmorguetechoptions';
      case 'pathologists':
        return 'getpathologistoptions';
      case 'investigators':
        return 'getinvestigatoroptions';
      case 'administrative assistants':
        return 'getadministrativeassistantsoptions';
      case 'agency types':
        return 'getagencytypeoptions';
      case 'case reporter type options':
        return 'getcasereportertypeoptions';
      case 'me action types':
        return 'getmeactiontypeoptions';
      case 'funeral home options':
        return `getfuneralhomeoptions?jdxSeq=${config?.jdxSeq}`;
      case 'law enforcement options':
        return `getlawenforcementoptions?jdxSeq=${config?.jdxSeq}`;
      case 'medical facility optionns':
        return `gethcfoptions?jdxSeq=${config?.jdxSeq}`;
      case 'nursing home options':
        return `getnursinghomeoptions?jdxSeq=${config?.jdxSeq}`;
      case 'investigative reason options':
        return 'getinvestigationreasonoptions';
      case 'livery options':
        return 'getliveryoptions';
      case 'livery service user options':
        return `getliveryuseroptions?jdxSeq=${config?.jdxSeq}`;
      case 'no livery reason options':
        return 'getnoliveryreasonoptions';
      case 'livery item options':
        return 'getitemoptions';
      case 'me action options':
        return 'getmeactionoptions';
      case 'morgue tech options':
        return `get-mortuarytech-options?${buildJdxSeq(config?.jdxSeq)}`;
      case 'pathologist options':
        return `get-pathologist-options?${buildJdxSeq(config?.jdxSeq)}${config?.isActive === false ? "&isActive=false" : ""}`;
      case 'investigator options':
        return `get-investigator-options?${buildJdxSeq(config?.jdxSeq)}${config?.isActive === false ? "&isActive=false" : ""}`;
      case 'administrative assistant options':
        return `get-administrativeassistant-options?${buildJdxSeq(config?.jdxSeq)}`;
      default:
        return null;
    }
  };

  const fetchOptions = async () => {
    try {
      setLoading(true);
      setError(null);

      if (endpoint() === null) {
        setOptions([]);
        setLoading(false);
        return;
      }
      const response = await fetch(`${VITE_API_URL}${endpoint()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);
      const options = await response.json();
      setOptions(options);
      setLoading(false);
    } catch (error) {
      setError(`${error}`);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [optionType, config?.jdxSeq]);

  return {
    options,
    loading,
    error,
  };
}

export function useOptionsAPI<T = Option>(endpoint: string, config?: OptionsConfig) {
  const auth = useAuth();
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchOptions = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      if (!endpoint) {
        setOptions([]);
        return;
      }

      const response = await fetch(`${VITE_API_URL}${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
      });

      if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);

      let options = (await response.json()) as T[];

      if (options.every(isOption)) {
        const modifiedOptions = options.map(option => ({
          ...option,
          // @ts-ignore
          isActive: Boolean(option?.isActive),
        }));
        setOptions(modifiedOptions as T[]);
      } else {
        setOptions(options);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  }, [endpoint]);

  useEffect(() => {
    if (config?.shouldFetch !== false) {
      fetchOptions();
    }
  }, [endpoint, config?.shouldFetch, fetchOptions]);

  return {
    options,
    loading,
    error,
  };
}

function isOption(obj: any): obj is Option {
  return obj && typeof obj === 'object' && 'optionName' in obj && 'optionSeq' in obj;
}

export const CASE_REPORTER_TYPES = [
  { type: 'MEDICAL_FACILITY', caseReporterTypeSeq: '875C36E0-A559-4552-BA14-2C0F8368A632' },
  { type: 'NURSING_HOME', caseReporterTypeSeq: '3b758606-75d6-4068-a66d-21176a9f1c5f' },
  { type: 'FUNERAL_HOME', caseReporterTypeSeq: 'dc11f527-4cb4-4df8-9b20-22f1f2731c6e' },
  { type: 'LAW_ENFORCEMENT', caseReporterTypeSeq: '85115ed1-039f-4b89-b15c-cfcb4f6046d3' },
  { type: 'OTHER', caseReporterTypeSeq: '25439ade-f997-451a-9122-2c904dad52d9' },
];

export function getAgenciesEndpointFromCaseReporterType(
  caseReporterTypeSeq: string
): OptionTypes | null {
  if (caseReporterTypeSeq === null) return null;

  const caseReporterType = CASE_REPORTER_TYPES.find(
    type => type.caseReporterTypeSeq.toLowerCase() === caseReporterTypeSeq.toLowerCase()
  );

  if (!caseReporterType) return null;

  switch (caseReporterType.type) {
    case 'MEDICAL_FACILITY':
      return 'medical facility optionns';
    case 'FUNERAL_HOME':
      return 'funeral home options';
    case 'LAW_ENFORCEMENT':
      return 'law enforcement options';
    case 'NURSING_HOME':
      return 'nursing home options';
    case 'OTHER':
      return null;
    default:
      return null;
  }
}
