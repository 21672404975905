import LaunchIcon from '@mui/icons-material/Launch';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useState } from 'react';
import { convertSvgToPng } from '../../utils/functions/convertSvgToPng';


const propertyMapping = {
  propertyClothing: {
    item: 'clothingItem',
    other: 'otherClothing',
    additionalFields: ['color'],
  },
  propertyCurrency: { item: 'countryOfOrigin', additionalFields: ['denomination'] }, // is optional
  propertyDocument: {
    item: 'documentItem',
    other: 'otherDocument',
    additionalFields: ['description'],
  },
  propertyEvidence: {
    item: 'evidenceItem',
    other: 'otherEvidence',
    additionalFields: ['description'],
  },
  propertyJewelry: {
    item: 'jewelryItem',
    other: 'otherJewelry',
    additionalFields: ['description', 'location'],
  },
  otherPI: { item: 'personalItem', other: 'otherPropInventory', additionalFields: ['description'] },
};

const formatPersonalPropertyItems = (casedata, options) => {
  const formattedItems = [];
  for (const [category, fieldMapping] of Object.entries(propertyMapping)) {
    const items = casedata?.caseMortuary?.propertyInventory?.[category];
    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        let propertyItem = items[i][fieldMapping.item]?.optionName;

        const shouldIncludeItem = (options, item) => {
          return (
            options?.all ||
            (options?.releasedtoLE && item?.releasedtoLE) ||
            (options?.retained && item?.retained) ||
            (options?.funeralHome && item?.funeralHome)
          );
        };

        if (!propertyItem || !shouldIncludeItem(options, items[i])) {
          continue;
        }
        const otherValue = items[i][fieldMapping?.other];
        const number = items[i]?.number;

        // If the item is 'Other', replace with the value from the 'other' field
        if (propertyItem == 'Other' && otherValue) {
          propertyItem = otherValue;
        }

        // Collect additional fields: we can filter what we want to show based on the mapping
        let additionalFields = [];

        if (number) {
          additionalFields.push(number);
        }

        if (fieldMapping.additionalFields) {
          fieldMapping.additionalFields.forEach(field => {
            if (items[i][field]) {
              // console.log('the field ', items[i][field]);
              if (typeof items[i][field] === 'object') {
                additionalFields.push(items[i][field]?.optionName);
              } else {
                additionalFields.push(items[i][field]);
              }
            }
          });
        }

        // Construct the formatted string
        const formattedString =
          additionalFields.length > 0
            ? `${propertyItem}(${additionalFields.join(', ')})`
            : propertyItem;

        // Push the formatted item
        const formattedItem = {
          personalPropertyItem: formattedString,
        };
        formattedItems.push(formattedItem);
      }
    }
  }

  return formattedItems;
};

export const generateDocument = async ({ casedata, options }) => {
  try {
    // console.log('my OPTIONS', options);
    const personalPropertyItems = formatPersonalPropertyItems(casedata, options);

    // console.log('the prop items?', personalPropertyItems);
    // console.log('wbu now: ', casedata);
    const pdfDoc = new jsPDF();
    pdfDoc.setFont('Arial', 'bold');
    pdfDoc.setFontSize(10);
    pdfDoc.setLineWidth(0.5);

    // I'll just leave here if there is a need for specific property inventory
    const specificInventory = Object.keys(options).find(key => options[key] === true);

    const headerTitle = `Property Inventory`;
    pdfDoc.setFontSize(16);
    const pageWidth = pdfDoc.internal.pageSize.getWidth();
    const textWidth = pdfDoc.getTextWidth(headerTitle);
    const textX = (pageWidth - textWidth) / 2; // Center the text
    pdfDoc.text(headerTitle, textX, 15); // Draw the text at Y-position 15

    // Define the header text
    // const headerText = reportHeader[0].reportHeaderName + "\n" + reportHeader[0].reportAddress;
    // const headerLines = headerText.split('\n');

    const headerText = '';
    const headerLines = '000';

    // Calculate the starting position for the header text
    const center = pdfDoc.internal.pageSize.getWidth() / 2;

    // const headerLinePositions = [];
    // headerLines.forEach(line => {
    //   const lineWidth = pdfDoc.getTextWidth(line);
    //   const lineCenter = center - lineWidth / 2;
    //   headerLinePositions.push(lineCenter);
    // });

    // Draw the header text
    // headerLines.forEach((line, index) => {
    //   pdfDoc.text(line, headerLinePositions[index], 15 + index * 6);
    // });

    // Add the logo in the top-left corner
    // console.log('what? ', casedata?.casedata);

    if (casedata?.caseSummary.jdxLogo) {
      const logoImage = await convertSvgToPng(casedata?.caseSummary.jdxLogo);
      const logoWidth = 40; // Adjust the logo width
      const logoHeight = 40; // Adjust the logo height
      const logoStartX = 10; // Position from left
      const logoStartY = 0; // Position from top
      pdfDoc.addImage(logoImage, 'PNG', logoStartX, logoStartY, logoWidth, logoHeight);
    }

    let maxHeaderWidth = 40;
    const padding = 0.1;

    // Calculate the starting position for the form elements
    const formX = 8;
    const logoBottom = 20 + headerLines.length * 6 + padding; // Calculate the bottom position of the logo
    const formY = logoBottom + padding; // Start the form elements below the logo

    // Helper function to draw underlined value with fixed length and padding
    const drawField = (header, value, x, y, underlineLength) => {
      pdfDoc.setFont('Arial', 'normal');
      pdfDoc.setFontSize(10);
      // Draw the header and value with padding
      pdfDoc.text(header, x + maxHeaderWidth, y, { align: 'right', direction: 'rtl' });
      pdfDoc.setFont('Arial', 'bold');
      pdfDoc.text(value, x + maxHeaderWidth + padding, y);

      // Draw the underline only under the value
      pdfDoc.line(
        x + maxHeaderWidth + padding,
        y + 2,
        x + maxHeaderWidth + padding + underlineLength,
        y + 2
      );
    };

    const drawField2 = (header, value, x, y, underlineLength) => {
      // Draw the header and value with padding, starting at the position of the first field
      const headerWidth = 80;
      pdfDoc.setFont('Arial', 'normal');
      pdfDoc.text(header, x + maxHeaderWidth + headerWidth, y, {
        align: 'right',
        direction: 'rtl',
      }); // Set text direction to "rtl"
      pdfDoc.setFont('Arial', 'bold');
      pdfDoc.text(value, x + maxHeaderWidth + headerWidth + padding, y);

      // Draw the underline only under the value
      pdfDoc.line(
        x + maxHeaderWidth + headerWidth + padding,
        y + 2,
        x + maxHeaderWidth + headerWidth + padding + underlineLength,
        y + 2
      );
    };

    function drawFieldWithBox(header1, value1, header2, value2, x, y, fieldWidth) {
      const value1Width = pdfDoc.getTextWidth(value1);
      const value2Width = pdfDoc.getTextWidth(value2);
      const maxValueWidth = Math.max(value1Width, value2Width);

      const boxHeight = 17;
      const headerWidth = 80;
      const dynamicFieldWidth = maxValueWidth + 4;

      drawRectangle(
        x + maxHeaderWidth + headerWidth + padding - 10,
        y - 5,
        dynamicFieldWidth + 10,
        boxHeight
      );

      pdfDoc.setFont('Arial', 'normal');
      pdfDoc.text(header1, x + maxHeaderWidth + headerWidth, y, {
        align: 'right',
        direction: 'rtl',
      });
      pdfDoc.setFont('Arial', 'bold');
      pdfDoc.text(value1, x + maxHeaderWidth + headerWidth + padding, y);

      pdfDoc.setFont('Arial', 'normal');
      pdfDoc.text(header2, x + maxHeaderWidth + headerWidth, y + 8, {
        align: 'right',
        direction: 'rtl',
      });
      pdfDoc.setFont('Arial', 'bold');
      pdfDoc.text(value2, x + maxHeaderWidth + headerWidth + padding, y + 8);
    }

    function drawRectangle(x, y, width, height) {
      pdfDoc.rect(x, y, width, height);
    }

    // const mtNames =
    //   casedata?.caseMortuary?.morgueTech?.map(mt => mt.optionInitials).join(', ') || '';

    const mtNames = casedata?.caseMortuary?.morgueTech?.map(mt => mt.optionName).join(' / ') || '';

    const caseNum = casedata?.caseSummary?.caseNumber ?? '';

    // Draw the fields with the desired format
    drawField(
      'Name of deceased :',
      casedata.caseSummary.decedentInfo.decedentName,
      formX - 10,
      formY + 10,
      60
    );
    drawField2('Case No :', caseNum, formX, formY + 10, 40);
    drawField(
      'Seal #',
      casedata.caseMortuary?.origRecoverySealNo ?? '',
      formX - 10,
      formY + 20,
      40
    );
    drawFieldWithBox(
      'ME :',
      casedata.caseSummary.caseInformation?.physician ?? '',
      'MT :',
      mtNames ?? '',
      formX - 4,
      formY + 25,
      70
    );

    pdfDoc.setFont('Arial', 'normal');
    pdfDoc.text('Item And Description :', formX + maxHeaderWidth - 10, formY + 35, {
      align: 'right',
      direction: 'rtl',
    });
    pdfDoc.line(formX + 7, formY + 35 + 2, formX + 30, formY + 35 + 2);
    pdfDoc.setFont('Arial', 'bold');

    const tableX = formX + 10; // X-coordinate for the table
    const tableY = formY + 40; // Y-coordinate for the table
    const itemNumberCellWidth = 6;
    const itemCellWidth = 165;
    const cellHeight = 6; // Height of each cell
    pdfDoc.setLineWidth(0);

    const pageHeight = pdfDoc.internal.pageSize.getHeight();
    let currentY = tableY;

    for (let i = 0; i < personalPropertyItems.length; i++) {
      const cellValue1 = (i + 1).toString();
      const cellValue2 = personalPropertyItems[i].personalPropertyItem;

      if (currentY + cellHeight > pageHeight - 20) {
        pdfDoc.addPage();
        currentY = 10;
      }

      // Draw the first cell
      pdfDoc.rect(tableX, currentY, itemNumberCellWidth, cellHeight);
      pdfDoc.text(cellValue1, tableX + 2, currentY + 4);

      // Draw the second cell
      pdfDoc.rect(tableX + itemNumberCellWidth, currentY, itemCellWidth, cellHeight);
      pdfDoc.text(cellValue2, tableX + itemNumberCellWidth + 2, currentY + 4);

      // Move down for the next row
      currentY += cellHeight;
    }

    const lastRowYPosition = tableY + personalPropertyItems.length * cellHeight; // Y-position of the last row in the table
    const fixedGap = 20; // Fixed gap between the table and the "Released by" section

    const releaseDateTime = casedata?.caseMortuary?.bodyReleaseDateTime;
    const bodyReleaseDay = releaseDateTime ? dayjs(releaseDateTime).format('MM/DD/YYYY') : '';
    const bodyReleaseTime = releaseDateTime ? dayjs(releaseDateTime).format('HH:mm') : '';

    pdfDoc.setLineWidth(0.5);

    drawField(
      'Released by: ',
      // casedata.caseMortuary?.releasedBy ?? '',
      '',
      formX,
      lastRowYPosition + fixedGap,
      60
    );
    drawField(
      'Witnessed by: ',
      // casedata.caseMortuary?.witnessedBy ?? '',
      '',
      formX,
      lastRowYPosition + fixedGap + 10,
      60
    );
    drawField(
      'Released On Date :',
      // bodyReleaseDay ?? '',
      '',
      formX,
      lastRowYPosition + fixedGap + 20,
      40
    );
    drawField2(
      'Time :',
      // bodyReleaseTime ?? '',
      '',
      formX,
      lastRowYPosition + fixedGap + 20,
      40
    );
    // Calculate the Y-position of the bottom of the last row and define a fixed gap
    const chainOfCustodyStartY = lastRowYPosition + fixedGap + 30;
    pdfDoc.setFont('Arial', 'normal');
    pdfDoc.text('Chain Of Custody :', formX + maxHeaderWidth, chainOfCustodyStartY, {
      align: 'right',
      direction: 'rtl',
    });

    // Draw the lines and text related to Chain of Custody using the new Y-position
    pdfDoc.line(formX + 20, chainOfCustodyStartY + 2, formX + 40, chainOfCustodyStartY + 2);
    pdfDoc.text('From', formX + maxHeaderWidth + padding, chainOfCustodyStartY);
    pdfDoc.text('To', formX + maxHeaderWidth + 35 + padding, chainOfCustodyStartY);
    pdfDoc.text('Date/Time', formX + maxHeaderWidth + 70 + padding, chainOfCustodyStartY);

    // Move down for the next set of lines
    pdfDoc.line(
      formX + 20,
      chainOfCustodyStartY + 10 + 2,
      formX + 60,
      chainOfCustodyStartY + 10 + 2
    );
    pdfDoc.line(
      formX + 70,
      chainOfCustodyStartY + 10 + 2,
      formX + 110,
      chainOfCustodyStartY + 10 + 2
    );
    pdfDoc.line(
      formX + 120,
      chainOfCustodyStartY + 10 + 2,
      formX + 160,
      chainOfCustodyStartY + 10 + 2
    );

    pdfDoc.line(
      formX + 20,
      chainOfCustodyStartY + 20 + 2,
      formX + 60,
      chainOfCustodyStartY + 20 + 2
    );
    pdfDoc.line(
      formX + 70,
      chainOfCustodyStartY + 20 + 2,
      formX + 110,
      chainOfCustodyStartY + 20 + 2
    );
    pdfDoc.line(
      formX + 120,
      chainOfCustodyStartY + 20 + 2,
      formX + 160,
      chainOfCustodyStartY + 20 + 2
    );

    const pdfBlob = pdfDoc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    // pdfDoc.save('CasePI.pdf');
    const newWindow = window.open(pdfUrl, '_blank');
    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>PDF Preview</title>
            <style>
              body {
                margin: 0;
                padding: 20px;
                font-family: Arial, sans-serif;
                background-color: #f9f9f9;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              iframe {
                border: none;
                width: 80%;
                height: 90vh;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                margin-top: 20px;
              }
              #download-btn {
                padding: 10px 20px;
                margin-top: 20px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-size: 18px;
                font-weight: bold
                width: 400px; 
              }
              #download-btn:hover {
                background-color: #0056b3;
              }
            </style>
          </head>
          <body>
            <button id="download-btn">Download PDF</button>
            <iframe src="${pdfUrl}#zoom=page-fit&view=FitH&toolbar=0" type="application/pdf"></iframe>
            <script>
              document.getElementById('download-btn').onclick = function() {
                const a = document.createElement('a');
                a.href = '${pdfUrl}';
                a.download = '${caseNum}-Property Inventory.pdf'
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              };
            </script>
          </body>
        </html>
      `);
      newWindow.document.close();
    }
  } catch (error) {
    console.error('Error while generating or downloading PDF:', error);
  }
};

export const PrintPropertyInventoryButton = ({ casedata }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('all');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleConfirm = () => {
    const optionsMap = {
      releasedtoLE: { releasedtoLE: true },
      funeralHome: { funeralHome: true },
      retained: { retained: true },
      all: { all: true },
    };

    const selectedOptions = optionsMap[selectedOption];

    if (selectedOptions) {
      generateDocument({
        casedata,
        options: selectedOptions,
      });
    }

    handleClose();
  };

  return (
    <>
      <Button
        variant='contained'
        color='info'
        style={{ width: '100%' }}
        onClick={handleClickOpen}
        startIcon={<LaunchIcon />}
      >
        PRINT PROPERTY INVENTORY
      </Button>

      {/* Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Property Inventory Form</DialogTitle>
        <DialogContent>
          <RadioGroup name='property-options' value={selectedOption} onChange={handleOptionChange}>
            <FormControlLabel value='releasedtoLE' control={<Radio />} label='Released To LE' />
            <FormControlLabel value='funeralHome' control={<Radio />} label='Funeral Home' />
            <FormControlLabel value='retained' control={<Radio />} label='Retained' />
            <FormControlLabel value='all' control={<Radio />} label='All' />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrintPropertyInventoryButton;
