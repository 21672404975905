import { Box, Grid, SxProps, TextField, TextFieldProps, Theme, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import { useAuth } from '../auth/AuthService';
import { AllRoles } from '../constants/AllRoles';

interface FormikTextFieldProps extends Omit<TextFieldProps, 'name' | 'error'> {
  label: React.ReactNode;
  formikField: string;
  authorizedToEdit: ((allRoles: typeof AllRoles) => string[]) | boolean;
  disabled?: boolean;
  value?: string;
  forceUpdateLocalStateWhenValueChanges?: boolean;
  gridProps?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    sx?: SxProps<Theme>;
  };
  onTextChange?: (
    value: string | null,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  maxCharacterLimit?: number;
}

export const FormikTextField: React.FC<FormikTextFieldProps> = ({
  label,
  formikField,
  authorizedToEdit,
  id = formikField,
  onTextChange,
  gridProps = {},
  disabled = false,
  forceUpdateLocalStateWhenValueChanges = false,
  InputProps,
  maxCharacterLimit,
  ...props
}) => {
  const { user } = useAuth();
  const formik = useFormikContext<any>();
  const { setUnsavedChanges } = useUnsavedChanges();

  const userCanEdit = useMemo(() => {
    if (typeof authorizedToEdit === 'function') {
      const roles = authorizedToEdit(AllRoles) as string[];
      return user?.roleCheck(roles) || false;
    }

    if (typeof authorizedToEdit === 'boolean') {
      return authorizedToEdit;
    }

    return false;
  }, [user, authorizedToEdit]);

  const { value } = formik.getFieldProps(formikField);
  const [state, setState] = useState(value);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      if (maxCharacterLimit === undefined || newValue.length <= maxCharacterLimit) {
        setState(newValue);
      } else {
        setState(newValue.slice(0, maxCharacterLimit));
      }
      onTextChange && onTextChange(newValue, event);
    },
    [maxCharacterLimit, onTextChange]
  );

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(formikField, true);
    formik.setFieldValue(formikField, state);
    if (value !== state) {
      setUnsavedChanges();
    }
  }, [formik, formikField, state]);

  useEffect(() => {
    if (forceUpdateLocalStateWhenValueChanges) {
      setState(value);
    }
  }, [value, forceUpdateLocalStateWhenValueChanges]);

  return (
    <Grid item {...gridProps}>
      <Box
        component='div'
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          disabled={!formik.status.editing || !userCanEdit || disabled}
          id={id}
          label={label}
          value={state ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={InputProps}
          {...props}
        />
        {maxCharacterLimit !== undefined && formik.status?.editing && (
          <Typography variant='caption' color='textSecondary' sx={{ mt: 0.5, display: 'block' }}>
            {`${state?.length || 0}/${maxCharacterLimit}`}
          </Typography>
        )}
        {(!formik?.status?.editing || !authorizedToEdit) && (
          <Box
            onClick={() => {
              if (formik.status?.disabledClickCount === 0) {
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                });
              }
            }}
            component='div'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
    </Grid>
  );
};
