import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function LiveryReportView() {
  const { user } = useAuth();
  const [userReady, setuserReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [results, setResults] = useState([]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [dateRangeAlertVisible, setDateRangeAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'CaseID',
      headerName: 'CaseID',
      width: 155,
      editable: false,
      renderCell: params => {
        const caseID = params.value || 'Unknown CaseID';
        return (
          <Button
            color='primary'
            variant='text'
            onClick={event => {
              handleCaseClick(event, params);
            }}
            disabled={caseID === 'Unknown CaseID'}
          >
            <b>{caseID}</b>
          </Button>
        );
      },
    },
    {
      field: 'locationOfBody',
      headerName: 'Location of Body',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'assignedToLivery',
      headerName: 'Assigned',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'accepted',
      headerName: 'Accepted',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'enRoute',
      headerName: 'En Route',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'atScene',
      headerName: 'At Scene',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'custodyOfBody',
      headerName: 'Custody of Body',
      flex: 1,
      minWidth: 180,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'departedScene',
      headerName: 'Departed Scene',
      flex: 1,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'morgueCheckIn',
      headerName: 'Morgue Check-In',
      flex: 1,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'transportedBy',
      headerName: 'Transported By',
      flex: 1,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'transporters',
      headerName: 'Transporters',
      flex: 1,
      minWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  const formik = useFormik({
    initialValues: {
      jurisdictions: [],
      reportedDateRange: [null, null],
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const shortcutsItems = [
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: 'Last Month',
      getValue: () => {
        const today = dayjs();
        const endOfLastMonth = today.startOf('month').subtract(1, 'day');
        return [endOfLastMonth.startOf('month'), endOfLastMonth];
      },
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(30, 'day'), today];
      },
    },
    {
      label: 'Last 6 Months',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(6, 'month'), today];
      },
    },
    {
      label: 'Last Year',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(1, 'year'), today];
      },
    },
    {
      label: 'Reset',
      getValue: () => [null, null],
    },
  ];

  const { VITE_API_URL } = import.meta.env;

  const handleJdxChange = (event, data) => {
    formik.setFieldValue('jurisdictions', data);
  };

  const dismissDateAlert = () => {
    setDateRangeAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const handleSelectAndDeselectAllJurisdictions = () => {
    if (JSON.stringify(formik.values?.jurisdictions) == JSON.stringify(authorizedJdxs)) {
      formik.setFieldValue('jurisdictions', []);
    } else {
      formik.setFieldValue('jurisdictions', authorizedJdxs);
    }
  };

  const clearAll = () => {
    formik.resetForm();
    setResultsVisible(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    var fieldRequired = false;

    if (formik.values?.jurisdictions.length == 0) {
      setJdxAlertVisible(true);
      fieldRequired = true;
    } else if (formik.values?.reportedDateRange.every(elem => elem === null)) {
      setDateRangeAlertVisible(true);
      fieldRequired = true;
    } else if (
      formik.values?.reportedDateRange.every(elem => elem !== null) &&
      formik.values?.reportedDateRange[1].diff(formik.values?.reportedDateRange[0], 'year', true) >
        1
    ) {
      setDateRangeAlertVisible(true);
      fieldRequired = true;
    }

    if (fieldRequired) return;

    setloading(true);
    setResultsVisible(true);
    setJdxAlertVisible(false);
    setDateRangeAlertVisible(false);

    if (user && user.token) {
      try {
        const response = await fetch(VITE_API_URL + 'liveryreport', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + user.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formik.values),
        });

        if (response.status === 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (response.status >= 400) {
          throw new Error('An error occurred');
        }

        const data = await response.json();

        const formatTimestamp = timestamp => {
          if (!timestamp) return '';
          // Input: "2018-07-31T14:01:34.747"
          // Output: "07/31/2018 14:01"
          const [datePart, timePart] = timestamp.split('T');
          const [year, month, day] = datePart.split('-');
          const [hour, minute] = timePart.split(':');
          return `${month}/${day}/${year} ${hour}:${minute}`;
        };

        // Extract numeric portion from CaseID for sorting
        const getNumericPortion = caseId => {
          const match = caseId.match(/\d+$/);
          return match ? parseInt(match[0]) : 0;
        };

        // Flatten and transform the data
        const flattenedData = data.map(item => ({
          CaseID: item.CaseID,
          locationOfBody: item.AddressTypeName || 'N/A',
          assignedToLivery: formatTimestamp(item.Timeline?.AssignedToLivery?.Timestamp),
          accepted: formatTimestamp(item.Timeline?.Accepted?.Timestamp),
          enRoute: formatTimestamp(item.Timeline?.EnRoute?.Timestamp),
          atScene: formatTimestamp(item.Timeline?.AtScene?.Timestamp),
          custodyOfBody: formatTimestamp(item.Timeline?.CustodyOfBody?.Timestamp),
          departedScene: formatTimestamp(item.Timeline?.DepartedScene?.Timestamp),
          morgueCheckIn: formatTimestamp(item.Timeline?.MorgueCheckIn?.Timestamp),
          transportedBy: [
            item.Timeline?.TransportedBy?.ServiceName || 'N/A',
            // item.Timeline?.TransportedBy?.Transporter1Name,
            // item.Timeline?.TransportedBy?.Transporter2Name,
          ]
            .filter(Boolean)
            .join(' , '),
          transporters: [
            item.Timeline?.TransportedBy?.Transporter1Name,
            item.Timeline?.TransportedBy?.Transporter2Name,
          ]
            .filter(Boolean)
            .join(' , '),
        }));

        // Sort the flattened data based on the numeric portion of CaseID
        const sortedData = flattenedData.sort((a, b) => {
          const numA = getNumericPortion(a.CaseID);
          const numB = getNumericPortion(b.CaseID);
          return numA - numB;
        });

        setResults(sortedData);
      } catch (e) {
        console.error(e);
        alert(e);
      } finally {
        setloading(false);
      }
    } else {
      console.log('user or token is null');
      setloading(false);
    }
  };

  useEffect(() => {
    if (user === null) return;

    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);

    setuserReady(true);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Livery Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This report provides a list of Livery Services that facilitated the transport of decedents that meet your search criteria'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <CardContent>
        <Slide direction='right' in={dateRangeAlertVisible} mountOnEnter unmountOnExit>
          <Alert severity='warning' onClose={dismissDateAlert}>
            The Reported on field range has a maximum 1 year range or a specific year dropdown must
            be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least one jurisdiction.
          </Alert>
        </Slide>

        <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

        <Grid container spacing={2}>
          <Grid xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs.sort((a, b) => a.jdxName.localeCompare(b.jdxName))}
              value={formik.values?.jurisdictions}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => {
                if (
                  option.jdxName ===
                  authorizedJdxs.sort((a, b) => a.jdxName.localeCompare(b.jdxName))[0].jdxName
                ) {
                  return (
                    <Stack>
                      <Button
                        sx={{
                          backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#cccccc',
                          color: theme.palette.text.primary,
                          p: 1,
                          m: 0.5,
                          boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)',
                        }}
                        onClick={handleSelectAndDeselectAllJurisdictions}
                      >
                        {JSON.stringify(formik.values?.jurisdictions) ===
                        JSON.stringify(authorizedJdxs) ? (
                          <Typography variant='button'>Deselect All</Typography>
                        ) : (
                          <Typography variant='button'>Select All</Typography>
                        )}
                      </Button>
                      <li {...props}>
                        <Checkbox
                          icon={<MdCheckBoxOutlineBlank />}
                          checkedIcon={<MdOutlineCheckBox />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.jdxName}
                      </li>
                    </Stack>
                  );
                }
                return (
                  <li {...props}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.jdxName}
                  </li>
                );
              }}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
            />
          </Grid>

          <Grid xs={16} md={8}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                name='reportedDateRange'
                value={formik.values?.reportedDateRange}
                onChange={data => formik.setFieldValue('reportedDateRange', data)}
                localeText={{ start: 'Reported From', end: 'Reported To' }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={results}
            idcolumn='CaseID'
            columnsInput={columns}
            title='Livery Report'
            casecount
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've listed the detailed case records below, you can export the list to csv
                  format.
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
