import { Autocomplete, AutocompleteProps, Grid, TextField } from '@mui/material';

interface AutocompleteWithOtherProps
  extends Omit<AutocompleteProps<Option, false, false, false>, 'options' | 'value' | 'onChange'> {
  options: Option[];
  autocompleteValue: Option | null;
  onChangeAutocomplete: (event: React.ChangeEvent<{}>, value: Option | null) => void;
  otherValue?: string;
  onChangeOther: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function AutocompleteWithOther({
  options,
  autocompleteValue,
  onChangeAutocomplete,
  otherValue,
  onChangeOther,
  ...other
}: AutocompleteWithOtherProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={autocompleteValue?.optionName === 'Other' ? 6 : 12}>
        <Autocomplete
          {...other}
          options={options}
          value={autocompleteValue}
          onChange={onChangeAutocomplete}
        />
      </Grid>
      {autocompleteValue?.optionName === 'Other' && (
        <Grid item xs={12} md={6}>
          <TextField label='Other' value={otherValue} onChange={onChangeOther} />
        </Grid>
      )}
    </Grid>
  );
}
