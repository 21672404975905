import { Delete, Save } from '@mui/icons-material';
import { IconButton, Paper, Stack } from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import LoadingIconButton from '../../utils/components/LoadingIconButton';
import RichTextTypography from '../Home/RichTextTypography';
import { DVCSCardProps } from './DVCSHomeCardAdmin';

interface HomeCardProps {
  allCards: DVCSCardProps[];
  setAllCards: React.Dispatch<React.SetStateAction<DVCSCardProps[]>>;
  cardSeq: string;
  initEditing?: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DVCSHomeCard_AdminView({
  allCards,
  setAllCards,
  cardSeq,
  setRefresh,
}: HomeCardProps) {
  const [card, setCard] = useState(allCards.find(card => card.homeCardSeq === cardSeq));
  const [loadingSaving, setLoadingSaving] = useState(false);
  const cardBuffer = allCards.find(card => card.homeCardSeq === cardSeq);
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;

  const isCardEmpty = (card: DVCSCardProps) => {
    return card.cardContent === '';
  };

  const deleteCard = async () => {
    if (isCardEmpty(cardBuffer)) {
      setAllCards((prevCardContent: DVCSCardProps[]) =>
        prevCardContent.filter(currentCard => currentCard.homeCardSeq !== cardSeq)
      );
    } else {
      try {
        const response = await fetch(`${VITE_API_URL}dvcs/deletehomecard?homeCardSeq=${cardSeq}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (response.ok) {
          setAllCards((prevCardContent: DVCSCardProps[]) =>
            prevCardContent.filter(currentCard => currentCard.homeCardSeq !== cardSeq)
          );
          setRefresh(true);
        } else {
          console.error('Failed to delete card');
        }
      } catch (error) {
        console.error('Error deleting card:', error);
      }
    }
  };

  const saveCard = async () => {
    try {
      setLoadingSaving(true);

      const response = await fetch(`${VITE_API_URL}dvcs/updatehomecard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify(card),
      });

      if (response.ok) {
        setRefresh(true);
      } else {
        console.error('Failed to update card');
      }
    } catch (error) {
      console.error('Error updating card:', error);
    } finally {
      setLoadingSaving(false);
    }
  };

  return (
    <Paper sx={{ p: 3, borderRadius: 4, position: 'relative' }}>
      <RichTextTypography
        sx={{ mt: 5 }}
        cardContent={card.cardContent}
        setCardContent={DVCSCardPropsContent =>
          setCard({ ...card, cardContent: DVCSCardPropsContent })
        }
      />

      <Stack direction='row' spacing={2} sx={{ position: 'absolute', top: 0, right: 0, p: 2 }}>
        <LoadingIconButton loading={loadingSaving} onClick={saveCard}>
          <Save color='primary' />
        </LoadingIconButton>
        <IconButton onClick={deleteCard}>
          <Delete color='error' />
        </IconButton>
      </Stack>
    </Paper>
  );
}
