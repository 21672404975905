import { Box } from '@mui/material';
import DesktopHomeView from './DesktopHomeView';
import MobileHomeView from './MobileHomeView';
import { useEffect, useRef, useState } from 'react';

export default function HomeView() {
  const boxRef = useRef(null);
  const searchRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(0);
  const [searchHeight, setSearchHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setBoxHeight(entry.contentRect.height); // Update the height
      }
    });

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current); // Observe the element
    }

    return () => {
      resizeObserver.disconnect(); // Clean up
    };
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setSearchHeight(entry.contentRect.height); // Update the height
      }
    });

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current); // Observe the element
    }

    return () => {
      resizeObserver.disconnect(); // Clean up
    };
  }, []);

  useEffect(() => {
    console.log(searchHeight);
  }, [searchHeight]);

  return (
    <Box
      component='div'
      sx={{ p: 3, height: '100%', mb: `calc((${boxHeight}px - 100vh) + 205px)` }}
    >
      <DesktopHomeView />
      <MobileHomeView boxRef={boxRef} />
    </Box>
  );
}
