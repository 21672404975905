import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import authHeader from '../services/auth-header';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const validationSchema = Yup.object({
  to: Yup.string().email('Invalid email').required('Required'),
  subject: Yup.string().required('Required'),
  body: Yup.string().required('Required'),
});

const WorkstationEmail = () => {
  const [opensaveapi, setOpensaveapi] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [open, setOpen] = useState(false);
  const Close = () => setOpen(false);
  return (
    <>
      <Formik
        initialValues={{ to: '', subject: '', body: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          var messageRecipients = [
            {
              personLastName: '',
              personFirstName: '',
              userName: '',
              primaryEmail: values.to,
              primaryMobileNo: '',
              userSeq: '',
              personSeq: '',
            },
          ];
          var messageRecipients = [
            {
              personLastName: '',
              personFirstName: '',
              userName: '',
              primaryEmail: values?.to,
              primaryMobileNo: '',
              userSeq: '',
              personSeq: '',
            },
          ];
          let formData = new FormData();
          formData.append('MESSAGEID', 5003);
          formData.append('SUBJECT', values.subject);
          formData.append('BODY', values.body);
          formData.append('RECIPIENTS', JSON.stringify(messageRecipients));

          axios
            .post(`${import.meta.env.VITE_API_URL}SendEmail`, formData, {
              headers: authHeader(),
            })
            .then((response) => {
              setEmailMessage(response.data.message);
              setOpensaveapi(true);
            })
            .catch((error) => {
              console.log('error', error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              From New Jersey State
            </Typography>
            <br />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={16} md={12} sm={12}>
                  <FormControl sx={{ minWidth: '94%' }}>
                    <TextField
                      name='to'
                      label='To'
                      variant='outlined'
                      size='small'
                      fullWidth
                      value={values.to}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.to && Boolean(errors.to)}
                      helperText={touched.to && errors.to}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <br />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <FormControl sx={{ minWidth: '94%' }}>
                    <TextField
                      name='subject'
                      label='Subject'
                      variant='outlined'
                      size='small'
                      fullWidth
                      value={values.subject}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.subject && Boolean(errors.subject)}
                      helperText={touched.subject && errors.subject}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <br />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <FormControl sx={{ minWidth: '94%' }}>
                    <TextField
                      name='body'
                      label='Body'
                      variant='outlined'
                      size='small'
                      fullWidth
                      multiline
                      rows={5}
                      rowsMax={10}
                      value={values.body}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.body && Boolean(errors.body)}
                      helperText={
                        touched.body && errors.body ? errors.body : ''
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <br />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={4} xs={12} md={12} sm={12}>
                  <FormControl>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      size='small'
                      sx={{
                        transform: 'translate(200px, 50px)',
                        position: 'relative',
                        top: '6px',
                      }}
                    >
                      Send Mail
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <br />
            <div className='modal'>
              <Dialog
                open={opensaveapi}
                TransitionComponent={Transition}
                keepMounted
                onClose={Close}
                aria-describedby='alert-dialog-slide-description'
              >
                <DialogTitle></DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-slide-description'>
                    {emailMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <IconButton onClick={() => setOpensaveapi(false)}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
              </Dialog>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WorkstationEmail;
