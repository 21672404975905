import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListIcon from '@mui/icons-material/List';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PublishIcon from '@mui/icons-material/Publish';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ManIcon from '@mui/icons-material/Man';
import DomainIcon from '@mui/icons-material/Domain';
import UsersAdminView from '../../SysAdmin/SysAdminModules/UserAdminModules/UserAdminView';
import ReferenceData from './ReferenceDataAdminModule/ReferenceDataView';
import WorkStationSetupView from '../../SysAdmin/SysAdminModules/WorkStationSetupView';
import GenerateBlankRIMEView from './GenerateBlankRIME/GenerateBlankRIMEView';
import CaseVisitView from '../../Case/CaseViewModules/CaseVisit/CaseVisitView';
import LiveryServices from '../../Case/CaseViewModules/LiveryServiceModule/LiveryServiceView';
import LocationList from '../../Case/CaseViewModules/LocationListModule/LocationListView';
import ReportHeaderDetailsView from '../../Case/CaseViewModules/ReportHeaderDetails/ReportHeaderDetailsView';
import QAReviewModule from '../CaseAdminModules/QAReviewModule';

import DailyExamListModule from '../CaseAdminModules/DailyExamListModule';
import DailyExamListIcon from '@mui/icons-material/RecentActors';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MessageIcon from '@mui/icons-material/Message';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import GradingIcon from '@mui/icons-material/Grading';
import BulkMessageView from '../../SysAdmin/SysAdminModules/BulkMessageView';
import SitesLocationsView from '../../SysAdmin/SysAdminModules/SitesLocationsView';
import CasesCurrentlyLockedView from '../../SysAdmin/SysAdminModules/AllLockedCasesView';
import AppSettingsView from '../../SysAdmin/SysAdminModules/AppSettingsView';
import SettingsIcon from '@mui/icons-material/Settings';
import UploadResourcesView from '../../SysAdmin/SysAdminModules/UploadResources/UploadResourcesView';
import BusinessLogicView from '../../SysAdmin/SysAdminModules/BusinessLogicView';
import POC from '../POC/POCView';
import LabListView from '../CaseAdminModules/LabListModules/LabListView';
import RolesAdminView from '../../SysAdmin/SysAdminModules/RolesAdmin/RolesAdminView';
import { Dashboard, FeedbackRounded } from '@mui/icons-material';
import AddCardIcon from '@mui/icons-material/AddCard';
import ServerMonitoringDashboard from '../../SysAdmin/SysAdminModules/ServerMonitoringDashboard/ServerMonitoringDashboard';
import { UserFeedbackView } from '../../SysAdmin/SysAdminModules/UserFeedback/UserFeedbackView';
import HomeCardsAdminView from '../../SysAdmin/SysAdminModules/HomeCardsAdmin/HomeCardsAdminView';
import { FilesAdminView } from '../../SysAdmin/SysAdminModules/FilesAdmin/FilesAdminView';

export const ADMIN_MENU_ICON = {
  Users: {
    icon: <ManageAccountsIcon />,
    component: <UsersAdminView />,
  },
  Roles: {
    icon: <ManageAccountsIcon />,
    component: <RolesAdminView />,
  },
  POC: {
    icon: <CalendarMonthIcon />,
    component: <POC />,
  },
  'Reference Data': {
    icon: <MenuBookIcon />,
    component: <ReferenceData />,
  },
  'Lab List': {
    icon: <ListIcon />,
    component: <LabListView />,
  },
  'Workstation Setup': {
    icon: <ManageAccountsIcon />,
    component: <WorkStationSetupView />,
  },
  'Location List': {
    icon: <LocationOnIcon />,
    component: <LocationList />,
  },
  'Case Visit': {
    icon: <ManageAccountsIcon />,
    component: <CaseVisitView />,
  },
  'Business Logic': {
    icon: <DomainIcon />,
    component: <BusinessLogicView />,
  },
  'Report Header Details': {
    icon: <SummarizeIcon />,
    component: <ReportHeaderDetailsView />,
  },
  'Generate Blank RIME': {
    icon: <FormatAlignJustifyIcon />,
    component: <GenerateBlankRIMEView />,
  },
  'Upload Resources': {
    icon: <PublishIcon />,
    component: <UploadResourcesView />,
  },
  'Livery Services': {
    icon: <ManIcon />,
    component: <LiveryServices />,
  },
  'QA Review': {
    icon: <GradingIcon />,
    component: <QAReviewModule />,
  },

  // 'Bulk File Upload': {
  //   icon: <DriveFolderUploadIcon />,
  //   component: <BulkFileUpload />,
  // },
  
  'Daily Exam List': {
    icon: <DailyExamListIcon />,
    component: <DailyExamListModule />,
  },
  'Send Bulk Msg': {
    icon: <MessageIcon />,
    component: <BulkMessageView />,
  },
  'Locked Cases': {
    icon: <MessageIcon />,
    component: <CasesCurrentlyLockedView />,
  },
  'Server Dashboard': {
    icon: <Dashboard />,
    component: <ServerMonitoringDashboard />,
  },
  'User Feedback': {
    icon: <FeedbackRounded />,
    component: <UserFeedbackView />,
  },
  'Home Cards Admin': {
    icon: <AddCardIcon />,
    component: <HomeCardsAdminView />,
  },
  'Sites/Locations': {
    icon: <AddBusinessIcon />,
    component: <SitesLocationsView />,
  },
  'Support Files': {
    icon: <AddBusinessIcon />,
    component: <FilesAdminView />,
  },
  'Global Settings': {
    icon: <SettingsIcon />,
    component: <AppSettingsView />,
  },
};

export const CASE_ADMIN = {
  'Reference Data': true,
  'Lab List': true,
  POC: true,
  'Location List': true,
  'Case Visit': true,
  'Report Header Details': true,
  'Generate Blank RIME': true,
  'Livery Services': true,
};

export const SYSTEMS_ADMIN = {
  'Workstation Setup': true,
};

export const TITLE = [
  { name: 'Chief Medical Examiner', value: 'Chief Medical Examiner' },
  {
    name: 'Deputy Chief Medical Examiner',
    value: 'Deputy Chief Medical Examiner',
  },
  { name: 'Doctor of Medicine', value: 'Doctor of Medicine' },
  {
    name: 'First Deputy Chief Medical Examiner',
    value: 'First Deputy Chief Medical Examiner',
  },
  { name: 'MD', value: 'MD' },
  { name: 'Medical Examiner', value: 'Medical Examiner' },
  { name: 'Medical-Legal Investigator', value: 'Medical-Legal Investigator' },
  { name: 'other', value: 'other' },
];

export const OPERATION_MODE = [
  { name: 'Regular', value: 'Regular' },
  { name: 'Disaster', value: 'Disaster' },
];

export const SPECIAL_PRIVILEGE = [
  { name: 'Show in NJ State Case list', value: 'srtCaseEditable' },
  { name: 'Ability to receive X-Ray Notification', value: 'xRayEmailEnabled' },
  { name: 'Ability to Hold', value: 'holdable' },
  { name: 'HP Notification Recipient', value: 'isHighProfile' },
  {
    name: 'Exam Complete Date Editable',
    value: 'isExamCompleteDateEditable',
  },
  { name: 'Unconfirm Case Release', value: 'isUnconfirmCaseRelease' },
  { name: 'Void Tissue Number', value: 'isVoidTissueNumber' },
  { name: 'Delete Tissue Number', value: 'isDeleteTissueNumber' },
  { name: 'Hide in Drop-Downs/Pop-Ups', value: 'isHideDropdownPopUp' },
  { name: 'Modify ID Modality', value: 'isModifyIdUser' },
  { name: 'Edit Autopsy (Final) Report', value: 'isAutopsyReportFinal' },
  { name: 'Bypass 2FA Authentication', value: 'enable2FA' },
];

export const SPECIAL_PRIVILEGE_OBJECT = {
  holdable: 'Ability to Hold',
  xRayEmailEnabled: 'Ability to receive X-Ray Notification',
  enable2FA: 'Bypass 2FA Authentication',
  isDeleteTissueNumber: 'Delete Tissue Number',
  isAutopsyReportFinal: 'Edit Autopsy (Final) Report',
  isExamCompleteDateEditable: 'Exam Complete Date Editable',
  isHideDropdownPopUp: 'Hide in Drop-Downs/Pop-Ups',
  isHighProfile: 'HP Notification Recipient',
  isModifyIdUser: 'Modify ID Modality',
  srtCaseEditable: 'Show in NJ State Case list',
  isUnconfirmCaseRelease: 'Unconfirm Case Release',
  isVoidTissueNumber: 'Void Tissue Number',
};

export const PHYSICIAN_CERTIFICATION_PATHOLOGY = [
  { name: 'AP (Anatomic Pathology)', value: 'isAnatomicPathologyCert' },
  { name: 'CP (Clinical Pathology)', value: 'isClinicalPathologyCert' },
  { name: 'FP (Forensic Pathology)', value: 'isForensicPathologyCert' },
];

export const PHYSICIAN_CERTIFICATION_PATHOLOGY_OBJECT = {
  isAnatomicPathologyCert: 'AP (Anatomic Pathology)',
  isClinicalPathologyCert: 'CP (Clinical Pathology)',
  isForensicPathologyCert: 'FP (Forensic Pathology)',
};

export const OCSME_CERTIFICATION = [
  { name: 'A (Autopsy)', value: 'isAutopsyCertCert' },
  { name: 'DI (Death Investigation)', value: 'isDeathInvestigationCert' },
  { name: 'E (External Exam)', value: 'isExternalExamCert' },
];

export const OCSME_CERTIFICATION_OBJECT = {
  isAutopsyCertCert: 'A (Autopsy)',
  isDeathInvestigationCert: 'DI (Death Investigation)',
  isExternalExamCert: 'E (External Exam)',
};

export const USERS_MEDICAL_CERTIFICATION = {
  isAnatomicPathologyCert: false,
  isAutopsyCertCert: false,
  isAutopsyReportFinal: false,
  isClinicalPathologyCert: false,
  isDeathInvestigationCert: false,
  isDeleteTissueNumber: false,
  isExamCompleteDateEditable: false,
  isExternalExamCert: false,
  isForensicPathologyCert: false,
  isHideDropdownPopUp: false,
  isHighProfile: false,
  isModifyIdUser: false,
  isUnconfirmCaseRelease: false,
  isVoidTissueNumber: false,
};

export const NotificationTemplateBodyTags = [
  { title: 'Caller Name', value: '<< Caller Name >>' },
  { title: 'Decedent Name', value: '<< Decedent Name >>' },
  { title: 'Case No', value: '<< Case No >>' },
  { title: 'ME Action', value: '<< ME Action >>' },
  { title: 'Identification By', value: '<< Identification By >>' },
  { title: 'Identification Type', value: '<< Identification Type >>' },
  { title: 'Identification Outcome', value: '<< Identification Outcome >>' },
  { title: 'IsIDConfirmedByME', value: '<< IsIDConfirmedByME >>' },
  { title: 'Location of the Body', value: '<< Location of the Body >>' },
  { title: 'User Name', value: '<< User Name >>' },
  { title: 'Date of Birth', value: '<< Date of Birth >>' },
  { title: 'POC', value: '<< POC >>' },
  { title: 'SSN', value: '<< SSN >>' },
  { title: 'Reason for Transfer', value: '<< Reason for Transfer >>' },
  { title: 'Not Acceptance Reason', value: '<< Not Acceptance Reason >>' },
  {
    title: 'Pending X-Ray Request Count',
    value: '<< Pending X-Ray Request Count >>',
  },
  { title: 'Case Name', value: '<< Case Name >>' },
  {
    title: 'Case link for Identification Outcome',
    value: '<< Case link for Identification Outcome >>',
  },
];

export const ROLE_OBJ_MAP = {
  isHCMRole: 'HCM Role',
  isInvestigator: 'Investigator',
  isDispatcher: 'Dispatcher',
  isLivery: 'Livery',
  isMedicalExaminer: 'Medical Examiner',
  isMorgueTechnician: 'Morgue Technician',
  isAdministrativeAssistant: 'Administrative Assistant',
  isLabTechnician: 'Lab Technician',
  isHospitalStaff: 'Hospital Staff',
  isRegularRole: 'Regular Role',
  isDisasterRole: 'Disaster Role',
  isMedicalExaminerNoDisplay: 'Medical Examiner No Display',
  isDataEntryOperator: 'Data Entry Operator',
  isQAPersonnel: 'QA Personnel',
};

export const SPECIAL_ROLE_MAP = {
  isAdministrativeAssistant: false,
  isDataEntryOperator: false,
  isDisasterRole: false,
  isDispatcher: false,
  isHCMRole: false,
  isHospitalStaff: false,
  isInvestigator: false,
  isLabTechnician: false,
  isLivery: false,
  isMedicalExaminer: false,
  isMedicalExaminerNoDisplay: false,
  isMorgueTechnician: false,
  isQAPersonnel: false,
  isRegularRole: false,
};

export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const SECURITY_QUESTIONS = [
  {
    securityQuestionDetails: 'Select Security Question',
    securityQuestionOrder: null,
    securityQuestionSeq: '',
    status: null,
    securityQuestionAns: '',
  },
  {
    securityQuestionDetails: 'Select Security Question',
    securityQuestionOrder: null,
    securityQuestionSeq: '',
    status: null,
    securityQuestionAns: '',
  },
  {
    securityQuestionDetails: 'Select Security Question',
    securityQuestionOrder: null,
    securityQuestionSeq: '',
    status: null,
    securityQuestionAns: '',
  },
];
