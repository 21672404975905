import { Box } from '@mui/material';
import DesktopAppBar from './DesktopAppBar';
import MobileAppBar from './MobileAppBar';
import { useLocation } from 'react-router';
import DVCSDesktopAppBar from './DVCSDesktopAppBar';
import DVCSMobileAppBar from './DVCSMobileAppBar';

export default function MLAppBar() {
  const location = useLocation();

  return location.pathname.startsWith('/dvcs') ? (
    <Box component='div'>
      <DVCSDesktopAppBar />
      <DVCSMobileAppBar />
    </Box>
  ) : (
    <Box component='div'>
      <DesktopAppBar />
      <MobileAppBar />
    </Box>
  );
}
