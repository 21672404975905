import {
  Grid,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import { FormikTextField } from '../../../utils/components/FormikTextField';
import CaseViewDateField from '../../../components/CaseView/CaseViewDateField';
import { useUnsavedChanges } from '../../../Context/UnsavedChangesContext';
import CaseViewAutocomplete from '../../../components/CaseView/CaseViewAutocomplete';
import NotesRichTextEditor from '../../../utils/components/NotesRichTextEditor';
import { useOptions } from '../../../utils/api/useOptions.hook';

export default function CertificationView() {
  const [meOptions, setMEOptions] = useState([]);
  const { user } = useAuth();
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);

  const { VITE_API_URL } = import.meta.env;

  const formik = useFormikContext();
  const { setUnsavedChanges } = useUnsavedChanges();

const {
    options: administrativeAssistantOptions,
    loading: administrativeAssistantOptionsLoading,
  } = useOptions('administrative assistant options', { jdxSeq: formik.values?.caseSummary?.jdxSeq });



  const handleNotesChange = content => {
    formik.setFieldValue('caseCertification.caseRecordsStatusNotes', content || '');
  };

  const fetchMEs = async () => {
    const queryParams = new URLSearchParams();
    if (formik.values?.caseSummary?.jdxSeq) {
      queryParams.append('jdxList', formik.values?.caseSummary?.jdxSeq);
    }

    await fetch(`${VITE_API_URL}get-pathologist-options?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMEOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchMEs();
  }, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} container spacing={2}>
          <CaseViewDateField
            xs={12}
            md={6}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.pathologistSignedAndFiledDate'
            label='Forensic Pathology Rpt Signed'
          />

          <CaseViewAutocomplete
            xs={12}
            md={6}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.pathologistSignedAndFiledUser'
            options={meOptions}
            label='Pathologist'
          />

          <CaseViewDateField
            xs={12}
            md={6}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.deathCertificateSignedAndFiledDate'
            label='Death Certificate Signed'
          />

          <CaseViewAutocomplete
            xs={12}
            md={6}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.deathCertificateSignedAndFiledUser'
            options={meOptions}
            label='Pathologist'
          />

          <CaseViewDateField
            xs={12}
            md={6}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.deathCertificateAmendedSignedAndFiledDate'
            label='Death Certificated Amended'
          />

          <CaseViewAutocomplete
            xs={12}
            md={6}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.deathCertificateAmendedSignedAndFiledUser'
            options={meOptions}
            label='Pathologist'
          />
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <FormikTextField
            gridProps={{ xs: 12, md: 3 }}
            authorizedToEdit={authorizedToEdit}
            formikField='caseCertification.edrsNumber'
            label='Death Certificate No.'
            inputProps={{
              maxLength: 50,
            }}
          />

          <FormikTextField
            gridProps={{ xs: 12, md: 3 }}
            authorizedToEdit={authorizedToEdit}
            formikField='caseCertification.labNumber'
            label='Lab Number'
            inputProps={{
              maxLength: 50,
            }}
          />

          <FormikTextField
            gridProps={{ xs: 12, md: 3 }}
            authorizedToEdit={authorizedToEdit}
            formikField='caseInvestigations.deathInvestigationAdditionalInformation.namUsID'
            label='NamUs ID'
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <CaseViewDateField
            xs={12}
            md={3}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.caseFiledByRecordsAdminDate'
            label='Case Filed On'
          />

          <CaseViewAutocomplete
            xs={12}
            md={3}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseCertification.caseFiledByRecordsAdminUser'
            options={administrativeAssistantOptions}
            label='Case Filed By'
          />

        </Grid>

        <Grid item xs={12}>
          <Typography variant='subtitle1' color='gray'>
            COMMENTS
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NotesRichTextEditor
            value={formik.values?.caseCertification?.caseRecordsStatusNotes}
            onChange={handleNotesChange}
            isEditing={formik.status.editing && authorizedToEdit}
            // sx={{
            //   mb: 2,
            //   '& .ProseMirror': {
            //     minHeight: '200px',
            //   },
            // }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
