import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import { useAuth } from '../../utils/auth/AuthService';
import { TimeoutWarningModal } from './TimeoutWarningModal';
import { addEventListeners, removeEventListeners } from './eventListenerUtil';

interface TimeoutConfig {
  minutesUntilWarning: number; // When to show the warning modal before logout
  durationOfWarning: number; // How long the modal stays before auto-logout
}

export const TimeoutLogic = () => {
  const auth = useAuth();
  const location = useLocation();
  const { hasUnsavedChanges } = useUnsavedChanges();

  const isDashboard = location.pathname.includes('dashboard');

  // Define configuration based on the current route
  const config: TimeoutConfig = isDashboard
    ? {
        minutesUntilWarning: 60, // Show modal at 60 minutes
        durationOfWarning: 5, // Modal stays for 5 minutes before auto-logout
      }
    : {
        minutesUntilWarning: 20, // Show modal at 20 min
        durationOfWarning: 5, // Modal stays for 5 minutes before auto-logout
      };

  // Validation to prevent logical errors
  if (config.durationOfWarning > config.minutesUntilWarning + config.durationOfWarning) {
    console.error(
      'durationOfWarning cannot exceed the total session timeout defined by minutesUntilWarning + durationOfWarning.'
    );
  }

  // Convert minutes to milliseconds
  const minutesToMs = (minutes: number) => minutes * 60 * 1000;

  const minutesUntilWarningMs = minutesToMs(config.minutesUntilWarning);
  const durationOfWarningMs = minutesToMs(config.durationOfWarning);
  const totalTimeoutMs = minutesUntilWarningMs + durationOfWarningMs;

  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(totalTimeoutMs);

  const logoutAtRef = useRef<number>(Date.now() + totalTimeoutMs);
  const warningAtRef = useRef<number>(Date.now() + minutesUntilWarningMs);
  const broadcastChannelRef = useRef<BroadcastChannel | null>(null);

  const isWarningModalOpenRef = useRef<boolean>(isWarningModalOpen);

  useEffect(() => {
    isWarningModalOpenRef.current = isWarningModalOpen;
  }, [isWarningModalOpen]);

  const resetLogoutAt = () => {
    const newLogoutAt = Date.now() + totalTimeoutMs;
    const newWarningAt = newLogoutAt - durationOfWarningMs;

    logoutAtRef.current = newLogoutAt;
    warningAtRef.current = newWarningAt;

    broadcastChannelRef.current?.postMessage({ type: 'RESET_TIMEOUT', logoutAt: newLogoutAt });

    setRemainingTime(totalTimeoutMs);
  };

  const activityListener = () => {
    if (!isWarningModalOpenRef.current) {
      resetLogoutAt();
    }
  };

  const performLogout = async () => {
    broadcastChannelRef.current?.postMessage({
      type: 'LOGOUT',
      logoutAt: null,
    });
    auth.logout();
    auth.authDispatch({
      type: 'SET_LOGOUT_MESSAGE_STATE',
      payload: {
        dismissAfterSeconds: -1,
        displayAlert: true,
        message: 'You have been automatically logged out for inactivity',
        shouldDismiss: false,
      },
    });
  };

  const handleExtendSession = () => {
    setWarningModalOpen(false);
    resetLogoutAt();
  };

  useEffect(() => {
    // Initialize BroadcastChannel
    broadcastChannelRef.current = new BroadcastChannel('timeout_channel');

    const handleBroadcastMessage = (event: MessageEvent) => {
      const { type, logoutAt } = event.data;
      if (type === 'RESET_TIMEOUT' && logoutAt) {
        logoutAtRef.current = logoutAt;
        warningAtRef.current = logoutAt - durationOfWarningMs;
        setRemainingTime(logoutAt - Date.now());
        isWarningModalOpenRef.current = false;
        setWarningModalOpen(false);
      }

      if (type === 'LOGOUT') {
        performLogout();
      }
    };

    broadcastChannelRef.current.addEventListener('message', handleBroadcastMessage);

    // Reset logout and warning times on mount
    resetLogoutAt();

    // Add activity event listeners
    addEventListeners(activityListener);

    // Set up a single interval to update remainingTime and manage modal/logout
    const timerInterval = setInterval(() => {
      const now = Date.now();
      const timeUntilLogout = logoutAtRef.current - now;
      const timeUntilWarning = warningAtRef.current - now;

      setRemainingTime(timeUntilLogout > 0 ? timeUntilLogout : 0);

      // Show warning modal if it's time
      if (timeUntilWarning <= 0 && !isWarningModalOpenRef.current) {
        setWarningModalOpen(true);
      }

      // Auto-logout if time is up
      if (timeUntilLogout <= 0) {
        performLogout();
      }
    }, 1000);

    return () => {
      removeEventListeners(activityListener);
      clearInterval(timerInterval);
      broadcastChannelRef.current?.removeEventListener('message', handleBroadcastMessage);
      broadcastChannelRef.current?.close();
    };
  }, [location.pathname, durationOfWarningMs, totalTimeoutMs, minutesUntilWarningMs]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges && !isWarningModalOpenRef.current) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  // // Calculate additional debug times
  // const now = Date.now();
  // const timeUntilLogout = logoutAtRef.current - now;
  // const timeUntilWarning = warningAtRef.current - now;
  // const elapsedTimeMs = totalTimeoutMs - timeUntilLogout; // Elapsed since last activity

  // // Collect all debug data
  // const debugData = {
  //   Configuration: {
  //     isDashboard,
  //     config: {
  //       minutesUntilWarning: config.minutesUntilWarning,
  //       durationOfWarning: config.durationOfWarning,
  //     },
  //   },
  //   State: {
  //     isWarningModalOpen,
  //     hasUnsavedChanges,
  //     locationPathname: location.pathname,
  //   },
  //   Timers: {
  //     currentTimestamp: new Date(now).toLocaleString(),
  //     timeSinceLastActivity: `${Math.floor(elapsedTimeMs / 1000)}s`,
  //     timeUntilWarning: `${Math.floor(timeUntilWarning / 1000)}s`,
  //     timeUntilLogout: `${Math.floor(timeUntilLogout / 1000)}s`,
  //     remainingTime: `${Math.floor(remainingTime / 1000)}s`,
  //   },
  //   Refs: {
  //     logoutAt: new Date(logoutAtRef.current).toLocaleString(),
  //     warningAt: new Date(warningAtRef.current).toLocaleString(),
  //     isWarningModalOpenRef: isWarningModalOpenRef.current,
  //   },
  //   BroadcastChannel: {
  //     channelName: 'timeout_channel',
  //   },
  // };

  // Optional: Only show debug information in development
  // const isDevelopment = import.meta.env.NODE_ENV === 'development';

  return (
    <div>
      <TimeoutWarningModal
        isOpen={isWarningModalOpen}
        onExtend={handleExtendSession}
        onLogout={performLogout}
        remainingTime={remainingTime}
      />
      {/* {isDevelopment && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          message={
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                margin: 0,
                fontSize: '0.75rem',
                maxWidth: '300px',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: '#fff',
                padding: '10px',
                borderRadius: '4px',
              }}
            >
              {JSON.stringify(debugData, null, 2)}
            </pre>
          }
        />
      )} */}
    </div>
  );
};
