import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ElabSampleEvidenceInvocing() {
  const sampleEvidenceColumns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 125,
      maxWidth: 125,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'itemName',
      headerName: 'Item Name',
      minWidth: 175,
      maxWidth: 200,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleELabCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'toBeInvoiced',
      headerName: 'To Be Invoiced',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'sampleNo',
      headerName: 'Sample No.',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'toxLimsNo',
      headerName: 'Tox LIMS No.',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'agencyName',
      headerName: 'Agency Name',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  return (
    <ReportDataGrid
      idcolumn='id'
      //   loading={loading}
      listresults={[]}
      columnsInput={sampleEvidenceColumns}
      title=''
      customButtons={[<GridToolbarQuickFilter key='filter' />]}
    />
  );
}
