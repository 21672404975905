import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DoneIcon from '@mui/icons-material/Done';
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../../../Context/GlobalContext';
import authHeader from '../../../../services/auth-header';
import {
  fetchCounty,
  fetchCountyCode,
  fetchRoles,
  fetchUserCounty,
  fetchUserProfileInformation,
  getNameFromSequence,
} from '../../../../services/utility-api';
import './UserAdminView.css';

import ApiNotification from '../../../../components/DialogBox';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const UsersAdminView = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const usersPermissions = permissions?.['Users'];
  const [name, setName] = useState('');
  const [role, setRole] = useState('Select One');
  const [county, setCounty] = useState('Select One');
  const [roleOptions, setRoleOptions] = useState([]);
  const [countiesOptions, setCountiesOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');
  const [open, setOpen] = useState(false);
  const [iconData, setIcondata] = useState({});
  const [dialogMessageModal, setDialogMessageModal] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const { sendUserProfile, sendSignature } = useContext(GlobalContext);

  const navigate = useNavigate();

  const columns = [
    {
      field: 'username',
      headerName: 'User Name',
      width: 160,
      editable: false,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      width: 160,
      editable: false,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      width: 160,
      editable: false,
    },
    {
      field: 'initial',
      headerName: 'Initials',
      width: 175,
      editable: false,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      renderCell: ({ row }) => {
        const nameFromSequence = getNameFromSequence(row);
        return nameFromSequence?.join(', ');
      },
      minWidth: 300,
      // align: 'left',
      // flex: 5,
      editable: false,
    },
    {
      field: 'Status',
      // headerClassName: 'super-app-theme--header',
      renderCell: cellValues => {
        const handleClick = event => {
          event.stopPropagation();
          setOpen(true);
          const newIconData = cellValues.row.status === 'Active' ? 'locked' : 'done';
          setIcondata({
            icon: newIconData,
            userseq: cellValues?.row?.userseq,
            status: cellValues.row.status,
          });
        };

        return (
          <Button onClick={handleClick}>
            {cellValues.row.status === 'Active' ? <LockIcon /> : <DoneIcon />}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    sendSignature(null);
    const result = fetchRoles('Users');
    result.then(roleDropdownValues => {
      let updatedRoleOptions = { name: 'Select One', value: 'Select One' };
      roleDropdownValues.splice(0, 0, updatedRoleOptions);
      setRoleOptions(roleDropdownValues);
    });
    const countiesResult = fetchCounty();
    countiesResult.then(countiesDropdown => {
      let updatedCountyOptions = { name: 'Select One', value: 'Select One' };
      countiesDropdown.splice(0, 0, updatedCountyOptions);
      setCountiesOptions(countiesDropdown);
    });
    fetchUserCounty().then(usersResponse => {
      setUsers(usersResponse);
      const filteredUsers = usersResponse?.filter(
        row => row?.status.toLowerCase() === activeInActiveFilter.toLowerCase()
      );
      setFilteredUsers(filteredUsers);
    });
    setName('');
    setRole('');
  }, []);

  const [filteredRows, setFilteredRows] = useState([]);

  const searchInTable = async () => {
    let filteredRow = users;
    filteredRow = users
      ?.filter(row => {
        if (!name) return row.firstname;
        else {
          return (
            row?.firstname.toLowerCase()?.includes(name.toLowerCase()) ||
            row?.lastname.toLowerCase().includes(name.toLowerCase())
          );
        }
      })
      ?.filter(row => {
        if (!role || role === 'Select One') return row;
        return row?.roleseq?.includes(role);
      })
      ?.filter(row => {
        if (!county || county === 'Select One') return row;
        let countyCode = [];
        fetchCountyCode(county).then(countyArray => {
          if (row?.county?.includes(countyArray?.[0]?.code)) {
            countyCode.push(row);
          }
        });
        setFilteredRows(countyCode);
        return filteredRows;
      })
      ?.filter(row => {
        if (activeInActiveFilter.toLowerCase() === 'all') return row;
        return row?.status.toLowerCase() === activeInActiveFilter.toLowerCase();
      });
    setFilteredUsers(filteredRow);
  };

  useEffect(() => {
    searchInTable();
    // setFilteredUsers(users?.filter((row) => {
    //   if (activeInActiveFilter.toLowerCase() === 'all') return row;
    //   return row?.status.toLowerCase() === activeInActiveFilter.toLowerCase();
    // }));
  }, [name, role, activeInActiveFilter, county]);

  const UpdateStatus = async () => {
    if (iconData.status === 'Inactive') {
      await axios
        .post(`${import.meta.env.VITE_API_URL}ActivateUser?userSeq=${iconData.userseq}`, '', {
          headers: authHeader(),
        })
        .then(response => {
          fetchUserCounty()?.then(usersCounty => {
            setUsers(usersCounty);
            setFilteredUsers(usersCounty);
            setName('');
            setRole('');
            setCounty('');
          });
          setDialogMessageModal(true);
          setDialogMessage(response.data.message);
          setActiveInActiveFilter('all');
        })
        .catch(error => {
          console.log('error', error);
        });
    } else {
      await axios
        .post(`${import.meta.env.VITE_API_URL}InactivateUser?userSeq=${iconData.userseq}`, '', {
          headers: authHeader(),
        })
        .then(response => {
          fetchUserCounty()?.then(usersCounty => {
            setUsers(usersCounty);
            setFilteredUsers(usersCounty);
            setName('');
            setRole('');
            setCounty('');
          });
          setDialogMessageModal(true);
          setDialogMessage(response.data.message);
          setActiveInActiveFilter('all');
        })
        .catch(error => {
          console.log('error', error);
        });
    }

    setOpen(false);
  };

  const checkStatus = name => {
    const selectedUser = users?.filter(user => user?.username === name?.[0]);
    return selectedUser?.[0]?.status.toLowerCase() === 'inactive';
  };

  if (usersPermissions?.view) {
    return (
      <div>
        <Stack>
          <Typography variant='h5' component='h1'>
            <b>User Admin</b>
          </Typography>
          <Typography variant='subtitle1' color='gray'>
            Create, Edit and Modify User Accounts
          </Typography>
        </Stack>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <br />

        <Box style={{ marginTop: '0px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={4}>
              <FormControl variant='outlined' fullWidth>
                <TextField
                  value={name}
                  onChange={e => setName(e.target.value)}
                  size='medium'
                  variant='outlined'
                  id='firstOrLastName'
                  label='Search First or Last Name'
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={4}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='countrySeq' id='countrySeq'>
                  Role
                </InputLabel>
                <Select
                  label='Role'
                  id='role'
                  name='role'
                  size='large'
                  value={role}
                  defaultValue={'Select One'}
                  onChange={e => setRole(e.target.value)}
                  style={{ width: '100%', height: '100%' }}
                >
                  {roleOptions?.map(roleOption => {
                    if (roleOption) {
                      return <MenuItem value={roleOption?.value}>{roleOption?.name}</MenuItem>;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={4}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='countrySeq' id='countrySeq'>
                  Jurisdiction
                </InputLabel>
                <Select
                  label='Jurisdiction'
                  id='county'
                  name='county'
                  size='large'
                  value={county}
                  onChange={e => setCounty(e.target.value)}
                  style={{ width: '100%', height: '100%' }}
                >
                  {countiesOptions?.map(countyOption => {
                    if (countyOption?.name) {
                      return <MenuItem value={countyOption?.value}>{countyOption?.name}</MenuItem>;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='isActive'
                onChange={e => setActiveInActiveFilter(e.target.value)}
                value={activeInActiveFilter}
                sx={{ marginLeft: 1 }}
              >
                {' '}
                <FormControlLabel value='Active' control={<Radio />} label='Active Only' />{' '}
                <FormControlLabel value='InActive' control={<Radio />} label='Inactive Only' />{' '}
                <FormControlLabel value='all' control={<Radio />} label='All' />{' '}
              </RadioGroup>
            </Grid>
          </Grid>

          <Box
            mt={2}
            // sx={{
            //   '& .super-app-theme--header': {
            //     backgroundColor: 'rgb(2, 136, 209)',
            //   },
            // }}
          >
            <DataGridPremium
              // className='ViewTable'
              getRowId={row => row.username}
              rows={filteredUsers}
              columns={columns}
              columnHeaderHeight={50}
              rowHeight={30}
              pageSize={50}
              scrollbarSize={5}
              pagination
              slots={{
                pagination: CustomPagination,
              }}
              onRowSelectionModelChange={name => {
                if (usersPermissions?.edit && !checkStatus(name) && name?.length !== 0) {
                  const selectedUser = users?.filter(user => user?.username === name?.[0]);
                  fetchUserProfileInformation(selectedUser?.[0]?.userseq).then(
                    profileInformation => {
                      sendUserProfile(profileInformation);
                      navigate('/edit-user');
                    }
                  );
                }
              }}
              // sx={{
              //   width: '100%',
              //   height: 450,
              //   // border: 'none',
              //   borderRadius: '6px',
              //   backgroundColor: 'white',
              //   // boxShadow: '0px 0px 10px #ddd',
              // }}
            />

            <Button
              variant='contained'
              color='info'
              sx={{ mt: 2 }}
              onClick={() => {
                sendUserProfile({});
                navigate('/add-user');
              }}
              disabled={!usersPermissions?.add}
              startIcon={<ControlPointIcon />}
            >
              Add User
            </Button>

            {/* Update status modal */}
            <div className='modal'>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby='alert-dialog-slide-description'
              >
                <DialogContent>
                  <DialogContentText id='alert-dialog-slide-description'>
                    Are you sure you want to{' '}
                    {iconData?.status === 'Active' ? 'InActive ' : 'Active'} this user?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>No</Button>
                  <Button onClick={UpdateStatus}>Yes</Button>
                </DialogActions>
              </Dialog>
            </div>
            <ApiNotification
              openApiDialogBox={dialogMessageModal}
              closeDialogBox={() => setDialogMessageModal(false)}
              dialogContents={dialogMessage || ''}
            />
          </Box>
        </Box>
      </div>
    );
  } else {
    return (
      <div>
        <h4 style={{ color: 'red' }}>Access Denied</h4>
      </div>
    );
  }
};

export default UsersAdminView;
