import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LockIcon from '@mui/icons-material/Lock';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../services/utility-api';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const id = '00000000-0000-0000-0000-000000000000';

const AddEdit = ({
  editObj,
  referenceMasterTableName,
  isCounty,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  row,
  caseClassification,
  caseClassificationMapping,
  priority,
  priorityMapping,
  referencePermissions,
}) => {
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const initialValues = {
    ...editObj,
    priorityName: editObj?.priorityName || ['', 'Low'],
    caseLocationName: editObj?.caseLocationName || '',
    caseLocationOrder: editObj?.caseLocationOrder
      ? Number(editObj.caseLocationOrder)
      : maxOrder + 10, // Pls explain this.
    caseLocationOnCaseClassification: editObj?.caseLocationOnCaseClassification || [],
  };

  const validationSchema = Yup.object({
    caseLocationName: Yup.string().required('Detail Name is required').nullable(),
    caseLocationOrder: Yup.string().required('List Order is required').nullable(),
  });

  const onSave = async values => {
    values.caseLocationOnCaseClassification =
      values?.caseLocationOnCaseClassification.filter(data => data?.caseClassificationSeq !== id) ??
      [];
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Grid elevation={0} spacing={2} sx={{ padding: '16px', marginLeft: '-16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={3} mt={1}>
            <Typography variant='h5'>
              <strong>
                {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Case Location
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
          {({ handleSubmit, values, handleChange }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12} md={6} log={6} mt={0}>
                        <FormControl fullWidth>
                          <InputLabel id='caseLocationLabel'>Jurisdiction</InputLabel>
                          <Select
                            labelId='caseLocationLabel'
                            id='countySeq'
                            onChange={handleChange}
                            variant='outlined'
                            label='Jurisdiction'
                            focused
                            displayEmpty
                            size='large'
                            name={`countySeq`}
                            value={values?.countySeq || ''}
                            disabled={!editObj}
                          >
                            {Object.keys(countyNames).map((item, z) => {
                              return (
                                <MenuItem
                                  key={countyNames[item].countySeq}
                                  value={countyNames[item].countySeq}
                                  sx={{
                                    marginLeft:
                                      countyNames[item].countyName == 'New Jersey' ||
                                      countyNames[item].countyName == 'NJ State Case' ||
                                      countyNames[item].countyName == 'MAiD Case'
                                        ? ''
                                        : '15px',
                                  }}
                                >
                                  {countyNames[item].countyName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl fullWidth error variant='standard'>
                          <TextField
                            required
                            focused
                            id='outlined-basic'
                            label={'Details Name'}
                            variant='outlined'
                            name='caseLocationName'
                            value={values?.caseLocationName}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={'caseLocationName'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl error fullWidth variant='standard'>
                          <TextField
                            focused
                            required
                            id='caseLocationOrder'
                            label='List Order'
                            variant='outlined'
                            name='caseLocationOrder'
                            margin='normal'
                            size='large'
                            type='number'
                            value={values?.caseLocationOrder}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          />
                          <ErrorMessage
                            name={'caseLocationOrder'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid container spacing={2} style={{ padding: '0px 0px 0px 30px' }}>
                        <Grid item xs={12} mt={2}>
                          <Button
                            onClick={() => {
                              handleChange({
                                target: {
                                  name: 'caseLocationOnCaseClassification',
                                  value: [
                                    ...values.caseLocationOnCaseClassification,
                                    {
                                      caseLocationOnCaseClassificationSeq: id,
                                      caseLocationOnCaseClassificationPrioritySeq: id,
                                      caseClassificationSeq: id,
                                      prioritySeq: id,
                                      status: 'Active',
                                    },
                                  ],
                                },
                              });
                            }}
                            variant='contained'
                            startIcon={<AddCircleIcon />}
                          >
                            Add Details
                          </Button>
                        </Grid>
                        {values?.caseLocationOnCaseClassification.length > 0 ? (
                          <Grid item xs={12}>
                            <List
                              sx={{
                                bgcolor: 'rgb(2, 136, 209)',
                                py: '0px',
                                color: '#fff',
                              }}
                            >
                              <ListItem>
                                <Grid container spacing={2}>
                                  <Grid
                                    xs={5}
                                    item
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      left: '-16px',
                                    }}
                                  >
                                    <Typography variant='h5' mt={0} sx={{ color: '#fff' }}>
                                      Details
                                    </Typography>
                                    <Divider
                                      sx={{
                                        position: 'absolute',
                                        right: '-10px',
                                      }}
                                    >
                                      |
                                    </Divider>
                                  </Grid>
                                  <Grid
                                    xs={5}
                                    item
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      left: '-16px',
                                    }}
                                  >
                                    <Typography variant='h5' mt={0} sx={{ color: '#fff' }}>
                                      Priority
                                    </Typography>
                                    <Divider
                                      sx={{
                                        position: 'absolute',
                                        right: '-10px',
                                      }}
                                    >
                                      |
                                    </Divider>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            </List>

                            <List mt={4}>
                              {values.caseLocationOnCaseClassification?.map((item, index) => (
                                <ListItem
                                  key={index}
                                  id={index}
                                  sx={{
                                    py: '8px',
                                    marginBottom: '5px',
                                    backgroundColor: '#f3f4f4',
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid
                                      xs={5}
                                      item
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        left: '-16px',
                                      }}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>
                                          Details
                                        </InputLabel>
                                        <Select
                                          labelId='demo-simple-select-label'
                                          label='Details'
                                          disabled={
                                            values?.caseLocationOnCaseClassification[index]
                                              ?.caseLocationOnCaseClassificationSeq != id
                                          }
                                          id={`caseLocationOnCaseClassification.${index}.caseClassificationSeq`}
                                          name={`caseLocationOnCaseClassification.${index}.caseClassificationSeq`}
                                          value={
                                            values['caseLocationOnCaseClassification'][index][
                                              'caseClassificationSeq'
                                            ]
                                          }
                                          onChange={e => {
                                            handleChange({
                                              target: {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          {caseClassification?.map((item, z) => {
                                            return (
                                              <MenuItem
                                                value={item.caseClassificationSeq}
                                                key={item.caseClassificationSeq}
                                              >
                                                {item.caseClassificationName}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      xs={5}
                                      item
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        left: '-16px',
                                      }}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>
                                          Priority
                                        </InputLabel>
                                        <Select
                                          labelId='demo-simple-select-label'
                                          label='priorityName'
                                          id={`caseLocationOnCaseClassification.${index}.prioritySeq`}
                                          name={`caseLocationOnCaseClassification.${index}.prioritySeq`}
                                          value={
                                            values['caseLocationOnCaseClassification'][index][
                                              'prioritySeq'
                                            ]
                                          }
                                          disabled={
                                            values['caseLocationOnCaseClassification'][index][
                                              'caseClassificationSeq'
                                            ] === id
                                          }
                                          onChange={e => {
                                            handleChange({
                                              target: {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          {priority?.map((item, z) => {
                                            return (
                                              <MenuItem
                                                value={item.prioritySeq}
                                                key={item.prioritySeq}
                                              >
                                                {item.priorityName}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    {values.caseLocationOnCaseClassification[index]
                                      .caseLocationOnCaseClassificationSeq == id && (
                                      <Grid
                                        xs={2}
                                        item
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          position: 'relative',
                                        }}
                                      >
                                        <Button
                                          onClick={() => {
                                            handleChange({
                                              target: {
                                                name: 'caseLocationOnCaseClassification',
                                                value:
                                                  values.caseLocationOnCaseClassification.filter(
                                                    (_, ind) => ind != index
                                                  ),
                                              },
                                            });
                                            return;
                                          }}
                                          aria-label='delete'
                                          size='large'
                                          id={`row_id_${index}`}
                                          sx={{
                                            backgroundColor: 'rgb(2, 136, 209)',
                                          }}
                                        >
                                          <DeleteIcon fontSize='inherit' sx={{ color: '#fff' }} />
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                        ) : (
                          <></>
                        )}

                        <Grid item xs={12}>
                          <Box>
                            <Button
                              type='submit'
                              variant='contained'
                              sx={{ minWidth: '125px', marginTop: '7px' }}
                              onClick={handleSubmit}
                              disabled={!referencePermissions?.add}
                            >
                              {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>

        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};
export default AddEdit;
