import { Chip, Stack, Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import '../../App.css';

export function DecedentNameCell(
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) {
  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'
      justifyContent='left'
      whiteSpace='normal'
      sx={{ height: '100%' }}
    >
      <Typography
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontFamily: 'DataGrid, sans-serif',
        }}
      >
        {params.row.decedentname?.toUpperCase()}
        {params.row.decedentName?.toUpperCase()}
      </Typography>

      {Boolean(params.row.isTentative) && (
        <Chip
          label='TENT'
          size='small'
          color='error'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}

      {Boolean(params.row?.isUnidentified) && (
        <Chip
          label='UNID'
          size='small'
          color='error'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}

      {Boolean(params.row?.hasObjection) && (
        <Chip
          label='OBJ'
          color='error'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}

      {Boolean(params.row?.isGrouped) && (
        <Chip
          label='G'
          color='warning'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}

      {Boolean(params.row?.isOrganDonor) && (
        <Chip
          label='ORGN'
          color='error'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}
    </Stack>
  );
}
