import { useEffect, useState } from 'react';
import Viewer360 from '../components/360DegreeViewer';
import { useAuth } from '../utils/auth/AuthService';
import { Typography } from '@mui/material';

const imageSrc =
  'https://l13.alamy.com/360/T258GN/full-seamless-spherical-panorama-360-degrees-angle-view-on-bank-of-wide-river-in-front-of-bridge-in-city-center-360-panorama-in-equirectangular-proje-T258GN.jpg';

export default function Viewer360Test() {
  const [loadingImage, setLoadingImage] = useState(false);
  const [image, setImage] = useState('');
  const auth = useAuth();
  const { VITE_API_URL } = import.meta.env;

  const fetchImage = async () => {
    setLoadingImage(true);

    let formData = new FormData();
    formData.append('PhotoSeq', 'bd1f5fc3-35bb-4caf-a2d7-426d8d47c761');
    formData.append('UserSeq', auth.user?.userSeq ?? '');

    await fetch(VITE_API_URL + 'casephotos/retrieve/original/single', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + auth.user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setImage('data:image/jpeg;base64,' + data.imageString);
        setLoadingImage(false);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return loadingImage ? <Typography>Loading image...</Typography> : <Viewer360 image={image} />;
}
