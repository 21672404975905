import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  FormGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../services/utility-api';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  isCode,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  referencePermissions,
}) => {
  const id = '00000000-0000-0000-0000-000000000000';
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const onSave = async values => {
    // make api call to save add data
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          isPhoto: values?.isPhoto,
          isDocument: values?.isDocument,
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} mb={2} mt={2}>
          <Typography variant='h5'>
            <strong>
              {Object.keys(editObj).length ? 'Modify ' : 'Enter New '}{' '}
              {referenceMasterTableName
                .split('')
                .map(alpha => {
                  if (alpha <= 'Z') return ' ' + alpha;
                  return alpha;
                })
                .join('')
                .trim()}
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...editObj,
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Name']:
            editObj[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Name'
            ],
          photoTypeOrder: editObj?.photoTypeOrder ? Number(editObj.photoTypeOrder) : maxOrder + 10,
          isPhoto: editObj?.isPhoto ?? false,
          isDocument: editObj?.isDocument ?? false,
        }}
        validationSchema={Yup.object().shape({
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Name']:
            Yup.string()
              .required(
                `${referenceMasterTableName
                  .split('')
                  .map(alpha => {
                    if (alpha <= 'Z') return ' ' + alpha;
                    return alpha;
                  })
                  .join('')
                  .trim()} is required`
              )
              .max(
                referenceTypeFieldLengthChecks[`photoTypeName`],
                `Photo Type name can not be more than ${referenceTypeFieldLengthChecks['photoTypeName']}`
              )
              .nullable(),
          photoTypeOrder: Yup.number()
            .required('Photo Type Order is Required')
            .max(99999, 'Photo Type Order can not be more than 99999')
            .nullable(),
          countySeq: isCounty
            ? Yup.string().required('Jurisdiction is Required').nullable()
            : Yup.string().nullable(),
        })}
        onSubmit={onSave}
      >
        {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {isCounty && (
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth focused sx={{ minWidth: '88%' }}>
                      <InputLabel htmlFor='countySeq' id='countySeq'>
                        Jurisdiction
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        variant='outlined'
                        label='Jurisdiction'
                        focused
                        displayEmpty
                        size='small'
                        name={`countySeq`}
                        value={values?.countySeq || ''}
                      >
                        {Object.keys(countyNames).map((item, z) => {
                          return (
                            <MenuItem
                              key={countyNames[item].countySeq}
                              value={countyNames[item].countySeq}
                            >
                              {countyNames[item].countyName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label={referenceMasterTableName
                        .split('')
                        .map(alpha => {
                          if (alpha <= 'Z') return ' ' + alpha;
                          return alpha;
                        })
                        .join('')
                        .trim()}
                      variant='outlined'
                      onInput={e => {
                        e.target.value = e.target.value.slice(
                          0,
                          referenceTypeFieldLengthChecks['photoTypeName']
                        );
                      }}
                      name={
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                      }
                      value={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ]
                      }
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Unknown'
                      }
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name={
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                      }
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='List Order'
                      variant='outlined'
                      name='photoTypeOrder'
                      type='number'
                      value={values.photoTypeOrder}
                      onChange={handleChange}
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Unknown'
                      }
                    />
                    <ErrorMessage
                      name='photoTypeOrder'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormGroup>
                    <FormControlLabel
                      focused
                      name='isPhoto'
                      checked={values.isPhoto}
                      onChange={handleChange}
                      control={<Checkbox />}
                      label='Is Photo'
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Unknown'
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormGroup>
                    <FormControlLabel
                      focused
                      name='isDocument'
                      checked={values.isDocument}
                      onChange={handleChange}
                      control={<Checkbox />}
                      label='Is Document'
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] === 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] === 'Unknown'
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={handleSubmit}
                      disabled={!referencePermissions?.add}
                    >
                      {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <div className='modal'>
        <Dialog
          open={openApiDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenApiDialogBox(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {dialogContents}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenApiDialogBox(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddEdit;
