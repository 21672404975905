import { EditRounded, SaveRounded } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  CaseAssignedList,
  CaseAssignmentManagerAPI,
} from '../../utils/api/CaseAssignmentManager.api';
import { useOptions } from '../../utils/api/useOptions.hook';
import { useAuth } from '../../utils/auth/AuthService';
import { AutocompleteMultiCheckbox } from '../../utils/components/MultiSelectAutocomplete';
import { AllRoles } from '../../utils/constants/AllRoles';
import { Option } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';

interface CaseViewAssignmentsDialogProps {
  open: boolean;
  setShouldRefetchCaseDataOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  jdxSeqList?: string[];
}

function CaseViewAssignmentsDialogInternal({
  open,
  setShouldRefetchCaseDataOnClose,
  onClose,
  jdxSeqList = [],
}: CaseViewAssignmentsDialogProps) {
  const auth = useAuth();
  const location = useLocation();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);

  const [caseAssignmentList, setCaseAssignmentList] = useState<CaseAssignedList>({
    caseSeq: '',
    assignedPathologist: null,
    assignedInvestigator: null,
    administrativeAssistants: [],
    assignedMorgueTechs: [],
  });

  const getCaseAssignmentList = async () => {
    CaseAssignmentManagerAPI.getCaseAssignmentList(
      auth.user?.accessToken!,
      location.state.cmscaseid
    )
      .then(list => {
        setCaseAssignmentList(list);
        setReady(true);
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const caseId = location.state.cmscaseid ?? null;
    if (caseId !== null && !ready && loading) {
      getCaseAssignmentList();
    }
  }, [location.state]);

  const authorizedToEdit =
    auth.user?.roleCheck([
      AllRoles.Investigator,
      AllRoles.Medical_Examiner,
      AllRoles.Systems_Admin,
      AllRoles.Administrative_Assistant,
    ]) ?? false;

  const historicDataEntryUser = auth.user?.roleCheck(['08b']);  //[HISTORIC-DATAENTRYONLY]

    const isAssignPathologist =   auth.user?.roleCheck(['451', 'APA']) //[SYSTEMS-ADMIN, ASSIGN-PATHOLOGIST]
    const isAssignForensicTech =   auth.user?.roleCheck(['451', 'AFA']) //[SYSTEMS-ADMIN, ASSIGN-FOR-TECH]

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [successMessage, setSuccessMessage] = useState<string[]>([]);

  const {
    assignedPathologist,
    assignedInvestigator,
    assignedMorgueTechs,
    administrativeAssistants,
  } = caseAssignmentList;

  const { options: pathologistOptions, loading: pathologistOptionsLoading } = useOptions(
    'pathologist options',
    { jdxSeq: jdxSeqList, isActive: historicDataEntryUser ? false : true}
  );

  const { options: investigatorOptions, loading: investigatorOptionsLoading } = useOptions(
    'investigator options',
    { jdxSeq: jdxSeqList, isActive: historicDataEntryUser ? false : true }
  );

  const { options: morgueTechOptions, loading: morgueTechOptionsLoading } = useOptions(
    'morgue tech options',
    { jdxSeq: jdxSeqList }
  );

  const {
    options: administrativeAssistantOptions,
    loading: administrativeAssistantOptionsLoading,
  } = useOptions('administrative assistant options', { jdxSeq: jdxSeqList });

  const formik = useFormik({
    initialValues: {
      selectedPathologist: assignedPathologist
        ? {
            optionName:
            pathologistOptions.find(
                o => o.optionSeq.toUpperCase() === assignedPathologist.userSeq.toUpperCase()
              )?.optionName ||
              `${assignedPathologist.person.personLastName}, ${assignedPathologist.person.personFirstName} (Inactive)`,
            optionSeq: assignedPathologist.userSeq,
          }
        : null,

      selectedInvestigator: assignedInvestigator
        ? {
            optionName:
              investigatorOptions.find(
                o => o.optionSeq.toUpperCase() === assignedInvestigator.userSeq.toUpperCase()
              )?.optionName ||
              `${assignedInvestigator.person.personLastName}, ${assignedInvestigator.person.personFirstName} (Inactive)`,
            optionSeq: assignedInvestigator.userSeq,
          }
        : null,

      selectedMorgueTechs: morgueTechOptions?.filter(mto =>
        assignedMorgueTechs?.find(
          amt => amt.userSeq?.toLowerCase() === mto.optionSeq?.toLowerCase()
        )
      ),
      selectedAdministrativeAssistants: administrativeAssistants?.map(aa => {
        const hasAccessToJdx =
          administrativeAssistantOptions.find(
            o => o.optionSeq.toUpperCase() === aa.userSeq.toUpperCase()
          ) ?? null;
        if (hasAccessToJdx) {
          return {
            optionName: `${aa.person.personLastName}, ${aa.person.personFirstName}`,
            optionSeq: aa.userSeq,
          };
        }

        return {
          optionName: `${aa.person.personLastName}, ${aa.person.personFirstName} (Inactive)`,
          optionSeq: aa.userSeq,
        };
      }),
    },
    enableReinitialize: true,
    onSubmit: async values => {
      setSaving(true);
      const successMessages: string[] = [];

      try {
          await CaseAssignmentManagerAPI.assignPathologist(
            auth.user?.accessToken!,
            location.state.cmscaseid,
            values.selectedPathologist?.optionSeq || null
          );
          successMessages.push('Pathologist successfully updated.');

        await CaseAssignmentManagerAPI.assignInvestigator(
          auth.user?.accessToken!,
          location.state.cmscaseid,
          values.selectedInvestigator?.optionSeq || null
        );
        successMessages.push('Investigator successfully updated.');

      
        const selectedMorgueTechsSeq = values.selectedMorgueTechs?.map(mt =>
          mt.optionSeq?.toLowerCase()
        );
        await CaseAssignmentManagerAPI.assignMorgueTechs(
          auth.user?.accessToken!,
          location.state.cmscaseid,
          selectedMorgueTechsSeq
        );
        successMessages.push('Morgue Techs successfully updated.');

        const selectedAdministrativeAssistantsSeq = values.selectedAdministrativeAssistants?.map(
          aa => aa.optionSeq?.toLowerCase()
        );
        await CaseAssignmentManagerAPI.assignAdministrativeAssistants(
          auth.user?.accessToken!,
          location.state.cmscaseid,
          selectedAdministrativeAssistantsSeq
        );
        successMessages.push('Administrative Assistants successfully updated.');

        setEditing(false);
        setSuccessMessage(successMessages);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          setErrors([error]);
        }
      } finally {
        setShouldRefetchCaseDataOnClose(true);
        setSaving(false);
      }
    },
  });

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    formik.handleSubmit();
  };

  const handleCloseSnackbar = () => {
    setErrors([]);
    setSuccessMessage([]);
  };

  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth='sm' fullWidth>
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            {editing ? `Editing Case Assignments` : `Case Assignments`}
            {editing ? (
              <Button
                variant='text'
                color='primary'
                startIcon={saving ? <CircularProgress size={24} /> : <SaveRounded />}
                onClick={handleSaveClick}
                disabled={!authorizedToEdit || loading || saving}
              >
                {editing ? (saving ? `Saving` : 'Save') : `Edit`}
              </Button>
            ) : (
              <Tooltip
                title={
                  authorizedToEdit
                    ? 'Edit Case Assignments'
                    : 'You do not have permission to edit case assignments'
                }
              >
                <Button
                  variant='text'
                  color='primary'
                  startIcon={<EditRounded />}
                  onClick={handleEditClick}
                  disabled={!authorizedToEdit || loading}
                >
                  Edit
                </Button>
              </Tooltip>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <br />
          <Autocomplete
            suppressHydrationWarning
            size='small'
            options={pathologistOptions}
            loading={pathologistOptionsLoading}
            getOptionLabel={o => o.optionName}
            isOptionEqualToValue={(o, v) =>
              o.optionSeq?.toLowerCase() === v.optionSeq?.toLowerCase()
            }
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                label='Pathologist'
                variant='outlined'
                fullWidth
              />
            )}
            disabled={!isAssignPathologist || pathologistOptionsLoading || !editing || saving}
            value={formik.values.selectedPathologist}
            onChange={(event, value) => {
              formik.setFieldValue('selectedPathologist', value || null);
            }}
            sx={{ marginBottom: 2 }}
          />
          <Autocomplete
            suppressHydrationWarning
            size='small'
            options={investigatorOptions}
            loading={investigatorOptionsLoading}
            getOptionLabel={o => o.optionName}
            isOptionEqualToValue={(o, v) =>
              o.optionSeq?.toLowerCase() === v.optionSeq?.toLowerCase()
            }
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                label='MLDI'
                variant='outlined'
                fullWidth
              />
            )}
            disabled={
              !authorizedToEdit || investigatorOptionsLoading || !editing || loading || saving
            }
            value={formik.values.selectedInvestigator}
            onChange={(event, value) => {
              formik.setFieldValue('selectedInvestigator', value || null);
            }}
            sx={{ marginBottom: 2 }}
          />

          <AutocompleteMultiCheckbox<Option>
            label='Forensic Techs'
            size='small'
            options={morgueTechOptions}
            selectedOptions={formik.values.selectedMorgueTechs}
            loading={morgueTechOptionsLoading || loading}
            disabled={!isAssignForensicTech || !editing || loading || saving}
            onSelectedOptionsChange={selected => {
              formik.setFieldValue('selectedMorgueTechs', selected);
            }}
            sx={{ mb: 2 }}
          />

          <AutocompleteMultiCheckbox<Option>
            label='Administrative Assistants'
            size='small'
            options={administrativeAssistantOptions}
            selectedOptions={formik.values.selectedAdministrativeAssistants}
            loading={administrativeAssistantOptionsLoading || loading}
            disabled={!authorizedToEdit || !editing || loading || saving}
            onSelectedOptionsChange={selected => {
              formik.setFieldValue('selectedAdministrativeAssistants', selected);
            }}
          />

          <Snackbar
            open={errors.length > 0}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity='error' sx={{ width: '100%' }} onClose={handleCloseSnackbar}>
              <AlertTitle>Error while saving Case Assignment</AlertTitle>
              <ul>
                {errors.map((e, index) => (
                  <li key={index}>{e.message}</li>
                ))}
              </ul>
            </Alert>
          </Snackbar>
          <Snackbar
            open={successMessage.length > 0}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity='success' sx={{ width: '100%' }} onClose={handleCloseSnackbar}>
              <AlertTitle>Case Assignments Updated</AlertTitle>
              <ul>
                {successMessage.map(m => (
                  <li>{m}</li>
                ))}
              </ul>
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const CaseViewAssignmentsDialog = React.memo(
  CaseViewAssignmentsDialogInternal,
  (prevProps, nextProps) => {
    return (
      prevProps.open === nextProps.open &&
      prevProps.onClose === nextProps.onClose &&
      prevProps.jdxSeqList === nextProps.jdxSeqList
    );
  }
);
