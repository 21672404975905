import { Article, Biotech, Science } from '@mui/icons-material';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LMSCaseLoading from '../../../../components/LMS/LMSCaseLoading';
import LMSCaseViewHeader from '../../../../components/LMS/LMSCaseViewHeader';
import { useAuth } from '../../../../utils/auth/AuthService';
import CustomDrawer from '../../../../utils/components/CustomDrawer';
import CustomTabPanel from '../../../../utils/components/CustomTabPanel';
import { useDebouncedValidate } from '../../../../utils/functions/debouncedFormikValidation';
import ELabDonorSamplesView from './ELabDonorSamples';
import ELabPMTestResultsView from './ELabPMTestResults';
import ElabPMCaseSummary from './ElabPMCaseSummary';

export default function ELabPMCaseView() {
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accessRestricted, setAccessRestricted] = useState(false);

  const { state } = useLocation();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const { VITE_API_URL } = import.meta.env;

  const drawerContents = [
    {
      text: 'Summary',
      icon: <Article />,
    },
    {
      text: 'Donor Samples',
      icon: <Science />,
    },
    {
      text: 'Test Results',
      icon: <Biotech />,
    },
    // {
    //   text: 'Documents',
    //   icon: <PictureAsPdf />,
    // },
  ];

  const formik = useFormik({
    initialValues: null,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      console.log(values);
    },
    initialStatus: {
      moduleVisibilityStatus: {
        casesummary: {
          enabled: true,
          visible: true,
        },
        samplelist: {
          enabled: true,
          visible: true,
        },
        labtestresultlist: {
          enabled: true,
          visible: true,
        },
      },
    },
  });

  // This changes formik's default behavior from validating on every input
  // to validation 200ms after the last input, which should improve
  // performance both locally and in the deployed versions
  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const GetELabPMCaseData = async () => {
    const userHasRole = await user.hasRole('MDXLMS-ACCESS');

    console.log(userHasRole);

    if (userHasRole) {
      let formData = new FormData();
      formData.append('ELABCASENUMBER', state.elabcasenumber);

      setLoading(true);

      // GET CASE DATA
      await fetch(VITE_API_URL + 'getelabpmcasedata', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You are unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          if (res.status == 204) {
            formik.setValues(null);
          }
          return res.json();
        })
        .then(data => {
          formik.setValues(data);
        })
        .catch(e => {
          //handle error
        });

      setLoading(false);
    } else {
      setAccessRestricted(true);
      setLoading(false);
    }
  };

  const AccessRestricted = () => {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            p: 3,
            pb: 0,
            backgroundColor: theme.palette.background.default,
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 20,
          }}
        >
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            {/* CaseID */}
            <Typography variant='h5' component='h1'>
              <b>****** ****** ({state.elabcasenumber})</b>
            </Typography>
          </Box>

          <Divider sx={{ borderColor: '#555', m: 0, mt: 3 }} />
        </Box>

        <Box sx={{ p: 3, width: '100%' }}>
          <Typography variant='h3' component='h2' sx={{ m: 2 }}>
            Access Denied
          </Typography>
          <br />

          <Typography variant='body1' sx={{ m: 2 }}>
            <i>You do not have permission to view this case.</i>
          </Typography>
          <br />

          <Button
            variant='outlined'
            sx={{ m: 2, mt: 4 }}
            size='large'
            onClick={() => {
              navigate('/home');
            }}
          >
            Return To Home
          </Button>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    GetELabPMCaseData();
  }, [state.elabcasenumber]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      {loading ? (
        <LMSCaseLoading />
      ) : accessRestricted ? (
        <AccessRestricted />
      ) : (
        <FormikProvider value={formik}>
          <Form style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <LMSCaseViewHeader handleMenuClick={handleMenuClick} />

              <CustomTabPanel value={currentTab} index={0}>
                <ElabPMCaseSummary />
                <ELabDonorSamplesView />
              </CustomTabPanel>
              <CustomTabPanel value={currentTab} index={1}>
                <ELabDonorSamplesView />
              </CustomTabPanel>
              <CustomTabPanel value={currentTab} index={2}>
                <ELabPMTestResultsView />
              </CustomTabPanel>
              <CustomTabPanel value={currentTab} index={3}>
                {/* <ELabPMCaseDocumentsView /> */}
              </CustomTabPanel>
            </Box>
          </Form>
        </FormikProvider>
      )}
    </Box>
  );
}
