import { Dayjs } from 'dayjs';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';

export interface Incident {
  caseCount: number;
  createdBy: string | null;
  createdOn: Dayjs | null;
  groupID: string;
  incidentDateTime: Dayjs | null;
  incidentDescription: string | null;
  incidentGroupSeq: string;
  isActive: boolean;
}

export interface Question {
  questionSeq: string;
  questionText: string;
}

interface IncidentContextProps {
  incident: Incident | null;
  setIncident: React.Dispatch<React.SetStateAction<Incident | null>>;
  incidentOptions: Incident[];
  dashboardRows: Incident[];
  dashboardRowsLoading: boolean;
  defaultIncidentThreshold: number;
  gradingQuestions: Question[];
  loadingGradingQuestions: boolean;
}

const IncidentContext = createContext<IncidentContextProps | undefined>(undefined);

export const IncidentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [incident, setIncident] = useState<Incident | null>(null);
  const [incidentOptions, setIncidentOptions] = useState<Incident[]>([]);
  const [dashboardRows, setDashboardRows] = useState<Incident[]>([]);
  const [dashboardRowsLoading, setDashboardRowsLoading] = useState<boolean>(true);
  const [gradingQuestions, setGradingQuestions] = useState<Question[]>([]);
  const [loadingGradingQuestions, setLoadingGradingQuestions] = useState<boolean>(false);
  const auth = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const defaultIncidentThreshold = 5;

  const fetchData = async () => {
    setDashboardRowsLoading(true);

    const formData = new FormData();
    formData.append('JDXLIST', JSON.stringify(auth.user?.jdxAccessList));
    formData.append('USERSEQ', auth.user?.userSeq!);

    try {
      const response = await fetch(`${VITE_API_URL}getIncidentGroups`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setDashboardRows(responseData);
        setIncidentOptions(responseData);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setDashboardRowsLoading(false);
    }
  };

  const fetchGradingQuestions = async () => {
    if (incident === null) {
      setGradingQuestions([]);
      return;
    }

    setLoadingGradingQuestions(true);

    await fetch(VITE_API_URL + `dvcs/getquestions?incidentGroupSeq=${incident?.incidentGroupSeq}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth.user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setGradingQuestions(data);
        setLoadingGradingQuestions(false);
      })
      .catch(e => {
        console.error(e);
        setLoadingGradingQuestions(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchGradingQuestions();
  }, [incident]);

  return (
    <IncidentContext.Provider
      value={{
        incident,
        setIncident,
        incidentOptions,
        dashboardRows,
        dashboardRowsLoading,
        defaultIncidentThreshold,
        gradingQuestions,
        loadingGradingQuestions,
      }}
    >
      {children}
    </IncidentContext.Provider>
  );
};

export const useIncident = () => {
  const context = useContext(IncidentContext);
  if (!context) {
    throw new Error('useIncident must be used within an IncidentProvider');
  }
  return context;
};
