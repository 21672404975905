import React from 'react';
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import { Field } from 'formik';

const SelectComponent = ({ label, name, options, disabled, ...rest }) => {
  return (
    <div>
      <Field id={name} name={name} {...rest}>
        {({ field, form }) => {
          return (
            <FormControl
              error={!!form?.errors?.[name]}
              component='fieldset'
              fullWidth
              variant='outlined'
            >
              <InputLabel>{label}</InputLabel>
              <Select
                label={name}
                id='name'
                name={name}
                size='small'
                style={{ width: '100%', height: '100%' }}
                defaultValue='Select One'
                {...rest}
                {...field}
                disabled={disabled}
              >
                <MenuItem key='SelectOne' value=''>
                  Select One
                </MenuItem>
                {options?.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {!!form?.errors?.[name] ? (
                <p style={{ color: '#d32f2f' }}>{form?.errors?.[name]}</p>
              ) : null}
            </FormControl>
          );
        }}
      </Field>
    </div>
  );
};

export default SelectComponent;
