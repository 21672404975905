import { Add, Check, Delete, Save } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SeveritySelectableAlert from '../../components/SelectableSeverityAlert';
import { useAuth } from '../../utils/auth/AuthService';
import CustomHeader from '../../utils/components/CustomHeader';
import LoadingIconButton from '../../utils/components/LoadingIconButton';
import LoadingIconButtonWithResult from '../../utils/components/LoadingIconButtonWithResult';
import RichTextTypography from '../Home/RichTextTypography';
import { CallCenterUsersDataGrid } from './CallCenterUsersDataGrid';
import { Incident, Question } from './IncidentContext';
import DVCSHomeCardsAdmin from './DVCSHomeCardAdmin';

export interface DVCSAlert {
  alertText: string | null;
  alertSeverity: 'warning' | 'success' | 'info' | 'error';
}

export function DVCSAdminView() {
  const [alert, setAlert] = useState<DVCSAlert>({ alertText: '', alertSeverity: 'info' });
  const [savingAlert, setSavingAlert] = useState<boolean>(false);
  const [alertSaveResult, setAlertSaveResult] = useState<'success' | 'failure' | undefined>(
    undefined
  );
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();

  const handleSaveAlert = async () => {
    setSavingAlert(true);

    // Handle Save Alert Here
    try {
      // fetching save endpoint
      const response = await fetch(VITE_API_URL + `dvcs/updatealert?userSeq=${user?.userSeq}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify(alert),
      });

      if (!response.ok) {
        throw new Error(`Failed to save details: ${response.statusText}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error saving details:', error);
      setAlertSaveResult('failure');
      throw error;
    } finally {
      setSavingAlert(false);
      setAlertSaveResult('success');
    }
  };

  const fetchAlert = async () => {
    await fetch(VITE_API_URL + `dvcs/getalert`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAlert(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchAlert();
  }, []);

  return (
    <Box component='div' sx={{ margin: 0, padding: 2 }}>
      {/* Header */}
      <CustomHeader title='DVCS Admin' inDevelopment />

      {/* Customizable Alert Banner */}
      {user?.roleCheck(['DVCS-ADMIN']) && (
        <SeveritySelectableAlert
          sx={{
            alignItems: 'center',
            mb: 2,
            '& .MuiAlert-message': { width: '100%' },
          }}
          value={alert?.alertSeverity ?? 'info'}
          onChange={value => setAlert({ ...alert, alertSeverity: value })}
        >
          <Stack direction='row' spacing={1}>
            <TextField
              placeholder='Alert Text'
              fullWidth
              value={alert?.alertText}
              onChange={event => setAlert({ ...alert, alertText: event.target.value })}
            />
            <LoadingIconButtonWithResult
              loading={savingAlert}
              result={alertSaveResult}
              onClick={handleSaveAlert}
            >
              <Save />
            </LoadingIconButtonWithResult>
          </Stack>
        </SeveritySelectableAlert>
      )}

      <Masonry columns={2} spacing={2}>
        {user?.roleCheck(['DVCS-CALL-CENTER-ADMIN']) && <CallCenterUsersDataGrid />}
        {user?.roleCheck(['DVCS-CALL-CENTER-ADMIN']) && <GradingQuestionsAdmin />}
        {user?.roleCheck(['DVCS-ADMIN']) && <DVCSHomeCardsAdmin />}
      </Masonry>
    </Box>
  );
}

const GradingQuestionsAdmin = () => {
  const [incident, setIncident] = useState<Incident | null>(null);
  const [incidentOptions, setIncidentOptions] = useState<Incident[]>([]);
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const [loadingGradingQuestions, setLoadingGradingQuestions] = useState<boolean>(false);
  const [gradingQuestions, setGradingQuestions] = useState<Question[]>([]);
  const [savingGradingQuestions, setSavingGradingQuestions] = useState<boolean>(false);
  const [deletingGradingQuestions, setDeletingGradingQuestions] = useState<boolean[]>([]);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('JDXLIST', JSON.stringify(user?.jdxAccessList));
    formData.append('USERSEQ', user?.userSeq!);

    try {
      const response = await fetch(`${VITE_API_URL}getIncidentGroups`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setIncidentOptions(responseData);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchGradingQuestions = async () => {
    if (incident == null) {
      setGradingQuestions([]);
      return;
    }

    setLoadingGradingQuestions(true);

    await fetch(VITE_API_URL + `dvcs/getquestions?incidentGroupSeq=${incident?.incidentGroupSeq}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setGradingQuestions(data);
        setLoadingGradingQuestions(false);
      })
      .catch(e => {
        console.error(e);
        setLoadingGradingQuestions(false);
      });
  };

  const handleSaveGradingQuestions = async () => {
    setSavingGradingQuestions(true);

    // Handle Save Grading Questions Here
    try {
      const response = await fetch(
        VITE_API_URL +
          `dvcs/updatequestions?incidentGroupSeq=${incident?.incidentGroupSeq}&userSeq=${user?.userSeq}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
          },
          body: JSON.stringify(gradingQuestions),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to save details: ${response.statusText}`);
      }

      const result = await response.json();
      setShowSuccess(true);
      return result;
    } catch (error) {
      console.error('Error saving details:', error);
      throw error;
    } finally {
      setSavingGradingQuestions(false);
      fetchGradingQuestions();
    }
  };

  const handleDeleteGradingQuestion = async (index: number) => {
    setDeletingGradingQuestions(deletingGradingQuestions.map((q, i) => (i === index ? true : q)));

    // Handle Delete Grading Questions Here
    try {
      const response = await fetch(
        VITE_API_URL + `dvcs/deletequestion?questionSeq=${gradingQuestions[index].questionSeq}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to save details: ${response.statusText}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error saving details:', error);
      throw error;
    } finally {
      setDeletingGradingQuestions(
        deletingGradingQuestions.map((q, i) => (i === index ? false : q))
      );
      fetchGradingQuestions();
    }
  };

  const handleAddNewQuestion = () => {
    setGradingQuestions(prevQuestions => [
      ...prevQuestions,
      { questionSeq: uuidv4(), questionText: '' },
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchGradingQuestions();
  }, [incident]);

  useEffect(() => {
    setDeletingGradingQuestions(gradingQuestions.map(q => false));
  }, [gradingQuestions]);

  return (
    <Stack spacing={1}>
      <Slide direction='right' in={showSuccess} mountOnEnter unmountOnExit>
        <Alert
          icon={<Check fontSize='inherit' />}
          severity='success'
          onClose={() => setShowSuccess(false)}
          sx={{ m: 2, alignItems: 'center' }}
        >
          <Typography>Successfully updated questions.</Typography>
        </Alert>
      </Slide>
      <Autocomplete
        options={incidentOptions}
        value={incident}
        renderInput={params => <TextField {...params} label='Active Incident' />}
        getOptionLabel={o => o.incidentDescription ?? ''}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack>
              <Typography>{option.incidentDescription}</Typography>
              <Typography sx={{ opacity: 0.6 }}>{option.groupID}</Typography>
            </Stack>
          </li>
        )}
        onChange={(event, value) => setIncident(value)}
        fullWidth
      />
      <Paper sx={{ p: 2 }}>
        <div style={{ marginLeft: 'calc(100% - 2rem)' }}>
          <LoadingIconButton
            loading={savingGradingQuestions}
            onClick={handleSaveGradingQuestions}
            disabled={incident === null}
          >
            <Save />
          </LoadingIconButton>
        </div>
        <Divider sx={{ my: 2, opacity: 0.8 }} />
        {loadingGradingQuestions
          ? Array.from({ length: 5 }, () => (
              <Stack spacing={0} sx={{ mb: 3 }}>
                <Skeleton variant='text' />
                <Skeleton variant='text' width='60%' />
                <Skeleton variant='rounded' height={100} sx={{ mt: 1.5 }} />
              </Stack>
            ))
          : gradingQuestions.map((question, index) => (
              <>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography variant='h5'>{index + 1}.</Typography>
                  <LoadingIconButton
                    loading={deletingGradingQuestions[index]}
                    onClick={() => handleDeleteGradingQuestion(index)}
                    color='error'
                  >
                    <Delete />
                  </LoadingIconButton>
                </Stack>
                <RichTextTypography
                  cardContent={question.questionText}
                  setCardContent={newQuestionText =>
                    setGradingQuestions(
                      gradingQuestions.map((q, i) =>
                        i === index ? { ...q, questionText: newQuestionText } : q
                      )
                    )
                  }
                />
                <Divider sx={{ my: 2, opacity: 0.8 }} />
              </>
            ))}
        <Button
          startIcon={<Add />}
          variant='contained'
          onClick={handleAddNewQuestion}
          disabled={incident === null}
        >
          Add New Question
        </Button>
      </Paper>
    </Stack>
  );
};
