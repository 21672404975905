import {
  Box,
  ButtonBase,
  Grid,
  Paper,
  Skeleton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material';

import { useAuth } from '../../../../utils/auth/AuthService';
// @ts-ignore
import getAppTheme from '../../../../utils/theme/getAppTheme';

const { VITE_API_URL } = import.meta.env;
export function Appearance() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Stack spacing={1}>
      <Typography variant='h6' fontWeight='bold' sx={{ mb: 1 }}>
        Appearance
      </Typography>
      <Stack direction='row' spacing={1}>
        <ToggleAppearanceButton mode='light' isSelected={!isDarkMode} />
        <ToggleAppearanceButton mode='dark' isSelected={isDarkMode} />
      </Stack>
    </Stack>
  );
}

function ToggleAppearanceButton({
  mode = 'light',
  isSelected = false,
}: {
  mode: 'light' | 'dark';
  isSelected: boolean;
}) {
  const appTheme = getAppTheme(mode);
  const theme = createTheme(appTheme);

  const { user, userState, userDispatch } = useAuth();

  const isDarkMode = !userState.user?.isDarkMode;


  const handleDarkModeToggle = async () => {
    userDispatch({
      type: 'SET_DARK_MODE',
      payload: isDarkMode,
    });

    try {
      if (!user) return;

      const endpoint = `${VITE_API_URL}user/update`;

      const body = JSON.stringify({
        access_token: user.accessToken,
        setting: 'theme display mode',
        new_value: `${isDarkMode}`,
      });

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error('There was an error toggling theme display mode:', error);
      // Handle or display the error message as required
    }
  };

  return (
    <ButtonBase
      onClick={() => handleDarkModeToggle()}
      disableRipple
      disableTouchRipple
      disabled={isSelected}
    >
      <Stack spacing={1}>
        <ThemeProvider theme={theme}>
          <Box
            component='div'
            width={120}
            maxWidth={120}
            maxHeight={120}
            sx={{
              border: isSelected ? '2px solid' : '2px solid',
              borderColor: isSelected
                ? theme.palette.primary.main
                : isDarkMode
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
              borderRadius: 2,
              backgroundColor: 'transparent',
            }}
          >
            <Paper
              variant='outlined'
              square={false}
              elevation={0}
              sx={{ margin: 1, borderColor: 'transparent' }}
            >
              <Grid container spacing={1} p={1}>
                <Grid item lg={3}>
                  <Skeleton width={20} variant='circular' animation={false} />
                </Grid>
                <Grid item lg={9}>
                  <Skeleton width='100%' variant='rounded' animation={false} />
                </Grid>
                <Grid item lg={12}>
                  <Skeleton width='85%' variant='rounded' animation={false} />
                </Grid>
                <Grid item lg={12}>
                  <Skeleton width='100%' variant='rounded' animation={false} />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </ThemeProvider>
        {mode === 'dark' && <Typography variant='body2'>Dark</Typography>}
        {mode === 'light' && <Typography variant='body2'>Light</Typography>}
      </Stack>
    </ButtonBase>
  );
}
