import { PostAdd, Refresh } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { ChatMessage, ChatRecord, shouldMaskNameAsSupportAgent } from './conversation.types';
import { useConversation } from './ConversationsContext';
import { ConversationTypeFilter } from './ConversationTypeFilter';
import { NewConversation } from './Dialogs/NewConversationDialog';
import { StartFeedbackConversationDialog } from './Dialogs/StartFeedbackDialog';

const formatMessageTime = (dateStr: string) => {
  const date = parseISO(dateStr);
  const now = new Date();

  // Check if the message is from today
  const isToday = date.toDateString() === now.toDateString();

  // Check if the message is from this year
  const isThisYear = date.getFullYear() === now.getFullYear();

  // For messages from today, return only the time
  if (isToday) {
    return format(date, 'h:mm a');
  }

  // For messages from this year but not today, return month/day
  if (isThisYear) {
    return format(date, 'MM/dd');
  }

  // For messages from previous years, include the year
  return format(date, 'MM/dd/yyyy');
};

type ConversationListProps = {
  sortedConversations: ChatRecord[];
  title?: string;
  kind?: string;
};
export function ConversationList({
  sortedConversations = [],
  title = 'User Feedback',
  kind = 'user',
}: ConversationListProps) {
  const { user } = useAuth();
  const {
    updateConversationOpenStatus,
    handleSelectChat,
    selectedChat,
    chats,
    refreshing,
    fetchChatsAccesibleBySupport,
    fetchUserChats,
    typingParticipants,
    supportAgentName,
    conversationTypes,
  } = useConversation();
  const userIsSysAdmin = user?.roleCheck(['451', '8f7']) || false;

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [openFilter, setOpenFilter] = useState(userIsSysAdmin ? 'open' : 'all');

  // @ts-ignore
  const hasUnreadMessages = conversation => {
    return conversation.messages.some(
      // @ts-ignore
      message =>
        !message.readBy.some(
          // @ts-ignore
          reader => reader.userSeq.toLowerCase() === user?.userSeq.toLowerCase()
        )
    );
  };

  // @ts-ignore
  const handleTypeToggle = typeSeq => {
    // @ts-ignore
    setSelectedTypes(prev =>
      prev.includes(
        // @ts-ignore
        typeSeq
      )
        ? prev.filter(t => t !== typeSeq)
        : [...prev, typeSeq]
    );
  };

  const filteredConversations = useMemo(() => {
    let filtered = sortedConversations;
    if (selectedTypes.length > 0) {
      filtered = filtered.filter(conversation =>
        // @ts-ignore
        selectedTypes.includes(conversation.type.conversationTypeSeq)
      );
    }
    if (openFilter !== 'all') {
      filtered = filtered.filter(conversation =>
        openFilter === 'open' ? conversation.isOpen : !conversation.isOpen
      );
    }
    return filtered;
  }, [sortedConversations, selectedTypes, openFilter]);

  // Add these types at the top of your filters
  type OpenFilter = 'open' | 'closed' | 'all';

  const unreadCount = useMemo(() => {
    const getFilteredUnreadCount = (conversations: ChatRecord[]) => {
      // Apply type filter
      let filtered = conversations;
      if (selectedTypes.length > 0) {
        filtered = filtered.filter(conversation =>
          // @ts-ignore
          selectedTypes.includes(conversation.type.conversationTypeSeq)
        );
      }

      // Apply open/closed filter
      if (openFilter !== 'all') {
        filtered = filtered.filter(conversation =>
          openFilter === 'open' ? conversation.isOpen : !conversation.isOpen
        );
      }

      // Count unread messages
      let count = 0;
      filtered.forEach(chat => {
        chat.messages.forEach(message => {
          if (
            // @ts-ignore
            message.sender.userSeq.toLowerCase() !== user.userSeq.toLowerCase() &&
            // @ts-ignore
            !message.readBy.some(
              (
                reader // @ts-ignore
              ) => reader.userSeq.toLowerCase() === user.userSeq.toLowerCase()
            )
          ) {
            count++;
          }
        });
      });
      return count;
    };

    return getFilteredUnreadCount(chats);
  }, [chats, user?.userSeq, selectedTypes, openFilter]);

  // Add total unread count (unfiltered) if needed
  const totalUnreadCount = useMemo(() => {
    let count = 0;
    chats.forEach(chat => {
      chat.messages.forEach(message => {
        if (
          message?.sender?.userSeq?.toLowerCase() !== user?.userSeq.toLowerCase() &&
          !message?.readBy?.some(
            reader => reader?.userSeq?.toLowerCase() === user?.userSeq.toLowerCase()
          )
        ) {
          count++;
        }
      });
    });
    return count;
  }, [chats, user?.userSeq]);

  const getSecondaryText = (conversation: ChatRecord) => {
    const activeTypers = typingParticipants[conversation.conversationSeq] || [];

    if (activeTypers.length > 0) {
      const typerNames = activeTypers
        .map(participant => {
          if (
            shouldMaskNameAsSupportAgent(
              { sender: { userSeq: participant.userSeq } } as unknown as ChatMessage,
              conversation.type.conversationTypeSeq || '',
              // @ts-ignore
              user
            )
          ) {
            return supportAgentName;
          }
          return participant.personFirstName
            ? `${participant.personFirstName} ${participant.personLastName}`
            : participant.userName;
        })
        .filter(Boolean)
        .join(', ');

      return (
        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
          <Typography
            component='div'
            variant='body2'
            color='text.secondary'
            sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            <span style={{ fontWeight: 600 }}>{typerNames} </span>
            is typing...
          </Typography>
          <Typography
            component='div'
            variant='caption'
            color='text.secondary'
            sx={{ ml: 1, flexShrink: 0 }}
          >
            {' '}
          </Typography>
        </Box>
      );
    }

    if (conversation.messages.length === 0) {
      return (
        <Typography component='div' variant='body2' color='text.secondary'>
          No messages yet
        </Typography>
      );
    }

    const lastMessage = conversation.messages[conversation.messages.length - 1];
    const senderName = shouldMaskNameAsSupportAgent(
      lastMessage,
      conversation.type.conversationTypeSeq || '',
      // @ts-ignore
      user
    )
      ? supportAgentName
      : lastMessage.sender?.personFirstName;

    return (
      <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
        <Typography
          component='div'
          variant='body2'
          color='text.secondary'
          sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          <span style={{ fontWeight: 600 }}>{senderName}: </span>
          {stripHtml(lastMessage?.messageContent || '').substring(0, 30)}...
        </Typography>
        <Typography
          component='div'
          variant='caption'
          color='text.secondary'
          sx={{ ml: 1, flexShrink: 0 }}
        >
          {formatMessageTime(lastMessage.sentAtTimestamp || '')}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {/* <pre>{JSON.stringify(typingParticipants, null, 2)}</pre> */}
      <List
        sx={{ p: 0, backgroundColor: user?.isDarkMode ? 'black' : 'white' }}
        subheader={
          <ListSubheader
            sx={{ pt: 6, pb: 4, backgroundColor: user?.isDarkMode ? 'black' : 'white' }}
          >
            <Stack direction='row' justifyContent='space-between'>
              <Stack alignContent='flex-start' alignItems='flex-start' spacing={2}>
                <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Stack>
                    <Typography
                      component='div'
                      variant='h6'
                      fontWeight='bold'
                      color={user?.isDarkMode ? 'white' : 'black'}
                    >
                      {title} — {unreadCount}
                    </Typography>
                  </Stack>

                  <Tooltip arrow title='Refresh'>
                    <IconButton
                      onClick={
                        user?.roleCheck(['451', '8f7'])
                          ? () => fetchChatsAccesibleBySupport({ refresh: true })
                          : () => fetchUserChats({ refresh: true })
                      }
                      size='small'
                      disabled={refreshing}
                      sx={{
                        ml: 1,
                        animation: refreshing ? 'spin 1s linear infinite' : 'none',
                        '@keyframes spin': {
                          '0%': { transform: 'rotate(0deg)' },
                          '100%': { transform: 'rotate(360deg)' },
                        },
                      }}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                </Box>

                {userIsSysAdmin && (
                  <ConversationTypeFilter
                    types={conversationTypes}
                    selectedTypes={selectedTypes}
                    onTypeToggle={handleTypeToggle}
                  />
                )}

                {userIsSysAdmin && (
                  <ButtonGroup variant='contained' size='small'>
                    <Button
                      onClick={() => setOpenFilter('open')}
                      variant={openFilter === 'open' ? 'contained' : 'outlined'}
                    >
                      Open
                    </Button>
                    <Button
                      onClick={() => setOpenFilter('closed')}
                      variant={openFilter === 'closed' ? 'contained' : 'outlined'}
                    >
                      Closed
                    </Button>
                    <Button
                      onClick={() => setOpenFilter('all')}
                      variant={openFilter === 'all' ? 'contained' : 'outlined'}
                    >
                      All
                    </Button>
                  </ButtonGroup>
                )}
              </Stack>

              <Button
                variant='text'
                size='small'
                startIcon={<PostAdd />}
                onClick={() => setCreateDialogOpen(true)}
              >
                New Chat
              </Button>

              {/* <Button
                variant='text'
                size='small'
                startIcon={<PostAdd />}
                onClick={() => setFeedbackDialogOpen(true)}
              >
                New Feedback
              </Button> */}
            </Stack>
          </ListSubheader>
        }
      >
        {filteredConversations.length === 0 ? (
          <ListItem sx={{ borderBottom: '1px solid', borderColor: 'divider', bgcolor: 'inherit' }}>
            <ListItemText
              primary={
                <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    component='div'
                    variant='body1'
                    sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                  >
                    {openFilter === 'all'
                      ? "You've submitted no feedback yet.\nWhen you do, it will appear here."
                      : `No ${openFilter} conversations found.`}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ) : (
          filteredConversations.map(conversation => (
            <ListItem
              key={conversation?.conversationSeq}
              disablePadding
              sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                bgcolor:
                  selectedChat?.conversationSeq === conversation?.conversationSeq
                    ? 'action.selected'
                    : 'inherit',
              }}
            >
              <ListItemButton onClick={() => handleSelectChat(conversation)}>
                <ListItemText
                  primary={
                    <Box
                      component='div'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant='body1'
                        component='div'
                        sx={{
                          fontWeight: hasUnreadMessages(conversation) ? 700 : 400,
                          wordBreak: 'break-word',
                          whiteSpace: 'pre-wrap',
                          flex: 1,
                          mr: 2,
                        }}
                      >
                        {conversation.topic}
                      </Typography>
                      {userIsSysAdmin && (
                        <Box component='div' sx={{ display: 'flex', gap: 1, flexShrink: 0 }}>
                          <Chip
                            label={conversation.type.conversationTypeName}
                            size='small'
                            variant='outlined'
                          />

                          {!conversation.isOpen && (
                            <Chip
                              label={conversation.isOpen ? 'Open' : 'Closed'}
                              size='small'
                              color={conversation.isOpen ? 'success' : 'default'}
                              onClick={() =>
                                updateConversationOpenStatus({
                                  conversationSeq: conversation.conversationSeq,
                                  isOpen: !conversation.isOpen,
                                })
                              }
                              sx={{ cursor: 'pointer' }}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  }
                  secondary={getSecondaryText(conversation)}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>

      <NewConversation
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        isDialog={true}
      />
      <StartFeedbackConversationDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        onConversationCreated={c => {}}
        isDialog={true}
      />
    </>
  );
}

const stripHtml = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};
