import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import CountCard from './CountCard';
import { GlobalContext } from '../../../../Context/GlobalContext';
import axios from 'axios';
import authHeader from '../../../../services/auth-header';
import { Button, Card, Divider, Grid, Typography, alpha, styled } from '@mui/material';
import RectangleIcon from '@mui/icons-material/Rectangle';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import { Form, Formik } from 'formik';
import ControlComponents from '../UserAdminModules/ControlComponents';
import { TreeItem, TreeView, treeItemClasses } from '@mui/lab';
import { AiFillCloseSquare, AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Stack,
    Snackbar,
    Alert,
    DialogContentText,
} from '@mui/material';
import CopyRole from './CopyRole';
import Slide from '@mui/material/Slide';
import { SPECIAL_ROLE_MAP } from '../../../CMSAdmin/Menu/constants';
import ColorCode from './ColorCode';
import PermissionBox from './PermissionBox';
import { useNavigate } from 'react-router';
import ApiNotification from '../../../../components/DialogBox';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';

let flag = false;

const RolesAdminForm = () => {
    const {
        roleDetails,
        sendPhotoTypeObject,
        sendDocumentTypeObject,
        sendReportTypeObject,
        copyRoleObject,
        sendRoleDetails,
    } = useContext(GlobalContext);
    const [currentRoleDetails, setCurrentRoleDetails] = useState({});
    const [photoTypeDetails, setPhotoTypeDetails] = useState('');
    const [docTypeDetails, setDocTypeDetails] = useState('');
    const [reportTypeDetails, setReportTypeDetails] = useState('');
    const [specialRoleOption, setSpecialRoleOption] = useState([]);
    const [photoTypeOption, setPhotoTypeOption] = useState([]);
    const [documentTypeOption, setDocumentTypeOption] = useState([]);
    const [reportTypeOption, setReportTypeOption] = useState([]);
    const [treeItems, setTreeItems] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCopy, setOpenCopy] = useState(false);
    const [checkedPermissions, setCheckedPermissions] = useState({});
    const [dialogContents, setDialogContents] = useState('');
    const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
    const [roleData, setRoleData] = useState(null);
    const [page, setPage] = useState(false);
    const [roleFields, setRoleFields] = useState({
        roleName: '',
        roleDescription: '',
    });

    const { user } = useAuth();

    const permission = JSON.parse(localStorage.getItem('accessPermissions'));
    const rolePermissions = permission?.['Roles'];
    useEffect(() => {
        if (copyRoleObject == '00000000-0000-0000-0000-000000000000') {
            setPage(true);
        }
    }, []);

    let permissionFlag = false;
    const [permissionAlert, setPermissionAlert] = useState(false);

    const navigator = useNavigate();
    const initialValues = {
        roleName: '',
        roleDescription: '',
        specialRoleCharacteristics: '',
        photoTypePermissions: [],
        // treeData: storeallpermissionTree,
        documentTypePermissions: [],
        reportTypePermissions: [],
    };
    const validationSchema = Yup.object().shape({
        roleName: Yup.string().required('Please Enter Role Name.'),
        roleDescription: Yup.string().required('Please Enter Role Description.'),
    });

    let photoTypeObject = {};
    let documentTypeObject = {};
    let reportTypeObject = {};

    useEffect(() => {
        let roleSeq = roleDetails?.roleSeq ?? copyRoleObject?.roleSeq;
        roleSeq = roleSeq === undefined ? '00000000-0000-0000-0000-000000000000' : roleSeq;
        axios
            .get(`${import.meta.env.VITE_API_URL}GetRoleDetails?roleSeq=` + roleSeq, {
                headers: authHeader(),
            })
            .then(res => {
                const updatedPermissions = res?.data?.data?.permissions.map(function (permission) {
                    if (permission.color !== "#585654") {
                        permission.isChanged = true;
                    }
                    // Check if the current entry has children and update them recursively
                    permission.children = permission.children.map(function (child) {
                        if (child.color !== "#585654") {
                            child.isChanged = true;
                        }
                        return child;
                    });
                    return permission;
                });
                let data = { ...res?.data?.data, permissions: updatedPermissions };

                setForm(data);
            })
            .catch(err => {
                console.log(err);
            });
    }, [copyRoleObject]);

    const setForm = data => {
        setCurrentRoleDetails(data);
        //#region ddl Options
        let roleOption = Object.keys(data)
            .filter(a => a.startsWith('is'))
            .map(a => ({
                value: a,
                name:
                    a
                        .match(/[A-Z][a-z]+/g)
                        .join(' ')
                        .toLowerCase() === 'role'
                        ? 'HCM ' + a.match(/[A-Z][a-z]+/g).join(' ')
                        : a
                            .match(/[A-Z][a-z]+/g)
                            .join(' ')
                            .toLowerCase() === 'personnel'
                            ? 'QA ' + a.match(/[A-Z][a-z]+/g).join(' ')
                            : a.match(/[A-Z][a-z]+/g).join(' '),
                status: data[a],
            }));
        setSpecialRoleOption(roleOption);
        let selectedPhotoTypes = data?.photoTypes
            ?.filter(a => a.isAllow === true)
            ?.map(a => {
                return {
                    ...a,
                    name: a.photoTypeName,
                    value: a.photoTypeSeq,
                };
            });
        let selectedDocumentTypes = data?.documentTypes
            ?.filter(a => a.isAllow === true)
            ?.map(a => ({
                ...a,
                name: a.photoTypeName,
                value: a.photoTypeSeq,
            }));
        let selectedReportTypes = data?.reportlist
            ?.filter(a => a.isAllow === true)
            ?.map(a => ({
                ...a,
                name: a.reportListName,
                value: a.reportListSeq,
            }));
        let photoOption = data?.photoTypes?.map(a => {
            photoTypeObject = {
                ...photoTypeObject,
                [a.photoTypeSeq]: a.photoTypeName,
            };
            return {
                ...a,
                name: a.photoTypeName,
                value: a.photoTypeSeq,
            };
        });
        sendPhotoTypeObject(photoTypeObject);
        setPhotoTypeOption(photoOption);
        let documentOption = data?.documentTypes?.map(a => {
            documentTypeObject = {
                ...documentTypeObject,
                [a.photoTypeSeq]: a.photoTypeName,
            };
            return {
                ...a,
                name: a.photoTypeName,
                value: a.photoTypeSeq,
            };
        });
        sendDocumentTypeObject(documentTypeObject);
        setDocumentTypeOption(documentOption);
        let reportOption = data?.reportlist?.map(a => {
            reportTypeObject = {
                ...reportTypeObject,
                [a.reportListSeq]: a.reportListName,
            };
            return {
                ...a,
                name: a.reportListName,
                value: a.reportListSeq,
            };
        });
        sendReportTypeObject(reportTypeObject);
        setReportTypeOption(reportOption);
        setTreeItems(data?.permissions);

        setRoleData({
            roleName: !page ? data?.roleName : roleFields?.roleName,
            roleDescription: !page ? data?.description : roleFields?.roleDescription,
            specialRoleCharacteristics: roleOption
                ?.filter(a => a.status === true)
                ?.map(a => a.value)
                .toString(),
            photoTypePermissions: data?.photoTypes
                ?.filter(a => a.isAllow === true)
                ?.map(a => {
                    return {
                        ...a,
                        name: a.photoTypeName,
                        value: a.photoTypeSeq,
                    };
                })
                ?.map(a => a.value),
            documentTypePermissions: data?.documentTypes
                ?.filter(a => a.isAllow === true)
                ?.map(a => ({
                    ...a,
                    name: a.photoTypeName,
                    value: a.photoTypeSeq,
                }))
                ?.map(a => a.value),
            reportTypePermissions: data?.reportlist
                ?.filter(a => a.isAllow === true)
                ?.map(a => ({
                    ...a,
                    name: a.reportListName,
                    value: a.reportListSeq,
                }))
                ?.map(a => a.value),
        });

        //#region Card Data Binding
        let photoTypes = {
            message: 'Total Photo Type Permissions',
            activeCount: selectedPhotoTypes?.length,
            totalCount: data?.photoTypes?.length,
            photoTypes: data?.photoTypes,
        };
        setPhotoTypeDetails(photoTypes);
        let documentTypes = {
            message: 'Total Document Type Permissions',
            activeCount: selectedDocumentTypes?.length,
            totalCount: data?.documentTypes?.length,
            documentTypes: data?.documentTypes,
        };
        setDocTypeDetails(documentTypes);
        let reportlist = {
            message: 'Total Report Type Permissions',
            activeCount: selectedReportTypes?.length,
            totalCount: data?.reportlist?.length,
            reportTypes: data?.reportlist,
        };
        setReportTypeDetails(reportlist);
        //#endregion
    };

    //#region Tree
    const [treeNode, setTreeNode] = useState('');
    const roleGriddata = {};
    const ReeSet = () => { };
    const handleCopyRoleClose = () => {
        setOpenCopy(false);
    };
    const copyRoleBtn = e => {
        setOpenCopy(true);
    };

    const [checkedNodeColor, setCheckedNodeColor] = useState({
        Black: '#585654',
    });

    function TransitionComponent(props) {
        const style = useSpring({
            from: {
                opacity: 0,
                transform: 'translate3d(20px,0,0)',
            },
            to: {
                opacity: props.in ? 1 : 0,
                transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
            },
        });

        return (
            <animated.div style={style}>
                <Collapse {...props} />
            </animated.div>
        );
    }

    TransitionComponent.propTypes = {
        in: PropTypes.bool,
    };

    const StyledTreeItem = styled(props => (
        <TreeItem {...props} TransitionComponent={TransitionComponent} />
    ))(({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }));

    const getTreeItemsFromData = treeItems => {
        return treeItems?.map(treeItemData => {
            let children = undefined;
            if (treeItemData.children && treeItemData.children.length > 0) {
                children = getTreeItemsFromData(treeItemData.children);
            }

            var arr = [];
            const handleChange = e => {
                let newArray = [...arr, e.target.id];
                arr = newArray;
            };

            const renderLabel = treeItemData => (
                <span
                    onClick={event => {
                        if (rolePermissions?.edit) {
                            setTreeData(treeItemData);
                            setOpen(true);
                            event.stopPropagation();
                            event.preventDefault();
                        }
                    }}
                >
                    <Grid item style={{ width: '500px' }}>
                        {treeItemData.treeName}
                    </Grid>
                </span>
            );

            return (
                <>
                    <StyledTreeItem
                        TransitionComponent={TransitionComponent}
                        key={treeItemData.treeSeq}
                        nodeId={treeItemData.treeSeq}
                        label={renderLabel(treeItemData)}
                        sx={{ color: { marginRight: '85px' } }}
                        children={children}
                        style={{ color: treeItemData.color }}
                    ></StyledTreeItem>
                </>
            );
        });
    };
    const DataTreeView = ({ treeItems, index }) => {
        return (
            <TreeView
                key={index}
                aria-label='customized'
                defaultExpanded={['1']}
                defaultCollapseIcon={<AiFillMinusSquare />}
                defaultExpandIcon={<AiFillPlusSquare />}
                defaultEndIcon={<AiFillCloseSquare />}
                sx={{ height: 464, flexGrow: 1, overflowY: 'auto' }}
            >
                {getTreeItemsFromData(treeItems)}
            </TreeView>
        );
    };
    //#endregion

    const handleClose = () => {
        setOpen(false);
    };

    const sendCheckedPermissions = (checked, nodeName) => {
        setCheckedPermissions(checked);
        setTreeNode(nodeName);

        if (checked?.Creator && checked?.Editor && checked?.Viewer) {
            setCheckedNodeColor({ Green: '#3B6A0C' });
        } else if (!checked?.Creator && checked?.Editor && checked?.Viewer) {
            setCheckedNodeColor({ Violet: '#C466C4' });
        } else if (!checked?.Creator && !checked?.Editor && checked?.Viewer) {
            setCheckedNodeColor({ Blue: '#004A9D' });
        } else if (!checked?.Creator && !checked?.Editor && !checked?.Viewer) {
            setCheckedNodeColor({ Black: '#585654' });
        }
    };

    const searchNode = items => {
        return items.map(item => {
            if (item.color !== '#585654' || item.color !== 'Black') {
                permissionFlag = true;
            }
            if (item.treeName === treeData?.treeName) {
                item.color = Object.keys(checkedNodeColor)?.[0];
                item.isChanged = true;
                permissionFlag = true;
                const colorAllNodes = parent => {
                    if (parent.children.length > 0) {
                        return parent.children.map(child => {
                            if (child.color !== '#585654' || child.color !== 'Black') {
                                permissionFlag = true;
                            }
                            child.color = Object.keys(checkedNodeColor)?.[0];
                            child.isChanged = true;
                            permissionFlag = true;

                            colorAllNodes(child);
                            return child;
                        });
                    }
                };
                colorAllNodes(item);
                return item;
            }
            if (item.children.length > 0) {
                searchNode(item.children);
            }
            return item;
        });
    };

    const searchColor = items => {
        const colorTree = items.map(item => {
            if (item.color !== '#585654' || item.color !== 'Black') {
                flag = true;
            }
            if (item.treeName === treeData?.treeName) {
                item.color = Object.keys(checkedNodeColor)?.[0];
                item.isChanged = true;
                permissionFlag = true;
                const colorAllNodes = parent => {
                    if (parent.children.length > 0) {
                        return parent.children.map(child => {
                            if (child.color !== '#585654' || child.color !== 'Black') {
                                flag = true;
                            }
                            child.color = Object.keys(checkedNodeColor)?.[0];
                            child.isChanged = true;
                            permissionFlag = true;
                            colorAllNodes(child);
                            return child;
                        });
                    }
                };
                colorAllNodes(item);
                return item;
            }
            if (item.children.length > 0) {
                searchColor(item.children);
            }
            return item;
        });

        return flag;
    };

    useEffect(() => {
        if (treeItems) {
            const updatedTree = searchNode(treeItems);
            setTreeItems(updatedTree);
        }
    }, [treeNode, checkedPermissions]);

    let flattenTreeStructure = [];

    const flattenTree = treeItems => {
        for (const treeItem of treeItems) {
            flattenTreeStructure.push(treeItem);
            if (treeItem?.children) {
                flattenTree(treeItem?.children);
            }
        }
    };

    const checkTreeContainsPermission = treeItems => {
        flattenTree(treeItems);
        const treeColorExist = flattenTreeStructure?.some(
            tree => tree?.color !== '#585654' && tree?.color !== 'Black'
        );
        return treeColorExist;
    };

    // useEffect(() => {
    //     if (
    //       window.location.pathname.includes('rolesedit') &&
    //       !!roleDetails
    //     ) {
    //       navigate('/sysadmin');
    //     }
    //   }, []);
    return (
        <>
            <div className='container-fluid my-2 py-2'>
                <Stack>
                    <Typography variant='h5' component='h1'>
                    {roleDetails === '00000000-0000-0000-0000-000000000000' ? <b>Enter New Role</b> : <b>Modify Role</b> }
                    </Typography>
                </Stack>
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                <br />
                {/* <MainCard> */}
                    <Formik
                        initialValues={roleData || initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={values => {
                            const payload = treeItems?.length > 0 ? treeItems : currentRoleDetails?.permissions;
                            const permissionCheck = checkTreeContainsPermission(payload);

                            if (!permissionCheck) {
                                setPermissionAlert(true);
                            } else if (permissionCheck) {
                                let saveData = {
                                    ...currentRoleDetails,
                                    roleSeq: page
                                        ? '00000000-0000-0000-0000-000000000000'
                                        :
                                        currentRoleDetails?.roleSeq,
                                    roleCode: page ? '' : currentRoleDetails?.roleCode,
                                    order: '0',
                                    roleName: values?.roleName,
                                    description: values?.roleDescription,
                                    photoTypes: currentRoleDetails?.photoTypes?.map(phoType => {
                                        if (values?.photoTypePermissions.includes(phoType?.photoTypeSeq)) {
                                            const obj = { ...phoType, ['isAllow']: true };
                                            return obj;
                                        } else {
                                            const obj = { ...phoType, ['isAllow']: false };
                                            return obj;
                                        }
                                    }),
                                    documentTypes: currentRoleDetails?.documentTypes?.map(docType => {
                                        if (values?.documentTypePermissions.includes(docType?.photoTypeSeq)) {
                                            const obj = { ...docType, ['isAllow']: true };
                                            return obj;
                                        } else {
                                            const obj = { ...docType, ['isAllow']: false };
                                            return obj;
                                        }
                                    }),
                                    reportlist: currentRoleDetails?.reportlist?.map(repType => {
                                        if (values?.reportTypePermissions.includes(repType?.reportListSeq)) {
                                            const obj = { ...repType, ['isAllow']: true };
                                            return obj;
                                        } else {
                                            const obj = { ...repType, ['isAllow']: false };
                                            return obj;
                                        }
                                    }),
                                    permissions: treeItems,
                                    ...SPECIAL_ROLE_MAP,
                                    [values?.specialRoleCharacteristics]: true,
                                };
                                console.log('saveData', saveData);
                                axios
                                    .post(
                                        `${import.meta.env.VITE_API_URL}SaveRoleDetails?userseq=` +
                                        user.userSeq,
                                        saveData,
                                        { headers: authHeader() }
                                    )
                                    .then(res => {
                                        setPage(false);
                                        sendRoleDetails(res?.data?.data);
                                        setCurrentRoleDetails(res?.data?.data);
                                        setDialogContents(res?.data?.message);
                                        setOpenApiDialogBox(true);
                                    })
                                    .catch(err => {
                                        console.log('api err=>', err);
                                    });
                            }
                        }}
                    >
                        {({ values, setFieldValue }) => {
                            setRoleFields(values);
                            return (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item lg={4} xs={12} md={4} sm={12}>
                                            <CountCard
                                                message={photoTypeDetails?.message}
                                                totalCount={photoTypeDetails?.totalCount}
                                                activeCount={values?.photoTypePermissions?.length}
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12} md={4} sm={12}>
                                            <CountCard
                                                message={docTypeDetails?.message}
                                                totalCount={docTypeDetails?.totalCount}
                                                activeCount={values?.documentTypePermissions?.length}
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12} md={4} sm={12}>
                                            <CountCard
                                                message={reportTypeDetails?.message}
                                                totalCount={reportTypeDetails?.totalCount}
                                                activeCount={values?.reportTypePermissions?.length}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} lg={12} xs={12} md={12} sm={12} mt={1}>
                                        <Grid container item spacing={2} lg={8} xs={12} md={8} sm={12}>
                                            <Grid container item lg={12} xs={12} md={12} sm={12}>
                                                <Grid item lg={12} xs={12} md={12} sm={12} sx={{ mb: 2 }}>
                                                    <ControlComponents
                                                        control='input'
                                                        name='roleName'
                                                        label='Role Name*'
                                                        type='input'
                                                    />
                                                </Grid>
                                                <Grid item lg={12} xs={12} md={12} sm={12} sx={{ mb: 2 }}>
                                                    <ControlComponents
                                                        control='input'
                                                        name='roleDescription'
                                                        label='Role Description*'
                                                        type='input'
                                                    />
                                                </Grid>
                                                {!roleDetails?.roleSeq ? (
                                                    <Grid item sm={12} xs={12} md={12} lg={12}>
                                                        <Button
                                                            onClick={e => copyRoleBtn(e)}
                                                            variant='contained'
                                                            style={{ marginTop: '5px' }}
                                                        >
                                                            copy role features from
                                                        </Button>
                                                    </Grid>
                                                ) : (
                                                    ''
                                                )}
                                            </Grid>
                                            <Grid container item lg={12} xs={12} md={12} sm={12}>
                                                <Grid item lg={12} xs={12} md={12} sm={12} sx={{ mb: 2 }}>
                                                    <ControlComponents
                                                        control='select'
                                                        name='specialRoleCharacteristics'
                                                        label='Special Role Characteristics'
                                                        options={specialRoleOption || []}
                                                        type='select'
                                                    />
                                                </Grid>
                                                <Grid item lg={12} xs={12} md={12} sm={12} sx={{ mb: 2 }}>
                                                    <ControlComponents
                                                        control='multiSelect'
                                                        name='photoTypePermissions'
                                                        label='Photo Type Permissions'
                                                        options={photoTypeOption || []}
                                                        type='select'
                                                        module='Photo Type Permissions'
                                                    />
                                                </Grid>
                                                <Grid item lg={12} xs={12} md={12} sm={12} sx={{ mb: 2 }}>
                                                    <ControlComponents
                                                        control='multiSelect'
                                                        name='documentTypePermissions'
                                                        label='Document Type Permissions'
                                                        options={documentTypeOption || []}
                                                        type='select'
                                                        module='Document Type Permissions'
                                                    />
                                                </Grid>
                                                <Grid item lg={12} xs={12} md={12} sm={12} sx={{ mb: 2 }}>
                                                    <ControlComponents
                                                        control='multiSelect'
                                                        name='reportTypePermissions'
                                                        label='Report Type Permissions'
                                                        options={reportTypeOption || []}
                                                        type='select'
                                                        module='Report Type Permissions'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={4} xs={12} md={4} sm={12} sx={{ mb: 2 }}>
                                            <ColorCode />
                                            <DataTreeView treeItems={treeItems} />
                                        </Grid>
                                        <Grid item lg={12} xs={12} md={12} sm={12}>
                                            <Button type='submit' variant='contained' disabled={!rolePermissions?.edit}>
                                                {roleDetails == '00000000-0000-0000-0000-000000000000' ? 'Save' : 'Update'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                    <Grid item>
                        <Dialog
                            open={openCopy}
                            onClose={() => setOpenCopy(false)}
                            style={{ alignItems: 'center' }}
                        >
                            <DialogActions>
                                <IconButton onClick={() => setOpenCopy(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogActions>
                            <DialogContent>
                                <CopyRole
                                    roleGriddata={roleGriddata}
                                    onCopyRole={handleCopyRoleClose}
                                    handleClick={ReeSet}
                                />
                            </DialogContent>
                        </Dialog>
                    </Grid>
                    {open ? (
                        <PermissionBox
                            open={open}
                            handleClose={handleClose}
                            treeItems={treeData}
                            sendCheckedPermissions={sendCheckedPermissions}
                        />
                    ) : null}
                {/* </MainCard> */}
            </div>
            <ApiNotification
                openApiDialogBox={openApiDialogBox}
                dialogContents={dialogContents}
                closeDialogBox={() => setOpenApiDialogBox(false)}
            />

            {permissionAlert ? (
                <ApiNotification
                    openApiDialogBox={permissionAlert}
                    dialogContents='Please select atleast one permission.'
                    closeDialogBox={() => setPermissionAlert(false)}
                />
            ) : null}
        </>
    );
};

export default RolesAdminForm;
