import { Button, Grid, Tab } from '@mui/material';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { GlobalContext } from '../../../Context/GlobalContext';
import AddressData from '../../../components/AdministrationModules/AddressData/AddressView';
import ContactData from '../../../components/AdministrationModules/ContactData/ContactView';
import InvestigatorCertification from '../../../components/AdministrationModules/InvestigatorCertification';
import PhysicianCertification from '../../../components/AdministrationModules/PhysicianCertification';
import SecurityQuestionTab from '../../../components/AdministrationModules/SecurityQuestionComponent/SecurityQuestionTab';
import UserForm from '../../../components/AdministrationModules/UserForm';
import ApiNotification from '../../../components/DialogBox';
import authHeader from '../../../services/auth-header';
import { fetchFormat, fetchUserProfileInformation } from '../../../services/utility-api';
import { useAuth } from '../../../utils/auth/AuthService';
import {
  OCSME_CERTIFICATION_OBJECT,
  PHYSICIAN_CERTIFICATION_PATHOLOGY_OBJECT,
  SECURITY_QUESTIONS,
  SPECIAL_PRIVILEGE_OBJECT,
  USERS_MEDICAL_CERTIFICATION,
} from '../../CMSAdmin/Menu/constants';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Divider } from '@mui/material';
import Documents from '../../SysAdmin/SysAdminModules/UserAdminModules/Documents';
import LoginHistory from '../../SysAdmin/SysAdminModules/UserAdminModules/LoginHistory';

const UserProfileDetailsView = () => {
  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;
  const guid = '00000000-0000-0000-0000-000000000000';
  const auth = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { profileInformation, signature, photo, sendUserProfile } = useContext(GlobalContext);
  useEffect(() => {
    // setTimeout(() => {}, 400);
    // if (user?.securityQuestionCount === 0) {
    fetchUserProfileInformation(user?.userSeq, true).then(profile => {
      console.log('profile', profile);
      let payload = {
        ...profile,
        securityQuestion:
          profile?.securityQuestion?.length > 0 ? profile?.securityQuestion : SECURITY_QUESTIONS,
      };
      sendUserProfile(payload);
    });
    // }
  }, [user]);
  const [openApiDialogBox, setOpenApiDialogBox] = useState();
  const [dialogContents, setDialogContents] = useState();
  const [contactsErrorMessage, setContactsErrorMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('1');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const {
    userTitle,
    userName,
    userLastName,
    userFirstName,
    userMiddleName,
    userSuffix,
    primaryEmail,
    primaryMobileNo,
    operationMode,
    organizationseq,
    mdLicenseNo,
    mdLicenseExpiryDate,
    departmentSeq,
    boardcertificatefellowNo,
    barcode,
    abmdiCertificateNO,
    abmdiCertificateExpiryDate,
    userContacts,
    userAddress,
    initials,
    roleseq,
    countySeqandDashboard,
    userSeq,
    specialPrivilege,
    securityQuestion,
  } = profileInformation ?? {};

  console.log(
    'profileInformation',
    // userTitle,
    // userName,
    // userLastName,
    // userFirstName,
    // userMiddleName,
    // userSuffix,
    // primaryEmail,
    // primaryMobileNo,
    // operationMode,
    // organizationseq,
    // mdLicenseNo,
    // mdLicenseExpiryDate,
    // departmentSeq,
    // boardcertificatefellowNo,
    // barcode,
    // abmdiCertificateNO,
    // abmdiCertificateExpiryDate,
    // userContacts,
    // userAddress,
    // initials,
    // roleseq,
    // countySeqandDashboard,
    // userSeq,
    // specialPrivilege,
    profileInformation,
    securityQuestion
  );

  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const userPermissions = permissions?.['Users'];
  const [mobileNumber, setMobileNumber] = useState(primaryMobileNo ?? null);
  const [contactsError, setContactsError] = useState({});
  const [securityQuestionError, setSecurityQuestionError] = useState({});

  const convertBooleanToSequence = (moduleSequence, selected) => {
    return (
      moduleSequence?.map(sequence => {
        if (selected === 'Dashboard') {
          if (sequence.split(' || ')?.[2] === 'True') {
            return sequence.split(' || ')?.[0].toLowerCase();
          }
        } else {
          return sequence.split(' || ')?.[0].toLowerCase();
        }
      }) ?? []
    );
  };

  const checkPhysicianAndInvestigation = certificates => {
    return (
      Object.keys(certificates)?.map(certificate => {
        if (profileInformation?.[certificate]) {
          return certificate;
        }
      }) ?? []
    );
  };

  const fetchSpecialPriveleges = () => {
    return Object.keys(SPECIAL_PRIVILEGE_OBJECT).filter(key => !!profileInformation?.[key]);
  };

  const populateSecurityQuestion = () => {
    let i = 0;
    let arr = [];
    while (i < 3) {
      arr.push({
        securityQuestion: '',
        securityQuestionAns: '',
        securityQuestionSeq: '',
        userToSecurityQuestionSeq: guid,
      });
      i++;
    }
    return arr;
  };

  const [securityQuestionsMap, setSecurityQuestionMap] = useState(
    profileInformation?.securityQuestion ?? ''
  );

  useEffect(() => {
    setSecurityQuestionMap(profileInformation?.securityQuestion);
  }, [securityQuestion]);
  const initialValues = {
    countySeqandDashboard: convertBooleanToSequence(countySeqandDashboard, 'Users'),
    dashboardPermissions: convertBooleanToSequence(countySeqandDashboard, 'Dashboard'),
    roleseq: convertBooleanToSequence(roleseq, 'Roles'),
    userTitle: userTitle ?? '',
    userName: userName ?? '',
    userFirstName: userFirstName ?? '',
    userLastName: userLastName ?? '',
    userSuffix: userSuffix ?? '',
    userMiddleName: userMiddleName ?? '',
    primaryEmail: primaryEmail ?? '',
    primaryMobileNo: primaryMobileNo ?? '',
    initials: initials ?? '',
    departmentSeq: departmentSeq ?? '',
    barcode: barcode ?? '',
    specialPrivilege: fetchSpecialPriveleges() ?? [],
    pathology: checkPhysicianAndInvestigation(PHYSICIAN_CERTIFICATION_PATHOLOGY_OBJECT) ?? [],
    mdLicenseExpiryDate: fetchFormat(profileInformation?.mdLicenseExpiryDate?.substr(0, 10)) ?? '',
    mdLicenseNo: mdLicenseNo ?? '',
    operationMode: operationMode ?? null,
    organizationseq: organizationseq ?? '',
    ocsmeCertification: checkPhysicianAndInvestigation(OCSME_CERTIFICATION_OBJECT) ?? [],
    ocsmeCertificateExpiryDate:
      fetchFormat(profileInformation?.ocsmeCertificateExpiryDate?.substr(0, 10)) ?? '',
    boardcertificatefellowNo: boardcertificatefellowNo ?? '',
    abmdiCertificateNO: abmdiCertificateNO ?? '',
    abmdiCertificateExpiryDate:
      fetchFormat(profileInformation?.abmdiCertificateExpiryDate?.substr(0, 10)) ?? '',
    address: userAddress ?? [],
    contactItem: userContacts ?? [],
    securityQuestion: profileInformation?.securityQuestion ?? '',
  };

  const updateCertifications = values => {
    let updatedPathologyOCSME = {};

    const combinedPathologyAndOCSME = [...values?.pathology, ...values?.ocsmeCertification];

    Object.entries(USERS_MEDICAL_CERTIFICATION)?.map(([key, value], index) => {
      if (combinedPathologyAndOCSME?.includes(key)) {
        updatedPathologyOCSME = { ...updatedPathologyOCSME, [key]: true };
      } else {
        updatedPathologyOCSME = { ...updatedPathologyOCSME, [key]: false };
      }
      return;
    });
    return updatedPathologyOCSME;
  };

  const fetchCountyAndDashboard = (counties, dashboards) => {
    let countyAndDashboard = [];
    counties?.forEach(county => {
      if (dashboards?.includes(county)) {
        countyAndDashboard?.push(`${county} || True || True`);
      } else {
        countyAndDashboard?.push(`${county} || True || False`);
      }
    });
    return countyAndDashboard;
  };

  const formatRoleSequence = roles => {
    const exisitingRoles = convertBooleanToSequence(profileInformation?.roleseq);
    let rolesToMap = [...roles, ...exisitingRoles];
    let removedDuplicates = rolesToMap.filter((item, index) => rolesToMap.indexOf(item) === index);
    return removedDuplicates?.map(role => {
      if (!roles?.includes(role)) {
        return `${role} || False`;
      } else {
        return `${role} || True`;
      }
    });
  };

  const closeDialogBox = () => {
    setOpenApiDialogBox(false);
    if (user?.securityQuestionCount === 0) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...user,
          securityQuestionCount: 3,
          isSecurityquestionSet: 'True',
        })
      );
      auth.authDispatch({ type: 'SET_AUTHENTICATED', payload: true });
      navigate('/');
    }
  };

  const [errorMsg, setErrorMsg] = useState('');
  const [box, setBox] = useState(false);
  const [contactsErrorModal, setContactsErrorModal] = useState(false);

  const checkForAddressAndContactValidation = errors => {
    if (errors?.address && Object.keys(errors)?.length === 1) {
      setErrorMsg('Address field is required');
      setBox(true);
    } else if (errors?.securityQuestion && Object.keys(errors)?.length === 1) {
      setErrorMsg('Please fill all security questions and answers');
      setBox(true);
    }
  };

  const updateSpecialPrivileges = values => {
    let privilegesObject = {};

    Object.entries(SPECIAL_PRIVILEGE_OBJECT)?.map(([key, value]) => {
      if (values?.specialPrivilege.includes(key)) {
        privilegesObject = { ...privilegesObject, [key]: true };
      }
      return;
    });

    return privilegesObject;
  };

  const isSecurityValidation = () => {
    const flattenSecurityStructure = Object.entries(securityQuestionsMap).map(
      ([key, value], index) => value?.securityQuestionSeq
    );
    return flattenSecurityStructure.some(function (item, idx) {
      return flattenSecurityStructure.indexOf(item) != idx;
    });
  };

  const isSecurityFiled = () => {
    const flattenSecurityStructure = Object.entries(securityQuestionsMap).map(
      ([key, value], index) => value?.securityQuestionSeq
    );
    console.log('flattenSecurityStructure', flattenSecurityStructure, securityQuestionsMap);
    return flattenSecurityStructure.some(item => item === '');
  };

  const [securityError, setSecurityError] = useState(false);

  const onSubmit = async (values, errors) => {
    if (!isSecurityFiled() && !isSecurityValidation()) {
      const reports = updateCertifications(values);
      const privileges = updateSpecialPrivileges(values);
      const payload = {
        abmdiCertificateExpiryDate: values?.abmdiCertificateExpiryDate,
        abmdiCertificateNO: values?.abmdiCertificateNO,
        barcode: values?.barcode,
        boardcertificatefellowNo: values?.boardcertificatefellowNo,
        countySeqandDashboard: fetchCountyAndDashboard(
          values?.countySeqandDashboard,
          values?.dashboardPermissions
        ),
        departmentSeq: values?.departmentSeq,
        eSignature: null,
        enable2FA: false,
        initials: values?.initials,
        mdLicenseExpiryDate: values?.mdLicenseExpiryDate,
        mdLicenseNo: values?.mdLicenseNo,
        ocsmeCertificateExpiryDate: values?.ocsmeCertificateExpiryDate,
        operationMode: values?.operationMode ?? null,
        organizationseq: values?.organizationseq,
        primaryEmail: values?.primaryEmail,
        primaryMobileNo: values?.primaryMobileNo ? values?.primaryMobileNo : null,
        roleseq: formatRoleSequence(values?.roleseq),
        securityQuestion: values.securityQuestion,
        userAddress: values?.address,
        userContacts: values?.contactItem,
        userFirstName: values?.userFirstName,
        userLastName: values?.userLastName,
        userMiddleName: values?.userMiddleName,
        userName: values?.userName,
        userSeq: userSeq ?? guid,
        userSuffix: values?.userSuffix,
        userTitle: values?.userTitle,
        IsWithinServiceArea: true,
      };
      try {
        const response = await axios.post(
          `${VITE_API_URL}SaveUpdateUser?LogInuserSeq=${user?.userSeq}&isIncludeSecurityQuestion=true`,
          { ...payload, ...reports, ...privileges },
          { headers: authHeader() }
        );
        setOpenApiDialogBox(true);
        setDialogContents(response.data.message);
        if ((response?.data?.status === 0 || response?.data?.status === 1) && signature) {
          let formData = new FormData();
          formData.append('photo', signature);
          formData.append('userSeq', userSeq ?? '');
          formData.append('logInUserSeq', user?.userSeq ?? '');
          formData.append('caseSeq', '');
          formData.append(
            'fileName',
            userName + Math.floor(Math.random() * 90000) + 10000 + `${signature.name}`
          );
          formData.append('entityName', '');
          formData.append('photoInfo', '');
          formData.append('photoSeq', '00000000-0000-0000-0000-000000000000');
          formData.append('uploadType', '');
          formData.append('isResourceFile', 'false');
          formData.append('isUsercertificate', 'false');
          formData.append('recoverySeal', '');
          formData.append('photoTypeSeq', '');
          formData.append('certificateTypeSeq', '');
          formData.append('photoLabel', '');
          formData.append('resourceTypeSeq', '');
          formData.append('photoMimeType', '');
          formData.append('filePath', '');
          formData.append('isEsignature', 'true');
          await axios.post(`${VITE_API_URL}SaveEsignature`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + user.token,
            },
          });
        }

        if (photo) {
          if (typeof photo === 'object') {
            // Create a new FormData instance
            const formData = new FormData();
            // Append the file to the FormData instance. 'file' is the key your backend expects.
            formData.append('file', photo[0]);

            // Make the POST request with axios
            await axios.post(
              `${VITE_FASTAPI_URL}users/user-profile-picture/${user.userSeq}`,
              formData // Pass the FormData instance as the request body
            );
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    } else {
      setSecurityError(true);
    }
  };

  const validationSchema = Yup.object({
    countySeqandDashboard: Yup.array().min(1, 'Atleast one Jurisdiction is required'),
    roleseq: Yup.array().min(1, 'Atleast one Role is required'),
    userName: Yup.string().required('User Name is required'),
    userFirstName: Yup.string().required('First Name is required'),
    userLastName: Yup.string().required('Last Name is required'),
    initials: Yup.string().required('Initals are required'),
    departmentSeq: Yup.string().required('Department are required'),
    organizationseq: Yup.string().required('Organization is required'),
    primaryEmail: Yup.string().email('Invalid email').required('Primary email is required'),
    securityQuestion: Yup.array().of(
      Yup.object().shape({
        securityQuestionSeq: Yup.string().required('Security Question is required'),
        securityQuestionAns: Yup.string().required('Answer is required'),
      })
    ),
    address: Yup.array()
      .of(
        Yup.object().shape({
          addressTypeSeq: Yup.string().nullable(),

          addressLine1: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq != 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' &&
              addressTypeSeq != 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
              return Yup.string().required('Address line 1 is required');
            }
          }),
          city: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq != 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' &&
              addressTypeSeq != 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
              return Yup.string().required('City is required');
            }
          }),
          contactItem: Yup.array()
            .of(
              Yup.object().shape({
                contactItemTypeSeq: Yup.string().required('Contact type is required'),
                contactItemDetails: Yup.string().required('Contact Details is required'),
              })
            )
            .min(1, 'Contact is a required field'),
        })
      )
      .min(1, 'Address is a required field')
      .nullable(),
    // mdLicenseExpiryDate: Yup.mixed().when(['pathology'], pathology => {
    //   const updatedPathology = pathology?.[0]?.filter(data => data !== undefined);
    //   if (updatedPathology?.length > 0) {
    //     return Yup.string().required('Expiry Date is required');
    //   }
    // }),
    // ocsmeCertificateExpiryDate: Yup.mixed().when(['ocsmeCertification'], ocsmeCertification => {
    //   const updatedOcsme = ocsmeCertification?.[0]?.filter(ocsme => ocsme !== undefined);
    //   if (updatedOcsme?.length > 0) {
    //     return Yup.string().required('Expiry Date is required');
    //   }
    // }),
  });

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formik => {
        return (
          <div>
            <Grid item xs={12} md={12}>
              <TabContext value={selectedTab}>
                <TabList value={selectedTab} onChange={handleTabChange}>
                  <Tab label='User Profile' value='1' />
                  <Tab label='Login History' value='2' />
                  <Tab label='Documents' value='3' />
                </TabList>
                <TabPanel value='1' paddingTop={3}>
                  <Form className='AddUser'>
                    <UserForm
                      title='userTitle'
                      userName='userName'
                      firstName='userFirstName'
                      lastName='userLastName'
                      suffix='userSuffix'
                      primaryEmail='primaryEmail'
                      initials='initials'
                      middleName='userMiddleName'
                      department='departmentSeq'
                      operationMode='operationMode'
                      organization='organizationseq'
                      userBarCode='barcode'
                      specialPrivilege='specialPrivilege'
                      primaryMobileNo='primaryMobileNo'
                      // primaryCellularNumber={mobileNumber}
                      // handlePrimaryMobileNumber={mobileNumber => setMobileNumber(mobileNumber)}
                    />
                    <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                    <SecurityQuestionTab
                      securityQuestion={formik?.values?.securityQuestion || []}
                      handleChange={data =>
                        formik?.handleChange({
                          target: {
                            value: data,
                            name: 'securityQuestion',
                          },
                        })
                      }
                      setSecurityQuestionError={err => setSecurityQuestionError(err)}
                      setSecurityQuestionMap={setSecurityQuestionMap}
                    />
                    <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                    <AddressData
                      title='Address'
                      isMultipleOn={true}
                      handleChange={data =>
                        formik?.handleChange({
                          target: {
                            value: data,
                            name: 'address',
                          },
                        })
                      }
                      addresses={formik?.values?.address}
                    />
                    <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                    <ContactData
                      contactItem={formik?.values?.contactItem || []}
                      handleChange={data =>
                        formik?.handleChange({
                          target: {
                            value: data,
                            name: 'contactItem',
                          },
                        })
                      }
                      setContactDetailsError={err => setContactsError(err)}
                    />
                    <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                    <PhysicianCertification
                      pathology='pathology'
                      mdLicenseNumber='mdLicenseNo'
                      ocsmeCertification='ocsmeCertification'
                      ocsmeExpiryDate='ocsmeCertificateExpiryDate'
                      expiryDate='mdLicenseExpiryDate'
                    />
                    <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                    <InvestigatorCertification
                      abmdiCertification='abmdiCertificateNO'
                      abmdiCertificationExpiryDate='abmdiCertificateExpiryDate'
                      boardCertifiedFellow='boardcertificatefellowNo'
                    />
                    <Button
                      variant='contained'
                      style={{ float: 'right', margin: '20px 0px' }}
                      onClick={() =>
                        Object.keys(formik.errors ?? {})?.length === 0 &&
                        formik.values.userName.length > 0
                          ? onSubmit(formik?.values, formik?.errors)
                          : checkForAddressAndContactValidation(formik?.errors)
                      }
                      // disabled={!userPermissions?.edit}
                    >
                      Update
                    </Button>
                    {openApiDialogBox ? (
                      <ApiNotification
                        openApiDialogBox={openApiDialogBox}
                        closeDialogBox={closeDialogBox}
                        dialogContents={dialogContents || ''}
                      />
                    ) : null}

                    {box ? (
                      <ApiNotification
                        openApiDialogBox={box}
                        closeDialogBox={() => setBox(false)}
                        dialogContents={errorMsg || ''}
                      />
                    ) : null}
                    {contactsError ? (
                      <ApiNotification
                        openApiDialogBox={contactsErrorModal}
                        closeDialogBox={() => setContactsErrorModal(false)}
                        dialogContents={contactsErrorMessage}
                      />
                    ) : null}
                  </Form>
                  {securityError ? (
                    <ApiNotification
                      openApiDialogBox={securityError}
                      closeDialogBox={() => setSecurityError(false)}
                      dialogContents={
                        isSecurityFiled()
                          ? 'Please fill all security questions.'
                          : 'Duplicate security questions are not allowed.'
                      }
                    />
                  ) : null}
                </TabPanel>
                <TabPanel value='2'>
                  <LoginHistory userSeq={user?.userSeq} />
                </TabPanel>
                <TabPanel value='3'>
                  <Documents userSeq={user?.userSeq} />
                </TabPanel>
              </TabContext>
            </Grid>
          </div>
        );
      }}
    </Formik>
  );
};

export default UserProfileDetailsView;
