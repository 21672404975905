import { Box, Grid, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { parseISO } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { ConversationList } from './Conversations/ConversationList';
import { ConversationListSkeleton } from './Conversations/ConversationListSkeleton';
import { useConversation } from './Conversations/ConversationsContext';
import { MessageArea } from './MessageArea';

const { REACT_APP_API_URL } = import.meta.env;

export function UserFeedbackView() {
  // const { fetchConversations } = useConversation();
  // useEffect(() => {
  //   fetchConversations({ all: true, conversatonTypeSeqs: [] });
  // }, []);
  return (
    <Box component='div' sx={{ pt: 1 }}>
      <ConversationLayout kind='sysadmin' />
    </Box>
  );
}
interface ConversationLayoutProps {
  kind: 'user' | 'sysadmin';
  title?: string;
  BoxProps?: {
    sx?: SxProps;
  };
  DefaultComponent?: React.ReactNode;
  CloseButtonComponent?: React.ReactNode;
}

function ConversationLayout({
  title = 'User Feedback',
  kind = 'user',
  BoxProps = {
    sx: {},
  },
  DefaultComponent,
  CloseButtonComponent = <></>,
}: ConversationLayoutProps) {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const conversationContentRef = useRef<HTMLDivElement>(null);
  const previousChatSeqRef = useRef<string | null>(null);

  const {
    chats,
    selectedChat,
    showConversationList,
    setShowConversationList,
    setSelectedChat,
    loading,
  } = useConversation();

  const sortedConversations = useMemo(() => {
    return [...chats].sort((a, b) => {
      if (!a.messages?.length || !b.messages?.length) return 0;

      const aLastMessage = a.messages[a.messages.length - 1];
      const bLastMessage = b.messages[b.messages.length - 1];

      if (!aLastMessage?.sentAtTimestamp || !bLastMessage?.sentAtTimestamp) return 0;

      try {
        return (
          parseISO(bLastMessage.sentAtTimestamp).getTime() -
          parseISO(aLastMessage.sentAtTimestamp).getTime()
        );
      } catch (error) {
        console.error('Error parsing dates:', error);
        return 0;
      }
    });
  }, [chats]);

  const handleBackToList = () => {
    setShowConversationList(true);
    setSelectedChat(null);
    previousChatSeqRef.current = null;
  };

  const previousMessagesLengthRef = useRef<number>(0);

  const scrollToBottom = useCallback(() => {
    if (!messagesEndRef.current) return;
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 100);
  }, []);

  useEffect(() => {
    if (!selectedChat?.messages.length) return;

    const isNewChat = selectedChat.conversationSeq !== previousChatSeqRef.current;
    const hasNewMessage = selectedChat.messages.length > previousMessagesLengthRef.current;

    if (isNewChat || hasNewMessage) {
      scrollToBottom();
      previousChatSeqRef.current = selectedChat.conversationSeq;
    }

    previousMessagesLengthRef.current = selectedChat.messages.length;
  }, [selectedChat?.conversationSeq, selectedChat?.messages.length, scrollToBottom]);

  // Handle image loads
  useEffect(() => {
    if (!conversationContentRef.current) return;

    const handleImageLoad = () => {
      scrollToBottom();
    };

    const images = conversationContentRef.current.getElementsByTagName('img');
    Array.from(images).forEach(img => {
      if (!img.complete) {
        img.addEventListener('load', handleImageLoad);
      }
    });

    return () => {
      Array.from(images).forEach(img => {
        img.removeEventListener('load', handleImageLoad);
      });
    };
  }, [selectedChat?.messages, scrollToBottom]);

  if (loading) {
    return (
      <Stack>
        <Box component='div' sx={{ position: 'absolute', top: 8, left: 8, zIndex: 2 }}>
          {CloseButtonComponent}
        </Box>
        <Box component='div' sx={{ mt: 6 }}>
          <ConversationListSkeleton />
        </Box>
      </Stack>
    );
  }

  return (
    <Box component='div' sx={{ pt: 0, ...BoxProps.sx }}>
      <Grid
        container
        spacing={0}
        sx={{
          display: 'flex',
          overflowY: 'hidden',
          maxHeight: isMobile ? '85vh' : '90vh',
          minHeight: isMobile ? '85vh' : '90vh',
          position: 'relative',
        }}
      >
        <Box component='div' sx={{ position: 'absolute', top: 8, left: 8, zIndex: 2 }}>
          {CloseButtonComponent}
        </Box>

        {(showConversationList || !isMobile) && (
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              overflow: 'auto',
              maxHeight: 'inherit',
              transition: 'none',
            }}
          >
            <ConversationList sortedConversations={sortedConversations} title={title} kind={kind} />
          </Grid>
        )}

        {(!showConversationList || !isMobile) && (
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              maxHeight: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              transition: 'none',
            }}
            ref={conversationContentRef}
          >
            <MessageArea
              // @ts-ignore
              messagesEndRef={messagesEndRef}
              onBackClick={handleBackToList}
              DefaultComponent={DefaultComponent}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
export default ConversationLayout;
