import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import DialogCrudDataGrid from '../../../../../utils/components/DialogCrudDataGrid';

export function PropertyInventoryDataGrid({
  rows,
  columns,
  dialogContent,
  fieldPath,
  idColumn,
  title,
  getApi,
  createApi,
  updateApi,
  deleteApi,
  ...other
}) {
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext<CMSCase>();

  useEffect(() => {
    fetchPropertyInventory();
  }, []);

  const fetchPropertyInventory = async () => {
    await fetch(VITE_API_URL + `${getApi}?caseSeq=${formik.values?.caseSummary?.caseSeq}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status === 204) {
          return;
        }
        return res.json();
      })
      .then(data => {
        formik.setFieldValue(`caseMortuary.propertyInventory.${fieldPath}`, data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const createPropertyInventory = async newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    await fetch(VITE_API_URL + createApi, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.error(e);
      });

    await fetchPropertyInventory();
  };

  const updatePropertyInventory = async updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    await fetch(VITE_API_URL + updateApi, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.error(e);
      });

    await fetchPropertyInventory();
  };

  const deletePropertyInventory = async id => {
    await fetch(VITE_API_URL + `${deleteApi}/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.error(e);
      });

    await fetchPropertyInventory();
  };

  return (
    <DialogCrudDataGrid
      rows={rows}
      columns={columns}
      idColumn={idColumn}
      createFunction={createPropertyInventory}
      updateFunction={updatePropertyInventory}
      deleteFunction={deletePropertyInventory}
      title={title}
      dialogContent={dialogContent}
      {...other}
    />
  );
}
