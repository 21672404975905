import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';
import { useFormikContext } from 'formik';
import { useCaseSearch } from '../../utils/hooks/CaseSearch/useCaseSearch.hook';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';

interface SearchProps {
  menuOpen?: boolean;
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  sx?: object;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  includePrevNextArrows?: boolean;
}

// Regular expression to match the nn-nn-nnnn format
// const cmsCaseFormat = /^\d{2}-\d{2}-\d{4}$/;

const cmsCaseFormat = /^(?!\w\*).{2}-\d{2}-\d{4}$/;

export default function Search({
  menuOpen = false,
  setMenuOpen = () => {},
  startAdornment = null,
  endAdornment = null,
  sx = {},
  fullWidth = false,
  size = 'small',
  includePrevNextArrows = false,
}: SearchProps) {
  const [loadingPreviousCase, setLoadingPreviousCase] = useState(false);
  const [loadingNextCase, setLoadingNextCase] = useState(false);

  // This is to increase performance when using in conjunction with the case view
  const [temporaryCaseID, setTemporaryCaseID] = useState('');

  const navigate = useNavigate();
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const { caseID, setCaseID, caseIDFromCaseView } = useCaseSearch();
  const location = useLocation();
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);
  const [resolveDialog, setResolveDialog] = useState<((value: boolean) => void) | null>(null);

  // Check if the current URL is '/caseview'
  const isCaseView = location.pathname === '/caseview';

  useEffect(() => {
    setTemporaryCaseID(caseID);
  }, [caseID]);

  const OpenCMSCase = (caseId: string) => {
    navigate('/caseview', {
      state: { cmscaseid: caseId },
    });
  };

  const checkForUnsavedChanges = (): Promise<boolean> => {
    if (!hasUnsavedChanges) return Promise.resolve(true);

    setUnsavedDialogOpen(true);

    return new Promise(resolve => {
      setResolveDialog(() => resolve);
    });
  };

  const handleUnsavedDialogLeave = () => {
    resetUnsavedChanges();
    setUnsavedDialogOpen(false);
    if (resolveDialog) resolveDialog(true);
  };

  const handleUnsavedDialogCancel = () => {
    setUnsavedDialogOpen(false);
    if (resolveDialog) resolveDialog(false);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault(); // Prevents the default form submission behavior

    const canProceed = await checkForUnsavedChanges();
    if (!canProceed) return;

    setCaseID(temporaryCaseID);

    // Check if input matches the CMS case format
    if (cmsCaseFormat.test(temporaryCaseID)) {
      OpenCMSCase(temporaryCaseID);
    } else if (/^\d{8}$/.test(temporaryCaseID)) {
      // Check for purely numeric input with exactly 8 digits
      // Format into nn-nn-nnnn and call OpenCMSCase
      const formattedCaseId = `${temporaryCaseID.substring(0, 2)}-${temporaryCaseID.substring(
        2,
        4
      )}-${temporaryCaseID.substring(4)}`;
      OpenCMSCase(formattedCaseId);
    } else if (/^\d+$/.test(temporaryCaseID)) {
      // Check for other purely numeric inputs
      OpenCMSCase(temporaryCaseID);
    } else if (/\D/.test(temporaryCaseID)) {
      navigate('/quickcasesearch', { state: { lastname: temporaryCaseID } }); // if alphanumeric go to quickcasesearch instead
    }
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  const handleCaseIDChange = (event: { target: { value: SetStateAction<string> } }) => {
    setTemporaryCaseID(event.target.value);
  };

  const handlePreviousCaseClick = async () => {
    const canProceed = await checkForUnsavedChanges();
    if (!canProceed) return;

    setLoadingPreviousCase(true);

    fetch(VITE_API_URL + `getpreviouscase?caseID=${caseIDFromCaseView}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status == 200) return res.text();
        else throw new Error('There was an error fetching the case');
      })
      .then(data => {
        OpenCMSCase(data);
        setCaseID(data);
        setLoadingPreviousCase(false);
      })
      .catch(e => {
        //alert(e);
        setLoadingPreviousCase(false);
      });
  };

  const handleNextCaseClick = async () => {
    const canProceed = await checkForUnsavedChanges();
    if (!canProceed) return;

    setLoadingNextCase(true);

    fetch(VITE_API_URL + `getnextcase?caseID=${caseIDFromCaseView}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status == 200) return res.text();
        else throw new Error('There was an error fetching the case');
      })
      .then(data => {
        OpenCMSCase(data);
        setCaseID(data);
        setLoadingNextCase(false);
      })
      .catch(e => {
        //alert(e);
        setLoadingNextCase(false);
      });
  };

  return (
    <Stack
      direction='row'
      spacing={1}
      justifyContent='center'
      alignItems='center'
      sx={{ width: '100%' }}
    >
      {includePrevNextArrows &&
        isCaseView &&
        (loadingPreviousCase ? (
          <CircularProgress size={30} />
        ) : (
          <Tooltip title='Navigate to the previous case'>
            <IconButton onClick={handlePreviousCaseClick}>
              <ArrowBack />
            </IconButton>
          </Tooltip>
        ))}
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          fullWidth={fullWidth}
          key='search-bar'
          name='search-bar'
          placeholder='Case ID or Last Name'
          value={temporaryCaseID}
          onChange={handleCaseIDChange}
          size={size}
          sx={sx}
          InputProps={{
            startAdornment: <InputAdornment position='start'>{startAdornment}</InputAdornment>,
            endAdornment: <InputAdornment position='end'>{endAdornment}</InputAdornment>,
          }}
        />
      </form>
      {includePrevNextArrows &&
        isCaseView &&
        (loadingNextCase ? (
          <CircularProgress size={30} />
        ) : (
          <Tooltip title='Navigate to the next case'>
            <IconButton onClick={handleNextCaseClick}>
              <ArrowForward />
            </IconButton>
          </Tooltip>
        ))}

      <UnsavedChangesDialog
        open={unsavedDialogOpen}
        onLeave={handleUnsavedDialogLeave}
        onCancel={handleUnsavedDialogCancel}
      />
    </Stack>
  );
}
