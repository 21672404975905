import React, { useEffect, useState } from 'react';
import Address from './Address';
import axios from 'axios';
import authHeader from '../../../services/auth-header';
import { DecedentAddressTypeOptions } from '../../../utils/constants/constants';

const { VITE_API_URL } = import.meta.env;

const id = '00000000-0000-0000-0000-000000000000';

const AddressView = ({
  addresses = [],
  handleChange,
  showAllTypes = false,
  contactInfoSeq = '',
  isMultipleOn = false,
  title = '',
  disabled = false,
  isDefaultState = true,
  decedentAddressTypes = false,
}) => {
  const [countyNames, setCountyNames] = useState({});
  const [countryNames, setCountryNames] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [addressTypeNames, setAddressTypeNames] = useState({});

  const getCountyNamesAddressTypes = async () => {
    const tempCountyNames = {};
    const tempAddressTypeNames = {};
    axios
      .get(VITE_API_URL + 'GetAddressType', {
        headers: authHeader(),
      })
      .then(response => {
        response.data.data.forEach(addressType => {
          if (showAllTypes) {
            tempAddressTypeNames[addressType.addressTypeSeq] = addressType;
          } else if (decedentAddressTypes) {
            const allowedAddressTypeIds = Object.values(DecedentAddressTypeOptions);
            if (allowedAddressTypeIds.includes(addressType.addressTypeSeq?.toUpperCase())) {
              tempAddressTypeNames[addressType.addressTypeSeq] = addressType;
            } 
          } else {
          if (
            addressType.addressTypeName != 'Medical Facility' &&
            addressType.addressTypeName != 'Nursing Home' &&
            addressType.addressTypeName != 'Funeral Home' &&
            addressType.addressTypeName != 'Law Enforcement'
          ) {
            tempAddressTypeNames[addressType.addressTypeSeq] = addressType;
          }
        }
        });
      });
    axios
      .get(VITE_API_URL + 'GetAllCounty', {
        headers: authHeader(),
      })
      .then(response => {
        response.data.data.forEach(county => {
          tempCountyNames[county.countySeq] = {
            countySeq: county.countySeq,
            countyName: county.countyName,
            code: county.code,
            order: county.order,
          };
          if (county.hasOwnProperty('children')) {
            county.children.forEach(county => {
              tempCountyNames[county.countySeq] = {
                countySeq: county.countySeq,
                countyName: county.countyName,
                code: county.code,
                order: county.order,
              };
            });
          }
        });
      });
    axios
      .get(VITE_API_URL + 'GetMasterData?tableName=country', {
        headers: authHeader(),
      })
      .then(response => {
        setCountryNames(response?.data?.data || []);
      });
    axios
      .get(VITE_API_URL + 'GetMasterData?tableName=state', {
        headers: authHeader(),
      })
      .then(response => {
        setStateNames(response?.data?.data || []);
      });
    setCountyNames(tempCountyNames);
    setAddressTypeNames(tempAddressTypeNames);
  };


  const CreateNewAddress = () => {
    let makePanel = true;
    addresses?.forEach(panel => {
      if (
        addressTypeNames[panel.addressTypeSeq]?.addressTypeName?.toLowerCase() == 'unknown' ||
        addressTypeNames[panel.addressTypeSeq]?.addressTypeName?.toLowerCase() == 'homeless'
      ) {
      } else if (!panel.addressTypeSeq || !panel.addressLine1 || !panel.city) {
        makePanel = false;
      }
    });
    if (!makePanel) {
      return;
    }

    handleChange([
      ...addresses,
      {
        addressSeq: id,
        addressTypeSeq: id,
        addressLine1: '',
        city: '',
        placeSeq: null,
        stateSeq: isDefaultState ? 'cfcd3025-4434-4c07-8e6a-951b4d0c7490' : null,
        countrySeq: null,
        countySeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
        isWithinServiceArea: true,
        creationDateTime: new Date().toISOString(),
        status: 'Active',
        geoX: null,
        geoY: null,
        isCopyAddress: false,
        floor: '',
        isPrimary: false,
        otherState: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
        contactInfoSeq: contactInfoSeq || id,
        isActive: true,
      },
    ]);
  };

  useEffect(() => {
    getCountyNamesAddressTypes().then(() => {
      if (addresses?.length === 0) {
        CreateNewAddress();
      }
    })
  }, []);

  return (
    <>
      {addresses?.map((panel, index) => (
        <Address
          handleChange = {handleChange}
          handleCreateNewAddress = {CreateNewAddress}
          panel={panel}
          index={index}
          panels={addresses}
          countyNames={countyNames}
          countryNames={countryNames}
          stateNames={stateNames}
          addressTypeNames={addressTypeNames}
          isMultipleOn = {isMultipleOn}
          title = {title}
          disabled = {disabled}
        />
      ))}
    </>
  );
};

export default AddressView;
