import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LockIcon from '@mui/icons-material/Lock';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../services/utility-api';
// import { checkAccess, checkView, checkEdit } from 'utils/index';
// import { PERMISSIONS, APP_ACTION_SEQUENCE } from 'constants';
// import AccessDenied from 'ui-component/AccessDenied';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  referenceMasterTableName,
  isCounty,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countryNames,
  rows,
  referencePermissions
}) => {
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const initialValues = {
    ...editObj,
    countrySeq: editObj?.countrySeq || '',
    denominationValue: editObj?.denominationValue || '',
    denominationOrder: editObj?.denominationOrder
      ? Number(editObj.denominationOrder)
      : maxOrder + 10, // Pls explain this.
  };

  const validationSchema = Yup.object({
    denominationValue: Yup.string()
      .required('Denomination is required')
      .nullable(),
    denominationOrder: Yup.string().required('Denomination order is required'),
    countrySeq: Yup.string().required('Country is required').nullable(),
  });

  const onSave = async (values) => {
    const id = '00000000-0000-0000-0000-000000000000';

    try {
      const response = await saveAdminData({
        values: {
          ...values,

          id: !editObj?.id && '111',
          status: !editObj?.status && 'Active',

          [referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() +
                referenceMasterTableName.slice(1) +
                'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
     if (response.status == 0) {
       setTimeout(() => {
         setIsAddEditOn(false);
         getAllData();
       }, 1700);
     }
     setDialogContents(response.message);
     setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Grid
        elevation={0}
        spacing={2}
        sx={{ padding: '16px', marginLeft: '-16px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={10} mb={3} mt={1}>
            <Typography variant='h5'>
              <strong>
              {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Currency
              Denomination
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={initialValues}
          onSubmit={onSave}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, handleChange, errors }) => {
            {
            }
            return (
              <Form onSubmit={handleSubmit}>
                {console.log('eerors', errors)}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12} md={6} log={6}>
                        <FormControl fullWidth focused>
                          <InputLabel required htmlFor='countrySeq' id='countrySeq'>
                            Country
                          </InputLabel>
                          <Select
                            onChange={handleChange}
                            variant='outlined'
                            label='Country'
                            required
                            focused
                            displayEmpty
                            size='large'
                            name={`countrySeq`}
                            value={values?.countrySeq || ''}
                          >
                            {Object.keys(countryNames).map((item, z) => {
                              return (
                                <MenuItem
                                  key={countryNames[item].countrySeq}
                                  value={countryNames[item].countrySeq}
                                >
                                  {countryNames[item].countryName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <ErrorMessage
                            name='countrySeq'
                            render={(msg) => (
                              <FormHelperText error>{msg}</FormHelperText>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl fullWidth error variant='standard'>
                          <TextField
                            required
                            focused
                            id='outlined-basic'
                            defaultValue={''}
                            label={
                              'denominationValue'
                                .split('')
                                .map((alpha) => {
                                  if (alpha <= 'Z') return ' ' + alpha;
                                  return alpha;
                                })
                                .join('')
                                .trim() + ''
                            }
                            variant='outlined'
                            name={
                              ('denominationValue'?.[0].toLowerCase() + 'denominationValue'?.slice(1)) || ''
                            }
                            
                            value={
                              values?.[
                                referenceMasterTableName?.[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Value'
                              ] ?? ''
                            }
                            onChange={handleChange}
                            multiline
                          />
                          <ErrorMessage
                            name='denominationValue'
                            render={(msg) => (
                              <FormHelperText error>{msg}</FormHelperText>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl
                          error
                          fullWidth
                          variant='standard'
                          sx={{ position: 'relative', bottom: '16px' }}
                        >
                          <TextField
                            focused
                            required
                            id='denominationOrder'
                            label='List Order'
                            variant='outlined'
                            name='denominationOrder'
                            defaultValue=''
                            margin='normal'
                            size='large'
                            value={values?.denominationOrder}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Order'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Order'
                              ] == 'Unknown'
                            }
                          />
                          <ErrorMessage
                            name='denominationOrder'
                            render={(msg) => (
                              <FormHelperText error>{msg}</FormHelperText>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Box>
                          <Button
                            variant='contained'
                            sx={{ marginTop: '7px' }}
                            onClick={handleSubmit}
                            disabled={!referencePermissions?.add}
                          >
                            {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>

        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};
export default AddEdit;
